import { DownloadOutlined, EyeFilled, PlusOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import {
    Button,
    Card,
    Col,
    Modal,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import FiltersComponent from "components/FiltersComponent";
import { StatusEnum, UserTypeEnums } from "enums/harvestJobCollectionEnums";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { useHarvestJobList } from "lib/core-react/hooks/private/userHarvestJob";
import { harvestJobCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import {
    HarvestBatchCollectionModel,
    HarvestBatchModel,
} from "models/harvestBatchCollectionModel";
import { PaginationModel } from "models/pagination";
import { useEffect, useState } from "react";
import { IFilterType } from "types/filters";
import ViewDetailsModal from "./components/viewDetails";
import { generateColor } from "utils/helper";
import { formatDate } from "utils/helpers";

const HarvestJobManage = () => {
    const { getHarvestJobList } = useHarvestJobList();
    const [{ data: harvestJobListData, isLoading, refetch }] = useAtom(
        harvestJobCollectionAtom,
    );
    const { Text } = Typography;
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    const {
        filters,
        handleFilterChange,
        isFirstCall,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getHarvestJobList);
        }
    }, [isFirstCall, isFetched, refetch]);

    const HarvestJobCollectionData =
        harvestJobListData &&
        new HarvestBatchCollectionModel(harvestJobListData);

    const [selectedHarvest, setSelectedHarvest] = useState<
        HarvestBatchModel | undefined
    >(undefined);
    //const paginationData = HarvestJobCollectionData?.getPagination();

    const filterData = HarvestJobCollectionData?.getFilters();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && HarvestJobCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        HarvestJobCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getHarvestJobList,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        HarvestJobCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        filters && ApiHelperModel.makeGetRequest(filters, getHarvestJobList);
    };

    const getColorForStatus = (status: string): string => {
        switch (status) {
            case StatusEnum.CREATED:
                return "#2db7f5";
            case StatusEnum.PRE_PROCESSED:
                return "#bef5a6";
            case StatusEnum.PROCESSING:
                return "#87d068";
            case StatusEnum.COMPLETED:
                return "#87d068";
            case StatusEnum.CANCELLED:
                return "#CDB37B";
            case StatusEnum.FAILED:
                return "#a82e2e";
            default:
                return "#000";
        }
    };

    const getColorForType = (status: string) => {
        switch (status) {
            case UserTypeEnums.AGENT:
                return "#265073";
            case UserTypeEnums.ADMIN:
                return "#0E46A3";
            default:
                return "#000000";
        }
    };

    const handleShowDetail = (record: any) => {
        setSelectedHarvest(record);
        setIsShowCreateModal(record);
    };

    const columns = [
        // {
        //   title: "#SL",
        //   dataIndex: "sl",
        //   key: "sl",
        //   align: "center" as const,
        //   render: (_: string, __: HarvestBatchModel, index: number) => {
        //     return (
        //       <div>
        //         {paginationData
        //           ? (paginationData.current_page - 1) * paginationData.per_page +
        //             index +
        //             1
        //           : ""}
        //       </div>
        //     );
        //   },
        // },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text type="success">{record.getBatchNumber()}</Text>
                    </div>
                );
            },
        },
        {
            title: "User Name",
            dataIndex: "user",
            key: "user",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text>{record.getUser().getName()}</Text>
                    </div>
                );
            },
        },
        {
            title: "User Type",
            dataIndex: "userType",
            key: "userType",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Tag
                            color={getColorForType(record.getUser().getType())}
                        >
                            {record.getUser().getType()}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Tag color={getColorForStatus(record.getStatus())}>
                            {record.getStatus()}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text>{record.getType()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Context",
            dataIndex: "context",
            key: "context",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Tag
                            color={generateColor(
                                record.getContext().getFilter().status,
                            )}
                        >
                            {record.getContext().getFilter().status}
                        </Tag>
                        {record.getContext().getAttachmentUpload() && (
                            <Tooltip title="Download Attachment">
                                <a
                                    href={record
                                        .getContext()
                                        .getAttachmentUpload()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DownloadOutlined />
                                </a>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Tag",
            dataIndex: "tags",
            key: "tags",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        {record.getTags().data.map((i, index) => (
                            <Tag key={index} color={generateColor(i.getTag())}>
                                {i.getTag()}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Create At",
            dataIndex: "create_At",
            key: "create_At",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text>{formatDate(record.getCreatedAt())}</Text>
                    </div>
                );
            },
        },
        {
            title: "View Details",
            dataIndex: "tags",
            key: "tags",
            align: "center" as const,
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <Tooltip title="View Details">
                        <Button
                            onClick={() => handleShowDetail(record)}
                            shape="round"
                            icon={<EyeFilled />}
                        />
                    </Tooltip>
                );
            },
        },
    ];
    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="All Harvest Jobs"
                    style={{ marginTop: "10px" }}
                    extra={[
                        <Button
                            // onClick={() => {
                            //   showModal("create_service");
                            // }}
                            icon={<PlusOutlined />}
                            type="primary"
                        >
                            Create a Jobs
                        </Button>,
                    ]}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <Card title="Filter">
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <div style={{ marginTop: "10px" }}>
                        <Table
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={HarvestJobCollectionData?.getData()}
                            columns={columns}
                            pagination={paginationConfig}
                        />
                    </div>
                </PageHeader>
            </div>
            {selectedHarvest && (
                <Modal
                    title={"View Details"}
                    open={isShowCreateModal}
                    onCancel={() => {
                        setIsShowCreateModal(false);
                        setSelectedHarvest(undefined);
                    }}
                    onOk={() => {
                        setIsShowCreateModal(false);
                        setSelectedHarvest(undefined);
                    }}
                    width="60vw"
                >
                    {selectedHarvest && (
                        <ViewDetailsModal product={selectedHarvest} />
                    )}
                </Modal>
            )}
        </>
    );
};

export default HarvestJobManage;
