import { IFilterType } from 'types/filters'
import {
  IDestinationWarehouseRfq,
  ImageRfq,
  IRegionRfq,
  IRfqImages,
  IRfqRequest,
  IRfqRequestCollection,
  IShippingCategoryRfq,
  IShippingCountryRfq,
  IUserRfq,
} from 'types/rfqRequestCollection'
import { PaginationModel } from './paginationModel'
import { RfqRequestCollectionEnum } from 'enums/rfqRequestCollectionEnum'

class ImageRfqModel {
  object: RfqRequestCollectionEnum.Attachment
  id: number
  name: null | string
  src: string
  constructor(payload: ImageRfq) {
    this.object = payload.object
    this.id = payload.id
    this.name = payload.name
    this.src = payload.src
  }

  public getImageSrc() {
    return this.src
  }
  public getImageName() {
    return this.name
  }
}
export class RfwImagesModel {
  object: RfqRequestCollectionEnum.RfqImage
  id: number
  ownable_type: string
  ownable_id: number
  type: string
  display_order: number
  image: ImageRfqModel
  created_at: string
  constructor(payload: IRfqImages) {
    this.object = payload?.object
    this.id = payload?.id
    this.ownable_type = payload?.ownable_type
    this.ownable_id = payload?.ownable_id
    this.type = payload?.type
    this.display_order = payload?.display_order
    this.image = new ImageRfqModel(payload?.image)
    this.created_at = payload?.created_at
  }

  public getImage() {
    return this.image
  }
}

export class RfqRequestModel {
  object: RfqRequestCollectionEnum.RfqRequest
  id: number
  user: IUserRfq
  request_number: string
  product_name: string
  region: IRegionRfq
  shipping_category: IShippingCategoryRfq
  shipping_country: IShippingCountryRfq
  product_link: string
  purchase_quantity: number
  purchase_unit_type: string
  product_description: string
  shipping_type: string
  shipping_mode: string
  valid_until: null | string
  reject_reason: null | string
  destination_warehouse: IDestinationWarehouseRfq
  rfq_images: RfwImagesModel[]
  status: string
  created_at: string
  constructor(payload: IRfqRequest) {
    this.object = payload.object
    this.id = payload.id
    this.user = payload.user
    this.request_number = payload.request_number
    this.product_name = payload.product_name
    this.region = payload.region
    this.shipping_category = payload.shipping_category
    this.shipping_country = payload.shipping_country
    this.product_link = payload.product_link
    this.purchase_quantity = payload.purchase_quantity
    this.purchase_unit_type = payload.purchase_unit_type
    this.product_description = payload.product_description
    this.shipping_type = payload.shipping_type
    this.shipping_mode = payload.shipping_mode
    this.valid_until = payload.valid_until
    this.reject_reason = payload.reject_reason
    this.destination_warehouse = payload.destination_warehouse
    this.rfq_images = payload.rfq_images.data.map(
      (item) => new RfwImagesModel(item),
    )
    this.status = payload?.status
    this.created_at = payload?.created_at
  }

  public getStatus() {
    return this.status
  }
  public getId() {
    return this.id
  }
  public getRequestedNumber() {
    return this.request_number
  }
  public getPurchaseQuantity() {
    return this.purchase_quantity
  }
  public getProductDescription() {
    return this.product_description
  }
  public getProductName() {
    return this.product_name
  }
  public getProductLink() {
    return this.product_link
  }
  public getRegionCode() {
    return this.region.code
  }
  // shipping and warehouse
  public getShippingType() {
    return this.shipping_type
  }
  public getShippingMode() {
    return this.shipping_mode
  }
  public getValidUntil() {
    return this.valid_until
  }
  public getRejectReason() {
    return this.reject_reason
  }
  public getWarehouseName() {
    return this.destination_warehouse.name
  }
  // rfq_images
  public getRfqImages() {
    return this?.rfq_images
  }
  // user
  public getUserName() {
    return this.user.name
  }
  public getUserEmail() {
    return this.user.email
  }
  public getUserType() {
    return this.user?.type
  }
  public getUserShippingMark() {
    return this.user.shipping_mark
  }
}

export class RfqRequestCollectionModel {
  data: RfqRequestModel[]
  filters: IFilterType
  pagination: PaginationModel
  constructor(payload: IRfqRequestCollection) {
    this.data = payload?.data?.map((item) => new RfqRequestModel(item))
    this.filters = payload?.filters
    this.pagination = new PaginationModel(payload?.pagination)
  }

  public getData() {
    return this.data
  }

  public getFilters() {
    return this.filters
  }

  public getPagination() {
    return this.pagination
  }
}
