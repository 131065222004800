import { Form, FormInstance, Input } from "antd";
interface IProps {
  form: FormInstance<any>;
  onOk: () => void;
}

export const TrackingModificationModal = ({ form, onOk }: IProps) => {
  return (
    <Form form={form} name="form" onFinish={onOk}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Tracking is required",
          },
        ]}
        required
        name="tracking"
        label="Tracking"
        labelCol={{ span: 24 }}  // Full width label
        wrapperCol={{ span: 24 }} // Full width input
      >
        <Input placeholder="type tracking" />
      </Form.Item>
    </Form>
  );
};
