import { ILabels } from "types/buyOrderCollection";
import {
    IShipmentProduct,
    IShipmentImageCollection,
    IShipmentImage,
    IShippingCategory,
    IShippingAddress,
    IAddress,
    IShipmentProductPackageCollection,
    IShipmentProductPackage,
    IShipmentTrackingCollection,
    IShipmentProductPackageVariationCollection,
    IShipmentProductPackageVariation,
    IShipmentProductPackagePropertyVariationCollection,
    IShipmentProductPackagePropertyVariation,
    IShipmentTracking,
} from "types/shipmentProduct";

export class ShipmentProductModel implements IShipmentProduct {
    public readonly object: "ShipmentProduct";
    public readonly id: number;
    public readonly product_number: string;
    public readonly images: IShipmentImageCollection;
    public readonly shipping_category: IShippingCategory;
    public readonly shipping_address: IShippingAddress;
    public readonly shipment_instruction: string | null;
    public readonly shipping_mode: string;
    public readonly shipping_type: string;
    public readonly status: string;
    public readonly status_label: string;
    public readonly total_quantity: number;
    public readonly total_weight: number;
    public readonly packages: ShipmentProductPackageCollectionModel;
    public readonly created_at: string;

    constructor(data: IShipmentProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_number = data.product_number;
        this.images = data.images;
        this.shipping_category = data.shipping_category;
        this.shipping_address = data.shipping_address;
        this.shipment_instruction = data.shipment_instruction;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.status = data.status;
        this.status_label = data.status_label;
        this.total_quantity = data.total_quantity;
        this.total_weight = data.total_weight;
        this.packages = new ShipmentProductPackageCollectionModel(
            data.packages,
        );
        this.created_at = data.created_at;
    }

    getId(): number {
        return this.id;
    }

    getProductNumber(): string {
        return this.product_number;
    }

    getImages(): IShipmentImageCollection {
        return this.images;
    }

    getShippingCategory(): IShippingCategory {
        return this.shipping_category;
    }

    getShippingAddress(): IShippingAddress {
        return this.shipping_address;
    }

    getShipmentInstruction(): string | null {
        return this.shipment_instruction;
    }

    getShippingMode(): string {
        return this.shipping_mode;
    }

    getShippingType(): string {
        return this.shipping_type;
    }

    getStatus(): string {
        return this.status;
    }

    getStatusLabel(): string {
        return this.status_label;
    }

    getTotalQuantity(): number {
        return this.total_quantity;
    }

    getTotalWeight(): number {
        return this.total_weight;
    }

    getPackages() {
        return this.packages;
    }

    getTrackingsCount(): number {
        const trackings =
            this.packages &&
            this.packages.data.flatMap((item) => item.trackings.data)
        return trackings &&  trackings.length > 0 ? trackings.length : 0;
        
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class ShipmentImageCollectionModel implements IShipmentImageCollection {
    public readonly object: "ShipmentImageCollection";
    public readonly data: IShipmentImage[];

    constructor(data: IShipmentImageCollection) {
        this.object = data.object;
        this.data = data.data;
    }

    getObject(): "ShipmentImageCollection" {
        return this.object;
    }

    getData(): IShipmentImage[] {
        return this.data;
    }
}

export class ShipmentImageModel implements IShipmentImage {
    public readonly object: "ShipmentImage";
    public readonly id: number;
    public readonly title: string | null;
    public readonly alt: string | null;
    public readonly src: string;
    public readonly display_order: number;
    public readonly type: string;

    constructor(data: IShipmentImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.display_order = data.display_order;
        this.type = data.type;
    }

    getObject(): "ShipmentImage" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getTitle(): string | null {
        return this.title;
    }

    getAlt(): string | null {
        return this.alt;
    }

    getSrc(): string {
        return this.src;
    }

    getDisplayOrder(): number {
        return this.display_order;
    }

    getType(): string {
        return this.type;
    }
}

export class ShippingCategoryModel implements IShippingCategory {
    public readonly object: "ShippingCategory";
    public readonly id: string;
    public readonly parent_id: string;
    public readonly base_shipping_category_id: string;
    public readonly name: string;
    public readonly slug: string;
    public readonly status: string;

    constructor(data: IShippingCategory) {
        this.object = data.object;
        this.id = data.id;
        this.parent_id = data.parent_id;
        this.base_shipping_category_id = data.base_shipping_category_id;
        this.name = data.name;
        this.slug = data.slug;
        this.status = data.status;
    }

    getObject(): "ShippingCategory" {
        return this.object;
    }

    getId(): string {
        return this.id;
    }

    getParentId(): string {
        return this.parent_id;
    }

    getBaseShippingCategoryId(): string {
        return this.base_shipping_category_id;
    }

    getName(): string {
        return this.name;
    }

    getSlug(): string {
        return this.slug;
    }

    getStatus(): string {
        return this.status;
    }
}

export class ShippingAddressModel implements IShippingAddress {
    public readonly id: number;
    public readonly address: IAddress;
    public readonly is_default_billing: number;
    public readonly is_default_shipping: number;
    public readonly object?: string;
    public readonly labels?: ILabels;

    constructor(data: IShippingAddress) {
        this.id = data.id;
        this.address = data.address;
        this.is_default_billing = data.is_default_billing;
        this.is_default_shipping = data.is_default_shipping;
        this.object = data.object;
        this.labels = data.labels;
    }

    getId(): number {
        return this.id;
    }

    getAddress(): IAddress {
        return this.address;
    }

    getIsDefaultBilling(): number {
        return this.is_default_billing;
    }

    getIsDefaultShipping(): number {
        return this.is_default_shipping;
    }

    getObject(): string | undefined {
        return this.object;
    }

    getLabels(): ILabels | undefined {
        return this.labels;
    }
}

export class AddressModel implements IAddress {
    public readonly area: string;
    public readonly email: string;
    public readonly phone: string;
    public readonly country: string;
    public readonly district: string;
    public readonly address_1: string;
    public readonly address_2: string;
    public readonly last_name: string;
    public readonly first_name: string;
    public readonly postal_code: string;

    constructor(data: IAddress) {
        this.area = data.area;
        this.email = data.email;
        this.phone = data.phone;
        this.country = data.country;
        this.district = data.district;
        this.address_1 = data.address_1;
        this.address_2 = data.address_2;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.postal_code = data.postal_code;
    }

    getArea(): string {
        return this.area;
    }

    getEmail(): string {
        return this.email;
    }

    getPhone(): string {
        return this.phone;
    }

    getCountry(): string {
        return this.country;
    }

    getDistrict(): string {
        return this.district;
    }

    getAddress1(): string {
        return this.address_1;
    }

    getAddress2(): string {
        return this.address_2;
    }

    getLastName(): string {
        return this.last_name;
    }

    getFirstName(): string {
        return this.first_name;
    }

    getPostalCode(): string {
        return this.postal_code;
    }
}

export class ShipmentProductPackageCollectionModel
    implements IShipmentProductPackageCollection
{
    public readonly object: "ShipmentProductPackageCollection";
    public readonly data: ShipmentProductPackageModel[];

    constructor(data: IShipmentProductPackageCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new ShipmentProductPackageModel(item),
        );
    }

    getObject(): "ShipmentProductPackageCollection" {
        return this.object;
    }

    getData() {
        return this.data;
    }
}

export class ShipmentProductPackageModel implements IShipmentProductPackage {
    public readonly object: "ShipmentProductPackage";
    public readonly id: number;
    public readonly package_number: string;
    public readonly product_price: number;
    public readonly quantity: number;
    public readonly weight: number;
    public readonly length: number;
    public readonly width: number;
    public readonly height: number;
    public readonly cbm: number;
    public readonly contains: string;
    public readonly contains_2: string | null;
    public readonly hs_code: string | null;
    public readonly carton_number: string | null;
    public readonly fulfillment_status: string;
    public readonly trackings: ShipmentTrackingCollectionModel;
    public readonly images: IShipmentImageCollection;
    public readonly variations: IShipmentProductPackageVariationCollection;

    constructor(data: IShipmentProductPackage) {
        this.object = data.object;
        this.id = data.id;
        this.package_number = data.package_number;
        this.product_price = data.product_price;
        this.quantity = data.quantity;
        this.weight = data.weight;
        this.length = data.length;
        this.width = data.width;
        this.height = data.height;
        this.cbm = data.cbm;
        this.contains = data.contains;
        this.contains_2 = data.contains_2;
        this.hs_code = data.hs_code;
        this.carton_number = data.carton_number;
        this.fulfillment_status = data.fulfillment_status;
        this.trackings = new ShipmentTrackingCollectionModel(data.trackings);
        this.images = data.images;
        this.variations = data.variations;
    }

    getTrackings() {
        return this.trackings;
    }

    getObject(): "ShipmentProductPackage" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getPackageNumber(): string {
        return this.package_number;
    }

    getProductPrice(): number {
        return this.product_price;
    }

    getQuantity(): number {
        return this.quantity;
    }

    getWeight(): number {
        return this.weight;
    }

    getLength(): number {
        return this.length;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getCbm(): number {
        return this.cbm;
    }

    getContains(): string {
        return this.contains;
    }

    getContains2(): string | null {
        return this.contains_2;
    }

    getHsCode(): string | null {
        return this.hs_code;
    }

    getCartonNumber(): string | null {
        return this.carton_number;
    }

    getFulfillmentStatus(): string {
        return this.fulfillment_status;
    }

    getImages(): IShipmentImageCollection {
        return this.images;
    }

    getVariations(): IShipmentProductPackageVariationCollection {
        return this.variations;
    }
}

export class ShipmentTrackingModel implements IShipmentTracking {
    public readonly object: "ShipmentTracking";
    public readonly id: number;
    public readonly type: string;
    public readonly tracking: string;
    public readonly created_at: string;
    constructor(data: IShipmentTracking) {
        this.object = data.object;
        this.id = data.id;
        this.type = data.type;
        this.tracking = data.tracking;
        this.created_at = data.created_at;
    }
    getId = () => this.id;
    getType = () => this.type;
    getTracking = () => this.tracking;
}

export class ShipmentTrackingCollectionModel
    implements IShipmentTrackingCollection
{
    public readonly object: "ShipmentTrackingCollection";
    public readonly data: ShipmentTrackingModel[];

    constructor(data: IShipmentTrackingCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShipmentTrackingModel(item));
    }

    getObject(): "ShipmentTrackingCollection" {
        return this.object;
    }

    getData(): IShipmentTracking[] {
        return this.data;
    }
}

export class ShipmentProductPackageVariationCollectionModel
    implements IShipmentProductPackageVariationCollection
{
    public readonly object: "ShipmentProductPackageVariationCollection";
    public readonly data: IShipmentProductPackageVariation[];

    constructor(data: IShipmentProductPackageVariationCollection) {
        this.object = data.object;
        this.data = data.data;
    }

    getObject(): "ShipmentProductPackageVariationCollection" {
        return this.object;
    }

    getData(): IShipmentProductPackageVariation[] {
        return this.data;
    }
}

export class ShipmentProductPackageVariationModel
    implements IShipmentProductPackageVariation
{
    public readonly object: "ShipmentProductPackageVariation";
    public readonly id: number;
    public readonly quantity: number;
    public readonly sku_id: string;
    public readonly properties: IShipmentProductPackagePropertyVariationCollection;

    constructor(data: IShipmentProductPackageVariation) {
        this.object = data.object;
        this.id = data.id;
        this.quantity = data.quantity;
        this.sku_id = data.sku_id;
        this.properties = data.properties;
    }

    getObject(): "ShipmentProductPackageVariation" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getQuantity(): number {
        return this.quantity;
    }

    getSkuId(): string {
        return this.sku_id;
    }

    getProperties(): IShipmentProductPackagePropertyVariationCollection {
        return this.properties;
    }
}

export class ShipmentProductPackagePropertyVariationCollectionModel
    implements IShipmentProductPackagePropertyVariationCollection
{
    public readonly object: "ShipmentProductPackagePropertyVariationCollection";
    public readonly data: IShipmentProductPackagePropertyVariation[];

    constructor(data: IShipmentProductPackagePropertyVariationCollection) {
        this.object = data.object;
        this.data = data.data;
    }

    getObject(): "ShipmentProductPackagePropertyVariationCollection" {
        return this.object;
    }

    getData(): IShipmentProductPackagePropertyVariation[] {
        return this.data;
    }
}

export class ShipmentProductPackagePropertyVariationModel
    implements IShipmentProductPackagePropertyVariation
{
    public readonly object: "ShipmentProductPackagePropertyVariation";
    public readonly id: number;
    public readonly property_id: string;
    public readonly property_name: string;
    public readonly property_original_name: string;
    public readonly property_value_id: string;
    public readonly property_value_name: string;
    public readonly property_value_title: string;
    public readonly property_value_color: string | null;
    public readonly property_value_image: string;
    public readonly property_value_thumb: string;

    constructor(data: IShipmentProductPackagePropertyVariation) {
        this.object = data.object;
        this.id = data.id;
        this.property_id = data.property_id;
        this.property_name = data.property_name;
        this.property_original_name = data.property_original_name;
        this.property_value_id = data.property_value_id;
        this.property_value_name = data.property_value_name;
        this.property_value_title = data.property_value_title;
        this.property_value_color = data.property_value_color;
        this.property_value_image = data.property_value_image;
        this.property_value_thumb = data.property_value_thumb;
    }

    getObject(): "ShipmentProductPackagePropertyVariation" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getPropertyId(): string {
        return this.property_id;
    }

    getPropertyName(): string {
        return this.property_name;
    }

    getPropertyOriginalName(): string {
        return this.property_original_name;
    }

    getPropertyValueId(): string {
        return this.property_value_id;
    }

    getPropertyValueName(): string {
        return this.property_value_name;
    }

    getPropertyValueTitle(): string {
        return this.property_value_title;
    }

    getPropertyValueColor(): string | null {
        return this.property_value_color;
    }

    getPropertyValueImage(): string {
        return this.property_value_image;
    }

    getPropertyValueThumb(): string {
        return this.property_value_thumb;
    }
}
