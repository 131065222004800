import { Link } from "react-router-dom";
import { Card, Image, List, Typography } from "antd";

import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

export const Product = ({
  isLoading,
  title,
  ShipmentProductData,
}: IShipmentProductDetailCommonProps) => {
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      <List
        dataSource={[
          {
            title: "Product number",
            content: (
              <Typography.Text copyable>
                {ShipmentProductData?.getProductNumber()}
              </Typography.Text>
            ),
          },
          {
            title: "Product link",
            content: ShipmentProductData?.getLink() ? (
              <Link to={`${ShipmentProductData.getLink()}`}>
                {ShipmentProductData.getLink()}
              </Link>
            ) : (
              "Own source product"
            ),
          },
          {
            title: "Product title",
            content: (
              <Typography.Text
                ellipsis={{ tooltip: ShipmentProductData?.getTitle() }}
              >
                {ShipmentProductData?.getTitle()}
              </Typography.Text>
            ),
          },
          {
            title: "Product images",
            content: (
              <Image.PreviewGroup>
                <List
                  grid={{ gutter: 16, column: 8 }}
                  size="small"
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 3,
                  }}
                  dataSource={ShipmentProductData?.getImages().getData()}
                  renderItem={(item) => (
                    <Image width={80} height={80} src={item.getSrc()} />
                  )}
                />
              </Image.PreviewGroup>
            ),
          },
        ]}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <Typography.Text strong>{item.title}: </Typography.Text>{" "}
            {item.content}
          </List.Item>
        )}
      />
    </Card>
  );
};
