import { IPagination } from "types/pagination";
import {
  IRole,
  IRoleCollection,
  IRolePostRequest,
  IRoleUpdateRequest,
} from "types/roleCollection";
import { RoleEnum } from "enums/roleCollectionEnums";
import { PermissionCollectionModel } from "./permissionCollectionModel";

export class RoleModel {
  public readonly object: RoleEnum.Role;
  public readonly id: number;
  public readonly name: string;
  public readonly label: string;
  public readonly permissions: PermissionCollectionModel;

  constructor(dataItem: IRole) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.label = dataItem.label;
    this.permissions = dataItem.permissions;
  }

  getId = () => this.id;
  getName = () => this.name;
  getLabel = () => this.label;
  getPermissions = () => this.permissions;
}

export class RoleCollectionModel {
  public readonly object: RoleEnum.RoleCollection;
  public readonly data: RoleModel[];
  public readonly pagination: IPagination;

  constructor(data: IRoleCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new RoleModel(item));
    this.pagination = data.pagination;
  }

  getData = () => this.data;

  getRoleIdsAsObjectName() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.name] = item.name));
    return obj;
  }

  getRoleIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }

  getRolesLabeledValue = () => {
    return this.getData().map((item) => ({
      label: item.getLabel(),
      value: item.getId(),
    }));
  };
}

//Post Request
export class RolePostRequestModel {
  public readonly name: string;
  public readonly label: string;

  constructor(reqData: IRolePostRequest) {
    this.name = reqData.name;
    this.label = reqData.label;
  }
}

//update Request
export class RoleUpdateRequestModel {
  public readonly name: string;
  public readonly label: string;
  public readonly permission_ids: number[];

  constructor(reqData: IRoleUpdateRequest) {
    this.name = reqData.name;
    this.label = reqData.label;
    this.permission_ids = reqData.permission_ids;
  }
}
