import { Alert, Button, Form, TreeSelect, message } from "antd";
import { useAtom } from "jotai";
import { useGetBaseShippingCategory } from "lib/core-react/hooks/private";
import { useCopyCommissionForBaseShipping } from "lib/core-react/hooks/private/useCommission";
import { baseShippingCategoryAtom } from "lib/core-react/store/store";
import { BaseShippingCategoryModel } from "models/baseShippingCategory";
import { CommissionCopyForBaseShippingRequestModel } from "models/commissionCollectionModel";
import React, { useEffect, useState } from "react";
import {
  ICategoryCommission,
  ICommission,
  ICommissionAssociation,
  ICommissionCopyForBaseShippingRequest,
} from "types/commissionCollection";

interface IProps {
  selectedCommission: ICommission;
  selectedCommissionAssociation: ICommissionAssociation;
  selectedCommissionCategory: ICategoryCommission;
  setIsShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyCommissionForBaseShipping = ({
  selectedCommission,
  selectedCommissionAssociation,
  selectedCommissionCategory,
  setIsShowCopyModal,
}: IProps) => {
  const [form] = Form.useForm();
  const { getBaseShippingCategory } = useGetBaseShippingCategory();
  const { copyCommission, isLoading: copyLoading } =
    useCopyCommissionForBaseShipping();
  const [{ data: baseShippingCategoryDataAtom, isLoading }] = useAtom(
    baseShippingCategoryAtom,
  );
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getBaseShippingCategory();
    
  }, []);

  const baseShippingCategoryTreeIds =
    baseShippingCategoryDataAtom &&
    new BaseShippingCategoryModel(
      baseShippingCategoryDataAtom,
    ).getBaseShippingCategoryTreeIds();

  const onFinish = async (value: CommissionCopyForBaseShippingRequestModel) => {
    const payload: ICommissionCopyForBaseShippingRequest = {
      base_shipping_category_id: value.base_shipping_category_id,
    };

    try {
      const response = await copyCommission(
        selectedCommission.id,
        selectedCommissionAssociation.id,
        selectedCommissionCategory.id,
        payload,
      );
      setIsShowCopyModal(false);
      setIsErrorVisible(false);
      message.success(response);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="create-commission-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "Base shipping category is required",
            },
          ]}
          name={"base_shipping_category_id"}
          label="Base Shipping Category"
        >
          <TreeSelect
            showSearch
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Please select a base shipping category"
            allowClear
            loading={isLoading}
            treeLine={{ showLeafIcon: true }}
            treeData={baseShippingCategoryTreeIds}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isLoading || copyLoading}
          >
            Copy
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          //   message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default CopyCommissionForBaseShipping;
