export enum AuctionRuleEnum {
  AuctionRuleCollection = "AuctionRuleCollection",
  AuctionRule = "AuctionRule",
}
export enum AuctionRuleOperatorEnum {
  LESS_THAN = "<",
  LESS_EQUAL = "<=",
  GREATER = ">",
  GREATER_EQUAL = ">=",
  EQUAL = "=",
}
export enum AuctionRuleTypeEnum {
  WEIGHT = "weight",
  PIECE = "piece",
  PRICE = "price",
  SHIPPING_CATEGORY = "shipping_category_id",
  CBM = "CBM",
}
export enum AuctionRuleDataStatus {
  active = "active",
  inactive = "inactive",
}
