import React, { useEffect, useState, createRef } from "react";
import {
    Alert,
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    notification,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Forms } from "models/form";
import { useCreateCampaign } from "lib/core-react/hooks/private/useCampaign";
import { CreateCampaignPostRequestModel } from "models/campaignCollectionModel";
import TextArea from "antd/lib/input/TextArea";
import {
    renderOptionsFromEnum,
    renderOptionsFromEnumUpdateCampaignsPublished,
} from "components/Form/forms";
import { PublishStatusEnum } from "enums/campaignCollectionEnums";
import { useGetRegion } from "lib/core-react/hooks/private";
import { useGetStore } from "lib/core-react/hooks/private/useStore";
import { useAtom } from "jotai";
import { regionRegion, storeStoreAtom } from "lib/core-react/store/store";
import { RegionModel } from "models/region";
import { StoreCollectionModel } from "models/storeCollectionModel";
import { CampaignStripeModel } from "models/campaignStripModel";
import dayjs from "dayjs";
import {
    ServiceBaseUrl,
    ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateCampaign = ({ setIsShowCreateModal }: IProps) => {
    const { createCampaign, isLoading, isError } = useCreateCampaign();
    const { getRegion } = useGetRegion();
    const { getStore } = useGetStore();
    const [data, setData] = useState<CampaignStripeModel[] | null>(null);

    const dataStrip = data || [];

    const [{ data: storeDataAtom, isLoading: storeLoading }] =
        useAtom(storeStoreAtom);

    const [{ data: regionDataAtom, isLoading: regionLoading }] =
        useAtom(regionRegion);

    useEffect(() => {
        getRegion("per_page=200");
        getStore();
    }, []);

    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();
    const regionData = regionDataAtom && new RegionModel(regionDataAtom);
    const storeData = storeDataAtom && new StoreCollectionModel(storeDataAtom);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    //hide error message after 5 sec
    useEffect(() => {
        if (isErrorVisible) {
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 5000);
        }
    }, [isErrorVisible]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.CMS_STRIP_SERVICE)}/api/campaigns`,
                );
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const result = await response.json();
                setData(result.data);
            } catch (err: any) {}
        };

        fetchData();
    }, []);

    const onFinish = async (value: CreateCampaignPostRequestModel) => {
        const formattedValues = {
            ...value,
            start_at: dayjs(value.start_at).format("YYYY-MM-DD HH:mm:ss"),
            finish_at: dayjs(value.finish_at).format("YYYY-MM-DD HH:mm:ss"),
        };

        try {
            await createCampaign(formattedValues);
            setIsShowCreateModal(false);
            notification["success"]({
                message: "Updated Campaign successfully",
            });
            form.resetFields();
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Campaign"
            onFieldsChange={onValuesChange}
            ref={formRef}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Store is required",
                    },
                ]}
                name={["store_id"]}
                label="Store"
            >
                <Select
                    loading={storeLoading}
                    placeholder="Please select a store"
                >
                    {storeData?.getStoreIdsAsObject() &&
                        renderOptionsFromEnum(storeData?.getStoreIdsAsObject())}
                </Select>
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Region is required" }]}
                name={["region_id"]}
                label="Region"
            >
                <Select
                    loading={regionLoading}
                    placeholder="Please select a region"
                >
                    {regionData?.getRegionIdsAsObject() &&
                        renderOptionsFromEnum(
                            regionData?.getRegionIdsAsObject(),
                        )}
                </Select>
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Title is required" }]}
                label="Title"
                name="title"
            >
                <TextArea rows={4} placeholder="Please write your title" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "slug id is required" },
                    {
                        required: true,
                    },
                ]}
                label="Slug"
                name="slug"
            >
                <Input placeholder="Please write your slug" />
            </Form.Item>

            <Form.Item
                label="Strip Content Slug"
                name="strip_content_slug"
                rules={[{ required: true, message: "Slug is required" }]}
            >
                <Select placeholder="Please select your Strip Content Slug">
                    {dataStrip &&
                        dataStrip.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={item.attributes.slug}
                            >
                                {item.attributes.title}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="publishing_status"
                label="Select Publishing Status"
                rules={[
                    {
                        required: true,
                        message: "The publishing status field is required.",
                    },
                ]}
            >
                <Select placeholder="Please select a Publishing Status">
                    {renderOptionsFromEnumUpdateCampaignsPublished(
                        PublishStatusEnum,
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label="Start At"
                name="start_at"
                rules={[
                    { required: true, message: "Please input the start date!" },
                ]}
            >
                <DatePicker
                    showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>

            <Form.Item
                label="Finish At"
                name="finish_at"
                rules={[
                    {
                        required: true,
                        message: "Please input the finish date!",
                    },
                ]}
            >
                <DatePicker
                    showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>

            {isErrorVisible && (
                <Alert
                    style={{ marginTop: "7px" }}
                    message={isError}
                    closable
                    afterClose={() => setIsErrorVisible(false)}
                    type="error"
                    showIcon
                />
            )}
        </Form>
    );
};

export default CreateCampaign;
