import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Dropdown,
    Form,
    message,
    Modal,
    notification,
    Table,
    Tag,
    Typography,
} from "antd";
import { purchaseCommissionCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { AgentCompanyModel } from "models/agentCompanyCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { usePurchaseCommissionGet } from "lib/core-react/hooks/private";
import {
    PurchaseCommissionCollectionModel,
    PurchaseCommissionModel,
} from "models/purchaseCommissionCollectionModel";
import { BuyProductCommissionRateTypeEnum } from "enums/purchaseCommissionCollectionEnum";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { formatDate } from "utils/helpers";
import { ExtendedMenuItemType } from "types";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
import { DownOutlined, EditFilled, HddFilled } from "@ant-design/icons";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { MenuInfo } from "rc-menu/lib/interface";
import { PurchaseCommissionUpdate } from "./PurchaseCommissionUpdate";
import { IPurchaseCommissionUpdateData } from "types/purchaseCommissionCollection";
import { usePurchaseCommissionUpdate } from "lib/core-react/hooks/private/usePurchase";

interface IProps {
    data: AgentCompanyModel;
}

export const PurchaseCommissionTable = ({ data }: IProps) => {
    const { getPurchaseCommission } = usePurchaseCommissionGet(data.getId());
    const {
        updatePurchaseCommission,
        isLoading: isLoadingUpdatePurchaseCommission,
    } = usePurchaseCommissionUpdate();
    const [{ data: purchaseCommissionCollectionData, isLoading, refetch }] =
        useAtom(purchaseCommissionCollectionAtom);
    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();
    const { isMobile } = useWindowWidth();
    const { Text } = Typography;

    const [form] = Form.useForm();

    const [modalOpen, setModalOpen] = useState<"updateCommission" | undefined>(
        undefined,
    );

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getPurchaseCommission);
        }
    }, [data, filters]);

    const PurchaseCommissionCollectionData =
        purchaseCommissionCollectionData &&
        new PurchaseCommissionCollectionModel(purchaseCommissionCollectionData);

    // Pagination
    const paginationData = PurchaseCommissionCollectionData?.getPagination();

    const [selectedCommission, setSelectedCommission] = useState<
        PurchaseCommissionModel | undefined
    >(undefined);

    const handleMenuClick = (e: MenuInfo, record: PurchaseCommissionModel) => {
        if (e.key === "update-commission") {
            setModalOpen("updateCommission");
            setSelectedCommission(record);
        }
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPurchaseCommission,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PurchaseCommissionCollectionData,
        handlePaginationChange,
    );

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            width: 70,
            align: "center" as const,
            render: (_: string, __: PurchaseCommissionModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Commission Details",
            dataIndex: "store",
            hidden: !isMobile,
            key: "store",
            width: 100,
            render: (_: string, record: PurchaseCommissionModel) => {
                return (
                    <>
                        <Text>
                            Store:{" "}
                            <Typography.Text>
                                {record.getStore()?.getName()}
                            </Typography.Text>
                        </Text>
                        <br />
                        <Text>
                            Rate:{" "}
                            <Typography.Text>
                                {record.getCommissionRate()}
                                {record.getCommissionRateType() ===
                                BuyProductCommissionRateTypeEnum.PERCENTAGE
                                    ? "%"
                                    : "(Fixed)"}
                            </Typography.Text>
                        </Text>
                        <br />
                        {record.getCreatedAt() ? (
                            <Tag color="purple">
                                {formatDate(record.getCreatedAt()!)}
                            </Tag>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
        {
            title: "Store",
            dataIndex: "store",
            hidden: isMobile,
            key: "store",
            width: 100,
            render: (_: string, record: PurchaseCommissionModel) => {
                return (
                    <>
                        <Typography.Text>
                            {record.getStore()?.getName()}
                        </Typography.Text>
                    </>
                );
            },
        },
        {
            title: "Rate",
            dataIndex: "commission_rate",
            key: "commission_rate",
            hidden: isMobile,
            width: 30,
            align: "center" as const,
            render: (_: string, record: PurchaseCommissionModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() ===
                    BuyProductCommissionRateTypeEnum.PERCENTAGE
                        ? "%"
                        : "(Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            align: "center" as const,
            width: 100,
            key: "created_at",
            render: (_: string, record: PurchaseCommissionModel) => {
                return record.getCreatedAt() ? (
                    <Tag color="purple">
                        {formatDate(record.getCreatedAt()!)}
                    </Tag>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            align: "center" as const,
            width: 100,
            render: (_: string, record: PurchaseCommissionModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD,
                        label: "Update",
                        key: "update-commission",
                        icon: <EditFilled />,
                        onClick: (e) => handleMenuClick(e, record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const onFinish = async (values: any) => {
        try {
            const payload: IPurchaseCommissionUpdateData = {
                store_id: values.store_id,
                commission_rate: values.commission_rate,
                commission_rate_type: values.commission_rate_type,
            };

            if (selectedCommission) {
                await updatePurchaseCommission(
                    data.getId(),
                    selectedCommission.getId(),
                    payload,
                );
                notification["success"]({
                    message: "Update Successfully",
                });
                setSelectedCommission(undefined);
                setModalOpen(undefined);
            } else {
                message.error("No company selected!");
            }
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    return (
        <>
            <Table
                //@ts-ignore
                columns={columns}
                size="small"
                bordered
                loading={isLoading}
                dataSource={PurchaseCommissionCollectionData?.getData()}
                pagination={paginationConfig}
                rowKey="id"
            />
            <Modal
                open={Boolean(modalOpen)}
                onCancel={() => {
                    setSelectedCommission(undefined);
                    setModalOpen(undefined);
                }}
                onOk={form.submit}
                okText={"Update"}
                okButtonProps={{ loading: isLoadingUpdatePurchaseCommission }}
                title={"Update Commission"}
            >
                {modalOpen === "updateCommission" && selectedCommission && (
                    <PurchaseCommissionUpdate
                        selectedCommission={selectedCommission}
                        form={form}
                        onFinish={onFinish}
                    />
                )}
            </Modal>
        </>
    );
};
