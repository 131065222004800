import { useAtom } from "jotai";
import { useState } from "react";
import { SequencesDataModel } from "models/buyOrderCollectionModel";
import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
    buyOrderCollectionAtom,
    buyOrderDetailAtom,
    buyProductCollectionAtom,
} from "../../store/buyOrderAtoms/buyOrderAtoms";
import {
    IAddBuyOrderAddProductFormData,
    IAddVariationsFormData,
    IUpdateVariationsFormData,
} from "types/buyOrderDetail";
import {
    buyProductDetail,
    purchaseCommissionCollectionAtom,
} from "lib/core-react/store/store";
import { IPurchaseCommissionFormData, IPurchaseCommissionUpdateData } from "types/purchaseCommissionCollection";
import { IUserCart } from "types/buyOrderPlace";
import {
    BulkApprovedPayload,
    BulkRejectPayload,
    IShippingRateUpdatePayload,
    IShippingUpdatePayload,
} from "types/buyOrderCollection";
import { notification } from "antd";

export const useGetBuyOrders = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const getBuyOrders = async (params?: string): Promise<void> => {
        setBuyOrderCollection({
            ...buyOrderCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyOrders(params);
            setBuyOrderCollection({
                ...buyOrderCollection,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            console.log(error);
            setBuyOrderCollection({
                ...buyOrderCollection,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error.response.status === 403,
            });
        }
    };
    return { getBuyOrders } as const;
};

export const useGetBuyProducts = () => {
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const { purchaseService } = useService();
    const getBuyProducts = async (params?: string): Promise<void> => {
        setBuyProductCollection({
            ...buyProductCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyProducts(
                    params,
                );
            setBuyProductCollection({
                ...buyProductCollection,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setBuyProductCollection({
                ...buyProductCollection,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error.response?.status === 403,
            });
        }
    };
    return { getBuyProducts } as const;
};

export const useGetBuyProduct = () => {
    const [buyProductDetails, setBuyProductDetails] = useAtom(buyProductDetail);
    const { purchaseService } = useService();
    const getBuyProduct = async (buy_product_id: number): Promise<void> => {
        setBuyProductDetails({
            ...buyProductDetails,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyProduct(
                    buy_product_id,
                );
            setBuyProductDetails({
                ...buyProductDetails,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setBuyProductDetails({
                ...buyProductDetails,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };
    return { getBuyProduct } as const;
};

// This one is for Approving Product inside Orders
export const useApproveBuyProduct = () => {
    const { purchaseService } = useService();
    const { getBuyProducts } = useGetBuyProducts();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const approveBuyProduct = async (
        buyProductID: number,
        payload: { commission_rate: number; agent_company_id: number },
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.approveBuyProduct(
                    buyProductID,
                    payload,
                );
            setBuyOrderCollection({
                ...buyOrderCollection,
                isLoading: false,
                refetch: false,
                data: response,
            });

            notification["success"]({
                message: "Assign successfully",
            });
            await getBuyProducts();
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { approveBuyProduct, isLoading, isError };
};

export const useBulkApproveBuyProduct = () => {
    const { purchaseService } = useService();
    const { getBuyProducts } = useGetBuyProducts();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const bulkApproveBuyProduct = async (payload: BulkApprovedPayload) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.bulkApproveBuyProduct(
                    payload,
                );
            setBuyOrderCollection({
                ...buyOrderCollection,
                isLoading: false,
                refetch: false,
                data: response,
            });

            notification["success"]({
                message: "Bulk Approved and Assign successfully",
            });
            await getBuyProducts();
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { bulkApproveBuyProduct, isLoading, isError };
};

export const useBulkRejectBuyProduct = () => {
    const { purchaseService } = useService();
    const { getBuyProducts } = useGetBuyProducts();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const bulkRejectBuyProduct = async (payload: BulkRejectPayload) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.bulkRejectBuyProduct(
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });
            await getBuyProducts();
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { bulkRejectBuyProduct, isLoading, isError };
};

export const useRejectBuyProduct = () => {
    const { purchaseService } = useService();
    const { getBuyProducts } = useGetBuyProducts();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const rejectBuyProduct = async (buyProductID: number) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.rejectBuyProduct(
                    buyProductID,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });
            notification["success"]({
                message: "Reject successfully",
            });
            await getBuyProducts();
            return response;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { rejectBuyProduct, isLoading, isError };
};

export const useBuyProductTakeActions = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductTakeAction = async (
        buy_product_id: number,
        payload: SequencesDataModel,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.buyProductTakeActions(
                    buy_product_id,
                    payload,
                );
            setIsLoading(false);
            notification["success"]({
                message: "Successfully created",
            });
            return response;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(error);
            setIsLoading(false);
            throw error;
        }
    };

    return { buyProductTakeAction, isLoading, isError };
};

export const useGetBuyOrdersDetail = () => {
    const [buyOrder, setBuyOrder] = useAtom(buyOrderDetailAtom);
    const { purchaseService } = useService();

    const getBuyOrdersDetail = async (orderId: string): Promise<void> => {
        setBuyOrder({
            ...buyOrder,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyOrdersDetail(
                    orderId,
                );
            setBuyOrder({
                ...buyOrder,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setBuyOrder({
                ...buyOrder,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };
    return { getBuyOrdersDetail } as const;
};

// Variations
export const useBuyProductAddVariations = () => {
    const [buyOrder, setBuyOrder] = useAtom(buyOrderDetailAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const addVariations = async (
        buyProductID: number,
        payload: IAddVariationsFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.addVariations(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);
            setBuyOrder({
                ...buyOrder,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { addVariations, isLoading, isError };
};

export const useUpdateVariations = () => {
    const [buyOrder, setBuyOrder] = useAtom(buyOrderDetailAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateVariations = async (
        buyProductID: number,
        payload: IUpdateVariationsFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.updateVariations(
                    buyProductID,
                    payload,
                );
            notification["success"]({
                message: "Update successfully",
            });
            setIsLoading(false);
            setBuyOrder({
                ...buyOrder,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { updateVariations, isLoading, isError };
};

export const useDeleteVariations = () => {
    const [buyOrder, setBuyOrder] = useAtom(buyOrderDetailAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const deleteVariations = async (
        buyProductID: number,
        payload: { ids: number[] },
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.deleteVariations(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);
            setBuyOrder({
                ...buyOrder,
                refetch: true,
            });
            notification["success"]({
                message: "Delete successfully",
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return { deleteVariations, isLoading, isError };
};

// Products
export const useBuyOrderAddProduct = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const addProduct = async (
        buyOrderId: string,
        region: string,
        locale: string,
        payload: IAddBuyOrderAddProductFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.addProduct(
                    buyOrderId,
                    region,
                    locale,
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addProduct, isLoading, isError };
};

export const useDeleteBuyOrderProduct = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const deleteProduct = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.deleteProduct(id);
            setIsLoading(false);
            setBuyOrderCollection({ ...buyOrderCollection, refetch: true });
            notification["success"]({
                message: "Delete successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };
    return { deleteProduct, isLoading, isError };
};

export const useBuyProderOrderPlace = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductOrderPlace = async (payload: IUserCart) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.buyProductOrderPlace(
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({ ...buyOrderCollection, refetch: true });
            notification["success"]({
                message: "Successfully created",
            });
            return response.data;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };
    return { buyProductOrderPlace, isLoading, isError };
};

// Purchase Commission
export const usePurchaseCommissionGet = (agentCompanyId: number) => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();

    const getPurchaseCommission = async (params?: string): Promise<void> => {
        setPurchaseCommissionCollection({
            ...purchaseCommissionCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseCommissionResource.getPurchaseCommission(
                    agentCompanyId,
                    params,
                );
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };
    return { getPurchaseCommission } as const;
};

export const usePurchaseCommissionCreate = () => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const createPurchaseCommission = async (
        agentCompanyId: number,
        payload: IPurchaseCommissionFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseCommissionResource.addPurchaseCommission(
                    agentCompanyId,
                    payload,
                );
            setIsLoading(false);
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { createPurchaseCommission, isLoading, isError };
};

export const usePurchaseCommissionUpdate = () => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updatePurchaseCommission = async (
        agentCompanyId: number,
        commission_id: number,
        payload: IPurchaseCommissionUpdateData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseCommissionResource.updatePurchaseCommission(
                    agentCompanyId,
                    commission_id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { updatePurchaseCommission, isLoading, isError };
};

export const useUpdateFx = () => {
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const { getBuyProducts } = useGetBuyProducts();
    const updateFx = async (
        id: number,
        payload: { fx_rate: number; commission_rate: number | null },
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.updateFx(id, payload);
            setIsLoading(false);
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: true,
            });

            notification["success"]({
                message: "Update successfully",
            });
            await getBuyProducts();
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { updateFx, isLoading, isError };
};

export const useSetCurrentFx = () => {
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const { getBuyProducts } = useGetBuyProducts();
    const setCurrentFx = async (id: number) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.setCurrentFx(id);
            setIsLoading(false);
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: true,
            });

            notification["success"]({
                message: "Update successfully",
            });
            await getBuyProducts();
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { setCurrentFx, isLoading, isError };
};

export const useBuyProductsShippingUpdate = () => {
    const { purchaseService } = useService();
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductShippingUpdate = async (
        buyProductID: number,
        payload: IShippingUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.buyProductUpdateShipping(
                buyProductID,
                payload,
            );
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: false,
            });
            notification["success"]({
                message: "Update successfully",
            });
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return { buyProductShippingUpdate, isLoading, isError };
};

export const useBuyProductsShippingPriceUpdate = () => {
    const { purchaseService } = useService();
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductShippingPriceUpdate = async (
        buyProductID: number,
        payload: IShippingRateUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.buyProductUpdateShippingPrice(
                buyProductID,
                payload,
            );
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: false,
            });
            notification["success"]({
                message: "Update successfully",
            });
            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return { buyProductShippingPriceUpdate, isLoading, isError };
};

// export const useBuyProductUpdateShippigCategoryPrice = () => {
//   const { purchaseService } = useService();
//   const { getBuyProducts } = useGetBuyProducts();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");
//   const [buyProductCollection, setBuyProductCollection] = useAtom(
//     buyProductCollectionAtom,
//   );
//
//   //const [buyProductDetail, setBuyProductDetail] = useAtom(buyProductDetailAtom)
//   const buyProductUpdateShippigAgentCategoryPrice = async (
//     buyProductID: number,
//     payload: IUpdateShippingCategoriyPrice,
//   ) => {
//     setIsLoading(true);
//     try {
//       const response =
//         await purchaseService.buyProductsResource.buyProductUpdateShippingCategoryPrice(
//           buyProductID,
//           payload,
//         );
//       setBuyProductCollection({
//         ...buyProductCollection,
//         isLoading: false,
//         refetch: false,
//         data: response,
//       });
//       setIsLoading(false);
//       notification["success"]({
//         message: "Update successfully",
//       });
//       await getBuyProducts();
//     } catch (error: any) {
//       notification["error"]({
//         message: getError(error),
//       });
//       setError(getError(error));
//       setIsLoading(false);
//     }
//   };
//
//   return { buyProductUpdateShippigAgentCategoryPrice, isLoading, isError };
// };
