import {
  IShipmentProductCustomerAssociation,
  IShipmentProductCustomerAssociationWarehouse,
} from "types/shipmentProductCustomerAssociation";
import { AgentWarehouseAddressModel } from "./agentWarehouseAddressSchemaModel";
export class ShipmentProductCustomerAssociationWarehouseModel {
  object: string;
  id: number;
  name: string;
  shipping_mark: string;
  operation_status: string;
  status: string;

  constructor(data: IShipmentProductCustomerAssociationWarehouse) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.shipping_mark = data.shipping_mark;
    this.operation_status = data.operation_status;
    this.status = data.status;
  }

  getId = () => this.id;
  getName = () => this.name;
  getShippingMark = () => this.shipping_mark;
  getOperationStatus = () => this.operation_status;
  getStatus = () => this.status;
}

export class ShipmentProductCustomerAssociationModel {
  object: string;
  id: number;
  unit_type: string;
  shipping_type: string;
  shipping_mode: string;
  rate: number;
  weight: null | number;
  piece: null | number;
  cbm: null | number;
  warehouse: ShipmentProductCustomerAssociationWarehouseModel;
  warehouse_address: AgentWarehouseAddressModel;

  constructor(data: IShipmentProductCustomerAssociation) {
    this.object = data.object;
    this.id = data.id;
    this.unit_type = data.unit_type;
    this.shipping_mode = data.shipping_mode;
    this.shipping_type = data.shipping_type;
    this.rate = data.rate;
    this.weight = data.weight;
    this.piece = data.piece;
    this.cbm = data.cbm;
    this.warehouse = new ShipmentProductCustomerAssociationWarehouseModel(
      data.warehouse,
    );
    this.warehouse_address = new AgentWarehouseAddressModel(
      data.warehouse_address,
    );
  }

  getId = () => this.id;
  getUnitType = () => this.unit_type;
  getShippingMode = () => this.shipping_mode;
  getShippingType = () => this.shipping_type;
  getRate = () => this.rate;
  getWeight = () => this.weight;
  getPiece = () => this.piece;
  getCbm = () => this.cbm;
  getWarehouse = () => this.warehouse;
  getWarehouseAddress = () => this.warehouse_address;
}
