import { useAtom } from "jotai";
import {
  agentCompanyCollectionAtom,
  agentCompanyShippingPointCollectionAtom,
  agentVerificationCollectionAtom,
  memberCollectionAtom,
} from "lib/core-react/store/store";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import { useState } from "react";
import { IAgentVerificationFormData } from "types/agentVerificationCollection";

export const useGetAgentCompany = () => {
  const [agentCompanies, setAgentCompanies] = useAtom(
    agentCompanyCollectionAtom,
  );

  const { shippingAgentService } = useService();

  const getAgentCompanies = async (params?: string): Promise<void> => {
    setAgentCompanies({ ...agentCompanies, isLoading: true, error: null });
    try {
      const response =
        await shippingAgentService.agentCompanyResource.get(params);
      setAgentCompanies({
        ...agentCompanies,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentCompanies({
        ...agentCompanies,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getAgentCompanies } as const;
};

export const useGetAgentCompanyMembers = (agent_company_id: number) => {
  const [agentCompanyMembers, setAgentCompanyMembers] =
    useAtom(memberCollectionAtom);

  const { shippingAgentService } = useService();

  const getAgentCompanyMembers = async (params?: string): Promise<void> => {
    setAgentCompanyMembers({
      ...agentCompanyMembers,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingAgentService.agentCompanyResource.getMembers(
          agent_company_id,
          params,
        );
      setAgentCompanyMembers({
        ...agentCompanyMembers,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentCompanyMembers({
        ...agentCompanyMembers,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getAgentCompanyMembers };
};

export const useAgentVerificationsGet = (agent_company_id: number) => {
  const [agentVerifications, setAgentVerifications] = useAtom(
    agentVerificationCollectionAtom,
  );

  const { shippingAgentService } = useService();

  const getAgentVerifications = async (params?: string): Promise<void> => {
    setAgentVerifications({
      ...agentVerifications,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingAgentService.agentCompanyResource.getVerifications(
          agent_company_id,
          params,
        );
      setAgentVerifications({
        ...agentVerifications,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentVerifications({
        ...agentVerifications,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getAgentVerifications };
};

export const useAgentVerificationUpdate = () => {
  const { shippingAgentService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [agentVerifications, setAgentVerifications] = useAtom(
    agentVerificationCollectionAtom,
  );

  const updateVerification = async (
    id: number,
    payload: IAgentVerificationFormData,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingAgentService.agentCompanyResource.updateVerification(
          id,
          payload,
        );
      setIsLoading(false);
      setAgentVerifications({ ...agentVerifications, refetch: true });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateVerification, isLoading, isError };
};

export const useGetAgentCompanyShippingPoints = (agent_company_id: number) => {
  const [agentCompanyShippingPoints, setAgentCompanyShippingPoints] = useAtom(
    agentCompanyShippingPointCollectionAtom,
  );

  const { shippingAgentService } = useService();

  const getAgentCompanyShippingPoints = async (
    params?: string,
  ): Promise<void> => {
    setAgentCompanyShippingPoints({
      ...agentCompanyShippingPoints,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingAgentService.agentCompanyResource.getShippingPoints(
          agent_company_id,
          params,
        );
      setAgentCompanyShippingPoints({
        ...agentCompanyShippingPoints,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentCompanyShippingPoints({
        ...agentCompanyShippingPoints,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getAgentCompanyShippingPoints };
};
// export const useUpdateAgentCompany = () => {
//   const { shippingAgentService } = useService();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");
//   const [agentCompanyData, setAgentCompanyData] = useAtom(
//     agentCompanyCollectionAtom
//   );

//   const updateAgentCompany = async (
//     id: number,
//     payload: AgentCompanyUpdateVerificationStatusModel
//   ) => {
//     setIsLoading(true);
//     try {
//       const response =
//         await shippingAgentService.agentCompanyResource.updateVerificationStatus(
//           id,
//           payload
//         );
//       setIsLoading(false);
//       setAgentCompanyData({ ...agentCompanyData, refetch: true });
//       return response.data;
//     } catch (error: any) {
//       setError(getError(error));
//       setIsLoading(false);
//       throw error;
//     }
//   };
//   return { updateAgentCompany, isLoading, isError };
// };
