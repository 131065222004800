import { UserOutlined } from "@ant-design/icons";
import { Divider, Typography } from "antd";
import { BuyProductViewModel } from "models/buyProductDetailModel";

interface IProps {
  productData: BuyProductViewModel | undefined;
}

const ProductSellerDetail: React.FC<IProps> = ({ productData }) => {
  const { Text } = Typography;
  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <UserOutlined
          style={{
            border: "1px solid #f7f7f7",
            width: "25px",
            height: "25px",
            padding: "4px",
            borderRadius: "100%",
          }}
        />
        <Text style={{ marginLeft: "10px" }} type="success" strong>
          {productData?.getProductSeller()?.getSellerName()}
        </Text>
      </div>

      <Divider style={{ margin: "0px 0" }} />
      <p>
        Seller Id :{" "}
        <Text strong>{productData?.getProductSeller()?.getSellerId()}</Text>
      </p>
    </>
  );
};

export default ProductSellerDetail;
