import { Table } from "antd";
const BuyProductsTable = ({
  columns,
  dataSource,
  pagination,
  loading = false,
  expandedRowRender,
  selectedRowKeys,
  setSelectedRowKeys,
  rowKey='id',
  rowClassName=""
}) => {

  return (
    <Table
      rowKey={rowKey}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      rowClassName={rowClassName}
      rowSelection={{
        selectedRowKeys,
        onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
      }}
      pagination={pagination}
      expandable={expandedRowRender}
      scroll={{x:1000}}
    />
  );
};

export default BuyProductsTable;
