import { useAtom, useAtomValue } from "jotai";
import { productDetailAtom } from "lib/core-react/store/store";
import { useLocation } from "react-router-dom";
import {
    IAddCampaignProductRawPayload,
    IPropertyValue,
    ISku,
} from "types/campaignPayload";
import { selectedSkusAtom } from "./AddRawCampaignProductFormProvider";

interface VariationPrice {
    [key: string]: number;
}

interface Configuration {
    hike_type: "percentage" | "fixed";
    price_hike: number;
    discount_type: "fixed" | "percentage";
    discount: number;
}

export interface IChangesData {
    title: string;
    variation_original_price: VariationPrice;
    variation_discount_price?: VariationPrice;
    variation_offer_price: VariationPrice;
    variation_stock: VariationPrice;
    configuration: Configuration;
}

export const useFormatPayload = () => {
    const location = useLocation();
    const region = new URLSearchParams(location.search).get("region") || "BD";
    const campaignId = new URLSearchParams(location.search).get("campaignId");

    const [{ data }] = useAtom(productDetailAtom);

    const selectedSkus = useAtomValue(selectedSkusAtom);

    const propertiesValues = data && data.variation.properties.data.length > 0
        ? data.variation.properties.data[0].values.data
        : [];

    const property_associations_Ids = selectedSkus.flatMap((sku) => {
        return sku.property_associations.data.map(
            (association) => association.property_value_id,
        );
    });

    const filteredPropertiesValues = propertiesValues.filter((propertyValue) =>
        property_associations_Ids.includes(propertyValue.id),
    );

    const propertyValueList = filteredPropertiesValues.map((item) => ({
        id: item.id,
        name: item.name,
        color: item.color,
        image: item.image,
        thumb: item.thumb,
        title: item.title,
    }));

    const skusList = selectedSkus.map((item) => {
        return {
            id: item.id,
            etag: item.etag,
            meta: {
                data: item.meta.data.map((item) => ({
                    key: item.key,
                    value: item.value,
                    object: item.object,
                })),
                object: "SkuMetaCollection",
            },
            image: item.image,
            price: {
                offer: item.price.offer,
                object: "SkuPrice",
                currency: item.price.currency,
                original: item.price.original,
                preorder: item.price.preorder,
                wholesales: {
                    data: item.price.wholesales.data.map((item) => ({
                        hike: item.hike,
                        offer: item.offer,
                        object: "WholesalePrice",
                        discount: item.discount,
                        original: item.original,
                        qty_from: item.qty_from,
                        original_raw: item.original_raw,
                        discount_raw: item.discount_raw,
                    })),
                    object: "WholesalePriceCollection",
                },
            },
            stock: {
                min: item.stock,
                limit: item.stock.limit,
                available: item.stock.available,
                availability: item.stock.availability,
                object: "SkuStock",
            },
            object: "Sku",
            vendor_id: item.vendor_id,
            property_associations: {
                data: item.property_associations.data.map((item) => ({
                    id: item.id,
                    object: "PropertyAssociation",
                    property_id: item.property_id,
                    property_value_id: item.property_value_id,
                })),
                object: "PropertyAssociationCollection",
            },
        };
    });

    const formatPayload = async (changesData : IChangesData) => {
        if (!data) {
            return null;
        }

        const payloadData: IAddCampaignProductRawPayload = {
            region: region,
            locale: "en",
            campaign_id: campaignId as string,
            product_id: data.id,
            configuration: changesData.configuration,
            changes: {
                id: data.id,
                etag: data.etag,
                meta: data.meta,
                shop: data.shop,
                slug: data.slug,
                image: data.image,
                sales: data.sales,
                title: changesData.title,
                object: data.object,
                vendor: data.vendor,
                videos: data.videos,
                ratings: data.ratings,
                price: data.price as any,
                gallery: data.gallery as any,

                //FIXME
                country: {
                    id: "2342434234",
                    code: data.country.code,
                    name: data.country.name,
                    object: data.country.object,
                },
                //FIXME
                seller: {
                    // id : data.seller.id,
                    // link : data.seller.link,
                    // name : data.seller.name,
                    // object : data.seller.object
                    id: 358019,
                    link: "https://m.1688.com/winport/3433315184508b7.html",
                    name: "\u79d1\u7ef4\u7f8e\u6570\u7801\u914d\u4ef6",
                    object: "ProductSeller",
                },
                //FIXME
                category: {
                    //   id: data.shipping_category.id,
                    //   name: data.shipping_category.name,
                    //   object: data.shipping_category.object,
                    id: "2342342344",
                    name: "Electronic",
                    object: "ProductCategory",
                },
                //FIXME
                instance: {
                    created_at: "2024-07-30T13:25:56.000000Z",
                    last_updated_at: "2024-07-30T13:25:56.000000Z",
                    // created_at: "",
                    // last_updated_at: "",
                    object: "ObjectInstance",
                },
                //FIXME
                dimension: {
                    width: {
                        unit: "kg",
                        value: 12,
                        object: "ProductWidth",
                    },
                    height: {
                        unit: "kg",
                        value: 123,
                        object: "ProductHeight",
                    },
                    length: {
                        unit: "kg",
                        value: 123,
                        object: "ProductLength",
                    },
                    object: "ProductDimension",
                    weight: {
                        unit: "kg",
                        value: 123,
                        object: "ProductWeight",
                    },
                },
                variation: {
                    object: data.object,
                    properties: {
                        data: data.variation.properties.data.map((item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                object: item.object,
                                original_name: item.actual_name,
                                values: propertyValueList as IPropertyValue[],
                            };
                        }),
                        object: "PropertyCollection",
                    },
                    skus: {
                        object: data.variation.skus.object,
                        data: skusList as ISku[],
                    },
                },

                description: data.description,
                resource_version: data.resource_version,
                specifications: data.specifications as any,
                stock: data.stock,
            },
        };

        return payloadData;
    };

    return {
        formatPayload,
    };
};
