import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";

const useNotifyRfq = () => {
  const [notify, contextHolder] = notification.useNotification();
  //-------------------------------------- notification------------------------------//
  const onNotification = ({
    status,
    reason,
    placement = "top",
  }: {
    status: "success" | "error" | "warn";
    reason: string;
    placement?: NotificationPlacement;
  }) => {
    switch (status) {
      case "success":
        notify.success({
          message: `${status}`,
          description: `${reason}`,
          placement,
        });
        break;
      case "error":
        notify.error({
          message: `${status}`,
          description: `${reason}`,
          placement,
        });
        break;
      case "warn":
        notify.warning({
          message: `${status}`,
          description: `${reason}`,
          placement,
        });
        break;
      default:
        break;
    }
  };

  return { notify, contextHolder, onNotification };
};

export default useNotifyRfq;
