import { BaseResource } from "../public";
import { BaseShippingCategoryRequestModel } from "models/baseShippingCategory";
import { ResponsePromise } from "lib/core/request/types/typings";

class BaseShippingCategoryResource extends BaseResource {
  path = `/api/shipping-core/admin/base-shipping-category/v1/base-shipping-categories`;

  create(
    payload: BaseShippingCategoryRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}?expand=children${params ? `&${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  update(
    id: string,
    payload: BaseShippingCategoryRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default BaseShippingCategoryResource;
