import CommerceApiService from "lib/core/apiServices/commerceApiService";
import { CustomerUserResource } from "../../resources/private";

class CustomerUserService extends CommerceApiService {
  public customerUserResource: CustomerUserResource;

  constructor() {
    super();

    this.customerUserResource = new CustomerUserResource(this.config);
  }
}

export default CustomerUserService;
