import { useState } from "react";
import {
  BuyProductPackageModel,
  MoveonShippingModel,
} from "../../../models/buyOrderCollectionModel";
import { Button, Card, Modal, Tag, Tooltip, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ShippingUpdateModal from "./ShippingUpdateModal";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
const { Text } = Typography;

const ShippingDetails = ({
  shippingDetails,
}: {
  shippingDetails: {
    measurements: BuyProductPackageModel;
    shipping: MoveonShippingModel;
    userId: Number;
    agentCompany: {
      director_name: string;
      primary_name: string;
      designation: string;
      verficationStatus: string;
      registered_country_detail_name: string;
      registered_country_detail_status: string;
      commission_rate_type: string;
      commission_rate: number;
      fx_rate: number;
      total: number;
    };
    region: string;
    buyProductId: Number;
  };
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Card
        title={
          <Text style={{ color: "white" }} strong>
            Purchase Agent Company
          </Text>
        }
        bordered={true}
        style={{ width: "100%", minHeight: "41px" }}
      // extra={
      //   <Button type="text" onClick={showModal}>
      //     <EditOutlined style={{ color: "white" }} />
      //   </Button>
      // }
      >
        {shippingDetails?.agentCompany &&
          Object.values(shippingDetails.agentCompany).some(
            (value) => value !== undefined,
          ) ? (
          <>
            <div style={{ marginBottom: "10px" }}>
              <div>
                <Text style={{ fontWeight: "bold" }}>
                  {shippingDetails.agentCompany.director_name +
                    "(" +
                    shippingDetails.agentCompany.primary_name +
                    ")"}
                </Text>
                {" , "}
                <Tooltip placement="top" title={"Registration Country"}>
                  <Tag color="cyan">
                    {shippingDetails.agentCompany
                      .registered_country_detail_name +
                      "(" +
                      shippingDetails.agentCompany
                        .registered_country_detail_status +
                      ")"}
                  </Tag>
                </Tooltip>
                {" , "}
                <Tooltip placement="top" title={"Commission Rate"}>
                  <Tag color="magenta">
                    {shippingDetails.agentCompany.commission_rate}
                    {shippingDetails.agentCompany.commission_rate_type ===
                      "percentage"
                      ? "%"
                      : ""}
                  </Tag>
                </Tooltip>
                {" , "}
                <Tooltip placement="top" title={"Fx Rate"}>
                  <Tag color={"#EF4444"}>
                    {shippingDetails.agentCompany.fx_rate}
                  </Tag>
                </Tooltip>
                <br />
              </div>
            </div>
          </>
        ) : (
          <Tag color="warning">No Agent Assign</Tag>
        )}
      </Card>
      <Card
        title={
          <Text style={{ color: "white" }} strong>
            Shipping
          </Text>
        }
        bordered={true}
        style={{ width: "100%" }}
        extra={
          checkActionPermission(ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
            <Button type="text" onClick={showModal}>
              <EditOutlined style={{ color: "white" }} />
            </Button>,
            null

          )
        }
      >
        <div style={{ marginBottom: "10px" }}>

          { shippingDetails?.shipping?.getShippingCategory().name ? <div>
            <Tag color="cyan">
              {shippingDetails?.shipping?.getRate()}/-{" "}
              {shippingDetails?.shipping?.getUnitType()}{" "}
            </Tag>
            ,{" "}
            <Tag color="blue">
              {shippingDetails?.shipping?.getShippingCategory().getName()}{" "}
            </Tag>
            <br />
            <Text type="success" style={{ fontWeight: "bold" }}>
              From
            </Text>{" "}
            {shippingDetails?.shipping?.getAgentWarehouse().getName()}{" "}
            <Text type="success" style={{ fontWeight: "bold" }}>
              To
            </Text>{" "}
            {shippingDetails?.shipping?.getDestinationWarehouse().getName()}
          </div> : <Tag> Shipping not selected </Tag>

          }

        </div>

        {/*<div style={{ marginBottom: '10px' }}>*/}
        {/*  <Text strong>Shipping Rate:</Text>*/}
        {/*  <div>{shippingDetails?.shipping?.getRate()}/- {shippingDetails?.shipping?.getUnitType()}</div>*/}
        {/*</div>*/}

        {/*<div style={{ marginBottom: '10px' }}>*/}
        {/*  <Text strong>Shipping Category:</Text>*/}
        {/*  <div>{shippingDetails?.shipping?.getShippingCategory().getName()}</div>*/}
        {/*</div>*/}
      </Card>

      <div style={{ display: "flex", gap: "4px", alignItems: "center" }}></div>
      <Modal
        title="Edit Shipping Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose={true}
      >
        {/* Modal content goes here */}
        <ShippingUpdateModal
          setIsModalVisible={setIsModalVisible}
          shipping={shippingDetails.shipping}
          user={shippingDetails.userId}
          region={shippingDetails.region}
          buyProductId={shippingDetails.buyProductId}
        />
      </Modal>
    </div>
  );
};

export default ShippingDetails;
