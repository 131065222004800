import { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";

import { DestinationWarehouseModel } from "models/destinationWarehouseCollectionModel";
import { CountryCollectionModel } from "models/countryCollectionModel";
import { useAtom } from "jotai";
import { regionCountry } from "lib/core-react/store/store";
import {
  useCreateDestinationWarehouse,
  useGetCountry,
  useGetDestinationWarehouse,
  useGetRegion,
  useUpdateDestinationWarehouse,
} from "lib/core-react/hooks/private";
import { DestinationWarehouseStatus } from "enums/destinationWarehouseCollectionEnums";
import { IDestinationWarehouseForm } from "types/destinationWarehouseCollection";
import { slugMaker, transformEnumToLabeledValue } from "utils/helpers";
import {showError} from "../../../../helpers/showError";

interface IProps {
  onHide: () => void;
  data?: DestinationWarehouseModel;
}

const DestinationWarehouseModification = ({ onHide, data }: IProps) => {
  const { getDestinationWarehouse } = useGetDestinationWarehouse();
  const { getCountry } = useGetCountry();
  const { getRegion } = useGetRegion();

  const [{ data: countryDataAtom, isLoading: isLoadingCountry }] =
    useAtom(regionCountry);
  const countryCollectionData =
    countryDataAtom && new CountryCollectionModel(countryDataAtom);

  const { createWarehouse, isLoading, isError } =
    useCreateDestinationWarehouse();
  const {
    updateWarehouse,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
  } = useUpdateDestinationWarehouse();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [form] = Form.useForm<IDestinationWarehouseForm>();

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldValue("name", data.getName());
      form.setFieldValue("slug", data.getSlug());
      form.setFieldValue("country_id", data.getCountry().getId());
      form.setFieldValue("status", data.getStatus());
    }
  }, [data]);

  useEffect(() => {
    getCountry("per_page=200");
    getRegion("per_page=200");
  }, []);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinishUpdate = async (value: IDestinationWarehouseForm) => {
    try {
      value.slug = slugMaker(form.getFieldValue("name"));
      data && (await updateWarehouse(data.getId(), value));
      getDestinationWarehouse();
      onHide();
      setIsErrorVisible(false);
    } catch (error) {
      showError(error, form)
      setIsErrorVisible(true);
    }
  };

  const onFinishCreate = async (value: IDestinationWarehouseForm) => {
    try {
      value.slug = slugMaker(form.getFieldValue("name"));
      await createWarehouse(value);
      getDestinationWarehouse();
      onHide();
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
      showError(error, form)
    }
  };

  return (
    <Form
      onFinish={data ? onFinishUpdate : onFinishCreate}
      form={form}
      layout="vertical"
      name={
        data ? "Update Destination Warehouse" : "Create Destination Warehouse"
      }
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Country is required" }]}
        name="country_id"
        label="Country"
      >
        <Select
          loading={isLoadingCountry}
          placeholder="Please select a country"
          options={countryCollectionData?.getCountryLabeledValue()}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Status is required" }]}
        name="status"
        label="Status"
      >
        <Select
          placeholder="Please select a status"
          options={transformEnumToLabeledValue(DestinationWarehouseStatus)}
        />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading || isLoadingUpdate}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError || isErrorUpdate}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default DestinationWarehouseModification;
