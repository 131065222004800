import { useAtom } from "jotai";
import { harvestJobCollectionAtom } from "lib/core-react/store/store";
import { useService } from "lib/core-react/contexts";
import { getError } from "../utils/errors";
import { useEffect } from "react";

export const useHarvestJobList = () => {
    const [harvestJob, setHarvestJob] = useAtom(harvestJobCollectionAtom);

    const { harvestJobService } = useService();

    const getHarvestJobList = async (): Promise<void> => {
        setHarvestJob({ ...harvestJob, isLoading: true, error: null });
        try {
            const response = await harvestJobService.harvestJobResource.get();
            setHarvestJob({
                ...harvestJob,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setHarvestJob({
                ...harvestJob,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            getHarvestJobList();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    return { getHarvestJobList } as const;
};
