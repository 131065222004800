import { Card, Descriptions } from "antd";
import type { DescriptionsProps } from "antd";

import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

export const MoveonAssociation = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  const moveonAssociation = ShipmentProductData?.getMoveonAssociation();
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Rate",
      children: moveonAssociation?.getRate(),
    },
    {
      key: "2",
      label: "Rate type",
      children: moveonAssociation?.getRateType(),
    },
    {
      key: "3",
      label: "Max capacity",
      children: moveonAssociation?.getMaxCap() || "none",
    },
    {
      key: "4",
      label: "Commission rate",
      children: moveonAssociation?.getCommissionRate() ? (
        <>
          Rate: {moveonAssociation?.getCommissionRate()?.getRate()}
          <br />
          Rate type: {moveonAssociation?.getCommissionRate()?.getRateType()}
          <br />
          Unit type: {moveonAssociation?.getCommissionRate()?.getUnitType()}
          <br />
          Max capacity:{" "}
          {moveonAssociation?.getCommissionRate()?.getMaxCap() || "none"}
        </>
      ) : (
        "Not available"
      ),
    },
  ];
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {moveonAssociation ? (
        <Descriptions bordered size="small" layout="vertical" items={items} />
      ) : (
        "Moveon association not available yet."
      )}
    </Card>
  );
};
