import CreateUpdateExchange from "../components/CreateUpdateExchange";
const CreateStoreExchange = () => {
    return (
        <CreateUpdateExchange
            initialValues={{
                regional_exchanges: [
                    {
                        store_exchanges: [
                            {
                                store_id: undefined,
                            },
                        ],
                    },
                ],
            }}
            title="Create Exchange List"
            action="create"
        />
    );
};

export default CreateStoreExchange;
