
export enum PayoutGatewaySlugEnum {
  Bkash = "bkash",
  DbbL_Bank = "dbbl-bank",
  Brac_Bank = "brac-bank",
}

export enum PayoutGatewayTypeEnum {
  Automated = "automated",
}
