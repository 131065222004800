// Enum for admin permissions in the User module
export enum ADMIN_USER_PERMISSION_ENUM {
    // Admin-user permissions
    ADMIN_USER_VIEW = "admin-user-view",
    ADMIN_USER_ADD = "admin-user-add",
    ADMIN_USER_PROFILE_UPDATE = "admin-user-profile-update",
    ADMIN_USER_UPDATE = "admin-user-update",

    // Customer-user permissions
    ADMIN_CUSTOMER_USER_VIEW = "admin-customer-user-view",
    ADMIN_CUSTOMER_USER_ADD = "admin-customer-user-add",
    ADMIN_CUSTOMER_USER_UPDATE = "admin-customer-user-update",

    // Sync all user permissions
    ADMIN_SYNC_USERS = "admin-sync-users",

    // Login-session permissions
    ADMIN_LOGIN_SESSION_VIEW = "admin-login-session-view",
    ADMIN_DISABLE_ALL_SESSION = "admin-disable-all-session",
    ADMIN_DISABLE_SESSION = "admin-disable-session"
}

