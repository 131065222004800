import { useEffect } from "react";
import { Form, InputNumber, Select } from "antd";
import { useAtom } from "jotai";

import { storeStoreAtom } from "lib/core-react/store/store";
import { transformEnumToLabeledValue } from "utils/helpers";
import { PurchaseCommissionModel } from "models/purchaseCommissionCollectionModel";
import { useGetStore } from "lib/core-react/hooks/private/useStore";
import { StoreCollectionModel } from "models/storeCollectionModel";
import { BuyProductCommissionRateTypeEnum } from "enums/purchaseCommissionCollectionEnum";

interface IProps {
    selectedCommission: PurchaseCommissionModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

export const PurchaseCommissionUpdate = ({
    selectedCommission,
    form,
    onFinish,
}: IProps) => {
    const { getStore } = useGetStore();

    const [{ data: storeCollectionDataAtom, isLoading: isLoadingStore }] =
        useAtom(storeStoreAtom);

    useEffect(() => {
        form.resetFields();
    }, [selectedCommission, form]);

    useEffect(() => {
        getStore("per_page=200");
    }, [form]);

    const StoreCollectionData =
        storeCollectionDataAtom &&
        new StoreCollectionModel(storeCollectionDataAtom);

    const initialValues = {
        store_id: selectedCommission?.store?.id,
        commission_rate: selectedCommission.commission_rate,
        commission_rate_type: selectedCommission.commission_rate_type,
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update Commission"
            initialValues={initialValues}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Store is required",
                    },
                ]}
                name="store_id"
                label="Store"
            >
                <Select
                    loading={isLoadingStore}
                    placeholder="Please select a store"
                    options={StoreCollectionData?.getStoreLabeledValue()}
                />
            </Form.Item>

            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Commission rate is required",
                    },
                ]}
                required
                name="commission_rate"
                label="Commission Rate"
            >
                <InputNumber
                    placeholder="0"
                    min={1}
                    style={{ width: "100%" }}
                />
            </Form.Item>
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Rate type is required",
                    },
                ]}
                required
                name="commission_rate_type"
                label="Rate Type"
            >
                <Select
                    placeholder="Please select a rate type"
                    options={transformEnumToLabeledValue(
                        BuyProductCommissionRateTypeEnum,
                    )}
                />
            </Form.Item>
        </Form>
    );
};
