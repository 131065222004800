import { PageHeader } from "@ant-design/pro-layout";
import {
  Badge,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

import useDataFilters from "hooks/useDataFilters";
import {
  regionRegion,
  shippingCommissionAtom,
} from "lib/core-react/store/store";
import {
  CommissionAssociationModel,
  CommissionCollectionModel,
  CommissionModel,
} from "models/commissionCollectionModel";
import { useGetCommission, useGetRegion } from "lib/core-react/hooks/private";

import { ApiHelperModel } from "models/apiHelper";
import { IFilterType } from "types/filters";

import { CommissionDetails, CopyCommissionForRegion } from "./components";
import FiltersComponent from "components/FiltersComponent";
import { renderOptionsFromEnum } from "components/Form/forms";
import { RegionModel } from "models/region";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";

const Commission = () => {
  const { getRegion } = useGetRegion();
  const { getCommission } = useGetCommission();
  const [{ data: commissionStoreData, isLoading, refetch }] = useAtom(
    shippingCommissionAtom,
  );
  const CommissionCollectionData =
    commissionStoreData && new CommissionCollectionModel(commissionStoreData);

  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  const [selectedCommission, setSelectedCommission] = useState<
    CommissionModel | undefined
  >();

  const [selectedRegion, setSelectedRegion] = useState<string | null>();

  const [selectedCommissionAssociation, setSelectedCommissionAssociation] =
    useState<CommissionAssociationModel | undefined>(undefined);
  const [isShowCopyModal, setIsShowCopyModal] = useState<boolean>(false);

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || selectedRegion) {
      ApiHelperModel.makeGetRequest(
        { region_id: selectedRegion ? selectedRegion : regionData?.data[1].id },
        getCommission,
      );
    }
    
  }, [isFirstCall, isFetched, refetch, selectedRegion]);

  // Filter
  const filterData = CommissionCollectionData?.getFilters();
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && CommissionCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
    
  }, [
    isFetched,
    initializeAvailableFilter,
    
    CommissionCollectionData?.getFilters(),
  ]);

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCommission);
  };

  useEffect(() => {
    getRegion("per_page=200");
    
  }, []);

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const onChangeCommission = (data: CommissionModel) => {
    setSelectedCommission(data);
    if (data.getCommissionAssociations().getData().length) {
      setSelectedCommissionAssociation(
        data.getCommissionAssociations().getData()[0],
      );
    } else {
      setSelectedCommissionAssociation(undefined);
    }
  };

  const selectedRegionName = regionData?.data?.find(
    (region) => region.id === Number(selectedRegion),
  )?.name;

  return (
    <>
      <PageHeader
        ghost={false}
        title="Commission"
        style={{ marginTop: "10px" }}
        extra={[
          checkActionPermission(
            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD,
            <Link
              to="/shipping/commission/create"
              key={"create-commission-link"}
            >
              <Button key="create-commission-button" type="primary">
                Create Commission
              </Button>
            </Link>,
            null,
          ),
        ]}
      >
        {filters && Object.keys(filters).length > 0 && (
          <Row>
            <Col span={24}>
              <Card title="Filter">
                <FiltersComponent
                  handleProductFilter={handleProductFilter}
                  handleFilterChange={handleFilterChange}
                  handelFilterClear={handelFilterClear}
                  isFetched={isFetched}
                  filters={filters}
                  filtersData={filterData}
                  isFromProductReceived={true}
                />
              </Card>
            </Col>
          </Row>
        )}
        <>
          <div style={{ marginBottom: 15, width: 300 }}>
            <p>Please seleted the region</p>

            <Select
              loading={regionLoading}
              placeholder="Please select a region"
              onChange={handleRegionChange}
              style={{ width: 350 }}
              value={selectedRegion ? selectedRegion : regionData?.data[1].name}
            >
              {regionData?.getRegionIdsAsObject() &&
                renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
            </Select>
          </div>
        </>

        <Card style={{ marginBottom: 15 }}>
          <h3>
            {selectedRegion ? selectedRegionName : regionData?.data[1].name}
          </h3>
          <List
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={CommissionCollectionData?.getData()}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 4,
            }}
            renderItem={(item) => (
              <List.Item key={item.getId()}>
                <Skeleton title={false} loading={isLoading} active>
                  <Badge.Ribbon
                    text="Default"
                    color={item.getIsDefault() ? "" : ""}
                    style={{
                      display: item.getIsDefault() ? "block" : "none",
                      fontSize: "10px",
                    }}
                  >
                    <Card
                      onClick={() => onChangeCommission(item)}
                      size="small"
                      style={{
                        backgroundColor: `${
                          selectedCommission &&
                          selectedCommission.getId() === item.getId()
                            ? "white"
                            : "#FAFAFA"
                        }`,
                        color: `${
                          selectedCommission &&
                          selectedCommission.getId() === item.getId()
                            ? "#1890ff"
                            : "black"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      {item.getName()}
                    </Card>
                  </Badge.Ribbon>
                </Skeleton>
              </List.Item>
            )}
          />
        </Card>

        {selectedCommission && (
          <Card
            title={`Warehouses`}
            bordered={false}
            extra={[
              checkActionPermission(
                ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_COPY,
                <Button
                  onClick={() => setIsShowCopyModal(true)}
                  key="copy-commission-for-another-region"
                  type="primary"
                >
                  Copy for another region
                </Button>,

                null,
              ),
            ]}
          >
            <Row gutter={[16, 16]}>
              {CommissionCollectionData?.getCommissionById(
                selectedCommission.getId(),
              )
                ?.getCommissionAssociations()
                .getData()
                ?.map((item) => (
                  <Col key={item.getId()} xs={24} sm={12} md={8} lg={8}>
                    <Card
                      size="small"
                      onClick={() => setSelectedCommissionAssociation(item)}
                      style={{
                        backgroundColor: `${
                          selectedCommissionAssociation &&
                          selectedCommissionAssociation.getAgentWarehouse()
                            .id === item.getAgentWarehouse().id
                            ? "white"
                            : "#FAFAFA"
                        }`,
                        color: `${
                          selectedCommissionAssociation &&
                          selectedCommissionAssociation.getAgentWarehouse()
                            .id === item.getAgentWarehouse().id
                            ? "#1890ff"
                            : "black"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      {item.getAgentWarehouse().name}
                    </Card>
                  </Col>
                ))}
            </Row>
          </Card>
        )}
        {selectedCommission &&
          selectedCommissionAssociation &&
          selectedRegion && (
            <CommissionDetails
              selectedCommissionAssociationId={selectedCommissionAssociation.getId()}
              selectedCommissionId={selectedCommission.getId()}
              regionId={Number(selectedRegion)}
            />
          )}
      </PageHeader>

      {selectedCommission && (
        <Modal
          title={`Copy Commission For Another Region`}
          open={isShowCopyModal}
          footer={false}
          onCancel={() => setIsShowCopyModal(false)}
        >
          <CopyCommissionForRegion
            setIsShowCopyModal={setIsShowCopyModal}
            selectedCommission={selectedCommission}
          />
        </Modal>
      )}
    </>
  );
};

export default Commission;
