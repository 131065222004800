import { MenuDataItem } from "@ant-design/pro-layout";
import { FormInstance } from "antd/lib";
import RenderAuthorize from "components/Authorized";
import { MOVEON_ADMIN_AUTHORITY_KEY, MOVEON_ADMIN_TOKEN_KEY, MOVEON_ADMIN_USER_KEY } from "consts/storage";
import { InvoiceStatus } from "models/invoiceCollectionModel";
import { AllStatusEnum, InvoiceItemStatusEnum, OrderTypeEnum } from "types/InvoiceCollection";
import { IUser } from "types/userCollection";


export const getStatusColor = (status: string): string => {
  if (!status) {
    return "default";
  }

  const colorMap: Record<string, string> = {
    pending: "#108ee9",
    approved: "#87d068",
    rejected: "#cd201f",
    reverted: "#f50",
  };

  return colorMap[status.toLowerCase()] || "#87d068";
};

export const getStatusColorForActiveandInative = (status: string): string => {
  if (!status) {
    return "default";
  }

  const colorMap: Record<string, string> = {
    active: "#87d068",
    inactive: "#f50",
  };

  return colorMap[status.toLowerCase()] || "#87d068";
};

export const processNumberValue = (value: string | number) => {
  const numberValue = Number(value);
  const IntValue = Number.parseInt(String(numberValue));
  if (numberValue === IntValue) {
    return String(IntValue);
  }
  return numberValue.toFixed(2);
};
export const processPriceValue = (value: string | number) => {
  const numberValue = Math.ceil(Number(value));
  return String(numberValue);
};

export const generateColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hex = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - hex.length) + hex;
};

type Status =
  | InvoiceItemStatusEnum
  | OrderTypeEnum
  | AllStatusEnum
  | InvoiceStatus
  | string

export const getStatusBadgeColor = (status: Status): string => {
  switch (status) {
    case AllStatusEnum.UNPAID:
      return "red";
    case AllStatusEnum.PARTIAL_PAID:
      return "#FFEFEE";
    case AllStatusEnum.PAID:
      return "#00893c";
    case AllStatusEnum.UNSETTLED:
      return "blue";
    case AllStatusEnum.PARTIALLY_SETTLED:
      return "purple";
    case AllStatusEnum.SETTLED:
      return "cyan";
    case AllStatusEnum.AWAITING_FULFILMENT:
      return "gold";
    case AllStatusEnum.FULFILLED:
      return "lime";
    case AllStatusEnum.PARTIALLY_REFUNDED:
      return "magenta";
    case AllStatusEnum.REFUNDED:
      return "volcano";
    case AllStatusEnum.ONLY_SHIP:
      return "#0098B9";
    case AllStatusEnum.BUY_AND_SHIP:
      return "#527be7";
    case "pending":
      return "lime";
    case "open-for-everyone":
    case "active":
      return "#24b263";
    default:
      return "gray";
  }
};

export const capitalize = (text: string | undefined | null): string => {
  if (!text) return "";

  return text.toLowerCase().replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
};

export const truncateTitle = (title: string, maxLength: number) => {
  if (title && title.length > maxLength) {
    return title.substring(0, maxLength) + "...";
  }
  return title;
};


/**
 * Whether the current pathname exists in the route, if so, returns the current object, if not, it is undefined
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends MenuDataItem>(
  router: T[] = [],
  pathname: string,
): T | undefined => {
  const authority = router.find(
    ({children, path = '/'}) =>
      (path && path === pathname) ||
      (children && getAuthorityFromRouter(children, pathname)),
  );
  if (authority) return authority;
  return undefined;
};

export function getAuthority(str?: string): string | string[] {
  const authorityString = typeof str === 'undefined' && localStorage ? localStorage.getItem(MOVEON_ADMIN_AUTHORITY_KEY) : str;
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }

  // If it is a string, return an array
  if (typeof authority === 'string') {
    return [authority];
  }
  if (!authority) {
    return [];
  }
  return authority;
}

/**
 * Setting Authority
 * @param authority
 */
export function setAuthority(authority: string | string[]): void {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  localStorage.setItem(MOVEON_ADMIN_AUTHORITY_KEY, JSON.stringify(proAuthority));
  // auto reload
  reloadAuthorized();
}

export let Authorized = RenderAuthorize(getAuthority());

export const reloadAuthorized = (): void => {
  Authorized = RenderAuthorize(getAuthority());
};

export const getUserTokenFromLocalStorage=()=>{
  return localStorage.getItem(MOVEON_ADMIN_TOKEN_KEY)
}
export const getUserFromLocalStorage=():IUser | null =>{
  const userJon = localStorage.getItem(MOVEON_ADMIN_USER_KEY)
  if(userJon){
    return JSON.parse(userJon) as IUser
  }else{
    return null
  }
}


export const getUserPermissionFromLocalStorage = (): string[] => {
  const authority = localStorage.getItem(MOVEON_ADMIN_AUTHORITY_KEY);
  return authority ? JSON.parse(authority) as string[] : [];
};


/**
 * Handles setting form errors from an API response.
 *
 * @param {FormInstance} form - The Ant Design form instance.
 * @param {Object} errorResponse - The error response object from the API.
 */
export const handleFormErrors = (form: FormInstance, errorResponse) => {
  if (errorResponse?.data?.errors) {
    const errors = errorResponse.data.errors;
    const errorFields = Object.keys(errors).map((field) => ({
      name: field,
      errors: errors[field],
    }));
    form.setFields(errorFields);
  }
};

export const getEnvironmentMode = ():"development" | "production" | string=>{
  return process.env.REACT_APP_NODE_ENV || "production"
}
