import { Config } from "../request/types/request";
import { ServiceBaseUrl, ServiceNameEnum } from "./servicBaseUrl";
class CommerceApiService {
  public config: Config;

  constructor() {
    this.config = {
      baseUrl: ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.COMMERCE_SERVICE),
      apiKey: "",
      maxRetries: 0,
      publishableApiKey: "",
    };

  }
}

export default CommerceApiService;
