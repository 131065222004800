import {
  MOVEON_ADMIN_AUTHORITY_KEY,
  MOVEON_ADMIN_TOKEN_KEY,
  MOVEON_ADMIN_USER_KEY,
} from "consts/storage";
import { getPermissionList, userRoleData } from "data/userRoleData";
import OryAuthProvider from "lib/core-react/contexts/OryAuthProvider";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "routes/Routes";

function setTokenIfNotExists() {
  const token = localStorage.getItem(MOVEON_ADMIN_TOKEN_KEY);

  if (!token) {
    localStorage.setItem(MOVEON_ADMIN_TOKEN_KEY, `${token}`);
    localStorage.setItem(MOVEON_ADMIN_USER_KEY, JSON.stringify(userRoleData));
    localStorage.setItem(
      MOVEON_ADMIN_AUTHORITY_KEY,
      JSON.stringify(getPermissionList()),
    );
  }
}

function App() {
  useEffect(() => {
    setTokenIfNotExists();
  }, []);
  return (
    <BrowserRouter>
      <OryAuthProvider>
        <AllRoutes />
      </OryAuthProvider>
    </BrowserRouter>
  );
}

export default App;
