import {
  DiscountAmountTypeEnum,
  DiscountCollectionEnum,
  DiscountLimitationTypeEnum,
  DiscountRuleCollectionEnum,
  DiscountStatusEnum,
} from "enums/discountCollectionEnum";
import {
  IDiscount,
  IDiscountCollection,
  IDiscountRegion,
  IDiscountRule,
  IDiscountRuleCollection,
} from "types/discountCollection";
import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";

export class DiscountRuleModel {
  object: DiscountRuleCollectionEnum.DiscountRule;
  id: number;
  rule_identifier: string;
  metadata: IFilterType;
  created_at: string;

  constructor(dataItem: IDiscountRule) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.rule_identifier = dataItem.rule_identifier;
    this.metadata = dataItem.metadata;
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getRuleIdentifier = () => this.rule_identifier;
  getMetaData = () => this.metadata;
  getCreatedAt = () => this.created_at;
}

export class DiscountRuleCollectionModel {
  object: DiscountRuleCollectionEnum.DiscountRuleCollection;
  data: DiscountRuleModel[];

  constructor(dataItem: IDiscountRuleCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new DiscountRuleModel(d));
  }

  getData = () => this.data;
}

export class DiscountRegionModel {
  object: string;
  id: number;
  name: string;
  code: string;
  constructor(data: IDiscountRegion) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
  }

  getId = () => this.id;
  getName = () => this.name;
  getCode = () => this.code;
}
export class DiscountModel {
  object: DiscountCollectionEnum.Discount;
  id: number;
  name: string;
  amount_type: DiscountAmountTypeEnum;
  amount: number;
  maximum_discount_amount: number;
  start_date: string;
  end_date: string;
  limitation_type: DiscountLimitationTypeEnum;
  limitation_times: number;
  coupon_code: string;
  status: DiscountStatusEnum;
  discount_rules: DiscountRuleCollectionModel;
  created_at: string;
  region: DiscountRegionModel;

  constructor(dataItem: IDiscount) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.amount_type = dataItem.amount_type;
    this.amount = dataItem.amount;
    this.maximum_discount_amount = dataItem.maximum_discount_amount;
    this.start_date = dataItem.start_date;
    this.end_date = dataItem.end_date;
    this.limitation_type = dataItem.limitation_type;
    this.limitation_times = dataItem.limitation_times;
    this.coupon_code = dataItem.coupon_code;
    this.status = dataItem.status;
    this.discount_rules = new DiscountRuleCollectionModel(
      dataItem.discount_rules,
    );
    this.created_at = dataItem.created_at;
    this.region = new DiscountRegionModel(dataItem.region);
  }

  getId = () => this.id;
  getName = () => this.name;
  getAmountType = () => this.amount_type;
  getAmount = () => this.amount;
  getMaximumDiscountAmount = () => this.maximum_discount_amount;
  getStartDate = () => this.start_date;
  getEndDate = () => this.end_date;
  getLimitationType = () => this.limitation_type;
  getLimitationTimes = () => this.limitation_times;
  getCouponCode = () => this.coupon_code;
  getStatus = () => this.status;
  getDiscountRules = () => this.discount_rules;
  getCreatedAt = () => this.created_at;
  getRegion = () => this.region;
}

export class DiscountCollectionModel {
  public readonly object: DiscountCollectionEnum.DiscountCollection;
  public readonly data: DiscountModel[];
  public readonly filters: IFilterType;
  public readonly pagination: PaginationModel;

  constructor(data: IDiscountCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new DiscountModel(item));
    this.filters = data.filters;
    this.pagination = new PaginationModel(data.pagination);
  }
  getDiscountById(id: number): DiscountModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getData() {
    return this.data;
  }
  getPagination() {
    return this.pagination;
  }
  getFilters() {
    return this.filters;
  }
}
