import { Button, Input } from "antd";
import { rgba } from "polished";
import styled from "styled-components";

const { Search } = Input;

export const StyledProductHeader = styled.div`
    display: grid;
    width: 100%;
    height:100%;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background.paper};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        justify-items: end;
        grid-template-columns: repeat(12, 1fr);
    }
`;

export const StyledProductHeaderLeft = styled.div`
    grid-column: span 2;
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    & h3 {
        font-weight: ${({ theme }) => theme.font.weight.bold};
    }
`;

export const StyledProductHeaderShowingText = styled.span`
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
        display: block;
    }
`;

export const StyledProductHeaderRight = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: end;
    align-items: center;
    grid-column: span 10;
    justify-content: flex-end;
    align-items: center;
    // border : 1px solid red;
    width:100%;
    gap: 12px;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
     display: flex;
    }
`;
export const StyledProductHeaderSearchContainer = styled.div`
    display: flex;
    flex-direction : column;
    gap: 12px;
    justify-content: flex-end;
    width : 100%;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
     flex-direction : row;
    }

`;

export const StyledProductHeaderSearch = styled(Search)`
    width: 100%;
    & .ant-input {
        padding: 8px 14px;
        height: 36px;
        width: 100%;
    }

    & .ant-input-search-button {
        height: 36px;
        width: 36px;
        box-shadow: none;

        & .anticon svg {
            display: block;
        }
    }   @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
     display: flex;
    }
`;

export const StyledProductHeaderBtn = styled(Button)`
    border: 0 none;
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: none;
    border-radius: ${({ theme }) => theme.sizes.borderRadius.circle};
    width: 40px;
    height: 40px;
    padding: 10px;
    font-size: 20px;

    &:hover,
    &:focus {
        background-color: ${() => rgba("black", 0.1)};
        color: ${({ theme }) => theme.palette.text.primary};
    }

    &.active {
        color: ${({ theme }) => theme.palette.primary.main};

        &:hover,
        &:focus {
            background-color: ${() => rgba("black", 0.2)};
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;
