import { Card, Descriptions, List, Space, Tag, Typography } from "antd";
import type { DescriptionsProps } from "antd";

import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";
import { formatDateTime } from "utils/helpers";

export const Delivery = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  return (
    <>
      {ShipmentProductData?.getDeliveryEnrollments()?.getData().length === 0 ? (
        <Card title={title} bordered={false} loading={isLoading}>
          No data available yet.
        </Card>
      ) : (
        <Card title={title} bordered={true}>
          <List pagination={{ pageSize: 3 }} loading={isLoading}>
            {ShipmentProductData?.getDeliveryEnrollments()
              ?.getData()
              .map((data) => {
                const items: DescriptionsProps["items"] = [
                  {
                    key: "created_at",
                    label: "Created At",
                    children: formatDateTime(data.getCreatedAt()),
                  },
                  {
                    key: "request_number",
                    label: "Request Number",
                    children: (
                      <Typography.Text copyable>
                        {data.getDeliveryRequest().getRequestNumber()}
                      </Typography.Text>
                    ),
                  },
                  {
                    key: "status",
                    label: "Status",
                    children: data.getDeliveryRequest().getStatus() ? (
                      <Tag
                        style={{ textTransform: "capitalize" }}
                        color={
                          ShipmentProductData.getAgentAssociation()
                            ?.getWarehouse()
                            .getVerificationStatus() === "delivery-pending"
                            ? "red"
                            : "green"
                        }
                      >
                        {data.getDeliveryRequest().getStatus()}
                      </Tag>
                    ) : (
                      ""
                    ),
                  },

                  {
                    key: "total_quantity",
                    label: "Quantity",
                    children: data.getDeliveryRequest().getTotalQuantity(),
                  },
                  {
                    key: "total_weight",
                    label: "Weight",
                    children: data.getDeliveryRequest().getTotalWeight(),
                  },
                  {
                    key: "total_height",
                    label: "Measurement (Height x Length x Width)",
                    children: `${data.getDeliveryRequest().getTotalHeight()} x ${data.getDeliveryRequest().getTotalLength()} x ${data.getDeliveryRequest().getTotalWidth()}`,
                  },
                  {
                    key: "courier_option",
                    label: "Courier",
                    children: (
                      <Space style={{ display: "flex", flexWrap: "wrap" }}>
                        <Space direction="horizontal">
                          <Typography.Text strong>Name:</Typography.Text>
                          <Typography.Text>
                            {data
                              .getDeliveryRequest()
                              .getCourierOption()
                              .getName()}
                          </Typography.Text>
                        </Space>
                        <Space direction="horizontal">
                          <Typography.Text strong>Flat Amount:</Typography.Text>
                          <Typography.Text>
                            {data
                              .getDeliveryRequest()
                              .getCourierOption()
                              .getFlatAmount()}
                          </Typography.Text>
                        </Space>
                        <Space direction="horizontal">
                          <Typography.Text strong>Price Type:</Typography.Text>
                          <Typography.Text>
                            {data
                              .getDeliveryRequest()
                              .getCourierOption()
                              .getPriceType()}
                          </Typography.Text>
                        </Space>
                      </Space>
                    ),
                  },
                  {
                    key: "shipping_address",
                    label: "Shipping Address",
                    children: (
                      <div
                        style={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                      >
                        {data.getDeliveryRequest().getShippingAddress() &&
                        Object.keys(
                          data.getDeliveryRequest().getShippingAddress(),
                        ).length
                          ? Object.keys(
                              data.getDeliveryRequest().getShippingAddress(),
                            ).map((key) => (
                              <div key={key}>
                                <Typography.Text
                                  italic
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: 500,
                                  }}
                                >
                                  {key.replaceAll("_", " ")}:
                                </Typography.Text>{" "}
                                {
                                  data
                                    .getDeliveryRequest()
                                    .getShippingAddress()[key]
                                }
                              </div>
                            ))
                          : "Address not found"}
                      </div>
                    ),
                  },
                ];
                return (
                  <Descriptions
                    key={data.getId()}
                    bordered
                    size="small"
                    layout="vertical"
                    items={items}
                  />
                );
              })}
          </List>
        </Card>
      )}
    </>
  );
};
