import { PageHeader } from "@ant-design/pro-layout";
import {
    Badge,
    Button,
    Card,
    Col,
    List,
    Modal,
    Row,
    Skeleton,
    Typography,
    Table,
    Tag,
    Dropdown,
    Popconfirm,
    message,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";

import useDataFilters from "hooks/useDataFilters";
import { GetApiHelperModel } from "models/apiHelper";
import { storeExchangeAtom } from "lib/core-react/store/store";
import {
    useDeleteExchange,
    useGetExchange,
} from "lib/core-react/hooks/private/useExchange";
import {
    ExchangeListCollectionModel,
    ExchangeListModel,
    RegionalExchangeModel,
    StoreExchangeModel,
} from "models/exchangeListCollectionModel";
import { IFilterType } from "types/filters";
import CopyExchange from "./components/CopyExchange";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { CommissionRateTypeEnums } from "../../../enums/exchangeListCollectionEnums";
import {
    DeleteFilled,
    DownOutlined,
    EditOutlined,
    HddFilled,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import UpdateExchange from "./components/UpdateExchange";
import { capitalize } from "../../../utils/helper";
import StoreRateUpdate from "./components/StoreRateUpdate";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_STORE_PERMISSION_ENUM } from "consts/permission-enum/admin-store-enum";
import CommonError from "components/Error/CommonError";

const { Text } = Typography;
type ModalForType = "exchange-update" | "exchange-copy" | "rate-update";

const StoreExchange = () => {
    const { getExchange } = useGetExchange();
    const navigate = useNavigate();
    const { deleteExchange } = useDeleteExchange();
    const [
        { data: exchangeStoreData, isLoading, refetch, error, unAuthorized },
    ] = useAtom(storeExchangeAtom);
    const [selectedExchangeStore, setSelectedExchangeStore] = useState<
        StoreExchangeModel | undefined
    >(undefined);

    const [selectedRegionData, setSelectedRegionData] = useState<
        RegionalExchangeModel | undefined
    >(undefined);
    const [deletePopConfirmOpen, setDeletePopConfirmOpen] = useState(false);
    const [modalFor, setModalFor] = useState<ModalForType>("exchange-update");
    const ExchangeListCollectionData =
        exchangeStoreData && new ExchangeListCollectionModel(exchangeStoreData);

    const { isFirstCall, isFetched, initializeAvailableFilter } =
        useDataFilters();
    const [selectedExchange, setSelectedExchange] = useState<
        ExchangeListModel | undefined
    >(undefined);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            GetApiHelperModel.makeGetRequest({}, getExchange);
        }
    }, [isFirstCall, isFetched, refetch]);

    // Filter
    const filterData = ExchangeListCollectionData?.getFilters();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && ExchangeListCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        ExchangeListCollectionData?.getFilters(),
    ]);

    useEffect(() => {
        if (selectedExchange) {
            setSelectedRegionData(
                selectedExchange.getRegionalExchanges().getData()[0],
            );
        }
    }, [selectedExchange]);

    useEffect(() => {
        if (exchangeStoreData) {
            const collections = new ExchangeListCollectionModel(
                exchangeStoreData,
            );
            setSelectedExchange(collections.getData()[0]);
        }
    }, [exchangeStoreData]);

    const onChangeExchange = (data: ExchangeListModel) => {
        setSelectedExchange(data);
    };

    const getModelTitle = (modelFor: ModalForType) => {
        switch (modelFor) {
            case "exchange-update":
                return `Update Exchange (${selectedExchangeStore?.getStore().name})`;
            case "exchange-copy":
                return "Copy Exchange";
            case "rate-update":
                return `Update Rate (${selectedExchangeStore?.getStore().name})`;
            default:
                return "";
        }
    };

    const getModalContent = () => {
        if (
            modalFor === "exchange-update" &&
            selectedExchangeStore &&
            selectedExchange
        ) {
            return (
                <UpdateExchange
                    selectedExchange={selectedExchange}
                    setIsShowUpdateModal={setIsShowModal}
                    selectedStore={selectedExchangeStore}
                />
            );
        } else if (modalFor === "exchange-copy" && selectedExchange) {
            return (
                <CopyExchange
                    selectedExchange={selectedExchange}
                    setIsShowCopyModal={setIsShowModal}
                />
            );
        } else if (
            modalFor === "rate-update" &&
            selectedExchangeStore &&
            selectedExchange &&
            selectedRegionData
        ) {
            return (
                <StoreRateUpdate
                    selectedExchange={selectedExchange}
                    setIsShowUpdateModal={setIsShowModal}
                    selectedStore={selectedExchangeStore}
                    selectedRegion={selectedRegionData}
                />
            );
        } else {
            return <div>Please select a proper action </div>;
        }
    };

    const exchangeColumns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 70,
            key: "exchange-id",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getId()}</div>;
            },
        },
        {
            title: "Store",
            dataIndex: "store",
            key: "exchange-store",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getStore().getName()}</div>;
            },
        },
        {
            title: "FX Rate",
            dataIndex: "fx_rate",
            hidden: isMobile,
            key: "exchange-fx-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getFxRate()}</div>;
            },
        },
        {
            title: "Commission Rate",
            dataIndex: "commission_rate",
            hidden: isMobile,
            key: "exchange-commission-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div key={record.getId()}>
                        {record.getCommissionRate()}{" "}
                        {record.getCommissionRateType() ===
                        CommissionRateTypeEnums.PERCENTAGE
                            ? "%"
                            : ""}{" "}
                    </div>
                );
            },
        },
        {
            title: "Total",
            dataIndex: "commission_rate_type",
            hidden: isMobile,
            key: "exchange-commission-rate-total",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <Tag key={record.getId()} color="green">
                        {record.getTotal()}
                    </Tag>
                );
            },
        },
        {
            title: "Exchange Rates",
            hidden: !isMobile,
            key: "exchange-rates",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div key={record.getId()}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            minWidth: "150px",
                        }}
                    >
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                FX Rate:
                            </Text>
                            <Text>{record.getFxRate()}</Text>
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Commission:
                            </Text>
                            <Text>
                                {record.getCommissionRate()}{" "}
                                {record.getCommissionRateType() ===
                                CommissionRateTypeEnums.PERCENTAGE
                                    ? "%"
                                    : ""}
                            </Text>
                        </div>
                        <div>
                            <Text strong style={{ marginRight: "5px" }}>
                                Total:
                            </Text>
                            <Tag color="green">{record.getTotal()}</Tag>
                        </div>
                    </div>
                );
            },
        },

        {
            title: "Minimum Order Amount",
            dataIndex: ["options", "minimum_order_amount"],
            key: "commission-rate-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getMinimumOrderAmount()}</div>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            align: "center" as const,
            width: 100,
            render: (_: string, record: StoreExchangeModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_UPDATE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedExchangeStore(record);
                            setIsShowModal(true);
                            setModalFor("exchange-update");
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];

                if (selectedExchange && selectedExchange.getIsDefault()) {
                    items.push({
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_UPDATE,
                        label: "Update rate",
                        key: "update-rate",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedExchangeStore(record);
                            setIsShowModal(true);
                            setModalFor("rate-update");
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    // Delete exchange
    const deleteExchangeListHandler = async () => {
        if (selectedExchange) {
            try {
                const res = await deleteExchange(selectedExchange.getId());
                if (res) {
                    message.success(res.message);
                    setDeletePopConfirmOpen(false);
                }
            } catch (e: any) {
                const errorMessage = e?.response?.data?.message
                    ? e?.response?.data?.message
                    : e.message;
                message.error(errorMessage);
            }
        }
    };
    // Error handle
    if (error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Exchange List"
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_ADD,
                        <Link key={1} to="/store-manage/store-exchanges/create">
                            <Button key="create-exchange-button" type="primary">
                                Create Exchange List
                            </Button>
                        </Link>,
                        <></>,
                    ),
                ]}
                onBack={() => window.history.back()}
            >
                <Row>
                    <Col span={24}>
                        <Card
                            extra={
                                <div>
                                    {selectedExchange
                                        ? [
                                              checkActionPermission(
                                                  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_DELETE,
                                                  <Popconfirm
                                                      title={
                                                          <div>
                                                              Delete{" "}
                                                              <span
                                                                  style={{
                                                                      color: "red",
                                                                  }}
                                                              >
                                                                  {selectedExchange.getName()}
                                                              </span>{" "}
                                                              Exchange list
                                                          </div>
                                                      }
                                                      open={
                                                          deletePopConfirmOpen
                                                      }
                                                      icon={
                                                          <QuestionCircleOutlined
                                                              style={{
                                                                  color: "red",
                                                              }}
                                                          />
                                                      }
                                                      description="Are you sure to delete?"
                                                      onConfirm={
                                                          deleteExchangeListHandler
                                                      }
                                                      onCancel={() =>
                                                          setDeletePopConfirmOpen(
                                                              false,
                                                          )
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                  >
                                                      <Button
                                                          onClick={() =>
                                                              setDeletePopConfirmOpen(
                                                                  true,
                                                              )
                                                          } 
                                                          type="link"
                                                      >
                                                          <DeleteFilled
                                                              style={{
                                                                  color: "red",
                                                              }}
                                                          />
                                                      </Button>
                                                  </Popconfirm>,

                                                  null,
                                              ),
                                          ]
                                        : undefined}
                                    <Button
                                        onClick={() => {
                                            if (selectedExchange) {
                                                navigate(
                                                    `/store-manage/store-exchanges/update/${selectedExchange.getId()}`,
                                                );
                                            }
                                        }}
                                        type="link"
                                    >
                                        <EditOutlined
                                            style={{
                                                color: "white",
                                            }}
                                        />
                                    </Button>
                                </div>
                            }
                            style={{ margin: "10px 0px" }}
                            title="Item List"
                        >
                            <List
                                loading={isLoading}
                                itemLayout="horizontal"
                                dataSource={ExchangeListCollectionData?.getData()}
                                grid={{
                                    gutter: 16,
                                    xs: 2,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 5,
                                    xxl: 6,
                                }}
                                renderItem={(item) => (
                                    <List.Item key={item.getId()}>
                                        <Skeleton
                                            title={false}
                                            loading={isLoading}
                                            active
                                        >
                                            <div
                                                // key={item.getId()}
                                                onClick={() =>
                                                    onChangeExchange(item)
                                                }
                                                style={{
                                                    padding: "8px 0px",
                                                    textAlign: "center",
                                                    backgroundColor: `${
                                                        selectedExchange &&
                                                        selectedExchange.getId() ===
                                                            item.getId()
                                                            ? "white"
                                                            : "#FAFAFA"
                                                    }`,
                                                    border: `${
                                                        selectedExchange &&
                                                        selectedExchange.getId() ===
                                                            item.getId()
                                                            ? "2px solid #DFFFED"
                                                            : "1px solid #FAFAFA"
                                                    }`,
                                                    fontWeight: "bold",
                                                    color: `${
                                                        selectedExchange &&
                                                        selectedExchange.getId() ===
                                                            item.getId()
                                                            ? "black"
                                                            : "black"
                                                    }`,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item.getIsDefault() ? (
                                                    <Badge.Ribbon
                                                        text="Default"
                                                        style={{
                                                            fontSize: "12px",
                                                            height: "21px",
                                                            lineHeight: "16px",
                                                            padding: "2px 10px",
                                                        }}
                                                    >
                                                        {item.getName()}
                                                    </Badge.Ribbon>
                                                ) : (
                                                    item.getName()
                                                )}
                                            </div>
                                        </Skeleton>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    {selectedExchange && (
                        <Col span={24}>
                            <Card
                                style={{ margin: "10px 0px" }}
                                title={`${capitalize(selectedExchange.getName())} -> Region`}
                            >
                                <List
                                    loading={isLoading}
                                    itemLayout="horizontal"
                                    dataSource={selectedExchange
                                        .getRegionalExchanges()
                                        .getData()}
                                    grid={{
                                        gutter: 16,
                                        xs: 2,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 5,
                                        xxl: 6,
                                    }}
                                    renderItem={(item) => (
                                        <List.Item key={item.getId()}>
                                            <div
                                                key={item.getId()}
                                                onClick={() =>
                                                    setSelectedRegionData(item)
                                                }
                                                style={{
                                                    padding: "8px 0px",
                                                    textAlign: "center",
                                                    backgroundColor: `${
                                                        selectedRegionData &&
                                                        selectedRegionData.getId() ===
                                                            item.getId()
                                                            ? "white"
                                                            : "#FAFAFA"
                                                    }`,
                                                    border: `${
                                                        selectedRegionData &&
                                                        selectedRegionData.getId() ===
                                                            item.getId()
                                                            ? "2px solid #DFFFED"
                                                            : "1px solid #FAFAFA"
                                                    }`,
                                                    fontWeight: "bold",
                                                    color: `${
                                                        selectedRegionData &&
                                                        selectedRegionData.getId() ===
                                                            item.getId()
                                                            ? "black"
                                                            : "black"
                                                    }`,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item.getRegion().name}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Col>
                    )}
                </Row>
                {selectedExchange && selectedRegionData && (
                    <Card
                        title={`${capitalize(selectedExchange.getName())} -> ${selectedRegionData.getRegion().name} -> Store`}
                        bordered={false}
                        extra={[
                            checkActionPermission(
                                ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_COPY,
                                <Button
                                    onClick={() => {
                                        setIsShowModal(true);
                                        setModalFor("exchange-copy");
                                    }}
                                    key="copy-commission-for-another-region"
                                    type="primary"
                                >
                                    Create a Copy
                                </Button>,

                                null,
                            ),
                        ]}
                    >
                        <Table
                            rowKey="id"
                            bordered
                            loading={isLoading}
                            dataSource={selectedRegionData
                                .getStoreExchanges()
                                .getData()}
                            //@ts-ignore
                            columns={exchangeColumns}
                            scroll={{ x: 1000 }}
                            pagination={{ pageSize: 20 }}
                        />
                    </Card>
                )}
            </PageHeader>

            {selectedExchange && (
                <Modal
                    title={getModelTitle(modalFor)}
                    open={isShowModal}
                    destroyOnClose={true}
                    footer={false}
                    onCancel={() => setIsShowModal(false)}
                >
                    {getModalContent()}
                </Modal>
            )}
        </>
    );
};

export default StoreExchange;
