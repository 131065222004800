import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Comment } from "@ant-design/compatible";
import {
  Avatar,
  Collapse,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { TrackingEventModel } from "models/timelineTrackingModel";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { formatDateTime } from "utils/helpers";
import { SelectLabelValue } from "./utils";

interface IProps {
  data: TrackingEventModel;
  handleDeleteComment: (data: TrackingEventModel) => Promise<void>;
  setCommentContent: (value: SetStateAction<string>) => void;
  setSpecifiedUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  setRoleIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  setSelectedCommentIdToUpdate: (
    value: SetStateAction<number | undefined>,
  ) => void;
  setSelectedCommentIdToReply: (
    value: SetStateAction<number | undefined>,
  ) => void;
  isLoadingDeleteComment: boolean;
}

export const RenderComment = ({
  data,
  handleDeleteComment,
  setCommentContent,
  setSpecifiedUserIds,
  setRoleIds,
  setSelectedCommentIdToReply,
  setSelectedCommentIdToUpdate,
  isLoadingDeleteComment,
}: IProps) => {
  const renderChildrenComments = (comments: TrackingEventModel[]) => {
    return comments.map((comment) => (
      <Comment
        datetime={
          <Tooltip title={formatDateTime(comment.getCreatedAt())}>
            <span>{moment(comment.getCreatedAt()).fromNow()}</span>
          </Tooltip>
        }
        key={comment.getId()}
        actions={[
          <span
            onClick={() => {
              setCommentContent(comment.getMessage() || "");
              setSelectedCommentIdToUpdate(comment.getId());
            }}
          >
            <EditOutlined />
          </span>,
          <Popconfirm
            icon={<WarningOutlined />}
            title="Are you sure you want to delete this reply?"
            onConfirm={() => handleDeleteComment(comment)}
            okText="Delete"
            cancelText="No"
            okType="danger"
            okButtonProps={{
              loading: isLoadingDeleteComment,
            }}
          >
            <span onClick={(e) => e.stopPropagation()}>
              <DeleteOutlined />
            </span>
          </Popconfirm>,
          <span
            key="comment-basic-reply-to"
            onClick={() => {
              setSelectedCommentIdToReply(comment.getId());
            }}
          >
            Reply
          </span>,
        ]}
        author="Admin"
        avatar={
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
        }
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: comment.getMessage()!,
            }}
          />
        }
      >
        {comment.getChildren() && comment.getChildren().length > 0 && (
          <CommentList comments={comment.getChildren()} />
        )}
      </Comment>
    ));
  };

  const CommentList = ({ comments }: { comments: TrackingEventModel[] }) => {
    return (
      <>
        <Collapse
          accordion
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <CollapsePanel
            header={`${comments.length} ${
              comments.length > 1 ? "more replies" : "reply"
            }`}
            key="1"
          >
            {renderChildrenComments(comments)}
          </CollapsePanel>
        </Collapse>
      </>
    );
  };

  return (
    <Comment
      datetime={
        <Tooltip title={formatDateTime(data.getCreatedAt())}>
          <span>{moment(data.getCreatedAt()).fromNow()}</span>
        </Tooltip>
      }
      actions={[
        <span
          onClick={() => {
            setRoleIds(
              data
                .getAcls()
                .getRoleData()
                .map((d) => {
                  return {
                    label: d.getAclValue().getName(),
                    value: d.getAclValue().getId().toString(),
                  };
                }),
            );

            setSpecifiedUserIds(
              data
                .getAcls()
                .getUserData()
                .map((d) => {
                  return {
                    label: d.getAclValue().getName(),
                    value: d.getAclValue().getId().toString(),
                  };
                }),
            );

            setCommentContent(data.getMessage() || "");
            setSelectedCommentIdToUpdate(data.getId());
          }}
        >
          <EditOutlined />
        </span>,
        <Popconfirm
          icon={<WarningOutlined />}
          title="Are you sure you want to delete this comment?"
          onConfirm={() => handleDeleteComment(data)}
          okText="Delete"
          cancelText="No"
          okType="danger"
          okButtonProps={{
            loading: isLoadingDeleteComment,
          }}
        >
          <span onClick={(e) => e.stopPropagation()}>
            <DeleteOutlined />
          </span>
        </Popconfirm>,
        <span
          key="comment-basic-reply-to"
          onClick={() => {
            setSelectedCommentIdToReply(data.getId());
          }}
        >
          Reply
        </span>,
      ]}
      author="Admin"
      avatar={
        <Avatar
          style={{ backgroundColor: "#87d068" }}
          icon={<UserOutlined />}
        />
      }
      content={
        <Space direction="vertical">
          <div
            dangerouslySetInnerHTML={{
              __html: data.getMessage()!,
            }}
          />
          {data.getAcls().getData().length ? (
            <Typography.Text type="secondary">
              <Tag color="success">Notified:</Tag>{" "}
              {data
                .getAcls()
                .getData()
                .map((item) => item.getAclValue().getName())
                .join(", ")}
            </Typography.Text>
          ) : (
            ""
          )}
        </Space>
      }
    >
      {data.getChildren().length ? (
        <CommentList comments={data.getChildren()} />
      ) : (
        ""
      )}
    </Comment>
  );
};
