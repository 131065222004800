import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

import { ProductDetailModel } from "models/productDetailCollectionModel";
import { CampaignProductModel } from "models/campaignProductCollectionModel";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";

import ListItem from "./ListItem";
import CampaignListItem from "./CampaignListItem";
import AppList from "components/App/AppList";
import ListEmptyResult from "components/App/AppList/ListEmptyResult";

type Props = {
  selectedProducts: string[];
  setSelectedProducts: Dispatch<SetStateAction<string[]>>;
  ectomereList?: ProductDetailModel[];
  campaignProductList?: CampaignProductModel[];
  loading: boolean;
};

const ProductList = ({
  selectedProducts,
  setSelectedProducts,
  ectomereList,
  campaignProductList,
  loading,
}: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");

  const isChecked = (id: string) => {
    return selectedProducts.includes(id);
  };
  const handleCheck = (id: string) => {
    if (selectedProducts.includes(id)) {
      // If the product is already selected, let's remove it
      setSelectedProducts(
        selectedProducts.filter((productId) => productId !== id),
      );
    } else {
      // If the product is not selected, let's add it
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  if (operation === CampaignOperationEnum.ViewCampaignProduct) {
    return (
      <AppList
        delay={200}
        type="alpha"
        data={campaignProductList ? campaignProductList : []}
        renderItem={(item) => (
          <CampaignListItem
            selectable
            isChecked={isChecked(item.getId())}
            handleCheck={handleCheck}
            item={item}
            key={item.getId()}
          />
        )}
        ListEmptyComponent={
          <ListEmptyResult content="No product found" loading={loading} />
        }
      />
    );
  }

  return (
    <AppList
      delay={200}
      type="alpha"
      data={ectomereList ? ectomereList : []}
      renderItem={(item) => (
        <ListItem
          selectable={operation === CampaignOperationEnum.AddCampaignProduct}
          isChecked={isChecked(item.getId())}
          handleCheck={handleCheck}
          item={item}
          key={item.id}
        />
      )}
      ListEmptyComponent={
        <ListEmptyResult content="No product found" loading={loading} />
      }
    />
  );
};

export default ProductList;
