

import { Tag } from "antd";
import {
  STATUS_REJECT,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_PURCHASED,
  STATUS_NOT_PURCHASED,
  STATUS_HANDOVER,
  STATUS_ARRIVED_AT_WAREHOUSE,
  STATUS_PREPARING_FOR_TRANSPORT,
  STATUS_HANDOVER_TO_AIRLINE,
  STATUS_CUSTOM_RELEASED,
  STATUS_AGENT_PROCESSED,
  STATUS_RECEIVED_BY_MOVEON,
  STATUS_DELIVERY_PENDING,
  STATUS_PRODUCT_CANCELLED,
  STATUS_CANCEL_INITIATED,
  STATUS_REFUND_INITIATED,
  STATUS_REFUNDED,
} from "consts/buy-product-statuses";
import { capitalize } from "../../utils/helper";
import { DisputeMandateStatusEnum } from "../../enums/disputeCollectionEnum";
import { InvoiceItemStatusEnum, OrderTypeEnum } from "../../types/InvoiceCollection";
import { InvoiceStatus } from "../../models/invoiceCollectionModel";
import {
  CampaignStatusEnum,
  PublishStatusEnum,
} from "enums/campaignCollectionEnums";
enum AllStatus {
  UNPAID = "unpaid",
  PARTIAL_PAID = "partial-paid",
  PAID = "paid",
  UNSETTLED = "unsettled",
  PARTIALLY_SETTLED = "partially-settled",
  SETTLED = "settled",
  AWAITING_FULFILMENT = "awaiting-fulfilment",
  FULFILLED = "fulfilled",
  PARTIALLY_REFUNDED = "partially-refunded",
  REFUNDED = "refunded",
  ONLY_SHIP = "only-ship",
  BUY_AND_SHIP = "buy-and-ship",
  SHIPMENT_ASSIGN_AGENT = "shipment-assigned-agent",
  SHIPMENT_SHIPPING_STARTED = "shipment-shipping-started",
}

type Status =
  | InvoiceItemStatusEnum
  | OrderTypeEnum
  | AllStatus
  | InvoiceStatus
  | DisputeMandateStatusEnum
  | string;

const StatusTag = (props: { text: string; slug: Status }) => {
  const { text, slug } = props;
  let color = "default";

  switch (slug) {
    case "handover-to-shipping":
    case "handover-to-airline":
    case "arrived-at-destination-airport":
    case "customs-released":
    case "agent-processed":
    case "initial":
    case "buy-refund-initiated":
    case STATUS_PENDING:
    case STATUS_DELIVERY_PENDING:
    case CampaignStatusEnum.AWAITING_PUBLISH:
    case "processing":
      color = "processing";
      break;

    case "buy-processed":
    case "received-by-moveon":
    case "arrived-at-warehouse":
    case "buy-purchased":
    case "buy-approved":
    case "resolved":
    case "approved":
    case "dispute-approved":
    case "invoice-payment-paid":
    case "request_approved":
    case "available":
    case STATUS_PURCHASED:
    case STATUS_APPROVED:
    case STATUS_HANDOVER:
    case STATUS_CUSTOM_RELEASED:
    case STATUS_AGENT_PROCESSED:
    case STATUS_RECEIVED_BY_MOVEON:
    case DisputeMandateStatusEnum.AGREED:
    case PublishStatusEnum.published:
    case CampaignStatusEnum.RUNNING:
    case "finished":
      color = "Green";
      break;
    case "preparing-for-transport":
    case "buy-pending":
    case "buy-processing":
    case "partial-paid":
    case "request_pending":
    case STATUS_ARRIVED_AT_WAREHOUSE:
    case STATUS_PREPARING_FOR_TRANSPORT:
    case STATUS_HANDOVER_TO_AIRLINE:
    case "refund":
    case "cancel":
    case STATUS_CANCEL_INITIATED:
    case STATUS_REFUND_INITIATED:
    case STATUS_REFUNDED:
    case CampaignStatusEnum.UPCOMING:
    case "invoice-payment-unpaid":
      color = "warning";
      break;

    case "buy-reject":
    case "rejected":
    case "canceled":
    case "unpaid":
    case "request_reject":
    case STATUS_NOT_PURCHASED:
    case STATUS_REJECT:
    case STATUS_PRODUCT_CANCELLED:
      color = "error";
      break;

    case AllStatus.UNPAID:
    case CampaignStatusEnum.PAUSED:
      color = "red";
      break;
    case AllStatus.PARTIAL_PAID:
      color = "#FFEFEE";
      break;
    case AllStatus.PAID:
      color = "#00893c";
      break;
    case AllStatus.UNSETTLED:
      color = "blue";
      break;
    case AllStatus.PARTIALLY_SETTLED:
      color = "purple";
      break;
    case AllStatus.SETTLED:
    case DisputeMandateStatusEnum.ASSIGNED:
    case AllStatus.SHIPMENT_ASSIGN_AGENT:
    case CampaignStatusEnum.END:
      color = "cyan";
      break;
    case AllStatus.AWAITING_FULFILMENT:
      color = "gold";
      break;
    case AllStatus.FULFILLED:
      color = "lime";
      break;
    case AllStatus.PARTIALLY_REFUNDED:
      color = "magenta";
      break;
    case AllStatus.ONLY_SHIP:
      color = "#0098B9";
      break;
    case AllStatus.BUY_AND_SHIP:
      color = "#527be7";
      break;
    case "open-for-everyone":
    case "active":
    case "credit":
    case AllStatus.SHIPMENT_SHIPPING_STARTED:
      color = "#24b263";
      break;
    case "pending":
    case "dispute-pending":
      color = "lime";
      break;
    case "debit":
      color = "#C80036";
      break;
    default:
      color = "gray";
      break;
  }

  return (
    <Tag style={{ minWidth: "70px", textAlign: "center" }} color={color}>
      {capitalize(text)}
    </Tag>
  );
};

export default StatusTag;
