import { Image, Table, Tag, Typography } from "antd";
import {
  BuyProductVariationModel,
  BuyProductViewModel,
} from "models/buyProductDetailModel";
import { getStatusColor } from "utils/helper";

interface IProps {
  productData: BuyProductViewModel | undefined;
}

const { Text } = Typography;

const colums = [
  {
    title: "Product Meta",
    dataIndex: "product_variations",
    width: "35%",
    render: (_, record: BuyProductVariationModel) => (
      <>
        {record.properties.data.map((v, index) => (
          <div key={index}>
            {v.property_name && (
              <p>
                <Text strong>{v.property_name}</Text> : {v.property_value_name}
              </p>
            )}
            {v.property_name === "Size" && <p>Size: {v.property_value_name}</p>}
          </div>
        ))}
      </>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    render: (_, record: BuyProductVariationModel) => (
      <p>{record.getQuantity()}</p>
    ),
  },
  {
    title: "Price (BDT/RMB)",
    dataIndex: "unit_price",
    render: (_, record: BuyProductVariationModel) => (
      <p>{record.getUnitPrice()}</p>
    ),
  },
  {
    title: "Total Price",
    dataIndex: "total_price",
    render: (_, record: BuyProductVariationModel) => (
      <p>{record.getTotalPrice()}</p>
    ),
  },
];

const ProductMetas: React.FC<IProps> = ({ productData }) => {
  const { Text } = Typography;

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <Image
            width={100}
            style={{ borderRadius: "10px" }}
            src={productData?.getProductImage()}
            placeholder={
              <Image
                preview={false}
                src={productData?.getProductImage()}
                width={100}
              />
            }
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <a
            href={productData?.getProductLink()}
            target="_blank"
            rel="noreferrer"
          >
            {productData?.getProductTitle() && (
              <>
                {productData?.getProductTitle().length >= 100
                  ? `${productData?.getProductTitle().slice(0, 100)}...`
                  : productData?.getProductTitle()}
              </>
            )}
          </a>
          <p>
            {" "}
            PB:{" "}
            <Text type="success" strong>
              {productData?.getProductNumber()}
            </Text>
          </p>
          <p>
            {" "}
            Status:{" "}
            <Tag color={getStatusColor(productData?.getStatus() as string)}>
              {productData?.getStatus()}
            </Tag>
          </p>
        </div>
      </div>

      <br />
      <br />
      <Table
        dataSource={productData?.getProductVariations()?.getData()}
        pagination={false}
        scroll={{ y: 240 , x: 500  }}
        rowKey="sku_id"
        columns={colums}
        footer={() => {
          const data = productData?.getProductVariations()?.getData();
          const totalPrice = data?.reduce(
            (acc, item) => acc + item.getTotalPrice(),
            0,
          );
          const totalQunatity = data?.reduce(
            (acc, item) => acc + item.getQuantity(),
            0,
          );
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <strong>Total:</strong>
              </div>

              <div style={{ marginRight: "100px" }}>
                <strong>{totalQunatity}</strong>
              </div>

              <div style={{ marginRight: "167px" }}>
                {totalPrice?.toFixed(2)}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
export default ProductMetas;
