import { IPagination } from "types/pagination";
import {
  ICountry,
  IShippingCountry,
  IShippingCountryCollection,
} from "types/shippingCountryCollection";

export class CountryModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly code: string;
  public readonly flag: string;

  constructor(data: ICountry) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.flag = data.flag;
  }

  getName = () => this.name;
  getFlag = () => this.flag;
  getCode = () => this.code;
  getId = () => this.id;
}
export class ShippingCountryModel {
  public readonly object: string;
  public readonly id: number;
  public readonly country: CountryModel;

  constructor(data: IShippingCountry) {
    this.object = data.object;
    this.id = data.id;
    this.country = new CountryModel(data.country);
  }

  getCountry = () => this.country;
  getId = () => this.id;
}
export class ShippingCountryCollectionModel {
  public readonly object: string;
  public readonly data: ShippingCountryModel[];
  public readonly pagination: IPagination;

  constructor(data: IShippingCountryCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new ShippingCountryModel(item));
    this.pagination = data.pagination;
  }

  getData = () => this.data;

  getLabeledShippingCountries = () => {
    return this.data.map((data) => {
      return {
        label: data.getCountry()?.getName(),
        value: data.getId(),
      };
    });
  };
  getLabeledCountries = () => {
    return this.data.map((data) => {
      return {
        label: data.getCountry()?.getName(),
        value: data.getId(),
        code: data.getCountry()?.getCode(),
      };
    });
  };
  getShippingCountryIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.getData().forEach(
      (item) => (obj[item.getId()] = item.getCountry().getName()),
    );
    return obj;
  }
}
