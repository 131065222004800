import AddressResource from "lib/core/resources/private/addressResource";
import AddressSchemaResource from "lib/core/resources/private/addressSchemaResource";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class AddressService extends CommerceApiService {
  public addressResource: AddressResource;
  public addressSchemaResource: AddressSchemaResource;

  constructor() {
    super();

    this.addressResource = new AddressResource(this.config);
    this.addressSchemaResource = new AddressSchemaResource(this.config);
  }
}
export default AddressService;
