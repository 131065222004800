
import { AuctionRuleResource, BaseShippingCategoryResource, ShippingCategoryResource, ShippingCountryResource } from "../../resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";
class ShippingCoreService extends CommerceApiService {
  public auctionRuleResource: AuctionRuleResource;
  public baseShippingCategoryResource: BaseShippingCategoryResource;
  public shippingCategoryResource: ShippingCategoryResource;
  public shippingCountryResource: ShippingCountryResource;

  constructor() {
    super();

    this.auctionRuleResource = new AuctionRuleResource(this.config);
    this.baseShippingCategoryResource = new BaseShippingCategoryResource(this.config);
    this.shippingCategoryResource = new ShippingCategoryResource(this.config);
    this.shippingCountryResource = new ShippingCountryResource(this.config);
  }
}

export default ShippingCoreService;
