import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import useDataFilters from "hooks/useDataFilters";
import FiltersComponent from "components/FiltersComponent";
import { useAtom } from "jotai";
import { regionCurrency } from "lib/core-react/store/store";
import { IFilterType } from "types/filters";
import {
  useCreateCurrency,
  useGetCurrency,
  useUpdateCurrency,
} from "lib/core-react/hooks/private/useCurrency";
import {
  CurrencyCreateUpdateRequestModel,
  CurrencyDataModel,
  CurrencyModel,
} from "models/currency";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import CurrencyModifications from "./components/ForModificationForCurrency";
import { MenuInfo } from "rc-menu/lib/interface";
import { StatusTag } from "../../../components";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-core-enum";
const { Column } = Table;

const Currency = () => {
  const { getCurrency } = useGetCurrency();
  const { isMobile } = useWindowWidth();
  const { updateCurrency, isLoading: updateLoading } = useUpdateCurrency();
  const { createCurrency, isLoading: createLoading } = useCreateCurrency();

  const [form] = Form.useForm();

  const [{ data: currencyStoreData, isLoading, refetch }] =
    useAtom(regionCurrency);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if (
      (filters && !isFetched && isFirstCall) ||
      refetch ||
      refetchFromFilter
    ) {
      filters && ApiHelperModel.makeGetRequest(filters, getCurrency);
    }
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  // Currency
  const CurrencyData =
    currencyStoreData && new CurrencyModel(currencyStoreData);

  // Filter
  let filterData = CurrencyData?.filters;
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && CurrencyData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, CurrencyData?.filters]);

  // Update modal state
  const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
    useState<boolean>(false);

  const [selectedCurrency, setSelectedCurrency] = useState<
    CurrencyDataModel | undefined
  >(undefined);

  // Handle Modal
  const handleMenuClick = (e: MenuInfo, record: CurrencyDataModel) => {
    setSelectedCurrency(record);

    if (e.key === "update") {
      setIsShowCreateUpdateModal(true);
    }
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCurrency,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CurrencyData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCurrency);
  };

  const onFinish = async (value) => {
    try {
      const payload: CurrencyCreateUpdateRequestModel = {
        symbol: value.symbol,
        symbol_native: value.symbol_native,
        name: value.name,
        status: value.status,
        code: value.code,
      };
      if (selectedCurrency) {
        await updateCurrency(selectedCurrency?.id, payload);
        setSelectedCurrency(undefined);
        notification["success"]({
          message: "Currency Update successfully",
        });
      } else {
        await createCurrency(payload);
        notification["success"]({
          message: "Currency Create successfully",
        });
      }
      setIsShowCreateUpdateModal(false);
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Region Currency"
          style={{ marginTop: "10px" }}
          extra={[
            checkActionPermission(
              ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_ADD,
              <Button
                key="1"
                type="primary"
                onClick={() => setIsShowCreateUpdateModal(true)}
              >
                Add Currency
              </Button>,
              null,
            ),
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={CurrencyData?.data}
              scroll={{ x: 1000 }}
            >
              <Column width={70} title="ID" dataIndex="id" key="id" />
              <Column title="Currency Code" dataIndex="code" key="code" />
              <Column title="Currency Name" dataIndex="name" key="name" />
              <Column title="Currency Symbol" dataIndex="symbol" key="symbol" />
              <Column
                title="Currency Symbol Native"
                dataIndex="symbol_native"
                key="symbol_native"
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: CurrencyDataModel) => (
                  <>
                    <StatusTag slug={record.status} text={record.status} />
                  </>
                )}
              />

              <Column
                title="Action"
                fixed={isMobile ? "right" : undefined}
                width={isMobile ? 100 : 150}
                key="action"
                render={(_, record: CurrencyDataModel) => {
                  const items: ExtendedMenuItemType[] = [
                    {
                      permission: ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_UPDATE,
                      label: "Update",
                      key: "update",
                      icon: <EditOutlined />,
                      onClick: (e) => handleMenuClick(e, record),
                    },
                  ];

                  return (
                    <Dropdown
                      menu={{
                        items: items.filter((x) =>
                          checkActionPermission(x.permission, x, null),
                        ),
                      }}
                    >
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            {" "}
                            Actions <DownOutlined />
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`${selectedCurrency ? "Update" : "Create"} Currency`}
        open={isShowCreateUpdateModal}
        okText={selectedCurrency ? "Update" : "Create"}
        okButtonProps={{ loading: createLoading || updateLoading }}
        onCancel={() => {
          setIsShowCreateUpdateModal(false);
          setSelectedCurrency(undefined);
        }}
        onOk={form.submit}
        centered
      >
        <CurrencyModifications
          data={selectedCurrency}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
};

export default Currency;
