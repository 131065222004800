// buy order detail model

import {
  IBuyOrderDetail,
  IBuyOrderDetailMoveonShipping,
  IBuyOrderDetailOrderHandler,
  IBuyProductDetail,
  IBuyProductDetailCollection,
  IBuyProductDetailShippingAddress,
  IDestinationWarehouse,
} from 'types/buyOrderDetail'
import {
  BuyProductRegionModel,
  BuyProductVariationCollectionModel,
  ProductSellerModel,
  UserModel,
} from './buyOrderCollectionModel'
import { IShippingSlotCollection } from 'types/buyOrderCollection'

export class BuyOrderDetailMoveonShippingModel {
  object: string
  id: number
  shipping_type: string
  shipping_mode: string
  unit_type: string
  rate: number
  shipping_country: {
    object: string
    id: number
  }
  agent_category_price_read_id: number
  destination_warehouse: IDestinationWarehouse
  slots: IShippingSlotCollection

  constructor(payload: IBuyOrderDetailMoveonShipping) {
    this.object = payload?.object
    this.id = payload?.id
    this.shipping_type = payload?.shipping_type
    this.shipping_mode = payload?.shipping_mode
    this.unit_type = payload?.unit_type
    this.rate = payload?.rate
    this.shipping_country = payload?.shipping_country
    this.agent_category_price_read_id = payload?.agent_category_price_read_id
    this.destination_warehouse = payload?.destination_warehouse
    this.slots = payload?.slots
  }
  getId = () => this.id
  getShippingType = () => this.shipping_type
  getShippingMode = () => this.shipping_mode
  getUnitType = () => this.unit_type
  getRate = () => this.rate
  getShippingCountry = () => this.shipping_country
  getDestinationWarehouse = () => this.destination_warehouse
}

export class BuyProductDetailModel {
  object: string
  id: number
  product_number: string
  product_id: string
  product_vid: string
  cart_item_id: number
  product_title: string
  product_image: string
  product_link: string
  shipping_provider: string
  total_quantity: number
  status: string
  region: BuyProductRegionModel
  seller: ProductSellerModel
  product_variations: BuyProductVariationCollectionModel
  store: {
    name: string
  }
  shipping_address: IBuyProductDetailShippingAddress
  moveon_shipping: BuyOrderDetailMoveonShippingModel

  constructor(payload: IBuyProductDetail) {
    this.object = payload.object
    this.id = payload.id
    this.product_number = payload.product_number
    this.product_id = payload.product_id
    this.product_vid = payload.product_vid
    this.cart_item_id = payload.cart_item_id
    this.product_title = payload.product_title
    this.product_image = payload.product_image
    this.product_link = payload.product_link
    this.shipping_provider = payload.shipping_provider
    this.total_quantity = payload.total_quantity
    this.status = payload?.status
    this.region = new BuyProductRegionModel(payload.region)
    this.seller = new ProductSellerModel(payload.seller)
    this.product_variations = new BuyProductVariationCollectionModel(
      payload?.product_variations,
    )
    this.store = payload?.store
    this.shipping_address = payload?.shipping_address
    this.moveon_shipping = new BuyOrderDetailMoveonShippingModel(
      payload?.moveon_shipping,
    )
  }

  getId() {
    return this.id
  }

  getProductNumber() {
    return this.product_number
  }

  getProductId() {
    return this.product_id
  }

  getProductVId() {
    return this.product_vid
  }

  getCartItemId() {
    return this.cart_item_id
  }

  getProductTitle() {
    return this.product_title
  }

  getProductImage() {
    return this.product_image
  }

  getProductLink() {
    return this.product_link
  }

  getShippingProvider() {
    return this.shipping_provider
  }

  getTotalQuantity() {
    return this.total_quantity
  }

  getStatus() {
    return this.status
  }
  getRegion() {
    return this.region
  }

  getProductVariations() {
    return this.product_variations
  }

  getStoreName = () => this.store.name
  getSeller = () => this.seller
  getShippingAddress = () => this.shipping_address.address
  getShippingAddressId = () => this.shipping_address.id
  getMoveonShipping = () => this.moveon_shipping
}

export class BuyProductDetailCollectionModel {
  object: string
  data: BuyProductDetailModel[]
  constructor(dataItem: IBuyProductDetailCollection) {
    this.object = dataItem.object
    this.data = dataItem.data.map((d) => new BuyProductDetailModel(d))
  }
  getData = () => this.data
  getDataById = (id: number) => this.data.find((d) => d.getId() === id)
  getFirstProductShippingAddress = () => this.data[0]?.getShippingAddress()
  getFirstProductShippingAddressId = () => this.data[0]?.getShippingAddressId()
  getFirstProductRegionCode = () => this.data[0]?.getRegion().getCode()
  getFirstProductCurrency = () => this.data[0]?.getRegion().getCurrency()
  getFirstShippingCountryId = () =>
    this.data[0]?.getMoveonShipping().getShippingCountry().id
  getFirstDestinationWarehouseId = () =>
    this.data[0]?.getMoveonShipping().getDestinationWarehouse().id
}

export class BuyOrderDetailOrderHandlerModel {
  object: string
  id: number
  name: string
  phone: string
  email: string
  shipping_mark: string
  type: string
  is_drop_user: boolean
  is_active: number
  gravatar: string
  created_at: string
  constructor(data: IBuyOrderDetailOrderHandler) {
    this.object = data?.object
    this.id = data?.id
    this.name = data?.name
    this.phone = data?.phone
    this.email = data?.email
    this.shipping_mark = data?.shipping_mark
    this.type = data?.type
    this.is_active = data?.is_active
    this.is_drop_user = data?.is_drop_user
    this.gravatar = data?.gravatar
    this.created_at = data?.created_at
  }
  getId = () => this.id
  getName = () => this.name
  getEmail = () => this.email
  getPhone = () => this.phone
}

export class BuyOrderDetailModel {
  object: string
  id: number
  order_number: string
  order_tag: string | null
  order_type: string
  order_status: string
  payment_status: string
  order_handler: BuyOrderDetailOrderHandlerModel | null
  customer: UserModel
  products: BuyProductDetailCollectionModel
  created_at: string
  note: string | null

  constructor(payload: IBuyOrderDetail) {
    this.object = payload?.object
    this.id = payload?.id
    this.order_number = payload?.order_number
    this.customer = new UserModel(payload?.customer)
    this.products = new BuyProductDetailCollectionModel(payload?.products)
    this.order_tag = payload?.order_tag ? payload?.order_tag : null
    this.order_type = payload?.order_type
    this.order_status = payload?.order_status
    this.payment_status = payload?.payment_status
    this.order_handler =
      payload?.order_handler &&
      new BuyOrderDetailOrderHandlerModel(payload?.order_handler)
    this.created_at = payload?.created_at
    this.note = payload?.note
  }

  getId() {
    return this.id
  }

  getOrderNumber() {
    return this.order_number
  }

  getOrderType() {
    return this.order_type
  }

  getOrderStatus() {
    return this.order_status
  }

  getPaymentStatus() {
    return this.payment_status
  }

  getCustomer() {
    return this.customer
  }

  getOrderHandler() {
    return this.order_handler
  }

  getProducts() {
    return this.products
  }
  getCreatedAt = () => this.created_at
  getOrderTag = () => this.order_tag
  getNote = () => this.note
}
