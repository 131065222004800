import { atom, createStore } from "jotai";
import { BaseShippingCategoryModel } from "models/baseShippingCategory";
import { CurrencyModel } from "models/currency";
import { DestinationWarehouseModel } from "models/destinationWarehouse";
import { LanguageModel } from "models/language";
import { RegionModel } from "models/region";
import { ShippingCountryModel } from "models/shippingCountry";
import { IFilterType } from "types/filters";
import { createDataAtom } from "./jotaiUtility";
import { AuctionRuleModel } from "models/auctionRule";
import { WishlistCollectionModel } from "models/wishlistCollectionModel";
import { AgentCategoryPriceReadCollectionModel } from "models/agentCategoryPriceReadCollectionModel";
import { CustomerUserCollectionModel } from "models/customerUserCollectionModel";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { PermissionCollectionModel } from "models/permissionCollectionModel";
import { ShipmentProductBidCollectionModel } from "models/shipmentProductBidCollectionModel";
import { ShippingCartCollectionModel } from "models/shippingCartCollectionModel";
import { AddressCollectionModel } from "models/addressCollectionModel";
import { PaymentGatewayCollectionModel } from "models/paymentGatewayCollectionModel";
import { ICommissionCollection } from "types/commissionCollection";
import {
    IExchangeList,
    IExchangeListCollection,
} from "types/exchangeListCollection";
import { IExchangeGroupCollection } from "types/exchangeGroupCollection";
import { ICustomerUserCollection } from "types/customerUserCollection";
import { IAgentCompanyCollection } from "types/agentCompanyCollection";
import { IMemberCollection } from "types/memberCollection";
import { IAgentCompanyShippingPointCollection } from "types/agentCompanyShippingPointCollection";
import { IAgentWarehouseCollection } from "types/agentWarehouseCollection";
import {
    IShipmentProduct,
    IShipmentProductCollection,
} from "types/shipmentProductCollection";
import { TransactionCollectionModel } from "models/transactionCollectionModels";
import { WalletOverviewModel } from "models/walletOverviewCollectionModel";
import { AddonServiceCollectionModel } from "models/addonServiceCollectionModel";
import { BuyActionCollectionModel } from "models/buyActionCollectionModel";
import { IProductSearchResult } from "types/productDetailCollection";
import { IProductDetailExtended } from "types/productDetailExtended";
import { ICampaignCollection } from "types/campaignCollection";
import { ICampaignProductCollection } from "types/campaignProductCollection";
import { IDiscountRulesSchema } from "types/discountRulesSchema";
import { IShippingCountryCollection } from "types/shippingCountryCollection";
import { IDiscountUsageHistoryCollection } from "types/discountUsageHistoryCollection";
import { IPayoutRequestCollection } from "types/payoutRequestCollection";
import { IDiscount, IDiscountCollection } from "types/discountCollection";
import { IDisputeCollection } from "types/disputeCollection";
import { IDisputeDetailsCollection } from "types/disputeDetailsCollection";
import { IAgentVerificationCollection } from "types/agentVerificationCollection";
import {
    IDestinationWarehouseCollection,
    IDestinationWarehouseMemberCollection,
    IDestinationWarehouseMembershipInvitationCollection,
} from "types/destinationWarehouseCollection";
import { IRfqRequestCollection } from "types/rfqRequestCollection";
import { IMessage } from "@novu/shared";
import { HarvestBatchCollectionModel } from "models/harvestBatchCollectionModel";
import { ITransaction } from "types/transactionDetail";
import { BuyProductView } from "models/buyProductDetailModel";
import { IBuyOrderPlace } from "types/buyOrderPlace";
import { IPurchaseCartCollection } from "types/PurchaseCartCollection";
import { IAddressSchema } from "types/addressSchema";
import { IPurchaseCommissionCollection } from "types/purchaseCommissionCollection";
import { ICourierGatewayCollection } from "types/courierGatewayCollection";
import { ICountryCollection } from "types/countryCollection";
import { IStoreCollection } from "types/storeCollection";
import {
    IAgentInvoiceCollection,
    ICustomerInvoiceDetailsResponse,
    IInvoiceCollection,
    IInvoiceTransactionDetailsResponse,
} from "../../../types/InvoiceCollection";
import { IWalletTransactionCollection } from "../../../types/walletTransactionCollection";
import { PayoutGatewayCollectionModel } from "models/payoutGatewayCollectionModel";
import { CampaignDataModel } from "models/campaignStripModel";
import { UserCollectionModel } from "models/userCollectionModel";
import { InvoicePaymentRuleResponseModel } from "../../../models/invoicePaymentRoleModel";
import { IShippingCategory } from "../../../types/shippingCategory";
import { IBulkUserCollection } from "../../../types/bulkUserCollection.d";
import { HSCodeCollectionModel } from "models/hsCodeCollectionModel";

const rootStore = createStore();

// Agent Manage ->
export const agentCompanyCollectionAtom =
    createDataAtom<IAgentCompanyCollection>();

export const memberCollectionAtom = createDataAtom<IMemberCollection>();
export const agentVerificationCollectionAtom =
    createDataAtom<IAgentVerificationCollection>();

export const agentCompanyShippingPointCollectionAtom =
    createDataAtom<IAgentCompanyShippingPointCollection>();

export const agentWarehouseCollectionAtom =
    createDataAtom<IAgentWarehouseCollection>();

// Region Country store
export const regionCountry = createDataAtom<ICountryCollection>();

// Region Currency store
export const regionCurrency = createDataAtom<CurrencyModel>();

// Region Currency store
export const hsCodeCollection = createDataAtom<HSCodeCollectionModel>();


// Region Language store
export const regionLanguage = createDataAtom<LanguageModel>();

// Region store
export const regionRegion = createDataAtom<RegionModel>();

// Courier courier gateway store
export const courierGatewayCollectionAtom =
    createDataAtom<ICourierGatewayCollection>();

// Destination warehouse
export const destinationWarehouse = createDataAtom<DestinationWarehouseModel>(); // old version
export const destinationWarehouseCollectionAtom =
    createDataAtom<IDestinationWarehouseCollection>();
export const destinationWarehouseMemberCollectionAtom =
    createDataAtom<IDestinationWarehouseMemberCollection>();
export const destinationWarehouseInvitationCollectionAtom =
    createDataAtom<IDestinationWarehouseMembershipInvitationCollection>();

// Shipping Country
export const shippingCountry = createDataAtom<ShippingCountryModel>();
export const shippingCountryCollectionAtom =
    createDataAtom<IShippingCountryCollection>();

// BaseShipping Category store
export const baseShippingCategoryAtom =
    createDataAtom<BaseShippingCategoryModel>();

// Shipping Category store
export const shippingCategoryAtom = createDataAtom<IShippingCategory>();

// Auction Rule store
export const auctionRuleAtom = createDataAtom<AuctionRuleModel>();

// Bulk admin store
export const bulkUserAdmin = createDataAtom<IBulkUserCollection>();

// Bulk customer store
export const bulkUserCustomer = createDataAtom<IBulkUserCollection>();

// Shipping Commission store
export const shippingCommissionAtom = createDataAtom<ICommissionCollection>();

// Store exchange List
export const storeExchangeAtom = createDataAtom<IExchangeListCollection>();

// Single Store exchange
export const storeSingleExchangeAtom = createDataAtom<IExchangeList>();

// Store exchange group
export const exchangeGroupCollectionAtom =
    createDataAtom<IExchangeGroupCollection>();

export const exchangeGroupCustomerUserCollectionAtom =
    createDataAtom<ICustomerUserCollection>();

export const storeStoreAtom = createDataAtom<IStoreCollection>();

// Query store
export const queryStringAtom = atom<IFilterType>({});

// Customer Manage
export const customerUserCollectionAtom =
    createDataAtom<CustomerUserCollectionModel>();
export const wishlistAtom = createDataAtom<WishlistCollectionModel>();
export const shippingCartAtom = createDataAtom<ShippingCartCollectionModel>();

// Role Permission
export const roleAtom = createDataAtom<RoleCollectionModel>();
export const permissionAtom = createDataAtom<PermissionCollectionModel>();

// Admin User
export const adminUserCollection = createDataAtom<UserCollectionModel>();

//Shipment Products
export const shipmentProductsAtom =
    createDataAtom<IShipmentProductCollection>();
export const shipmentProductAtom = createDataAtom<IShipmentProduct>();

//Agent Category Price Read Collection
export const agentCategoryPriceReadCollectionAtom =
    createDataAtom<AgentCategoryPriceReadCollectionModel>();

//Bids
export const bidCollectionAtom =
    createDataAtom<ShipmentProductBidCollectionModel>();

//Address
export const addressCollectionAtom = createDataAtom<AddressCollectionModel>();

// Payment Manage
export const paymentGatewayCollectionAtom =
    createDataAtom<PaymentGatewayCollectionModel>();

export const payoutGateWayCollectionAtom =
    createDataAtom<PayoutGatewayCollectionModel>();

// Transactions Manage

export const transactionCollectionAtom =
    createDataAtom<TransactionCollectionModel>();
export const TransactionCollectionAtom =
    createDataAtom<IWalletTransactionCollection>();

export const transactionDetailAtom = createDataAtom<ITransaction>();

// BuyProduct Details
export const buyProductDetail = createDataAtom<BuyProductView>();

export const walletOverviewCollectionAtom =
    createDataAtom<WalletOverviewModel>();
export const payoutRequestCollectionAtom =
    createDataAtom<IPayoutRequestCollection>();

//Invoice
export const invoiceCollectionAtom = createDataAtom<IInvoiceCollection>();
export const agentInvoiceCollectionAtom =
    createDataAtom<IAgentInvoiceCollection>();
export const invoiceDetailsAtom =
    createDataAtom<ICustomerInvoiceDetailsResponse>();
export const invoiceTransactionDetailsAtom =
    createDataAtom<IInvoiceTransactionDetailsResponse>();
// invoice payment rule

export const invoicePaymentRuleCollectionAtom =
    createDataAtom<InvoicePaymentRuleResponseModel>();

//Product List
export const productSearchResultAtom = createDataAtom<IProductSearchResult>();
export const productDetailAtom = createDataAtom<IProductDetailExtended>();

//Product List
export const addonServiceCollectionAtom =
    createDataAtom<AddonServiceCollectionModel>();

//Product List
export const addressSchemaAtom = createDataAtom<IAddressSchema>();

//Take Actions List
export const buyActionCollectionAtom =
    createDataAtom<BuyActionCollectionModel>();
//Campaign List
export const campaignCollectionAtom = createDataAtom<ICampaignCollection>();

//Campaign Strip
export const campaignStripAtom = createDataAtom<CampaignDataModel>();
//Campaign
export const campaignProductCollectionAtom =
    createDataAtom<ICampaignProductCollection>();
// dispute
export const disputeCollectionAtom = createDataAtom<IDisputeCollection>();
export const disputeDetailsAtom = createDataAtom<IDisputeDetailsCollection>();
// RFQ
export const rfqRequestListAtom = createDataAtom<IRfqRequestCollection>();

// HarvestJob
export const harvestJobCollectionAtom =
    createDataAtom<HarvestBatchCollectionModel>();

// Discount
export const discountCollectionAtom = createDataAtom<IDiscountCollection>();
export const discountAtom = createDataAtom<IDiscount>();
export const discountRulesSchemaAtom = createDataAtom<IDiscountRulesSchema>();
export const discountUsageHistoryCollectionAtom =
    createDataAtom<IDiscountUsageHistoryCollection>();

// PurchaseCommission
export const purchaseCommissionCollectionAtom =
    createDataAtom<IPurchaseCommissionCollection>();

export const selectedNotificationAtom = atom<IMessage[]>([]);

// BuyOrderPlace
export const buyOrderPlaceAtom = createDataAtom<IBuyOrderPlace>();
export const purchaseCartCollectionAtom =
    createDataAtom<IPurchaseCartCollection>();

export default rootStore;
