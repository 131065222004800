import {
  IAddress,
  IAddressCollection,
  IAddressLabel,
  IAddressLabelCollection,
} from 'types/addressCollection';
import { CountryDataModel } from './country';
import { IPagination } from 'types/pagination';
import { PaginationModel } from './paginationModel';

export class AddressLabelModel {
  public readonly object: string;
  public readonly id: number;
  public readonly label: string;

  constructor(dataItem: IAddressLabel) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.label = dataItem.label;
  }
}

export class AddressLabelCollectionModel {
  public readonly object: string;
  public readonly data: AddressLabelModel[];

  constructor(dataItem: IAddressLabelCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((item) => new AddressLabelModel(item));
  }
}

export class AddressModel {
  public readonly object: string;
  public readonly id: number;
  public readonly country?: CountryDataModel;
  public readonly address: { [key: string]: string };
  public readonly is_default_shipping: boolean;
  public readonly is_default_billing: boolean;
  public readonly labels?: AddressLabelCollectionModel;
  constructor(dataItem: IAddress) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.country = dataItem?.country
      ? new CountryDataModel(dataItem?.country)
      : undefined;
    this.address = dataItem.address;
    this.is_default_billing = dataItem.is_default_billing;
    this.is_default_shipping = dataItem.is_default_shipping;
    this.labels = dataItem.labels
      ? new AddressLabelCollectionModel(dataItem.labels)
      : undefined;
  }

  getId = () => this.id;
  getAddress = () => this.address;
  getIsDefaultBilling = () => this.is_default_billing;
  getIsDefaultShipping = () => this.is_default_shipping;
}
export class AddressCollectionModel {
  object: string;
  data: AddressModel[];
  pagination: IPagination;

  constructor(dataItem: IAddressCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new AddressModel(d));
    this.pagination = new PaginationModel(dataItem.pagination);
  }

  getData = () => this.data;
  getPagination = () => this.pagination;

  getAllAddress() {
    // BEWARE:: Wrong implementation
    return this.data.map((item) => {
      return {
        id: item.id,
        name: `${item.address.first_name} ${item.address.last_name}`,
        email: item.address.email,
        phone: item.address.phone,
        country: item.address.country,
        district: item.address.district,
        postal_code: item.address.postal_code,
        address_1: item.address.address_1,
        address_2: item.address.address_2,
        is_default_shipping: item.is_default_shipping ? true : false,
        is_default_billing: item.is_default_billing ? true : false,
      };
    });
  }
}
