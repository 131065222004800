import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import {
    exchangeGroupCollectionAtom,
    exchangeGroupCustomerUserCollectionAtom,
} from "lib/core-react/store/store";
import {
    IExchangeGroupPostRequest,
    IExchangeGroupUserRequest,
} from "types/exchangeGroupCollection";
import { message, Modal } from "antd";
const { confirm } = Modal;

// Exchange Group
export const useGetExchangeGroup = () => {
    const [exchangeGroup, setExchangeGroup] = useAtom(
        exchangeGroupCollectionAtom,
    );
    const { storeService } = useService();
    const getExchangeGroup = async (params: Record<string, any>): Promise<void> => {
        setExchangeGroup({ ...exchangeGroup, isLoading: true, error: null });
        try {
            const response =
                await storeService.exchangeGroupResource.get(params);
            setExchangeGroup({
                ...exchangeGroup,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setExchangeGroup({
                ...exchangeGroup,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403
            });
        }
    };
    return { getExchangeGroup } as const;
};

export const useCreateExchangeGroup = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupData, setExchangeGroupData] = useAtom(
        exchangeGroupCollectionAtom,
    );

    const createExchangeGroup = async (payload: IExchangeGroupPostRequest) => {
        setIsLoading(true);
        try {
            const response =
                await storeService.exchangeGroupResource.create(payload);
            setIsLoading(false);
            setExchangeGroupData({ ...exchangeGroupData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { createExchangeGroup, isLoading };
};

export const useUpdateExchangeGroup = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupData, setExchangeGroupData] = useAtom(
        exchangeGroupCollectionAtom,
    );

    const updateExchangeGroup = async (
        id: number,
        payload: IExchangeGroupPostRequest,
    ) => {
        setIsLoading(true);
        try {
            const res = await storeService.exchangeGroupResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setExchangeGroupData({ ...exchangeGroupData, refetch: true });
            return res;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { updateExchangeGroup, isLoading };
};

export const useDeleteExchangeGroup = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupData, setExchangeGroupData] = useAtom(
        exchangeGroupCollectionAtom,
    );

    const deleteExchangeGroup = async (id: number) => {
        setIsLoading(true);
        confirm({
            title: "Do you want to delete exchange group?",
            content: "This action cannot be undone.",
            async onOk() {
                try {
                    const res =
                        await storeService.exchangeGroupResource.delete(id);
                    if (res) {
                        setIsLoading(false);
                        setExchangeGroupData({
                            ...exchangeGroupData,
                            refetch: true,
                        });
                        message.success(res.message);
                    }
                } catch (error) {
                    setIsLoading(false);
                    throw error;
                } finally {
                    setIsLoading(false);
                }
            },
            onCancel() {},
        });
    };

    return { deleteExchangeGroup, isLoading };
};

export const useGetExchangeGroupCustomers = () => {
    const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
        exchangeGroupCustomerUserCollectionAtom,
    );

    const { storeService } = useService();

    const getExchangeGroupCustomers = async (
        params: Record<string, any>,
    ): Promise<void> => {
        setExchangeGroupCustomers({
            ...exchangeGroupCustomers,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await storeService.exchangeGroupResource.getUsers(params);
            setExchangeGroupCustomers({
                ...exchangeGroupCustomers,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setExchangeGroupCustomers({
                ...exchangeGroupCustomers,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };

    return { getExchangeGroupCustomers };
};

export const useAddExchangeGroupCustomer = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupData, setExchangeGroupData] = useAtom(
        exchangeGroupCustomerUserCollectionAtom,
    );

    const addExchangeGroupCustomer = async (
        payload: IExchangeGroupUserRequest,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await storeService.exchangeGroupResource.addUser(payload);
            setIsLoading(false);
            setExchangeGroupData({ ...exchangeGroupData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { addExchangeGroupCustomer, isLoading };
};

export const useUpdateExchangeGroupCustomer = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
        exchangeGroupCustomerUserCollectionAtom,
    );

    const updateExchangeGroupCustomer = async (
        payload: IExchangeGroupUserRequest,
    ) => {
        setIsLoading(true);
        try {
            await storeService.exchangeGroupResource.deleteUser(
                payload.user_id,
            );
            const res =
                await storeService.exchangeGroupResource.updateUser(payload);
            setIsLoading(false);
            setExchangeGroupCustomers({
                ...exchangeGroupCustomers,
                refetch: true,
            });
            return res;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { updateExchangeGroupCustomer, isLoading };
};

export const useDeleteExchangeCustomer = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
        exchangeGroupCustomerUserCollectionAtom,
    );

    const deleteExchangeGroupCustomer = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await storeService.exchangeGroupResource.deleteUser(id);
            setIsLoading(false);
            setExchangeGroupCustomers({
                ...exchangeGroupCustomers,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { deleteExchangeGroupCustomer, isLoading };
};
