import {
  RegionCode,
  RegionDataStatus,
  RegionStoreType,
} from "enums/regionCollectionEnums";
import { ICountry } from "./country";
import { ILanguage } from "./language";
import { IPagination } from "./pagination";
import { IFilterType } from "./filters";
import { InvoiceStatus } from "../models/invoiceCollectionModel";
import { CurrencyDataStatus, CurrencyEnum } from "enums/currencyCollectionEnums";

interface IWallet {
  object: string;
  id: number;
  available_balance: number;
  reserved_balance: number;
  pending_balance: number;
}

export interface InvoiceTransactionAssociation {
  id: number;
  amount: number;
  wallet?: IWallet;
  created_at: string;
}

export interface InvoiceTransaction {
  object: string;
  id: number;
  associations: {
    data: {
      object: string;
      data: InvoiceTransactionAssociation[];
    };
  };
  transaction_number: string;
  amount: number;
  description: string | null;
  type: string;
  created_at: string;
}

export interface InvoiceTransactionCollection {
  object: string;
  data: InvoiceTransaction[];
}

export interface IItemBuyProductVariation {
  object: string;
  id: number;
  buy_product_id: number;
  sku_id: string;
  image: string;
  quantity: number;
  unit_price: number;
  total_price: number;
  original_unit_price: number;
  original_total_price: number;
}

 export enum AllStatusEnum {
  UNPAID = "unpaid",
  PARTIAL_PAID = "partial-paid",
  PAID = "paid",
  UNSETTLED = "unsettled",
  PARTIALLY_SETTLED = "partially-settled",
  SETTLED = "settled",
  AWAITING_FULFILMENT = "awaiting-fulfilment",
  FULFILLED = "fulfilled",
  PARTIALLY_REFUNDED = "partially-refunded",
  REFUNDED = "refunded",
  ONLY_SHIP = "only-ship",
  BUY_AND_SHIP = "buy-and-ship",
}

export enum InvoiceItemStatusEnum {
  AWAITING_FULFILMENT = "awaiting-fulfilment",
  FULFILLED = "fulfilled",
  PARTIALLY_REFUNDED = "partially-refunded",
  REFUNDED = "refunded",
}
export enum OrderTypeEnum {
  ONLY_SHIP = "only-ship",
  BUY_AND_SHIP = "buy-and-ship",
}

 export interface IInvoiceItem {
  object: string;
  total_amount: number;
  initial_amount: number;
  refundable_amount: number;
  parent_item_id: null | number;
  status: InvoiceItemStatusEnum;
  created_at: string;
  buy_product_variation?: IItemBuyProductVariation;
}

export interface IShipmentProductImage {
  length: number;
  object: string;
  id: number;
  title: string;
  alt: string;
  src: string;
  display_order: number;
}

export interface IShipmentProductShippingAddress {
  area: string;
  email: string;
  phone: string;
  country: string;
  district: string;
  address_1: string;
  address_2: string;
  last_name: string;
  first_name: string;
  postal_code: string;
}
export interface IShipmentProductImageCollection {
  object: string;
  data: IShipmentProductImage[];
}

export interface IShipmentProduct {
  // length: number;
  object: string;
  id: number;
  product_number: string;
  title: string;
  // images: IShipmentProductImageCollection;
  shipping_address: IShipmentProductShippingAddress;
  shipment_instruction: string | null;
  shipping_mode: string;
  shipping_type: string;
  status: string;
}
export interface IBuyProduct {
  object: string;
  id: number;
  product_number: string;
  product_id: string;
  product_vid: string;
  cart_item_id: null | string;
  product_title: string;
  product_image: string;
  product_link: string;
  shipping_provider: string;
  total_quantity: number;
  status: string;
  source_type: string;
  source_id: number;
  created_at: string;
}

export interface ICurrencyData {
  object: CurrencyEnum.Currency;
  id: number;
  name: string;
  code: string;
  symbol: string;
  symbol_native: string;
  status: CurrencyDataStatus;
  created_at: string;
}

export interface ICustomer {
  object: string;
  id: number;
  name: string;
  email: string;
  type: "customer";
  shipping_mark: string;
  created_at: string;
}

export interface IRegion {
  object: string;
  id: number;
  store: string;
  store_type: RegionStoreType;
  code: RegionCode;
  name: string;
  status: RegionDataStatus;
  created_at: string;
  currency: ICurrencyData;
  countries?: ICountry;
  languages?: ILanguage;
}

export interface IOrder {
  object: string;
  id: number;
  order_number: string;
  // user_id: number;
  // order_handler: number;
  order_tag: string | null;
  order_type: OrderTypeEnum;
  order_status: string;
  payment_status: string;
  created_at: string;
}

export interface IInvoice {
  product: IShipmentProductImage;
  images: any;
  object: string;
  id: number;
  invoice_number: string;
  customer?: ICustomer;
  region: IRegion;
  order: IOrder;
  type: string | null;
  product_number: string | null;
  status: InvoiceStatus;
  total_amount: number;
  initial_amount: number;
  refundable_amount: number;
  transaction_summary: {
    paid: number;
    due: number;
    overpaid: number;
  };
  shipment_product: IShipmentProduct | null;
  buy_product: IBuyProduct | null;
  items?: {
    data: IInvoiceItem[];
    object: string;
  };
  transactions?: InvoiceTransactionCollection;
  created_at: string;
}
export interface IAgentInvoice {
  object: string;
  id: number;
  invoice_number: string;
  type: string | null;
  product_number: string | null;
  status: InvoiceStatus;
  total_amount: number;
  initial_amount: number;
  refundable_amount: number;
  transaction_summary: {
    paid: number;
    due: number;
    overpaid: number;
  };
  shipment_product?: IShipmentProduct | null;
  buy_product?: IBuyProduct | null;
  created_at: string;
}

export interface IAgentInvoiceDetails extends IInvoice {
  agent_company: IInvoiceAgentCompany;
}

export interface IInvoiceAgentCompany {
  object: string;
  id: number;
  primary_name: string;
  secondary_name: string;
  slug: string;
  operation_status: string;
  verification_status: string;
  registered_country: number;
  registered_country_detail: {
    id: number;
    code: string;
    name: string;
    status: string;
    created_at: string;
  };
  company_type: string;
  director_name: string;
  designation: string;
  business_phone: string;
  years_of_experience: string;
  number_of_people_in_team: string;
  address: {
    city: string;
    state: string;
    postal: string;
    country: string;
    address_1: string;
    address_2: string;
  };
}

export interface IInvoiceCollection {
  object: string;
  data: IInvoice[];
  pagination: IPagination;
  filters: IFilterType;
}

export interface IAgentInvoiceCollection {
  object: string;
  data: IAgentInvoice[];
  pagination: IPagination;
  filters: IFilterType;
}

export interface ICustomerInvoiceDetailsResponse {
  message: string;
  data: IInvoice;
}
export interface IAgentInvoiceDetailsResponse {
  message: string;
  data: IAgentInvoiceDetails;
}
export type InvoiceProductType = "ShipmentProduct" | "BuyProduct";

interface ITransactionInvoice {
  object: string;
  id: number;
  invoice_number: string;
  status: InvoiceStatus;
  total_amount: number;
  initial_amount: number;
  refundable_amount: number;
  transaction_summary: {
    paid: number;
    due: number;
    overpaid: number;
  };
  created_at: string;
}

export interface InvoiceTransactionDetails {
  object: string;
  id: number;
  invoice: ITransactionInvoice;
  associations: {
    data: {
      object: string;
      data: InvoiceTransactionAssociation[];
    };
  };
  transaction_number: string;
  amount: number;
  description: string | null;
  type: string;
  created_at: string;
}

export interface IInvoiceTransactionDetailsResponse {
  message: string;
  data: InvoiceTransactionDetails;
}
