import { WishlistResource } from "lib/core/resources/private";
import CartApiService from "lib/core/apiServices/cartApiService";

class WishlistService extends CartApiService {
  public wishlistResource: WishlistResource;

  constructor() {
    super();

    this.wishlistResource = new WishlistResource(this.config);
  }
}

export default WishlistService;
