import { Form, Button, message, Alert } from "antd";
import { useDeleteCategoryCommission } from "lib/core-react/hooks/private/useCommission";
import { useState, useEffect } from "react";
import { ICategoryCommission } from "types/commissionCollection";

interface IProps {
  setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCategoryCommission: ICategoryCommission;
}

const DeleteCategoryCommission = ({ setIsShowDeleteModal, selectedCategoryCommission }: IProps) => {
  const { deleteCategoryCommission, isLoading, isError } = useDeleteCategoryCommission();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onConfirmDelete = async () => {
    try {
      await deleteCategoryCommission(selectedCategoryCommission.id);
      setIsShowDeleteModal(false);
      setIsErrorVisible(false);
      message.success({ content: 'Category commission deleted successfully.', key: 'deleteCategoryCommission', duration: 2 })
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
    <Form layout="vertical">
      <Form.Item>
        <h3>Are you sure you want to delete this category commission?</h3>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          loading={isLoading}
          onClick={onConfirmDelete}
        >
          Yes
        </Button> <Button disabled={isLoading} onClick={() => setIsShowDeleteModal(false)}>No</Button>
      </Form.Item>
      </Form>
    
      {isErrorVisible && (
       <Alert
        style={{ marginTop: "7px" }}
        message={isError}
        closable
        afterClose={() => setIsErrorVisible(false)}
        type="error"
        showIcon
        />
      )}

    </>
  )
}

export default DeleteCategoryCommission
