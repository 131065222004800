import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Table,
  Button,
  Tag,
  Row,
  Card,
  Col,
  Space,
  Modal,
  Typography,
  Flex,
  Dropdown,
  Form,
  message,
  notification,
} from "antd";
import {
  DiffOutlined,
  DownOutlined,
  FolderAddTwoTone,
  HddFilled,
  PartitionOutlined,
  SafetyCertificateOutlined,
  TeamOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";

import {
  useGetAgentCompany,
  usePurchaseCommissionCreate,
} from "lib/core-react/hooks/private";
import { agentCompanyCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import {
  AgentCompanyCollectionModel,
  AgentCompanyModel,
} from "models/agentCompanyCollectionModel";
import { PaginationModel } from "models/pagination";
import { IFilterType } from "types/filters";
import { AgentCompanyVerificationStatusEnums } from "enums/shared";

import FiltersComponent from "components/FiltersComponent";
import {
  MembersTable,
  PurchaseCommissionModification,
  PurchaseCommissionTable,
  ShippingPointsTable,
  VerificationsTable,
} from "./components";
import { IPurchaseCommissionFormData } from "types/purchaseCommissionCollection";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
import { ADMIN_WALLET_PERMISSION_ENUM } from "consts/permission-enum/wallet-enum";


const AgentCompany = () => {
  const { getAgentCompanies } = useGetAgentCompany();
  const {
    createPurchaseCommission,
    isLoading: isLoadingCreatePurchaseCommission,
  } = usePurchaseCommissionCreate();
  const [{ data: agentCompanyCollectionData, isLoading, refetch }] = useAtom(
    agentCompanyCollectionAtom,
  );

  const { isMobile } = useWindowWidth();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  const AgentCompanyCollectionData =
    agentCompanyCollectionData &&
    new AgentCompanyCollectionModel(agentCompanyCollectionData);

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
    }
  }, [isFirstCall, filters, isFetched, refetch]);

  // Filter
  const filterData = AgentCompanyCollectionData?.getFilters();
  // Pagination
  const paginationData = AgentCompanyCollectionData?.getPagination();

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && AgentCompanyCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    AgentCompanyCollectionData?.getFilters(),
  ]);

  const [selectedCompany, setSelectedCompany] = useState<
    AgentCompanyModel | undefined
  >(undefined);

  const [modalOpen, setModalOpen] = useState<
    | "addCommission"
    | "updateCompany"
    | "members"
    | "shippingPoints"
    | "verifications"
    | "commission"
    | undefined
  >(undefined);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentCompanies,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AgentCompanyCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
  };

  const handleMenuClick = (e: MenuInfo, record: AgentCompanyModel) => {
    setSelectedCompany(record);

    if (e.key === "update") {
      setModalOpen("updateCompany");
    }
    if (e.key === "add-commission") {
      setModalOpen("addCommission");
    }
  };

  const handleClick = (id: Number) => {
    navigate(`/agent-manage/agent-company/${id}/wallet`);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: AgentCompanyModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
              index +
              1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Company info",
      dataIndex: "primary_name",
      key: "primary_name",
      render: (_: string, record: AgentCompanyModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Business name: </Typography.Text>
              <Typography.Text>{record.getPrimaryName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Brand name: </Typography.Text>
              <Typography.Text>{record.getSecondaryName()}</Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Director: </Typography.Text>
              {record.getDirectorName() && record.getDesignation() ? (
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  {record.getDirectorName()} ({record.getDesignation()})
                </Typography.Text>
              ) : (
                ""
              )}
            </Space>
            <Space>
              <Typography.Text strong>Registered country: </Typography.Text>
              {record.getRegisteredCountryDetail() &&
                record.getRegisteredCountryDetail()?.getName() ? (
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  {record.getRegisteredCountryDetail()?.getName()}
                </Typography.Text>
              ) : (
                ""
              )}
            </Space>
            <Space>
              <Typography.Text strong>Type: </Typography.Text>
              <Typography.Text style={{ textTransform: "capitalize" }}>
                {record.getCompanyType()
                  ? record.getCompanyType().replaceAll("-", " ")
                  : ""}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>
                {record.getBusinessPhone() ? (
                  <a href={`tel:${record.getBusinessPhone()}`}>
                    {record.getBusinessPhone()}
                  </a>
                ) : (
                  ""
                )}
              </Typography.Text>
            </Space>
            {/* <Space>
              <Typography.Text strong>Years of experience: </Typography.Text>
              <Typography.Text style={{ textTransform: "capitalize" }}>
                {record.getYearsOfExperience()
                  ? record.getYearsOfExperience().replaceAll("_", " ")
                  : ""}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Operation status: </Typography.Text>
              {record.getOperationStatus() ? (
                <Tag
                  color={
                    record.getOperationStatus() ===
                    AgentCompanyOperationStatusEnums.CLOSED_OPERATION
                      ? "error"
                      : "success"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {record.getOperationStatus().replaceAll("-", " ")}
                </Tag>
              ) : (
                ""
              )}
            </Space> */}
            <Space>
              <Typography.Text strong>Verification status: </Typography.Text>
              {record.getVerificationStatus() ? (
                <Tag
                  style={{ textTransform: "capitalize" }}
                  color={
                    record.getVerificationStatus() ===
                      AgentCompanyVerificationStatusEnums.APPROVED
                      ? "success"
                      : "error"
                  }
                >
                  {record.getVerificationStatus()}
                </Tag>
              ) : (
                ""
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_: string, record: AgentCompanyModel) => {
        return (
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: 10, width: 200 }}
          >
            {record.getAddress() && Object.keys(record.getAddress()).length ? (
              Object.keys(record.getAddress()).map((key) => (
                <div key={key}>
                  <Typography.Text
                    strong
                    style={{ textTransform: "capitalize" }}
                  >
                    {key}:
                  </Typography.Text>{" "}
                  {record.getAddress()[key]}
                </div>
              ))
            ) : (
              <Tag color="error">Address not found</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Additional",
      dataIndex: "verifications",
      key: "verifications",
      align: "center" as const,
      render: (_: string, record: AgentCompanyModel) => {
        return (
          <Flex gap="small" vertical>
            {
              checkActionPermission(ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
                <Button
                  onClick={() => handleClick(record.getId())}
                  icon={<WalletOutlined />}
                >
                  Agent Wallet Balance
                </Button>
                , null
              )
            }

            {
              checkActionPermission(ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
                <Button
                  onClick={() => {
                    setSelectedCompany(record);
                    setModalOpen("verifications");
                  }}
                  icon={<SafetyCertificateOutlined />}
                >
                  Verifications
                </Button>
                , null
              )
            }

            {
              checkActionPermission(ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_MEMBER_ADMIN_VIEW,
                <Button
                  onClick={() => {
                    setSelectedCompany(record);
                    setModalOpen("members");
                  }}
                  icon={<TeamOutlined />}
                >
                  Members
                </Button>
                , null
              )
            }


            {
              checkActionPermission(ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_SHIPPING_POINT_ADMIN_VIEW,

                <Button
                  onClick={() => {
                    setSelectedCompany(record);
                    setModalOpen("shippingPoints");
                  }}
                  icon={<PartitionOutlined />}
                >
                  Shipping Points
                </Button>
                , null
              )
            }


            {
              checkActionPermission(ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_VIEW,

                <Button
                  onClick={() => {
                    setSelectedCompany(record);
                    setModalOpen("commission");
                  }}
                  icon={<DiffOutlined />}
                >
                  Commissions
                </Button>
                , null
              )
            }

          </Flex>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: AgentCompanyModel) => {
        const menuItems: ExtendedMenuItemType[] = [
          // {
          //   label: "Update",
          //   key: "update",
          //   icon: <EditTwoTone />,
          //   onClick: (e) => handleMenuClick(e, record),
          // },
          {
            permission: ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD,
            label: "Add Commission",
            key: "add-commission",
            icon: <FolderAddTwoTone />,
            onClick: (e) => handleMenuClick(e, record),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: menuItems.filter(x => checkActionPermission(x.permission, x, null)),
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const onFinish = async (values: any) => {
    try {
      const payload: IPurchaseCommissionFormData = {
        store_commissions: values.store_commissions,
      };

      if (selectedCompany) {
        await createPurchaseCommission(selectedCompany.getId(), payload);
        notification["success"]({
          message: "Create Successfully",
        });
        setSelectedCompany(undefined);
        setModalOpen(undefined);
      } else {
        message.error("No company selected!");
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Agent Companies"
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <Table
              style={{ marginTop: 10 }}
              bordered
              //@ts-ignore
              columns={columns}
              dataSource={AgentCompanyCollectionData?.data}
              size="middle"
              loading={isLoading}
              pagination={paginationConfig}
              rowKey="id"
              scroll={{ x: 1237 }}
            />
          </PageHeader>
        </Col>
      </Row>

      <Modal
        open={Boolean(selectedCompany) && Boolean(modalOpen)}
        onCancel={() => {
          setSelectedCompany(undefined);
          setModalOpen(undefined);
        }}
        width={
          isMobile ? "90vw" : modalOpen !== "addCommission" ? "70vw" : undefined
        }
        footer={modalOpen === "addCommission" ? undefined : null}
        onOk={form.submit}
        okText={modalOpen === "addCommission" ? "Add" : ""}
        okButtonProps={{ loading: isLoadingCreatePurchaseCommission }}
        title={
          modalOpen === "addCommission"
            ? `Add Commission (${selectedCompany?.getPrimaryName()})`
            : modalOpen === "members"
              ? `Members (${selectedCompany?.getPrimaryName()})`
              : modalOpen === "shippingPoints"
                ? `Shipping Points (${selectedCompany?.getPrimaryName()})`
                : modalOpen === "commission"
                  ? `Commissions (${selectedCompany?.getPrimaryName()})`
                  : `Verifications (${selectedCompany?.getPrimaryName()})`
        }
      >
        {modalOpen === "members" && selectedCompany && (
          <MembersTable data={selectedCompany} />
        )}
        {modalOpen === "shippingPoints" && selectedCompany && (
          <ShippingPointsTable data={selectedCompany} />
        )}
        {modalOpen === "verifications" && selectedCompany && (
          <VerificationsTable data={selectedCompany} />
        )}
        {modalOpen === "commission" && selectedCompany && (
          <PurchaseCommissionTable data={selectedCompany} />
        )}
        {modalOpen === "addCommission" && selectedCompany && (
          <PurchaseCommissionModification
            company={selectedCompany}
            form={form}
            onFinish={onFinish}
          />
        )}
      </Modal>
    </>
  );
};

export default AgentCompany;
