import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { walletOverviewCollectionAtom } from "lib/core-react/store/store";
import { useEffect, useState } from "react";
import { useWalletOverviewBalance } from "lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "models/walletOverviewCollectionModel";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";

const WalletOverview = () => {
    const { getWalletOverviewBalance } = useWalletOverviewBalance();

    const [{ data: walletOverviewData, refetch, error: isWalletErrors }] =
        useAtom(walletOverviewCollectionAtom);

    const [walletId, setWalletId] = useState<number[]>([]);

    useEffect(() => {
        getWalletOverviewBalance();
    }, [refetch]);

    const walletOverviewCollectionData =
        walletOverviewData && new WalletOverviewModel(walletOverviewData);

    const currencyData = walletOverviewCollectionData?.getByCurrency();

    const currencies = Object.entries(currencyData || {}).map(
        ([currencyCode, data]) => ({
            code: currencyCode,
            totalAvailableBalance: data.totalAvailableBalance,
            totalReservedBalance: data.totalReservedBalance,
            totalPendingBalance: data.totalPendingBalance,
            symbol: data.symbol,
            walletIds: data.walletIds,
        }),
    );

    const handleWalletBalanceClick = (walletIds) => {
        setWalletId(walletIds);
    };

    useEffect(() => {
        if (currencies && currencies[0]?.walletIds) {
            setWalletId(currencies[0]?.walletIds);
        }
    }, [walletOverviewData]);

    return (
        <PageHeader
            ghost={false}
            title="Master Wallet"
            style={{ marginTop: "10px" }}
            onBack={() => window.history.back()}
        >
            {isWalletErrors ? (
                <Skeleton active />
            ) : (
                <Row>
                    <WalletCard
                        walletId={walletId}
                        handleWalletBalanceClick={handleWalletBalanceClick}
                        item={currencies}
                    />
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <WalletTable isLoading={false} walletId={walletId} />
                </Col>
            </Row>
        </PageHeader>
    );
};

export default WalletOverview;
