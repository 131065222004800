// Enum for admin permissions in the AddonService module
export enum ADMIN_ADDON_SERVICE_PERMISSION_ENUM {
    ADMIN_ADDON_SERVICE_VIEW = "admin-addon-service-view",
    ADMIN_ADDON_SERVICE_ADD = "admin-addon-service-add",
    ADMIN_ADDON_SERVICE_UPDATE = "admin-addon-service-update",
    ADMIN_ADDON_SERVICE_PRICE_ADD = "admin-addon-service-price-add",
    ADMIN_ADDON_SERVICE_PRICE_UPDATE = "admin-addon-service-price-update",
    ADMIN_ADDON_SERVICE_PRICE_DELETE = "admin-addon-service-price-delete",
    ADMIN_ADDON_SERVICE_SYNC = "admin-addon-service-sync"
}

