import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import {
  Avatar,
  Card,
  Select,
  Skeleton,
  Tabs,
  TreeSelect,
  Typography,
} from "antd";
import { StarTwoTone } from "@ant-design/icons";

import {
  useGetDestinationWarehouse,
  useGetShippingCategory,
} from "lib/core-react/hooks/private";
import {
  agentCategoryPriceReadCollectionAtom,
  destinationWarehouseCollectionAtom,
  shippingCategoryAtom,
  shippingCountryCollectionAtom,
} from "lib/core-react/store/store";
import { ShippingCategoryModel } from "models/shippingCategory";
import { useGetCategoryPrices } from "lib/core-react/hooks/private/useShippingAgent";
import { AgentCategoryPriceReadCollectionModel } from "models/agentCategoryPriceReadCollectionModel";
import { IBuyPlaceOrderCartShippingFormData } from "types/buyOrderPlace";
import { DestinationWarehouseCollectionModel } from "models/destinationWarehouseCollectionModel";
import { useGetShippingCountries } from "lib/core-react/hooks/private/useShippingCountry";
import { ShippingCountryCollectionModel } from "models/shippingCountryCollectionModel";

interface IProps {
  placeBuyOrderProductStep2FormData:
    | IBuyPlaceOrderCartShippingFormData
    | undefined;
  setPlaceBuyOrderProductStep2FormData: Dispatch<
    SetStateAction<IBuyPlaceOrderCartShippingFormData>
  >;
  errors;
}

export const PlaceBuyOrderProductShippingMethodModal = ({
  placeBuyOrderProductStep2FormData,
  setPlaceBuyOrderProductStep2FormData,
}: IProps) => {
  const { getDestinationWarehouse } = useGetDestinationWarehouse();
  const [{ data: warehouseData }] = useAtom(destinationWarehouseCollectionAtom);
  const location = useLocation();
  const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");
  const userId = new URLSearchParams(location.search).get("userId");
  const region_id = new URLSearchParams(location.search).get("region_id");
  const region = new URLSearchParams(location.search).get("region");

  // Warehouse
  const DestinationWarehouseCollectionData =
    warehouseData && new DestinationWarehouseCollectionModel(warehouseData);

  const [productCategory, setProductCategory] = useState<string | undefined>();
  const [destinationWarehouse, setDestinationWarehouse] = useState<
    string | undefined
  >();

  const [shippingCountry, setSetShippingCountry] = useState<
    string | undefined
  >();

  const [shippingType, setShippingType] = useState<string>("air");
  const [shippingMode, setShippingMode] = useState<string>("cargo");

  const { getShippingCategory } = useGetShippingCategory();
  const { getCategoryPrices } = useGetCategoryPrices();
  const { getShippingCountries } = useGetShippingCountries();

  useEffect(() => {
    getDestinationWarehouse(); //fix it
    getShippingCategory();
    if(region){
      getShippingCountries(`region_id=${region_id}`);
    }
  }, [region]);

  useEffect(() => {
    if(productCategory){
      getCategoryPrices({
        user_id: Number(userId),
        region: region as string,
        destination_warehouse_id: Number(destinationWarehouse),
        shipping_category_id: productCategory,
        shipping_country_id: Number(shippingCountry),
        shipping_mode: shippingMode,
        shipping_type: shippingType,
      });
    }else{
      getCategoryPrices(undefined);
    }

  }, [shippingType, shippingMode, buyOrderId, productCategory,destinationWarehouse,shippingCountry]);

  const {
    data: shippingCategoryCollection,
    isLoading: shippingCategoryLoading,
  } = useAtomValue(shippingCategoryAtom);
  const {
    data: categoryPriceReadCollection,
    isLoading: categoryPriceReadLoading,
  } = useAtomValue(agentCategoryPriceReadCollectionAtom);

  const [{ data: shippingCountryCollectionData, isLoading }] = useAtom(
    shippingCountryCollectionAtom,
  );

  const ShippingCountryData =
    shippingCountryCollectionData &&
    new ShippingCountryCollectionModel(shippingCountryCollectionData);

  const shippingCategoryCollectionData =
    shippingCategoryCollection &&
    new ShippingCategoryModel(shippingCategoryCollection);

  const categoryPriceReadCollectionData =
    categoryPriceReadCollection &&
    new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection);

  const { Text } = Typography;

  // if(!shippingCountry){
  //   return <Text type="danger">Please select a Shipping Country</Text>
  // }

  return (
    <Card title={'Shipping'} style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: "24px",
        }}
      >
        <Text strong>Select a Shipping Country</Text>
        <Select
          loading={isLoading}
          placeholder="Please select a Shipping Country"
          onChange={(value) => setSetShippingCountry(value)}
          options={ShippingCountryData?.getLabeledCountries()}
        />
        {/* {errors.shippingCategoryError && (
          <Text type="danger">{errors.shippingCategoryError}</Text>
        )} */}

        <Text strong>Select a destination Warehouse</Text>
        <Select
          loading={isLoading}
          placeholder="Please select a destination Warehouse"
          options={DestinationWarehouseCollectionData?.getDataAsLabelValue()}
          onChange={(value) => setDestinationWarehouse(value)}
        />

        <Text strong>Product Category</Text>
        <TreeSelect
          onChange={(value) => {
            setProductCategory(value)
            setPlaceBuyOrderProductStep2FormData(pre=>{
              return {
                ...pre,
                shipping_category_id:value
              }
            })
          }}
          showSearch
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select a category"
          allowClear
          loading={shippingCategoryLoading}
          treeLine={{ showLeafIcon: true }}
          treeData={
            shippingCategoryCollectionData
              ? shippingCategoryCollectionData.getShippingCategoryTreeIds()
              : []
          }
        />
        {/* {errors.shippingCategoryError && (
          <Text type="danger">{errors.shippingCategoryError}</Text>
        )} */}
      </div>
      <div>
        <Tabs
          onChange={(activeKey) => {
            setShippingType(activeKey);
            if (activeKey === "ship") {
              setShippingMode("cargo");
            }
          }}
          defaultActiveKey={shippingType}
          centered
          size="small"
          type="card"
          items={["By Air", "By Ship"].map((item) => {
            return {
              label: item,
              key: item.split(" ")[1].toLowerCase(),
            };
          })}
        />
      </div>
      <div>
        <Text style={{ fontSize: "18px" }} strong>
          Shipping Modes
        </Text>
        <Tabs
          onChange={(activeKey) => setShippingMode(activeKey)}
          defaultActiveKey={shippingMode}
          size="small"
          items={
            shippingType === "ship"
              ? ["Cargo"].map((item) => {
                  return {
                    label: item,
                    key: item.toLowerCase(),
                  };
                })
              : ["Cargo", "International", "P2P"].map((item) => {
                  return {
                    label: item,
                    key: item.toLowerCase(),
                  };
                })
          }
        />
      </div>
      <div>
        <Card
          title={<Text strong>Select Cargo Company</Text>}
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {categoryPriceReadLoading ? (
              <Skeleton active />
            ) : !categoryPriceReadCollectionData ? (
              <div>Please select a category</div>
            ) : categoryPriceReadCollectionData?.getData().length === 0 ? (
              <div>Shipping partner is not available for this category!</div>
            ) : (
              categoryPriceReadCollectionData?.getData().map((data) => (
                <div
                  onClick={() => {
                    productCategory &&
                      setPlaceBuyOrderProductStep2FormData({
                        agent_category_price_read_id: data.getId(),
                        provider: data.getAgentWarehouse().getName(),
                        shipping_category_id: productCategory,
                        shipping_country: Number(shippingCountry),
                      });
                  }}
                  key={data.getId()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "16px",
                    marginBottom: "16px",
                    cursor: "pointer",
                    border: `${
                      placeBuyOrderProductStep2FormData?.agent_category_price_read_id ===
                      data.getId()
                        ? "1px solid green"
                        : ""
                    }`,
                  }}
                >
                  <div style={{ display: "flex", gap: "12px" }}>
                    <Avatar
                      size={50}
                      shape="square"
                      style={{
                        backgroundColor: "#00897B",
                        color: "white",
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    >
                      {data.getAgentWarehouse().getName()[0]}
                    </Avatar>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: "16px" }}>
                        {data.getAgentWarehouse().getName()}
                      </Text>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div
                          style={{
                            borderRight: "2px solid #DDD",
                            paddingRight: "16px",
                            display: "flex",
                          }}
                        >
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" /> (700)
                        </div>
                        <div>
                          <Text strong>800+ </Text>
                          <Text style={{ color: "#707070", fontSize: "14px" }}>
                            Shipping Delivered
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    <Text strong>
                      {data.getRate()}/{data.getUnitType()}
                    </Text>
                    <Text style={{ fontSize: "12px", color: "#6b7386" }}>
                      Tax included
                    </Text>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card>
        {/* {errors.categoryPriceError && (
          <Text type="danger">{errors.categoryPriceError}</Text>
        )} */}
      </div>
    </Card>
  );
};
