import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
class V1Resource extends BaseResource {
  path = `/api/user/admin/customer-user/v1/customer-users`;
  adminPath =`/api/user/admin/admin-user/v1/admin-users`

  getUsers(
    params: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}?${params}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getAdmins(
    params: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.adminPath}?${params}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
}

export default V1Resource;
