import { useState } from "react";
import { useAtom } from "jotai";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  Typography,
  notification,
} from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

import { BuyProductVariationModel } from "models/buyOrderCollectionModel";
import {
  useDeleteVariations,
  useGetBuyOrdersDetail,
  useUpdateVariations,
} from "lib/core-react/hooks/private/usePurchase";
import { buyOrderDetailAtom } from "lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import { BuyOrderDetailModel } from "models/buyOrderDetailModel";
import useWindowWidth from "../../../../../lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";

const { Text } = Typography;
const ProductDetailMeta = ({
  id,
  detailId,
}: {
  id: string | undefined;
  detailId: number;
}) => {
  const [{ data }] = useAtom(buyOrderDetailAtom);
  const { getBuyOrdersDetail } = useGetBuyOrdersDetail();

  const orderDetail = data && new BuyOrderDetailModel(data);
  const productMeta = orderDetail
    ?.getProducts()
    .getDataById(detailId)
    ?.getProductVariations()
    .getData();

  const { updateVariations, isLoading: isUpdateLoading } =
    useUpdateVariations();
  const { deleteVariations, isLoading: isDeleteLoading } =
    useDeleteVariations();
  const { isMobile } = useWindowWidth();
  const [selectedRow, setSelectedRow] = useState<BuyProductVariationModel>();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [quantity, setQuantity] = useState(selectedRow?.getQuantity());

  const handleMenuClick = async (e: MenuInfo) => {
    if (e.key === "update-variant") {
      setShowUpdateModal(true);
    }
  };

  const handleUpdateVariant = async () => {
    if (selectedRow && quantity) {
      try {
        await updateVariations(selectedRow.getBuyProductId(), {
          variations: [
            {
              variation_id: selectedRow.getId(),
              original_unit_price: selectedRow.getOriginalUnitPrice(),
              quantity,
              sku_id: selectedRow.getSkuId(),
            },
          ],
        });
        if (id) await getBuyOrdersDetail(id.toString());
        setShowUpdateModal(false);
        setSelectedRow(undefined);
      } catch (error) {
        // message.error(getError(error));
      }
    } else {
      notification["warning"]({
        message: "Please update quantity",
      });
    }
  };

  const handleDeleteVariant = async (
    buyProductId: number,
    variantId: number,
  ) => {
    try {
      await deleteVariations(buyProductId, {
        ids: [variantId],
      });
      if (id) await getBuyOrdersDetail(id.toString());
    } catch (error) {}
  };


  const columns = [
    {
      title: "Meta",
      dataIndex: "meta",
      render: (_: string, record: BuyProductVariationModel) => (
        <div>
          {record
            .getProperties()
            .getData()
            .map((singleProperty) => {
              return (
                <div
                  key={singleProperty.getId()}
                  style={{
                    display: "flex",
                    gap: "4px",
                    textTransform: "capitalize",
                  }}
                >
                  <span>{singleProperty.getPropertyName()}:</span>
                  <span style={{ fontWeight: "bold" }}>
                    {singleProperty.getPropertyValueName()}
                  </span>
                </div>
              );
            })}
        </div>
      ),
    },
    {
      hidden: isMobile,
      title: "Quantity",
      dataIndex: "quantity",
      render: (_: string, record: BuyProductVariationModel) => {
        return <div>{record.getQuantity()}</div>;
      },
    },
    {
      hidden: isMobile,
      title: "Price",
      dataIndex: "price",
      render: (_: string, record: BuyProductVariationModel) => {
        return <div>{record.getUnitPrice()}</div>;
      },
    },
    {
      hidden: isMobile,
      title: "Total Price",
      dataIndex: "totalPrice",
      render: (_: string, record: BuyProductVariationModel) => {
        return <div>{record.getTotalPrice()}</div>;
      },
    },
    {
      hidden: !isMobile,
      title: "Financial Details",
      dataIndex: "productDetails",
      render: (_: string, record: BuyProductVariationModel) => {
        return (
          <div>
            <div>
              <Text strong>Quantity: </Text>
              {record.getQuantity()}
            </div>
            <div>
              <Text strong>Price: </Text>
              {record.getUnitPrice()}
            </div>
            <div>
              <Text strong>Total Price: </Text>
              {record.getTotalPrice()}
            </div>
            <div>
              <Text strong>Payment Status: </Text>

            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: string, record: BuyProductVariationModel) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission:
              ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
            icon: <EditOutlined />,
            label: "Update variant",
            key: "update-variant",
          },
          {
            permission:
              ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
            icon: <DeleteOutlined />,
            label: (
              <Popconfirm
                title="Delete this variant?"
                onConfirm={() =>
                  handleDeleteVariant(record.getBuyProductId(), record.getId())
                }
                okButtonProps={{
                  loading: isDeleteLoading,
                  disabled: isDeleteLoading,
                }}
                disabled={isDeleteLoading}
                okText={isDeleteLoading ? "Please wait..." : "Delete"}
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>Delete variant</span>
              </Popconfirm>
            ),
            key: "delete-variant",
          },
        ];

        const menuProps = {
          items,
          onClick: handleMenuClick,
        };
        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedRow(record)}
          >
            <Button icon={<HddFilled />}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={productMeta}
        size="small"
        pagination={false}
        key="id"
        className={"meta-table"}
      />

      {selectedRow && (
        <Modal
          centered
          title="Update Variation Quantity"
          open={showUpdateModal}
          onCancel={() => setShowUpdateModal(false)}
          onOk={() => handleUpdateVariant()}
          confirmLoading={isUpdateLoading}
          okText={"Update"}
          destroyOnClose={true}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            Quantity:
            <InputNumber
              defaultValue={selectedRow.getQuantity()}
              min={1}
              onChange={(value: number | null) => {
                if (value !== null) setQuantity(value);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductDetailMeta;
