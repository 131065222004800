import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TreeSelect,
} from "antd";
import { useAtom } from "jotai";
import { regionRegion, shippingCategoryAtom } from "lib/core-react/store/store";
import React, { useEffect, useState } from "react";
import { useUpdateAuctionRule } from "lib/core-react/hooks/private/useAuctionRule";
import {
  AuctionRuleDataStatus,
  AuctionRuleOperatorEnum,
  AuctionRuleTypeEnum,
} from "enums/auctionRuleCollectionEnums";
import {
  AuctionRuleDataModel,
  AuctionRuleRequestModel,
} from "models/auctionRule";
import {
  renderOptionsFromEnum,
  renderOptionsFromEnum2,
} from "components/Form/forms";
import { RegionModel } from "models/region";
import { ShippingCategoryModel } from "models/shippingCategory";
import {
  useGetRegion,
  useGetShippingCategory,
} from "lib/core-react/hooks/private";

interface IProps {
  data: AuctionRuleDataModel;
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateAuctionRule = ({ data, setIsShowUpdateModal }: IProps) => {
  const { updateAuctionRule, isLoading, isError } = useUpdateAuctionRule();
  const [visible, setVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [data, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  // Region, base Shipping category
  const { getRegion } = useGetRegion();
  const { getShippingCategory } = useGetShippingCategory();

  useEffect(() => {
    getRegion("per_page=200");
    getShippingCategory("per_page=200");
    
  }, []);

  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);
  const [
    { data: shippingCategoryDataAtom, isLoading: shippingCategoryLoading },
  ] = useAtom(shippingCategoryAtom);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);
  const shippingCategoryData =
    shippingCategoryDataAtom &&
    new ShippingCategoryModel(shippingCategoryDataAtom);

  const onFinish = async (value: AuctionRuleRequestModel) => {
    console.log(value, "value");
    const payload = {
      name: value.name,
      status: value.status,
      region_id: value.region_id,
      operator: value.operator,
      shipping_category_id: value.shipping_category_id,
      value: value.value,
      rule_type: value.rule_type,
    };

    try {
      await updateAuctionRule(data.id as number, payload);
      setIsShowUpdateModal(false);
      setVisible(false);
    } catch (error) {
      setVisible(true);
    }
  };
  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Edit_Auction_Rules"
          ref={formRef}
          initialValues={{
            name: data.name,
            region_id: data.region_id.toString(),
            value: data.value,
            operator: data.operator,
            shipping_category_id: data.shipping_category_id,
            status: data.status,
            value_action: data.value,
            rule_type: data.rule_type,
          }}
        >
          {/* <Form.Item label="ID" name="id">
            <Input disabled placeholder="Auction ID" />
          </Form.Item> */}
          <Form.Item
            rules={[{ required: true, message: "Auction name is required" }]}
            label="Name"
            name="name"
          >
            <Input placeholder="Auction name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Region is required" }]}
            name="region_id"
            label="Region"
          >
            <Select
              loading={regionLoading}
              placeholder="Please select a region"
            >
              {regionData?.getRegionIdsAsObject() &&
                renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
            </Select>
          </Form.Item>

          <Form.Item required name="operator" label="Operator">
            <Select
              placeholder="Please select a operator"
              value={data.operator}
            >
              {renderOptionsFromEnum2(AuctionRuleOperatorEnum)}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Shipping category is required" },
            ]}
            label="Shipping Category"
            name="shipping_category_id"
          >
            <TreeSelect
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Please select a shipping category"
              allowClear
              loading={shippingCategoryLoading}
              treeLine={{ showLeafIcon: true }}
              treeData={shippingCategoryData?.getShippingCategoryTreeIds()}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Auction value is required" }]}
            label="Auction Value"
            name="value"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Auction Value"
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Rule type is required" }]}
            name="rule_type"
            label="Rule Type"
          >
            <Select placeholder="Please select a rule type">
              {renderOptionsFromEnum2(AuctionRuleTypeEnum)}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Status is required" }]}
            name="status"
            label="Status"
          >
            <Select placeholder="Please select a status">
              {renderOptionsFromEnum(AuctionRuleDataStatus)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {visible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default UpdateAuctionRule;
