import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { agentCategoryPriceReadCollectionAtom } from "lib/core-react/store/store";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";
import { getError } from "../utils/errors";
import { useState } from "react";

export const useGetCategoryPrices = () => {
  const [
    agentCategoryPriceReadCollectionData,
    setAgentCategoryPriceReadCollectionData,
  ] = useAtom(agentCategoryPriceReadCollectionAtom);
  const [isError, setError] = useState<string | null>(null);
  const { shippingAgentService } = useService();
  const getCategoryPrices = async (
    params: ICategoryPriceDetails | undefined,
  ): Promise<void> => {
    setAgentCategoryPriceReadCollectionData({
      ...agentCategoryPriceReadCollectionData,
      isLoading: true,
      error: null,
      data:undefined
    });

    if(!params){
      setAgentCategoryPriceReadCollectionData({
        ...agentCategoryPriceReadCollectionData,
        isLoading: false,
        data:undefined
      });
      return
    }

    try {
      const response =
        await shippingAgentService.categoryPriceResource.getCategoryPrice(
          params,
        );
      setAgentCategoryPriceReadCollectionData({
        ...agentCategoryPriceReadCollectionData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentCategoryPriceReadCollectionData({
        ...agentCategoryPriceReadCollectionData,
        isLoading: false,
        refetch: false,
        error: getError(error),
        data:undefined
      });
      setError(error);
    }
  };
  return { getCategoryPrices, isError } as const;
};
