import type { DescriptionsProps } from "antd";
import { Card, Descriptions } from "antd";

export const ProductPackage = ({ isLoading, productData, title }) => {
  const productPackage = productData?.getBuyProductPackage();
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Height Unit",
      children: productPackage?.getHeightUnit(),
    },
    {
      key: "2",
      label: "Height",
      children: productPackage?.getHeight(),
    },
    {
      key: "3",
      label: "Width Unit",
      children: productPackage?.getWidthUnit() || "none",
    },
    {
      key: "4",
      label: "Width",
      children: productPackage?.getWidth() || "none",
    },
    {
      key: "5",
      label: "Lenght Unit",
      children: productPackage?.getLengthUnit() || "none",
    },
    {
      key: "6",
      label: "Lenght",
      children: productPackage?.getLength() || "none",
    },
    {
      key: "7",
      label: "Weight Unit",
      children: productPackage?.getWeightUnit() || "none",
    },
    {
      key: "8",
      label: "Weight",
      children: productPackage?.getWeight() || "none",
    },
  ];
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {productPackage ? (
        <Descriptions bordered size="small" layout="vertical" items={items} />
      ) : (
        "Agent association not available yet."
      )}
    </Card>
  );
};
