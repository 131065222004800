import React from 'react';
import {Button, Form, Input, InputNumber, Switch, Col, Row} from 'antd';
import {IInvoicePaymentRuleBaseData} from "../../../../types/InvoiceRoleCollection";
import {InvoicePaymentRuleModel} from "../../../../models/invoicePaymentRoleModel";
import {useInvoicePaymentRule} from "../../../../lib/core-react/hooks/private/useInvoicePaymentRule";
import {useAtom} from "jotai";
import {invoicePaymentRuleCollectionAtom} from "../../../../lib/core-react/store/store";

interface InvoiceRuleFormProps {
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: InvoicePaymentRuleModel | undefined;
  modelFor:"create"| "update" | "delete"
}

const InvoiceRuleForm: React.FC<InvoiceRuleFormProps> = ({ modelFor,  selectedData, setIsShowModal }) => {
  const [,setInvoicePaymentCollection] = useAtom(invoicePaymentRuleCollectionAtom);

   const {updatePaymentRules, createPaymentRules, isCreateLoading, isUpdateLoading} = useInvoicePaymentRule()
   const [form] = Form.useForm();

  const initialValues = {
    name: selectedData?.getName(),
    is_default: selectedData?.isDefault() ? 1:0,
    is_wallet_enabled: selectedData?.isWalletEnabled() ? 1: 0,
    wallet_payment_percentage: selectedData?.getWalletPaymentPercentage(),
    is_automatic_gateway_enabled: selectedData?.isAutomaticGatewayEnabled() || 0,
    automatic_gateway_payment_percentage: selectedData?.getAutomaticGatewayPaymentPercentage(),
    is_bank_deposit_enabled: selectedData?.isBankDepositEnabled() ? 1:0,
    bank_deposit_payment_percentage: selectedData?.getBankDepositPaymentPercentage(),
    is_mobile_merchant_enabled: selectedData?.isMobileMerchantEnabled() ? 1 : 0,
    mobile_merchant_payment_percentage: selectedData?.getMobileMerchantPaymentPercentage(),
  }
  const onFinish = async(values:IInvoicePaymentRuleBaseData)=>{

    try {
      const modifyValue = {
        name:values.name,
        is_default: values.is_default ? 1:0,
        is_wallet_enabled: values.is_wallet_enabled ? 1: 0,
        wallet_payment_percentage: values.wallet_payment_percentage,
        is_automatic_gateway_enabled: values.is_automatic_gateway_enabled || 0,
        automatic_gateway_payment_percentage: values.automatic_gateway_payment_percentage,
        is_bank_deposit_enabled:values.is_bank_deposit_enabled ? 1:0,
        bank_deposit_payment_percentage: values.bank_deposit_payment_percentage,
        is_mobile_merchant_enabled: values.is_mobile_merchant_enabled ? 1 : 0,
        mobile_merchant_payment_percentage: values.mobile_merchant_payment_percentage,
      }
      if(modelFor === "create"){
        await createPaymentRules(modifyValue)
      }else if (modelFor === "update" && selectedData?.getId()){
        await updatePaymentRules(selectedData.getId(),modifyValue)
      }
      setIsShowModal(false)
      setInvoicePaymentCollection(pre=>{
        return {
          ...pre, refetch:true
        }
      })
    }catch (e) {

    }
  }
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name="invoice_rule_form"
      initialValues={initialValues}
    >
      <Row style={{marginTop:"20px"}} gutter={16}>

        <Col span={14}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the name of the rule!' },
              { type: 'string',
                message: 'Name must be a string'
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="is_default"
            label="Default"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

     </Row>
      <Row gutter={16}>

        <Col span={14}>
          <Form.Item
            name="wallet_payment_percentage"
            label="Wallet Payment ( % )"
            rules={[{ required: true, message: 'Please input the wallet payment percentage!' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="is_wallet_enabled"
            label="Wallet Enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16}>

        <Col span={14}>
          <Form.Item
            name="automatic_gateway_payment_percentage"
            label="Automatic Gateway Payment  ( % )"
            rules={[{ required: true, message: 'Please input the automatic gateway payment percentage!' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="is_automatic_gateway_enabled"
            label="Automatic Gateway Enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16}>

        <Col span={14}>
          <Form.Item
            name="bank_deposit_payment_percentage"
            label="Bank Deposit Payment ( % )"
            rules={[{ required: true, message: 'Please input the bank deposit payment percentage!' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="is_bank_deposit_enabled"
            label="Bank Deposit Enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16}>

        <Col span={14}>
          <Form.Item
            name="mobile_merchant_payment_percentage"
            label="Mobile Merchant Payment ( % )"
            rules={[{ required: true, message: 'Please input the mobile merchant payment percentage!' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="is_mobile_merchant_enabled"
            label="Mobile Merchant Enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

      </Row>
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={{ minWidth: '100px' }} onClick={() => setIsShowModal(false)}>
            Cancel
          </Button>
          <Button disabled={isCreateLoading || isUpdateLoading} loading={isCreateLoading || isUpdateLoading} style={{ marginLeft: '10px', minWidth: '100px' }} type="primary" htmlType="submit">
            {modelFor === "update" ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default InvoiceRuleForm;
