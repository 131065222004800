import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Dropdown,
  Row,
  Table,
  Col,
  Button,
  Tooltip,
  Modal,
} from "antd";
import {
  DownOutlined,
  ClusterOutlined,
  HddFilled,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";

import { permissionAtom, roleAtom } from "lib/core-react/store/store";
import { useGetPermission } from "lib/core-react/hooks/private/usePermission";
import { useGetRole } from "lib/core-react/hooks/private/useRole";
import useDataFilters from "hooks/useDataFilters";
import PermissionsDrawer from "./components/PermissionsDrawer";
import RoleModification from "./components/RoleModification";
import { RoleCollectionModel, RoleModel } from "models/roleCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { PermissionCollectionModel } from "models/permissionCollectionModel";
import { v4 } from "uuid";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_ACL_PERMISSION_ENUM } from "consts/permission-enum/ACL-enum";
import { ExtendedMenuItemType } from "types";

const Role = () => {
  const { getRole } = useGetRole();
  const [{ data: roleData, isLoading, refetch }] = useAtom(roleAtom);
  const { isMobile } = useWindowWidth();
  const { getPermission } = useGetPermission();
  const [{ data: permissionData }] = useAtom(permissionAtom);

  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();

  const RoleData = roleData && new RoleCollectionModel(roleData);
  const PermissionData =
    permissionData && new PermissionCollectionModel(permissionData);

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getRole);
      ApiHelperModel.makeGetRequest({}, getPermission);
    }
    
  }, [isFirstCall, filters, isFetched, refetch]);

  // Pagination
  const paginationData = RoleData?.pagination;

  const [selectedRole, setSelectedRole] = useState<RoleModel | undefined>(
    undefined,
  );
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [isShowPermissionModal, setIsShowPermissionModal] =
    useState<boolean>(false);

  const handleMenuClick = (key: string, record: RoleModel) => {
    setSelectedRole(record);
    if (key === "update") {
      setIsShowUpdateModal(true);
    }
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getRole,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    RoleData,
    handlePaginationChange,
  );

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: RoleModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
              index +
              1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => {
        return (
          <>
            <Row>{text}</Row>
          </>
        );
      },
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "label",
      width: 70,
      hidden: isMobile,
      align: "center" as const,
      render: (_: PermissionCollectionModel, record: RoleModel) => (
        <>
          {checkActionPermission(
            ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,

            <Tooltip title="View Permissions">
              <Button
                onClick={() => {
                  setSelectedRole(record);
                  setIsShowPermissionModal(true);
                }}
                type="text"
                icon={<ClusterOutlined />}
              />
            </Tooltip>,
            null,
          )}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: isMobile ? "right" : undefined,
      width: isMobile ? 100 : undefined,
      align: "center" as const,

      render: (_, record) => {
        const items: ExtendedMenuItemType[] = [
          {
            key: "1",
            permission: ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
            label: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleMenuClick("update", record)}
              >
                <EditOutlined /> <span> Update</span>
              </div>
            ),
          },
        ];

        if (isMobile) {
          items.push({
            label: "Permission",
            permission: ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
            onClick: () => {
              setSelectedRole(record);
              setIsShowPermissionModal(true);
            },
            key: "details",
            icon: <ClusterOutlined />,
          });
        }

        return (
          <Dropdown
            menu={{
              items: items.filter((x) =>
                checkActionPermission(x.permission, x, null),
              ),
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const onHide = () => {
    setIsShowPermissionModal(false);
    setIsShowUpdateModal(false);
    setIsShowCreateModal(false);
    setSelectedRole(undefined);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            style={{ marginTop: "10px" }}
            title={"Roles"}
            extra={[
              checkActionPermission(
                ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_CREATE,
                <Button
                  key={v4()}
                  onClick={() => {
                    setIsShowCreateModal(true);
                  }}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Add new role
                </Button>,
                null
              )
            ]}
          >
            <Table
              //@ts-ignore
              columns={columns}
              dataSource={RoleData?.data}
              loading={isLoading}
              style={{ width: "100%" }}
              size="middle"
              pagination={paginationConfig}
              rowKey="id"
              scroll={{ x: 500 }}
            />
          </PageHeader>
        </Col>
      </Row >

      <Modal
        title={`Create Role`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <RoleModification onHide={onHide} />
      </Modal>

      {
        selectedRole && (
          <Modal
            title={`Update Role`}
            open={isShowUpdateModal}
            footer={false}
            onCancel={onHide}
          >
            <RoleModification onHide={onHide} role={selectedRole} />
          </Modal>
        )
      }

      {
        selectedRole && PermissionData && (
          <PermissionsDrawer
            show={isShowPermissionModal}
            onHide={onHide}
            allPermissions={PermissionData}
            role={selectedRole}
          />
        )
      }
    </>
  );
};

export default Role;
