import { BaseResource } from "../public";
import {IInvoicePaymentRoleCollection, IInvoicePaymentRuleBaseData} from "../../../../types/InvoiceRoleCollection";
import { ResponsePromise } from "lib/core/request/types/typings";

class InvoicePaymentRuleResource extends BaseResource {
  path = `/api/invoice/admin/invoice-payment-rule-group/v1/invoice-payment-rule-groups`;

  getInvoicePaymentRules(
    params?:string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise<IInvoicePaymentRoleCollection> {
    const path = `${this.path}?${
        params ? `&${params}` : ""
      }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create( payload :IInvoicePaymentRuleBaseData):ResponsePromise<IInvoicePaymentRoleCollection>{
    return this.client.request("POST", this.path, payload,undefined,{})
  }
  update( id:number, payload :IInvoicePaymentRuleBaseData):ResponsePromise<any>{
    return this.client.request("PUT", `${this.path}/${id}`, payload,undefined,{})
  }
  delete( id:number):ResponsePromise<any>{
    return this.client.request("DELETE",`${this.path}/${id}`,undefined,undefined,{})
  }

  attachCustomer( groupId:number, payload :{user_id:number}):ResponsePromise<any>{
    return this.client.request("POST", `${this.path}/${groupId}/attach-user`, payload,undefined,{})
  }
}

export default InvoicePaymentRuleResource;
