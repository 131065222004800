import { IPagination } from "types/pagination";
import { AgentCompanyShippingPointCollectionEnums } from "enums/agentCompanyShippingPointCollectionEnums";
import {
  IAgentCompanyShippingPoint,
  IAgentCompanyShippingPointCollection,
  IAgentCompanyShippingPointCountry,
  IAgentCompanyShippingPointRoutes,
} from "types/agentCompanyShippingPointCollection";
import { CountryCollectionEnums } from "enums/countryCollectionEnums";

export class AgentCompanyShippingPointCountryModel {
  object: CountryCollectionEnums.Country;
  id: number;
  name: string;
  code: string;
  flag: string;
  status: string;
  createdAt: string;
  constructor(dataItem: IAgentCompanyShippingPointCountry) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.code = dataItem.code;
    this.flag = dataItem.flag;
    this.status = dataItem.status;
    this.createdAt = dataItem.createdAt;
  }

  getId() {
    return this.id;
  }
  getName() {
    return this.name;
  }
  getCode() {
    return this.code;
  }
  getFlag() {
    return this.flag;
  }
}

export class AgentCompanyShippingPointRoutesModel {
  public readonly type: string;
  public readonly modes: string[];

  constructor(dataItem: IAgentCompanyShippingPointRoutes) {
    this.type = dataItem.type;
    this.modes = dataItem.modes;
  }

  getType() {
    return this.type;
  }
  getModes() {
    return this.modes;
  }
}

export class AgentCompanyShippingPointModel {
  public readonly object: AgentCompanyShippingPointCollectionEnums.AgentCompanyShippingPoint;
  public readonly id: number;
  public readonly routes: AgentCompanyShippingPointRoutesModel[];
  public readonly source_country: AgentCompanyShippingPointCountryModel;
  public readonly destination_country: AgentCompanyShippingPointCountryModel;

  constructor(dataItem: IAgentCompanyShippingPoint) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.routes = dataItem.routes.map(
      (item) => new AgentCompanyShippingPointRoutesModel(item)
    );
    this.source_country = new AgentCompanyShippingPointCountryModel(
      dataItem.source_country
    );
    this.destination_country = new AgentCompanyShippingPointCountryModel(
      dataItem.destination_country
    );
  }

  getId() {
    return this.id;
  }
  getRoutes() {
    return this.routes;
  }
  getDestinationCountry() {
    return this.destination_country;
  }
  getSourceCountry() {
    return this.source_country;
  }
}

export class AgentCompanyShippingPointCollectionModel {
  public readonly object: AgentCompanyShippingPointCollectionEnums.AgentCompanyShippingPointCollection;
  public readonly data: AgentCompanyShippingPointModel[];
  public readonly pagination: IPagination;

  constructor(data: IAgentCompanyShippingPointCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (item) => new AgentCompanyShippingPointModel(item)
    );
    this.pagination = data.pagination;
  }
  getAgentCompanyShippingPointById(
    id: number
  ): AgentCompanyShippingPointModel | undefined {
    return this.data.find((item) => item.id === id);
  }
}
