/* eslint-disable no-lone-blocks */
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Col,
    Dropdown,
    Image,
    Modal,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { transactionCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import {
    DownOutlined,
    EditOutlined,
    EyeFilled,
    FileImageOutlined,
    HddFilled,
} from "@ant-design/icons";
import {
    TransactionCollectionModel,
    TransactionModel,
} from "models/transactionCollectionModels";
import { useTransactions } from "lib/core-react/hooks/private/useTransactions";
import UpdateStatus from "./components/UpdateStatus";
import FiltersComponent from "components/FiltersComponent";
import { IFilterType } from "types/filters";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "components";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "consts/permission-enum/payment-enum";

const Transactions = () => {
    const { getTransactions } = useTransactions();
    const [{ data: transactionCollectionData, isLoading, refetch }] = useAtom(
        transactionCollectionAtom,
    );
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<TransactionModel[]>([]);
    const [gatewayTotals, setGatewayTotals] = useState<Record<string, number>>(
        {},
    );

    const { Text } = Typography;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const navigate = useNavigate();
    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getTransactions);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const TransactionsCollectionData =
        transactionCollectionData &&
        new TransactionCollectionModel(transactionCollectionData);

    const paginationData = TransactionsCollectionData?.getPagination();

    const filterData = TransactionsCollectionData?.getfilters();

    const [selectedTransactions, setSelectedTransactions] = useState<
        number | undefined
    >(undefined);

    const [selectedAttachments, setSelectedAttachments] = useState<string[]>(
        [],
    );

    const [updatePayload, setUpdatePayload] = useState<
        TransactionModel | undefined
    >(undefined);

    // Show update modal with data
    const showModal = (record: any) => {
        setUpdatePayload(record);
        setIsShowCreateModal(true);
    };

    const handleShowDetail = (record: number) => {
        setSelectedTransactions(record);
    };

    const handleShowAttachments = (attachments: string[]) => {
        setSelectedAttachments(attachments);
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (selectedTransactions !== undefined) {
            navigate(`/payment/transaction-detail/${selectedTransactions}`);
        }
    }, [selectedTransactions]);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && TransactionsCollectionData?.getfilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        TransactionsCollectionData?.getfilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getTransactions,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        TransactionsCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        filters && ApiHelperModel.makeGetRequest(filters, getTransactions);
    };

    const onSelectChange = (
        newSelectedRowKeys: React.Key[],
        newSelectedRows: TransactionModel[],
    ) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);

        const newGatewayTotals = newSelectedRows.reduce(
            (totals, row) => {
                const gatewayName = row.gateway.name;
                const amount = row.amount || 0;

                if (!totals[gatewayName]) {
                    totals[gatewayName] = 0;
                }

                totals[gatewayName] += amount;
                return totals;
            },
            {} as Record<string, number>,
        );

        setGatewayTotals(newGatewayTotals);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const totalAmount = selectedRows.reduce(
        (sum, row) => sum + (row.amount || 0),
        0,
    );

    const totalRequestAmount = selectedRows.reduce(
        (sum, row) => sum + (row.requested_amount || 0),
        0,
    );

    const stringToColor = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            width: 70,
            align: "center" as const,
            render: (_: string, __: TransactionModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Transaction Source",
            dataIndex: "transaction_source",
            key: "transaction_source",
            align: "center" as const,
            render: (_: string, record: TransactionModel) => {
                return <div>{record.getTransactionSource()}</div>;
            },
        },
        {
            title: "Gateway",
            dataIndex: "gateway",
            key: "gateway",
            align: "center" as const,
            render: (_: string, record: TransactionModel) => {
                return <div>{record.getGateway().getName()}</div>;
            },
        },
        {
            title: "Amount Details",
            dataIndex: "amount",
            key: "amount",
            children: [
                {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    width: 100,
                    render: (_: string, record: TransactionModel) => {
                        return (
                            <>
                                <Text strong>{record.getAmount()}</Text>
                            </>
                        );
                    },
                },
                {
                    title: "Requested",
                    dataIndex: "requested",
                    key: "requested",
                    width: 100,
                    render: (_: string, record: TransactionModel) => {
                        return (
                            <>
                                <Text strong>{record.getRequestAmount()}</Text>
                            </>
                        );
                    },
                },
                {
                    title: "Stored",
                    dataIndex: "stored ",
                    key: "stored",
                    width: 100,
                    render: (_: string, record: TransactionModel) => {
                        return (
                            <>
                                <Text strong>{record.getAmount()}</Text>
                            </>
                        );
                    },
                },
            ],
        },
        {
            title: "Details",
            dataIndex: "id",
            key: "details",
            align: "center" as const,
            render: (_: string, record: TransactionModel) => {
                const attachmentImages = record.attachments?.data.map(
                    (attachment) => attachment?.image.src,
                );
                return checkActionPermission(
                    PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
                    <>
                        <Tooltip title="View Details" key={"view_details"}>
                            <Button
                                onClick={() => handleShowDetail(record.id)}
                                shape="round"
                                icon={<EyeFilled />}
                            />
                        </Tooltip>
                        {attachmentImages && (
                            <Tooltip
                                title="View Slip & Attachment"
                                key={"view_slip"}
                            >
                                <Button
                                    style={{ marginLeft: 5 }}
                                    onClick={() =>
                                        handleShowAttachments(attachmentImages)
                                    }
                                    shape="round"
                                    icon={<FileImageOutlined />}
                                />
                            </Tooltip>
                        )}
                    </>,
                    null,
                );
            },
        },
        {
            title: "Transaction Type",
            dataIndex: "type",
            key: "type",
            align: "center" as const,
            render: (_: string, record: TransactionModel) => {
                return <div>{record.getGateway().getType()}</div>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center" as const,
            render: (_: string, record: TransactionModel) => {
                return (
                    <div>
                        <StatusTag
                            slug={record.getStatus()}
                            text={record.getStatus()}
                        />
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            render: (_: string, record: TransactionModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_UPDATE,
                        label: "Status Update",
                        key: "update_status",
                        icon: <EditOutlined />,
                        onClick: () => showModal(record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="All Transactions"
                    style={{ marginTop: "10px" }}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <Card title="Filter">
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        {hasSelected && (
                            <>
                                <Tag
                                    color="blue"
                                    style={{
                                        fontWeight: "bold",
                                        padding: 3,
                                        fontSize: 14,
                                        marginTop: 5,
                                    }}
                                >
                                    Selected {selectedRowKeys.length} items
                                </Tag>
                                <Tag
                                    color="green"
                                    style={{
                                        fontWeight: "bold",
                                        padding: 3,
                                        fontSize: 14,
                                        marginTop: 5,
                                    }}
                                >
                                    Total Amount: ৳{totalAmount.toFixed(2)}
                                </Tag>

                                <Tag
                                    color="red"
                                    style={{
                                        fontWeight: "bold",
                                        padding: 3,
                                        fontSize: 14,
                                        marginTop: 5,
                                    }}
                                >
                                    Requested Amount: ৳
                                    {totalRequestAmount.toFixed(2)}
                                </Tag>
                                {Object.entries(gatewayTotals).map(
                                    ([gateway, total]) => (
                                        <Tag
                                            key={gateway}
                                            color={stringToColor(gateway)}
                                            style={{
                                                fontWeight: "bold",
                                                padding: 3,
                                                fontSize: 14,
                                                marginTop: 5,
                                            }}
                                        >
                                            {gateway} - ৳{total.toFixed(2)}
                                        </Tag>
                                    ),
                                )}
                            </>
                        )}
                    </div>
                    <div>
                        <Table
                            rowSelection={{
                                type: "checkbox",
                                ...rowSelection,
                            }}
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={TransactionsCollectionData?.data}
                            //@ts-ignore
                            columns={columns}
                            pagination={paginationConfig}
                            scroll={{ x: 1237 }}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`Update Status`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => {
                    setIsShowCreateModal(false);
                    setUpdatePayload(undefined);
                }}
            >
                <UpdateStatus
                    setIsShowUpdateModal={setIsShowCreateModal}
                    data={updatePayload as TransactionModel}
                />
            </Modal>

            <Modal
                open={isModalVisible}
                title="Attachment Slip & Images"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                onOk={() => {
                    setIsModalVisible(false);
                }}
            >
                {selectedAttachments.length > 0 ? (
                    selectedAttachments.map((attachment, index) => (
                        <Image
                            key={index}
                            src={attachment}
                            style={{ marginBottom: 10, maxWidth: "100%" }}
                            alt={`attachment-${index}`}
                        />
                    ))
                ) : (
                    <p>No images selected</p>
                )}
            </Modal>
        </>
    );
};

export default Transactions;
