import React, { useEffect } from "react";
import { Avatar, Button, Card, Tag, Typography } from "antd";
import { HarvestBatchCollectionModel } from "models/harvestBatchCollectionModel";
import { useAtom } from "jotai";
import { harvestJobCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { useHarvestJobList } from "lib/core-react/hooks/private/userHarvestJob";
import { DownloadOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { UserTypeEnums } from "enums/harvestJobCollectionEnums";

interface HarvestJobDrawerProps {
    open: boolean;
    onClose: () => void;
}

const HarvestJobPopupOver: React.FC<HarvestJobDrawerProps> = () => {
    const [{ data: harvestJobListData, refetch }] = useAtom(
        harvestJobCollectionAtom,
    );
    const navigate = useNavigate();
    const { Text } = Typography;

    const { getHarvestJobList } = useHarvestJobList();

    const { isFirstCall, isFetched } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getHarvestJobList);
        }
    }, [isFirstCall, isFetched, refetch]);

    const HarvestJobCollectionData =
        harvestJobListData &&
        new HarvestBatchCollectionModel(harvestJobListData);

    const handleButtonRedirect = () => {
        navigate("/harvest-job");
    };

    const getColorForType = (status: string) => {
        switch (status) {
            case UserTypeEnums.AGENT:
                return "#265073";
            case UserTypeEnums.ADMIN:
                return "#0E46A3";
        }
    };

    return (
        <>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                {HarvestJobCollectionData &&
                    HarvestJobCollectionData.getData().map((item) => {
                        return (
                            <Card
                                key={item.getId()}
                                style={{ marginTop: "5px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <Meta
                                            avatar={
                                                <Avatar
                                                    src={item
                                                        .getUser()
                                                        .getAvatar()}
                                                />
                                            }
                                            title={item.getUser().getName()}
                                        />
                                        <p
                                            style={{
                                                marginLeft: "49px",
                                                marginTop: "-8px",
                                            }}
                                        >
                                            BN:{" "}
                                            <Text type="success">
                                                {item.getBatchNumber()}
                                            </Text>
                                        </p>
                                        <p
                                            style={{
                                                marginLeft: "49px",
                                                marginTop: "-11px",
                                            }}
                                        >
                                            Type:{" "}
                                            <Tag
                                                color={getColorForType(
                                                    item.getUser().getType(),
                                                )}
                                            >
                                                {item.getUser().getType()}
                                            </Tag>
                                        </p>
                                    </div>
                                    <div
                                        className="iconsFixed"
                                        style={{ marginTop: -"17px" }}
                                    >
                                        <a
                                            href={item
                                                .getContext()
                                                .getAttachmentUpload()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Button
                                                icon={<DownloadOutlined />}
                                            ></Button>
                                        </a>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
            </div>

            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    type="dashed"
                    shape="round"
                    onClick={handleButtonRedirect}
                >
                    View All
                </Button>
            </div>
        </>
    );
};

export default HarvestJobPopupOver;
