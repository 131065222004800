import {
  CategoryCommissionCollectionEnums,
  CommissionAssociationEnums,
  CommissionRateCollectionEnums,
  CommissionRateSlotCollectionEnums,
  CommmissionEnums,
} from "enums/commissionCollectionEnums";
import {
  ICategoryCommission,
  ICategoryCommissionBaseShippingCategory,
  ICategoryCommissionCollection,
  ICommission,
  ICommissionAgentWarehouse,
  ICommissionAssociation,
  ICommissionAssociationCollection,
  ICommissionAssociationPostRequest,
  ICommissionCollection,
  ICommissionCopyForBaseShippingRequest,
  ICommissionCopyForWarehouseRequest,
  ICommissionCopyRequest,
  ICommissionPostRequest,
  ICommissionRate,
  ICommissionRateCollection,
  ICommissionRateSlot,
  ICommissionRateSlotCollection,
  ICommissionRegion,
} from "types/commissionCollection";
import {
  RateTypeEnums,
  ShippingModeEnums,
  ShippingTypeEnums,
  UnitTypeEnums,
} from "enums/shippingCoreEnums";
import { PaginationModel } from "./paginationModel";
import { IFilterType } from "types/filters";

export class CommissionRateSlotModel {
  object: CommissionRateSlotCollectionEnums.CommissionRateSlot;
  id: number;
  min_amount: string;
  max_amount: string;
  rate: string;
  rate_type: RateTypeEnums;
  max_cap: number | null;

  constructor(dataItem: ICommissionRateSlot) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.min_amount = dataItem.min_amount;
    this.max_amount = dataItem.max_amount;
    this.rate = dataItem.rate;
    this.rate_type = dataItem.rate_type;
    this.max_cap = dataItem.max_cap;
  }

  getId = () => this.id;
  getMinAmount = () => this.min_amount;
  getMaxAmount = () => this.max_amount;
  getRate = () => this.rate;
  getRateType = () => this.rate_type;
  getMaxCap = () => this.max_cap;
}
export class CommissionRateSlotCollectionModel {
  object: CommissionRateSlotCollectionEnums.CommissionRateSlotCollection;
  data: CommissionRateSlotModel[];

  constructor(dataItem: ICommissionRateSlotCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new CommissionRateSlotModel(d));
  }
  getData = () => this.data;
}

export class CommissionRateModel {
  object: CommissionRateCollectionEnums.CommissionRate;
  id: number;
  shipping_type: ShippingTypeEnums;
  shipping_mode: ShippingModeEnums;
  unit_type: UnitTypeEnums;
  rate: string;
  rate_type: RateTypeEnums;
  max_cap: number | null;
  slots: CommissionRateSlotCollectionModel;

  constructor(dataItem: ICommissionRate) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.shipping_type = dataItem.shipping_type;
    this.shipping_mode = dataItem.shipping_mode;
    this.unit_type = dataItem.unit_type;
    this.rate = dataItem.rate;
    this.rate_type = dataItem.rate_type;
    this.max_cap = dataItem.max_cap;
    this.slots = new CommissionRateSlotCollectionModel(dataItem.slots);
  }

  getId = () => this.id;
  getShippingType = () => this.shipping_type;
  getShippingMode = () => this.shipping_mode;
  getUnitType = () => this.unit_type;
  getRate = () => this.rate;
  getRateType = () => this.rate_type;
  getMaxCap = () => this.max_cap;
  getSlots = () => this.slots;
}

export class CommissionRateCollectionModel {
  object: CommissionRateCollectionEnums.CommissionRateCollection;
  data: CommissionRateModel[];
  constructor(dataItem: ICommissionRateCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new CommissionRateModel(d));
  }

  getData = () => this.data;
}

export class CategoryCommissionModel {
  public readonly object: CategoryCommissionCollectionEnums.CategoryCommission;
  public readonly id: number;
  public readonly base_shipping_category: ICategoryCommissionBaseShippingCategory;
  public readonly commission_rates: CommissionRateCollectionModel;

  constructor(dataItem: ICategoryCommission) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.base_shipping_category = dataItem.base_shipping_category;
    this.commission_rates = new CommissionRateCollectionModel(
      dataItem.commission_rates,
    );
  }

  getId = () => this.id;
  getBaseShippingCategory = () => this.base_shipping_category;
  getCommissionRates = () => this.commission_rates;
}

export class CategoryCommissionCollectionModel {
  public readonly object: CategoryCommissionCollectionEnums.CategoryCommissionCollection;
  public readonly data: CategoryCommissionModel[];

  constructor(dataItem: ICategoryCommissionCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new CategoryCommissionModel(d));
  }
  getData = () => this.data;
  getCategoryCommissionById(id: number): CategoryCommissionModel | undefined {
    return this.data.find((item) => item.id === id);
  }
}

export class CommissionAssociationModel {
  public readonly object: CommissionAssociationEnums.CommissionAssociation;
  public readonly id: number;
  public readonly agent_warehouse: ICommissionAgentWarehouse;
  public readonly category_commission_associations: CategoryCommissionCollectionModel;

  constructor(dataItem: ICommissionAssociation) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.agent_warehouse = dataItem.agent_warehouse;
    this.category_commission_associations =
      new CategoryCommissionCollectionModel(
        dataItem.category_commission_associations,
      );
  }

  getId = () => this.id;
  getAgentWarehouse = () => this.agent_warehouse;
  getCategoryCommissionAssociations = () =>
    this.category_commission_associations;
}

export class CommissionAssociationCollectionModel {
  public readonly object: CommissionAssociationEnums.CommissionAssociationCollection;
  public readonly data: CommissionAssociationModel[];

  constructor(dataItem: ICommissionAssociationCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((d) => new CommissionAssociationModel(d));
  }

  getData = () => this.data;
  getCommissionAssociationById(
    id: number,
  ): CommissionAssociationModel | undefined {
    return this.getData().find((item) => item.id === id);
  }
}

export class CommissionModel {
  public readonly object: CommmissionEnums.Commission;
  public readonly id: number;
  public readonly region: ICommissionRegion;
  public readonly name: string;
  public readonly is_default: number;
  public readonly commission_associations: CommissionAssociationCollectionModel;

  constructor(dataItem: ICommission) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.region = dataItem.region;
    this.name = dataItem.name;
    this.is_default = dataItem.is_default;
    this.commission_associations = new CommissionAssociationCollectionModel(
      dataItem.commission_associations,
    );
  }
  getId = () => this.id;
  getRegion = () => this.region;
  getName = () => this.name;
  getIsDefault = () => this.is_default;
  getCommissionAssociations = () => this.commission_associations;
}

export class CommissionCollectionModel {
  object: CommmissionEnums.CommissionCollection;
  data: CommissionModel[];
  pagination: PaginationModel;
  filters: IFilterType;

  constructor(data: ICommissionCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CommissionModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }
  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
  getCommissionById(id: number): CommissionModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getDefaultCommissionId(): number | undefined {
    return this.data.find((item) => item.is_default === 1)?.id;
  }
  getDefaultCommission(): CommissionModel | undefined {
    return this.data.find((item) => item.is_default === 1);
  }
}

//Post Request
export class CommissionPostRequestModel {
  public readonly region_id: number;
  public readonly name: string;
  public readonly is_default: boolean;
  public readonly commission_associations: ICommissionAssociationPostRequest[];

  constructor(reqData: ICommissionPostRequest) {
    this.region_id = reqData.region_id;
    this.name = reqData.name;
    this.is_default = reqData.is_default;
    this.commission_associations = reqData.commission_associations;
  }
}

export class CommissionCopyRequestModel {
  public readonly region_id: number;
  public readonly is_default: boolean;

  constructor(reqData: ICommissionCopyRequest) {
    this.region_id = reqData.region_id;
    this.is_default = reqData.is_default;
  }
}

export class CommissionCopyForWarehouseRequestModel {
  public readonly agent_warehouse_id: number;

  constructor(reqData: ICommissionCopyForWarehouseRequest) {
    this.agent_warehouse_id = reqData.agent_warehouse_id;
  }
}

export class CommissionCopyForBaseShippingRequestModel {
  public readonly base_shipping_category_id: string;

  constructor(reqData: ICommissionCopyForBaseShippingRequest) {
    this.base_shipping_category_id = reqData.base_shipping_category_id;
  }
}
