import React from "react";
import TextLine from "./TextLine";
import { Col, Row, Space } from "antd";
import { DisputeItemStatusEnum } from "enums/disputeCollectionEnum";
import { StatusTag } from "../../../../components";

interface props {
  data: {
    requested_amount: number;
    approved_amount: number;
    approved_percentage: number;
    status: string;
  };
}
const DisputeVariationProduct: React.FC<props> = ({ data }) => {
  const { requested_amount, approved_amount, approved_percentage, status } =
    data;

  return (
    <Space direction="vertical">
      <Row gutter={12}>
        <Col>
          <Space direction="vertical" size={0}>
            {requested_amount && (
              <TextLine label="Requested Amount : " value={requested_amount} />
            )}

            {approved_amount && (
              <TextLine label="Approved Amount : " value={approved_amount} />
            )}
            {approved_percentage &&
              status === DisputeItemStatusEnum.APPROVED && (
                <TextLine
                  label=" Approved Percentage : "
                  value={"" + approved_percentage + "%"}
                />
              )}

            <p style={{ padding: 0, margin: 0 }}>
              <span>Status : </span>
              <StatusTag slug={status} text={status} />
            </p>
          </Space>
        </Col>
      </Row>
    </Space>
  );
};

export default DisputeVariationProduct;
