import CommerceApiService from "lib/core/apiServices/commerceApiService";
import { CourierGatewayResource } from "../../resources/private";

class CourierService extends CommerceApiService {
  public courierGatewayResource: CourierGatewayResource;

  constructor() {
    super();

    this.courierGatewayResource = new CourierGatewayResource(this.config);
  }
}

export default CourierService;
