import { Col, Row, Typography } from "antd";
import { BuyActionModel } from "models/buyActionCollectionModel";
import ProductDetails from "./productDetails";
import ActionNeededIssuesType from "./ActionNeededIssuesType";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";

interface IProps {
  selectedProduct: BuyActionModel;
}

const ActionViewDetailsModal: React.FC<IProps> = (selectedProduct) => {
  const { Text } = Typography;
  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader ghost={false} title="Product Information">
            <Col span={24}>
              <ProductDetails data={selectedProduct.selectedProduct} />
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "30px",
                borderLeft: "5px solid #5755FE",
                padding: "10px 5px 10px 5px",
                background: "#FFF7FC",
                borderRadius: 5,
              }}
            >
              {selectedProduct.selectedProduct.getSequences().map((item) => (
                <ActionNeededIssuesType data={item} />
              ))}
            </Col>
            <Col
              style={{
                marginTop: 30,
                padding: "10px 5px 10px 5px",
                borderRadius: 5,
              }}
            >
              <span style={{ fontWeight: 700 }}>Admin Note: </span>
              <Text> {selectedProduct.selectedProduct.getAdminNote()}</Text>
            </Col>

            <Col
              style={{
                marginTop: 30,
                padding: "10px 5px 10px 5px",
                borderRadius: 5,
              }}
            >
              <span style={{ fontWeight: 700 }}>Agent Note: </span>
              <Text> {selectedProduct.selectedProduct.getAgentNote()}</Text>
            </Col>
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default ActionViewDetailsModal;
