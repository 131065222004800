import { DestinationWarehouseMemberModalType } from "types/destinationWarehouseCollection";

function getKeyByValue(value: string): string {
  const key =
    Object.keys(DestinationWarehouseMemberModalType).find(
      (key) =>
        DestinationWarehouseMemberModalType[
          key as keyof typeof DestinationWarehouseMemberModalType
        ] === value
    ) || "";

  return key;
}

export { getKeyByValue };
