import { CountryDataStatus, CountryEnum } from "enums/countryCollectionEnums";
import { ICountry, ICountryData, ICountryUpdateRequest } from "types/country";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class CountryDataModel {
  public readonly object: CountryEnum.Country;
  public readonly id: number;
  public readonly code: string;
  public readonly name: string;
  public readonly status: CountryDataStatus;
  public readonly created_at: string;

  constructor(dataItem: ICountryData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }
}

export class CountryModel {
  public readonly object: CountryEnum.CountryCollection;
  public readonly data: CountryDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: ICountry) {
    this.object = data.object;
    this.data = data.data.map((item) => new CountryDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  getCountryCode() {
    const obj: { [key: string]: string } = {};
    this.data.forEach((item) => (obj[item.code] = item.code));
    return obj;
  }
  getCountryId() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
}

//update Request
export class CountryUpdateRequestModel {
  public readonly name: string;
  public readonly status: CountryDataStatus;

  constructor(reqData: ICountryUpdateRequest) {
    this.name = reqData.name;
    this.status = reqData.status;
  }
}

export class CountryCreateRequestModel {
  public readonly name: string;
  public readonly status: CountryDataStatus;

  constructor(reqData: ICountryUpdateRequest) {
    this.name = reqData.name;
    this.status = reqData.status;
  }
}
