import { useEffect } from "react";
import { useAtom } from "jotai/index";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Card,
  Col,
  Descriptions, Result,Button,
  Image,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { useGetDispute } from "lib/core-react/hooks/private/useDispute";
import { disputeDetailsAtom } from "lib/core-react/store/store";
import { DisputeDetailsCollectionModel } from "models/disputeDetailsCollectionModel";
import { useParams } from "react-router-dom";
import ItemsTable from "./ItemsTable";
import MandatesTable from "./MandatesTable";
import {StatusTag} from "../../../../components";
import {truncateTitle} from "../../../../utils/helper";

const RefundDisputeDetails = () => {
   const { getDispute,  } = useGetDispute();
  const [{ data: disputeDetailsData, isLoading, error }] = useAtom(disputeDetailsAtom);
  const { id } = useParams();
  const parsedId: number = Number(id);

  useEffect(() => {
    getDispute(parsedId);
  }, [parsedId]);

  const disputeDetailsCollectionModelData =
    disputeDetailsData && new DisputeDetailsCollectionModel(disputeDetailsData);
  const buyProduct = disputeDetailsCollectionModelData
    ?.getData()
    ?.getBuyProduct();

  const mandates = disputeDetailsCollectionModelData?.getData()?.getMandates();
  const region = disputeDetailsCollectionModelData?.getData().getRegion();
  const user = disputeDetailsCollectionModelData?.getData().getUser();


  if(error){
    return  <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button  onClick={() => window.history.back()} type="primary">Back Home</Button>}
    />
  }

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={"Refund Details"}
        style={{ marginTop: "10px" }}
      >
        <div>
          <Card loading={isLoading}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Descriptions
                  size="small"
                  bordered
                  title={<span style={{ fontWeight: 'bold' }}>Buy Product</span>}
                  column={1}
                >
                  <Descriptions.Item
                    span={12}
                    labelStyle={{ textAlign: 'start', minWidth: 100, fontWeight: 'bold' }}
                    label="Product Id"
                  >
                    <Typography.Text copyable>
                      {buyProduct?.getProductId()}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Title"
                    span={12}
                    labelStyle={{ textAlign: 'start', minWidth: 100, fontWeight: 'bold' }}
                  >
                    <Typography.Text>
                      {truncateTitle(buyProduct?.getProductTitle() || "", 50)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ textAlign: 'start', minWidth: 100, fontWeight: 'bold' }}
                    label="Photo"
                    span={12}
                  >
                    {buyProduct?.getProductImage() !== 'None' && buyProduct?.getProductImage() ? (
                      <Image
                        height={60}
                        width={60}
                        alt="image"
                        src={buyProduct?.getProductImage()}
                        preview={true}
                        style={{ borderRadius: 4 }}
                      />
                    ) : (
                      <Skeleton.Image
                        active={true}
                        style={{ width: 60, height: 60, borderRadius: 4 }}
                      />
                    )}
                  </Descriptions.Item>
                  {buyProduct?.getStatus() && (
                    <Descriptions.Item
                      label="Buy Product Status"
                      span={12}
                      labelStyle={{
                        textAlign: 'start',
                        minWidth: 100,
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                      }}
                    >
                      <StatusTag text={buyProduct?.getStatus()} slug={buyProduct?.getStatus()} />
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    label="Shipping provider"
                    span={12}
                    labelStyle={{ textAlign: 'start', width: 100, fontWeight: 'bold' }}
                  >
                    <Typography.Text>
                      {buyProduct?.getShippingProvider()}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Region Name"
                    span={12}
                    labelStyle={{ textAlign: 'start', width: 100, fontWeight: 'bold' }}
                  >
                    {region?.getName()}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Region Code"
                    span={12}
                    labelStyle={{ textAlign: 'start', width: 100, fontWeight: 'bold' }}
                  >
                    {region?.getCode()}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Curency Code"
                    span={12}
                    labelStyle={{ textAlign: 'start', width: 100, fontWeight: 'bold' }}
                  >
                    {region?.currency?.getCurrencyCode()}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <MandatesTable mandates={mandates} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Descriptions
                  column={1}
                  size="small"
                  bordered
                  title={<span style={{ fontWeight: 'bolder' }}>User</span>}
                  style={{ marginTop: 20 }}
                >
                  <Descriptions.Item span={12} labelStyle={{ fontWeight: 'bold' }} label="Name">
                    {user?.getUserName()}
                  </Descriptions.Item>
                  <Descriptions.Item span={12} labelStyle={{ fontWeight: 'bold' }} label="Email">
                    {user?.getUserEmail()}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </div>

        <div style={{ marginTop: 24 }}>
           <ItemsTable />
        </div>
      </PageHeader>
    </div>
  );
};

export default RefundDisputeDetails;
