import { useState } from "react";
import type { DescriptionsProps } from "antd";
import { Badge, Card, Descriptions, Typography } from "antd";

const tabList = [
  {
    key: "Customer Invoice",
    tab: "Customer Invoice",
  },
  {
    key: "Agent Invoice",
    tab: "Agent Invoice",
  },
];

export const Invoice = ({ isLoading, productData, title }) => {
  const [activeTab, setActiveTab] = useState<string>("Customer Invoice");

  const customerItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Invoice Number",
      span: 3,
      children: (
        <Typography.Text copyable>
          {productData?.getCustomerInvoice()?.getInvoiceNumber()}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      label: "Status",
      span: 3,
      children: (
        <Badge
          status={
            productData?.getCustomerInvoice()?.getStatus() === "pending"
              ? "processing"
              : "success"
          }
          text={productData?.getCustomerInvoice()?.getStatus()}
        />
      ),
    },
    {
      key: "3",
      label: "Total Amount",
      children: (
        <Typography.Text>
          {productData?.getCustomerInvoice()?.getTotalAmount().toFixed(3)}
        </Typography.Text>
      ),
    },
    {
      key: "4",
      label: "Refundable Amount",
      children: (
        <Typography.Text>
          {productData?.getCustomerInvoice()?.getRefundableAmount().toFixed(3)}
        </Typography.Text>
      ),
    },
    {
      key: "5",
      label: "Transaction Summary",
      children: (
        <Typography.Text>
          Due:{" "}
          {productData
            ?.getCustomerInvoice()
            ?.getTransctionSummary()
            .due.toFixed(3)}
          <br />
          Paid:{" "}
          {productData
            ?.getCustomerInvoice()
            ?.getTransctionSummary()
            .paid.toFixed(3)}
          <br />
          Overpaid:{" "}
          {productData
            ?.getCustomerInvoice()
            ?.getTransctionSummary()
            .overpaid.toFixed(3)}
          <br />
        </Typography.Text>
      ),
    },
  ];

  const agentItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Invoice Number",
      span: 3,
      children: (
        <Typography.Text copyable>
          {productData?.getAgentInvoice()?.getInvoiceNumber()}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      label: "Status",
      span: 3,
      children: (
        <Badge
          status={
            productData?.getAgentInvoice()?.getStatus() === "pending"
              ? "processing"
              : "success"
          }
          text={productData?.getAgentInvoice()?.getStatus()}
        />
      ),
    },
    {
      key: "3",
      label: "Total Amount",
      children: (
        <Typography.Text>
          {productData?.getAgentInvoice()?.getTotalAmount()}
        </Typography.Text>
      ),
    },
    {
      key: "4",
      label: "Refundable Amount",
      children: (
        <Typography.Text>
          {productData?.getAgentInvoice()?.getRefundableAmount()}
        </Typography.Text>
      ),
    },
    // {
    //   key: "5",
    //   label: "Transaction Summary",
    //   children: (
    //     <Typography.Text>
    //       Due:{" "}
    //       {ShipmentProductData?.getAgentInvoice()
    //         ?.getTransactionSummary()
    //         .getDue()}
    //       <br />
    //       Paid:{" "}
    //       {ShipmentProductData?.getAgentInvoice()
    //         ?.getTransactionSummary()
    //         .getPaid()}
    //       <br />
    //       Overpaid:{" "}
    //       {ShipmentProductData?.getAgentInvoice()
    //         ?.getTransactionSummary()
    //         .getOverpaid()}
    //       <br />
    //     </Typography.Text>
    //   ),
    // },
  ];

  const contentList: Record<string, React.ReactNode> = {
    "Customer Invoice": (
      <Descriptions layout="vertical" bordered items={customerItems} />
    ),
    "Agent Invoice": (
      <Descriptions layout="vertical" bordered items={agentItems} />
    ),
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Card
      title={title}
      loading={isLoading}
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={onTabChange}
      tabProps={{
        size: "middle",
      }}
    >
      {activeTab === "Customer Invoice" && !productData?.getCustomerInvoice()
        ? "Customer invoice is not available yet."
        : activeTab === "Agent Invoice" && !productData?.getAgentInvoice()
          ? "Agent invoice is not available yet."
          : contentList[activeTab]}
    </Card>
  );
};
