import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
  notification, Badge,
} from 'antd'
import { useAtom } from 'jotai'

import {
  ProductDetailExtendedModel,
  PropertyModel,
  PropertyValueModelExtended,
} from "models/productDetailExtendedModel";
import {
  useBuyOrderAddProduct,
  useBuyProductAddVariations,
  useGetBuyOrdersDetail,
} from "lib/core-react/hooks/private/usePurchase";
import { getError } from "lib/core-react/hooks/utils/errors";
import { generateStringFromArray } from "../helpers/generateStringFromArray";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import {
  IAddBuyOrderAddProductFormData,
  IAddBuyOrderProductStep1FormData,
  IAddBuyOrderProductStep2FormData,
  IBuyOrderQuantityFormData,
} from "types/buyOrderDetail";
import { buyOrderDetailAtom } from "lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import { BuyOrderDetailModel } from "models/buyOrderDetailModel";
import { ShipmentProductOperationEnum } from "enums/shipmentProductCollectionEnums";

import { IPropertyValue, IPropertyValueMap } from "..";
import { useShipmentProductAddPackage } from "lib/core-react/hooks/private/useShipping";
import { IShipmentProductAddPackageVariationForm } from "types/shipmentProductCollection";
import { AddShipmentProductCartonModel } from "./AddShipmentProductCartonModel";
import SelectedItems from "./SelectedItems";
import {
  buyOrderPlaceAtom,
  productDetailAtom,
} from "lib/core-react/store/store";
import {
  AddBuyOrderProductShippingMethodModal,
  PlaceBuyOrderProductShippingMethodModal,
} from ".";
import {
  IBuyPlaceOrderCartFormData,
  IBuyPlaceOrderCartShippingFormData,
  IPlaceBuyOrderProductStepFormData,
} from "types/buyOrderPlace";
import { usePlaceOrderCartProduct } from "lib/core-react/hooks/private/usePurchaseCart";
import TextArea from "antd/es/input/TextArea";

interface IProps {
  selectedVariants: IPropertyValue[];
  property: PropertyModel;
  mappedSkuProperties: IPropertyValueMap;
}

export const LastProperty = ({
  selectedVariants,
  property,
  mappedSkuProperties,
}: IProps) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const [{ data: productDetailsData }] = useAtom(productDetailAtom);

  const productDetail =
    productDetailsData && new ProductDetailExtendedModel(productDetailsData);

  const operation = new URLSearchParams(location.search).get("operation");
  const userId = new URLSearchParams(location.search).get("userId");
  const regin = new URLSearchParams(location.search).get("region");
  // to add buy order variation
  const buyProductId = new URLSearchParams(location.search).get("buyProductId");
  const {
    addVariations: addVariationsBuyProduct,
    isLoading: addVariantLoading,
  } = useBuyProductAddVariations();

  // to add new buy order product
  const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");
  const { addProduct: addProductBuyOrder, isLoading: isLoadingAddProduct } =
    useBuyOrderAddProduct();

  const { cartProduct , isLoading} = usePlaceOrderCartProduct();

  // to add new package shipment product
  const { getBuyOrdersDetail } = useGetBuyOrdersDetail()
  const shipment_product_id = new URLSearchParams(location.search).get(
    'shipmentProductId',
  )
  const { addPackage: addShipmentProductPackage, isLoading:addPackageLoading } =
    useShipmentProductAddPackage();

  const [quantity, setQuantity] = useState<IBuyOrderQuantityFormData[]>([]);

  // to add new buy order product
  const [addBuyOrderProductStep1FormData, setAddBuyOrderProductStep1FormData] =
    useState<IAddBuyOrderProductStep1FormData[] | undefined>();
  const [addBuyOrderProductStep2FormData, setAddBuyOrderProductStep2FormData] =
    useState<IAddBuyOrderProductStep2FormData >({} as IAddBuyOrderProductStep2FormData);
  const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] =
    useState(false);

  const [errors, setErrors] = useState({
    shippingCountryError: "",
    shippingCategoryError: "",
    categoryPriceError: "",
    noteError:""
  });

  // to place new buy order
  const [
    placeBuyOrderProductStep2FormData,
    setPlaceBuyOrderProductStep2FormData,
  ] = useState<IBuyPlaceOrderCartShippingFormData>({} as IBuyPlaceOrderCartShippingFormData);

  const [cartNote, setCartNote] = useState<string | "">();



  const [
    placeBuyOrderProductStepFormData,
    setPlaceBuyOrderProductStepFormData,
  ] = useState<IPlaceBuyOrderProductStepFormData[] | undefined>();

  // to add new shipment product package
  const [
    shipmentProductAddPackageFormData,
    setShipmentProductAddPackageFormData,
  ] = useState<IShipmentProductAddPackageVariationForm[] | undefined>();
  const [
    isShipmentProductAddCartonModalOpen,
    setIsShippmentProductAddCartonModalOpen,
  ] = useState(false);
  const [form] = Form.useForm();
  console.log(isShipmentProductAddCartonModalOpen,"isShipmentProductAddCartonModalOpen")

  const handleDecrement = (sku_id: string, original_unit_price: number) => {
    if (!quantity) {
      setQuantity([{ sku_id, quantity: 0, original_unit_price }]);
      return;
    }

    const updatedQuantity = quantity
      .map((item) =>
        item.sku_id === sku_id
          ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
          : item,
      )
      .filter((item) => item.quantity > 0);
    setQuantity(updatedQuantity);
  };

  const handleIncrement = (
    sku_id: string,
    original_unit_price: number,
    value?: number | null,
  ) => {
    if (!quantity) {
      setQuantity([
        { sku_id, quantity: value ? value : 1, original_unit_price },
      ]);
      return;
    }

    const existingItem = quantity.find((item) => item.sku_id === sku_id);

    if (existingItem) {
      const updatedQuantity = quantity.map((item) =>
        item.sku_id === sku_id
          ? { ...item, quantity: value ? value : item.quantity + 1 }
          : item,
      );
      setQuantity(updatedQuantity);
    } else {
      setQuantity([
        ...quantity,
        { sku_id, quantity: value ? value : 1, original_unit_price },
      ]);
    }
  };

  const handleAddVariationsBuyProduct = async () => {
    try {
      if (buyProductId && quantity && quantity.length) {
        await addVariationsBuyProduct(Number(buyProductId), {
          variations: quantity,
        });
      }
      notification["success"]({
        message: "Variant added successfully",
      });
      if (buyOrderId) {
        navigate(`/buy-and-ship-for-me/orders/edit/${buyOrderId}`);
      }
    } catch (error) {
      notification["error"]({
        message: getError(error),
      });
    }
  };

  const prepareBuyOrderAddProduct = () => {
    if (id && quantity && quantity.length) {
      const payload: IAddBuyOrderProductStep1FormData[] = quantity.map(
        (item) => {
          return {
            sku_id: item.sku_id,
            quantity: item.quantity,
          };
        },
      );
      setAddBuyOrderProductStep1FormData(payload);
      setIsShippingMethodModalOpen(true);
    }
  };

  const [buyOrderPlaceAtomData, setByOrderPlaceAtomData] =
    useAtom(buyOrderPlaceAtom);

  const quantityFromAtom = buyOrderPlaceAtomData.data?.productDetail?.find(
    (product) => product.slug === productDetailsData?.slug,
  )?.quantity;

  useEffect(() => {
    if (quantityFromAtom) {
      setQuantity(quantityFromAtom);
    }
  }, []);

  // step 01
  const handlePlaceBuyOrder = async () => {
   await  handlePlaceBuyOrderAddProduct();
    if (productDetail && quantity.length) {
      const variations = quantity.map((q) => {
        const quantity = q.quantity;
        const foundProperties = Object.values(mappedSkuProperties).find(
          (v) => v.getId() === q.sku_id,
        );
        return {
          quantity,
          properties: foundProperties!
            .getPropertyAssociations()
            .getCustomizedDataToAddShipmentProductCarton(),
        };
      });

      const productDetailAtomData =
        buyOrderPlaceAtomData.data?.productDetail || [];

      const productExistsIndex = productDetailAtomData.findIndex(
        (p) => p.slug === productDetail.getSlug(),
      );

      if (productExistsIndex !== -1) {
        const existingProduct = productDetailAtomData[productExistsIndex];

        const updatedProductDetails = {
          ...existingProduct,
          variations,
          quantity,
          quantityFromAtom,
        };

        setByOrderPlaceAtomData({
          ...buyOrderPlaceAtomData,
          data: {
            ...buyOrderPlaceAtomData.data,
            productDetail: [
              ...productDetailAtomData.slice(0, productExistsIndex),
              updatedProductDetails,
              ...productDetailAtomData.slice(productExistsIndex + 1),
            ],
          },
        });
      } else {
        const newProductDetails = {
          id: productDetail.getId(),
          title: productDetail.getTitle(),
          slug: productDetail.getSlug(),
          image: productDetail.getImage(),
          description: productDetail.getDescription(),
          stock: productDetail.getStock() || 0,
          sales: productDetail.getSales() || 0,
          variations,
          quantity,
          quantityFromAtom,
        };

        setByOrderPlaceAtomData({
          ...buyOrderPlaceAtomData,
          data: {
            ...buyOrderPlaceAtomData.data,
            // @ts-ignore FIXME
            productDetail: [...productDetailAtomData, newProductDetails],
          },
        });
      }
      setIsShippingMethodModalOpen(true);

      //navigate("/buy-and-ship-for-me/orders/create");
    }
  };

  // step-2:Place order
  const handlePlaceBuyOrderAddProduct = async () => {

    if(!cartNote){
      setErrors(pre=>{
        return {
          ...pre,
          noteError: "Note is required"
        }
      })
      return
    }


    if (id && quantity && quantity.length) {
      const payload: IPlaceBuyOrderProductStepFormData[] = quantity.map(
        (item) => {
          return {
            sku_id: item.sku_id,
            quantity: item.quantity,
          };
        },
      );
      setPlaceBuyOrderProductStepFormData(payload);
    }

    if (
      buyOrderPlaceAtomData &&
      placeBuyOrderProductStep2FormData &&
      buyOrderPlaceAtomData.data &&
      placeBuyOrderProductStepFormData
    ) {
      const quantityFromAtom = buyOrderPlaceAtomData.data?.productDetail?.find(
        (product) => product.slug === productDetailsData?.slug,
      )?.id;

      if (!quantityFromAtom) {
        return;
      }

      const locale = "en";
      const payload: IBuyPlaceOrderCartFormData = {
        user_id: Number(userId),
        product_id: quantityFromAtom,
        shipping: placeBuyOrderProductStep2FormData,
        variations: placeBuyOrderProductStepFormData,
        app_context: [],
        note: cartNote ? cartNote : "",
      };
      try {
        await cartProduct(regin as string, locale, payload);
        navigate(`/buy-and-ship-for-me/orders/create?userId=${payload.user_id}&region=${regin}`);
        notification["success"]({
          message: "Product Added in Cart successfully",
        });

        setErrors(pre=>{
          return {
            ...pre,
            noteError: ""
          }
        })
      } catch (error: any) {
        if (error?.response?.data?.message) {
          notification["error"]({
            message: error.response.data.message,
          });
        }

        setErrors(pre=>{
          return {
            ...pre,
            noteError: ""
          }
        })
      }
    }
  };
  const handleBuyOrderAddProduct = async () => {
    if (
      orderDetail &&
      buyOrderId &&
      id &&
      addBuyOrderProductStep1FormData &&
      Object.keys(addBuyOrderProductStep2FormData).length
    ) {

      const region = orderDetail.getProducts().getFirstProductRegionCode();
      const locale = "en";
      const payload: IAddBuyOrderAddProductFormData = {
        variations: addBuyOrderProductStep1FormData,
        instruction: null,
        product_id: id,
        shipping:addBuyOrderProductStep2FormData,
      };
      try {
        await addProductBuyOrder(buyOrderId, region, locale, payload);
        navigate(`/buy-and-ship-for-me/orders/edit/${buyOrderId}`);
        notification["success"]({
          message: "Add product successful",
        });
      } catch (error) {
        notification["error"]({
          message: getError(error),
        });
      }
    }
  };

  const prepareShipmentProductAddPackage = () => {
    if (quantity && quantity.length) {
      const variations = quantity.map((q) => {
        const quantity = q.quantity;
        const foundProperties = Object.values(mappedSkuProperties).find(
          (v) => v.getId() === q.sku_id,
        );
        return {
          quantity,
          properties: foundProperties!
            .getPropertyAssociations()
            .getCustomizedDataToAddShipmentProductCarton(),
        };
      });

      setShipmentProductAddPackageFormData(variations);
      setIsShippmentProductAddCartonModalOpen(true);
    }
  };

  const handleShipmentProductAddPackage = async () => {
    try {
      const trigger = await form.validateFields()
      if (shipment_product_id) {
        await addShipmentProductPackage(parseInt(shipment_product_id), {
          variations: shipmentProductAddPackageFormData,
          ...trigger,
        })
        navigate(`/shipping/shipment-products/edit/${shipment_product_id}`)
        notification["success"]({
          message: "Successfully added",
        });

      } else {
        notification["error"]({
          message: 'Shipment product number not found!',
        });
      }
    } catch (err: any) {
      notification["error"]({
        message: err.response.data.message,
      });
    }
  };

  useEffect(() => {
    if (operation === BuyOrderOperationEnum.AddBuyOrderProduct && buyOrderId) {
      getBuyOrdersDetail(buyOrderId);
    }

  }, [operation]);

  const [{ data }] = useAtom(buyOrderDetailAtom);

  const orderDetail = data && new BuyOrderDetailModel(data);

  const handleAddButton = async () => {
    // const newErrors = {
    //   shippingCountryError: "",
    //   shippingCategoryError: "",
    //   categoryPriceError: "",
    // };

    // setErrors((pre) => {
    //   return {
    //     ...pre,
    //     shippingCountryError: "",
    //     shippingCategoryError: "",
    //     categoryPriceError: "",
    //   };
    // });

    // let hasErrors = false;
    // console.log(placeBuyOrderProductStep2FormData, "from data")

    // if (!placeBuyOrderProductStep2FormData?.shipping_country) {
    //   newErrors.shippingCountryError = "Please add the shipping Country";
    //   hasErrors = true;
    // }

    // if (!placeBuyOrderProductStep2FormData?.shipping_category_id) {
    //   newErrors.shippingCategoryError = "Please add the shipping category";
    //   hasErrors = true;
    // }

    // if (!placeBuyOrderProductStep2FormData?.agent_category_price_read_id) {
    //   newErrors.categoryPriceError = "Please add the category price";
    //   hasErrors = true;
    // }

    // if (hasErrors) {
    //   setErrors(newErrors);
    //   return;
    // }


    // console.log("Test")

    switch (operation) {
      case BuyOrderOperationEnum.AddBuyOrderProductVariant:
        await handleAddVariationsBuyProduct();
        break;
      case BuyOrderOperationEnum.AddBuyOrderProduct:
        await prepareBuyOrderAddProduct();
        break;
      case ShipmentProductOperationEnum.AddShipmentProductPackage:
        prepareShipmentProductAddPackage();
        break;
      case BuyOrderOperationEnum.PlaceBuyOrder:
        await handlePlaceBuyOrder();
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_: string, record: PropertyValueModelExtended) => (
        <Avatar
          src={<Image src={record.getImage()} />}
          size={50}
          shape="square"
        />
      ),
    },
    {
      title: `${property.getName()}`,
      dataIndex: "id",
      key: "id",
      render: (_: string, record: PropertyValueModelExtended) => (
        <div>{record.getName()}</div>
      ),
    },
    {
      title: "Price (৳)",
      dataIndex: "color",
      key: "color",
      render: (_: string, record: PropertyValueModelExtended) => {
        const newSelectedVariants = [
          ...selectedVariants!,
          {
            propertyId: property.getId(),
            propertyValueId: record.getId(),
          },
        ];
        const index = generateStringFromArray(newSelectedVariants);

        return (
          <div>
            {mappedSkuProperties[index]?.getPrice()?.getDiscount() ? (
              <>
                {mappedSkuProperties[index]?.getPrice()?.getDiscount()} (
                <Typography.Text type="secondary">
                  {mappedSkuProperties[index]?.getPrice()?.getDiscountRaw()}{" "}
                  {mappedSkuProperties[index]?.getPrice()?.getCurrency()}
                </Typography.Text>
                )
              </>
            ) : (
              <>
                {mappedSkuProperties[index]?.getPrice()?.getOriginal()} (
                <Typography.Text type="secondary">
                  {mappedSkuProperties[index]?.getPrice()?.getOriginalRaw()}{" "}
                  {mappedSkuProperties[index]?.getPrice()?.getCurrency()}
                </Typography.Text>
                )
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "title",
      key: "title",
      render: (_: string, record: PropertyValueModelExtended) => {
        const newSelectedVariants = [...selectedVariants].concat({
          propertyId: property.getId(),
          propertyValueId: record.getId(),
        });

        const index = generateStringFromArray(newSelectedVariants);
        return <div>{mappedSkuProperties[index]?.getStock() || 0}</div>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "thumb",
      key: "thumb",
      align: "center" as const,
      render: (_: string, record: PropertyValueModelExtended) => {
        const newSelectedVariants = [...selectedVariants].concat({
          propertyId: property.getId(),
          propertyValueId: record.getId(),
        });

        const index = generateStringFromArray(newSelectedVariants);
        const sku_id = mappedSkuProperties[index].getId();
        const original_unit_price = mappedSkuProperties[index]
          .getPrice()
          .getOriginalRaw();

        return (
          <Row gutter={[4, 4]}>
            <Col sm={8}>
              <Button
                onClick={() => handleDecrement(sku_id, original_unit_price)}
              >
                -
              </Button>
            </Col>
            <Col sm={8}>
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                defaultValue={0}
                onChange={(value) =>
                  handleIncrement(sku_id, original_unit_price, value)
                }
                value={
                  quantity.find((item) => item.sku_id === sku_id)?.quantity || 0
                }
              />
            </Col>
            <Col sm={8}>
              <Button
                onClick={() => handleIncrement(sku_id, original_unit_price)}
              >
                +
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  console.log(addBuyOrderProductStep2FormData,'addBuyOrderProductStep2FormData')


  return (
    <>
      <Row gutter={16}>
        <Col sm={24} lg={18}>
          <div>
            <Table
              size="small"
              bordered
              columns={columns}
              dataSource={property.getValues().getData()}
              rowKey="id"
              pagination={false}
            />
          </div>

          {operation === BuyOrderOperationEnum.PlaceBuyOrder && (
            <div style={{ marginTop: 15 }}>
              <div>
                <PlaceBuyOrderProductShippingMethodModal
                  placeBuyOrderProductStep2FormData={
                    placeBuyOrderProductStep2FormData
                  }
                  setPlaceBuyOrderProductStep2FormData={
                    setPlaceBuyOrderProductStep2FormData
                  }
                  errors={errors}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Form.Item
                  label="Add note"
                  name="description"
                  validateStatus={errors.noteError ? "error" : ""}
                  help={errors.noteError}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0 }}
                  required
                >
                  <TextArea
                    onChange={(e) => setCartNote(e.target.value)}
                    rows={4}
                    placeholder="Please add your note"
                    required={true}
                  />
                </Form.Item>

              </div>
            </div>
          )}
        </Col>
        <Col sm={24} lg={6}>
          {quantity.length ? (
            <SelectedItems
              quantity={quantity}
              mappedSkuProperties={mappedSkuProperties}
            />
          ) : (
            <div></div>
          )}
        </Col>
      </Row>

      {addBuyOrderProductStep1FormData && orderDetail && (
        <Modal
          width={726}
          centered
          title={<Typography.Text strong>Shipping Methods</Typography.Text>}
          open={isShippingMethodModalOpen}
          onCancel={() => setIsShippingMethodModalOpen(false)}
          onOk={handleBuyOrderAddProduct}
          confirmLoading={isLoadingAddProduct}
          okText={"Submit"}
          cancelText="Cancel"
        >
          <AddBuyOrderProductShippingMethodModal
            orderDetail={orderDetail}
            addBuyOrderProductStep2FormData={addBuyOrderProductStep2FormData}
            setAddBuyOrderProductStep2FormData={
              setAddBuyOrderProductStep2FormData
            }
          />
        </Modal>
      )}

      {shipmentProductAddPackageFormData &&
        shipment_product_id &&
        quantity.length && (
          <Modal
            width={726}
            centered
            title={
              <Typography.Text strong>
                Add Carton to Shipment Product
              </Typography.Text>
            }
            open={isShipmentProductAddCartonModalOpen}
            onCancel={() => setIsShippmentProductAddCartonModalOpen(false)}
            onOk={handleShipmentProductAddPackage}
            confirmLoading={addPackageLoading}
            okText={"Submit"}
            okButtonProps={{loading:addPackageLoading, disabled:addPackageLoading}}
            cancelText="Cancel"
          >
            <AddShipmentProductCartonModel
              form={form}
              onOk={handleShipmentProductAddPackage}
              quantity={quantity}
              mappedSkuProperties={mappedSkuProperties}
            />
          </Modal>
        )}

      {(operation === BuyOrderOperationEnum.AddBuyOrderProductVariant ||
        operation === BuyOrderOperationEnum.AddBuyOrderProduct ||
        operation === ShipmentProductOperationEnum.AddShipmentProductPackage ||
        operation === BuyOrderOperationEnum.PlaceBuyOrder) && (
        <div style={{ position: 'fixed', bottom: '20px', right: '16px', zIndex:99999 }}>
          <Badge count={
            quantity.length > 0
            ? quantity.reduce((acc, curr) => acc + curr.quantity, 0)
            : ''
          }>
            <Button
              type={
                quantity.length === 0 || addVariantLoading || isLoading || ( !placeBuyOrderProductStep2FormData.agent_category_price_read_id &&  operation === BuyOrderOperationEnum.PlaceBuyOrder)
                  ? "default"
                  : "primary"
              }
              onClick={handleAddButton}
              disabled={quantity.length === 0 || addVariantLoading || isLoading || ( !placeBuyOrderProductStep2FormData.agent_category_price_read_id &&  operation === BuyOrderOperationEnum.PlaceBuyOrder)}
              loading={addVariantLoading || isLoading}
              style={{ width: "150px" }}
            >
              {operation === BuyOrderOperationEnum.AddBuyOrderProductVariant
                ? "Add Variations"
                : operation === BuyOrderOperationEnum.AddBuyOrderProduct
                  ? "Add Product"
                  : operation ===
                      ShipmentProductOperationEnum.AddShipmentProductPackage
                    ? "Add Carton"
                    : operation === BuyOrderOperationEnum.PlaceBuyOrder
                      ? "Add to cart"
                      : ""}
            </Button>
          </Badge>
        </div>
      )}
    </>
  );
};
