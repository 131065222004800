import qs from "qs";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { SearchProps } from "antd/lib/input";
import { Pagination, Skeleton } from "antd";
import { PaginationProps } from "antd/es/pagination";

import {
    isLoadingCampaignAtom,
    useGetCampaignProductList,
} from "lib/core-react/hooks/private/useCampaign";
import {
    campaignProductCollectionAtom,
    productSearchResultAtom,
} from "lib/core-react/store/store";
import { ProductSearchResultModel } from "models/productDetailCollectionModel";
import { CampaignProductCollectionModel } from "models/campaignProductCollectionModel";
import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import {
    StyledProductListMainContent,
    StyledProductListView,
} from "./index.styled";
import { VIEW_TYPE } from "../index";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";

import ProductHeader from "../ProductHeader";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import AppsHeader from "components/App/AppsContainer/AppsHeader";
import AppsContent from "components/App/AppsContainer/AppsContent";
import { Button } from "antd";
import CommonError from "components/Error/CommonError";
type Props = {
    viewType: string;
    setViewType: (viewType: string) => void;
};

const ProductListing = ({ viewType, setViewType }: Props) => {
    const location = useLocation();
    const operation = new URLSearchParams(location.search).get("operation");
    const id = new URLSearchParams(location.search).get("id");
    const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { keyword, page, ...rest } = parsedQuery;

    // Product list
    const { getProductDetailsCollection } = useGetProductDetailsCollection();
    const setIsLoadingCampaign = useAtomValue(isLoadingCampaignAtom);
    // Product list (campaign)
    const { getCampaignProducts } = useGetCampaignProductList();

    // Product list
    const [{ data, isLoading,error,unAuthorized }] = useAtom(productSearchResultAtom);
    const productList = data && new ProductSearchResultModel(data);

    // Product list (campaign)
    const [{ data: campaignData,error : errorCampaignData, unAuthorized : unAuthorizedCampaignProduct }] = useAtom(campaignProductCollectionAtom);
    const campaignProductList =
        campaignData && new CampaignProductCollectionModel(campaignData);

    const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const handleSelectAllProducts = () => {
        setIsSelectAll(() => !isSelectAll);
    };

    useEffect(() => {
        if (isSelectAll) {
            const currentlyLoadedProductIds = new Set(
                operation === CampaignOperationEnum.ViewCampaignProduct
                    ? campaignProductList?.getData().map((p) => p.getId())
                    : productList?.getData().map((p) => p.getId()),
            );

            // Add previously selected product IDs
            selectedProducts.forEach((id) => currentlyLoadedProductIds.add(id));

            // Convert the Set back to an array of unique IDs
            const uniqueProductIds = Array.from(currentlyLoadedProductIds);

            setSelectedProducts(uniqueProductIds);
        } else {
            setSelectedProducts(() => []);
        }
    }, [isSelectAll]);

    const onSearchProduct: SearchProps["onSearch"] = async (value: string) => {
        await getProductDetailsCollection(
            {
                region: "BD",
                locale: "en",
                ...rest,
            },
            {
                keyword: value,
            },
        );
    };

    const onChangePage: PaginationProps["onChange"] = async (page) => {
        setCurrentPage(page);

        operation === CampaignOperationEnum.ViewCampaignProduct && id
            ? await getCampaignProducts(
                  id,
                  {
                      page,
                      ...rest,
                  },
                  "paginate",
              )
            : await getProductDetailsCollection({
                  region: "BD",
                  locale: "en",
                  page,
              });
    };

    const paginationItemRender: PaginationProps["itemRender"] = (
        _,
        type,
        originalElement,
    ) => {
        if (type === "prev") {
            return <Button>Previous</Button>;
        }
        if (type === "next") {
            return <Button>Next</Button>;
        }
        return originalElement;
    };

    // Skeleton
    const showSkeleton = setIsLoadingCampaign;
    // Pagination
    const paginationPageSize =
        operation === CampaignOperationEnum.ViewCampaignProduct
            ? campaignProductList?.getPagination().getPerPage()
            : productList?.getPagination().getPerPage();

    const paginationTotal =
        operation === CampaignOperationEnum.ViewCampaignProduct
            ? campaignProductList?.getPagination().getTotal()
            : productList?.getPagination().getTotal();

  // ------------------------------ui------------------------
  if (unAuthorized || error || errorCampaignData || unAuthorizedCampaignProduct)  {
    return <CommonError unAuthorized={unAuthorized || unAuthorizedCampaignProduct} message={error || errorCampaignData}  />;
  }


    return (
        <StyledProductListView>
            <AppsHeader>
                <ProductHeader
                    viewType={viewType}
                    onSearch={onSearchProduct}
                    setViewType={setViewType}
                    defaultSearchValue={
                        typeof keyword === "string" ? keyword : undefined
                    }
                    selectedProducts={selectedProducts}
                    isSelectAll={isSelectAll}
                    handleSelectAllProducts={handleSelectAllProducts}
                />
            </AppsHeader>

            <AppsContent>
                <StyledProductListMainContent>
                    {showSkeleton ? (
                        <>
                            {[1, 2, 3, 4].map((key) => {
                                return (
                                    <Skeleton
                                        key={key}
                                        style={{
                                            marginTop: "16px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }}
                                        active
                                    />
                                );
                            })}
                        </>
                    ) : viewType === VIEW_TYPE.GRID ? (
                        <ProductGrid
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            ectomereList={
                                operation !==
                                CampaignOperationEnum.ViewCampaignProduct
                                    ? productList?.getData()
                                    : undefined
                            }
                            campaignProductList={
                                operation ===
                                CampaignOperationEnum.ViewCampaignProduct
                                    ? campaignProductList?.getData()
                                    : undefined
                            }
                            loading={isLoading}
                        />
                    ) : (
                         <ProductList
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            ectomereList={
                                operation !==
                                CampaignOperationEnum.ViewCampaignProduct
                                    ? productList?.getData()
                                    : undefined
                            }
                            campaignProductList={
                                operation ===
                                CampaignOperationEnum.ViewCampaignProduct
                                    ? campaignProductList?.getData()
                                    : undefined
                            }
                            loading={isLoading}
                        />
                    )}
                    {paginationTotal && paginationTotal > 25 && (
                        <Pagination
                            style={{
                                marginTop: "16px",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            onChange={onChangePage}
                            showSizeChanger={false}
                            current={currentPage}
                            pageSize={paginationPageSize}
                            total={paginationTotal}
                            itemRender={paginationItemRender}
                        />
                    )}
                </StyledProductListMainContent>
            </AppsContent>
        </StyledProductListView>
    );
};

export default ProductListing;
