import BaseResource from "../public/baseResource";
import { ResponsePromise } from "lib/core/request/types/typings";

class HarvestJobResource extends BaseResource {
  path = `/api/harvest-job/admin/harvest-job/v1/`;

  get(customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}/harvest-jobs`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
}

export default HarvestJobResource;
