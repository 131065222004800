import { Table } from "antd";
import { IBuyOrderQuantityFormData } from "types/buyOrderDetail";
import { IPropertyValueMap } from "..";

interface IProps {
  bordered?: boolean;
  pagination?: boolean;
  title?: string;
  quantity: IBuyOrderQuantityFormData[];
  mappedSkuProperties: IPropertyValueMap;
}

const SelectedItems = ({
  bordered = false,
  pagination = false,
  title,
  quantity,
  mappedSkuProperties,
}: IProps) => {
  return (
    <Table
      size="small"
      bordered={bordered}
      caption={title}
      dataSource={quantity.map((q, index) => ({
        key: index,
        properties: Object.values(mappedSkuProperties)
          .find((v) => v.getId() === q.sku_id)
          ?.getPropertyAssociations()
          .getCustomizedDataToAddShipmentProductCarton()
          .map((item) => item.property_value_name)
          .join(", "),
        quantity: q.quantity,
      }))}
      footer={() => (
        <p style={{ textAlign: "center" }}>
          Total cost ={" "}
          {quantity
            .reduce((acc, curr) => acc + curr.original_unit_price, 0)
            .toFixed(3)}{" "}
          ৳
        </p>
      )}
      pagination={pagination ? { pageSize: 3 } : false}
    >
      <Table.Column title="Properties" dataIndex="properties" />
      <Table.Column title="Quantity" dataIndex="quantity" />
    </Table>
  );
};

export default SelectedItems;
