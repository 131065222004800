export enum LanguageEnum {
  LanguageCollection = "LanguageCollection",
  Language = "Language",
}
export enum LanguageDataStatus {
  active = "active",
  inactive = "inactive",
}

export enum LanguageCode {
  ENGLISH = "en",
  BENGALI = "bn",
  ARABIC = "ar",
  HINDI = "hi",
  CATALAN = "ca",
}
