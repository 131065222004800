import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import {
  CommissionCopyForBaseShippingRequestModel,
  CommissionCopyForWarehouseRequestModel,
  CommissionCopyRequestModel,
  CommissionPostRequestModel,
} from "models/commissionCollectionModel";
import { ICategoryCommissionAssociationsPutRequest } from "types/commissionCollection";

class CommissionResource extends BaseResource {
  path = `/api/shipping-agent/admin/commission/v1/commissions`;
  categoryCommissionPath = `/shipping-agent/admin/commission/v1/category-commission-association`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}${params ? `?${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: CommissionPostRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  copyCommissionForRegion(
    id: number,
    payload: CommissionCopyRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}/copy`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  copyCategoryCommissionForWarehouse(
    commission_id: number,
    commission_association_id: number,
    payload: CommissionCopyForWarehouseRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${commission_id}/commission-associations/${commission_association_id}/copy`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  copyCategoryCommissionForBaseShipping(
    commission_id: number,
    commission_association_id: number,
    category_commission_id: number,
    payload: CommissionCopyForBaseShippingRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${commission_id}/commission-associations/${commission_association_id}/category-commissions/${category_commission_id}/copy`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  deleteCategoryCommission(
    id: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.categoryCommissionPath}/${id}`;
    return this.client.request("DELETE", path, undefined, {}, customHeaders);
  }

  updateCategoryCommission(
    id: string,
    payload: ICategoryCommissionAssociationsPutRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.categoryCommissionPath}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default CommissionResource;
