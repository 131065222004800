import { Col, Row, Space, Typography } from "antd";
import { BuyActionModel } from "models/buyActionCollectionModel";
const ProductDetails = ({ data }: { data: BuyActionModel }) => {
  const { Text } = Typography;
  return (
    <Row gutter={16}>
      <Col span={3}>
        <img
          src={data?.getBuyProduct().getProductImage()}
          alt="item"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={19}>
        <Space direction="vertical" size={2}>
          <span>
            <Text strong>ID: </Text> {data?.getBuyProduct().getProductNumber()}
          </span>
          <span>
            <Text strong>Title: </Text>{" "}
            <a
              href={data?.getBuyProduct().getProductLink()}
              target="_blank"
              rel="noreferrer"
            >
              {data?.getBuyProduct().getProductTitle()}
            </a>
          </span>
        </Space>
      </Col>
    </Row>
  );
};
export default ProductDetails;
