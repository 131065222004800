import { Button, Form, InputNumber, Row, Col, notification } from "antd";
import React, { useEffect } from "react";
import {
    ExchangeListModel,
    RegionalExchangeModel,
    StoreExchangeModel,
} from "../../../../models/exchangeListCollectionModel";
import {
    useExchangeRegionalStoreRateUpdate,
    useGetExchange,
} from "../../../../lib/core-react/hooks/private/useExchange";
import { IStoreRateUpdatePayload } from "../../../../types/exchangeListCollection";
import { handleFormErrors } from "utils/helper";

interface IProps {
    selectedExchange: ExchangeListModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedStore: StoreExchangeModel;
    selectedRegion: RegionalExchangeModel;
}

const StoreRateUpdate = ({
    selectedExchange,
    setIsShowUpdateModal,
    selectedStore,
    selectedRegion,
}: IProps) => {
    const [form] = Form.useForm();
    const { getExchange } = useGetExchange();
    const { updateStoreRate, isLoading } = useExchangeRegionalStoreRateUpdate();

    useEffect(() => {
        form.resetFields();
    }, [form, selectedExchange, selectedStore, selectedRegion]);

    const onFinish = async (values: any) => {
        const { fx_rate, commission_rate } = values;
        try {
            const data: IStoreRateUpdatePayload = {
                region_id: selectedRegion.getRegion().id,
                store_id: selectedStore.getStore().getId(),
                fx_rate,
                commission_rate,
            };
            const res = await updateStoreRate(data);
            if (res) {
                setIsShowUpdateModal(false);
                notification["success"]({
                    message: res.message,
                });
                getExchange({});
            }
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message
                ? e?.response?.data?.message
                : e.message;
            if (e.response.data.errors) {
                handleFormErrors(form, e.response);
            }
            notification["error"]({
                message: errorMessage,
            });
        }
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    fx_rate: selectedStore.getFxRate(),
                    commission_rate: selectedStore.getCommissionRate(),
                    commission_rate_type: selectedStore.getCommissionRateType(),
                    minimum_order_amount: selectedStore.getMinimumOrderAmount(),
                }}
            >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="fx_rate"
                            label="FX Rate"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the FX rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="commission_rate"
                            label="Commission Rate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input the commission rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            style={{ minWidth: "100px" }}
                            onClick={() => setIsShowUpdateModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={isLoading}
                            style={{ marginLeft: "10px", minWidth: "100px" }}
                            type="primary"
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default StoreRateUpdate;
