import { Collapse } from "antd";
import { ShipmentProductPackageModel } from "models/shipmentProductPackageCollectionModel";
import { CollapseProps } from "antd/lib";

import NotAvailableTracking from "./NotAvailableTracking";
import { AddTrackingForm } from "./AddTrackingForm";
import { UpdateTrackingForm } from "./UpdateTrackingForm";

interface Props {
    packages: ShipmentProductPackageModel[] | undefined;
    productId?: string | number;
    onCloseModal: () => void;
    action: "view" | "add";
}
const Trackings = ({ packages, productId, onCloseModal, action }: Props) => {
    const items: CollapseProps["items"] =
        packages &&
        packages.map((item: ShipmentProductPackageModel, index) => {
            if (item.getTrackings().getData().length > 0) {
                return {
                    key: `${index}`,
                    label: `Package No: ${item.getPackageNumber()} ${item.getCartonNumber() ? "| Carton Number:" + item.getCartonNumber() : ""}`,
                    children: (
                        <>
                            {item
                                .getTrackings()
                                .getData()
                                .map((tracking) => {
                                    return (
                                        <UpdateTrackingForm
                                            action={action}
                                            onCloseModal={onCloseModal}
                                            productId={productId}
                                            trackingId={tracking.getId()}
                                            tracking={tracking}
                                        />
                                    );
                                })}
                        </>
                    ),
                };
            } else {
                return {
                    key: `${index}`,
                    label: `Package No: ${item.getPackageNumber()} ${item.getCartonNumber() ? "| Carton Number:" + item.getCartonNumber() : ""}`,
                    children: (
                        <>
                            <AddTrackingForm
                                action={action}
                                onCloseModal={onCloseModal}
                                productId={productId}
                                packageId={item.getId()}
                            />
                        </>
                    ),
                };
            }
        });

    return (
        <>
            {packages && packages.length > 0 ? (
                <Collapse
                    items={items}
                    defaultActiveKey={["0"]}
                    expandIconPosition="start"
                />
            ) : (
                <NotAvailableTracking text={"Package"} />
            )}
        </>
    );
};

export default Trackings;
