import { ICurrency, ICurrencyCollection } from "types/currencyCollection";
import { IPagination } from "types/pagination";

export class CurrencyModel {
  object: string;
  id: number;
  code: string;
  name: string;
  symbol: string;
  symbol_native: string;

  constructor(data: ICurrency) {
    this.object = data.object;
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.symbol = data.symbol;
    this.symbol_native = data.symbol_native;
  }

  getId = () => this.id;
  getName = () => this.name;
  getCode = () => this.code;
  getSymbol = () => this.symbol;
  getSymbol_native = () => this.symbol_native;
}

export class CurrencyCollectionModel {
  object: string;
  data: CurrencyModel[];
  pagination: IPagination;

  constructor(data: ICurrencyCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CurrencyModel(item));
    this.pagination = data.pagination;
  }

  getData = () => this.data;
  getPagination = () => this.pagination;

  getCurrencyForBDT = () => {
    return this.data.find((item) => item.getCode() === "BDT");
  };

  getCurrencyLabeledValue() {
    return this.data.map((item) => ({
      label: item.getCode(),
      value: item.getCode(),
    }));
  }

  getCurrencyLabeledValueV2() {
    return this.data.map((item) => ({
      label: item.getCode(),
      value: item.getId(),
    }));
  }
}
