import { useService } from "lib/core-react/contexts";
import {
  agentInvoiceCollectionAtom,
  invoiceCollectionAtom,
  invoiceDetailsAtom
} from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useAtom } from "jotai";
import {message, Modal} from "antd";
const { confirm } = Modal;

interface UseInvoice {
  getCustomerInvoice: (params?: string) => Promise<void>;
  getAgentInvoice: (params?: string) => Promise<void>;
  getInvoiceDetailsById: (id: number | string) => Promise<void>;
  recalculateInvoiceData:(id: number | string , requestFor:"customer" | "agent")=> Promise<void>
  settleInvoice:(id: number | string , requestFor:"customer" | "agent")=> Promise<void>
}

export const useInvoice = ():UseInvoice => {
  const [invoice, setInvoice] = useAtom(invoiceCollectionAtom);
  const [agentInvoice, setAgentInvoice] = useAtom(agentInvoiceCollectionAtom)
  const [, setInvoiceDetails] = useAtom(invoiceDetailsAtom)

  const { invoiceService } = useService();

  const getCustomerInvoice = async (params?: string): Promise<void> => {
    setInvoice({ ...invoice, isLoading: true, error: null });
    try {
      const response = await invoiceService.invoiceResource.getCustomerInvoices(params);
      setInvoice({
        ...invoice,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setInvoice({
        ...invoice,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      // throw error;
    }
  };

  const getAgentInvoice = async (params?: string): Promise<void> => {
    setAgentInvoice({ ...agentInvoice, isLoading: true, error: null });
    try {
      const response = await invoiceService.invoiceResource.getAgentInvoices(params);
      setAgentInvoice({
        ...agentInvoice,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAgentInvoice({
        ...agentInvoice,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      // throw error;
    }
  };

  const getInvoiceDetailsById = async(id: number | string): Promise<void> =>{

    setInvoiceDetails(pre=>({
      ...pre, isLoading:true, error:null
    }))

    try {
      const response = await invoiceService.invoiceResource.getCustomerInvoiceDetails(id);
      setInvoiceDetails(pre=>({
        ...pre, data: response, isLoading:false, error:null
      }))

    } catch (error: any) {
      setInvoiceDetails(pre=>({
        ...pre, refetch: false, isLoading:false, error: getError(error),
      }))
      // throw error;
    }
  }

  const recalculateInvoiceData = async (invoiceId: string | number, requestFor:"customer" | "agent" = "customer") => {
    try {
      const response = await invoiceService.invoiceResource.recalculateInvoice(invoiceId, requestFor);
      if (response) {
        message.success(response.message);
        if(requestFor === "customer"){
          setInvoice({
            ...invoice,
            isLoading: false,
            refetch: true,
          });

        }else{
          setAgentInvoice({
            ...agentInvoice,
            isLoading: false,
            refetch: true,
          });
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 422) {
        const errorMessage = e.response.data.message;
        message.error(errorMessage);
      } else {
        message.error("Server error, try again later");
      }
    }
  };

  const handleSettleInvoiceData = async (invoiceId: string | number, requestFor:"customer" | "agent" = "customer") => {
    try {
      const response = await invoiceService.invoiceResource.settleInvoiceTransaction(invoiceId, requestFor);
      if (response) {
        message.success(response.message);
        if(requestFor === "customer"){
          setInvoice({
            ...invoice,
            isLoading: false,
            refetch: true,
          });

        }else{
          setAgentInvoice({
            ...agentInvoice,
            isLoading: false,
            refetch: true,
          });
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 422) {
        const errorMessage = e.response.data.message;
        message.error(errorMessage);
      } else {
        message.error("Server error, try again later");
      }
    }
  };

  const settleInvoice = async (invoiceId: string | number, requestFor:"customer" | "agent" = "customer") => {
    confirm({
      title: 'Do you want to settle this invoice?',
      content: 'This action cannot be undone.',
      async onOk() {
        try {
          await handleSettleInvoiceData(invoiceId, requestFor);
        } catch {
          return;
        } finally {

        }
      },
      onCancel() {
      },
    });

  };

  return { getCustomerInvoice, getInvoiceDetailsById, getAgentInvoice, recalculateInvoiceData ,settleInvoice} as const;
};
