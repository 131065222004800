import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Table,
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  Alert,
  Modal,
} from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import {
  CategoryCommissionModel,
  CommissionAssociationCollectionModel,
  CommissionCollectionModel,
  CommissionRateModel,
  CommissionRateSlotModel,
} from "models/commissionCollectionModel";

import { useGetCommission } from "lib/core-react/hooks/private/useCommission";
import { shippingCommissionAtom } from "lib/core-react/store/store";

import CopyCommissionForWarehouse from "./CopyCommissionForWarehouse";
import CopyCommissionForBaseShipping from "./CopyCommissionForBaseShipping";
import DeleteCategoryCommission from "./DeleteCategoryCommission";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";

interface IProps {
  selectedCommissionId: number;
  selectedCommissionAssociationId: number;
  regionId: number;
}

const CommissionDetails = ({
  regionId,
  selectedCommissionId,
  selectedCommissionAssociationId,
}: IProps) => {
  const navigate = useNavigate();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { getCommission } = useGetCommission();
  const [selectedCommissionCategory, setSelectedCommissionCategory] = useState<
    CategoryCommissionModel | undefined
  >(undefined);

  const [{ data: commissionStoreData }] = useAtom(shippingCommissionAtom);
  const CommissionData =
    commissionStoreData &&
    new CommissionCollectionModel(commissionStoreData).getCommissionById(
      selectedCommissionId,
    );

  const CommissionAssociationData =
    CommissionData?.getCommissionAssociations() &&
    new CommissionAssociationCollectionModel(
      CommissionData?.getCommissionAssociations(),
    ).getCommissionAssociationById(selectedCommissionAssociationId);

  const [isShowCopyModal, setIsShowCopyModal] = useState<boolean>(false);
  const [isShowCategoryCopyModal, setIsShowCategoryCopyModal] =
    useState<boolean>(false);
  const [isShowCategoryDeleteModal, setIsShowCategoryDeleteModal] =
    useState<boolean>(false);

  const handleCommissionAssociationMenuClick = () => {
    setIsShowCopyModal(true);
  };

  const handleCommissionCategoryMenuClick = (e: MenuInfo) => {
    if (e.key === "copyCommissionCategory") {
      setIsShowCategoryCopyModal(true);
    } else if (e.key === "delete") {
      setIsShowCategoryDeleteModal(true);
    } else if ((e.key = "updateCommissionCategory")) {
      navigate(
        `/shipping/commission/update-category/${regionId}/${CommissionData?.getId()}/${CommissionAssociationData?.getId()}/${selectedCommissionCategory?.getId()}`,
      );
    }
  };

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getCommission(`region_id=${regionId}`);
    
  }, []);

  const commissionRateSlotColumns = [
    {
      title: "Min Amount",
      dataIndex: "min_amount",
      key: "slot-min_amount",
      render: (_: string, record: CommissionRateSlotModel) => {
        return <div>{record.getMinAmount()}</div>;
      },
    },
    {
      title: "Max Amount",
      dataIndex: "max_amount",
      key: "slot-max_amount",
      render: (_: string, record: CommissionRateSlotModel) => {
        return <div>{record.getMaxAmount()}</div>;
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "slot-rate",
      render: (_: string, record: CommissionRateSlotModel) => {
        return <div>{record.getRate()}</div>;
      },
    },
    {
      title: "Rate Type",
      dataIndex: "rate_type",
      key: "slot-rate_type",
      render: (_: string, record: CommissionRateSlotModel) => {
        return <div>{record.getRateType()}</div>;
      },
    },
    {
      title: "Max Cap",
      dataIndex: "max_cap",
      key: "slot-max_cap",
      render: (_: string, record: CommissionRateSlotModel) => {
        return <div>{record.getMaxCap()}</div>;
      },
    },
  ];

  const commissionRateColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "commission-rate-id",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getId()}</div>;
      },
    },
    {
      title: "Shipping Type",
      dataIndex: "shipping_type",
      key: "commission-rate-shipping-type",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getShippingType()}</div>;
      },
    },
    {
      title: "Shipping Mode",
      dataIndex: "shipping_mode",
      key: "commission-rate-shipping-mode",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getShippingMode()}</div>;
      },
    },
    {
      title: "Unit Type",
      dataIndex: "unit_type",
      key: "commission-rate-unit-type",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getUnitType()}</div>;
      },
    },
    {
      title: "Rate Type",
      dataIndex: "rate_type",
      key: "commission-rate-rate-type",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getRateType()}</div>;
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "commission-rate-rate",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getRate()}</div>;
      },
    },
    {
      title: "Max Cap",
      dataIndex: "max_cap",
      key: "commission-rate-max_cap",
      render: (_: string, record: CommissionRateModel) => {
        return <div>{record.getMaxCap()}</div>;
      },
    },
    {
      title: "Slots",
      dataIndex: ["slots", "data"],
      align: "center" as const,
      key: "commission_rate-slot",
      render: (_: string, record: CommissionRateModel) => {
        return (
          <Table
            dataSource={record.getSlots().getData()}
            rowKey="id"
            columns={commissionRateSlotColumns}
            pagination={false}
            bordered={false}
            size="small"
            className="meta_table"
          />
        );
      },
    },
  ];

  const items: ExtendedMenuItemType[] = [
    {
      permission:ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_COPY,
      icon: <CopyOutlined />,
      label: "Copy",
      key: "copyCommissionCategory",
    },
    {
      permission:ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_UPDATE,
      icon: <EditOutlined />,
      label: "Update",
      key: "updateCommissionCategory",
    },
    {
      permission:ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_DELETE,
      icon: <EditOutlined />,
      label: "Delete",
      key: "delete",
    },
  ];

  const menuProps = {
    items: items.filter((x) =>
      checkActionPermission(x.permission, x, null),
    ),
    onClick: handleCommissionCategoryMenuClick,
  };

  return (
    <>
      {CommissionData && CommissionAssociationData && (
        <PageHeader
          style={{ marginTop: "10px" }}
          ghost={false}
          title={`Base Shipping Categories`}
          extra={[
            <Button
              key="2"
              type="primary"
              onClick={handleCommissionAssociationMenuClick}
            >
              Copy for another warehouse
            </Button>,
          ]}
        >
          <Row gutter={[16, 16]}>
            {CommissionAssociationData.getCategoryCommissionAssociations()
              .getData()
              .map((commissionCategory, index) => (
                <Col span={24} key={commissionCategory.getId()}>
                  <Card
                    style={{ border: "none" }}
                    title={`${index + 1}. ${commissionCategory.getBaseShippingCategory().name
                      }`}
                    extra={
                      <Dropdown
                        menu={menuProps}
                        onOpenChange={() =>
                          setSelectedCommissionCategory(commissionCategory)
                        }
                      >
                        <Button icon={<HddFilled />}>
                          Actions <DownOutlined />
                        </Button>
                      </Dropdown>
                    }
                  >
                    <Table
                      className="meta_table"
                      rowKey="id"
                      bordered
                      dataSource={commissionCategory
                        .getCommissionRates()
                        .getData()}
                      columns={commissionRateColumns}
                      pagination={{ pageSize: 10 }}
                      scroll={{ x: 1000 }}
                    />
                  </Card>
                </Col>
              ))}
          </Row>

          <Modal
            title={`Copy ${CommissionAssociationData.agent_warehouse.name} Information`}
            open={isShowCopyModal}
            footer={false}
            onCancel={() => setIsShowCopyModal(false)}
          >
            <CopyCommissionForWarehouse
              setIsShowCopyModal={setIsShowCopyModal}
              selectedCommissionAssociation={CommissionAssociationData}
              selectedCommission={CommissionData}
            />
          </Modal>

          {selectedCommissionCategory &&
            CommissionAssociationData &&
            CommissionData && (
              <>
                <Modal
                  title={`Copy ${selectedCommissionCategory.base_shipping_category.name} Information`}
                  open={isShowCategoryCopyModal}
                  footer={false}
                  onCancel={() => setIsShowCategoryCopyModal(false)}
                >
                  <CopyCommissionForBaseShipping
                    setIsShowCopyModal={setIsShowCategoryCopyModal}
                    selectedCommissionCategory={selectedCommissionCategory}
                    selectedCommissionAssociation={CommissionAssociationData}
                    selectedCommission={CommissionData}
                  />
                </Modal>
                <Modal
                  title={`Delete Commission Category`}
                  open={isShowCategoryDeleteModal}
                  footer={false}
                  onCancel={() => setIsShowCategoryDeleteModal(false)}
                >
                  <DeleteCategoryCommission
                    setIsShowDeleteModal={setIsShowCategoryDeleteModal}
                    selectedCategoryCommission={selectedCommissionCategory}
                  />
                </Modal>
              </>
            )}

          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </PageHeader>
      )}
    </>
  );
};

export default CommissionDetails;
