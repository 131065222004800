import React, { useEffect, useState } from "react";
import {
    useBulkRejectBuyProduct,
    useGetBuyProduct,
    useGetBuyProducts,
    useRejectBuyProduct,
    useSetCurrentFx,
} from "../../../lib/core-react/hooks/private/usePurchase";
import { useAtom, useAtomValue } from "jotai/index";
import { buyProductCollectionAtom } from "../../../lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import useDataFilters from "../../../hooks/useDataFilters";
import { ApiHelperModel } from "../../../models/apiHelper";
import { IFilterType } from "../../../types/filters";
import {
    BuyProductCollectionModel,
    BuyProductModel,
} from "../../../models/buyOrderCollectionModel";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Badge,
    Button,
    Card,
    Col,
    Dropdown,
    Image,
    Modal,
    notification,
    Row,
    Space,
    TableColumnsType,
    Tabs,
} from "antd";
import FiltersComponent from "../../../components/FiltersComponent";
import BuyProductsTable from "../components/BuyProductsTable";
import ProductInfo, { IProductInfo } from "../components/ProductInfo";
import { ProductMeta } from "../components";
import ShippingDetails from "../components/ShippingDetails";
import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    DownOutlined,
    EditOutlined,
    FunctionOutlined,
    HddFilled,
    InteractionOutlined,
} from "@ant-design/icons";
import BuyProductAgentAssignModal from "./components/BuyProductAgentAssignModal";
import TakeActionsModal from "./components/TakeActionsModal";
import { PaginationModel } from "models/pagination";
import UpdateFxModal from "./components/UpdateFxModal";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { TimelineTracking } from "../components/viewDetails/TimelineTracking";
import { buyProductDetail } from "../../../lib/core-react/store/store";
import { BuyProductView } from "../../../models/buyProductDetailModel";
import { BulkRejectPayload } from "types/buyOrderCollection";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM } from "consts/permission-enum/buy-and-take-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
import { UpdateShippingPrice } from "./components";
import CommonError from "../../../components/Error/CommonError";
import ActionsNeededsBuyProduct from "./components/ActionsNeededBuyProduct";
import Trackings from "pages/Shipping/ShipmentProducts/EditShipmentProduct/Trackings/Trackings";
const { TabPane } = Tabs;
interface ExpandedDataType {
    key: React.Key;
    date: string;
    productSeller: BuyProductModel;
    upgradeNum: string;
}

export interface UpdateFax {
    product_id: number;
    fxRate: number;
    commission_type: string;
    commission_rate: number;
    totalFxAndCommission: string;
}

const BuyProducts = () => {
    const [openReject, setOpenReject] = useState<boolean>(false);
    const [isApproveModalOpen, setIsApproveModalOpen] =
        useState<boolean>(false);
    const [isTakeActionsModalOpen, setIsTakeActionsModalOpen] =
        useState<boolean>(false);
    const [isUpdateFxModalOpen, setIsUpdateFxModalOpen] =
        useState<boolean>(false);
    const [isOpenUpdateShippingModal, setIsOpenUpdateShippingModal] =
        useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<
        BuyProductModel | undefined
    >(undefined);

    const [isSeletedTakeActionProduct, setIsSelectedTakeActionProduct] =
        useState<BuyProductModel>();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isBulkReject, setIsBulkReject] = useState(false);

    const [selectFxData, setSelectFxData] = useState<UpdateFax>();
    const { isMobile, width } = useWindowWidth();
    const [activeTab, setActiveTab] = useState<string>("");
    const { setCurrentFx, isLoading: currentFxLoading } = useSetCurrentFx();
    const {
        data: buyProductCollectionData,
        refetch,
        isLoading,
        unAuthorized,
    } = useAtomValue(buyProductCollectionAtom);

    const { getBuyProducts } = useGetBuyProducts();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const { rejectBuyProduct, isLoading: isRejectLoading } =
        useRejectBuyProduct();

    const { bulkRejectBuyProduct, isLoading: isBulkRejectLoading } =
        useBulkRejectBuyProduct();

    const [{ data: buyProductDetailData, isLoading: isBuyProductLoading }] =
        useAtom(buyProductDetail);
    const { getBuyProduct } = useGetBuyProduct();

    const buyProductDetailsForTimeLine =
        buyProductDetailData && new BuyProductView(buyProductDetailData);

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            filters && ApiHelperModel.makeGetRequest(filters, getBuyProducts);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    useEffect(() => {
        // Cleanup function to reset state when modal closes
        return () => {
            setSelectedProduct(undefined);
        };
    }, [isTakeActionsModalOpen]);

    // ------------------------------ API Data Model -> --------------------------- //

    const buyProductCollectionModel =
        buyProductCollectionData &&
        new BuyProductCollectionModel(buyProductCollectionData);

    // ---------------------------- Filter Data Model -> -------------------------- //

    let orderFiltersModel = buyProductCollectionModel
        ? buyProductCollectionModel.getFilters()
        : undefined;
    // ---------------------- Getting all available filters -> -------------------- //

    useEffect(() => {
        if (!isFetched && orderFiltersModel) {
            initializeAvailableFilter(orderFiltersModel as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter]);

    // Filter Handler
    const handleFilter = () => {
        filters && ApiHelperModel.makeGetRequest(filters, getBuyProducts);
    };

    const ShowApprovedModal = (opration: BuyProductModel) => {
        setSelectedProduct(opration);
        setIsApproveModalOpen(true);
    };
    const handleUpdateShipping = (productData: BuyProductModel) => {
        setSelectedProduct(productData);
        setIsOpenUpdateShippingModal(true);
    };

    const ShowBulkApprovedModal = () => {
        setIsApproveModalOpen(true);
    };

    const ShowTakeActionsModal = (opration: BuyProductModel) => {
        setIsSelectedTakeActionProduct(opration);
        setIsTakeActionsModalOpen(true);
    };

    const showRejectConfirm = (operation: BuyProductModel) => {
        setSelectedProduct(operation);
        setIsBulkReject(false);
        setOpenReject(true);
    };

    const showBulkRejectConfirm = () => {
        setIsBulkReject(true);
        setOpenReject(true);
    };
    const showUpdateFx = (operation: BuyProductModel) => {
        const updateFx: UpdateFax = {
            product_id: operation.getId(),
            fxRate: operation.getProductCustomerAssociation().getFxRate(),
            commission_rate: operation
                .getProductCustomerAssociation()
                .getCommissionRate(),
            commission_type: operation
                .getProductCustomerAssociation()
                .getCommissionRateType(),
            totalFxAndCommission: operation
                .getProductCustomerAssociation()
                .getTotalCommissionAndFx(),
        };
        setSelectFxData(updateFx);
        setIsUpdateFxModalOpen(true);
    };

    const fetchItemDetails = (id: number) => {
        getBuyProduct(id);
    };

    // Reject handlers =>
    const handleRejectOk = async () => {
        try {
            if (isBulkReject) {
                const payload: BulkRejectPayload = {
                    product_ids: selectedRowKeys.map((key) => Number(key)),
                };
                const data = await bulkRejectBuyProduct(payload);
                if (data) {
                    notification["success"]({
                        message: data.message,
                    });
                }
            } else {
                const data = await rejectBuyProduct(
                    Number(selectedProduct?.getId()),
                );
                if (data) {
                    notification["success"]({
                        message: data.message,
                    });
                }
            }
            setOpenReject(false);
        } catch (error: any) {
            setOpenReject(false);
        }
    };

    const handleRejectCancel = () => {
        setOpenReject(false);
    };

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getBuyProducts,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        buyProductCollectionModel,
        handlePaginationChange,
    );

    // ------------------------------ Loading -> --------------------------- //
    const data = buyProductCollectionModel
        ? buyProductCollectionModel.getProcessedProducts()
        : [];

    const handleSetCurrentFx = async (productId: number) => {
        await setCurrentFx(productId);
    };

    if (unAuthorized) {
        return <CommonError unAuthorized={unAuthorized} />;
    }

    const createMenuProps = (operation: {
        productDetails: BuyProductModel;
        productID: number;
        productNumber: any;
    }) => {
        const { productDetails } = operation;

        const items = [
            {
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                key: "update_fx",
                icon: <FunctionOutlined />,
                onClick: () => showUpdateFx(productDetails),
                label: "Update Fx",
            },
            {
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                key: "set_current_fx",
                icon: <FunctionOutlined />,
                onClick: () => handleSetCurrentFx(productDetails.getId()),
                label: "Set Current Fx",
                loading: currentFxLoading,
                disabled: currentFxLoading,
            },
            {
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                key: "shippingPriceUpdate",
                icon: <EditOutlined />,
                onClick: () => handleUpdateShipping(productDetails),
                label: "Shipping Price Update",
            },
        ] as ExtendedMenuItemType[];

        if (
            productDetails.status === "buy-processing" ||
            productDetails.status === "buy-purchased" ||
            productDetails.status === "buy-not-purchased"
        ) {
            items.unshift({
                permission:
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_ADD,
                key: "buyTakeActions",
                icon: <InteractionOutlined />,
                onClick: () => ShowTakeActionsModal(productDetails),
                label: "Take Actions",
            });
        }

        if (productDetails.status === "buy-pending") {
            items.unshift(
                {
                    permission:
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                    key: "approved",
                    icon: <CheckCircleOutlined />,
                    onClick: () => ShowApprovedModal(productDetails),
                    label: "Approved",
                },
                {
                    permission:
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                    key: "reject",
                    icon: <CloseCircleOutlined />,
                    onClick: () => showRejectConfirm(productDetails),
                    label: "Reject",
                },
            );
        }

        return { items };
    };

    const columns: TableColumnsType<ExpandedDataType> = [
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            width: 80,
            render: (image) => (
                <Image
                    width={100}
                    src={image}
                    placeholder={
                        <Image preview={false} src={image} width={100} />
                    }
                />
            ),
        },
        {
            title: "Product Info",
            dataIndex: "productInfo",
            key: "productInfo",
            width: 300,
            showSorterTooltip: { target: "full-header" },
            render: (productInfo: IProductInfo) => (
                <ProductInfo productInfo={productInfo} />
            ),
            // filters: [
            //   {
            //     text: "Joe",
            //     value: "Joe",
            //   },
            //   {
            //     text: "Jim",
            //     value: "Jim",
            //   },
            // ],
            // onFilter: (value, record) => {
            //   return (
            //     record.productSeller.getProductSeller().getSellerName() === value
            //   );
            // },
        },
        {
            title: "Shipping Details",
            dataIndex: "shippingDetails",
            key: "shippingDetails",
            width: 200,
            render: (shippingDetails) => (
                <ShippingDetails shippingDetails={shippingDetails} />
            ),
        },
        {
            title: "Actions",
            dataIndex: "operation",
            key: "operation",
            fixed: isMobile ? "right" : undefined,
            width: isMobile ? 70 : 150,
            render: (operation) => {
                const menuProps = createMenuProps(operation);
                return (
                    <Space size="middle">
                        <Dropdown menu={menuProps}>
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    </Space>
                );
            },
        },
    ];

    const bulkItems: ExtendedMenuItemType[] = [
        {
            permission:
                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
            icon: <CheckCircleTwoTone />,
            label: "Approved",
            key: "approve-and-assign-agent",
            onClick: () => ShowBulkApprovedModal(),
        },
        {
            permission: ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
            key: "reject",
            icon: <CloseCircleOutlined />,
            onClick: () => showBulkRejectConfirm(),
            label: "Reject",
        },
    ];

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Buy And Ship Products"}
                style={{ marginTop: "10px" }}
            >
                {filters && Object.keys(filters).length > 0 && (
                    <Row>
                        <Col span={24}>
                            <Card title={"Filter"}>
                                <FiltersComponent
                                    handleProductFilter={handleFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={orderFiltersModel}
                                    isFromProductReceived={true}
                                />
                            </Card>
                        </Col>
                    </Row>
                )}

                <div style={{ margin: "20px 0", textAlign: "right" }}>
                    <Dropdown
                        menu={{
                            items: bulkItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                        disabled={selectedRowKeys.length === 0}
                    >
                        <Button icon={<HddFilled />}>
                            Bulk Actions
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>

                <div style={{ marginTop: "15px" }}>
                    <BuyProductsTable
                        loading={isLoading}
                        dataSource={data}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                        rowKey="uniqueKey"
                        pagination={paginationConfig}
                        columns={columns}
                        expandedRowRender={{
                            expandedRowKeys: data.map((x) => x.uniqueKey),
                            expandedRowRender: (record) => {
                                return (
                                    <Tabs
                                        onChange={(key) => {
                                            // const id = key.split("-")[1];
                                            // setBuyProductId({
                                            //   buy_product_id: Number(id),
                                            // });

                                            setActiveTab(key);
                                            if (key.includes("timeline")) {
                                                fetchItemDetails(
                                                    record.uniqueKey,
                                                );
                                            }
                                        }}
                                        activeKey={activeTab}
                                    >
                                        <TabPane
                                            tab="Product Meta"
                                            key={`productMeta-${record.uniqueKey}`}
                                        >
                                            <ProductMeta
                                                productMeta={record.productMeta}
                                            />
                                        </TabPane>
                                        <TabPane
                                            tab="Timeline"
                                            key={`timeline-${record.uniqueKey}`}
                                        >
                                            {isBuyProductLoading
                                                ? "Please wait..."
                                                : buyProductDetailsForTimeLine?.getData() && (
                                                      <TimelineTracking
                                                          productData={{
                                                              id: buyProductDetailsForTimeLine
                                                                  ?.getData()
                                                                  .getId(),
                                                              productNumber:
                                                                  buyProductDetailsForTimeLine
                                                                      ?.getData()
                                                                      .getProductNumber(),
                                                          }}
                                                          isLoading={false}
                                                          title="Timeline"
                                                          modalWidth={"100%"}
                                                      />
                                                  )}
                                        </TabPane>
                                        <TabPane
                                            tab="Take Action"
                                            key={`action-${record.uniqueKey}`}
                                        >
                                            <ActionsNeededsBuyProduct
                                                selectedBuyActionProduct={
                                                    record.productInfo.buyAction
                                                }
                                                isBuyProductLoading={
                                                    isBuyProductLoading
                                                }
                                            />
                                        </TabPane>
                                        <TabPane
                                            tab={<div>
                                                <Badge offset={[16,0]} showZero={true} count={record.totalTrackingCount}>
                                                    Trackings
                                                </Badge>
                                            </div>}
                                            key={`tracking-${record.uniqueKey}`}
                                        >
                            

                                            <Trackings action="view" onCloseModal={()=>{}} packages={record.packages} productId={record.productId}/>
                                        </TabPane>
                                    </Tabs>
                                );
                            },
                            expandIcon: () => null,
                        }}
                    />
                </div>
                {(selectedRowKeys.length > 0 || selectedProduct) &&
                    isApproveModalOpen && (
                        <Modal
                            title="Select Buying Agent"
                            open={isApproveModalOpen}
                            footer={false}
                            onCancel={() => {
                                setIsApproveModalOpen(false);
                                setSelectedProduct(undefined);
                            }}
                            width={width < 500 ? "90%" : "70%"}
                        >
                            <BuyProductAgentAssignModal
                                isApproveModalOpen={isApproveModalOpen}
                                setIsApproveModalOpen={setIsApproveModalOpen}
                                selectedRowKeys={selectedRowKeys}
                                productId={Number(selectedProduct?.getId())}
                                storeId={selectedProduct?.getStore().getId()}
                            />
                        </Modal>
                    )}

                {isSeletedTakeActionProduct && (
                    <TakeActionsModal
                        isTakeActionsModalOpen={isTakeActionsModalOpen}
                        setIsTakeActionsModalOpen={setIsTakeActionsModalOpen}
                        selectedProduct={isSeletedTakeActionProduct}
                    />
                )}

                {openReject && (
                    <Modal
                        open={openReject}
                        title={
                            isBulkReject
                                ? "Bulk Product Reject"
                                : "Product Reject"
                        }
                        onCancel={handleRejectCancel}
                        footer={[
                            <Button key="back" onClick={handleRejectCancel}>
                                No
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                disabled={
                                    isRejectLoading || isBulkRejectLoading
                                }
                                loading={isRejectLoading || isBulkRejectLoading}
                                onClick={handleRejectOk}
                            >
                                Yes
                            </Button>,
                        ]}
                    >
                        Are you sure you want to{" "}
                        {isBulkReject
                            ? "reject the selected products?"
                            : "reject the product?"}
                    </Modal>
                )}

                {selectFxData && (
                    <Modal
                        title="Update Fx"
                        open={isUpdateFxModalOpen}
                        destroyOnClose={true}
                        confirmLoading={isLoading}
                        onCancel={() => {
                            setIsUpdateFxModalOpen(false);
                        }}
                        footer={false}
                    >
                        <UpdateFxModal
                            fxData={selectFxData}
                            setIsUpdateFxModalOpen={setIsUpdateFxModalOpen}
                        />
                    </Modal>
                )}
                {isOpenUpdateShippingModal && selectedProduct && (
                    <Modal
                        title={`Shipping price update (${selectedProduct.getProductAgentAssociation()?.getAgentCompany().primary_name || "Agent company not assign"})`}
                        open={isOpenUpdateShippingModal}
                        destroyOnClose={true}
                        onCancel={() => {
                            setIsOpenUpdateShippingModal(false);
                        }}
                        footer={false}
                    >
                        <UpdateShippingPrice
                            setIsOpenUpdateShippingModal={
                                setIsOpenUpdateShippingModal
                            }
                            selectedProduct={selectedProduct}
                        />
                    </Modal>
                )}
            </PageHeader>
        </div>
    );
};

export default BuyProducts;
