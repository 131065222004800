import { v4 as uuidv4 } from "uuid";

export interface IGuestData {
    guestId: string;
    guestSession: string;
}

const isBrowser = typeof window !== 'undefined';


const generateGuestId = (): string => {
    return uuidv4();
};

const generateGuestSession = (): string => {
    return uuidv4();
};

const setAndGetGuestData = (): IGuestData | null => {
    if (!isBrowser) {
        // localStorage is not available on the server
        return null;
    }

    const getLocalStorageItem = (key: string): string | null => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.error("Error getting item from localStorage:", error);
            return null;
        }
    };

    const setLocalStorageItem = (key: string, value: string): void => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error("Error setting item in localStorage:", error);
        }
    };

    let guestId: string | null = getLocalStorageItem("mvn-guest-id");
    let guestSession: string | null = getLocalStorageItem("mvn-guest-session");

    if (!guestId) {
        // Generate guest id if not available
        guestId = generateGuestId();
        setLocalStorageItem("mvn-guest-id", guestId);
    }

    if (!guestSession) {
        // Generate guest session if not available
        guestSession = generateGuestSession();
        setLocalStorageItem("mvn-guest-session", guestSession);
    }

    return { guestId: guestId as string, guestSession: guestSession as string };
};


export default setAndGetGuestData;