import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Dropdown,
    Modal,
    Row,
    Space,
    Spin,
    Table,
    Typography,
    notification,
} from "antd";
import {
    CheckCircleOutlined,
    DownOutlined,
    HddFilled,
} from "@ant-design/icons";
import { useAtomValue } from "jotai";

import { agentCategoryPriceReadCollectionAtom } from "lib/core-react/store/store";
import { useGetCategoryPrices } from "lib/core-react/hooks/private/useShippingAgent";
import {
    useApprovedShippingProductForProduct,
    useCreateBid,
    useDefaultAgentAssign,
    useGetShipmentProducts,
} from "lib/core-react/hooks/private/useShipping";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
    AgentCategoryPriceSlotModel,
} from "models/agentCategoryPriceReadCollectionModel";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";
import {
    IDefaultAssignAgentPayload,
    IOpenBidPayload,
} from "types/shipmentProductCollection";
import { ShipmentProductModel } from "models/shipmentProductCollectionModel";
import AgentAssignWithPriceModal from "./AgentAssignWithPriceModal";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
import useDataFilters from "hooks/useDataFilters";
import { IFilterType } from "types/filters";
import FiltersComponent from "components/FiltersComponent";

interface IProps {
    open: boolean;
    selectedProductIds: number[];
    onCancel: () => void;
    categoryPriceDetails: ICategoryPriceDetails;
    selectedProducts: ShipmentProductModel[];
}
export const SelectWarehouseModal = ({
    open,
    onCancel,
    categoryPriceDetails,
    selectedProductIds,
    selectedProducts,
}: IProps) => {
    const { getShipmentProducts } = useGetShipmentProducts();
    const { approvedShippingProduct } = useApprovedShippingProductForProduct();

    const {
        filters,
        handleFilterChange,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
    } = useDataFilters();

    const { isMobile } = useWindowWidth();

    const [isAssigning, setIsAssigning] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<
        AgentCategoryPriceReadModel[]
    >([]);
    const { defaultAgentAssign } = useDefaultAgentAssign();
    const { createBid } = useCreateBid();

    const { getCategoryPrices } = useGetCategoryPrices();
    const { data, isLoading } = useAtomValue(
        agentCategoryPriceReadCollectionAtom,
    );

    const getData = async () => {
        await getCategoryPrices(categoryPriceDetails);
    };

    useEffect(() => {
        getData();
    }, [categoryPriceDetails]);

    const agentCategoryPriceRead =
        data && new AgentCategoryPriceReadCollectionModel(data);

    const filterData = agentCategoryPriceRead?.getFilters();
    const okHandler = () => {};

    const [priceReadId, setPriceReadId] = useState<number | undefined>(
        undefined,
    );
    const [showAgentAssignModal, setShowAgentAssignModal] =
        useState<boolean>(false);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && agentCategoryPriceRead?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        agentCategoryPriceRead?.getFilters(),
    ]);

    // Filter Handler
    const handleProductFilter = async () => {
        const merged = getMergeFilterItems();
        await getCategoryPrices(merged);
    };

    const defaultAgentAssignHandler = async (priceReadId: number) => {
        try {
            // first we approve all
            setIsAssigning(true);
          await Promise.all(
            selectedProducts
              .filter(
                (product) =>
                  product.getStatus() === "shipment-pending" ||
                  product.getStatus() === "shipment-rejected"
              )
              .map(async (product) => {
                await approvedShippingProduct(product.getId());
              })
          );

            const payload: IDefaultAssignAgentPayload = {
                agent_category_price_read_id: priceReadId,
                shipment_product_ids: selectedProductIds,
            };
            // now assign agent
            await defaultAgentAssign(payload);
            notification["success"]({
                message: "Agent Assign successfully",
            });
            setIsSuccess(true);
            onCancel();
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        } finally {
            setIsAssigning(false);
        }
    };

    const createBidHandler = async (
        payload: IOpenBidPayload,
        productId: number,
    ) => {
        try {
            if (
                selectedProducts[0].getStatus() === "shipment-pending" ||
                selectedProducts[0].getStatus() === "shipment-rejected"
            ) {
                await approvedShippingProduct(selectedProducts[0].getId());
            }
            await getShipmentProducts();
            await createBid(payload, productId);
            notification["success"]({
                message: "Create Admin User successfully",
            });
            onCancel();
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    // Bid is possible only when there is one product selected and a warehouse selection is also required
    const isBidDisabled =
        selectedRowKeys.length === 0 || selectedProductIds.length > 1;

    const mainItems: ExtendedMenuItemType[] = [
        {
            permission:
                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_REJECT_SHIPMENT_PRODUCT,
            label:
                selectedProducts[0].getStatus() === "shipment-pending" ||
                selectedProducts[0].getStatus() === "shipment-rejected"
                    ? "Approve & Open Bid"
                    : "Open Bid",
            key: "open_bid",
            onClick: () =>
                createBidHandler(
                    {
                        agent_warehouse_ids: [
                            ...selectedRows.map((row) =>
                                row.getAgentWarehouse().getId(),
                            ),
                        ],
                    },
                    Number(selectedProductIds[0]),
                ),
            disabled: isBidDisabled,
            title:
                selectedProductIds.length > 1
                    ? "Bid is not possible when multiple product is selected"
                    : selectedRowKeys.length === 0
                      ? "Please select warehouse to open bid"
                      : "",
        },
    ];

    const menuProps = {
        items: mainItems.filter((x) =>
            checkActionPermission(x.permission, x, null),
        ),
        onClick: () => {},
    };

    const columns = [
        {
            title: "Warehouse",
            dataIndex: "warehouse",
            key: "warehouse",
            render: (_text: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text
                    ellipsis={{ tooltip: record.getAgentWarehouse().getName() }}
                >
                    {record.getAgentWarehouse().getName()}
                </Typography.Text>
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            render: (_: string, record: AgentCategoryPriceReadModel) =>
                record.getRate(),
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() === "percentage"
                        ? " (%)"
                        : " (Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Customer Cost",
            dataIndex: "customer_cost",
            key: "customer_cost",
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getTotalRate()} /- {record.getUnitType()}
                </Typography.Text>
            ),
        },
        {
            title: "Slots",
            dataIndex: "slots",
            key: "slots",
            children: [
                {
                    title: "Minimum",
                    dataIndex: "min_amount",
                    key: "slot-min_amount",
                    width: 100,
                    render: (
                        _: string,
                        slotRecord: AgentCategoryPriceSlotModel,
                    ) => {
                        return (
                            <>
                                <Typography.Text>
                                    {slotRecord.min_amount}
                                </Typography.Text>
                            </>
                        );
                    },
                },
                {
                    title: "Maximum",
                    dataIndex: "max_amount",
                    key: "slot-max_amount",
                    width: 100,
                    render: (
                        _: string,
                        slotRecord: AgentCategoryPriceSlotModel,
                    ) => {
                        return (
                            <>
                                <Typography.Text>
                                    {slotRecord?.max_amount}
                                </Typography.Text>
                            </>
                        );
                    },
                },
                {
                    title: "Rate",
                    dataIndex: "rate",
                    key: "slot-rate",
                    width: 100,
                    render: (
                        _: string,
                        slotRecord: AgentCategoryPriceSlotModel,
                    ) => {
                        return (
                            <>
                                <Typography.Text>
                                    {slotRecord.rate}
                                </Typography.Text>
                            </>
                        );
                    },
                },
            ],
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 120,
            render: (_: string, record: AgentCategoryPriceReadModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        // label: (
                        //   <Popconfirm
                        //     title="Assign agent"
                        //     description="Are you sure about assigning product to this agent?"
                        //     onConfirm={() =>
                        //       defaultAgentAssignHandler(record.getId())
                        //     }
                        //     okButtonProps={{
                        //       loading: isLoadingDefaultAgentAssign,
                        //     }}
                        //     okText="Yes"
                        //     okType="danger"
                        //     cancelText="No"
                        //   >
                        //     <span onClick={(e) => e.stopPropagation()}>
                        //       Assign Agent
                        //     </span>
                        //   </Popconfirm>
                        // ),
                        permission:
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                        label: "Assign Agent",
                        icon: isAssigning ? (
                            <Spin />
                        ) : isSuccess ? (
                            <CheckCircleOutlined />
                        ) : null,
                        onClick: () => {
                            defaultAgentAssignHandler(record.getId());
                        },
                        key: "1",
                    },
                    {
                        permission:
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                        label: "Assign agent with price",
                        key: "2",
                        onClick: () => {
                            setShowAgentAssignModal(true);
                            setPriceReadId(record.getId());
                        },
                    },
                ];
                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            Actions
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const getMergeFilterItems = () => {
        const merged = { ...categoryPriceDetails };
        for (const key in filters) {
            if (key === "page" || key === "per_page") {
                continue;
            }

            if (filters[key] !== undefined) {
                merged[key] = filters[key];
            }
        }
        return merged;
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                footer={false}
                title={
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Text>Select Warehouse</Typography.Text>
                        <Dropdown menu={menuProps}>
                            <Button icon={<HddFilled />}>
                                Actions
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                }
                width="1000px"
                open={open}
                onOk={okHandler}
                onCancel={() => {
                    onCancel();
                    handelFilterClear();
                }}
                closable={false}
            >
                {filters && Object.keys(filters).length > 0 && (
                    <Row>
                        <Col span={24}>
                            <Card title="Filter">
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={getMergeFilterItems()}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Card>
                        </Col>
                    </Row>
                )}
                <Table
                    style={{ marginTop: 10 }}
                    size="small"
                    dataSource={agentCategoryPriceRead?.getData()}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeys(selectedRowKeys);
                            setSelectedRows(selectedRows);
                        },
                    }}
                    //@ts-ignore
                    columns={columns}
                    loading={isLoading}
                    scroll={{ x: 950 }}
                ></Table>
            </Modal>

            {priceReadId && agentCategoryPriceRead && (
                <AgentAssignWithPriceModal
                    agentCategoryPriceRead={categoryPriceDetails}
                    onCancel={() => setShowAgentAssignModal(false)}
                    open={showAgentAssignModal}
                    priceReadId={priceReadId}
                    selectedProducts={selectedProducts}
                    selectedProductIds={selectedProductIds}
                />
            )}
        </>
    );
};
