import {
  DisputeCollectionEnum,
  DisputeItemCollectionEnum,
  DisputeMandatesEnum,
  DisputeRegionEnum,
} from "enums/disputeCollectionEnum";

import {
  IDisputeBuyProduct,
  IDisputeRegion,
  IDisputeUser,
} from "types/disputeCollection";
import {
  IBuyProductVariation,
  IDisputeBuyProductVariationProperty,
  IDisputeDetails,
  IDisputeDetailsCollection,
  IDisputeItem,
  IDisputeMandate,
} from "types/disputeDetailsCollection";
import { ICurrencyData } from "../types/currency";

export class DisputeBuyProductModel {
  id: number;
  product_number: string;
  product_id: string;
  product_vid: string;
  cart_item_id: number;
  product_title: string;
  product_image: string;
  product_link: string;
  shipping_provider: string;
  total_quantity: number;
  status: string;
  created_at: string;
  constructor(dataItem: IDisputeBuyProduct) {
    this.id = dataItem?.id;
    this.product_number = dataItem?.product_number;
    this.product_id = dataItem?.product_id;
    this.product_title = dataItem?.product_title;
    this.product_link = dataItem?.product_link;
    this.shipping_provider = dataItem?.shipping_provider;
    this.status = dataItem?.status;
    this.total_quantity = dataItem?.total_quantity;
    this.product_image = dataItem?.product_image;
    this.cart_item_id = dataItem?.cart_item_id;
    this.product_vid = dataItem?.product_vid;
    this.created_at = dataItem?.created_at;
  }

  getId = () => this.id;
  getProductId = () => this.product_id;
  getProductTitle = () => this.product_title;
  getCreatedAt = () => this.created_at;
  getStatus = () => this.status;
  getShippingProvider = () => this.shipping_provider;
  getProductImage = () => this?.product_image;
}

export class RefundDetailsCurrencyModel{
   private readonly  currency:ICurrencyData

  constructor(currency:ICurrencyData) {
     this.currency = currency
  }
  getId(){
    return this.currency.id
  }
  getCurrencyCode(){
     return this.currency.code
  }
  getName(){
     return this.currency.name
  }
  getSymbol(){
     return this.currency.symbol
  }
  getSymbolNative(){
     return this.currency.symbol_native
  }
  getStatus(){
     return  this.currency.status
  }
}
export class DisputeRegionModel {
  object: DisputeRegionEnum.Region;
  id: number;
  name: string;
  code: string;
  store_type: string;
  store: string;
  status: string;
  created_at: string;
  currency?: RefundDetailsCurrencyModel | undefined

  constructor(data: IDisputeRegion) {
    this.object = data?.object;
    this.id = data?.id;
    this.name = data?.name;
    this.code = data?.code;
    this.store_type = data?.store_type;
    this.store = data?.store;
    this.status = data?.status;
    this.created_at = data?.created_at;
    this.currency = undefined;
       if(data.currency){
         this.currency = new RefundDetailsCurrencyModel(data.currency)
       }
  }

  getId = () => this.id;
  getName = () => this.name;
  getCode = () => this.code;
}

export class DisputeItemsModel {
  item: IDisputeItem;
  constructor(payload: IDisputeItem) {
    this.item = payload;
  }
  getItem = () => this.item;
}

export class DisputeBuyProductVariationPropertiesModel {
  _object: DisputeItemCollectionEnum.BuyProductVariationProperty;
  _id: number;
  _property_id: string;
  _property_name: string;
  _property_original_name: string;
  _property_value_id: string;
  _property_value_name: string;
  _property_value_color: string;
  _property_value_title: string;
  _property_value_thumb: string;
  _property_value_image: string;
  constructor(data: IDisputeBuyProductVariationProperty) {
    this._object = data?.object;
    this._id = data?.id;
    this._property_id = data?.property_id;
    this._property_name = data?.property_name;
    this._property_original_name = data?.property_original_name;
    this._property_value_color = data?.property_value_color;
    this._property_value_name = data?.property_value_name;
    this._property_value_id = data?.property_value_id;
    this._property_value_title = data?.property_value_title;
    this._property_value_thumb = data?.property_value_thumb;
    this._property_value_image = data?.property_value_image;
  }

  getId = () => this._id;
  getPropertyId = () => this._property_id;
  getPropertyName = () => this._property_name;
  getPropertyOriginalName = () => this._property_original_name;
  getPropertyValueId = () => this._property_value_id;
  getPropertyValueName = () => this._property_value_name;
  getPropertyValueColor = () => this._property_value_color;
  getPropertyValueTitle = () => this._property_value_title;
  getPropertyValueThumb = () => this._property_value_thumb;
  getPropertyValueImage = () => this._property_value_image;
}

export class DisputeBuyProductVriationModel {
  _object: DisputeItemCollectionEnum.BuyProductVariation;
  _id: number;
  _buy_product_id: number;
  _cart_item_variation_id: number;
  _sku_id: string;
  _image: string;
  _quantity: number;
  _unit_price: number;
  _total_price: number;
  _original_unit_price: number;
  _original_total_price: number;
  _properties: DisputeBuyProductVariationPropertiesModel[];
  constructor(data: IBuyProductVariation) {
    this._object = data?.object;
    this._id = data?.id;
    this._buy_product_id = data?.buy_product_id;
    this._cart_item_variation_id = data?.cart_item_variation_id;
    this._sku_id = data?.sku_id;
    this._image = data?.image;
    this._quantity = data?.quantity;
    this._unit_price = data?.unit_price;
    this._total_price = data?.total_price;
    this._original_unit_price = data.original_unit_price;
    this._original_total_price = data?.original_total_price;
    this._properties = data?.properties?.data?.map(
      (item) => new DisputeBuyProductVariationPropertiesModel(item),
    );
  }

  getId = () => this._id;
  getBuyProductId = () => this._buy_product_id;
  getProperties = () => this._properties;
  getQuantity = () => this._quantity;
  getUnitPrice = () => this._unit_price;
  getTotalPrice = () => this._total_price;
  getOriginalUnitPrice = () => this._original_unit_price;
  getOriginalTotalPrice = () => this._original_total_price;
  getImage = () => this?._image;
}

export class DisputeItemModel {
  id: number;
  dispute_id: number;
  requested_amount: number;
  approved_amount: number;
  approved_percentage: number;
  status: string;
  buy_product_variation: DisputeBuyProductVriationModel;
  constructor(payload: IDisputeItem) {
    this.id = payload?.id;
    this.dispute_id = payload?.dispute_id;
    this.requested_amount = payload?.requested_amount;
    this.approved_amount = payload?.approved_amount;
    this.approved_percentage = payload?.approved_percentage;
    this.status = payload?.status;
    this.buy_product_variation = new DisputeBuyProductVriationModel(
      payload?.buy_product_variation,
    );
  }
  getId = () => this.id;
  getDisputeId = () => this.dispute_id;
  getRequestedAmount = () => this.requested_amount;
  getApprovedAmount = () => this.approved_amount;
  getApprovedPercentage = () => this.approved_percentage;
  getStatus = () => this.status;
  getBuyProductVariation = () => this.buy_product_variation;
}

export interface DisputeItems {
  key: React.Key;
  item: DisputeItemModel;
}

export class DisputeMandatesModel {
  _object: DisputeMandatesEnum.DisputeMandate;
  _id: number;
  _mandate_to: string;
  _status: string;
  _agent_company: null;
  constructor(data: IDisputeMandate) {
    this._object = data?.object;
    this._id = data?.id;
    this._mandate_to = data?.mandate_to;
    this._status = data?.status;
    this._agent_company = data?.agent_company;
  }
  getId = () => this._id;
  getMandateTo = () => this._mandate_to;
  getAgentCompany = () => this._agent_company;
  getStatus = () => this._status;
}

class DisputeUserModel {
  _object: string;
  _id: number;
  _name: string;
  _email: string;

  constructor(data: IDisputeUser) {
    this._object = data?.object;
    this._id = data?.id;
    this._name = data?.name;
    this._email = data?.email;
  }

  getUserName = () => this._name;
  getUserEmail = () => this._email;
}

export class DisputeDetailsModel {
  object: DisputeCollectionEnum.Dispute;
  id: number;
  created_at: string;
  dispute_number: string;
  intent: string;
  status: string;
  region: DisputeRegionModel;
  user: DisputeUserModel;
  buy_product: DisputeBuyProductModel;
  items: DisputeItems[];
  listItmes: IDisputeItem[];
  _mandates: DisputeMandatesModel[];
  constructor(dataItem: IDisputeDetails) {
    this.object = dataItem?.object;
    this.id = dataItem?.id;
    this.dispute_number = dataItem?.dispute_number;
    this.intent = dataItem?.intent;
    this.status = dataItem?.status;
    this.status = dataItem?.status;
    this.region = new DisputeRegionModel(dataItem.region);
    this.user = new DisputeUserModel(dataItem?.user);
    this.created_at = dataItem.created_at;
    this.buy_product = new DisputeBuyProductModel(dataItem?.buy_product);
    this.items = dataItem?.items?.data?.map((data) => {
      return {
        key: data?.id,
        item: new DisputeItemModel(data),
      };
    });

    this.listItmes = dataItem?.items?.data;

    this._mandates = dataItem?.mandates?.data.map(
      (item) => new DisputeMandatesModel(item),
    );
  }

  getId = () => this.id;
  getStatus = () => this.status;
  getCreatedAt = () => this.created_at;
  getRegion = () => this.region;
  getBuyProduct = () => this.buy_product;
  getItems = () => this.items;
  getistItmes = () => this.listItmes;
  getMandates = () => (this?._mandates.length > 0 ? this._mandates : []);
  getUser = () => this.user;
}

export class DisputeDetailsCollectionModel {
  public data: DisputeDetailsModel;

  constructor(data: IDisputeDetailsCollection) {
    this.data = new DisputeDetailsModel(data?.data);
  }

  getData() {
    return this.data;
  }
}
