import { Checkbox } from "antd";
import { StyledProductSideCheckedCell } from "./index.styled";
import { FacetRangeFieldModel } from "models/productDetailCollectionModel";
import { StarFilled } from "@ant-design/icons";

type Props = {
  selected: any;
  data: FacetRangeFieldModel;
  onChange: (data: FacetRangeFieldModel, isChecked: boolean) => void;
};
const RatingCell = ({ selected, data, onChange }: Props) => {
  const isChecked =
    selected?.min === data.getFrom() || selected?.max === data.getTo();
  return (
    <StyledProductSideCheckedCell onClick={() => onChange(data, isChecked)}>
      <Checkbox
        checked={
          selected?.min === data.getFrom() || selected?.max === data.getTo()
        }
      />{" "}
      <StarFilled style={{ fontSize: 14, marginLeft: 5 }} />{" "}
      <p className="mb-0">
        {data.name} ({data.count})
      </p>
    </StyledProductSideCheckedCell>
  );
};

export default RatingCell;
