import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";

function getShippingModeKeyByValue(value: string): string {
  const key =
    Object.keys(ShippingModesEnums).find(
      (key) =>
        ShippingModesEnums[key as keyof typeof ShippingModesEnums] === value,
    ) || "";

  return key;
}

function getShippingTypeKeyByValue(value: string): string {
  const key =
    Object.keys(ShippingTypesEnums).find(
      (key) =>
        ShippingTypesEnums[key as keyof typeof ShippingTypesEnums] === value,
    ) || "";

  return key;
}

export { getShippingModeKeyByValue, getShippingTypeKeyByValue };
