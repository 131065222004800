import { useEffect} from "react";
import {Button, Form, Input,notification} from "antd";

import {
  useDestinationWarehouseRoleCreate,
  useGetRole,
} from "lib/core-react/hooks/private";
import { IDestinationWarehouseRoleForm } from "types/roleCollection";
import {showError} from "../../../../../helpers/showError";

interface IProps {
  destinationWarehouseId: number;
  onHide: () => void;
}
export const AddRole = ({ destinationWarehouseId, onHide }: IProps) => {
  const {
    createDestinationWarehouseRole,
    isLoading: isLoadingSend
  } = useDestinationWarehouseRoleCreate();
  const { getRole } = useGetRole();

  const [form] = Form.useForm<IDestinationWarehouseRoleForm>();

  useEffect(() => {
    form.resetFields();

  }, [destinationWarehouseId]);


  const onFinishInvite = async (value: IDestinationWarehouseRoleForm) => {
    try {
      if (destinationWarehouseId) {
        const payload: IDestinationWarehouseRoleForm = {
          destination_warehouse_id: destinationWarehouseId,
          label: value.label,
          name: value.name,
        };
        await createDestinationWarehouseRole(payload);
        await getRole(`destination_warehouse_id=${destinationWarehouseId}`);
        notification.success({message:"Role successfully created"});
        onHide();
      }
    } catch (error) {
      showError(error, form)
    }
  };

  return (
    <Form
      onFinish={onFinishInvite}
      form={form}
      layout="vertical"
      name="Add Role"
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Label is required" }]}
        label="Label"
        name="label"
      >
        <Input placeholder="Enter label" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoadingSend}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Create Role
        </Button>
      </Form.Item>
    </Form>
  );
};
