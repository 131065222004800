// Enum for admin permissions in the Wallet module
export enum ADMIN_WALLET_PERMISSION_ENUM {
    // Payout Gateway permissions
    ADMIN_PAYOUT_GATEWAY_VIEW = "admin-payout-gateway-view",
    ADMIN_PAYOUT_GATEWAY_ADD = "admin-payout-gateway-add",
    ADMIN_PAYOUT_GATEWAY_UPDATE = "admin-payout-gateway-update",

    // Payout Request permissions
    ADMIN_PAYOUT_REQUEST_VIEW = "admin-payout-request-view",
    ADMIN_PAYOUT_REQUEST_APPROVE = "admin-payout-request-approve",
    ADMIN_PAYOUT_REQUEST_PROCESS = "admin-payout-request-process",
    ADMIN_PAYOUT_REQUEST_REJECT = "admin-payout-request-reject",

    // Wallet permissions
    ADMIN_WALLET_VIEW = "admin-wallet-view",
    ADMIN_TRANSACTION_VIEW = "admin-transaction-view",
    ADMIN_VIEW_AGENT_WALLET = "admin-view-agent-wallet",
    ADMIN_VIEW_AGENT_TRANSACTION = "admin-view-agent-transaction"
}


