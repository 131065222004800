import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import QueryString from "qs";
import {
  IExchangeGroupPostRequest,
  IExchangeGroupUserRequest,
} from "types/exchangeGroupCollection";

class ExchangeGroupResource extends BaseResource {
  path = `/api/store/admin/exchange-group/v1/exchange-groups`;
  exchangeGroupUser = `/api/store/admin/exchange-group/v1/exchange-group-users`;
  userPath = `/api/user/admin/customer-user/v1/customer-users`
  

  get(
    params: Record<string, any>,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}?expand=exchangeList&${QueryString.stringify(params)}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: IExchangeGroupPostRequest,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: IExchangeGroupPostRequest,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  delete(id: number, customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("DELETE", path, undefined, {}, customHeaders);
  }

  // Exchange group user
  getUsers(params: Record<string, any>,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.userPath}/?${QueryString.stringify(params)}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  addUser(
    payload: IExchangeGroupUserRequest,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.exchangeGroupUser}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
  updateUser(
    payload: IExchangeGroupUserRequest,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.exchangeGroupUser}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
  deleteUser(
    id: number,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.exchangeGroupUser}/${id}`;
    return this.client.request("DELETE", path, undefined, {}, customHeaders);
  }
}

export default ExchangeGroupResource;
