import qs, { ParsedQs } from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import MultiCheckedCell from "../MultiCheckedCell";

interface IProps {
  categoriesField: FacetFieldModel[];
}

const CategoriesFacet = ({ categoriesField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { categories, ...rest } = parsedQuery;
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectCategories = async (name: string & ParsedQs) => {
    let updatedCategories;

    if (Array.isArray(categories)) {
      if (categories.includes(name)) {
        // Remove the category if it already exists
        // @ts-ignore FIXME
        // updatedCategories = categories.filter((category) => category !== name);
      } else {
        // Add the category if it doesn't exist
        updatedCategories = [...categories, name];
      }
    } else {
      // If category array does not exist, create it with the current categoryName
      updatedCategories = [name];
    }

    const queryString = qs.stringify({
      ...rest,
      categories: updatedCategories,
    });
    await getProductDetailsCollection(
      {
        region: "BD",
        locale: "en",
      },
      { categories: updatedCategories, ...rest }
    );
    navigate(`/product-manage/products?${queryString}`);
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Categories</StyledProductSidebarItemTitle>
      <AppList
        data={categoriesField}
        renderItem={(data) => (
          <MultiCheckedCell
            key={data.getName()}
            data={data}
            onChange={onSelectCategories}
            selected={Array.isArray(categories) ? categories : []}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default CategoriesFacet;
