export enum filterResourceEnum {
  LABEL = "label",
  COUNTRY = "country",
  USER = "user",
  AGENT_WAREHOUSE = "agentWarehouse",
  BUY_PRODUCTS = "buyProducts",
  DESTINATION_WAREHOUSE = "destinationWarehouse",
  COURIER_OPTIONS = "courierOptions",
  ADDRESS = "address",
  DELIVERY_REQUESTS = "deliveryRequests",
  CUSTOMER = "customer",
  REGION = "region",
  DISCOUNT_GROUPS = "discountGroups",
  DISCOUNT = "discount",
  GATEWAY = "gateway",
  GATEWAY_TRANSACTION = "gatewayTransaction",
  CURRENCY = "currency",
  ADMIN = "admin",
  SHIPPING_COUNTRY = "shippingCountry",
  AGENT_COMPANY = "agentCompany",
  STORE = "store",
  BASE_SHIPPING_CATEGORY = "baseShippingCategory",
  WAREHOUSE = "warehouse",
  SHIPPING_CATEGORY = "shippingCategory",
  WAREHOUSE_SHIPMENT = "warehouseShipment",
  SHIPMENT_PRODUCT = "shipmentProduct",
  AGENT_ROLE = "agentRole",
  EXCHANGE_LIST = "exchangeList",
  EXCHANGE_GROUP = "exchangeGroup",
  PAYOUT_GATEWAY = "payoutGateway",
  PAYOUT_ACCOUNT = "payoutAccount",
}
