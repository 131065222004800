import React, { ReactNode } from "react";
import { Form, message, notification } from "antd";
import {
    useAddCampaignProducts,
    useAddRawCampaignProduct,
} from "lib/core-react/hooks/private/useCampaign";
import { useFormatPayload } from "./useFormatPayload";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useAtom, useSetAtom } from "jotai";
import useHandleVariationsUpdate from "./useHandleUpdatePrice";
import { FormNameEnum, IAddRawProductToCampaignForm } from "./types";
import { SkuModelExtended } from "models/productDetailExtendedModel";
import { IAddCampaignProductsPostRequest } from "types/campaignCollection";
import { getError } from "lib/core-react/hooks/utils/errors";

export const isShowAddToCampaignModalAtom = atom<boolean>(false);
export const selectedSkusAtom = atom<SkuModelExtended[]>([]);
export const isOpenUpdatePriceModalAtom = atom<{
    formName:
        | "updateRawProductOfferPriceForm"
        | "updateRawProductOriginalPriceForm"
        | "updateRawProductStockForm"
        | undefined;
    isOpen: boolean;
}>({
    formName: undefined,
    isOpen: false,
});

const AddRawCampaignProductFormProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const region = new URLSearchParams(location.search).get("region") || "BD";
    const campaignId = new URLSearchParams(location.search).get("campaignId");
    const [form] = Form.useForm();

    const setIsShowAddToCampaignModal = useSetAtom(
        isShowAddToCampaignModalAtom,
    );
    const { handleOfferPrice, handleOriginalPrice, handleStock } =
        useHandleVariationsUpdate();

    const { addRawCampaignProduct } = useAddRawCampaignProduct();
    const { addCampaignProducts } = useAddCampaignProducts();

    const { formatPayload } = useFormatPayload();

    const [isOpenModal, setIsOpenModal] = useAtom(isOpenUpdatePriceModalAtom);

    const onFinish = async (values: IAddRawProductToCampaignForm) => {
        try {
            const payload = await formatPayload(values);
            // console.log("payload :>> ", payload);

            if (payload) {
                const response = await addRawCampaignProduct(
                    campaignId as string,
                    "en",
                    region,
                    payload,
                );
                if (response) {
                    notification["success"]({
                        message: "Successfully Added To Campaign",
                    });

                    form.resetFields();
                    setIsShowAddToCampaignModal(false);
                    window.location.reload();
                }
            }
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    const handleAddProductToCampaign = async (
        value: IAddCampaignProductsPostRequest,
    ) => {
        try {
            const payload = {
                product_ids: value.product_ids,
                configuration: {
                    price_hike: value.configuration.price_hike,
                    hike_type: value.configuration.hike_type,
                    discount: value.configuration.discount,
                    discount_type: value.configuration.discount_type,
                },
            };
            await addCampaignProducts(
                campaignId as string,
                "en",
                region,
                payload as IAddCampaignProductsPostRequest,
            );

            message.success("Product added successfully");
            setIsShowAddToCampaignModal(false);
            navigate("/campaigns");
        } catch (error) {
            message.error(
                getError(error).includes("Product already added")
                    ? "Product already exist at campaign"
                    : "Something Went Wrong",
            );
        }
    };

    const onFormFinish = async (name: string, { values, forms }) => {
        try {
            const { addRawProductToCampaignForm } = forms;

            await handleAddProductToCampaign({
                product_ids: [values.productId],
                configuration: values.configuration,
            });

            switch (
                name + "add raw product to campaign hold it will use later"
            ) {
                case FormNameEnum.UPDATE_RAW_PRODUCT_OFFER_PRICE_FORM:
                    handleOfferPrice(
                        addRawProductToCampaignForm,
                        values.updatedPrice.amount,
                        values.updatedPrice.action,
                    );
                    setIsOpenModal({
                        formName: undefined,
                        isOpen: false,
                    });
                    return;
                case FormNameEnum.UPDATE_RAW_PRODUCT_ORIGINAL_PRICE_FORM:
                    handleOriginalPrice(
                        addRawProductToCampaignForm,
                        values.updatedPrice.amount,
                        values.updatedPrice.action,
                    );
                    setIsOpenModal({
                        formName: undefined,
                        isOpen: false,
                    });
                    return;
                case FormNameEnum.UPDATE_RAW_PRODUCT_STOCK_FORM:
                    handleStock(addRawProductToCampaignForm, values.stock);
                    setIsOpenModal({
                        formName: undefined,
                        isOpen: false,
                    });
                    return;
                case FormNameEnum.ADD_RAW_PRODUCT_TO_CAMPAIGN_FORM:
                    return await onFinish(values);

                default:
                    // console.log("Unknown form");
                    return;
            }
        } catch (error) {
            notification["error"]({
                message: "Something Went wrong",
            });
        }
    };

    return (
        <Form.Provider onFormFinish={onFormFinish}>
            <Form
                form={form}
                layout="vertical"
                name="addRawProductToCampaignForm"
                id="addRawProductToCampaignForm"
                initialValues={{
                    selectedVariations: [],
                }}
            >
                {children}
            </Form>

            {isOpenModal.isOpen && (
                <Form
                    form={form}
                    layout="vertical"
                    name={isOpenModal.formName}
                    id={isOpenModal.formName}
                >
                    {/* inject form input from modal */}
                </Form>
            )}
        </Form.Provider>
    );
};

export default AddRawCampaignProductFormProvider;
