import qs from "qs";
export class ApiHelperModel {
    static makeGetRequest = async (
        filters: Record<string, any>,
        getRequest: (data: string) => Promise<void>,
    ): Promise<void> => {
        await getRequest(qs.stringify({ ...filters }));
    };
}
export class GetApiHelperModel {
    static makeGetRequest = async (
        params: Record<string, any>,
        getRequest: (data: Record<string, any>) => Promise<void>,
    ): Promise<void> => {
        await getRequest(params);
    };
}
