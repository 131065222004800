import styled from "styled-components";

export const StyledEmptyListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  min-height: 450px;
  height: 100%;
  padding: 20px;
  //border: @border-style-base @border-width-base darken(@border-color-base, 5);
  font-size: 16px;
  color: rgb(174, 175, 184);

  & p {
    font-size: 14px;
    color: rgb(17, 24, 39);
  }

  & h4 {
    font-size: 18px;
    color: rgb(107, 114, 128);
    margin-bottom: 12px;
  }
`;

export const StyledEmptyListContainerFlex = styled(StyledEmptyListContainer)`
  flex-direction: row;
`;

export const StyledListFooter = styled.div`
  padding: 10px;
  color: rgb(107, 114, 128);
  display: flex;
  justify-content: center;
`;

export const StyledLoaderProgress = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: rgb(174, 175, 184);
  padding: 8px;

  border-top: 1px solid #757575;
  box-sizing: border-box;

  & span {
    margin-left: 8px;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 8px;
    }
  }
`;
