import {
  Avatar,
  Button,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { IFilterType } from "types/filters";
import { IShippingCartItemPackage } from "types/shippingCartCollection";
import {
  ShippingCartItemModel,
  ShippingCartItemPackageModel,
} from "models/shippingCartCollectionModel";
import { Dispatch } from "react";
import { SetStateAction } from "jotai";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";

interface IProps {
  data: ShippingCartItemModel[];
  setSelectedPackage: Dispatch<
    SetStateAction<ShippingCartItemPackageModel[] | undefined>
  >;
}
const CartItemsTable = ({ data, setSelectedPackage }: IProps) => {
  const columns: TableColumnsType<ShippingCartItemModel> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string, record: ShippingCartItemModel) => {
        return (
          <div style={{ display: "flex", gap: "16px" }}>
            <Avatar
              shape="square"
              size={100}
              style={{ flex: "none" }}
              src={record.images[0]}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                width: 200,
              }}
            >
              <Tooltip placement="topLeft" title={record.title}>
                <Typography.Text
                  style={{
                    width: 200,
                  }}
                  ellipsis
                >
                  <a href={record.link}>{title}</a>
                </Typography.Text>
              </Tooltip>
              <div>
                <strong>Shipping Type:</strong>{" "}
                <Tag
                  style={{
                    textTransform: "capitalize",
                    background: "#ff9100",
                    color: "white",
                    borderRadius: 10,
                  }}
                >
                  {record.shipping_type}
                </Tag>
              </div>
              <div>
                <strong>Shipping Mode:</strong>{" "}
                <Tag
                  style={{
                    textTransform: "capitalize",
                    background: "#ff9100",
                    color: "white",
                    borderRadius: 10,
                  }}
                >
                  {record.shipping_mode}
                </Tag>
              </div>
              <div>
                <strong>Shipment Instruction:</strong>{" "}
                <span>{record.shipment_instruction || "None"}</span>
              </div>
              <div>
                <strong>Source:</strong>{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {record.product_source}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Shipping Address",
      dataIndex: "shipping_address",
      key: "shipping_address",
      render: (address: IFilterType, record: ShippingCartItemModel) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: 300,
            }}
          >
            {Object.keys(record.getShippingAddress()).map((key) => (
              <div key={key}>
                <strong style={{ textTransform: "capitalize" }}>{key}:</strong>{" "}
                {address[key]}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Packages",
      dataIndex: ["packages", "data"],
      key: "packages",
      render: (packages: IShippingCartItemPackage[]) => {
        return (
          checkActionPermission(

            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
            <Button onClick={() => setSelectedPackage(packages)}>
              View Package Details
            </Button>,
            null
          )

        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 10 }}
      rowKey="id"
      scroll={{ x: 600 }}
    />
  );
};

export default CartItemsTable;
