import React from 'react';
import { Card, Col, Row, Descriptions, Tag, Typography } from 'antd';
import { InvoiceModel } from "../../../../models/invoiceCollectionModel";
import { StatusTag } from "../../../../components";
const { Text } = Typography;

interface DetailsCardProps {
  invoice: InvoiceModel;
  detailsFor: "customer" | "agent"
}

const BasicDetailsCard: React.FC<DetailsCardProps> = ({ invoice, detailsFor }) => {
  const region = invoice.getRegion();
  const order = invoice.getOrder();
  const transactionSummary = invoice.getTransactionSummery();
  const titleStyle = { fontWeight: 'bold', fontSize: '1em' };

  return (
    <Card style={{ margin: "10px" }} title="Basic Details" bordered={false}>
      <Row gutter={[16, 16]}>
        {detailsFor === "customer" && (
          <Col xs={24} sm={24} md={12} lg={8}>
            <Descriptions title={<span style={titleStyle}>Customer</span>} bordered column={1}>
              <Descriptions.Item label={<b>Name</b>}>{invoice?.getCustomer()?.getName()}</Descriptions.Item>
              <Descriptions.Item label={<b>Email</b>}>{invoice?.getCustomer()?.getEmail()}</Descriptions.Item>
              <Descriptions.Item label={<b>Shipping Mark</b>}>{invoice?.getCustomer()?.getShippingMark()}</Descriptions.Item>
              <Descriptions.Item label={<b>Created At</b>}>
                <Tag color="purple">{invoice?.getCustomer()?.getCreateData()}</Tag>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}

        <Col xs={24} sm={24} md={12} lg={8}>
          <Descriptions title={<span style={titleStyle}>Region</span>} bordered column={1}>
            <Descriptions.Item label={<b>Name</b>}>{region.getName()}</Descriptions.Item>
            <Descriptions.Item label={<b>Store Type</b>}>{region.getStoreType()}</Descriptions.Item>
            <Descriptions.Item label={<b>Store</b>}>{region.getStore()}</Descriptions.Item>
            {region.getCurrency()?.name && (
              <Descriptions.Item label={<b>Currency</b>}>{region.getCurrency()?.name} ({region.getCurrency().symbol})</Descriptions.Item>
            )}
          </Descriptions>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8}>
          <Descriptions title={<span style={titleStyle}>Order</span>} bordered column={1}>
            <Descriptions.Item label={<b>Order Number</b>}>
              <Text copyable={true}>{order.getOrderNumber()}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={<b>Order Type</b>}>
              <StatusTag slug={order.getType()} text={order.getType()} />
            </Descriptions.Item>
            <Descriptions.Item label={<b>Order Status</b>}>
              <StatusTag slug={order.getStatus()} text={order.getStatus()} />
            </Descriptions.Item>
            <Descriptions.Item label={<b>Payment Status</b>}>
              <StatusTag slug={order.payment_status} text={order.payment_status} />
            </Descriptions.Item>
            <Descriptions.Item label={<b>Created At</b>}>
              <Tag color="purple">{order.getCreatedAt()}</Tag>
            </Descriptions.Item>
          </Descriptions>
        </Col>

        {detailsFor === "agent" && <InvoiceBasicData detailsFor={detailsFor} invoice={invoice} />}
      </Row>

      <Row gutter={[16, 16]}>
        {detailsFor === "customer" && <InvoiceBasicData detailsFor={detailsFor} invoice={invoice} />}

        <Col xs={24} sm={24} md={12} lg={8}>
          <Descriptions title={<span style={titleStyle}>Transaction Summary</span>} bordered column={1}>
            <Descriptions.Item label={<b>Paid</b>}>{transactionSummary.paid || 0}</Descriptions.Item>
            <Descriptions.Item label={<b>Due</b>}>{transactionSummary.due}</Descriptions.Item>
            <Descriptions.Item label={<b>Overpaid</b>}>{transactionSummary.overpaid || 0}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};
export default BasicDetailsCard;

const InvoiceBasicData: React.FC<DetailsCardProps> = ({ invoice }) => {
  const invoiceNumber = invoice.getInvoiceNumber();
  const status = invoice.getStatus();
  const totalAmount = invoice.getTotalAmount();
  const initialAmount = invoice.getInitialAmount();
  const refundableAmount = invoice.getRefundableAmount();
  const titleStyle = { fontWeight: 'bold', fontSize: '1em' };

  return (
    <Col xs={24} sm={24} md={12} lg={8}>
      <Descriptions title={<span style={titleStyle}>Invoice</span>} bordered column={1}>
        <Descriptions.Item label={<b>Invoice Number</b>}><Text copyable>{invoiceNumber}</Text></Descriptions.Item>
        <Descriptions.Item label={<b>Status</b>}>
          <StatusTag slug={status} text={status} />
        </Descriptions.Item>
        <Descriptions.Item label={<b>Total Amount</b>}>{totalAmount}</Descriptions.Item>
        <Descriptions.Item label={<b>Initial Amount</b>}>{initialAmount}</Descriptions.Item>
        <Descriptions.Item label={<b>Refundable Amount</b>}>{refundableAmount}</Descriptions.Item>
      </Descriptions>
    </Col>
  );
};
