import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Button, Dropdown, Modal, notification } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import {
    AppstoreOutlined,
    CheckSquareOutlined,
    DownOutlined,
    HddFilled,
    PlusOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import clsx from "clsx";

import {
    StyledProductHeader,
    StyledProductHeaderBtn,
    StyledProductHeaderLeft,
    StyledProductHeaderRight,
    StyledProductHeaderSearch,
    StyledProductHeaderSearchContainer,
    StyledProductHeaderShowingText,
} from "./index.styled";
import { VIEW_TYPE } from "../index";
import {
    campaignProductCollectionAtom,
    productSearchResultAtom,
} from "lib/core-react/store/store";
import { ProductSearchResultModel } from "models/productDetailCollectionModel";
import { CampaignProductCollectionModel } from "models/campaignProductCollectionModel";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";

import { AddProductToCampaign } from "./components";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { useDeleteCampaignProducts } from "lib/core-react/hooks/private/useCampaign";
import { IDeleteCampaignProducts } from "types/campaignCollection";
import { ExtendedMenuItemType } from "types";
import { ROLE_PERMISSION_TEST_ENUM } from "consts/parmissionEnum";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { useDebounce } from "hooks/useDebounce";
import CommonError from "components/Error/CommonError";

type Props = {
    onSearch: (data: string) => void;
    viewType: string;
    setViewType: (data: string) => void;
    defaultSearchValue: string | undefined;
    selectedProducts: string[];
    handleSelectAllProducts: () => void;
    isSelectAll: boolean;
};
const ProductHeader = ({
    onSearch,
    viewType,
    setViewType,
    defaultSearchValue,
    selectedProducts,
    handleSelectAllProducts,
    isSelectAll,
}: Props) => {
    const location = useLocation();
    const operation = new URLSearchParams(location.search).get("operation");
    const region = new URLSearchParams(location.search).get("region");
    const campaignId = new URLSearchParams(location.search).get("campaignId");
    const id = new URLSearchParams(location.search).get("id");
    const { deleteCampaignProducts } = useDeleteCampaignProducts();
    const [isShowAddToCampaignModal, setIsShowAddToCampaignModal] =
        useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const navigate = useNavigate();

    const debouncedSearchTerm = useDebounce(searchTerm, 700);
    // Product list
    const [{ data,error,unAuthorized }] = useAtom(productSearchResultAtom);
    const productList = data && new ProductSearchResultModel(data);

    // Product list (campaign)
    const [{ data: campaignData,error : errorCampaignData, unAuthorized : unAuthorizedCampaignProduct }] = useAtom(campaignProductCollectionAtom);
    const campaignProductList =
        campaignData && new CampaignProductCollectionModel(campaignData);

    const handleMenuClick = (e: MenuInfo) => {
        if (e.key === CampaignOperationEnum.AddCampaignProduct) {
            setIsShowAddToCampaignModal(true);
        }

        if (e.key === CampaignOperationEnum.goToCampaignProductPage) {
            navigate(
                `/product-manage/products?operation=${CampaignOperationEnum.AddCampaignProduct}&region=${region}&campaignId=${campaignId}`,
            );
        }
    };

    const onConfirmDelete = async () => {
        const payload: IDeleteCampaignProducts = {
            ids: selectedProducts,
        };

        try {
            await deleteCampaignProducts(id as string, payload);
            notification["success"]({
                message: "Deleted Campaigns Products successfully",
            });
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    const handleOnChangeSearch = (value: string) => {
        setSearchTerm(() => value);
    };

    const handleSearchByIdOrUrl = (value: string) => {
        const isValidUrl =
            value.startsWith("http://") || value.startsWith("https://");

        if (isValidUrl) {
            if (location.search) {
                
                navigate(
                    `/product-manage/products/product_detail/find${location.search}&productUrl=${value}`,
                );
            }else{
                navigate(
                    `/product-manage/products/product_detail/find?productUrl=${value}`,
                );
            }
        } else {
            navigate(
                `/product-manage/products/product_detail/find${location.search}&productId=${value}`,
            );
        }
    };

    useEffect(() => {
        onSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    const items: ExtendedMenuItemType[] = [
        {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <PlusOutlined />,
            label: "Add to campaign",
            key: CampaignOperationEnum.AddCampaignProduct,
            disabled:
                operation !== CampaignOperationEnum.AddCampaignProduct ||
                selectedProducts.length <= 0 ||
                !campaignId,
        },
        {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <PlusOutlined />,
            label: "Go to campaign product",
            key: CampaignOperationEnum.goToCampaignProductPage,
            disabled: operation !== CampaignOperationEnum.ViewCampaignProduct,
        },
        {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <PlusOutlined />,
            label: "Delete campaign Product",
            key: CampaignOperationEnum.ViewCampaignProduct,
            onClick: () => {
                onConfirmDelete();
            },
            disabled: operation !== CampaignOperationEnum.ViewCampaignProduct,
        },
    ];

    const menuProps = {
        items: items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        ),
        onClick: handleMenuClick,
    };

    const paginationFrom =
        operation === "viewProduct"
            ? campaignProductList?.getPagination().getFrom()
            : productList?.getPagination().getFrom();
    const paginationTo =
        operation === "viewProduct"
            ? campaignProductList?.getPagination().getTo()
            : productList?.getPagination().getTo();
    const paginationTotal =
        operation === "viewProduct"
            ? campaignProductList?.getPagination().getTotal()
            : productList?.getPagination().getTotal();

    // ------------------------------ui------------------------
    if (
        unAuthorized ||
        error ||
        errorCampaignData ||
        unAuthorizedCampaignProduct
    ) {
        return (
            <CommonError
                unAuthorized={unAuthorized || unAuthorizedCampaignProduct}
                message={error || errorCampaignData}
            />
        );
    }

    return (
        <>
            <StyledProductHeader>
                <StyledProductHeaderLeft>
                    <h3>
                        {operation === CampaignOperationEnum.AddCampaignProduct
                            ? "Select Products"
                            : operation ===
                                CampaignOperationEnum.ViewCampaignProduct
                              ? "Campaign Product List"
                              : operation ===
                                  BuyOrderOperationEnum.AddBuyOrderProduct
                                ? "Select a Product"
                                : operation ===
                                    BuyOrderOperationEnum.PlaceBuyOrder
                                  ? "Select a Product"
                                  : "Product List"}
                    </h3>
                    {!operation && (
                        <StyledProductHeaderShowingText className="text-truncate">
                            (Showing {paginationFrom || 0} - {paginationTo || 0}{" "}
                            products of {paginationTotal || 0} products)
                        </StyledProductHeaderShowingText>
                    )}
                </StyledProductHeaderLeft>

                <StyledProductHeaderRight>
                    {operation !== CampaignOperationEnum.ViewCampaignProduct ? (
                        <StyledProductHeaderSearchContainer>
                            <div>
                                <StyledProductHeaderSearch
                                    placeholder="search by id or url"
                                    defaultValue={defaultSearchValue}
                                    onSearch={handleSearchByIdOrUrl}
                                />
                            </div>

                            <div>
                                <StyledProductHeaderSearch
                                    placeholder="Search by keyword"
                                    defaultValue={defaultSearchValue}
                                    onSearch={onSearch}
                                    onChange={(e) => {
                                        handleOnChangeSearch(e.target.value);
                                    }}
                                />
                            </div>
                        </StyledProductHeaderSearchContainer>
                    ) : (
                        ""
                    )}
                    <div>
                        <div
                            style={{
                                display: "flex",
                                gap: 12,
                                width: "100%",
                            }}
                        >
                            {(operation ===
                                CampaignOperationEnum.AddCampaignProduct ||
                                operation ===
                                    CampaignOperationEnum.ViewCampaignProduct) && (
                                <Button
                                    style={{
                                        height: "36px",
                                        backgroundColor: isSelectAll
                                            ? "green"
                                            : "",
                                        color: isSelectAll ? "white" : "",
                                    }}
                                    icon={<CheckSquareOutlined />}
                                    onClick={handleSelectAllProducts}
                                >
                                    Select All
                                </Button>
                            )}
                            {(operation ===
                                CampaignOperationEnum.AddCampaignProduct ||
                                operation ===
                                    CampaignOperationEnum.ViewCampaignProduct) && (
                                <div>
                                    <Dropdown menu={menuProps}>
                                        <Button
                                            style={{ height: "36px" }}
                                            icon={<HddFilled />}
                                        >
                                            Actions <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </div>
                            )}

                            <StyledProductHeaderBtn
                                className={clsx({
                                    active: viewType === VIEW_TYPE.LIST,
                                })}
                                onClick={() => setViewType(VIEW_TYPE.LIST)}
                            >
                                <UnorderedListOutlined />
                            </StyledProductHeaderBtn>
                            <StyledProductHeaderBtn
                                className={clsx({
                                    active: viewType === VIEW_TYPE.GRID,
                                })}
                                onClick={() => setViewType(VIEW_TYPE.GRID)}
                            >
                                <AppstoreOutlined />
                            </StyledProductHeaderBtn>
                        </div>
                    </div>
                </StyledProductHeaderRight>
            </StyledProductHeader>

            {campaignId && region && (
                <Modal
                    title="Add Configuration"
                    open={isShowAddToCampaignModal}
                    onCancel={() => setIsShowAddToCampaignModal(false)}
                    footer={null}
                >
                    <AddProductToCampaign
                        setIsShowAddToCampaignModal={
                            setIsShowAddToCampaignModal
                        }
                        region={region}
                        campaignId={campaignId}
                        selectedProducts={selectedProducts}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductHeader;
