import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { IFilterType } from "types/filters";

const useDataFilters = () => {
  const [filters, setFilters] = useState<IFilterType | null>(null);
  const [refetch, setRefetch] = useState(false)
  const [isFirstCall, setIsFirstCall] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const navigate = useNavigate();

  // This function take all search parameter and set in state as object and isFirstCall will become true.
  const initializeFilter = () => {
    const params = qs.parse(window.location.search.substring(1));
    setFilters(params);
    setIsFirstCall(true);
    setRefetch(false)
  };
  // first time isFirstCall is false means it call initializeFilter();
  useEffect(() => {
    if (!isFirstCall) {
      initializeFilter();
    }
  }, [isFirstCall]);

  const initializeAvailableFilter = (data: IFilterType) => {
    const params = qs.parse(window.location.search.substring(1));
    const initialFilter: IFilterType = {};
    // For Page value. if pageValue is there then keep that value under page property in initialFilter object.
    const pageValue = params["page"];
    if (pageValue) {
      initialFilter.page = pageValue;
    }
    // For Per page value. if perPageValue is there then keep that value under per_page property in initialFilter object.
    const perPageValue = params["per_page"];
    if (perPageValue) {
      initialFilter.per_page = perPageValue;
    }
    // take all keys of data object. and keep value in initialFilter object according to key.
    Object.keys(data).forEach((key) => {
      initialFilter[key] = params[key] ? params[key] : undefined;
    });
    // at last set initialFilter in filter state.
    setFilters(initialFilter);
    setIsFetched(true);
    setRefetch(false)
  };

  /**
   * Push parameters to url
   * @param {object} items Params items to be pushed to url
   */
  const updateQueryParams = (items: IFilterType) => {
    const itemsFields = items;
    // if (Object.hasOwnProperty("date")) {
    //   const [startDate, endDate] = itemsFields.date;
    //   itemsFields["date[0]"] = startDate;
    //   itemsFields["date[1]"] = endDate;
    //   delete itemsFields.date;
    // }

    // Make string data with current data and query
    const params = qs.stringify(
      { ...filters, ...itemsFields },
      { encodeValuesOnly: true }
    );
    navigate({
      search: `?${params}`,
    });
  };

  /**
   * Update filter state.
   *
   * @param {object} field field name and values object for filter
   */
  const handleFilterChange = (fields: IFilterType) => {
    setFilters({
      ...filters,
      ...fields,
    });
    updateQueryParams(fields);
    setRefetch(false)
  };

  /**
   * Clear filter state and filter params.
   */
  const handelFilterClear = () => {
    navigate({
      search: "",
    });
    const newFilter: IFilterType = {};
    Object.keys(filters as IFilterType).forEach((el) => {
      newFilter[el] = undefined;
    });
    setFilters(newFilter);
    setRefetch(true)

  };

  return {
    filters,
    isFirstCall,
    handleFilterChange,
    handelFilterClear,
    isFetched,
    setIsFetched,
    initializeFilter,
    initializeAvailableFilter,
    refetch,
  };
};

export default useDataFilters;
