import { IPagination } from "types/pagination";
import {
    StoreAvailabilityStatusEnums,
    StoreCollectionEnums,
} from "enums/storeCollectionEnums";
import { ICurrency, IStore, IStoreCollection } from "types/storeCollection";
import { IFilterType } from "types/filters";
import { formatDateTime } from "../utils/helpers";

export class CurrencyModel {
    object: string;
    id: number;
    code: string;
    symbol: string;
    symbol_native: string;
    name: string;
    status: "active" | "inactive";

    constructor(dataItem: ICurrency) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.code = dataItem.code;
        this.symbol = dataItem.symbol;
        this.symbol_native = dataItem.symbol_native;
        this.name = dataItem.name;
        this.status = dataItem.status;
    }

    getId = () => this.id;
    getCode = () => this.code;
    getSymbol = () => this.symbol;
    getSymbolNative = () => this.symbol_native;
    getName = () => this.name;
    getStatus = () => this.status;
}

export class StoreModel {
    object: StoreCollectionEnums.Store;
    id: number;
    name: string;
    identifier: string;
    default_currency?: CurrencyModel;
    availability_status: StoreAvailabilityStatusEnums;
    created_at: string;

    constructor(dataItem: IStore) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.identifier = dataItem.identifier;
        this.availability_status = dataItem.availability_status;
        this.default_currency = dataItem.default_currency
            ? new CurrencyModel(dataItem.default_currency)
            : undefined;
        this.created_at = dataItem.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getIdentifier = () => this.identifier;
    getAvailabilityStatus = () => this.availability_status;
    getCreatedAt = () => formatDateTime(this.created_at);
    getDefaultCurrency = () => this.default_currency;
}

export class StoreCollectionModel {
    object: StoreCollectionEnums.StoreCollection;
    data: StoreModel[];
    pagination: IPagination;
    filters: IFilterType;

    constructor(data: IStoreCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new StoreModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getData = () => this.data;
    getFilters = () => this.filters;
    getPagination = () => this.pagination;
    getStoreById(id: number): StoreModel | undefined {
        return this.getData().find((item) => item.getId() === id);
    }

    getStoreLabeledValue() {
        return this.data.map((item) => ({
            label: item.getName(),
            value: item.getId(),
        }));
    }

    getStoreIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.getData().forEach((item) => (obj[item.getId()] = item.getName()));
        return obj;
    }
    getSelectComponentSelectOptions() {
        return this.getData().map((store) => {
            return {
                label: store.getName(),
                value: store.getId(),
            };
        });
    }
}
