import { FormInstance, notification } from "antd";
import { PriceAdjustmentsActionEnum } from "./types";
import { useAtom } from "jotai";
import { productDetailAtom } from "lib/core-react/store/store";
import { UpdatedPriceCalculate } from "./updatedPriceCalculate";

const useHandleVariationsUpdate = () => {
    const [{ data: productDetail }] = useAtom(productDetailAtom);

    const handleOfferPrice = (
        addRawProductToCampaignForm: FormInstance,
        amount: number,
        action: PriceAdjustmentsActionEnum,
    ) => {
        try {
            const skus = productDetail
                ? productDetail?.variation?.skus?.data
                : [];
            const currentPrice = addRawProductToCampaignForm.getFieldValue(
                "variation_offer_price",
            );
            const skusKeyValuePair = skus.map((sku) => ({
                id: sku.id,
                value: currentPrice[sku.id],
            }));

            let updated_variation_offer_price;

            switch (action) {
                case PriceAdjustmentsActionEnum.SET_TO_FIXED:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.setToFixed(amount);
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_FIXED:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByFixed(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.DECREMENT_BY_FIXED:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.decrementByFixed(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_PERCENT:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByPercent(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );

                    // notification["error"]({
                    //     message:
                    //         "Current amount should be greater than 0 if you want to increase by percentage",
                    // });

                    break;
                case PriceAdjustmentsActionEnum.DECREMENT_BY_PERCENT:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.decrementByPercent(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );
                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_MULTIPLY:
                    updated_variation_offer_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByMultiply(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );
                    break;
                default:
                    // Handle any default case
                    console.log("Action not recognized");
                    break;
            }

            addRawProductToCampaignForm.setFieldsValue({
                variation_offer_price: {
                    ...updated_variation_offer_price,
                },
            });
        } catch (error) {
            notification["error"]({
                message: "Something Went wrong",
            });
        }
    };

    const handleOriginalPrice = (
        addRawProductToCampaignForm: FormInstance,
        amount: number,
        action: PriceAdjustmentsActionEnum,
    ) => {
        try {
            const skus = productDetail
                ? productDetail?.variation?.skus?.data
                : [];
            const currentPrice = addRawProductToCampaignForm.getFieldValue(
                "variation_original_price",
            );

            const skusKeyValuePair = skus.map((sku) => ({
                id: sku.id,
                value: currentPrice[sku.id],
            }));

            let updated_variation_original_price;

            switch (action) {
                case PriceAdjustmentsActionEnum.SET_TO_FIXED:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.setToFixed(amount);
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_FIXED:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByFixed(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.DECREMENT_BY_FIXED:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.decrementByFixed(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );

                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_PERCENT:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByPercent(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );
                    break;
                case PriceAdjustmentsActionEnum.DECREMENT_BY_PERCENT:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.decrementByPercent(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );
                    break;
                case PriceAdjustmentsActionEnum.INCREMENT_BY_MULTIPLY:
                    updated_variation_original_price = skusKeyValuePair.reduce(
                        (acc, item) => {
                            acc[item.id] =
                                UpdatedPriceCalculate.incrementByMultiply(
                                    item.value,
                                    amount,
                                );
                            return acc;
                        },
                        {},
                    );
                    break;
                default:
                    // Handle any default case
                    console.log("Action not recognized");
                    return;
            }

            addRawProductToCampaignForm.setFieldsValue({
                variation_original_price: {
                    ...updated_variation_original_price,
                },
            });
        } catch (error) {
            notification["error"]({
                message: "Something Went wrong",
            });
        }
    };

    const handleStock = (
        addRawProductToCampaignForm: FormInstance,
        amount: number,
    ) => {
        try {
            const skus = productDetail
                ? productDetail?.variation?.skus?.data
                : [];
            const currentStock =
                addRawProductToCampaignForm.getFieldValue("variation_stock");

            const skusKeyValuePair = skus.map((sku) => ({
                id: sku.id,
                value: currentStock[sku.id],
            }));

            const updated_variation_stock = skusKeyValuePair.reduce(
                (acc, item) => {
                    acc[item.id] = UpdatedPriceCalculate.setToFixed(amount);
                    return acc;
                },
                {},
            );

            addRawProductToCampaignForm.setFieldsValue({
                variation_stock: {
                    ...updated_variation_stock,
                },
            });

        
        } catch (error) {
            notification["error"]({
                message: "Something Went wrong",
            });
        }
    };

    return {
        handleOfferPrice,
        handleOriginalPrice,
        handleStock,
    };
};

export default useHandleVariationsUpdate;
