const TextLine = ({
  label,
  value,
  style = { padding: 0, margin: 0 },
}: {
  label: string;
  value: string | number | undefined;
  style?: Record<string, any>;
}) => {
  return (
    <p style={style}>
      <span style={{ fontWeight: "bold" }}>{label}</span>
      <span>{value}</span>
    </p>
  );
};

export default TextLine;
