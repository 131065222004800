import { Button, Form, Select, message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, createRef } from "react";

import { exchangeGroupCollectionAtom } from "lib/core-react/store/store";
import {
  useAddExchangeGroupCustomer,
  useGetExchangeGroup,
} from "lib/core-react/hooks/private/useExchangeGroup";

import { ExchangeGroupCollectionModel } from "models/exchangeGroupCollectionModel";
import { IExchangeGroupUserRequest } from "types/exchangeGroupCollection";

import { renderOptionsFromEnum } from "components/Form/forms";
import { CustomerUserModel } from "models/customerUserCollectionModel";
import { showError } from "helpers/showError";

interface IProps {
  setIsShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerUser: CustomerUserModel;
}

const AddExchangeGroupCustomer = ({
  setIsShowAddCustomerModal,
  selectedCustomerUser,
}: IProps) => {
  const { getExchangeGroup } = useGetExchangeGroup();
  const [
    { data: exchangeGroupCollectionDataAtom, isLoading: exchangeGroupLoading },
  ] = useAtom(exchangeGroupCollectionAtom);

  const { addExchangeGroupCustomer, isLoading } =
    useAddExchangeGroupCustomer();

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [selectedCustomerUser, form]);

  useEffect(() => {
    getExchangeGroup({});
    
  }, []);

  const ExchangeGroupCollectionData =
    exchangeGroupCollectionDataAtom &&
    new ExchangeGroupCollectionModel(exchangeGroupCollectionDataAtom);

  const onFinish = async (value: IExchangeGroupUserRequest) => {
    const payload = {
      user_id: selectedCustomerUser.getId(),
      exchange_group_id: Number(value.exchange_group_id),
    };

    try {
      const res = await addExchangeGroupCustomer(payload as IExchangeGroupUserRequest);
      if(res){
        setIsShowAddCustomerModal(false);
        message.success(res.message);
      }
    } catch (error:any) {
      showError(error, form)
    }
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add-Exchange-Group-Customer"
      ref={formRef}
    >
      <Form.Item
        rules={[{ required: true, message: "Exchange group is required" }]}
        name="exchange_group_id"
        label="Exchange Group"
      >
        <Select
          loading={exchangeGroupLoading}
          placeholder="Please select an exchange group"
        >
          {ExchangeGroupCollectionData?.getExchangeGroupIdsAsObject() &&
            renderOptionsFromEnum(
              ExchangeGroupCollectionData?.getExchangeGroupIdsAsObject(),
            )}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddExchangeGroupCustomer;
