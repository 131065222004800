export enum AgentCompanyVerificationStatusEnums {
  PENDING = "pending",
  PROCESSING = "processing",
  ON_HOLD = "on-hold",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum AgentCompanyOperationStatusEnums {
  OPEN_FOR_EVERYONE = "open-for-everyone",
  OPEN_FOR_ADMIN = "open-for-admin",
  CLOSED_OPERATION = "closed-operation",
  BLOCKED_OPERATION = "blocked-operation",
}

export enum AgentWarehouseVerificationStatusEnums {
  PENDING = "pending",
  PROCESSING = "processing",
  ON_HOLD = "on-hold",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum AgentWarehouseOperationStatusEnums {
  OPEN_FOR_EVERYONE = "open-for-everyone",
  OPEN_FOR_ADMIN = "open-for-admin",
  CLOSED_OPERATION = "closed-operation",
  BLOCKED_OPERATION = "blocked-operation",
}

export enum TrackerCommentVisibilityEnum {
  Public = "public",
  Protected = "protected",
  Private = "private",
}
