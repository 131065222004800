import { Button, Form, Input, message, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, createRef } from "react";
import { storeExchangeAtom } from "lib/core-react/store/store";
import { renderOptionsFromEnum } from "components/Form/forms";
import { useCreateExchangeGroup } from "lib/core-react/hooks/private/useExchangeGroup";
import { useGetExchange } from "lib/core-react/hooks/private/useExchange";
import { ExchangeListCollectionModel } from "models/exchangeListCollectionModel";
import { IExchangeGroupPostRequest } from "types/exchangeGroupCollection";
import { showError } from "helpers/showError";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateExchangeGroup = ({ setIsShowCreateModal }: IProps) => {
    const { getExchange } = useGetExchange();
    const [{ data: exchangeDataAtom, isLoading: exchangeLoading }] =
        useAtom(storeExchangeAtom);
    const { createExchangeGroup, isLoading } = useCreateExchangeGroup();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const getExchangeData = async () => {
        await getExchange({ per_page: 200 });
    };
    useEffect(() => {
        getExchangeData();
    }, []);

    const ExchangeData =
        exchangeDataAtom && new ExchangeListCollectionModel(exchangeDataAtom);

    const onFinish = async (value: IExchangeGroupPostRequest) => {
        const payload = {
            name: value.name,
            exchange_list_id: value.exchange_list_id,
        };

        try {
            const res = await createExchangeGroup(
                payload as IExchangeGroupPostRequest,
            );
            if (res) {
                message.success(res.message);
                setIsShowCreateModal(false);
            }
        } catch (e: any) {
            showError(e, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Exchange Group"
            ref={formRef}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Exchange group name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Exchange group name" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Exchange list is required" },
                ]}
                name="exchange_list_id"
                label="Exchange List"
            >
                <Select
                    loading={exchangeLoading}
                    placeholder="Please select a exchange list"
                >
                    {ExchangeData?.getExchangeListIdsAsObject() &&
                        renderOptionsFromEnum(
                            ExchangeData?.getExchangeListIdsAsObject(),
                        )}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateExchangeGroup;
