import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Col,
    Dropdown,
    MenuProps,
    Modal,
    Row,
    Table,
    Tag,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { exchangeGroupCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { GetApiHelperModel } from "models/apiHelper";
import {
    useDeleteExchangeGroup,
    useGetExchangeGroup,
} from "lib/core-react/hooks/private/useExchangeGroup";
import {
    ExchangeGroupCollectionModel,
    ExchangeGroupModel,
} from "models/exchangeGroupCollectionModel";
import {
    DeleteOutlined,
    DownOutlined,
    HddFilled,
    TeamOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";

import CreateExchangeGroup from "./components/CreateExchangeGroup";
import UpdateExchangeGroup from "./components/UpdateExchangeGroup";
import FiltersComponent from "components/FiltersComponent";
import { PaginationModel } from "models/pagination";
import ExchangeGroupCustomersTable from "./components/ExchangeGroupCustomersTable";
import { IFilterType } from "types/filters";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_STORE_PERMISSION_ENUM } from "consts/permission-enum/admin-store-enum";
import CommonError from "components/Error/CommonError";

const ExchangeGroup = () => {
    // State
    const [selectedExchangeGroup, setSelectedExchangeGroup] = useState<
        ExchangeGroupModel | undefined
    >(undefined);

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [isShowCustomerModal, setIsShowCustomerModal] =
        useState<boolean>(false);
    const [
        {
            data: exchangeGroupStoreData,
            isLoading,
            refetch,
            error,
            unAuthorized,
        },
    ] = useAtom(exchangeGroupCollectionAtom);
    // Hooks
    const { getExchangeGroup } = useGetExchangeGroup();
    const { deleteExchangeGroup } = useDeleteExchangeGroup();
    const { isMobile } = useWindowWidth();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            GetApiHelperModel.makeGetRequest({}, getExchangeGroup);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    // Model
    const ExchangeGroupCollectionData =
        exchangeGroupStoreData &&
        new ExchangeGroupCollectionModel(exchangeGroupStoreData);

    // Filter
    const filterData = ExchangeGroupCollectionData?.getFilters();
    // Pagination
    const paginationData = ExchangeGroupCollectionData?.getPagination();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && ExchangeGroupCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        ExchangeGroupCollectionData?.getFilters(),
    ]);

    // Columns
    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            width: 70,
            key: "sl",
            render: (_: string, __: ExchangeGroupModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_: string, record: ExchangeGroupModel) => {
                return <div>{record.getName()}</div>;
            },
        },
        {
            title: "Exchange list",
            dataIndex: "exchange_list",
            key: "exchange_list",
            render: (_: string, record: ExchangeGroupModel) => {
                return <div>{record.getExchangeList().getName()}</div>;
            },
        },
        {
            title: "Customers",
            dataIndex: "customers",
            hidden: isMobile,
            key: "customers",
            align: "center" as const,
            render: (_: string, record: ExchangeGroupModel) => {
                return checkActionPermission(
                    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
                    <Button
                        onClick={() => {
                            setSelectedExchangeGroup(record);
                            setIsShowCustomerModal(true);
                        }}
                        icon={<TeamOutlined />}
                    >
                        Customers
                    </Button>,
                    null,
                );
            },
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            render: (_: string, record: ExchangeGroupModel) => {
                return <Tag color={"purple"}>{record.getCreatedAt()}</Tag>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: isMobile ? 100 : 150,
            fixed: isMobile ? "right" : undefined,
            render: (_: string, record: ExchangeGroupModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_UPDATE,
                        icon: <UsergroupAddOutlined />,
                        label: "Update Group",
                        key: "update",
                    },
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_DELETE,
                        icon: <DeleteOutlined />,
                        label: "Delete Group",
                        key: "delete",
                    },
                ];

                if (isMobile) {
                    items.push(
                        {
                            permission:
                                ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_UPDATE,
                            label: "Customers",
                            key: "customers",
                            icon: <TeamOutlined />,
                            onClick: () => {
                                setSelectedExchangeGroup(record);
                                setIsShowCustomerModal(true);
                            },
                        },
                        {
                            permission:
                                ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
                            label: (
                                <Tag color={"purple"}>
                                    {record.getCreatedAt()}
                                </Tag>
                            ),
                            disabled: isMobile,
                            key: "created_at",
                        },
                    );
                }

                const menuProps = {
                    items: items.filter((x) =>
                        checkActionPermission(x.permission, x, null),
                    ),
                    onClick: handleMenuClick,
                };

                return (
                    <Dropdown
                        menu={menuProps}
                        onOpenChange={() => setSelectedExchangeGroup(record)}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    {" "}
                                    Actions <DownOutlined />
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];
    // Handler
    const handleMenuClick: MenuProps["onClick"] = async (e) => {
        if (e.key === "update") {
            setIsShowUpdateModal(true);
        } else if (e.key === "delete" && selectedExchangeGroup) {
            await deleteExchangeGroup(selectedExchangeGroup.id);
        }
    };

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        GetApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getExchangeGroup,
        );
    };

    const handleProductFilter = () => {
        filters && GetApiHelperModel.makeGetRequest(filters, getExchangeGroup);
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        ExchangeGroupCollectionData,
        handlePaginationChange,
    );

    // Error handle
    if (error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        title="Exchange Group"
                        style={{ marginTop: "10px" }}
                        extra={[
                            checkActionPermission(
                                ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_ADD,
                                <Button
                                    key="1"
                                    type="primary"
                                    onClick={() => setIsShowCreateModal(true)}
                                >
                                    Create Exchange Group
                                </Button>,
                                null,
                            ),
                        ]}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <Card
                                        style={{ marginBottom: "10px" }}
                                        title="Filter"
                                    >
                                        <FiltersComponent
                                            handleProductFilter={
                                                handleProductFilter
                                            }
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            handelFilterClear={
                                                handelFilterClear
                                            }
                                            isFetched={isFetched}
                                            filters={filters}
                                            filtersData={filterData}
                                            isFromProductReceived={true}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        <div>
                            <Table
                                loading={isLoading}
                                rowKey="id"
                                bordered={true}
                                dataSource={ExchangeGroupCollectionData?.getData()}
                                //@ts-ignore
                                columns={columns}
                                pagination={paginationConfig}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                title={`Create Exchange Group`}
                open={isShowCreateModal}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <CreateExchangeGroup
                    setIsShowCreateModal={setIsShowCreateModal}
                />
            </Modal>

            {selectedExchangeGroup && (
                <Modal
                    title={`Update Exchange Group`}
                    open={isShowUpdateModal}
                    footer={false}
                    onCancel={() => setIsShowUpdateModal(false)}
                >
                    <UpdateExchangeGroup
                        setIsShowUpdateModal={setIsShowUpdateModal}
                        selectedExchangeGroup={selectedExchangeGroup}
                    />
                </Modal>
            )}

            {selectedExchangeGroup && isShowCustomerModal && (
                <Modal
                    open={isShowCustomerModal}
                    onCancel={() => {
                        setIsShowCustomerModal(false);
                    }}
                    width={isMobile ? undefined : "60vw"}
                    footer={null}
                    title={`Customers (${selectedExchangeGroup.getName()})`}
                >
                    <ExchangeGroupCustomersTable data={selectedExchangeGroup} />
                </Modal>
            )}
        </>
    );
};

export default ExchangeGroup;
