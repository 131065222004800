import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  Alert,
  Avatar,
  Button,
  Dropdown,
  Form,
  Image,
  MenuProps,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

import {
  useAgentVerificationUpdate,
  useAgentVerificationsGet,
} from "lib/core-react/hooks/private/useAgentCompany";
import { agentVerificationCollectionAtom } from "lib/core-react/store/store";

import useDataFilters from "hooks/useDataFilters";

import { AgentCompanyModel } from "models/agentCompanyCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";

import DateTimeFormatter from "components/DateTime/DateTimeFormatter";
import {
  CheckCircleOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HddFilled,
} from "@ant-design/icons";
import {
  AgentVerificationCollectionModel,
  AgentVerificationModel,
} from "models/agentVerificationCollectionModel";
import { AgentVerificationStatus } from "enums/agentCompanyCollectionEnums";
import { IAgentVerificationFormData } from "types/agentVerificationCollection";
import { UpdateVerificationStatus } from "./UpdateVerificationStatus";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { formatDate } from "utils/helpers";
import { StatusTag } from "components";

interface IProps {
  data: AgentCompanyModel;
}

export const VerificationsTable = ({ data }: IProps) => {
  const { getAgentVerifications } = useAgentVerificationsGet(data.id);
  const [{ data: agentVerificationCollectionData, isLoading, refetch }] =
    useAtom(agentVerificationCollectionAtom);
  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();
  const { isMobile } = useWindowWidth();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getAgentVerifications);
    }
    
  }, [data, filters]);

  const AgentVerificationCollectionData =
    agentVerificationCollectionData &&
    new AgentVerificationCollectionModel(agentVerificationCollectionData);

  // Pagination
  const paginationData = AgentVerificationCollectionData?.getPagination();

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentVerifications,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AgentVerificationCollectionData,
    handlePaginationChange,
  );

  // Modal
  const {
    updateVerification,
    isLoading: isLoadingUpdate,
    isError,
  } = useAgentVerificationUpdate();

  const [selectedVerification, setSelectedVerification] = useState<
    AgentVerificationModel | undefined
  >();
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === "update") {
      setIsShowUpdateModal(true);
    }
  };

  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: IAgentVerificationFormData) => {
    try {
      await updateVerification(selectedVerification!.getId(), value);
      notification["success"]({
        message: "Verfication Updated successfully",
      });
      await getAgentVerifications();
      setSelectedVerification(undefined);
      setIsShowUpdateModal(false);
      setIsErrorVisible(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      width: 70,
      align: "center" as const,
      render: (_: string, __: AgentVerificationModel, index: number) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "Verifications Details",
      dataIndex: "name",
      hidden: !isMobile,
      key: "name",
      render: (_: string, record: AgentVerificationModel) => {
        return (
          <>
            <Typography style={{ textTransform: "capitalize" }}>
              {record.getName().replaceAll("_", " ")}
            </Typography>
            <br />
            <Space direction="vertical">
              {record
                .getVerificationItems()
                .getData()
                .map((item) => {
                  return (
                    <Space key={item.getId()}>
                      <Typography.Text
                        style={{ textTransform: "capitalize" }}
                        strong
                      >
                        {item.getName().replaceAll("_", " ")}:
                      </Typography.Text>
                      {item.getType() === "string" ||
                      item.getType() === "select" ? (
                        item.getValue()
                      ) : item.getType() === "date" ? (
                        <Tag color="purple">{formatDate(item.getValue())}</Tag>
                      ) : item.getType() === "image-upload" ? (
                        <Avatar
                          src={<Image src={item.getValue()} />}
                          size={50}
                          shape="square"
                        />
                      ) : (
                        ""
                      )}
                    </Space>
                  );
                })}
            </Space>
            <br />
            Status:{" "}
            <Tag
              icon={
                record.getStatus() === AgentVerificationStatus.APPROVED ? (
                  <CheckCircleOutlined />
                ) : (
                  <ExclamationCircleOutlined />
                )
              }
              color={
                record.getStatus() === AgentVerificationStatus.REJECTED
                  ? "warning"
                  : "success"
              }
            >
              {record.getStatus()}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      render: (_: string, record: AgentVerificationModel) => {
        return (
          <Typography style={{ textTransform: "capitalize" }}>
            {record.getName().replaceAll("_", " ")}
          </Typography>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "verification_items",
      hidden: isMobile,
      key: "verification_items",
      render: (_: string, record: AgentVerificationModel) => {
        return (
          <Space direction="vertical">
            {record
              .getVerificationItems()
              .getData()
              .map((item) => {
                return (
                  <Space key={item.getId()}>
                    <Typography.Text
                      style={{ textTransform: "capitalize" }}
                      strong
                    >
                      {item.getName().replaceAll("_", " ")}:
                    </Typography.Text>
                    {item.getType() === "string" ||
                    item.getType() === "select" ? (
                      item.getValue()
                    ) : item.getType() === "date" ? (
                      <DateTimeFormatter date={item.getValue()} />
                    ) : item.getType() === "image-upload" ? (
                      <Avatar
                        src={<Image src={item.getValue()} />}
                        size={50}
                        shape="square"
                      />
                    ) : (
                      ""
                    )}
                  </Space>
                );
              })}
          </Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      hidden: isMobile,
      align: "center" as const,
      key: "status",
      render: (_: string, record: AgentVerificationModel) => {
        return (
          <StatusTag text={record.getStatus()} slug={record.getStatus()} />
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      align: "center" as const,
      hidden: isMobile,
      key: "created_at",
      render: (_: string, record: AgentVerificationModel) => {
        return <Tag color="purple">{formatDate(record.getCreatedAt())}</Tag>;
      },
    },
    {
      title: "Actions",
      fixed: isMobile ? "right" : undefined,
      key: "actions",
      align: "center" as const,
      width: 90,
      render: (_: string, record: AgentVerificationModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <EditOutlined />,
            label: "Update",
            key: "update",
          },
        ];

        const menuProps = {
          items,
          onClick: (e: MenuInfo) => handleMenuClick(e),
        };

        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => {
              setSelectedVerification(record);
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        size="small"
        bordered
        loading={isLoading}
        dataSource={AgentVerificationCollectionData?.getData()}
        pagination={paginationConfig}
        rowKey="id"
      />

      {selectedVerification && (
        <Modal
          centered
          title={`Update Verification Status`}
          open={isShowUpdateModal}
          onCancel={() => setIsShowUpdateModal(false)}
          onOk={form.submit}
          okText="Update"
          okButtonProps={{ loading: isLoadingUpdate }}
        >
          <UpdateVerificationStatus
            form={form}
            onFinish={onFinish}
            selectedVerification={selectedVerification}
          />
          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default VerificationsTable;
