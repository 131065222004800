import { Alert, Button, Form, FormInstance, Select } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";
import { ShippingCountryDataStatus } from "enums/shippingCountryCollectionEnums";
import { useAtom } from "jotai";
import { useGetCountry, useGetRegion } from "lib/core-react/hooks/private";
import { useCreateShippingCountry } from "lib/core-react/hooks/private/useShippingCountry";
import { regionCountry, regionRegion } from "lib/core-react/store/store";
import { CountryModel } from "models/country";
import { RegionModel } from "models/region";

import { ShippingCountryPostRequestModel } from "models/shippingCountry";
import React, { useEffect, useState } from "react";

interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateShippingCountry = ({ setIsShowCreateModal }: IProps) => {
  const { createShippingCountry, isLoading, isError } =
    useCreateShippingCountry();

  const [visible, setVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = React.createRef();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  useEffect(() => {
    getCountry("per_page=200");
    getRegion("per_page=200");
    
  }, []);

  // Country, Region
  const { getCountry } = useGetCountry();
  const { getRegion } = useGetRegion();

  const [{ data: countryDataAtom, isLoading: countryLoading }] =
    useAtom(regionCountry);
  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);
  // @ts-ignore FIXME:
  const countryData = countryDataAtom && new CountryModel(countryDataAtom);
  const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  const onFinish = async (value: ShippingCountryPostRequestModel) => {
    const payload = {
      status: value.status,
      country_id: value.country_id,
      region_id: value.region_id,
    };

    try {
      await createShippingCountry(payload as ShippingCountryPostRequestModel);
      setIsShowCreateModal(false);
      setVisible(false);
    } catch (error) {
      setVisible(true);
    }
  };
  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Create_shipping_country"
          ref={formRef}
        >
          <Form.Item
            rules={[{ required: true, message: "Region ID is required" }]}
            name="region_id"
            label="Region"
          >
            <Select
              loading={regionLoading}
              placeholder="Please select a Region ID"
            >
              {regionData?.getRegionIdsAsObject() &&
                renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Country id is required" }]}
            name="country_id"
            label="Country"
          >
            <Select
              loading={countryLoading}
              placeholder="Please select a country id"
            >
              {countryData?.getCountryId() &&
                renderOptionsFromEnum(countryData?.getCountryId())}
            </Select>
          </Form.Item>

          <Form.Item required name="status" label="Status">
            <Select placeholder="Please select a status">
              {" "}
              {renderOptionsFromEnum(ShippingCountryDataStatus)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {visible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default CreateShippingCountry;
