import {
  BaseShippingCategoryDataStatus,
  BaseShippingCategoryEnum,
} from "enums/baseShippingCategoryCollectionEnums";
import { TreeSelect } from "types";
import {
  IBaseShippingCategory,
  IBaseShippingCategoryData,
  IBaseShippingCategoryRequest,
} from "types/baseShippingCategory";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class BaseShippingCategoryDataModel {
  public readonly object: BaseShippingCategoryEnum.BaseShippingCategory;
  public readonly id: string;
  public readonly parent_id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly status: BaseShippingCategoryDataStatus;
  public readonly created_at: string;
  public readonly children: BaseShippingCategoryDataModel[];

  constructor(dataItem: IBaseShippingCategoryData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.parent_id = dataItem.parent_id;
    this.name = dataItem.name;
    this.slug = dataItem.slug;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
    this.children = dataItem.children;
  }
}

export class BaseShippingCategoryModel {
  public readonly object: BaseShippingCategoryEnum.BaseShippingCategoryCollection;
  public readonly data: BaseShippingCategoryDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IBaseShippingCategory) {
    this.object = data?.object;
    this.data = data?.data?.map(
      (item) => new BaseShippingCategoryDataModel(item)
    );
    this.pagination = data?.pagination;
    this.filters = data?.filters;
  }

  getBaseShippingCategoryTreeIds() {
    const treeData: TreeSelect[] = [];
    // Helper function to build the tree recursively
    function buildTree(dataItem: BaseShippingCategoryDataModel): TreeSelect {
      const node: TreeSelect = {
        value: dataItem.id,
        title: dataItem.name,
        children: [],
      };

      if (dataItem.children && dataItem.children.length > 0) {
        dataItem.children.forEach((childItem: BaseShippingCategoryDataModel) => {
          const childNode = buildTree(childItem);
          node.children.push(childNode);
        });
      }

      return node;
    }

    // Build the tree for each top-level data item
    this.data.forEach((dataItem) => {
      const treeItem = buildTree(dataItem);
      treeData.push(treeItem);
    });

    return treeData;
  }

  getBaseShippingCategoryTreeIdsForParticularData(dataItem: BaseShippingCategoryDataModel) {
    const treeData: TreeSelect[] = [];
    // Helper function to build the tree recursively
    function buildTree(dataItem: BaseShippingCategoryDataModel): TreeSelect {
      const node: TreeSelect = {
        value: dataItem.id,
        title: dataItem.name,
        children: [],
      };

      if (dataItem.children && dataItem.children.length > 0) {
        dataItem.children.forEach((childItem: BaseShippingCategoryDataModel) => {
          const childNode = buildTree(childItem);
          node.children.push(childNode);
        });
      }

      return node;
    }

    // Build the tree for each top-level data item
    const treeItem = buildTree(dataItem);
    treeData.push(treeItem);

    dataItem.children.forEach((dataItem) => {
      const treeItem = buildTree(dataItem);
      treeData.push(treeItem);
    });

    return buildTree(dataItem);
  }
}

//Post Request
export class BaseShippingCategoryRequestModel {
  public readonly parent_id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly status: BaseShippingCategoryDataStatus;

  constructor(reqData: IBaseShippingCategoryRequest) {
    this.parent_id = reqData.parent_id;
    this.name = reqData.name;
    this.slug = reqData.slug;
    this.status = reqData.status;
  }
}
