import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Form,
    Input,
    Select,
    Table,
    Image,
    Typography,
    Dropdown,
    MenuProps,
    FormInstance,
    Cascader,
    Skeleton,
    Row,
    Col,
    Tabs,
    Empty,
} from "antd";
import { createRef, useEffect, useRef, useState } from "react";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { useNavigate } from "react-router-dom";
import {
    addonServiceCollectionAtom,
    addressCollectionAtom,
    bulkUserCustomer,
    buyOrderPlaceAtom,
    purchaseCartCollectionAtom,
    regionRegion,
} from "lib/core-react/store/store";
import { useAtom } from "jotai";
import { useGetRegion } from "lib/core-react/hooks/private";
import { RegionCollectionModel } from "models/regionCollectionModel";
import VariationTable from "./BuyOrderPlace/expandedRowRender";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    HddFilled,
} from "@ant-design/icons";
import { AddonServiceCollectionModel } from "models/addonServiceCollectionModel";
import { useAddonServicesList } from "lib/core-react/hooks/private/useAddonService";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { ICartItem, IDeleteCartProduct, IUserCart } from "types/buyOrderPlace";
import {
    useDeleteCartProduct,
    useGetCartList,
} from "lib/core-react/hooks/private/usePurchaseCart";
import {
    PurchaseCartCollectionModel,
    PurchaseCartItemModel,
} from "models/PurchaseCartCollectionModel";
import TextArea from "antd/es/input/TextArea";
import { useGetAddress } from "lib/core-react/hooks/private/useAddress";
import { AddressCollectionModel } from "models/addressCollectionModel";
import { Forms } from "models/form";
import Address from "./BuyOrderPlace/Adddress";
import { useBuyProderOrderPlace } from "lib/core-react/hooks/private/usePurchase";
import TabPane from "antd/es/tabs/TabPane";
import Link from "antd/lib/typography/Link";
import { BulkUserCollectionModel } from "../../../../models/bulkUserCollectionModel";
import { useGetCustomers } from "../../../../lib/core-react/hooks/private/useBulkUser";
import { UserSelect } from "../../../../components/CustomFormElement/UserSelect";
import { useDebounce } from "hooks/useDebounce";

// interface Option {
//   value: string
//   id: number | null
// }

const BuyOrderCreate = () => {
    const [form] = Form.useForm();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const navigate = useNavigate();
    const { Text } = Typography;
    const { getCustomers } = useGetCustomers();
    const { getRegion } = useGetRegion();
    const { getAddonServices } = useAddonServicesList();
    const { deleteCartProduct } = useDeleteCartProduct();
    const { getAddress } = useGetAddress();
    const { getCartList } = useGetCartList();
    const { buyProductOrderPlace, isLoading } = useBuyProderOrderPlace();
    const { filters, isFirstCall, isFetched } = useDataFilters();
    const [bulkUserCustomerData] = useAtom(bulkUserCustomer);
    // State
    const [url, setUrl] = useState("");
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    // Atom
    const [{ data: addonServicsCollectionData, refetch }] = useAtom(
        addonServiceCollectionAtom,
    );

    const [{ data: addressCollectionData, refetch: refetchAddress }] = useAtom(
        addressCollectionAtom,
    );

    const [
        {
            data: purchaseCartCollectionDataAtom,
            isLoading: isPurchaseCartLoading,
        },
    ] = useAtom(purchaseCartCollectionAtom);

    const [buyOrderPlaceAtomData, setByOrderPlaceAtomData] =
        useAtom(buyOrderPlaceAtom);

    const [{ data: regionDataAtom }] = useAtom(regionRegion);

    const user = buyOrderPlaceAtomData.data?.user;

    // Api Call
    useEffect(() => {
        if (buyOrderPlaceAtomData.data?.user?.value) {
            getCartList(Number(buyOrderPlaceAtomData.data?.user?.value));
        }
    }, [buyOrderPlaceAtomData.data?.user?.value]);

    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            filters && ApiHelperModel.makeGetRequest(filters, getAddonServices);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    useEffect(() => {
        if (refetchAddress) {
            getAddress({
                countries: buyOrderPlaceAtomData.data?.countryCode,
                user_id: buyOrderPlaceAtomData.data?.user?.value,
            });
        }
    }, [refetchAddress]);

    useEffect(() => {
        if (
            buyOrderPlaceAtomData.data?.user?.value &&
            buyOrderPlaceAtomData.data.countryCode
        ) {
            getAddress({
                countries: buyOrderPlaceAtomData.data?.countryCode,
                user_id: buyOrderPlaceAtomData.data?.user?.value,
            });
        }
    }, [buyOrderPlaceAtomData.data?.user?.value]);

    useEffect(() => {
        getRegion("per_page=200");
    }, []);

    // Data Models
    const purchaseCartCollectionData =
        purchaseCartCollectionDataAtom &&
        new PurchaseCartCollectionModel(purchaseCartCollectionDataAtom);

    const addonServiceCollectionModel =
        addonServicsCollectionData &&
        new AddonServiceCollectionModel(addonServicsCollectionData);

    const options = addonServiceCollectionModel
        ?.getData()
        .filter((service) => service.addon_for === "buy-and-ship")
        .map((service) => ({
            value: service.getId(),
            label: service.getName(),
        }));

    const regionData =
        regionDataAtom && new RegionCollectionModel(regionDataAtom);

    const addressCollectionObj =
        addressCollectionData &&
        addressCollectionData.data &&
        new AddressCollectionModel(addressCollectionData);

    const handleAddProductDetail = (product_url: any) => {
        setByOrderPlaceAtomData({
            ...buyOrderPlaceAtomData,
            data: {
                ...buyOrderPlaceAtomData.data,
                product_url: product_url,
            },
        });
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        navigate(
            `/product-manage/products/product_detail/${e.key}?operation=${BuyOrderOperationEnum.PlaceBuyOrder}&userId=${buyOrderPlaceAtomData.data?.user?.value}&region=${buyOrderPlaceAtomData.data?.region}&region_id=${buyOrderPlaceAtomData.data?.region_id}`,
        );
    };

    const handleDeleteCart = async (e) => {
        if (buyOrderPlaceAtomData && buyOrderPlaceAtomData.data?.region) {
            const region = buyOrderPlaceAtomData.data?.region;
            const locale = "en";
            const payload: IDeleteCartProduct = {
                user_id: Number(buyOrderPlaceAtomData.data?.user?.value),
                ids: [e.key],
            };
            try {
                await deleteCartProduct(region, locale, payload);
            } catch (error) {}
        }
    };

    const handleChangeRegion = async (value) => {
        const [regionId, countryId] = value;

        const selectedRegion = regionData
            ?.getData()
            .find((region) => region.id === regionId);
        const selectedCountry = selectedRegion
            ?.getCountries()
            ?.data.find((country) => country.id === countryId);

        const regionCode = selectedRegion ? selectedRegion.code : null;
        const countryCode = selectedCountry ? selectedCountry.code : null;

        setByOrderPlaceAtomData({
            ...buyOrderPlaceAtomData,
            isLoading: false,
            data: {
                ...buyOrderPlaceAtomData.data,
                region: regionCode,
                region_id: selectedRegion?.id,
                countryCode: countryCode ? [countryCode] : undefined,
                countryId: countryId,
            },
        });
    };

    const handleOnSubmit = async (values) => {
        const cartData = purchaseCartCollectionData?.getData() || [];
        const cartId = cartData
            .filter((x) => x.getCartType() === "impersonate-cart")
            .map((cart) => cart.getId())[0];
        const items: ICartItem[] = cartData
            .flatMap((cart) =>
                cart
                    .getItems()
                    .getData()
                    .map((item) => ({
                        id: item.id,
                        variation_ids: selectedVariationRowKeys[item.id] || [],
                    })),
            )
            .filter((item) => item.variation_ids.length > 0);
        const payload: IUserCart = {
            user_id:
                typeof values.user === "number"
                    ? values.user
                    : values.user.value,
            cart: {
                id: cartId,
                items: items,
            },
            addon_service_ids: values.addon_service_ids ?? [],
            shipping_address_id: Number(selectedAddressId),
            instruction: values.note ?? "",
        };
        try {
            await buyProductOrderPlace(payload);
            form.resetFields();
        } catch (error) {}
    };

    const residences = regionData?.getData().map((region) => ({
        value: region.id,
        label: region.code,
        children: region.getCountries()?.data.map((country) => ({
            value: country.id,
            label: country.name,
            isLeaf: true,
        })),
    }));

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
        handleAddProductDetail(e.target.value);
    };

    const handleClear = () => {
        form.resetFields();
    };

    const adminCartData = purchaseCartCollectionData
        ?.getData()
        .filter((cart) => cart.cart_type === "impersonate-cart")
        .flatMap((cart) => cart.getItems().getData());

    const customerCartData = purchaseCartCollectionData
        ?.getData()
        .filter((cart) => cart.cart_type === "regular")
        .flatMap((cart) => cart.getItems().getData());

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedVariationRowKeys, setSelectedVariationRowKeys] = useState<{
        [key: number]: number[];
    }>({});

    // Use a ref to avoid triggering the effect repeatedly
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current && adminCartData && adminCartData.length > 0) {
            initialized.current = true;

            const allMainRowKeys = adminCartData.map((item) => item.id);
            setSelectedRowKeys(allMainRowKeys);

            const allVariationKeys: { [key: number]: number[] } = {};
            adminCartData.forEach((item) => {
                allVariationKeys[item.id] = item.variations.map(
                    (variation) => variation.id,
                );
            });
            setSelectedVariationRowKeys(allVariationKeys);
        }
    }, [adminCartData]);

    const handleVariationSelectionChange = (
        recordId: number,
        newSelectedRowKeys: number[],
    ) => {
        setSelectedVariationRowKeys((prevSelectedKeys) => ({
            ...prevSelectedKeys,
            [recordId]: newSelectedRowKeys,
        }));
    };

    const handleSelectAll = (
        selected: boolean,
        selectedRows: PurchaseCartItemModel[],
    ) => {
        if (selected) {
            const newSelectedVariationRowKeys = {};
            selectedRows.forEach((row) => {
                newSelectedVariationRowKeys[row.id] = row.variations.map(
                    (v) => v.id,
                );
            });
            setSelectedVariationRowKeys(newSelectedVariationRowKeys);
        } else {
            setSelectedVariationRowKeys({});
        }
        setSelectedRowKeys(selected ? selectedRows.map((row) => row.id) : []);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
            if (newSelectedRowKeys.length === 0) {
                setSelectedVariationRowKeys({});
            }
        },
        onSelect: (record, selected) => {
            if (!selected) {
                setSelectedVariationRowKeys((prevKeys) => ({
                    ...prevKeys,
                    [record.id]: [],
                }));
            } else {
                setSelectedVariationRowKeys((prevKeys) => ({
                    ...prevKeys,
                    [record.id]: record.variations.map((v) => v.id),
                }));
            }
        },
        onSelectAll: handleSelectAll,
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            const queryParam = `per_page=100&keyword=${debouncedSearchTerm}`;
            getCustomers(queryParam);
        } else {
            getCustomers("per_page=200");
        }
    }, [debouncedSearchTerm]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };

    // Colums
    const columns = [
        {
            title: "Image",
            dataIndex: "product_image",
            key: "product_image",
            render: (product_image: any) => {
                return (
                    <>
                        <Image
                            width={60}
                            src={product_image}
                            placeholder={
                                <Image
                                    preview={false}
                                    src={product_image}
                                    width={200}
                                />
                            }
                        />
                    </>
                );
            },
        },
        {
            title: "Title",
            dataIndex: "product_title",
            key: "product_title",
            render: (product_title: any) => {
                return (
                    <Text>
                        <Link target={"_blank"} rel="noreferrer">
                            {product_title.length >= 50
                                ? product_title.slice(0, 50) + "..."
                                : product_title}
                        </Link>
                    </Text>
                );
            },
        },
        {
            title: "Quatity",
            dataIndex: "total_quantity",
            key: "total_quantity",
            render: (total_quantity: any) => {
                return <Text>{total_quantity}</Text>;
            },
        },
        {
            title: "Orginal Price",
            dataIndex: "original_total",
            key: "original_total",
            render: (original_total: any) => {
                return <Text>{original_total}</Text>;
            },
        },
        {
            title: "Total Price/BDT",
            dataIndex: "total",
            key: "total",
            render: (original_total: any) => {
                return <Text>{original_total}</Text>;
            },
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: string, record: PurchaseCartItemModel) => {
                const items: MenuProps["items"] = [
                    {
                        label: "Edit",
                        key: record.product_id,
                        icon: <EditOutlined />,
                        onClick: handleMenuClick,
                    },
                    {
                        label: "Delete",
                        key: record.id,
                        icon: <DeleteOutlined />,
                        onClick: handleDeleteCart,
                    },
                ];

                const menuProps = {
                    items,
                };

                return (
                    <Dropdown menu={menuProps} placement={"bottom"}>
                        <Button icon={<HddFilled />}>
                            Actions
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const isDisabledUrlSubmit =
        !buyOrderPlaceAtomData?.data?.product_url ||
        !buyOrderPlaceAtomData?.data?.region ||
        !buyOrderPlaceAtomData?.data?.user;
    const isDisabledInventoryButton =
        !buyOrderPlaceAtomData?.data?.region ||
        !buyOrderPlaceAtomData?.data?.user;

    const Customers =
        bulkUserCustomerData &&
        bulkUserCustomerData.data &&
        new BulkUserCollectionModel(bulkUserCustomerData.data);
    const formatCustomersData = Customers?.data.map((x) => {
        return {
            label: `${x.name} (${x.email})`,
            value: x.id,
        };
    });
    return (
        <div>
            <PageHeader
                style={{ marginTop: "10px" }}
                ghost={false}
                title="Create Order"
                onBack={() => navigate("/buy-and-ship-for-me/orders")}
                extra={[
                    <Button key="1" type="primary" onClick={handleClear}>
                        clear
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    onFinish={handleOnSubmit}
                    layout="vertical"
                    onFieldsChange={onValuesChange}
                    ref={formRef}
                >
                    <Card title="Required informations">
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="region"
                                    label="Select Region"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please add your region!",
                                        },
                                    ]}
                                    required
                                    initialValue={
                                        buyOrderPlaceAtomData.data?.region
                                    }
                                >
                                    <Cascader
                                        options={residences}
                                        placeholder="Please select a region and country"
                                        onChange={(value) =>
                                            handleChangeRegion(value)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Select User"
                                    name="user"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select the user!",
                                        },
                                    ]}
                                    required
                                    initialValue={
                                        buyOrderPlaceAtomData.data?.user
                                    }
                                >
                                    <UserSelect
                                        initialData={
                                            (!bulkUserCustomerData.isLoading &&
                                                formatCustomersData) ||
                                            []
                                        }
                                        loading={bulkUserCustomerData.isLoading}
                                        onClick={async () => {
                                            if (
                                                (!formatCustomersData &&
                                                    !searchTerm) ||
                                                (!formatCustomersData?.length &&
                                                    !searchTerm)
                                            ) {
                                                await getCustomers(
                                                    "per_page=200",
                                                );
                                            }
                                        }}
                                        resource={""}
                                        onSearch={async (value) => {
                                            handleSearch(value);
                                        }}
                                        value={
                                            buyOrderPlaceAtomData.data?.user
                                                ?.value
                                        }
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={
                                            "Search users by email or name"
                                        }
                                        onChange={(value) => {
                                            form.setFieldValue("user", value);
                                            const data = {
                                                ...buyOrderPlaceAtomData,
                                            };
                                            data.data = {
                                                ...data.data,
                                                user: {
                                                    value: value,
                                                    label:
                                                        formatCustomersData?.find(
                                                            (x) =>
                                                                x.value ===
                                                                value,
                                                        )?.label || "",
                                                },
                                            };
                                            setByOrderPlaceAtomData(data);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item label="Add From Url" name="link">
                                    <div
                                        style={{ display: "flex", gap: "8px" }}
                                    >
                                        <Input
                                            style={{ flex: 1 }}
                                            placeholder="Please Paste your URL"
                                            onChange={handleUrlChange}
                                            defaultValue={
                                                buyOrderPlaceAtomData?.data
                                                    ?.product_url || ""
                                            }
                                        />
                                        <Button
                                            type="primary"
                                            style={{
                                                opacity: isDisabledUrlSubmit
                                                    ? 0.5
                                                    : 1,
                                                color: "white",
                                                backgroundColor:
                                                    isDisabledUrlSubmit
                                                        ? "#d9d9d9"
                                                        : "#1890ff", // Disabled background color
                                                cursor: isDisabledUrlSubmit
                                                    ? "not-allowed"
                                                    : "pointer", // Change cursor when disabled
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/product-manage/products/product_detail/find?operation=${BuyOrderOperationEnum.PlaceBuyOrder}&userId=${buyOrderPlaceAtomData.data?.user?.value}&region=${buyOrderPlaceAtomData.data?.region}&region_id=${buyOrderPlaceAtomData.data?.region_id}&productUrl=${url}`,
                                                )
                                            }
                                            disabled={
                                                !buyOrderPlaceAtomData?.data
                                                    ?.product_url ||
                                                !buyOrderPlaceAtomData?.data
                                                    ?.region ||
                                                !buyOrderPlaceAtomData?.data
                                                    ?.user
                                            }
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item label="Add From Inventory">
                                    <Button
                                        style={{
                                            opacity: isDisabledInventoryButton
                                                ? 0.5
                                                : 1,
                                            color: "white",
                                            backgroundColor:
                                                isDisabledInventoryButton
                                                    ? "#d9d9d9"
                                                    : "#1890ff", // Disabled background color
                                            cursor: isDisabledInventoryButton
                                                ? "not-allowed"
                                                : "pointer", // Change cursor when disabled
                                        }}
                                        type="primary"
                                        disabled={isDisabledInventoryButton}
                                        onClick={() =>
                                            navigate(
                                                `/product-manage/products?operation=${BuyOrderOperationEnum.PlaceBuyOrder}&userId=${buyOrderPlaceAtomData.data?.user?.value}&region=${buyOrderPlaceAtomData.data?.region}&region_id=${buyOrderPlaceAtomData.data?.region_id}`,
                                            )
                                        }
                                    >
                                        Go to inventory
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        style={{ margin: "10px 0px " }}
                        title="Place Order Products"
                    >
                        {user ? (
                            purchaseCartCollectionData &&
                            purchaseCartCollectionData.getData() ? (
                                <div>
                                    <Card>
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab="Admin Cart" key="1">
                                                <Card>
                                                    <Table
                                                        rowKey="id"
                                                        columns={columns}
                                                        rowSelection={
                                                            rowSelection
                                                        }
                                                        expandable={{
                                                            expandedRowRender: (
                                                                record,
                                                            ) => (
                                                                <VariationTable
                                                                    variations={
                                                                        record.variations
                                                                    }
                                                                    selectedVariationRowKeys={
                                                                        selectedVariationRowKeys[
                                                                            record
                                                                                .id
                                                                        ] || []
                                                                    }
                                                                    setSelectedVariationRowKeys={(
                                                                        selectedRowKeys,
                                                                    ) =>
                                                                        handleVariationSelectionChange(
                                                                            record.id,
                                                                            selectedRowKeys,
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                        }}
                                                        dataSource={
                                                            adminCartData
                                                        }
                                                        pagination={false}
                                                        scroll={{
                                                            x: "max-content",
                                                        }}
                                                    />
                                                </Card>
                                            </TabPane>
                                            <TabPane
                                                tab="Customer Cart"
                                                key="2"
                                            >
                                                <Card>
                                                    <Table
                                                        rowKey="id"
                                                        columns={columns}
                                                        rowSelection={
                                                            rowSelection
                                                        }
                                                        expandable={{
                                                            expandedRowRender: (
                                                                record,
                                                            ) => (
                                                                <VariationTable
                                                                    variations={
                                                                        record.variations
                                                                    }
                                                                    selectedVariationRowKeys={
                                                                        selectedVariationRowKeys[
                                                                            record
                                                                                .id
                                                                        ] || []
                                                                    }
                                                                    setSelectedVariationRowKeys={(
                                                                        selectedRowKeys,
                                                                    ) =>
                                                                        handleVariationSelectionChange(
                                                                            record.id,
                                                                            selectedRowKeys,
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                        }}
                                                        dataSource={
                                                            customerCartData
                                                        }
                                                        pagination={false}
                                                        scroll={{
                                                            x: "max-content",
                                                        }}
                                                    />
                                                </Card>
                                            </TabPane>
                                        </Tabs>
                                    </Card>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <Card
                                            title="Address"
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        >
                                            {addressCollectionObj && (
                                                <Address
                                                    address={addressCollectionObj.getAllAddress()}
                                                    selectedAddressId={
                                                        selectedAddressId
                                                    }
                                                    setSelectedAddressId={
                                                        setSelectedAddressId
                                                    }
                                                />
                                            )}
                                        </Card>
                                    </div>
                                </div>
                            ) : isPurchaseCartLoading ? (
                                <Skeleton />
                            ) : (
                                <Empty />
                            )
                        ) : (
                            <p>
                                Please select the user and regions and country.
                            </p>
                        )}
                    </Card>
                    <Card title={"Others details"}>
                        <div style={{ marginTop: "20px" }}>
                            <Form.Item
                                name="addon_service_ids"
                                label="Value added services"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please Select services!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Please Select your service...."
                                    options={options}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="note"
                                label="Add Note"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please add your note!",
                                    },
                                ]}
                                style={{ marginTop: "10px" }}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder="Please add your note"
                                />
                            </Form.Item>
                        </div>
                    </Card>

                    <Form.Item>
                        <div
                            style={{
                                margin: "10px 0px",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                loading={isLoading}
                                disabled={isLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Place Order
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </PageHeader>
        </div>
    );
};

export default BuyOrderCreate;
