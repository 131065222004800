import { IPagination } from "types/pagination";
import {
  IShipmentProductPackageTracking,
  IShipmentProductPackageTrackingCollection,
} from "types/shipmentProductTrackingCollection";

export class ShipmentProductPackageTrackingModel {
  object: string;
  id: number;
  type: string;
  tracking: string;

  constructor(dataItem: IShipmentProductPackageTracking) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.type = dataItem.type;
    this.tracking = dataItem.tracking;
  }

  getId = () => this.id;
  getType = () => this.type;
  getTracking = () => this.tracking;
}

export class ShipmentProductPackageTrackingCollectionModel {
  object: string;
  data: ShipmentProductPackageTrackingModel[];
  pagination?: IPagination;

  constructor(dataItem: IShipmentProductPackageTrackingCollection) {
    this.object = dataItem?.object;
    this.pagination = dataItem?.pagination;
    this.data = dataItem?.data?.map(
      (item) => new ShipmentProductPackageTrackingModel(item)
    );
  }

  getData = () => this.data;
}
