import { Col, Row, Space } from "antd";
import React from "react";

import TextLine from "./TextLine";
import { DisputeBuyProductVariationPropertiesModel } from "models/disputeDetailsCollectionModel";

const DisputeItemProperties: React.FC<
  DisputeBuyProductVariationPropertiesModel
> = (props) => {
  const { getPropertyName, getPropertyValueName } = props;

  return (
    <>
      <Row align={"middle"}>
        <Col>
          <Space direction="vertical" size={0}>
            <TextLine label="Name :" value={getPropertyName()} />
            <TextLine label="value : " value={getPropertyValueName()} />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default DisputeItemProperties;
