import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useCreateCustomerUser } from "lib/core-react/hooks/private/useCustomerUser";
import { Forms } from "models/form";
import { CustomerUserPostRequestModel } from "models/customerUserCollectionModel";
import {handleFormErrors} from "../../../../../utils/helper";


interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateCustomerUser = ({ setIsShowCreateModal }: IProps) => {
  const { createCustomerUser, isLoading, isError } = useCreateCustomerUser();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = async (value: CustomerUserPostRequestModel) => {
    try {
      await createCustomerUser(value);
      setIsShowCreateModal(false);
      notification["success"]({
        message: "Created User successfully",
      });
      form.resetFields();
    } catch (error: any) {
       if(error.response.data.errors){
         handleFormErrors(form, error.response);
       }
     else if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const onValuesChange = (changedValues: any, ) => {
    Forms.formValueChange(form, changedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add User"
      onFieldsChange={onValuesChange}
      ref={formRef}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "User name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="User name" />
      </Form.Item>

      <Form.Item
        rules={[
          { required: true, message: "Email is required" },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
        label="Email"
        name="email"
      >
        <Input placeholder="Email" type="email" autoComplete="email" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Phone is required" }]}
        label="Phone"
        name="phone"
      >
        <Input placeholder="Phone" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Password is required" }]}
        label="Password"
        name="password"
      >
        <Input.Password placeholder="Password" type="password" />
      </Form.Item>

      <Form.Item
        rules={[
          { required: true, message: "Password Confirmation is required" },
        ]}
        label="Password Confirmation"
        name="password_confirmation"
      >
        <Input.Password placeholder="Password" type="password" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Shipping mark is required" }]}
        label="Shipping Mark"
        name="shipping_mark"
      >
        <Input placeholder="Shipping mark" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default CreateCustomerUser;
