import { Button, Col, Flex, Form, InputNumber, Row, Select } from "antd";
import { renderOptionsFromEnumReversed } from "components/Form/forms";
import { CampaignProductConfigurationTypeEnum } from "enums/campaignCollectionEnums";

const AddRawProductToCampaignInput = () => {
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Hike type is required",
                            },
                        ]}
                        name={["configuration", "hike_type"]}
                        label="Hike type"
                    >
                        <Select placeholder="Please select a hike type">
                            {renderOptionsFromEnumReversed(
                                CampaignProductConfigurationTypeEnum,
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Price hike is required",
                            },
                        ]}
                        label="Price Hike"
                        name={["configuration", "price_hike"]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                            placeholder="Enter price hike"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Discount type is required",
                            },
                        ]}
                        name={["configuration", "discount_type"]}
                        label="Discount type"
                    >
                        <Select placeholder="Please select a discount type">
                            {renderOptionsFromEnumReversed(
                                CampaignProductConfigurationTypeEnum,
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Discount is required",
                            },
                        ]}
                        label="Discount"
                        name={["configuration", "discount"]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                            placeholder="Enter discount"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Flex justify="end">
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button>
                ,
            </Flex>
        </div>
    );
};

export default AddRawProductToCampaignInput;
