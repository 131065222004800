import { IPagination } from "types/pagination";

import { AgentWarehouseCollectionEnums } from "enums/agentWarehouseCollectionEnums";
import {
  IAgentWarehouse,
  IAgentWarehouseAgentCompany,
  IAgentWarehouseCollection,
  IAgentWarehouseCountry,
} from "types/agentWarehouseCollection";
import { IFilterType } from "types/filters";
import {
  AgentCompanyOperationStatusEnums,
  AgentCompanyVerificationStatusEnums,
  AgentWarehouseOperationStatusEnums,
  AgentWarehouseVerificationStatusEnums,
} from "enums/shared";
import { CountryCollectionEnums } from "enums/countryCollectionEnums";
import { AgentCompanyCollectionEnums } from "enums/agentCompanyCollectionEnums";

export class AgentWarehouseAgentCompanyModel {
  object: AgentCompanyCollectionEnums.AgentCompany;
  id: number;
  primary_name: string;
  secondary_name: string;
  slug: string;
  operation_status: AgentCompanyOperationStatusEnums;
  verification_status: AgentCompanyVerificationStatusEnums;
  registered_country: number;
  company_type: string;
  director_name: string;
  designation: string;
  business_phone: string;
  years_of_experience: string;
  number_of_people_in_team: string;
  address: IFilterType | null;

  constructor(dataItem: IAgentWarehouseAgentCompany) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.primary_name = dataItem.primary_name;
    this.secondary_name = dataItem.secondary_name;
    this.slug = dataItem.slug;
    this.operation_status = dataItem.operation_status;
    this.verification_status = dataItem.verification_status;
    this.registered_country = dataItem.registered_country;
    this.company_type = dataItem.company_type;
    this.director_name = dataItem.director_name;
    this.designation = dataItem.designation;
    this.business_phone = dataItem.business_phone;
    this.years_of_experience = dataItem.years_of_experience;
    this.number_of_people_in_team = dataItem.number_of_people_in_team;
    this.address = dataItem.address;
  }

  getId() {
    return this.id;
  }
  getPrimaryName() {
    return this.primary_name;
  }
  getSecondaryName() {
    return this.secondary_name;
  }
  getSlug() {
    return this.slug;
  }
  getOperationStatus() {
    return this.operation_status;
  }
  getVerificationStatus() {
    return this.verification_status;
  }
  getRegisteredCountry() {
    return this.registered_country;
  }
  getCompanyType() {
    return this.company_type;
  }
  getDirectorName() {
    return this.director_name;
  }
  getDesignation() {
    return this.designation;
  }
  getBusinessPhone() {
    return this.business_phone;
  }
  getYearsOfExperience() {
    return this.years_of_experience;
  }
  getNumberOfPeopleInTeam() {
    return this.number_of_people_in_team;
  }
  getAddress() {
    return this.address;
  }
}

export class AgentWarehouseCountryModel {
  public readonly object: CountryCollectionEnums.Country;
  public readonly id: number;
  public readonly name: string;
  public readonly code: string;
  public readonly flag: string;
  public readonly status: string;
  public readonly created_at: string;

  constructor(dataItem: IAgentWarehouseCountry) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.code = dataItem.code;
    this.flag = dataItem.flag;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }

  getId() {
    return this.id;
  }
  getName() {
    return this.name;
  }
  getCode() {
    return this.code;
  }
  getFlag() {
    return this.flag;
  }
  getStatus() {
    return this.status;
  }
  getCreatedAt() {
    return this.created_at;
  }
}

export class AgentWarehouseModel {
  public readonly object: AgentWarehouseCollectionEnums.AgentWarehouse;
  public readonly id: number;
  public readonly name: string;
  public readonly shipping_mark: string;
  public readonly operation_status: AgentWarehouseOperationStatusEnums;
  public readonly verification_status: AgentWarehouseVerificationStatusEnums;
  public readonly contact_person: string;
  public readonly contact_number: string;
  public readonly instruction: string;
  public readonly country: AgentWarehouseCountryModel;
  public readonly agent_company: AgentWarehouseAgentCompanyModel;

  constructor(dataItem: IAgentWarehouse) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.shipping_mark = dataItem.shipping_mark;
    this.operation_status = dataItem.operation_status;
    this.verification_status = dataItem.verification_status;
    this.contact_person = dataItem.contact_person;
    this.contact_number = dataItem.contact_number;
    this.instruction = dataItem.instruction;
    this.country = new AgentWarehouseCountryModel(dataItem.country);
    this.agent_company = new AgentWarehouseAgentCompanyModel(
      dataItem.agent_company,
    );
  }

  getId() {
    return this.id;
  }
  getName() {
    return this.name;
  }
  getShippingMark() {
    return this.shipping_mark;
  }
  getOperationStatus() {
    return this.operation_status;
  }
  getVerificationStatus() {
    return this.verification_status;
  }
  getContactPerson() {
    return this.contact_person;
  }
  getContactNumber() {
    return this.contact_number;
  }
  getInstruction() {
    return this.instruction;
  }
  getCountry() {
    return this.country;
  }
  getAgentCompany() {
    return this.agent_company;
  }
}

export class AgentWarehouseCollectionModel {
  public readonly object: AgentWarehouseCollectionEnums.AgentWarehouseCollection;
  public readonly data: AgentWarehouseModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IAgentWarehouseCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AgentWarehouseModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  getAgentWarehouseById(id: number): AgentWarehouseModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getWarehouseIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }

  getWarehouseLabeledValue() {
    return this.data.map((item) => ({
      label: item.getName(),
      value: item.getId(),
    }));
  }
  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
}
