import {
  ExchangeGroupResource,
  ExchangeResource,
  StoreResource,
} from "lib/core/resources/private";

import CommerceApiService from "lib/core/apiServices/commerceApiService";
class StoreService extends CommerceApiService {
  public exchangeResource: ExchangeResource;
  public exchangeGroupResource: ExchangeGroupResource;
  public storeResource: StoreResource;

  constructor() {
    super();

    this.exchangeResource = new ExchangeResource(this.config);
    this.exchangeGroupResource = new ExchangeGroupResource(this.config);
    this.storeResource = new StoreResource(this.config);
  }
}

export default StoreService;
