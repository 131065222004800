import { Config } from "../request/types/request";
import { ServiceBaseUrl, ServiceNameEnum } from "./servicBaseUrl";

class CartApiService {
  public config: Config;

  constructor() {
    this.config = {
      baseUrl: ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.CART_SERVICE),
      apiKey: "",
      maxRetries: 0,
      publishableApiKey: "",
    };
  }
}

export default CartApiService;
