import React, { useEffect, useState } from "react";
import {Form, Input, Button, Row, Col, Select, Card, Checkbox} from "antd";
import { useGetCountry } from "lib/core-react/hooks/private";
import { useAtom } from "jotai";
import {
  addressSchemaAtom,
  buyOrderPlaceAtom,
  regionCountry,
} from "lib/core-react/store/store";
import { CountryCollectionModel } from "models/countryCollectionModel";
import { useCreateAddress } from "lib/core-react/hooks/private/useAddress";
import { useAddressSchemaRetrive } from "lib/core-react/hooks/private/useAddressSchema";
import { AddressSchemaModel } from "models/addressSchemaModel";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {ICreateAddressRequest} from "../../../../../types/buyOrderPlace";
import {showError} from "../../../../../helpers/showError";


const AddressModal: React.FC<{ setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>> }> = ({
                                                                                                        setIsModalVisible,
                                                                                                      }) => {
  const [form] = Form.useForm();
  const { getCountry } = useGetCountry();
  const { createAddress, isLoading } = useCreateAddress();
  const { getAddressSchema } = useAddressSchemaRetrive();
  const [, setIsErrorVisible] = useState(false);

  const [{ data: countryCollectionAtom }] = useAtom(regionCountry);
  const [{ data: addressSchema, isLoading:addressSchemaLoading, error:addressSchemaError }] = useAtom(addressSchemaAtom);
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState({});

  const CountryData =
    countryCollectionAtom && new CountryCollectionModel(countryCollectionAtom);

  const [buyOrderPlaceAtomData] = useAtom(buyOrderPlaceAtom);

  useEffect(() => {
    getCountry("per_page=200");
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [selectedCountryId]);


  const addressSchemaData =
    addressSchema && new AddressSchemaModel(addressSchema);

  const webFull = addressSchemaData?.getData().getWebFull() || {};
  const fields = addressSchemaData?.getData().getFields() || {};

  const handleOnSubmitAddress = async (values) => {
    const userId = buyOrderPlaceAtomData.data?.user?.value;
    const { country, is_default_shipping, is_default_billing, ...rest } = values
    const selectedCountryName = CountryData?.getCountryById(values.country)?.getName() as string;

    const payload: ICreateAddressRequest = {
      user_id: Number(userId),
      country_id: values.country,
      address: {
       ...rest,
        country: selectedCountryName,
      },
      label_ids: [],
      is_default_shipping: values.is_default_shipping,
      is_default_billing: values.is_default_billing,
    };

    try {
      await createAddress(Number(userId), country, payload);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      showError(error, form)
      setIsErrorVisible(true);
    }
  };


  const handleAddressByCountry = async () => {
    try {
      const values = await form.validateFields();
      const countryId = values.countrySelect as number
      setSelectedCountryId(countryId)
      const selectedCountryCode = CountryData?.getCountryById(countryId)?.getCode();
      if (selectedCountryCode) {
        await getAddressSchema(selectedCountryCode, "en");
        setShowAddressForm(true);
      }
      form.setFields([{ name:"country", value:countryId}])
    }catch (_e) {
      setShowAddressForm(false);
    }
  }

  useEffect(()=>{
    if(addressSchemaError){
      form.setFields([
        {
          name: 'countrySelect',
          errors: [addressSchemaError],
        },
      ]);
    }
  },[addressSchemaError])


  const handleParentChange = (parentKey: string, value: string) => {
    const parentField = fields[parentKey];

    if (parentField && parentField.relation) {
      const childKey = parentField.relation.relations.child;

      if (childKey && fields[childKey]) {

        const childOptions = parentField.data?.data[value]?.[childKey] || [];

        setDynamicOptions((prev) => ({
          ...prev,
          [childKey]: childOptions.map((childValue) => {
            const childData = fields[childKey]?.data?.data[childValue];

            if (typeof childData === "string") {
              return {
                label: childData,
                value: childValue,
              };
            } else if (typeof childData === "object" && childData !== null) {
              return {
                label: childData.name,
                value: childValue,
              };
            }
            return {
              label: childValue,
              value: childValue,
            };
          }),
        }));
        form.setFieldsValue({ [childKey]: null });
      }
    }
  };

  const renderField = (fieldKey, fieldSchema) => {
    const { type, place_holder, label, is_required, errors, data_source, data, properties, resource } = fieldSchema;

    let fieldComponent;
    const rules = [
      { required: is_required, message: errors?.on_empty || `${label} is required` },
    ];

    if (type === 'string') {
      fieldComponent = (
        <Form.Item label={label} name={fieldKey} rules={rules}>
          <Input placeholder={place_holder} disabled={!properties.is_editable} />
        </Form.Item>
      );
    } else if (type === 'textarea') {
      fieldComponent = (
        <Form.Item label={label} name={fieldKey} rules={rules}>
          <Input.TextArea placeholder={place_holder} disabled={!properties.is_editable} />
        </Form.Item>
      );
    } else if (type === 'select') {
      const options = dynamicOptions[fieldKey] || (data_source === 'on-premise' ? Object.keys(data?.data || {}).map(x => ({
        label: data?.data[x]?.name,
        value: x,
      })) : []);

      let externalOption:{label:string, value:string | number}[] = []
      if(data_source === "external" ){
        switch (resource) {
          case "country":
            externalOption = CountryData?.getData().map(x=>{
              return {
                label:x.getName(),
                value:x.getId(),
              }
            }) || []
            break;
          default:
            externalOption=[]
        }

        fieldComponent = (
          <Form.Item label={label} name={fieldKey} rules={rules}>
            <Select   filterOption={(input, option) =>
              (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
            }  onChange={(value) => handleParentChange(fieldKey, value)} showSearch={true} placeholder={place_holder} disabled={!properties.is_editable} options={externalOption} />
          </Form.Item>
        )
      }else{

        fieldComponent = (
          <Form.Item label={label} name={fieldKey} rules={rules}>
            <Select   filterOption={(input, option) =>
              (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
            }  onChange={(value) => handleParentChange(fieldKey, value)} showSearch={true} placeholder={place_holder} disabled={!properties.is_editable} options={options} />
          </Form.Item>
        );
      }
    }

    return fieldComponent;
  };

  const renderLines = (lines) => {
    return lines.map((line) => (
      <Row gutter={16} key={line.join('-')}>
        {line.map((fieldKey) => (
          <Col span={12} key={fieldKey}>
            {fields && renderField(fieldKey, fields[fieldKey])}
          </Col>
        ))}
      </Row>
    ));
  };


  return (
    <Card>
      {!showAddressForm ? (
        <Form form={form} layout="vertical" onFinish={handleAddressByCountry}>
          <Form.Item label="Select Country" name="countrySelect" rules={[{ required: true, message: 'Please select a country' }]}>
            <Select
              placeholder="Select a country"
              options={CountryData?.getData().map(x=>{
                return {
                  label: x.getName(),
                  value:x.getId()
                }
              })}
            />

          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={addressSchemaLoading}
            disabled={addressSchemaLoading}
            icon={<RightOutlined />}
          >
            Next
          </Button>
        </Form>
      ) : (
        <Form form={form} onFinish={handleOnSubmitAddress} layout="vertical">
          {webFull && Object.keys(webFull).map((lineKey) => renderLines([webFull[lineKey]]))}
          <Row>
          <Col>
            <Form.Item name="is_default_shipping" valuePropName="checked" initialValue={false}>
              <Checkbox>Set as default shipping address</Checkbox>
            </Form.Item>
            <Form.Item name="is_default_billing" valuePropName="checked" initialValue={false}>
              <Checkbox>Set as default billing address</Checkbox>
            </Form.Item>
          </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Button
                onClick={() => setShowAddressForm(false)}
                icon={<LeftOutlined />}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default AddressModal;
