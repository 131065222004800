import { EyeFilled } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Table, Tag, notification } from "antd";
import { useWinnerWarehouse } from "lib/core-react/hooks/private/useShipping";
import { ShipmentProductBidParticipantModel } from "models/shipmentProductBidCollectionModel";
import { useState } from "react";
import { BidCollectionDataType } from "types/shipmentProductBidCollection";
import BidOffersTable from "./BidsOffersTable";
const { Column } = Table;
interface IProps {
  allDataRecord: BidCollectionDataType;
  bidId: number;
}
const ParticipantsTable = ({ allDataRecord, bidId }: IProps) => {
  const { winnerWarehouse } = useWinnerWarehouse();
  const [showOffers, setShowOffers] = useState(false);
  const [selectedData, setSelectedData] = useState<
    ShipmentProductBidParticipantModel | undefined
  >();

  const winnerHandler = async (warehouseId: number) => {
    try {
      const res = await winnerWarehouse(bidId, {
        agent_warehouse_id: warehouseId,
      });
      notification["success"]({
        message: res.message,
      });
      // onCancel();
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };
  return (
    <>
      <Table
        dataSource={allDataRecord.participants}
        pagination={false}
        rowKey="id"
      >
        <Column
          title="Warehouse Details"
          dataIndex="product_details"
          key="product_details"
          className="vertical-align-top"
          render={(_: string, record: ShipmentProductBidParticipantModel) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Name:</b> <span>{record.getAgentWarehouse().getName()}</span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Shipping mark:</b>
                <span>{record.getAgentWarehouse().getShippingMark()}</span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Operation status:</b>
                <span>{record.getAgentWarehouse().getOperationStatus()}</span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Verification status:</b>
                <span>
                  {record.getAgentWarehouse().getVerificationStatus()}
                </span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Contact person:</b>
                <span>{record.getAgentWarehouse().getContactPerson()}</span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Contact number:</b>
                <span>{record.getAgentWarehouse().getContactNumber()}</span>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <b>Instruction:</b>
                <span>{record.getAgentWarehouse().getInstruction()}</span>
              </div>
            </div>
          )}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="vertical-align-top"
          render={(_: string, record: ShipmentProductBidParticipantModel) => (
            <div style={{ textTransform: "capitalize" }}>
              <Tag color="#87d068">{record.getParticipantStatus()}</Tag>
            </div>
          )}
        />
        <Column
          title="Offers"
          dataIndex="offers"
          key="offer"
          className="vertical-align-top"
          render={(_: string, record: ShipmentProductBidParticipantModel) => (
            <div>
              <Button
                onClick={() => {
                  setShowOffers(true);
                  setSelectedData(record);
                }}
                shape="round"
                title={`${
                  record.getOffers() && record.getOffers()?.getData().length
                    ? "Show Offers"
                    : "No offers available yet"
                }`}
                icon={<EyeFilled />}
                style={{ marginRight: "8px" }}
                disabled={
                  record.getOffers() &&
                  record.getOffers()?.getData().length === 0
                    ? true
                    : false
                }
              />
            </div>
          )}
        />
        <Column
          title="Actions"
          dataIndex="actions"
          key="actions"
          className="vertical-align-top"
          render={(_: string, record: ShipmentProductBidParticipantModel) => (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() =>
                winnerHandler(Number(record.getAgentWarehouse().getId()))
              }
            >
              <Button disabled={record.getParticipantStatus() !== "interested"}>
                Set Winner
              </Button>
            </Popconfirm>
          )}
        />
      </Table>

      {selectedData?.getOffers() && (
        <Modal
          title={`Offers by ${selectedData?.getAgentWarehouse().getName()}`}
          open={showOffers}
          destroyOnClose={true}
          width="900px"
          footer={false}
          onCancel={() => setShowOffers(false)}
        >
          {selectedData && (
            <BidOffersTable data={selectedData.getOffers()!.getData()} />
          )}
        </Modal>
      )}
    </>
  );
};

export default ParticipantsTable;
