import { Form } from "antd";
import { MultiChunkUpload } from "components/MultiChunkUpload";

interface IProps {
  form: any;
  onOk: () => void;
}

export const AttachCartonImageModal = ({ form, onOk }: IProps) => {
  return (
    <Form form={form} name="form" onFinish={onOk}>
      <MultiChunkUpload fieldName="images" form={form} />
    </Form>
  );
};
