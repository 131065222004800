// Enum for admin permissions in the Address module
export enum ADMIN_ADDRESS_PERMISSION_ENUM {
    // Addresses permissions
    ADMIN_ADDRESS_VIEW = "admin-address-view",
    ADMIN_ADDRESS_ADD = "admin-address-add",
    ADMIN_ADDRESS_UPDATE = "admin-address-update",
    ADMIN_ADDRESS_DELETE = "admin-address-delete",
    ADMIN_ADDRESS_SYNC = "admin-address-sync",

    // Dw-Addresses permissions
    ADMIN_DW_ADDRESS_ADD = "admin-dw-address-add",
    ADMIN_DW_ADDRESS_UPDATE = "admin-dw-address-update"
}


