import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { storeStoreAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { MenuInfo } from "rc-menu/lib/interface";
import { PaginationModel } from "models/pagination";
import {
  DownOutlined,
  EditTwoTone,
  HddFilled,
  SyncOutlined,
} from "@ant-design/icons";
import { IFilterType } from "types/filters";
import FiltersComponent from "components/FiltersComponent";
import { getError } from "lib/core-react/hooks/utils/errors";
import { StoreModification } from "./components";
import {
  useGetStore,
  useStoreCreate,
  useStoreSync,
  useStoreUpdate,
} from "lib/core-react/hooks/private/useStore";
import { StoreCollectionModel, StoreModel } from "models/storeCollectionModel";
import { IStoreFormData } from "types/storeCollection";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_STORE_PERMISSION_ENUM } from "consts/permission-enum/admin-store-enum";
import CommonError from "components/Error/CommonError";

const StoreList = () => {
  const { getStore } = useGetStore();
  // const { deleteStore } = useStoreDelete();
  const { syncStore, isLoading: isStoreSyncLoading } = useStoreSync();
  const [
    {
      data: storeCollectionData,
      isLoading,
      refetch,
      error: storeCollectionError,
      unAuthorized,
    },
  ] = useAtom(storeStoreAtom);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getStore);
    }
  }, [isFirstCall, isFetched, refetch]);

  const StoreCollectionData =
    storeCollectionData && new StoreCollectionModel(storeCollectionData);
  // Filter
  const filterData = StoreCollectionData?.getFilters();
  // Pagination
  const paginationData = StoreCollectionData?.getPagination();

  const [selectedStore, setSelectedStore] = useState<StoreModel | undefined>();
  const [modalOpen, setModalOpen] = useState<"create" | "update" | undefined>();
  const { isMobile } = useWindowWidth();

  const handleMenuClick = (e: MenuInfo, record: StoreModel) => {
    setSelectedStore(record);

    if (e.key === "update") {
      setModalOpen("update");
    }
  };

  // const handleDeleteStore = async (id: number) => {
  //   try {
  //     await deleteStore(id);
  //     message.success("Store deleted.");
  //   } catch (error) {
  //     message.error(getError(error));
  //   }
  // };

  const handleSync = async () => {
    try {
      await syncStore();
      await getStore();
    } catch (error) {
      message.error(getError(error));
    }
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && StoreCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, StoreCollectionData?.getFilters()]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getStore,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    StoreCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getStore);
  };

  const { createStore, isLoading: isLoadingCreate } = useStoreCreate();
  const { updateStore, isLoading: isLoadingUpdate } = useStoreUpdate();

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      const payload: IStoreFormData = {
        name: values.name,
        identifier: values.identifier,
        availability_status: values.availability_status,
      };

      if (selectedStore) {
        await updateStore(selectedStore.getId(), payload);
        setSelectedStore(undefined);
      } else {
        await createStore(payload);
      }
      setModalOpen(undefined);
    } catch (error) {
      message.error(getError(error));
    }
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: StoreModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: StoreModel) => (
        <>
          <Typography.Text>{record.getName()}</Typography.Text>{" "}
          <Tag
            color={
              record.getAvailabilityStatus() === "public" ? "success" : "error"
            }
          >
            {record.getAvailabilityStatus()}
          </Tag>
        </>
      ),
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      key: "identifier",
      render: (_: string, record: StoreModel) => (
        <Typography.Text>{record.getIdentifier()}</Typography.Text>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      key: "created_at",
      render: (_: string, record: StoreModel) => (
        <Tag color="purple">{record.getCreatedAt()}</Tag>
      ),
    },
    {
      title: "Actions",
      fixed: isMobile ? "right" : undefined,
      width: isMobile ? 100 : 150,
      key: "actions",
      render: (_: string, record: StoreModel) => {
        const menuItems: ExtendedMenuItemType[] = [
          {
            permission: ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_UPDATE,
            label: "Update",
            key: "update",
            icon: <EditTwoTone />,
            onClick: (e) => handleMenuClick(e, record),
          },
          // {
          //   icon: <DeleteTwoTone />,
          //   label: (
          //     <Popconfirm
          //       title="Delete this store?"
          //       onConfirm={() => handleDeleteStore(record.getId())}
          //       okButtonProps={{
          //         loading: isLoadingDelete,
          //         disabled:isLoadingDelete,
          //       }}
          //       okText="Delete"
          //       okType="danger"
          //       cancelText="No"
          //     >
          //       <span onClick={(e) => e.stopPropagation()}>Delete</span>
          //     </Popconfirm>
          //   ),
          //   key: "delete",
          // },
        ];
        if (isMobile) {
          menuItems.push({
            permission: ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_VIEW,
            label: <Tag color="purple">{record.getCreatedAt()}</Tag>,
            key: "created_at",
            disabled: true,
          });
        }

        return (
          <Dropdown
            menu={{
              items: menuItems.filter((x) =>
                checkActionPermission(x.permission, x, null),
              ),
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  {" "}
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  // Error handling
  if (storeCollectionError) {
    return (
      <CommonError unAuthorized={unAuthorized} message={storeCollectionError} />
    );
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Store List"
        style={{ marginTop: "10px" }}
        extra={[
          checkActionPermission(
            ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_SYNC,
            <Button
              key="1"
              type="default"
              onClick={() => handleSync()}
              disabled={isStoreSyncLoading}
              loading={isStoreSyncLoading}
              icon={<SyncOutlined />}
            >
              Sync
            </Button>,
            null,
          ),

          checkActionPermission(
            ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_ADD,
            <Button
              key="2"
              type="primary"
              onClick={() => setModalOpen("create")}
            >
              Add Store
            </Button>,
            null,
          ),
        ]}
      >
        {filters && Object.keys(filters).length > 0 && (
          <Row>
            <Col span={24}>
              <Card title="Filter">
                <FiltersComponent
                  handleProductFilter={handleProductFilter}
                  handleFilterChange={handleFilterChange}
                  handelFilterClear={handelFilterClear}
                  isFetched={isFetched}
                  filters={filters}
                  filtersData={filterData}
                  isFromProductReceived={true}
                />
              </Card>
            </Col>
          </Row>
        )}
        <div>
          <Table
            loading={isLoading}
            rowKey="id"
            bordered={true}
            dataSource={StoreCollectionData?.getData()}
            //@ts-ignore*
            columns={columns}
            style={{ marginTop: "10px" }}
            pagination={paginationConfig}
            scroll={{ x: 1000 }}
          />
        </div>
      </PageHeader>

      <Modal
        title={`${selectedStore ? "Update" : "Add"} Store`}
        open={Boolean(modalOpen)}
        onCancel={() => {
          setModalOpen(undefined);
          setSelectedStore(undefined);
        }}
        onOk={form.submit}
        okText={selectedStore ? "Update" : "Create"}
        okButtonProps={{ loading: isLoadingCreate || isLoadingUpdate }}
        centered
        destroyOnClose={true}
      >
        <StoreModification
          data={selectedStore}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
};
export default StoreList;
