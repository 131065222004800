import { IFilterType } from "types/filters";
import { PayoutGatewayModel } from "./payoutGatewayCollectionModel";
import {
  IAgentCompany,
  IPayoutRequest,
  IPayoutRequestCollection,
  IPayoutRequestPayoutAccount,
} from "types/payoutRequestCollection";
import { CommonUserModel } from "./shared";
import { PaginationModel } from "./paginationModel";

export class PayoutRequestPayoutAccountModel {
  object: string;
  id: number;
  account_data: IFilterType;
  ownable_type: string;
  ownable_id: number;
  payout_gateway: PayoutGatewayModel;
  created_at: string;

  constructor(data: IPayoutRequestPayoutAccount) {
    this.object = data.object;
    this.id = data.id;
    this.account_data = data.account_data;
    this.ownable_type = data.ownable_type;
    this.ownable_id = data.ownable_id;
    this.payout_gateway = new PayoutGatewayModel(data.payout_gateway);
    this.created_at = data.created_at;
  }

  getId = () => this.id;
  getAccountData = () => this.account_data;
  getOwnableType = () => this.ownable_type;
  getOwnableId = () => this.ownable_id;
  getPayoutGateway = () => this.payout_gateway;
  getCreatedAt = () => this.created_at;
}

export class AgentCompanyModel {
  object: string;
  id: number;
  name: string;
  email: string | null;
  type: string | null;
  shipping_mark: string | null;
  created_at: string;

  constructor(data: IAgentCompany) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.type = data.type;
    this.shipping_mark = data.shipping_mark;
    this.created_at = data.created_at;
  }

  getObject = () => this.object;
  getId = () => this.id;
  getName = () => this.name;
  getEmail = () => this.email;
  getType = () => this.type;
  getShippingMark = () => this.shipping_mark;
  getCreatedAt = () => this.created_at;
}

export class PayoutRequestModel {
  object: string;
  id: number;
  request_number: string;
  requested_amount: number;
  approved_amount: number | null;
  status: string;
  customer_note: string | null;
  admin_note: string | null;
  ownable_type: string;
  ownable_id: number;
  customer?: CommonUserModel;
  agent_company?: AgentCompanyModel;
  payout_account: PayoutRequestPayoutAccountModel;
  created_at: string;

  constructor(data: IPayoutRequest) {
    this.object = data.object;
    this.id = data.id;
    this.request_number = data.request_number;
    this.requested_amount = data.requested_amount;
    this.approved_amount = data.approved_amount;
    this.status = data.status;
    this.customer_note = data.customer_note;
    this.admin_note = data.admin_note;
    this.ownable_type = data.ownable_type;
    this.ownable_id = data.ownable_id;
    this.customer = data.customer
      ? new CommonUserModel(data.customer)
      : undefined;
    this.agent_company = data.agent_company
      ? new AgentCompanyModel(data.agent_company)
      : undefined;
    this.payout_account = new PayoutRequestPayoutAccountModel(
      data.payout_account,
    );
    this.created_at = data.created_at;
  }

  getId = () => this.id;
  getRequestNumber = () => this.request_number;
  getRequestedAmount = () => this.requested_amount;
  getApprovedAmount = () => this.approved_amount;
  getStatus = () => this.status;
  getCustomerNote = () => this.customer_note;
  getAdminNote = () => this.admin_note;
  getOwnableId = () => this.ownable_id;
  getCustomer = () => this.customer;
  getAgent = () => this.agent_company;
  getPayoutAccount = () => this.payout_account;
  getCreatedAt = () => this.created_at;
}

export class PayoutRequestCollectionModel {
  object: string;
  data: PayoutRequestModel[];
  pagination: PaginationModel;
  filters: IFilterType;
  constructor(data: IPayoutRequestCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new PayoutRequestModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }

  getData() {
    return this.data;
  }
  getPagination() {
    return this.pagination;
  }
  getFilters = () => this.filters;
  getPayoutRequestById(id: number): PayoutRequestModel | undefined {
    return this.data.find((item) => item.id === id);
  }
}
