import React, {ReactNode} from 'react';
import { Navigate,  } from 'react-router-dom';
import {
  getUserFromLocalStorage,
  getUserPermissionFromLocalStorage,
  getUserTokenFromLocalStorage
} from "../../utils/helper";
import { SUPER_ADMIN } from 'consts/storage';

const AuthenticatedRoute:React.FC<{permissions:string[], children?: ReactNode}> = ({ permissions = [],children }) => {
 const token  = getUserTokenFromLocalStorage()
  const userPermissions = getUserPermissionFromLocalStorage()
  const user = getUserFromLocalStorage()



  const isSuperAdmin =  user && user.roles?.data ?  user.roles.data.find(role => role.name === SUPER_ADMIN) : undefined

  const hasPermission = () => {
    return permissions.some(permission => userPermissions.includes(permission));
  };



     // if user super admin no need to check permission
     if(isSuperAdmin){
        return <>{children}</>;
     }


  if (!hasPermission()) {
    return <Navigate to="/403" />;
  }else if (!token) {
    return <Navigate to="/login" />;
  }else{
    return <>{children}</>;
  }
};

export default AuthenticatedRoute;
