import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";
import {
  IRole,
  IRoleCollection,
  IUser,
  IUserCollection,
} from "types/userCollection";

export class RoleCollectionModel {
  public readonly object: string;
  public readonly data: RoleModel[];

  constructor(data: IRoleCollection) {
    this.object = data.object;
    this.data = data.data.map((role) => new RoleModel(role));
  }

  getAllRoleNames() {
    return this.data.map((role) => role.getName());
  }

  getAllRoleIds(): number[] {
    return this.data.map((role) => role.getId());
  }
}

export class RoleModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly label: string | null;
  public readonly access: string;

  constructor(data: IRole) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.label = data.label;
    this.access = data.access;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getLabel() {
    return this.label;
  }

  getAccess() {
    return this.access;
  }
}
export class UserModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly phone: string | null;
  public readonly email: string;
  public readonly emailVerifiedAt: string | null;
  public readonly type: string;
  public readonly is_active: number;
  public readonly roles?: RoleCollectionModel;
  public readonly created_at: string;

  constructor(data: IUser) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.emailVerifiedAt = data.emailVerifiedAt;
    this.type = data.type;
    this.is_active = data.is_active;
    this.roles = data.roles ? new RoleCollectionModel(data.roles) : undefined;
    this.created_at = data.created_at;
  }

  getAllRoleIds() {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getPhone(): string | null {
    return this.phone;
  }

  getEmail(): string {
    return this.email;
  }

  getEmailVerifiedAt(): string | null {
    return this.emailVerifiedAt;
  }

  getType(): string {
    return this.type;
  }

  getIsActive(): number {
    return this.is_active;
  }

  getCreatedAt(): string {
    return this.created_at;
  }
}

export class UserCollectionModel {
  public readonly object: string;
  public readonly data: UserModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IUserCollection) {
    this.object = data.object;
    this.data = data.data.map((user) => new UserModel(user));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
}
