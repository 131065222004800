// Enum for admin permissions in the Discount module
export enum ADMIN_DISCOUNT_PERMISSION_ENUM {
  // Discount permissions
  ADMIN_DISCOUNT_VIEW = "admin-discount-view",
  ADMIN_DISCOUNT_CREATE = "admin-discount-create",
  ADMIN_DISCOUNT_UPDATE = "admin-discount-update",
  ADMIN_DISCOUNT_DELETE = "admin-discount-delete",

  // Discount Group permissions
  ADMIN_DISCOUNT_GROUP_CREATE = "admin-discount-group-create",
  ADMIN_DISCOUNT_GROUP_USER_ADD = "admin-discount-group-user-add",
  ADMIN_DISCOUNT_GROUP_USER_REMOVE = "admin-discount-group-user-remove",

  // Discount usage history permissions
  ADMIN_DISCOUNT_USAGE_HISTORY_VIEW = "admin-discount-usage-history-view"
}

