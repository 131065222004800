import {Typography} from "antd";
import { BuyProductDetailModel } from "models/buyOrderDetailModel";
import {StatusTag} from "../../../../../components";

const ProductDetailInfo = ({
  productInfo,
}: {
  productInfo: BuyProductDetailModel;
}) => {
  const { Text, Link } = Typography;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <Text strong>Title: </Text>
        <Link
          href={productInfo.getProductLink()}
          target={"_blank"}
          rel="noreferrer"
        >
          {productInfo?.getProductTitle()?.substring(0, 80) + " ..."}
        </Link>
      </div>

      <div>
        <Text strong>Product ID:</Text>{" "}
        <Text copyable>{productInfo.getProductId()}</Text>
      </div>
      <div>
        <Text strong>Shipping provider: </Text>{" "}
        <Text>{productInfo.getShippingProvider()}</Text>
      </div>
      <div>
        <Text strong>Store: </Text>
        <Text>{productInfo.getStoreName()}</Text>
      </div>

      <div>
        <Text strong>Total quantity: </Text>
        <Text>{productInfo.getTotalQuantity()}</Text>
      </div>
      <div>
        <Text strong>Status: </Text>
       <StatusTag text={productInfo.getStatus()} slug={productInfo.getStatus()}/>
      </div>
    </div>
  );
};

export default ProductDetailInfo;
