import type { DescriptionsProps } from "antd";
import { Card, Descriptions } from "antd";

export const AgentAssociation = ({ isLoading, productData, title }) => {
  const agentAssociation = productData?.getProductAgentAssociation();
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Commission Rate type",
      children: agentAssociation?.getCommissionRateType(),
    },
    {
      key: "2",
      label: "Commission Rate",
      children: agentAssociation?.getCommissionRate(),
    },
    {
      key: "3",
      label: "Fx Rate",
      children: agentAssociation?.getFxRate() || "none",
    },
    {
      key: "4",
      label: "Tax",
      children: agentAssociation?.getIncludeTax() || "none",
    },
    {
      key: "5",
      label: "Tax Total",
      children: agentAssociation?.getTaxTotal() || "none",
    },
    {
      key: "6",
      label: "Sub Total",
      children: agentAssociation?.getSubTotal() || "none",
    },
    {
      key: "7",
      label: "Total",
      children: agentAssociation?.getTotal() || "none",
    },
    {
      key: "8",
      label: "Original Sub Total",
      children: agentAssociation?.getTotal() || "none",
    },
    {
      key: "9",
      label: "Original Total",
      children: agentAssociation?.getOriginalTotal() || "none",
    },
    {
      key: "10",
      label: "Discount Total",
      children: agentAssociation?.getDiscountTotal() || "none",
    },
  ];
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {agentAssociation ? (
        <Descriptions bordered size="small" layout="vertical" items={items} />
      ) : (
        "Agent association not available yet."
      )}
    </Card>
  );
};
