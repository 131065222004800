import {
  CloseCircleTwoTone,
  DownOutlined,
  EditTwoTone,
  HddFilled,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Modal,
  Popconfirm,
  Table,
  TableColumnsType,
  Tag,
  notification,
} from "antd";
import { useAtom, useAtomValue } from "jotai";
import {
  useAbandonBid,
  useGetAllBids,
} from "lib/core-react/hooks/private/useShipping";
import {
  bidCollectionAtom,
  shipmentProductsAtom,
} from "lib/core-react/store/store";
import { ShipmentProductBidCollectionModel } from "models/shipmentProductBidCollectionModel";
import { useEffect, useState } from "react";
import { BidCollectionDataType } from "types/shipmentProductBidCollection";
import AgentAssignWithBidManage from "pages/Shipping/components/AgentAssignWithBidManage";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";
import {
  ShipmentProductCollectionModel,
  ShipmentProductModel,
} from "models/shipmentProductCollectionModel";
import ParticipantsTable from "pages/Shipping/ShipmentProducts/components/ParticipantsTable";
import { ExtendedMenuItemType } from "types";
import { ROLE_PERMISSION_TEST_ENUM } from "consts/parmissionEnum";
import checkActionPermission from "components/Authorized/CheckPermissions";

interface IProps {
  product: ShipmentProductModel;
}

export const BidList = ({ product }: IProps) => {
  const { data: shipmentProductCollectionData } =
    useAtomValue(shipmentProductsAtom);

  const shipmentProductCollection =
    shipmentProductCollectionData &&
    new ShipmentProductCollectionModel(shipmentProductCollectionData);

  const { getAllBids } = useGetAllBids();
  const { abandonBid, isLoading: isLoadingAbandon } = useAbandonBid();
  const [{ data: bidData, isLoading }] = useAtom(bidCollectionAtom);

  useEffect(() => {
    getAllBids(product.getId());
    
  }, [product]);

  const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
    ICategoryPriceDetails | undefined
  >(undefined);

  const [selectedBidId, setSelectedBidId] = useState<number | undefined>(
    undefined,
  );
  const [openUpdateBidModal, setOpenUpdateBidModal] = useState<boolean>(false);

  const bidAbandonOkHandler = async (id: number) => {
    try {
      const res = await abandonBid(id);
      notification["success"]({
        message: res.message,
      });
      getAllBids(product.getId());
      // onCancel();
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  const showUpdateBidModalHandler = (bidId: number) => {
    setSelectedBidId(bidId);
    setOpenUpdateBidModal(true);
    if (shipmentProductCollection) {
      const categoryDetails: ICategoryPriceDetails = {
        user_id: product.getUserId(),
        region: product.getRegionCode(),
        shipping_category_id: product.getShippingCategoryId(),
        destination_warehouse_id: product.getDestinationWarehouseId(),
        shipping_type: product.getShippingType(),
        shipping_mode: product.getShippingMode(),
        shipping_country_id: product.getShippingCountryId(),
      };
      setCategoryPriceDetailsInfo(categoryDetails);
    }
  };

  // shipment Order Collection
  const bidCollectionData =
    bidData && new ShipmentProductBidCollectionModel(bidData);

  const columns: TableColumnsType<BidCollectionDataType> = [
    {
      title: "Status",
      key: "status",
      render: (_: string, record: BidCollectionDataType) => (
        <div>
          <div>
            <Tag color={`${record.status === "abandoned" ? "red" : "success"}`}>
              {record.status}
            </Tag>
          </div>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_: string, record: BidCollectionDataType) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <EditTwoTone />,
            label: "Update",
            key: "update",
            onClick: () => showUpdateBidModalHandler(Number(record.key)),
          },
          {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <CloseCircleTwoTone />,
            label: (
              <Popconfirm
                title="Abandon Bid"
                description="Are you sure you want to abandon this bid?"
                onConfirm={() => bidAbandonOkHandler(Number(record.key))}
                okButtonProps={{
                  loading: isLoadingAbandon,
                }}
                okText="Yes"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>Abandon</span>
              </Popconfirm>
            ),
            key: "abandon",
            danger: true,
          },
        ];
        return (
          <Dropdown menu={{
            items: items.filter((x) =>
              checkActionPermission(x.permission, x, null),
            ),
          }}>
            <Button icon={<HddFilled />}>
              Actions
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  const expandedRowRender = (allRecord: BidCollectionDataType) => {
    return (
      <>
        <ParticipantsTable
          bidId={Number(allRecord.key)}
          allDataRecord={allRecord}
        />
      </>
    );
  };

  const data: BidCollectionDataType[] = bidCollectionData
    ? bidCollectionData.getBidCollectionTableData()
    : [];

  return (
    <>
      <Table
        style={{ verticalAlign: "top" }}
        dataSource={data}
        rowKey="key"
        columns={columns}
        loading={isLoading}
        expandable={{ expandedRowRender }}
      />

      <Modal
        title="Warehouse List"
        open={openUpdateBidModal}
        destroyOnClose={true}
        width="900px"
        footer={false}
        onCancel={() => setOpenUpdateBidModal(false)}
      >
        {categoryPriceDetailsInfo && (
          <AgentAssignWithBidManage
            mode="update"
            bidId={selectedBidId}
            onCancel={() => setOpenUpdateBidModal(false)}
            productId={product.getId()}
            agentCategoryPriceRead={categoryPriceDetailsInfo}
          />
        )}
      </Modal>
    </>
  );
};
