import { Divider, Typography } from "antd";
import { StyledProductView } from "./index.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { useLocation } from "react-router-dom";
import ProductSpecification from "pages/ProductManage/ProductDetail/ProductView/ProductSpecification";
import { ProductDetailsVariations } from "../ProductDetailCampaign/ProductDetailsVariations";


type Props = {
  product: ProductDetailExtendedModel;
};
const ProductView = ({ product }: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  return (
    <StyledProductView>
      {product.getVariation().getSkus().getData().length > 0 ? (
        
        <ProductDetailsVariations
        store={product.getStore()}
        variations={product.getVariation()}
    />
      ) : (
        <Typography.Text type="warning">
          Variations not available for this product
        </Typography.Text>
      )}
     <Divider style={{ marginTop: 15, marginBottom: 15 }} />
     { operation === BuyOrderOperationEnum.PlaceBuyOrder  ? ""  : <ProductSpecification productSpec={product.getSpecifications() || []} />}

    </StyledProductView>
  );
};

export default ProductView;
