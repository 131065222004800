import axios from "axios";
import { BaseResource } from "../public";
import setAndGetGuestData from "helpers/setAndGetGuestData";
import {
    ServiceBaseUrl,
    ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";



class ProductResource extends BaseResource {
    //Product List
    async getProductList(
        params: Record<string, any>,
        payload?: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): Promise<any> {
        const endpoint = `${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.PRODUCT_SERVICE)}/api/product/admin/v1/products/search${this.addQuery(params)}`;
        try {
            const response = await axios.post(endpoint, payload, {
                headers: {
                    ...customHeaders,
                    "Content-Type": "application/json",
                    "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
                    "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
                },
            });

            return response;
        } catch (error) {
            console.error("Error occurred while fetching product data:", error);
            throw error;
        }
    }

    //Product Details
    async getProductDetails(
        params: Record<string, any>,
        productId: string,
    ): Promise<any> {
        const path = `/api/product/admin/v1/products/find/${productId}${this.addQuery(params)}`;
        try {
            const response = await this.axiosClient.get(path, params);

            return response;
        } catch (error) {
            throw error;
        }
    }

    async getProductDetailById(
        params: Record<string, any>,
        productId: string,
    ): Promise<any> {        
        if (!productId) {
            throw new Error('productId is required')
        }
        const path = `/api/product/admin/v1/products/find/${productId}`;
        try {
            const response = await this.axiosClient.get(path, params);

            return response;
        } catch (error) {
            throw error;
        }
    }

    //Product description
    async getDescriptionProduct({
        params,
        productId,
    }: {
        params: Record<string, any>;
        productId: string;
    }): Promise<any> {
        const path = `/api/product/admin/v1/products/find/${productId}/description`;
        try {
            const response = await this.axiosClient.get(path, params);
            return response;
        } catch (error) {
            return undefined
            // throw error;
        }
    }
}

export default ProductResource;
