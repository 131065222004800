import qs, { ParsedQs } from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import MultiCheckedCell from "../MultiCheckedCell";

interface IProps {
  shopsField: FacetFieldModel[];
}

const ShopsFacet = ({ shopsField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { shops, ...rest } = parsedQuery;
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectShops = async (name: string & ParsedQs) => {
    let updatedShops;

    if (Array.isArray(shops)) {
      if (shops.includes(name)) {
        // Remove the shop if it already exists
        // @ts-ignore FIXME
        // updatedShops = shops.filter((shop) => shop !== name);
      } else {
        // Add the shop if it doesn't exist
        updatedShops = [...shops, name];
      }
    } else {
      // If shop array does not exist, create it with the current shopName
      updatedShops = [name];
    }

    const queryString = qs.stringify({
      ...rest,
      shops: updatedShops,
    });
    await getProductDetailsCollection(
      {
        region: "BD",
        locale: "en",
      },
      { shops: updatedShops, ...rest }
    );
    navigate(`/product-manage/products?${queryString}`);
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Shops</StyledProductSidebarItemTitle>
      <AppList
        data={shopsField}
        renderItem={(data) => (
          <MultiCheckedCell
            key={data.getName()}
            data={data}
            onChange={onSelectShops}
            selected={Array.isArray(shops) ? shops : []}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default ShopsFacet;
