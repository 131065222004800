import { EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";

import { ISourceDestinationProps } from "types/shipmentProductCollection";
import { ChangeDestinationWarehouseModal, ChangeShippingCountryModal } from ".";
import { Skeleton } from "antd/lib";
import useWindowWidth from "../../../../../lib/core-react/hooks/public/useWindowWidth";

export const SourceDestination = ({
  isLoading,
  title,
  ShipmentProductData,
  changeDestinationWarehouseDetails,
  changeDestinationWarehouseHandler,
  changeShippingCountryDetails,
  changeShippingCountryHandler,
  setChangeDestinationWarehouseDetails,
  setChangeShippingCountryDetails,
}: ISourceDestinationProps) => {
  const {width } = useWindowWidth()

  const isEditable = ShipmentProductData?.getStatus() === "shipment-pending"


  return (
    <>
      <Card title={title} bordered={false} loading={isLoading}>
        <Row justify="center" align="middle" gutter={16}>
          <Col xs={24} sm={10}>
            <Card
              bordered
              style={{ flex: 1 }}
            >
              <strong style={{ textAlign: "center", display:"block", marginBottom:"10px" }}>Source</strong>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >

                <img
                  src={ShipmentProductData?.getShippingCountry()
                    ?.getCountry()
                    .getFlag()}
                  style={{ width: 18, height: 18 }}
                  alt=""
                />
                {ShipmentProductData?.getShippingCountry()
                  ?.getCountry()
                  .getName()}
                { isEditable &&  <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() =>
                    changeShippingCountryHandler(
                      [ShipmentProductData!.getId()],
                      ShipmentProductData!.getShippingCountry(),
                    )
                  }
                >
                  <EditOutlined />
                </Button>

                }
              </div>
            </Card>
          </Col>
          <Col style={{textAlign:"center", margin:"5px 0px "}} xs={24} sm={4}>
            <SendOutlined rotate={ width < 572? 90 :0} />
          </Col>
          <Col xs={24} sm={10}>
            <Card
              bordered
              style={{ flex: 1 }}
            >
              <strong style={{ textAlign: "center", display:"block", marginBottom:"10px" }}>Destination</strong>

              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 5,
                    fontWeight: 500,
                    whiteSpace: "nowrap",

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {ShipmentProductData && ShipmentProductData.getDestinationCountry() ?
                      <>
                        <img
                          src={ShipmentProductData.getDestinationCountry()?.getFlag()}
                          style={{ width: 18, height: 18 }}
                          alt="flag"
                        />
                        {ShipmentProductData.getDestinationCountry()?.getName()}
                      </> : <Skeleton style={{ width: 18, height: 18 }} />
                    }

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    ({ShipmentProductData ? ShipmentProductData.getDestinationWarehouse()?.getName() : ""})

                    {isEditable && <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() =>
                        changeDestinationWarehouseHandler(
                          [ShipmentProductData!.getId()],
                          ShipmentProductData && ShipmentProductData.getDestinationWarehouse() ? ShipmentProductData.getDestinationWarehouse() : undefined,
                        )
                      }
                    >
                      <EditOutlined />
                    </Button>
                    }
                  </div>
                </div>
            </Card>
          </Col>
        </Row>
      </Card>
      {changeShippingCountryDetails.productIds &&
        changeShippingCountryDetails.productIds.length &&
        changeShippingCountryDetails.existingShippingCountry && (
          <ChangeShippingCountryModal
            existingShippingCountryId={changeShippingCountryDetails.existingShippingCountry.getId()}
            isOpen={changeShippingCountryDetails.isOpen}
            productIds={changeShippingCountryDetails.productIds}
            onCancel={() =>
              setChangeShippingCountryDetails({
                isOpen: false,
                existingShippingCountry: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      {ShipmentProductData &&
        changeDestinationWarehouseDetails.productIds &&
        changeDestinationWarehouseDetails.productIds.length &&
        changeDestinationWarehouseDetails.existingWarehouse && (
          <ChangeDestinationWarehouseModal
            country_id={ShipmentProductData.getDestinationCountryId()}
            existingWarehouseId={changeDestinationWarehouseDetails.existingWarehouse.getId()}
            isOpen={changeDestinationWarehouseDetails.isOpen}
            productIds={changeDestinationWarehouseDetails.productIds}
            onCancel={() =>
              setChangeDestinationWarehouseDetails({
                isOpen: false,
                existingWarehouse: undefined,
                productIds: undefined,
              })
            }
          />
        )}
    </>
  );
};
