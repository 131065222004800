import { ResponsePromise } from 'lib/core/request/types/typings'
import { BaseResource } from '../public'

class AddressSchemaResource extends BaseResource {
  retrieve(
    country: string,
    locale: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `/api/address/admin/v1/customer-address-schema?country=${country}&language=${locale}`
    return this.client.request('GET', path, undefined, {}, customHeaders)
  }
  retrieveDwAddressSchema(
    country: string,
    language: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `/api/address/admin/v1/dw-address-schema?country=${country}&language=${language}`
    return this.client.request('GET', path, undefined, {}, customHeaders)
  }
}

export default AddressSchemaResource
