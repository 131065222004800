import React, { ReactElement, Suspense, useState } from "react";
import { Layout, Button, Row, Col, Spin, Popover, Result } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LoadingOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { Sidebar } from "./components";
import styles from "./AntDesignLayout.module.css";
import {
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter,
} from "components/NovuPackage";
import { NOVU_APPLICATION_IDENTIFIER } from "consts/storage";
import HarvestJobPopupOver from "pages/HarvestJobManage/components/harvestJobPopupOver";
import { Link, Outlet } from "react-router-dom";
import { getEnvironmentMode } from "../../utils/helper";
import useOryAuth from "lib/core-react/utils/useOryAuth";
import { useNavigate } from 'react-router-dom';
const { Header, Content } = Layout;
const isModeProduction = getEnvironmentMode() !== "development";

const AntDesignLayout: React.FC<{ children?: ReactElement }> = () => {
  const navigate = useNavigate();
  // State
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [showHarvestJobDrawer, setShowHarvestJobDrawer] = useState(false);

  // Hooks
  const { session, getNotificationSubscriberId, getUserName, logoutUrl } =
    useOryAuth();

  // Handler
  const handleMenuCollapsed = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };
  const handleCloseDrawer = () => {
    setShowHarvestJobDrawer(false);
  };

  // Loader
  if (!session && isModeProduction) {
    navigate("/auth/login");
  }

  return (
    <NovuProvider
      stores={[
        /**
    storeId and feedIdentifier must be identical and should be copied from In-App editor feed
    */
        {
          storeId: "Orders",
          query: { feedIdentifier: "Orders" },
        },
        {
          storeId: "Shipment Updated",
          query: { feedIdentifier: "Shipment Updated" },
        },
        {
          storeId: "Action Needed",
          query: { feedIdentifier: "Action Needed" },
        },
        { storeId: "Promotions", query: { feedIdentifier: "Promotions" } },
        { storeId: "Notice", query: { feedIdentifier: "Notice" } },
      ]}
      subscriberId={getNotificationSubscriberId()}
      applicationIdentifier={NOVU_APPLICATION_IDENTIFIER}
    >
      <Layout className={`${styles.layoutStyle}`}>
        <Sidebar
          isMenuCollapsed={isMenuCollapsed}
          handleCollapsed={handleMenuCollapsed}
        />
        <Layout
          className={`site-layout ${
            isMenuCollapsed
              ? styles.layoutOnSiderCollapse
              : styles.layoutOnSiderExpand
          }`}
        >
          <Header
            className={`site-layout-background ${styles.layoutStyle__header}`}
          >
            <Row>
              <Col span={4}>
                {React.createElement(
                  isMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: `trigger ${styles.triggerButton}`,
                    onClick: handleMenuCollapsed,
                  },
                )}
              </Col>
              <Col
                lg={{ span: 3, offset: 10 }}
                xs={{ span: 3, offset: 14 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <p>{getUserName()}</p>
                <Link to={logoutUrl ? logoutUrl : "/auth-admin/login"}>
                  {/*<Button*/}
                  {/*  className="ant-dropdown-link"*/}
                  {/*  onClick={handleLogout}*/}
                  {/*  disabled={!session}*/}
                  {/*  loading={!session}*/}
                  {/*>*/}
                  {/*  Ory Logout/ Login*/}
                  {/*</Button>*/}
                </Link>

                {/* Notifications */}
                <PopoverNotificationCenter colorScheme="light">
                  {({ unseenCount }) => (
                    <NotificationBell unseenCount={unseenCount} />
                  )}
                </PopoverNotificationCenter>

                {/* Harvest Job */}
                <Popover
                  placement="bottomRight"
                  content={
                    <HarvestJobPopupOver
                      open={true}
                      onClose={handleCloseDrawer}
                    />
                  }
                  trigger="click"
                  overlayStyle={{ width: 450 }}
                >
                  <Button type="dashed" shape="circle">
                    <BulbOutlined />
                  </Button>
                </Popover>

                {showHarvestJobDrawer && (
                  <HarvestJobPopupOver
                    open={showHarvestJobDrawer}
                    onClose={handleCloseDrawer}
                  />
                )}
              </Col>
            </Row>
          </Header>
          <Content className={`${styles.layoutStyle__content}`}>
            {session && isModeProduction ? (
              <div
                className={`site-layout-background ${styles.layoutStyle__contentAuth}`}
              >
                <Suspense
                  fallback={
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  }
                >
                  <Outlet />
                </Suspense>
              </div>
            ) : isModeProduction ? (
              <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary">Back Home</Button>}
              />
            ) : (
              <div
                className={`site-layout-background ${styles.layoutStyle__contentAuth}`}
              >
                <Suspense
                  fallback={
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  }
                >
                  <Outlet />
                </Suspense>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </NovuProvider>
  );
};

export default AntDesignLayout;
