import { CampaignResource } from "../../resources/private";
import ProductApiService from "lib/core/apiServices/productApiService";
class CampaignService extends ProductApiService {
    public campianResource: CampaignResource;
  
    constructor() {
      super();
  
      this.campianResource = new CampaignResource(this.config);
    }
  }
  
  export default CampaignService;
  