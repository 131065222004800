import { ADMIN_ACL_PERMISSION_ENUM } from "consts/permission-enum/ACL-enum";
import { ADMIN_ADDON_SERVICE_PERMISSION_ENUM } from "consts/permission-enum/addon-service-enum";
import { ADMIN_ADDRESS_PERMISSION_ENUM } from "consts/permission-enum/address-enum";
import { ADMIN_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-core-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM, ADMIN_SHIPPING_CORE_PERMISSION_ENUM, ADMIN_SHIPPING_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
import { ADMIN_STORE_PERMISSION_ENUM } from "consts/permission-enum/admin-store-enum";
import { ADMIN_USER_PERMISSION_ENUM } from "consts/permission-enum/admin-user-enum";
import { ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM } from "consts/permission-enum/buy-and-take-enum";
import { ADMIN_DESTINATION_PERMISSION_ENUM } from "consts/permission-enum/destination-enum";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "consts/permission-enum/invoice-enum";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "consts/permission-enum/payment-enum";
import { ADMIN_REFUND_PERMISSION_ENUM } from "consts/permission-enum/refund-enum";
import { ADMIN_RFQ_PERMISSION_ENUM } from "consts/permission-enum/RFQ-enum";
import { ADMIN_TRACKING_PERMISSION_ENUM } from "consts/permission-enum/tracking-enum";
import { ADMIN_WALLET_PERMISSION_ENUM } from "consts/permission-enum/wallet-enum";
import { ADMIN_DISCOUNT_PERMISSION_ENUM } from "consts/permission-enum/admin-discount-enum";

// Array for admin permissions in the Discount module
const ADMIN_DISCOUNT_PERMISSION_VALUES = [
  // Discount permissions
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_VIEW,
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_CREATE,
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_UPDATE,
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_DELETE,

  // Discount Group permissions
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_GROUP_CREATE,
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_GROUP_USER_ADD,
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_GROUP_USER_REMOVE,

  // Discount usage history permissions
  ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_USAGE_HISTORY_VIEW
];



// Array for admin permissions in the Shipping module
 const ADMIN_SHIPPING_PERMISSION_VALUES = [
  // Shipment Product permissions
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_VIEW_SHIPMENT_PRODUCT,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PLACE_ORDER,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_COUNTRY,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_DESTINATION_WAREHOUSE,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_CATEGORY,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_WITH_PRICE,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_APPROVE_SHIPMENT_PRODUCT,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_REJECT_SHIPMENT_PRODUCT,

  // Shipment Product Tracking permissions
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_TRACKING,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_UPDATE_TRACKING,

  // Shipment Product Package permissions
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_VIEW,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_ADD,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_UPDATE,
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_DELETE,

  // Shipment Product Unlisted Package permissions
  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_UNLISTED_PACKAGE_VIEW
];


const HARBEST_PERMISSION_VALUES = [
  "admin-harvest-batch-view",
  "admin-harvest-batch-create",
  "admin-harvest-batch-cancel",
];

const PAYMENT_PERMISSION_VALUES = [
  PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
  PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_ADD,
  PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_UPDATE,
  PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
  PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_UPDATE,
];

const ADMIN_REFUND_PERMISSION_VALUES = [
  ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
  ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
  ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_APPROVE,
  ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_COMPLETE,
  ADMIN_REFUND_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_DISPUTE_ASSIGN,
];

const ADMIN_RFQ_PERMISSION_VALUES = [
  ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_VIEW,
  ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_ADD,
  ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_APPROVE,
  ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_REJECT,
];

// Array for admin permissions in the ACL module
const ADMIN_ACL_PERMISSION_VALUES = [
  ADMIN_ACL_PERMISSION_ENUM.ADMIN_PERMISSION_VIEW,
  ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
  ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_CREATE,
  ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
];

// Array for admin permissions in the User module
const ADMIN_USER_PERMISSION_VALUES = [
  // Admin-user permissions
  ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_VIEW,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_ADD,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_PROFILE_UPDATE,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_UPDATE,

  // Customer-user permissions
  ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_ADD,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_UPDATE,

  // Sync all user permissions
  ADMIN_USER_PERMISSION_ENUM.ADMIN_SYNC_USERS,

  // Login-session permissions
  ADMIN_USER_PERMISSION_ENUM.ADMIN_LOGIN_SESSION_VIEW,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_DISABLE_ALL_SESSION,
  ADMIN_USER_PERMISSION_ENUM.ADMIN_DISABLE_SESSION,
];

// Array for admin permissions in the Shipping-Core module
const ADMIN_SHIPPING_CORE_PERMISSION_VALUES = [
  // Base-shipping-category permissions
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_VIEW,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_CREATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_UPDATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_SYNC,

  // Shipping-category permissions
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_VIEW,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_CREATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_DELETE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_SYNC,

  // Shipping-country permissions
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_VIEW,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_CREATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_SYNC,

  // Auction rule permissions
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_VIEW,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_CREATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_UPDATE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_DELETE,
  ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_SYNC,
];


// Array for admin permissions in the Purchase module
const ADMIN_PURCHASE_PERMISSION_VALUES = [
  // Buy-product permissions
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,

  // Buy Order permissions
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,

  // Buy Product Charge permissions
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_ADD,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
  ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
];

// Array for admin permissions in the Shipping-Agent module
const ADMIN_SHIPPING_AGENT_PERMISSION_VALUES = [
  // Agent Company permissions
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_SHIPPING_POINT_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_MEMBER_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CHANGE_AGENT_VERIFICATION_STATUS,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_SYNC,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_UPDATE,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_SYNC,

  // Agent Category Read Price permissions
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_CATEGORY_PRICE_READ_ADMIN_VIEW,

  // Pricing Template permissions
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.PRICING_TEMPLATE_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.PRICING_TEMPLATE_ADMIN_ADD,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.PRICING_TEMPLATE_ADMIN_UPDATE,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.PRICING_TEMPLATE_ADMIN_DELETE,

  // Agent Verification permissions
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_VERIFICATION_ADMIN_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_VERIFICATION_ADMIN_UPDATE,

  // Commission permissions
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_VIEW,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_COPY,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ASSOCIATION_COPY,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_COPY,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_UPDATE,
  ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_DELETE,
];

// Array for admin permissions in the Core module
const ADMIN_CORE_PERMISSION_VALUES = [
  // Country permissions
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_VIEW,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_ADD,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_UPDATE,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_SYNC,

  // Language permissions
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_VIEW,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_ADD,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_UPDATE,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_SYNC,

  // Region permissions
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_VIEW,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_ADD,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_UPDATE,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_SYNC,

  // Currency permissions
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_VIEW,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_ADD,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_UPDATE,
  ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_SYNC,
];

// Array for admin permissions in the Wallet module
const ADMIN_WALLET_PERMISSION_VALUES = [
  // Payout Gateway permissions
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_ADD,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_UPDATE,

  // Payout Request permissions
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_APPROVE,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_PROCESS,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_REJECT,

  // Wallet permissions
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_TRANSACTION_VIEW,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
  ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_TRANSACTION,
];

// Array for admin permissions in the Store module
const ADMIN_STORE_PERMISSION_VALUES = [
  // Store permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_ADD,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_UPDATE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_VIEW,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_SYNC,

  // Exchange permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_ADD,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_VIEW,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_COPY,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_UPDATE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_DELETE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_SYNC,

  // Regional Store Exchange permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_REGIONAL_STORE_EXCHANGE_UPDATE,

  // Exchange Group permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_ADD,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_UPDATE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_DELETE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_SYNC,

  // Exchange Group User permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_ADD,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_DELETE,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_SYNC,

  // Store Exchange Read permissions
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_EXCHANGE_READ_VIEW,
  ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_EXCHANGE_READ_SYNC,
];

// Array for admin permissions in the Tracking module
const ADMIN_TRACKING_PERMISSION_VALUES = [
  // Comments permissions
  ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_ADD,
  ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_UPDATE,
  ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_DELETE,
  ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_REPLY,

  // Timeline Tracking permissions
  ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_TIMELINE_VIEW,
];

// Array for admin permissions in the Destination module
const ADMIN_DESTINATION_PERMISSION_VALUES = [
  // Destination Warehouse permissions
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_VIEW,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_ADD,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_SYNC,

  // Membership Invitation permissions
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_VIEW,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_ADD,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_RESEND,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_DELETE,

  // Membership permissions
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_UPDATE,
  ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_DELETE,
];

// Array for admin permissions in the AddonService module
const ADMIN_ADDON_SERVICE_PERMISSION_VALUES = [
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_VIEW,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_ADD,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_UPDATE,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_ADD,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_UPDATE,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_DELETE,
  ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_SYNC,
];

// Array for admin permissions in the Address module
const ADMIN_ADDRESS_PERMISSION_VALUES = [
  // Addresses permissions
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_VIEW,
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_ADD,
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_UPDATE,
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_DELETE,
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_SYNC,

  // Dw-Addresses permissions
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_DW_ADDRESS_ADD,
  ADMIN_ADDRESS_PERMISSION_ENUM.ADMIN_DW_ADDRESS_UPDATE,
];

// Array for admin permissions in the BuyTakeAction module
const ADMIN_BUY_TAKE_ACTION_PERMISSION_VALUES = [
  ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_VIEW,
  ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ACTION_VIEW,
  ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_ADD,
];

// Array for admin permissions in the Invoice module
 const ADMIN_INVOICE_PERMISSION_VALUES = [
  // Invoice permissions
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_SETTLE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_TRANSACTION_VIEW,

  // Agent Invoice permissions
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_RECALCULATE_AGENT_INVOICE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE_TRANSACTION,

  // Invoice payment rule group permissions
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_ADD,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_UPDATE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_DELETE,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_ADD,
  ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_DELETE,
];

export const permissionData = [
  "test_admin_role_index",
  ADMIN_SHIPPING_AGENT_PERMISSION_VALUES,
  ADMIN_DISCOUNT_PERMISSION_VALUES,
  HARBEST_PERMISSION_VALUES,
  PAYMENT_PERMISSION_VALUES,
  ADMIN_INVOICE_PERMISSION_VALUES,
  ADMIN_REFUND_PERMISSION_VALUES,
  ADMIN_RFQ_PERMISSION_VALUES,
  ADMIN_ACL_PERMISSION_VALUES,
  ADMIN_USER_PERMISSION_VALUES,
  ADMIN_SHIPPING_CORE_PERMISSION_VALUES,
  ADMIN_PURCHASE_PERMISSION_VALUES,
  ADMIN_CORE_PERMISSION_VALUES,
  ADMIN_STORE_PERMISSION_VALUES,
  ADMIN_WALLET_PERMISSION_VALUES,
  ADMIN_TRACKING_PERMISSION_VALUES,
  ADMIN_DESTINATION_PERMISSION_VALUES,
  ADMIN_ADDON_SERVICE_PERMISSION_VALUES,
  ADMIN_BUY_TAKE_ACTION_PERMISSION_VALUES,
  ADMIN_ADDRESS_PERMISSION_VALUES,
  ADMIN_SHIPPING_PERMISSION_VALUES
].flat();
