import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { CountryDataModel } from "models/country";

class CountryResource extends BaseResource {
  path = `/api/core/admin/country/v1/countries`;
  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}${params ? `?${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  update(
    id: number,
    payload: Partial<CountryDataModel>,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  create(
    payload: Partial<CountryDataModel>,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
}

export default CountryResource;
