import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Col, Dropdown, Modal, Row, Table } from "antd";
import FiltersComponent from "components/FiltersComponent";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { useGetShippingCountry } from "lib/core-react/hooks/private";
import { shippingCountry } from "lib/core-react/store/store";
import { PaginationModel } from "models/pagination";
import {
  ShippingCountryDataModel,
  ShippingCountryModel,
} from "models/shippingCountry";
import { StatusTag } from "../../../components";
import { useEffect, useState } from "react";
import { IFilterType } from "types/filters";
import CreateShippingCountry from "./components/CreateShippingCountry";
import UpdateShippingCountry from "./components/UpdateShippingCountry";
import { ApiHelperModel } from "models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
const { Column } = Table;

const ShippingCountry = () => {
  const { getShippingCountry } = useGetShippingCountry();
  const { isMobile } = useWindowWidth();
  const [{ data: shippingCountryStoreData, isLoading, refetch }] =
    useAtom(shippingCountry);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if (
      (filters && !isFetched && isFirstCall) ||
      refetch ||
      refetchFromFilter
    ) {
      filters && ApiHelperModel.makeGetRequest(filters, getShippingCountry);
    }
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  // Shipping Country
  const ShippingCountryData =
    shippingCountryStoreData &&
    new ShippingCountryModel(shippingCountryStoreData);

  // Filter
  let filterData = ShippingCountryData?.filters;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && ShippingCountryData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, ShippingCountryData?.filters]);

  // Create modal state
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  // Update modal state
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [updatePayload, setUpdatePayload] = useState<
    ShippingCountryDataModel | undefined
  >(undefined);

  // Show update modal with data
  const showUpdateModal = (record: any) => {
    setUpdatePayload(record);
    setIsShowUpdateModal(true);
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getShippingCountry,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    ShippingCountryData,
    handlePaginationChange,
  );

  // Filter Handling
  const handleFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getShippingCountry);
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Shipping Country"
          style={{ marginTop: "10px" }}
          extra={[
            checkActionPermission(
              ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_CREATE,
              <Button
                key="1"
                type="primary"
                onClick={() => setIsShowCreateModal(true)}
              >
                Create Shipping Country
              </Button>,
              null,
            ),
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              scroll={{ x: 1000 }}
              pagination={paginationConfig}
              dataSource={ShippingCountryData?.data}
            >
              <Column width={70} title="ID" dataIndex="id" key="id" />

              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: ShippingCountryDataModel) => (
                  <StatusTag text={record.status} slug={record.status} />
                )}
              />

              <Column
                title="Country Name"
                dataIndex="country_name"
                key="country_name"
                render={(_, record: ShippingCountryDataModel) => (
                  <span>{record.country && record.country.name}</span>
                )}
              />

              <Column
                title="Country Code"
                dataIndex="country_code"
                key="country_code"
                render={(_, record: ShippingCountryDataModel) => (
                  <span>{record.country && record.country.code}</span>
                )}
              />
              <Column
                title="Region Name"
                dataIndex="region_name"
                key="region_name"
                render={(_, record: ShippingCountryDataModel) => (
                  <span>{record.region && record.region.name}</span>
                )}
              />

              <Column
                title="Action"
                key="action"
                fixed={isMobile ? "right" : undefined}
                align="center"
                width={100}
                render={(_, record) => {
                  const items: ExtendedMenuItemType[] = [
                    {
                      permission:
                        ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
                      key: "1",
                      label: (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => showUpdateModal(record)}
                        >
                          <EditOutlined /> <span> Update</span>
                        </div>
                      ),
                    },
                  ];

                  return (
                    <Dropdown
                      menu={{
                        items: items.filter((x) =>
                          checkActionPermission(x.permission, x, null),
                        ),
                      }}
                    >
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            Actions <DownOutlined />{" "}
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`Create Shipping Country`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateShippingCountry setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>

      <Modal
        title={`Update Shipping Country`}
        open={isShowUpdateModal}
        footer={false}
        onCancel={() => setIsShowUpdateModal(false)}
      >
        <UpdateShippingCountry
          setIsShowUpdateModal={setIsShowUpdateModal}
          data={updatePayload as ShippingCountryDataModel}
        />
      </Modal>
    </>
  );
};

export default ShippingCountry;
