import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetRangeFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import RatingCell from "../RatingCell";

interface IProps {
  ratingAverageRangesField: FacetRangeFieldModel[];
}

const RatingAverageRangeFacet = ({ ratingAverageRangesField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { rating_average_ranges, ...rest } = parsedQuery;

  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectRatingAverageRanges = async (
    name: FacetRangeFieldModel,
    isChecked: boolean,
  ) => {
    let updatedRatingAverageRanges = {
      min: name.getFrom(),
      max: name.getTo(),
    };

    if (isChecked) {
      // isChecked=true means user trying deselect
      const queryString = qs.stringify({ ...rest });
      await getProductDetailsCollection(
        {
          region: "BD",
          locale: "en",
        },
        { ...rest },
      );
      navigate(`/product-manage/products?${queryString}`);
    } else {
      const queryString = qs.stringify({
        ...rest,
        rating_average_ranges: updatedRatingAverageRanges,
      });
      await getProductDetailsCollection(
        {
          region: "BD",
          locale: "en",
        },
        { rating_average_ranges: updatedRatingAverageRanges, ...rest },
      );
      navigate(`/product-manage/products?${queryString}`);
    }
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Rating</StyledProductSidebarItemTitle>
      <AppList
        data={ratingAverageRangesField}
        renderItem={(data) => (
          <RatingCell
            key={data.getName()}
            data={data}
            onChange={onSelectRatingAverageRanges}
            selected={rating_average_ranges ? rating_average_ranges : undefined}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default RatingAverageRangeFacet;
