import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Dropdown,
    MenuProps,
    message,
    Modal,
    Space,
    Table,
    Typography,
} from "antd";
import { exchangeGroupCustomerUserCollectionAtom } from "lib/core-react/store/store";
import {  GetApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import {
    DeleteOutlined,
    DownOutlined,
    HddFilled,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import { useAddExchangeGroupCustomer, useGetExchangeGroupCustomers } from "lib/core-react/hooks/private/useExchangeGroup";
import { ExchangeGroupModel } from "models/exchangeGroupCollectionModel";
import {
    CustomerUserCollectionModel,
    CustomerUserModel,
} from "models/customerUserCollectionModel";
import UpdateExchangeGroupUser from "./UpdateExchangeGroupCustomer";
import DeleteExchangeGroupCustomer from "./DeleteExchangeGroupCustomer";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_STORE_PERMISSION_ENUM } from "consts/permission-enum/admin-store-enum";
import { PageHeader } from "@ant-design/pro-layout";
import AddUserToExchangeGroup from "./AddUserToExchangeGroup";
import { IExchangeGroupUserRequest } from "types/exchangeGroupCollection";
import { showError } from "helpers/showError";
import { FormInstance } from "antd/lib";
interface IProps {
    data: ExchangeGroupModel;
}
const ExchangeGroupCustomersTable = ({ data }: IProps) => {
    const [filterData, setFilterData] = useState<Record<string, any>>({exchange_group_id: data.getId()})
    const { getExchangeGroupCustomers } = useGetExchangeGroupCustomers();
    const [{ data: customerCollectionData, isLoading }] = useAtom(
        exchangeGroupCustomerUserCollectionAtom,
    );
  const { addExchangeGroupCustomer, isLoading: addCustomerLoading } =
        useAddExchangeGroupCustomer();

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [isShowCreateUserModal, setIsShowCreateUserModal] =
        useState<boolean>(false);
    const { isMobile } = useWindowWidth();
    const handleMenuClick: MenuProps["onClick"] = (e) => {
        if (e.key === "update") {
            setIsShowUpdateModal(true);
        } else if (e.key === "delete") {
            setIsShowDeleteModal(true);
        }
    };
    // Api Call
    useEffect(() => {
        if (Object.keys(filterData).length) {
            GetApiHelperModel.makeGetRequest(filterData, getExchangeGroupCustomers);
        }
    }, [filterData]);

    const CustomerCollectionData =
        customerCollectionData &&
        new CustomerUserCollectionModel(customerCollectionData);

    // Pagination
    const paginationData = CustomerCollectionData?.getPagination();

    const [selectedExchangeGroupCustomer, setSelectedExchangeGroupCustomer] =
        useState<CustomerUserModel | undefined>(undefined);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        setFilterData(pre => {
            return {
                ...pre,
                ...pageInfo
            }
        })

    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        CustomerCollectionData,
        handlePaginationChange,
    );
    
    const columns = [
        {
            title: "#SL",
            width: 60,
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            render: (_: string, __: CustomerUserModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "User info",
            dataIndex: "user",
            key: "user",
            render: (_: string, record: CustomerUserModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>Name: </Typography.Text>
                            <Typography.Text>
                                {record.getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Phone: </Typography.Text>
                            <Typography.Text>
                                {record.getPhone()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Email: </Typography.Text>
                            <Typography.Text>
                                {record.getEmail()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:{" "}
                            </Typography.Text>{" "}
                            <Typography.Text>
                                {record.getShippingMark()}
                            </Typography.Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: isMobile ? 90 : 150,
            render: (_: string, record: CustomerUserModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_UPDATE,
                        icon: <UsergroupAddOutlined />,
                        label: "Update Customer",
                        key: "update",
                    },
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_DELETE,
                        icon: <DeleteOutlined />,
                        label: "Delete Customer",
                        key: "delete",
                    },
                ];

                const menuProps = {
                    items: items.filter((x) =>
                        checkActionPermission(x.permission, x, null),
                    ),
                    onClick: handleMenuClick,
                };

                return (
                    <Dropdown
                        menu={menuProps}
                        onOpenChange={() =>
                            setSelectedExchangeGroupCustomer(record)
                        }
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    {" "}
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    // add customer handler
    const addCustomerHandler = async (value: { user: number }, form: FormInstance<any>)=>{
        const payload = {
            user_id: value.user,
            exchange_group_id: Number(data.getId()),
        };
        try {
            const res = await addExchangeGroupCustomer(
                payload as IExchangeGroupUserRequest,
            );
            if (res) {
                setIsShowCreateUserModal(false);
                if (Object.keys(filterData).length) {
                    GetApiHelperModel.makeGetRequest(filterData, getExchangeGroupCustomers);
                }
                message.success(res.message);
             
            }
        } catch (error: any) {
            showError(error, form);
        }

    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Exchange Group"
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_ADD,
                        <Button
                            key="1"
                            type="primary"
                            onClick={() => setIsShowCreateUserModal(true)}
                        >
                            Add customer
                        </Button>,
                        null,
                    ),
                ]}
            >
                <Table
                    //@ts-ignore
                    columns={columns}
                    size="small"
                    bordered
                    loading={isLoading}
                    dataSource={CustomerCollectionData?.getData()}
                    pagination={paginationConfig}
                    rowKey="id"
                    scroll={{ x: 350 }}
                    style={{ maxHeight:"60vh", overflowY:"auto", overflowX:"hidden"}}
                />
            </PageHeader>

            {selectedExchangeGroupCustomer && isShowUpdateModal && (
                <Modal
                    title={`Update Exchange Group Customer`}
                    open={isShowUpdateModal}
                    footer={false}
                    onCancel={() => {
                        setIsShowUpdateModal(false);
                    }}
                    destroyOnClose={true}
                >
                    <UpdateExchangeGroupUser
                        setIsShowUpdateModal={setIsShowUpdateModal}
                        selectedCustomerUser={selectedExchangeGroupCustomer}
                        data={data}
                        filterData={filterData}
                    />
                </Modal>
            )}

            {selectedExchangeGroupCustomer && (
                <Modal
                    title="Delete Exchange Group Customer"
                    open={isShowDeleteModal}
                    onCancel={() => setIsShowDeleteModal(false)}
                    footer={null}
                >
                    <DeleteExchangeGroupCustomer
                        setIsShowDeleteModal={setIsShowDeleteModal}
                        selectedCustomerUser={selectedExchangeGroupCustomer}
                        filterData={filterData}
                    />
                </Modal>
            )}
            {/* Add user to exchange group */}
            {isShowCreateUserModal && (
                <Modal
                    title={`Add user to ${data.getName()}`}
                    open={isShowCreateUserModal}
                    onCancel={() => setIsShowCreateUserModal(false)}
                    footer={null}
                >
                    <AddUserToExchangeGroup
                        addCustomerHandler={addCustomerHandler}
                        loading={addCustomerLoading}
                    />
                </Modal>
            )}
        </>
    );
};

export default ExchangeGroupCustomersTable;
