import React from "react";
import { Space, Table, Tag } from "antd";
import type { TableColumnsType } from "antd";
import { DisputeMandatesModel } from "models/disputeDetailsCollectionModel";
import { DisputeMandateStatusEnum } from "enums/disputeCollectionEnum";

interface MandatesTableProps {
  mandates?: DisputeMandatesModel[];
}
const MandatesTable: React.FC<MandatesTableProps> = ({ mandates }) => {
  const columns: TableColumnsType<DisputeMandatesModel> = [
    {
      title: "Mandate To",
      dataIndex: "mandate_to",
      key: "_id",
      render: (tags: any, record) => {
        return <Space>{record?.getMandateTo()}</Space>;
      },
    },

    {
      title: "status",
      dataIndex: "status",
      key: "_id",
      render: (tags: any, record) => {
        return (
          <Space>
            <Tag
              color={
                record?.getStatus() === DisputeMandateStatusEnum.AGREED
                  ? "success"
                  : "warning"
              }
            >
              {record.getStatus()}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Agent Company",

      dataIndex: "agent_company",
      key: "_id",
      render: (tags: any, record) => {
        return (
          <Space>
            {record?.getAgentCompany()
              ? record?.getAgentCompany()
              : "Not Exist"}
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <p style={{ fontWeight: "bold", fontSize: "1rem",padding:0,marginTop:0,marginLeft:0,marginRight:0,marginBottom:20}}>
        Mandates
      </p>
      <Table
        style={{ height: 280, overflow: "auto",marginTop:0 }}
        scroll={{ x: "100%" }}
        rowKey="_id"
        bordered={true}
        columns={columns}
        dataSource={mandates}
        pagination={false}
        loading={false}
      />
    </>
  );
};

export default MandatesTable;
