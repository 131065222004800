import { Checkbox } from "antd";
import { StyledProductSideCheckedCell } from "./index.styled";
import { ParsedQs } from "qs";

type Props = {
  selected: string[] | ParsedQs[];
  data: any;
  onChange: (data: string & ParsedQs) => void;
};
const MultiCheckedCell = ({ selected, data, onChange }: Props) => {
  return (
    <StyledProductSideCheckedCell onClick={() => onChange(data.name)}>
      <Checkbox checked={selected.some((item) => item === data.name)} />
      <p className="mb-0">
        {data.name} ({data.count})
      </p>
    </StyledProductSideCheckedCell>
  );
};

export default MultiCheckedCell;
