// Enum for admin permissions in the Tracking module
export enum ADMIN_TRACKING_PERMISSION_ENUM {
    // Comments permissions
    ADMIN_TRACKING_EVENT_ADD = "admin-tracking-event-add",
    ADMIN_TRACKING_EVENT_UPDATE = "admin-tracking-event-update",
    ADMIN_TRACKING_EVENT_DELETE = "admin-tracking-event-delete",
    ADMIN_TRACKING_EVENT_REPLY = "admin-tracking-event-reply",

    // Timeline Tracking permissions
    ADMIN_TRACKING_TIMELINE_VIEW = "admin-tracking-timeline-view"
}

