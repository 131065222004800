import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Collapse,
  Descriptions,
  Dropdown,
  Form,
  Image,
  List,
  Modal,
  Space,
  Typography,
  notification,
} from "antd";
import type { MenuProps } from "antd";
import type { DescriptionsProps } from "antd";
import type { CollapseProps } from "antd";
import {
  CaretRightOutlined,
  DeleteTwoTone,
  DownOutlined,
  EditOutlined,
  HddFilled,
  PlusSquareTwoTone,
} from "@ant-design/icons";

import { FALLBACK_IMAGE_URL } from "consts/appConstants";
import {
  useShipmentProductAddPackage,
  useShipmentProductAttachPackageImage,
  useShipmentProductAttachTrackingId, useShipmentProductDeletePackage,
  useShipmentProductUpdateTrackingId,
} from "lib/core-react/hooks/private/useShipping";
import { getError } from "lib/core-react/hooks/utils/errors";

import {
  IShipmentProductAttachImageForm,
  IShipmentProductDetailCommonProps,
} from "types/shipmentProductCollection";
import { ProductSourceEnum } from "enums/shipForMeContextEnums";
import { ShipmentProductOperationEnum } from "enums/shipmentProductCollectionEnums";

import {
  AddOwnSourceCartonModal,
  TrackingModificationModal,
  AttachCartonImageModal,
  CartonVariationsModal,
} from ".";
import { ShipmentProductPackageModel } from "models/shipmentProductPackageCollectionModel";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_TRACKING_PERMISSION_ENUM } from "consts/permission-enum/tracking-enum";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";

export const Carton = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  const navigate = useNavigate();
  const [addCartonModalOpen, setAddCartonModalOpen] = useState<boolean>(false);

  const [attachCartonImageModalOpen, setAttachCartonImageModalOpen] =
    useState<boolean>(false);

  const [selectedPackageIds, setSelectedPackageIds] = useState<number[]>([]);
  const [selectedPackage, setSelectedPackage] =
    useState<ShipmentProductPackageModel>();
  const [trackingModalOpen, setTrackingModalOpen] = useState<boolean>(false);
  const [selectedTrackingId, setSelectedTrackingId] = useState<number>();
  const [variationsModalOpen, setVariationsModalOpen] =
    useState<boolean>(false);

  const shipment_product_id = ShipmentProductData?.getId();

  const [form] = Form.useForm();

  const {
    addPackage: addShipmentProductPackage,
    isLoading: isLoadingAddShipmentProductPackage,
  } = useShipmentProductAddPackage();
  const {deletePackage, isLoading:deletePackageLoading} = useShipmentProductDeletePackage()

  const {
    attachImage: attachShipmentProductPackageImage,
    isLoading: isLoadingAttachShipmentProductPackageImage,
  } = useShipmentProductAttachPackageImage();

  const {
    attachTrackingId,
    isLoading: isLoadingAttachShipmentProductTrackingId,
  } = useShipmentProductAttachTrackingId();

  const {
    updateTrackingId,
    isLoading: isLoadingUpdateShipmentProductTrackingId,
  } = useShipmentProductUpdateTrackingId();

  const handleMenuClick = () => { };
  const handleSecondaryMenuClick: MenuProps["onClick"] = async(e) => {
    if (e.key === "add-tracking") {
      setTrackingModalOpen(true);
    }
    else if (e.key === "attach-carton-image") {
      setAttachCartonImageModalOpen(true);
    }
    else if (e.key === "delete-carton"){
      if(shipment_product_id && selectedPackage?.getId()){
        // @ts-ignore
        await  deletePackage(shipment_product_id, selectedPackage?.getId())

      }
    }
  };

  const items: ExtendedMenuItemType[] = [
    {
      permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_ADD,
      icon: <PlusSquareTwoTone />,
      label: "Add Carton",
      key: "add-carton",
      onClick: () =>
        ShipmentProductData?.getProductSource() === ProductSourceEnum.OWN_SOURCE
          ? setAddCartonModalOpen(true)
          : navigate(
            `/product-manage/products/product_detail/find?operation=${ShipmentProductOperationEnum.AddShipmentProductPackage}&shipmentProductId=${ShipmentProductData?.getId()}&region=${  ShipmentProductData && ShipmentProductData.getRegion() ? ShipmentProductData.getRegion()?.getCode() : undefined}&userId=${ShipmentProductData?.getOrder().getCustomer().getId()}&productUrl=${ShipmentProductData?.getLink()}`,
          ),
    },
  ];

  const secondaryItems: ExtendedMenuItemType[] = [

    {
      permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
      icon: <PlusSquareTwoTone />,
      label: "Attach Image",
      key: "attach-carton-image",
    },
    {
      permission:  ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_DELETE,
      icon: <DeleteTwoTone />,
      label: "Delete Carton",
      key: "delete-carton",
      disabled:deletePackageLoading
    }
  ];


  if(selectedPackage && !selectedPackage.getAbroadTracking()){
    secondaryItems.push({
      permission: ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_ADD,
      icon: <PlusSquareTwoTone />,
      label: "Add Tracking",
      key: "add-tracking",
    })
  }

  const menuProps = {
    items: items.filter((x) =>
      checkActionPermission(x.permission, x, null),
    ),
    onClick: handleMenuClick,
  };

  const secondaryMenuProps = {
    items: secondaryItems.filter((x) =>
      checkActionPermission(x.permission, x, null),
    ),
    onClick: handleSecondaryMenuClick,
  };

  const handleOwnSourceAddPackage = async () => {
    try {
      const trigger = await form.validateFields();
      if (shipment_product_id) {
        await addShipmentProductPackage(shipment_product_id, {
          ...trigger,
        });

        notification["success"]({
          message: "Add carton successfully",
        });
        setAddCartonModalOpen(false);
      } else {
        notification["error"]({
          message: "Shipment product number not found!",
        });
      }
    } catch (err) {
      notification["error"]({
        message: getError(err),
      });
    }
  };

  const handleAttachCartonImage = async () => {

    try {
      if (shipment_product_id && selectedPackageIds.length) {
        const images = form.getFieldValue("images") as string[]

          const payload: IShipmentProductAttachImageForm = {
            shipment_product_ids:[shipment_product_id],
            images:images,
            packages:[{id:selectedPackageIds[0], images:images}]
          };

          await  attachShipmentProductPackageImage(payload);
        notification["success"]({
          message: "Attached carton images successfully.",
        });

        setSelectedPackageIds([]);
        setAddCartonModalOpen(false);
      } else {
        notification['error']({
          message:"Shipment product id not found!"
        })      }
    } catch (err) {
      notification['error']({
        message:getError(err)
      })
    }
  };

  const handleAttachTracking = async () => {
    try {
      const values = await form.validateFields();

      if (selectedPackageIds.length && shipment_product_id) {
        await attachTrackingId(shipment_product_id, [{
          tracking: values.tracking,
          package_id: selectedPackageIds[0],
        }]);

        form.resetFields()
        notification["success"]({
          message: "Add tracking successfully.",
        });
        setSelectedPackageIds([]);
        setTrackingModalOpen(false);
      } else {
        notification['error']({
          message:"Shipment product id not found!"
        })       }
    } catch (err) {
      notification['error']({
        message:getError(err)
      })
    }
  };

  const handleUpdateTracking = async () => {
    try {
      const trigger = await form.validateFields();
      if (
        selectedPackageIds.length &&
        shipment_product_id &&
        selectedTrackingId
      ) {
        await updateTrackingId(shipment_product_id, selectedTrackingId, {
          ...trigger,
        });
        form.resetFields()
        notification["success"]({
          message: "Update tracking successfully.",
        });
        setSelectedPackageIds([]);
        setSelectedTrackingId(undefined);
        setTrackingModalOpen(false);
      } else {
        notification['error']({
          message:getError('Shipment product number or tracking id not found!')
        })
      }
    } catch (err) {
      notification['error']({
        message:getError(err)
      })
    }
  };
  return (
    <Card
      title={title}
      loading={isLoading}
      bordered={false}
      extra={[
        <Dropdown menu={menuProps} key="main-actions">
          <Button icon={<HddFilled />}>
            Actions <DownOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      <List
        pagination={{ pageSize: 3 }}
        dataSource={ShipmentProductData?.getPackages().getData()}
        renderItem={(item, index) => {
          const shipmentItems: DescriptionsProps["items"] = [
            {
              key: "shipment_no",
              label: "Shipment no",
              children: item.getWarehouseShipment()?.getShipmentNumber(),
            },
            {
              key: "waybill_no",
              label: "Waybill no",
              children: item.getWarehouseShipment()?.getWaybillNumber(),
            },
            {
              key: "estimated_fly_time",
              label: "Estimated fly time",
              children: item.getWarehouseShipment()?.getEstimatedFlyTime(),
            },
          ];
          const shipmentCollapseItems: CollapseProps["items"] = [
            {
              key: "shipment_info",
              label: "Shipment informations",
              children: <Descriptions items={shipmentItems} />,
            },
          ];

          const additionalItems: DescriptionsProps["items"] = [
            {
              key: "package-no",
              label: "Package no",
              children: item.getPackageNumber(),
            },
            {
              key: "carton-no",
              label: "Carton no",
              children: item.getCartonNumber(),
            },
            {
              key: "contains",
              label: "Contains",
              children: item.getContains(),
            },
            {
              key: "fullfillment",
              label: "Fulfillment",
              children: item.getFulfillmentStatus(),
            },
            {
              key: "height",
              label: "Height",
              children: item.getHeight(),
            },
            {
              key: "weight",
              label: "Weight",
              children: item.getWeight(),
            },
            {
              key: "width",
              label: "Width",
              children: item.getWidth(),
            },
            {
              key: "length",
              label: "Length",
              children: item.getLength(),
            },
            {
              key: "cbm",
              label: "Cbm",
              children: item.getCbm(),
            },
          ];
          const additionalCollapseItems: CollapseProps["items"] = [
            {
              key: "additional-info",
              label: "Additional information",
              children: <Descriptions items={additionalItems} />,
            },
          ];
          return (
            <Card
              key={item.getPackageNumber()}
              title={`Package ${index + 1}`}
              bordered
              extra={[
                <Dropdown
                  menu={secondaryMenuProps}
                  onOpenChange={() => {
                    setSelectedPackageIds([item.getId()])
                    setSelectedPackage(item)

                  }}
                  key="package-actions"
                >
                  <Button icon={<HddFilled />}>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>,
              ]}
              style={{ marginBottom: 10 }}
            >
              <Space
                direction="vertical"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                {item.getImages().getData().length ? (
                  <Image.PreviewGroup>
                    <List
                      grid={{ gutter: 16, column: 8 }}
                      size="small"
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 3,
                      }}
                      dataSource={item.getImages().getData()}
                      renderItem={(item) => (
                        <Image width={50} height={50} src={item.getSrc()} />
                      )}
                    />
                  </Image.PreviewGroup>
                ) : (
                  "No images available"
                )}

                <Space direction="vertical" style={{ marginBottom: 10 }}>
                  {item.getTrackings().getData().length === 0 ? (
                    "No tracking available"
                  ) : (
                    <List
                      grid={{
                        gutter: 16,
                        sm: 1,
                        lg: 2,
                      }}
                      dataSource={item
                        .getTrackings()
                        .getData()
                        .map((tracking) => {
                          return {
                            id: tracking.getId(),
                            title: tracking.getType(),
                            content: tracking.getTracking(),
                          };
                        })}
                      renderItem={(tracking) => (
                        <List.Item key={tracking.id}>
                          <Typography.Text
                            strong
                            style={{ textTransform: "capitalize" }}
                          >
                            {tracking.title.replaceAll("-", " ")}:{" "}
                          </Typography.Text>{" "}
                          {tracking.content}{" "}
                          {tracking.title === "abroad-courier-tracking" &&
                            checkActionPermission(ADMIN_TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_EVENT_UPDATE,
                              <Button
                                style={{ padding: 0 }}
                                type="link"
                                onClick={() => {
                                  setSelectedPackageIds([item.getId()]);
                                  setSelectedTrackingId(tracking.id);
                                  setTrackingModalOpen(true);
                                  form.setFieldValue("type", tracking.title);
                                  form.setFieldValue("tracking", tracking.content);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                              ,
                              null
                            )
                          }
                        </List.Item>
                      )}
                    />
                  )}
                </Space>

                {item.getWarehouseShipment() ? (
                  <Collapse
                    bordered={false}
                    items={shipmentCollapseItems}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                  />
                ) : (
                  ""
                )}
                <Collapse
                  bordered={false}
                  items={additionalCollapseItems}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                />

                <Space
                  size="large"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Avatar
                    shape="square"
                    src={
                      <Image
                        src={item.getImages().getFirstImageSrc() || ""}
                        fallback={FALLBACK_IMAGE_URL}
                      />
                    }
                  />
                  <Typography.Text strong>
                    {ShipmentProductData?.getCurrency().getSymbolNative()}
                    {item.getProductPrice()} × {item.getQuantity()}
                  </Typography.Text>
                  <Typography.Text strong>
                    {ShipmentProductData?.getCurrency().getSymbolNative()}{" "}
                    {(item.getProductPrice() * item.getQuantity()).toFixed()}
                  </Typography.Text>
                </Space>

                {/* Variations */}
                {
                  checkActionPermission(ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_PACKAGE_VIEW,
                    <Button
                      onClick={() => {
                        setSelectedPackage(item);
                        setVariationsModalOpen(true);
                      }}
                      type="link"
                    >
                      Show Variations
                    </Button>
                    ,
                    null

                  )
                }
              </Space>
            </Card>
          );
        }}
      />

      {addCartonModalOpen && ShipmentProductData && (
        <Modal
          title="Add Carton"
          open={addCartonModalOpen}
          centered
          okText="Add"
          onOk={handleOwnSourceAddPackage}
          onCancel={() => setAddCartonModalOpen(false)}
          okButtonProps={{
            loading: isLoadingAddShipmentProductPackage,
            disabled:isLoadingAddShipmentProductPackage
        }}
        >
          <AddOwnSourceCartonModal
            form={form}
            onOk={handleOwnSourceAddPackage}
          />
        </Modal>
      )}

      <Modal
        title="Attach Carton Image"
        open={attachCartonImageModalOpen}
        centered
        okText="Attach"
        destroyOnClose={true}
        onOk={handleAttachCartonImage}
        onCancel={() => setAttachCartonImageModalOpen(false)}
        okButtonProps={{ loading: isLoadingAttachShipmentProductPackageImage }}
      >
        <AttachCartonImageModal form={form} onOk={handleAttachCartonImage} />
      </Modal>

      {selectedPackage && (
        <Modal
          open={variationsModalOpen}
          centered
          destroyOnClose={true}
          footer={null}
          onCancel={() => {
            setVariationsModalOpen(false);
            setSelectedPackage(undefined);
          }}
        >
          <CartonVariationsModal productPackage={selectedPackage} />
        </Modal>
      )}

      {ShipmentProductData && (
        <Modal
          destroyOnClose={true}
          title={selectedTrackingId ? `Update Tracking` : `Add Tracking`}
          open={trackingModalOpen}
          centered
          okText={selectedTrackingId ? `Update` : `Add`}
          onOk={
            selectedTrackingId ? handleUpdateTracking : handleAttachTracking
          }
          onCancel={() => {
            setTrackingModalOpen(false);
            setSelectedTrackingId(undefined);
          }}
          okButtonProps={{
            loading:
              isLoadingAttachShipmentProductTrackingId ||
              isLoadingUpdateShipmentProductTrackingId,
          }}
        >
          <TrackingModificationModal
            form={form}
            onOk={handleOwnSourceAddPackage}
          />
        </Modal>
      )}
    </Card>
  );
};
