import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { addressSchemaAtom } from "lib/core-react/store/store";
import { getError } from "../utils/errors";

export const useAddressSchemaRetrive = () => {
  const [addressSchema, setAddressSchema] = useAtom(addressSchemaAtom);

  const { addressService } = useService();

  const getAddressSchema = async (
    country: string,
    locale: string,
  ): Promise<void> => {
    setAddressSchema({ ...addressSchema, isLoading: true, error: null });
    try {
      const response = await addressService.addressSchemaResource.retrieve(
        country,
        locale,
      );
      setAddressSchema({
        ...addressSchema,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAddressSchema({
        ...addressSchema,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw Error
    }
  };

  return { getAddressSchema } as const;
};

export const useDwAddressSchema = () => {
  const [addressSchema, setAddressSchema] = useAtom(addressSchemaAtom);

  const { addressService } = useService();

  const getAddressSchema = async (
    country: string,
    language: string,
  ): Promise<void> => {
    setAddressSchema({ ...addressSchema, isLoading: true, error: null });
    try {
      const response =
        await addressService.addressSchemaResource.retrieveDwAddressSchema(
          country,
          language,
        );
      setAddressSchema({
        ...addressSchema,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAddressSchema({
        ...addressSchema,
        isLoading: false,
        data: undefined,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getAddressSchema } as const;
};
