import {
  AuctionRuleDataStatus,
  AuctionRuleEnum,
  AuctionRuleOperatorEnum,
  AuctionRuleTypeEnum,
} from "enums/auctionRuleCollectionEnums";
import {
  IAuctionRule,
  IAuctionRuleData,
  IAuctionRuleRequest,
} from "types/auctionRule";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class AuctionRuleDataModel {
  public readonly object: AuctionRuleEnum.AuctionRule;
  public readonly id: number;
  public readonly name: string;
  public readonly region_id: number;
  public readonly operator: AuctionRuleOperatorEnum;
  public readonly rule_type: AuctionRuleTypeEnum;
  public readonly shipping_category_id: string | null;
  public readonly value: number | null;
  public readonly status: AuctionRuleDataStatus;
  public readonly lock_version: number;
  public readonly etag: string;
  public readonly created_at: string;

  constructor(dataItem: IAuctionRuleData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.region_id = dataItem.region_id;
    this.operator = dataItem.operator;
    this.rule_type = dataItem.rule_type;
    this.shipping_category_id = dataItem.shipping_category_id;
    this.value = dataItem.value;
    this.status = dataItem.status;
    this.lock_version = dataItem.lock_version;
    this.etag = dataItem.etag;
    this.created_at = dataItem.created_at;
  }
}

export class AuctionRuleModel {
  public readonly object: AuctionRuleEnum.AuctionRuleCollection;
  public readonly data: AuctionRuleDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IAuctionRule) {
    this.object = data.object;
    this.data = data.data.map((item) => new AuctionRuleDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
}

//Post Request
export class AuctionRuleRequestModel {
  public readonly name: string;
  public readonly region_id: number;
  public readonly operator: AuctionRuleOperatorEnum;
  public readonly rule_type: AuctionRuleTypeEnum;
  public readonly shipping_category_id: string | null;
  public readonly value: number | null;
  public readonly status: AuctionRuleDataStatus;

  constructor(reqData: IAuctionRuleRequest) {
    this.name = reqData.name;
    this.region_id = reqData.region_id;
    this.operator = reqData.operator;
    this.rule_type = reqData.rule_type;
    this.shipping_category_id = reqData.shipping_category_id;
    this.value = reqData.value;
    this.status = reqData.status;
  }
}
