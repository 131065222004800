import { PageHeader } from "@ant-design/pro-layout";

const CreateOrderForCustomer = () => {



  return (
    <div>
      <PageHeader
        style={{ marginTop: "10px" }}
        ghost={false}
        title="Create Order for Customer"
        onBack={() => window.history.back()}
      >
        Not implemented yet
        {/* <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinish}
          // style={{ maxWidth: 600 }}
          autoComplete="off"
          initialValues={{ products: [{ packages: [{}] }] }}
        >
          <Row>
            <div className="label">
              <sup style={{ color: "red", fontSize: "12px" }}>* </sup>Select
              customer
            </div>
            <div
              style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
            >
              <CustomerSelect handleCustomerSelect={handleCustomerSelect} />
            </div>
          </Row>

          <Form.List name="products">
            {(fields, { add, remove }) => (
              <div
              // style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
              >
                <Row gutter={[20, 20]}>
                  {fields.map((field, index) => (
                    <Product
                      index={index}
                      key={field.key}
                      productRemove={remove}
                      customerId={
                        selectedCustomer ? selectedCustomer.id : undefined
                      }
                      field={field}
                    />
                  ))}
                </Row>

                <Button
                  type="dashed"
                  onClick={() => add()}
                  className=" my-2"
                  block
                >
                  + Add More Product
                </Button>
              </div>
            )}
          </Form.List>

          <Form.Item>
            <Button type="primary" className="my-2" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form> */}
      </PageHeader>
    </div>
  );
};

export default CreateOrderForCustomer;
