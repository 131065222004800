import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Row, Space, Table, Typography } from "antd";
import FiltersComponent from "../../../../components/FiltersComponent";
import { useGetAgentCompany } from "../../../../lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { agentCompanyCollectionAtom } from "../../../../lib/core-react/store/store";
import {
  AgentCompanyCollectionModel,
  AgentCompanyModel,
} from "../../../../models/agentCompanyCollectionModel";
import { PaginationModel } from "../../../../models/pagination";
import AgentCommissionSubmit from "./AgentCommissionSubmit";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";
import { ApiHelperModel } from "../../../../models/apiHelper";
import {IPagination} from "../../../../types/pagination";

const { Text } = Typography;

interface IProps {
  isApproveModalOpen: boolean;
  setIsApproveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: number;
  storeId?: number;
  selectedRowKeys: React.Key[];
}

const BuyProductAgentAssignModal: React.FC<IProps> = ({
                                                        setIsApproveModalOpen,
                                                        productId,
                                                        storeId,
                                                        selectedRowKeys,
                                                      }) => {
  const { getAgentCompanies } = useGetAgentCompany();
  const [{ data: agentCompanyCollectionData, isLoading }] = useAtom(
    agentCompanyCollectionAtom
  );

  const [filters, setFilters] = useState<Record<string, any>>({});
  const [isFetched, setIsFetched] = useState(false);
  const [paginationData, setPaginationData] = useState<IPagination | null>(null);

  const AgentCompanyCollectionData =
    agentCompanyCollectionData &&
    new AgentCompanyCollectionModel(agentCompanyCollectionData);

  useEffect(() => {
    if (storeId) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        purchase_store_id: storeId,
      }));
    }
  }, [storeId, productId]);

  useEffect(() => {
    if (filters && !isFetched) {
      ApiHelperModel.makeGetRequest(filters, getAgentCompanies).then(() => {
        setIsFetched(true);
      });
    }
  }, [filters, isFetched, getAgentCompanies]);

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const handelFilterClear = () => {
    const clearedFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = ''; // Reset each filter to an empty string or default value
      return acc;
    }, {} as Record<string, any>);
    setFilters(clearedFilters);
    setIsFetched(false); // To trigger re-fetching with cleared filters
    handleProductFilter(); // Re-fetch data with cleared filters
  };

  const handleProductFilter = () => {
    ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
  };

  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentCompanies
    );
  };

  useEffect(() => {
    if (AgentCompanyCollectionData) {
      setPaginationData(AgentCompanyCollectionData.getPagination());
    }
  }, [AgentCompanyCollectionData]);

  const paginationConfig = PaginationModel.getPaginationConfig(
    {pagination: paginationData},
    handlePaginationChange
  );
  const CompanyInfoColumn: React.FC<{ record: AgentCompanyModel }> = ({ record }) => {
    const { width } = useWindowWidth();

    const renderRow = (key: string, value: string) => {
      if (width < 400) {
        return (
          <Space direction="vertical">
            <Text strong>{key}</Text>
            <Text>{value}</Text>
          </Space>
        );
      }
      return (
        <Space>
          <Text strong>{key}</Text>
          <Text>{value}</Text>
        </Space>
      );
    };

    return (
      <Space direction="vertical">
        {renderRow("Business name: ", record.getPrimaryName())}
        {renderRow("Brand name: ", record.getSecondaryName())}
      </Space>
    );
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      render: (_: string, __: AgentCompanyModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
              index +
              1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Company info",
      dataIndex: "primary_name",
      key: "primary_name",
      render: (_: string, record: AgentCompanyModel) => (
        <CompanyInfoColumn record={record} />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: string, record: AgentCompanyModel) => {
        return (
          <AgentCommissionSubmit
            selectedRowKeys={selectedRowKeys}
            setIsApproveModalOpen={setIsApproveModalOpen}
            record={record}
            productId={productId}
          />
        );
      },
    },
  ];

  console.log(filters,"filters", AgentCompanyCollectionData?.getFilters())
  return (
    <Row>
      <Col span={24}>
        <PageHeader
          style={{ marginTop: "10px" }}
          ghost={false}
          title="Agent Companies"
        >
          <Row>
            <Col span={24}>
              <Card title="Filter">
                <FiltersComponent
                  handleProductFilter={handleProductFilter}
                  handleFilterChange={handleFilterChange}
                  handelFilterClear={handelFilterClear}
                  isFetched={isFetched}
                  filters={filters}
                  filtersData={AgentCompanyCollectionData?.getFilters()}
                  isFromProductReceived={true}
                />
              </Card>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={AgentCompanyCollectionData?.getData()}
            size="middle"
            loading={isLoading}
            pagination={paginationConfig}
            rowKey="id"
            scroll={{ x: 400 }}
          />
        </PageHeader>
      </Col>
    </Row>
  );
};

export default BuyProductAgentAssignModal;
