import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { shippingCartAtom } from "lib/core-react/store/store";

export const useGetShippingCart = () => {
  const [shippingCart, setShippingCart] = useAtom(shippingCartAtom);
  const { shippingCartService } = useService();
  const getShippingCart = async (params?: string): Promise<void> => {
    setShippingCart({ ...shippingCart, isLoading: true, error: null });
    try {
      const response = await shippingCartService.shippingCartResource.get(
        params
      );
      setShippingCart({
        ...shippingCart,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShippingCart({
        ...shippingCart,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShippingCart } as const;
};
