import React, { useEffect, createRef } from "react";
import { Button, Form, message, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import { exchangeGroupCollectionAtom } from "lib/core-react/store/store";
import {
    useGetExchangeGroup,
    useGetExchangeGroupCustomers,
    useUpdateExchangeGroupCustomer,
} from "lib/core-react/hooks/private/useExchangeGroup";

import {
    ExchangeGroupCollectionModel,
    ExchangeGroupModel,
} from "models/exchangeGroupCollectionModel";
import { IExchangeGroupUserRequest } from "types/exchangeGroupCollection";
import { CustomerUserModel } from "models/customerUserCollectionModel";
import { showError } from "helpers/showError";

interface IProps {
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
    data: ExchangeGroupModel;
    filterData: Record<string, any>;
}

const UpdateExchangeGroupCustomer: React.FC<IProps> = ({
    setIsShowUpdateModal,
    selectedCustomerUser,
    data,
    filterData,
}) => {
    const { getExchangeGroupCustomers } = useGetExchangeGroupCustomers();
    const { getExchangeGroup } = useGetExchangeGroup();
    const [
        {
            data: exchangeGroupCollectionDataAtom,
            isLoading: exchangeGroupLoading,
        },
    ] = useAtom(exchangeGroupCollectionAtom);

    const { updateExchangeGroupCustomer, isLoading } =
        useUpdateExchangeGroupCustomer();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [selectedCustomerUser, form]);

    useEffect(() => {
        getExchangeGroup({});
    }, []);

    const exchangeGroupCollectionData =
        exchangeGroupCollectionDataAtom &&
        new ExchangeGroupCollectionModel(exchangeGroupCollectionDataAtom);

    const onFinish = async (value: IExchangeGroupUserRequest) => {
        const payload = {
            user_id: selectedCustomerUser.getId(),
            exchange_group_id: Number(value.exchange_group_id),
        };

        try {
            const res = await updateExchangeGroupCustomer(
                payload as IExchangeGroupUserRequest,
            );
            if (res) {
                setIsShowUpdateModal(false);
                message.success(res.message);
                await getExchangeGroupCustomers(filterData);
            }
        } catch (error: any) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update-Exchange-Group-Customer"
            ref={formRef}
            initialValues={{
                exchange_group_id: data.getId(),
            }}
        >
            <Form.Item
                name="exchange_group_id"
                label="Exchange Group"
                rules={[
                    { required: true, message: "Exchange group is required" },
                ]}
            >
                <Select
                    loading={exchangeGroupLoading}
                    placeholder="Please select an exchange group"
                    options={exchangeGroupCollectionData?.getSelectComponentSelectOptions()}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateExchangeGroupCustomer;
