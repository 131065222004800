interface IResponse {
  statusCode: number;
  message: string;
}

export const prepareError = (error: any): IResponse => {
  console.log(error);
  let message = error?.message || "Something went wrong";
  let statusCode = 500;

  if (error?.response?.status) {
    if (error.response.status >= 400 && error.response.status < 500) {
      message = error.response?.data?.message;
      statusCode = error.response.status;
    }
  }
  return {
    statusCode,
    message,
  };
};
