import { Card, Col, List, Row, Typography } from "antd";

export const OrderDetail = ({ isLoading, productData }) => {
  const customer = productData?.getOrder().getCustomer();
  const orderHandler = productData?.getOrder().getOrderHandler();
  const shippingAddress = productData?.getShippingAddress().address;
  const hasAddress = shippingAddress && Object.keys(shippingAddress).length > 0;

  return (
    <Card bordered={false} loading={isLoading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Customer" bordered>
            <List
              grid={{ column: 2 }}
              dataSource={[
                {
                  title: "Name",
                  content: (
                    <Typography.Text>{customer?.getName()}</Typography.Text>
                  ),
                },
                {
                  title: "Phone",
                  content: (
                    <Typography.Text>
                      {customer?.getPhone() ? (
                        <a href={`tel:${customer.getPhone()}`}>
                          {customer.getPhone()}
                        </a>
                      ) : (
                        "Not available"
                      )}
                    </Typography.Text>
                  ),
                },
                {
                  title: "Email",
                  content: (
                    <Typography.Text>
                      {customer?.getPhone() ? (
                        <a href={`mailto:${customer.getEmail()}`}>
                          {customer.getEmail()}
                        </a>
                      ) : (
                        "Not available"
                      )}
                    </Typography.Text>
                  ),
                },
                {
                  title: "Shipping mark",
                  content: (
                    <Typography.Text>
                      {customer?.getShippingMark()}
                    </Typography.Text>
                  ),
                },
                {
                  title: "Delivery Address",
                  content: (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                      {hasAddress
                        ? Object.keys(shippingAddress).map((key) => {
                            return (
                              <div key={key}>
                                <Typography.Text
                                  italic
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: 500,
                                  }}
                                >
                                  {key.replaceAll("_", " ")}:
                                </Typography.Text>{" "}
                                {shippingAddress[key]?.toString()}
                              </div>
                            );
                          })
                        : "Address not found"}
                    </div>
                  ),
                },
              ]}
              renderItem={(item) => (
                <List.Item key={item.title}>
                  <Typography.Text strong>{item.title}: </Typography.Text>{" "}
                  {item.content}
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Order Handler" bordered>
            {orderHandler ? (
              <List
                grid={{ column: 2 }}
                dataSource={[
                  {
                    title: "Name",
                    content: (
                      <Typography.Text>
                        {orderHandler.getName()}
                      </Typography.Text>
                    ),
                  },
                  {
                    title: "Phone",
                    content: (
                      <Typography.Text>
                        {orderHandler.getPhone() ? (
                          <a href={`tel:${orderHandler.getPhone()}`}>
                            {orderHandler.getPhone()}
                          </a>
                        ) : (
                          "Not available"
                        )}
                      </Typography.Text>
                    ),
                  },
                  {
                    title: "Email",
                    content: (
                      <Typography.Text>
                        {orderHandler.getPhone() ? (
                          <a href={`mailto:${orderHandler.getEmail()}`}>
                            {orderHandler.getEmail()}
                          </a>
                        ) : (
                          "Not available"
                        )}
                      </Typography.Text>
                    ),
                  },
                  {
                    title: "ShippingMark",
                    content: (
                      <Typography.Text>
                        {orderHandler.getShippingMark()}
                      </Typography.Text>
                    ),
                  },
                ]}
                renderItem={(item) => (
                  <List.Item key={item.title}>
                    <Typography.Text strong>{item.title}: </Typography.Text>{" "}
                    {item.content}
                  </List.Item>
                )}
              />
            ) : (
              "Order handler not assigned yet."
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
