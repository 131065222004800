import { Alert, Button, Form, FormInstance, Select } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";
import { ShippingCountryDataStatus } from "enums/shippingCountryCollectionEnums";
import { useUpdateShippingCountry } from "lib/core-react/hooks/private";
import {
  ShippingCountryDataModel,
  ShippingCountryUpdateRequestModel,
} from "models/shippingCountry";
import React, { useState, useEffect } from "react";

interface IProps {
  data: ShippingCountryDataModel;
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateShippingCountry = ({ data, setIsShowUpdateModal }: IProps) => {

  const { updateShippingCountry, isLoading, isError } =
  useUpdateShippingCountry();

  const [visible, setVisible] = useState(false);
  
  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [data, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  const onFinish = async (value: ShippingCountryUpdateRequestModel) => {
    const payload = {
      status: value.status,
    };

    try {
      await updateShippingCountry(
        data.id as number,
        payload
      );
      setIsShowUpdateModal(false);
      setVisible(false);
    } catch (error) {
      setVisible(true);
    }
  };
  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="update_shipping_country"
          ref={formRef}
          initialValues={{
            status: data.status,
          }}
        >
          <Form.Item required name="status" label="Status">
            <Select placeholder="Please select a status"> {renderOptionsFromEnum(ShippingCountryDataStatus)}</Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {visible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default UpdateShippingCountry;
