import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Modal,
  Row,
  Table,
  notification,
  Tag,
  Typography,
  Image,
  TablePaginationConfig,
  Badge,
} from "antd";
import { ShipmentOrderCollectionModel } from "models/shipmentOrderCollectionModel";
import {
  ArrowRightOutlined,
  BorderOuterOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownOutlined,
  EditOutlined,
  HddFilled,
  HomeTwoTone,
  RadiusBottomleftOutlined,
  RadiusUpleftOutlined,
  UnorderedListOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";

import {
  useApprovedShippingProductForProduct,
  useRejectedShippingProduct
} from "lib/core-react/hooks/private/useShipping";
import { ShipmentProductModel } from "models/shipmentProductCollectionModel";
import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";

import {
  getShippingModeKeyByValue,
  getShippingTypeKeyByValue,
} from "pages/Shipping/ShipmentProducts/utils";
import {
  BidList,
  Carton,
  ChangeDestinationWarehouseModal,
  ChangeShippingCategoryModal,
  ChangeShippingCountryModal,
  ChangeShippingModeModal,
  ChangeShippingTypeModal,
  SelectWarehouseModal,
} from "pages/Shipping/ShipmentProducts";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_DESTINATION_PERMISSION_ENUM } from "consts/permission-enum/destination-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
import {  ADMIN_SHIPPING_CORE_PERMISSION_ENUM, ADMIN_SHIPPING_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";
import Trackings from "pages/Shipping/ShipmentProducts/EditShipmentProduct/Trackings/Trackings";
import { ShipmentProductPackageModel } from "models/shipmentProductPackageCollectionModel";
import {getError} from "../../../../lib/core-react/hooks/utils/errors";
import {useAtom} from "jotai/index";
import {shipmentProductsAtom} from "../../../../lib/core-react/store/store";

const { Column } = Table;

interface IProps {
  data?: ShipmentProductModel[];
  isLoading?: boolean;
  paginationConfig?: false | TablePaginationConfig;
}

const ShipmentProductsTable = ({
  data,
  isLoading,
  paginationConfig,
}: IProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<ShipmentProductModel[]>([]);
  const { approvedShippingProduct } = useApprovedShippingProductForProduct();
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);

  const { isMobile } = useWindowWidth();

  // to handle single product actions
  const [selectedProduct, setSelectedProduct] =
    useState<ShipmentProductModel>();

  const [packagesData, setPackagesData] =useState<{
    packages : ShipmentProductPackageModel[] | undefined,
    productId : string | number | undefined
  } >({
    packages :  undefined,
    productId : undefined
  });

  // hooks
  const { rejectedShippingProduct, isLoading: isRejectedLoading } =
    useRejectedShippingProduct();

  // State
  const [modalOpen, setModalOpen] = useState<
    "carton" | "reject" | "assignAgent" | "bidList" | "tracking" | "notMatch"
  >();
 const onCloseModal = ()=> {
  setModalOpen('notMatch')
 }

  const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
    ICategoryPriceDetails | undefined
  >(undefined);

  const agentAssignHandler = (record?: ShipmentProductModel) => {
    const firstRow = record ? record : selectedRows[0];
    const categoryDetails: ICategoryPriceDetails = {
      user_id: firstRow.getUserId(),
      region: firstRow.getRegionCode(),
      shipping_category_id: firstRow.getShippingCategoryId(),
      destination_warehouse_id: firstRow.getDestinationWarehouseId(),
      shipping_type: firstRow.getShippingType(),
      shipping_mode: firstRow.getShippingMode(),
      shipping_country_id: firstRow.getShippingCountryId(),
      region_id:firstRow.getRegion()?.id || 3
    };
    setCategoryPriceDetailsInfo(categoryDetails);
    setModalOpen("assignAgent");
  };

  const rejectedModalOkHandler = async () => {
    if (selectedProduct) {
      try {
        const data = await rejectedShippingProduct(
          selectedProduct.getId(),
        );
        if (data) {
          notification["success"]({
            message: data.message,
          });
          setModalOpen(undefined);
        }
      } catch (error: any) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  // Change shipping Country -
  const [changeShippingCountryDetails, setChangeShippingCountryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingShippingCountryId: number | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingShippingCountryId: undefined,
    });
  const changeShippingCountryHandler = (
    ids: number[],
    shippingCountryId: number | undefined,
  ) => {
    setChangeShippingCountryDetails({
      existingShippingCountryId: shippingCountryId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Destination warehouse
  const [
    changeDestinationWarehouseDetails,
    setChangeDestinationWarehouseDetails,
  ] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingWarehouseId: number | undefined;
    existingCountryId: number | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingWarehouseId: undefined,
    existingCountryId: undefined,
  });

  const changeDestinationWarehouseHandler = (
    ids: number[],
    warehouseId: number | undefined,
    countryId: number | undefined,
  ) => {
    setChangeDestinationWarehouseDetails({
      existingCountryId: countryId,
      existingWarehouseId: warehouseId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping category
  const [changeShippingCategoryDetails, setChangeShippingCategoryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingId: string | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingId: undefined,
    });

  const changeShippingCategoryHandler = (
    ids: number[],
    categoryId: string | undefined,
  ) => {
    setChangeShippingCategoryDetails({
      existingId: categoryId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping mode
  const [changeShippingModeDetails, setChangeShippingModeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingModesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingModeHandler = (
    ids: number[],
    shippingMode: ShippingModesEnums | undefined,
  ) => {
    setChangeShippingModeDetails({
      existingData: shippingMode,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping type
  const [changeShippingTypeDetails, setChangeShippingTypeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingTypesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingTypeHandler = (
    ids: number[],
    shippingType: ShippingTypesEnums | undefined,
  ) => {
    setChangeShippingTypeDetails({
      existingData: shippingType,
      isOpen: true,
      productIds: ids,
    });
  };

  const onCancelModal = () => {
    setSelectedProduct(undefined);
    setModalOpen(undefined);
    setCategoryPriceDetailsInfo(undefined);
  };

  const handleBulkApprove = async () => {
    const productsToApprove = selectedRows.filter(
      (product) =>
        product.getStatus() === "shipment-pending" ||
        product.getStatus() === "shipment-rejected"
    );

    if (productsToApprove.length === 0) {
      notification["info"]({
        message: "No Products to Approve",
        description: "All selected products are already approved or not eligible for approval.",
      });
      return;
    }

    const modal = Modal.confirm({
      title: "Confirm Bulk Approval",
      content: `Are you sure you want to approve these ${productsToApprove.length} products?`,
      okText: "Ok",
      cancelText: "No",
      onOk: async () => {
        const successCount = [] as ShipmentProductModel[];
        const failedCount = [] as ShipmentProductModel[];

        await Promise.all(
          productsToApprove.map(async (product) => {
            try {
              await approvedShippingProduct(product.getId());
              successCount.push(product);
            } catch (error) {
              failedCount.push(product);
            }
          })
        );

        setShipmentProductsData({
          ...shipmentProductsData,
          refetch: true,
        });
        // Provide feedback based on the results
        if (failedCount.length > 0) {
          notification["warning"]({
            message: "Bulk Approval Partial Success",
            description: `${successCount.length} products approved successfully, ${failedCount.length} failed.`,
          });
        } else {
          notification["success"]({
            message: "Bulk Approval Success",
            description: "All eligible products have been successfully approved.",
          });
        }

        modal.destroy();
      },
      onCancel: () => {
        notification["info"]({
          message: "Bulk Approval Cancelled",
          description: "The bulk approval action was cancelled.",
        });
      },
    });
  };



  const bulkItems: ExtendedMenuItemType[] = [
    {
      permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
      icon: <CheckCircleTwoTone />,
      label: "Assign Agent",
      key: "approve-and-assign-agent",
      onClick: () => agentAssignHandler(),
    },
    {
      permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
      icon: <CheckCircleTwoTone />,
      label: "Approve products",
      key: "approve-product",
      onClick: () => handleBulkApprove(),
      disabled: !selectedRows.some(
        (x) => x.getStatus() === 'shipment-pending' || x.getStatus() === 'shipment-rejected'
      ),
    },

  ];

  const isBulkStatusApprove = selectedRows.every(x=>x.getStatus() === "shipment-pending")

  if(isBulkStatusApprove){

    bulkItems.push(    {
        permission: ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,
        icon: <VerticalLeftOutlined />,
        label: "Change source country",
        key: "change-source-country",
        onClick: () =>
          changeShippingCountryHandler(
            selectedRowKeys.map((item) => Number(item)),
            undefined,
          ),
      },
      {
        permission: ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,
        icon: <HomeTwoTone />,
        label: "Change destination warehouse",
        key: "change-destination-warehouse",
        onClick: () =>
          changeDestinationWarehouseHandler(
            selectedRowKeys,
            selectedRows[0].getDestinationWarehouseId(),
            selectedRows[0].getDestinationCountryId(),
          ),
      },
      {
        permission: ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
        icon: <BorderOuterOutlined />,
        label: "Change shipping category",
        key: "change-shipping-category",
        onClick: () =>
          changeShippingCategoryHandler(
            selectedRowKeys.map((item) => Number(item)),
            undefined,
          ),
      },
      {
        permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE,
        icon: <RadiusBottomleftOutlined />,
        label: "Change shipping type",
        key: "change-shipping-type",
        onClick: () =>
          changeShippingTypeHandler(
            selectedRowKeys.map((item) => Number(item)),
            undefined,
          ),
      },
      {
        permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
        icon: <RadiusUpleftOutlined />,
        label: "Change shipping mode",
        key: "change-shipping-mode",
        onClick: () =>
          changeShippingModeHandler(
            selectedRowKeys.map((item) => Number(item)),
            undefined,
          ),
      },)


  }


  const { Text } = Typography;

  const handleApprove = (record: ShipmentProductModel) => {

    const modal = Modal.confirm({
      title: "Confirm Approval",
      content: "Are you sure you want to approve this product?",
      okText: "Ok",
      cancelText: "No",
      onOk: async () => {
        try {
          setSelectedProduct(record);
          await approvedShippingProduct(record.getId());
          notification["success"]({
            message: "Product Approved",
            description: "The product has been successfully approved.",
          });

          setShipmentProductsData({
            ...shipmentProductsData,
            refetch: true,
          });

          // Hide the modal after success
          modal.destroy();
        } catch (error) {
          notification["error"]({
            message: "Approval Failed",
            description:getError(error)
          });
        } finally {
        }
      },
      onCancel: () => {
      },
    });
  };


  return (
    <>
      <div style={{ marginTop: "15px" }}>
        <div style={{ margin: "20px 0", textAlign: "right" }}>
          <Dropdown
            menu={{

              items: bulkItems.filter((x) =>
                checkActionPermission(x.permission, x, null),
              ),
            }}
            disabled={selectedRowKeys.length === 0}
          >
            <Button icon={<HddFilled />}>
              Bulk Actions
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <Table
          loading={isLoading}
          dataSource={data}
          rowKey="id"
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              const rowsAsNumber = selectedRowKeys.map((key) => Number(key));
              setSelectedRowKeys(rowsAsNumber);
              // console.log(selectedRows[0].getStatus(),"selectedRows",selectedRowKeys)
              setSelectedRows(selectedRows);
            },
            getCheckboxProps: (record) => {
              // console.log(record,"record")
              return   {
                disabled: record.getStatus() !== 'shipment-pending' && record.getStatus() !== 'shipment-rejected' && record.getStatus() !== 'shipment-approved',
            }},
          }
        }
          bordered
          scroll={{ x: 1237 }}
          pagination={paginationConfig}
        >
          <Column
            ellipsis
            title="Product Details"
            dataIndex="product_details"
            key="product_details"
            width={350}
            render={(_: string, record: ShipmentProductModel) => (


              <div style={{ display: "flex", gap: "16px" }}>
                <Avatar
                  shape="square"
                  size={80}
                  style={{ flex: "none" }}
                  src={
                    <Image src={`${record.getImages().getFirstImageSrc()}`} />
                  }
                />
                <div>
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip: record.getTitle(),
                    }}
                  >
                    {record.getTitle()}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Order no:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getOrderNumber()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Order status:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getStatus()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Product no:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getProductNumber()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Product id:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getId()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Status:</Text>
                    <Tag
                      color={
                        ShipmentOrderCollectionModel.getStatusLabel(
                          record.getStatus(),
                        )?.color || ""
                      }
                    >
                      {record.getStatusLabel()}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Price:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {Math.ceil(record.getPackages().getTotalProductPrice())}{" "}
                      {record?.getRegion()?.getCurrency()?.getCode()}
                    </div>
                  </div>
                  <p style={{ margin: 0 }}>
                    Source:{" "}
                    <span
                      style={{
                        display: "inline-block",
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getOauthClientId() ? (
                        <Tag color="#F15412">MoveOnDrop</Tag>
                      ) : (
                        <Tag color="#87d068">MoveOn</Tag>
                      )}
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text style={{ whiteSpace: "nowrap" }}>
                      Shipping category:
                    </Text>
                    {
                      checkActionPermission(ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          disabled={ record.getStatus() !== "shipment-pending"}
                          onClick={() =>
                            changeShippingCategoryHandler(
                              [record.getId()],
                              record.getShippingCategoryId(),
                            )
                          }
                        >
                          <Text
                            style={{ width: 90, fontWeight: 500 }}
                            ellipsis={{
                              tooltip: record.getShippingCategory()?.getName() ,
                            }}
                          >
                            {record.getShippingCategory()?.getName()}
                          </Text>
                          { record.getStatus() === "shipment-pending" &&   <EditOutlined />

                          }
                        </Button>
                        ,
                        null
                      )
                    }

                  </div>
               <div
               >
                  {
                    checkActionPermission(
                      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
                      <Link
                        to={`/shipping/shipment-products/edit/${record.getId()}`}
                      >
                        View Details <ArrowRightOutlined />
                      </Link>
                      ,
                      null
                    )
                  }

                  {
                    <div    onClick={()=>{
                      setModalOpen('tracking')
                      setPackagesData({
                        packages : record.getPackages().getData(),
                        productId : record.getId()
                      })
                    }}

                    style={{ whiteSpace: "nowrap",color : '#1677ff', cursor : "pointer",marginTop : 6 }}>

                        <Badge offset={[16,0]} showZero count={record.getTrackingsCount() || 0} color="#1677ff">

                        <span style={{color:"#1677ff"}}>
                            View Trackings <ArrowRightOutlined />
                            </span>

                        </Badge>
                      {

                  }
                    </div>



                  }

                 </div>
                </div>
              </div>
            )}
          />

          <Column
            title="Cartons"
            dataIndex="packages"
            key="packages"
            width={150}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Quantity:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalQuantity() + " "}
                    pieces
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Weight:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalWeight().toFixed(3) + " "}
                    KG
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>CBM:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalCbm()}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Carton:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getData().length}
                  </div>
                </div>
                Create Destination Warehouse
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Contains:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    {Array.from(
                      new Set(
                        record
                          .getPackages()
                          .getData()
                          .map((item) => item.getContains()),
                      ),
                    ).join(", ")}
                  </div>
                </div>
                {

                  checkActionPermission(ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setSelectedProduct(record);
                        setModalOpen("carton");
                      }}
                    >
                      View Details <ArrowRightOutlined />
                    </Button>
                    ,
                    null

                  )
                }
              </div>
            )}
          />

          <Column
            title="Shipping & Rate"
            dataIndex="shipping"
            key="shipping"
            width={250}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <Row style={{ display: "flex", alignItems: "flex-start" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Type:</Text>
                    {
                      checkActionPermission(ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE,
                        <Button
                          style={{ padding: 0 }}
                          disabled={record.getStatus() !== "shipment-pending"}
                          type="link"
                          onClick={() =>
                            changeShippingTypeHandler(
                              [record.getId()],
                              record.getShippingType() as unknown as ShippingTypesEnums,
                            )
                          }
                        >
                          <Text
                            style={{ maxWidth: 95, fontWeight: 500 }}
                            ellipsis={{
                              tooltip: getShippingTypeKeyByValue(
                                record.getShippingType(),
                              ),
                            }}
                          >
                            {getShippingTypeKeyByValue(record.getShippingType())}
                          </Text>
                          { record.getStatus() === "shipment-pending" && <EditOutlined />

                          }

                        </Button>,
                        null
                      )
                    }
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Mode:</Text>

                    {
                      checkActionPermission(ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
                        <Button
                          style={{ padding: 0 }}
                          disabled={record.getStatus() !== "shipment-pending"}
                          type="link"
                          onClick={() =>
                            changeShippingModeHandler(
                              [record.getId()],
                              record.getShippingMode() as unknown as ShippingModesEnums,
                            )
                          }
                        >
                          <Text
                            style={{ maxWidth: 95, fontWeight: 500 }}
                            ellipsis={{
                              tooltip: getShippingModeKeyByValue(
                                record.getShippingMode(),
                              ),
                            }}
                          >
                            {getShippingModeKeyByValue(record.getShippingMode())}
                          </Text>
                          { record.getStatus() === "shipment-pending" && <EditOutlined />
                          }
                        </Button>,
                        null

                      )
                    }

                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Source:</Text>
                    {

                      checkActionPermission(ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
                        <Button
                          style={{
                            padding: 0,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5,
                          }}
                          disabled={record.getStatus() !== "shipment-pending"}
                          type="link"
                          onClick={() =>
                            changeShippingCountryHandler(
                              [record.getId()],
                              record.getShippingCountryId(),
                            )
                          }
                        >
                          <img
                            src={record
                              .getShippingCountry()
                              ?.getCountry()
                              .getFlag()}
                            style={{ width: 18, height: 18 }}
                            alt=""
                          />
                          <Text style={{ fontWeight: 500 }}>
                            {record.getShippingCountry()?.getCountry().getName()}
                          </Text>
                          { record.getStatus() === "shipment-pending" && <EditOutlined />
                          }
                        </Button>,
                        null
                      )
                    }

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text style={{ whiteSpace: "nowrap" }}>Destination:</Text>
                    {

                      checkActionPermission(ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,

                        <Button
                          style={{
                            padding: 0,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 5,
                            fontWeight: "500",
                          }}
                          disabled={record.getStatus() !== "shipment-pending"}
                          type="link"
                          onClick={() =>
                            changeDestinationWarehouseHandler(
                              [record.getId()],
                              record.getDestinationWarehouseId(),
                              record.getDestinationCountryId(),
                            )
                          }
                        >
                          <img
                            src={record
                              .getDestinationWarehouse()
                              ?.getCountry()
                              .getFlag()}
                            style={{ width: 18, height: 18 }}
                            alt=""
                          />
                          <Text
                            style={{ maxWidth: 90 }}
                            ellipsis={{
                              tooltip: record.getDestinationWarehouse()?.getName(),
                            }}
                          >
                            {record.getDestinationWarehouse()?.getName()}
                          </Text>
                          { record.getStatus() === "shipment-pending" && <EditOutlined />
                          }
                        </Button>,
                        null

                      )

                    }
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Customer rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getCustomerAssociation()?.getRate() ? (
                        <Tag color="success">
                          {record.getCustomerAssociation()?.getRate()}
                        </Tag>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Agent rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getAgentAssociation()?.getRate()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>MoveOn rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getMoveonAssociation()?.getRate()}
                    </div>
                  </div>

                  {

                    checkActionPermission(ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                      <>

                        {record.getStatusLabel() !== "Pending" &&
                          record.getStatusLabel() !== "Rejected" && (
                            <Button
                              type="link"
                              style={{ padding: 0 }}
                              onClick={() => {
                                setSelectedProduct(record);
                                setModalOpen("bidList");
                              }}
                            >
                              Bid List <ArrowRightOutlined />
                            </Button>
                          )}
                      </>,
                      null

                    )
                  }
                </div>
              </Row>
            )}
          />

          <Column
            title="Customer & Shipping Partner"
            dataIndex="packages"
            key="packages"
            width={250}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <div>
                <p style={{ margin: 0 }}>
                  Customer Name:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {record.getOrder().getCustomer().getName()}
                  </span>
                </p>
                <div style={{ margin: 0 }}>
                  Customer Email:{" "}
                  <Text
                    style={{ fontWeight: 500, maxWidth: 130 }}
                    ellipsis={{
                      tooltip: record.getOrder().getCustomer().getEmail(),
                    }}
                  >
                    <a
                      href={`mailto:${record.getOrder().getCustomer().getEmail()}`}
                    >
                      {record.getOrder().getCustomer().getEmail()}
                    </a>
                  </Text>
                </div>
                <div style={{ margin: 0 }}>
                  Customer Phone:{" "}
                  <Text
                    style={{ fontWeight: 500, maxWidth: 130 }}
                    ellipsis={{
                      tooltip: record.getOrder().getCustomer().getPhone(),
                    }}
                  >
                    <a
                      href={`tel:${record.getOrder().getCustomer().getPhone()}`}
                    >
                      {record.getOrder().getCustomer().getPhone()}
                    </a>
                  </Text>
                </div>
                <p style={{ margin: 0 }}>
                  Shipping mark:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record.getOrder().getCustomer().getShippingMark()}
                  </span>
                </p>
                <p style={{ margin: 0 }}>
                  Order Handler:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record?.getOrder()?.getOrderHandler()?.getName()}
                  </span>
                </p>
                <p style={{ margin: 0 }}>
                  Shipping Agent:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record
                      .getAgentAssociation()
                      ?.getAgentCompany()
                      .getPrimaryName()}
                  </span>
                </p>
              </div>
            )}
          />

          <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            width={110}
            fixed={isMobile ? "right" : undefined}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => {
              const items: ExtendedMenuItemType[] = [
                {
                  permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                  icon: <CheckCircleTwoTone />,
                  label: "Assign Agent",
                  key: "approve-and-assign-agent",
                  onClick: () => {
                    setSelectedProduct(record);
                    agentAssignHandler(record);
                  },
                },
                {
                  permission: ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                  icon: <CloseCircleTwoTone />,
                  label: "Reject product",
                  key: "reject-product",
                  onClick: () => {
                    setSelectedProduct(record);
                    setModalOpen("reject");
                  },
                },
                {
                  permission: ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
                  icon: <UnorderedListOutlined />,
                  label: "Bid list",
                  key: "bid-list",
                  onClick: () => {
                    setSelectedProduct(record);
                    setModalOpen("bidList");
                  },
                },

              ];

               if(record.getStatus() === "shipment-pending" || record.getStatus() === "shipment-rejected"){
                 items.unshift({
                     permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                     icon: <CheckCircleTwoTone />,
                     label: "Approve product",
                     key: "approve-products",
                     onClick: () => {
                     handleApprove(record);
                   }})
               }if(record.getStatus() === "shipment-pending"){

                 items.push(        {
                     permission: ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
                     icon: <VerticalLeftOutlined />,
                     label: "Change source country",
                     key: "change-source-country",
                     onClick: () =>
                       changeShippingCountryHandler(
                         [record.getId()],
                         record.getShippingCountryId(),
                       ),
                   },
                   {
                     permission: ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,
                     icon: <HomeTwoTone />,
                     label: "Change destination warehouse",
                     key: "change-destination-warehouse",
                     onClick: () =>
                       changeDestinationWarehouseHandler(
                         [record.getId()],
                         record.getDestinationWarehouseId(),
                         record.getDestinationCountryId(),
                       ),
                   },
                   {
                     permission: ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
                     icon: <BorderOuterOutlined />,
                     label: "Change shipping category",
                     key: "change-shipping-category",
                     onClick: () =>
                       changeShippingCategoryHandler(
                         [record.getId()],
                         record.getShippingCategoryId(),
                       ),
                   },
                   {
                     permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE,
                     icon: <RadiusBottomleftOutlined />,
                     label: "Change shipping type",
                     key: "change-shipping-type",
                     onClick: () =>
                       changeShippingTypeHandler(
                         [record.getId()],
                         record.getShippingType(),
                       ),
                   },
                   {
                     permission: ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
                     icon: <RadiusUpleftOutlined />,
                     label: "Change shipping mode",
                     key: "change-shipping-mode",
                     onClick: () =>
                       changeShippingModeHandler(
                         [record.getId()],
                         record.getShippingMode(),
                       ),
                   },)


               }

              return (
                <Dropdown
                  // disabled={record.getStatus() !== "shipment-pending"}
                  menu={{
                    items: items.filter((x) =>
                      checkActionPermission(x.permission, x, null),
                    ),
                  }}
                >
                  <Button icon={<HddFilled />}>
                    {isMobile ? (
                      <DownOutlined />
                    ) : (
                      <>
                        Actions <DownOutlined />{" "}
                      </>
                    )}
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </div>

      {categoryPriceDetailsInfo && (
        <SelectWarehouseModal
          categoryPriceDetails={categoryPriceDetailsInfo}
          selectedProductIds={
            selectedProduct ? [selectedProduct.getId()] : selectedRowKeys
          }
          selectedProducts={selectedProduct ? [selectedProduct] : selectedRows}
          onCancel={onCancelModal}
          open={modalOpen === "assignAgent"}
        />
      )}
      <Modal
        title="Are you sure?"
        open={modalOpen === "reject"}
        onOk={rejectedModalOkHandler}
        confirmLoading={isRejectedLoading}
        onCancel={onCancelModal}
      >
        Are you sure want to reject?
      </Modal>

      {changeShippingCountryDetails.productIds &&
        changeShippingCountryDetails.productIds.length && (
          <ChangeShippingCountryModal
            existingShippingCountryId={
              changeShippingCountryDetails.existingShippingCountryId
            }
            isOpen={changeShippingCountryDetails.isOpen}
            productIds={changeShippingCountryDetails.productIds}
            onCancel={() =>
              setChangeShippingCountryDetails({
                isOpen: false,
                existingShippingCountryId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeDestinationWarehouseDetails.productIds &&
        changeDestinationWarehouseDetails.productIds.length &&
        changeDestinationWarehouseDetails.existingWarehouseId && (
          <ChangeDestinationWarehouseModal
            country_id={changeDestinationWarehouseDetails.existingCountryId}
            existingWarehouseId={
              changeDestinationWarehouseDetails.existingWarehouseId
            }
            isOpen={changeDestinationWarehouseDetails.isOpen}
            productIds={changeDestinationWarehouseDetails.productIds}
            onCancel={() =>
              setChangeDestinationWarehouseDetails({
                isOpen: false,
                existingWarehouseId: undefined,
                existingCountryId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingCategoryDetails.productIds &&
        changeShippingCategoryDetails.productIds.length && (
          <ChangeShippingCategoryModal
            existingId={changeShippingCategoryDetails.existingId}
            isOpen={changeShippingCategoryDetails.isOpen}
            productIds={changeShippingCategoryDetails.productIds}
            onCancel={() =>
              setChangeShippingCategoryDetails({
                isOpen: false,
                existingId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingModeDetails.productIds &&
        changeShippingModeDetails.productIds.length && (
          <ChangeShippingModeModal
            existingMode={changeShippingModeDetails.existingData}
            isOpen={changeShippingModeDetails.isOpen}
            productIds={changeShippingModeDetails.productIds}
            onCancel={() =>
              setChangeShippingModeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingTypeDetails.productIds &&
        changeShippingTypeDetails.productIds.length && (
          <ChangeShippingTypeModal
            existingType={changeShippingTypeDetails.existingData}
            isOpen={changeShippingTypeDetails.isOpen}
            productIds={changeShippingTypeDetails.productIds}
            onCancel={() =>
              setChangeShippingTypeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      <Modal
        width={modalOpen === "bidList" || modalOpen === "tracking" || modalOpen === "notMatch" ? "900px" : undefined}
        open={modalOpen === "carton" || modalOpen === "bidList" || modalOpen === "tracking"}
        onCancel={onCancelModal}
        destroyOnClose={true}
        footer={null}
        title={
          modalOpen === "bidList"
            ? "Bid List"
            : modalOpen === "carton"
              ? "Package Details"
              : modalOpen === "tracking" ? "Tracking" : ""
        }
      >
        {selectedProduct && modalOpen === "carton" && (
          <Carton ShipmentProductData={selectedProduct} isLoading={false} />
        )}

        {selectedProduct && modalOpen === "bidList" && (
          <BidList product={selectedProduct} />
        )}

        { modalOpen === "tracking" && (
          <Trackings action="add" onCloseModal={onCloseModal} {...packagesData} />
        )}

      </Modal>
    </>
  );
};

export default ShipmentProductsTable;
