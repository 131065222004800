export enum ShippingModesEnums {
  International = "international",
  Cargo = "cargo",
  P2p = "p2p",
}
export enum ShippingTypesEnums {
  "By Air" = "air",
  "By Ship" = "ship",
  "By Road" = "road",
}
export enum ShippingTrackingTypesEnums {
  "abroad-courier-tracking" = "abroad-courier-tracking",
  "global-tracking" = "global-tracking",
}
export enum ProductSourceEnums {
  "website" = "website",
}
export enum ShippingModeEnums {
  "air" = "air",
  "ship" = "ship",
}
export enum ShippingTypeEnums {
  "international" = "international",
  "cargo" = "cargo",
  "p2p" = "p2p",
}
export enum ShippingAddonServiceEnums {
  "Inspections" = "0",
  "Insurance" = "1",
  "Packing Box/Bag Replace" = "2",
  "Customized Labelling" = "3",
  "Tag Removal" = "4",
  "Bubble Mailer Packaging" = "5",
}
