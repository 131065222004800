import {notification} from "antd";
import { FormInstance } from "antd/lib";
import { handleFormErrors } from "utils/helper";

export const showError = (error: any, form?: FormInstance) => {
    const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : error.message;
    if (form) {
        if (error?.response?.status === 422) {
            handleFormErrors(form, error.response);
        }
    }
    // if (error?.response?.status !== 422) {
    //     message.error(errorMessage);
    // }
    notification.error({message:errorMessage});
};
