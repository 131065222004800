import { Form, Button, Alert, notification } from "antd";
import { useDeleteCampaign } from "lib/core-react/hooks/private/useCampaign";
import { CampaignModel } from "models/campaignCollectionModel";
import { useState, useEffect } from "react";

interface IProps {
  setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCampaign: CampaignModel;
}

const DeleteCampaign = ({ setIsShowDeleteModal, selectedCampaign }: IProps) => {
  const { deleteCampaign, isLoading, isError } = useDeleteCampaign();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onConfirmDelete = async () => {
    try {
      await deleteCampaign(selectedCampaign.id);
      setIsShowDeleteModal(false);
      notification["success"]({
        message: "Updated Admin User successfully",
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <h3>Are you sure you want to delete this Campagin?</h3>
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ minWidth: "100px" }}
              disabled={isLoading}
              onClick={() => setIsShowDeleteModal(false)}
            >
              No
            </Button>
            <Button
              style={{ marginLeft: "10px", minWidth: "100px" }}
              type="primary"
              loading={isLoading}
              onClick={onConfirmDelete}
            >
              Yes
            </Button>
          </div>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default DeleteCampaign;
