import { DiscountRulesSchemaEnum } from "enums/discountCollectionEnum";
import {
  IDiscountRuleSchemaData,
  IDiscountRulesSchema,
  IDiscountRulesSchemaDataType,
  IDiscountRulesSchemaDataTypeSchema,
} from "types/discountRulesSchema";

export class DiscountRulesSchemaDataTypeSchemaModel {
  type: string;
  label: string;
  placeholder: string;
  is_required: boolean;

  constructor(data: IDiscountRulesSchemaDataTypeSchema) {
    this.type = data.type;
    this.label = data.label;
    this.placeholder = data.placeholder;
    this.is_required = data.is_required;
  }

  getType = () => this.type;
  getLabel = () => this.label;
  getPlaceholder = () => this.placeholder;
  getIsRequired = () => this.is_required;
}

export class DiscountRulesSchemaDataTypeModel {
  [key: string]: DiscountRulesSchemaDataTypeSchemaModel;

  constructor(data: IDiscountRulesSchemaDataType) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = new DiscountRulesSchemaDataTypeSchemaModel(data[key]);
      }
    }
  }
}

export class DiscountRulesSchemaDataModel {
  [key: string]: DiscountRulesSchemaDataTypeModel;

  constructor(data: IDiscountRuleSchemaData) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = new DiscountRulesSchemaDataTypeModel(data[key]);
      }
    }
  }
}

export class DiscountRulesSchemaModel {
  object: DiscountRulesSchemaEnum.DiscountRulesSchema;
  data: DiscountRulesSchemaDataModel;

  constructor(dataItem: IDiscountRulesSchema) {
    this.object = dataItem.object;
    this.data = new DiscountRulesSchemaDataModel(dataItem.data);
  }

  getData = () => this.data;
  getDataByKey = (key: string) => this.getData()[key];
  getDataKeysAsObject() {
    const obj: { [key: string]: string } = {};
    Object.keys(this.getData()).forEach((key) => {
      obj[key] = key.replaceAll("-", " ");
    });
    return obj;
  }
}
