import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import FiltersComponent from "components/FiltersComponent";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";

import {
  agentCompanyCollectionAtom,
  rfqRequestListAtom,
} from "lib/core-react/store/store";

import { ApiHelperModel } from "models/apiHelper";

import TextLine from "pages/RefundManage/Refund/components/TextLine";
import { useEffect, useState } from "react";
import { IFilterType } from "types/filters";
import { v4 } from "uuid";
import type { SelectProps } from "antd";
import { useGetAgentCompany } from "lib/core-react/hooks/private";
import { AgentCompanyCollectionModel } from "models/agentCompanyCollectionModel";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from "@ant-design/icons";
import moment from "moment";
import QueryString from "qs";
import useNotifyRfq from "./hooks/useNotifyRfq";
import { Link } from "react-router-dom";
import { ImagesModal } from "./components";
import { rejectReasonOptions } from "./utils";
import {
  RfqRequestCollectionModel,
  RfqRequestModel,
  RfwImagesModel,
} from "models/rfqRequestCollectionModel";
import {
  useGetRfq,
  useUpdateRfqApprove,
  useUpdateRfqReject,
} from "lib/core-react/hooks/private/useRfq";
import { IRfqRequestsStatus } from "lib/core/resources/private/rfqResource";
import StatusTag from "components/StatusTag/StatusTag";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_RFQ_PERMISSION_ENUM } from "consts/permission-enum/RFQ-enum";
import { PaginationModel } from "models/pagination";
import CommonError from "components/Error/CommonError";


const RfqManage = () => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedItem, setSelectedItem] = useState<RfqRequestModel>();
  const { contextHolder, onNotification } = useNotifyRfq();
  const [form] = Form.useForm(); // Create a form instance
  const [visible, setVisible] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const { isMobile } = useWindowWidth();
  const [isOpenImageModal, setIsOpenImageModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<RfwImagesModel[]>([]);

  const onClose = () => {
    setIsOpenImageModal(false);
  };

  const imagesDetailsHandler = (product) => {
    setSelectedProduct(product);
    setIsOpenImageModal(true);
  };

  // -----------------------------resource hooks
  const { getRequestsList } = useGetRfq();
  const { updateRequestsApprove } = useUpdateRfqApprove();
  const { updateRequestsReject } = useUpdateRfqReject();
  const { getAgentCompanies } = useGetAgentCompany();

  const [{ data: requestsData, isLoading: isLoadingRequests,error,unAuthorized }] =
    useAtom(rfqRequestListAtom);
  const [{ data: agentCompanyCollectionData }] = useAtom(
    agentCompanyCollectionAtom,
  );

  // ---------------------------filter
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  const handleGetApiRequest = () => {
    ApiHelperModel.makeGetRequest(
      {
        region: "BD",
        locale: "en",
      },
      getRequestsList,
    );

    ApiHelperModel.makeGetRequest(
      {
        region: "BD",
        locale: "en",
        per_page: 1000 //FIXME: it will be dynamic in future. do not give here statically big number. per_page will be 25 and when scroll down then api will be call
      },
      getAgentCompanies,
    );
  };

  // ---------------------------Api Call
  useEffect(() => {
    handleGetApiRequest();
  }, [refetchFromFilter]);

  // -------------------------model

  const rfqRequestCollectionModel =
    requestsData && new RfqRequestCollectionModel(requestsData);
  const agentCompanyCollectionModel =
    agentCompanyCollectionData &&
    new AgentCompanyCollectionModel(agentCompanyCollectionData);

  // filter and pagination
  const filterData = rfqRequestCollectionModel?.getFilters();
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && rfqRequestCollectionModel?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    rfqRequestCollectionModel?.getFilters(),
  ]);

  // Filter Handler
  const handleProductFilter = () => {
    filters &&
      ApiHelperModel.makeGetRequest(
        {
          region: "BD",
          locale: "en",
          ...filters,
        },
        getRequestsList,
      );
  };

  // ------------------data
  const requestsList = rfqRequestCollectionModel?.getData();
  const agentCompanies: SelectProps["options"] = agentCompanyCollectionModel
    ?.getData()
    .map((item) => {
      return {
        label: item.getPrimaryName(),
        value: item.getId(),
      };
    });
  // ------------------------------ Modal -> --------------------------- //

  const showModal = (type: "approve" | "reject", record: RfqRequestModel) => {
    if (type === "approve") {
      setActionType(() => "approve");
      setSelectedItem(() => record);
      setVisible(true);
    } else if (type === "reject") {
      setActionType(() => "reject");
      setSelectedItem(() => record);
      setVisible(true);
    } else {
      setActionType(null);
    }
  };

  const handleCancel = () => {
    setVisible(false); // Hide the modal when canceled
  };

  const handleOk = () => {
    form
      .validateFields() // Validate the form
      .then((values) => {
        handleUpdate(values);
        setVisible(false); // Hide the modal
      })
      .catch(() => {
        onNotification({
          reason: `Some went wrong. please communcate with developer`,
          status: "error",
        });
      });
  };

  // ------------------------------ Approved -> --------------------------- //

  const handleApprove = async (inputData) => {
    try {
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const payload: IRfqRequestsStatus = {
        source_agent_company_ids: inputData?.source_agent_company_ids,
        shipping_agent_company_ids: inputData?.shipping_agent_company_ids,
        valid_until: time,
      };
      const id = selectedItem?.getId();
      const params = QueryString.stringify({
        region: "BD",
        locale: "en",
      });
      if (id) {
        const response = await updateRequestsApprove({
          requestId: `${id}`,
          payload,
          params,
        });
        if (
          response &&
          response?.data &&
          response?.data.status === "approved"
        ) {
          onNotification({
            status: "success",
            reason: "Approved Successfully",
          });
          handleGetApiRequest();
        }
      } else {
        onNotification({
          status: "error",
          reason: "Request Id does not Found",
        });
      }
    } catch (error: any) {
      const message =
        error &&
        error?.response &&
        error.response?.data &&
        error.response?.data?.message
          ? error.response?.data?.message
          : "Something went wrong";
      if (message) {
        onNotification({
          reason: `${message}`,
          status: "error",
        });
      }
    }
  };

  const handleReject = async (inputData) => {
    try {
      const payload = {
        reject_reason: inputData?.reject_reason,
      };

      const id = selectedItem?.getId();

      if (id) {
        const params = QueryString.stringify({
          region: "BD",
          locale: "en",
        });
        const response = await updateRequestsReject({
          requestId: `${id}`,
          payload,
          params,
          customHeaders: {},
        });
        if (response && response?.data && response?.data.status === "closed") {
          onNotification({
            status: "success",
            reason: "Rejected Successfully",
          });
          handleGetApiRequest();
        }
      } else {
        onNotification({
          status: "error",
          reason: "Request Id does not Found",
        });
      }
    } catch (error: any) {
      const message =
        error &&
        error?.response &&
        error.response?.data &&
        error.response?.data?.message
          ? error.response?.data?.message
          : "Something went wrong";

      if (error && error?.response && error.response?.status !== 400) {
        onNotification({
          reason: `Internal Server Error`,
          status: "error",
        });
      } else if (message) {
        onNotification({
          reason: `${message}`,
          status: "error",
        });
      }
    }
  };

  const handleUpdate = async (inputData) => {
    if (actionType === "approve") {
      handleApprove(inputData);
    } else if (actionType === "reject") {
      handleReject(inputData);
    }
  
  };



  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
          region: "BD",
          locale: "en",
          ...filters,
        
        ...pageInfo,
      },
      getRequestsList,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    requestsData,
    handlePaginationChange,
  );








  // ------------------------------ui------------------------
  if (unAuthorized || error) {
    return <CommonError unAuthorized={unAuthorized} message={error}  />;
  }

  const columns: TableColumnsType<RfqRequestModel> = [
    {
      title: "Images",
      dataIndex: "images",
      width: isMobile ? 100 : 150,
      key: "id",
      render: (tags: any, record) => {
        const images = record.getRfqImages();
        const imageName = images[0]?.getImage()?.getImageName();
        const imageSrc = images[0]?.getImage()?.getImageSrc();
        return (
          <Row gutter={8} align={"middle"} justify={"center"}>
            <Col key={v4()}>
              {images ? (
                <Image
                  height={isMobile ? 72 : 100}
                  width={isMobile ? 72 : 100}
                  wrapperStyle={{ marginBottom: 4 }}
                  alt={imageName || ""}
                  src={imageSrc || ""}
                  preview={false}
                  style={{
                    borderRadius: 4,
                    boxShadow: "#f0f0f0 1px 1px 1px 1px",
                  }}
                />
              ) : (
                <Skeleton.Image
                  active={true}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 4,
                    marginBottom: 4,
                  }}
                />
              )}
            </Col>
          </Row>
        );
      },
    },
    
    {
      title: "Product Info",
      dataIndex: "product_info",
      key: "id",
      render: (_tags: any, record,) => {
        return (
          <div style={{width: isMobile ? "100%" : 500}}>
            <Col>
              <Space direction="vertical" size={2}>
                <div style={{ padding: 0, margin: 0, whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Requested Number :{" "}
                  </span>
                  <Typography.Text copyable>
                    {record?.getRequestedNumber()}
                  </Typography.Text>
                </div>
                <TextLine label="Request Id : " value={record?.getId()} />
                <TextLine
                  label="Product Name : "
                  value={record?.getProductName()}
                />
  
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>Product link: </span>
                  <Link to={record?.getProductLink()} target="_blank">
                    view
                  </Link>
                </p>
                <TextLine
                  label="Purchase Quantity : "
                  value={record?.getPurchaseQuantity()}
                />

                <TextLine
                  label="Region Code : "
                  value={record?.getRegionCode()}
                />
                <p
                  style={{
                    margin: "0 0",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Status:{" "}
                  </span>
                  <StatusTag
                    text={record?.getStatus()}
                    slug={record?.getStatus()}
                  />
                </p>
              <TextLine

                  label="Description : "
                  value={record?.getProductDescription()}
                />

                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => imagesDetailsHandler(record?.getRfqImages())}
                >
                  View All Images <ArrowRightOutlined />
                </Button>
              </Space>
            </Col>
          </div>
        );
      },
    },

    {
      title: "Shipping",
      dataIndex: "shipping",
      width: 450,
      colSpan: 1,
      key: "id",
      render: (_tags: any, record, ) => {
        const expireTime = record?.getValidUntil();
        return (
          <Row>
            <Col>
              <Space direction="vertical" size={2}>
                <p style={{ margin: "0 0", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>Shipping Type :</span>
                  <span
                    style={{
                      marginLeft: 3,
                    }}
                  >
                    {record?.getShippingType()}
                  </span>
                </p>
                <p style={{ margin: "0 0", whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>Shipping Mode :</span>
                  <span
                    style={{
                      marginLeft: 3,
                    }}
                  >
                    {record?.getShippingMode()}
                  </span>
                </p>
                {expireTime && (
                  <p style={{ margin: "0 0", whiteSpace: "nowrap" }}>
                    <span style={{ fontWeight: "bold" }}>Valid Until :</span>
                    <span
                      style={{
                        marginLeft: 3,
                      }}
                    >
                      {expireTime}
                    </span>
                  </p>
                )}

                {
                  <p style={{ margin: "0 0", whiteSpace: "nowrap" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Destination Warehouse Name :
                    </span>

                    <span
                      style={{
                        marginLeft: 3,
                      }}
                    >
                      {record?.getWarehouseName()}
                    </span>
                  </p>
                }
              </Space>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "User",
      dataIndex: "user",
      key: "id",
      render: (_tags: any, record, ) => {
        return (
          <Row>
            <Col>
              <Space direction="vertical" size={1}>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>Name : </span>
                  <span>{record?.getUserName()}</span>
                </p>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>Email : </span>
                  <span>{record?.getUserEmail()}</span>
                </p>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>User Type : </span>
                  <span>{record?.getUserType()}</span>
                </p>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>
                    User Shipping Mark :{" "}
                  </span>
                  <span>{record?.getUserShippingMark()}</span>
                </p>
              </Space>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Actions",
      key: "id",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      align: "center" as const,
      render: (_key: string, record) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_APPROVE,
            icon: <EditOutlined />,
            key: "approve",
            label: (
              <span onClick={() => showModal("approve", record)}>Approve</span>
            ),
          },
          {
            permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_REJECT,
            icon: <DeleteOutlined />,
            key: "reject",
            label: (
              <span onClick={() => showModal("reject", record)}>Reject</span>
            ),
          },
        ];


        return (
          <Dropdown menu={{
            items: items.filter((x) =>
              checkActionPermission(x.permission, x, null),
            ),
          }}>
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="RFQ Manage"
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <div style={{ marginTop: 20 }}>
              <Table
                style={{ overflow: "auto" }}
                columns={columns}
                dataSource={requestsList}
                rowSelection={{
                  type: "checkbox",
                  onSelect: function (
                    record,
                    selected,
          
                  ) {
                    if (selected) {
                      setSelectedItem(() => record);
                    }
                  },
                }}
                
              pagination={paginationConfig}
                

                bordered
                loading={isLoadingRequests || isLoadingRequests}
                rowKey="id"
                scroll={{ x: 1237 }}
              />
            </div>

            <Modal
              title={`RFQ ${actionType?.toUpperCase()}`}
              open={visible}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="Save"
              confirmLoading={false}
            >
              <Form form={form} layout="vertical">
                {actionType === "approve" && (
                  <>
                    <Form.Item
                      label="Source Agent Company"
                      name="source_agent_company_ids"
                      style={{ color: "red" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select al least one option",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        options={agentCompanies}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Shipping Agent Company"
                      name="shipping_agent_company_ids"
                      style={{ color: "red" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select al least one option",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        options={agentCompanies}
                      />
                    </Form.Item>
                  </>
                )}

                {actionType === "reject" && (
                  <>
                    <Form.Item
                      label="Reject Reason"
                      name="reject_reason"
                      style={{ color: "red" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select al least one option",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select reason"
                        options={rejectReasonOptions}
                      />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Modal>
            <ImagesModal
              open={isOpenImageModal}
              cancelHandler={onClose}
              rfq_images={selectedProduct}
            />
            {contextHolder}
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default RfqManage;
