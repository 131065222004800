import {
  IShipmentProductMoveOnAssociation,
  IShipmentProductMoveOnAssociationCommissionRate,
} from "types/shipmentProductMoveOnAssociation";

export class ShipmentProductMoveOnAssociationCommissionRateModel {
  object: string;
  id: number;
  shipping_type: string;
  shipping_mode: string;
  unit_type: string;
  rate: number;
  rate_type: string;
  max_cap: number | null;

  constructor(data: IShipmentProductMoveOnAssociationCommissionRate) {
    this.object = data.object;
    this.id = data.id;
    this.shipping_type = data.shipping_type;
    this.shipping_mode = data.shipping_mode;
    this.unit_type = data.unit_type;
    this.rate = data.rate;
    this.rate_type = data.rate_type;
    this.max_cap = data.max_cap;
  }

  getId = () => this.id;
  getShippingType = () => this.shipping_type;
  getShippingMode = () => this.shipping_mode;
  getUnitType = () => this.unit_type;
  getRate = () => this.rate;
  getRateType = () => this.rate_type;
  getMaxCap = () => this.max_cap;
}

export class ShipmentProductMoveOnAssociationModel {
  object: string;
  id: number;
  rate: number;
  rate_type: string;
  max_cap: number | null;
  commission_rate: ShipmentProductMoveOnAssociationCommissionRateModel | null;

  constructor(data: IShipmentProductMoveOnAssociation) {
    this.object = data.object;
    this.id = data.id;
    this.rate = data.rate;
    this.rate_type = data.rate_type;
    this.max_cap = data.max_cap;
    this.commission_rate =
      data.commission_rate &&
      new ShipmentProductMoveOnAssociationCommissionRateModel(
        data.commission_rate,
      );
  }

  getId = () => this.id;
  getRate = () => this.rate;
  getRateType = () => this.rate_type;
  getMaxCap = () => this.max_cap;
  getCommissionRate = () => this.commission_rate;
}
