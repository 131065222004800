import { Card, Typography } from "antd";
import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

export const ShipmentInstruction = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {ShipmentProductData?.getShipmentInstruction() ? (
        <Typography.Text>
          {ShipmentProductData.getShipmentInstruction()}
        </Typography.Text>
      ) : (
        <Typography.Text>No instruction from customer.</Typography.Text>
      )}
    </Card>
  );
};
