import { Form, Button, message, Alert } from "antd";
import { useDeletePrice } from "lib/core-react/hooks/private/useAddonService";
import { AddonServicePricesModel } from "models/addonServiceCollectionModel";
import { useState, useEffect } from "react";

interface IProps {
  setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  isShowDeleteId: AddonServicePricesModel;
}

const DeletePrice = ({ setIsShowDeleteModal, isShowDeleteId }: IProps) => {
  const { deletePrice, isLoading, isError } = useDeletePrice();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onConfirmDelete = async () => {
    try {
      await deletePrice(
        isShowDeleteId.getAddonServiceId(),
        isShowDeleteId.getId(),
      );
      setIsShowDeleteModal(false);
      setIsErrorVisible(false);
      message.success({
        content: "Price deleted successfully.",
        key: "deletePrice",
        duration: 2,
      });
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <h3>Are you sure you want to delete this Price?</h3>
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={isLoading} onClick={onConfirmDelete}>
            Yes
          </Button>{" "}
          <Button
            disabled={isLoading}
            onClick={() => setIsShowDeleteModal(false)}
          >
            No
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default DeletePrice;
