import { Divider, Space, Typography } from "antd";
import { DiscountModel } from "models/discountCollectionModel";
import { formatDateTime, isValidISODate } from "utils/helpers";

interface IProps {
  selectedDiscount: DiscountModel;
}
const DiscountRules = ({ selectedDiscount }: IProps) => {
  const { Text } = Typography;
  return (
    <Space direction="vertical" wrap>
      {selectedDiscount
        .getDiscountRules()
        .getData()
        .map((item) => (
          <Space
            direction="vertical"
            style={{
              textTransform: "capitalize",
            }}
            key={item.getRuleIdentifier()}
          >
            <Text underline>
              {item.getRuleIdentifier().replaceAll("-", " ")}
            </Text>
            <Space direction="vertical">
              {Object.entries(item.getMetaData()).map((item) => (
                <Space key={item[0]}>
                  <Text>{item[0].replaceAll("_", " ")}:</Text>
                  <Text>
                    {isValidISODate(item[1])
                      ? formatDateTime(item[1])
                      : item[1]}
                  </Text>
                </Space>
              ))}
            </Space>
            <Divider />
          </Space>
        ))}
    </Space>
  );
};
export default DiscountRules;
