import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { DeleteTwoTone, PlusSquareTwoTone } from "@ant-design/icons";

import { transformEnumToLabeledValue } from "utils/helpers";
import {
  ContainsEnum,
  DimensionUnitEnum,
  WeightUnitEnum,
} from "enums/shipForMeContextEnums";
import { useGetCurrency } from "lib/core-react/hooks/private";
import { regionCurrency } from "lib/core-react/store/store";
import { CurrencyCollectionModel } from "models/currencyCollectionModel";

interface IProps {
  form: FormInstance<any>;
  onOk: () => void;
}

export const AddOwnSourceCartonModal = ({ form, onOk }: IProps) => {
  const { getCurrency } = useGetCurrency();
  const [{ data: currencyDataAtom, isLoading: isLoadingCurrency }] =
    useAtom(regionCurrency);
  const currencyCollectionData =
    currencyDataAtom && new CurrencyCollectionModel(currencyDataAtom);

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    getCurrency("per_page=200");
  }, []);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const variationsData = Form.useWatch("variations", form);

  useEffect(() => {
    const calculateTotalQuantity = () => {
      if (Array.isArray(variationsData)) {
        const totalQuantity = variationsData.reduce(
          (acc, cur) => acc + cur.quantity,
          0,
        );
        form.setFieldsValue({ quantity: totalQuantity });
      }
    };

    calculateTotalQuantity();
  }, [variationsData]);

  return (
    <Card style={{ background: "#F7F8FA" }}>
      <Form
        form={form}
        name="form"
        onFinish={onOk}
        initialValues={{
          weight: {
            unit: WeightUnitEnum.KG,
          },
          length: {
            unit: DimensionUnitEnum.CM,
          },
          width: {
            unit: DimensionUnitEnum.CM,
          },
          height: {
            unit: DimensionUnitEnum.CM,
          },
          price: {
            currency: currencyCollectionData?.getCurrencyForBDT()?.getCode(),
          },
          variations: [
            {
              quantity: "",
              properties: [
                { property_name: "Color", property_value_name: "" },
                { property_name: "Size", property_value_name: "" },
              ],
            },
          ],
        }}
      >
        <Form.List name="variations">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{
                    display: "flex",
                    margin: "0 10px",
                  }}
                >
                  <Row
                    justify="space-between"
                    style={{
                      padding: 5,
                      borderLeft: "2px solid hotpink",
                    }}
                  >
                    <Typography.Text mark>
                      Variation #{index + 1}
                    </Typography.Text>

                    {index > 0 && (
                      <DeleteTwoTone
                        twoToneColor="hotpink"
                        onClick={() => remove(name)}
                      />
                    )}
                  </Row>

                  <Form.Item
                    {...restField}
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required",
                      },
                    ]}
                    required
                    name={[name, "quantity"]}
                    label="Quantity"
                  >
                    <InputNumber placeholder="0" min={1} />
                  </Form.Item>
                  <Form.List key={key} name={[name, "properties"]}>
                    {(PropertiesFields) => (
                      <>
                        {PropertiesFields.map(
                          (
                            {
                              key: PropertiesKey,
                              name: PropertiesName,
                              ...PropertiesRestField
                            },
                            PropertiesIndex,
                          ) => (
                            <Space
                              key={PropertiesKey}
                              direction="vertical"
                              size="middle"
                              style={{
                                display: "flex",
                                margin: "0 10px",
                              }}
                            >
                              <Form.Item
                                {...PropertiesRestField}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      PropertiesIndex === 1
                                        ? "Color is required"
                                        : "Size is required",
                                  },
                                ]}
                                required
                                name={[PropertiesName, "property_value_name"]}
                                label={PropertiesIndex === 1 ? "Color" : "Size"}
                              >
                                <Input
                                  placeholder={
                                    PropertiesIndex === 1
                                      ? "Type color"
                                      : "Type size"
                                  }
                                />
                              </Form.Item>
                            </Space>
                          ),
                        )}
                      </>
                    )}
                  </Form.List>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    const lastVariationIndex = fields.length - 1;
                    form
                      .validateFields([
                        ["variations", lastVariationIndex, "quantity"],
                        [
                          "variations",
                          lastVariationIndex,
                          "properties",
                          0,
                          "property_value_name",
                        ],
                        [
                          "variations",
                          lastVariationIndex,
                          "properties",
                          1,
                          "property_value_name",
                        ],
                      ])
                      .then(() =>
                        add({
                          quantity: "",
                          properties: [
                            { property_name: "Color" },
                            { property_name: "Size" },
                          ],
                        }),
                      )
                      .catch((error) => {
                        console.error("Validation Error:", error);
                      });
                  }}
                  block
                  icon={<PlusSquareTwoTone />}
                >
                  Add more variation
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true, message: "Quantity is required" }]}
            >
              <InputNumber placeholder="0" min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contains"
              label="Contains"
              rules={[{ required: true, message: "Contains is required" }]}
            >
              <Select
                placeholder="Select"
                options={transformEnumToLabeledValue(ContainsEnum)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["weight", "amount"]}
              label="Weight"
              rules={[{ required: true, message: "Weight is required" }]}
            >
              <InputNumber
                min={0}
                placeholder="0"
                addonAfter={
                  <Form.Item name={["weight", "unit"]} noStyle>
                    <Select
                      style={{ width: 70 }}
                      options={transformEnumToLabeledValue(WeightUnitEnum)}
                    />
                  </Form.Item>
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["length", "amount"]}
              label="Length"
              rules={[{ required: true, message: "Length is required" }]}
            >
              <InputNumber
                min={0}
                placeholder="0"
                addonAfter={
                  <Form.Item name={["length", "unit"]} noStyle>
                    <Select
                      style={{ width: 70 }}
                      options={transformEnumToLabeledValue(DimensionUnitEnum)}
                    />
                  </Form.Item>
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["width", "amount"]}
              label="Width"
              rules={[{ required: true, message: "Width is required" }]}
            >
              <InputNumber
                min={0}
                placeholder="0"
                addonAfter={
                  <Form.Item name={["width", "unit"]} noStyle>
                    <Select
                      style={{ width: 70 }}
                      options={transformEnumToLabeledValue(DimensionUnitEnum)}
                    />
                  </Form.Item>
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["height", "amount"]}
              label="Height"
              rules={[{ required: true, message: "Height is required" }]}
            >
              <InputNumber
                min={0}
                placeholder="0"
                addonAfter={
                  <Form.Item name={["height", "unit"]} noStyle>
                    <Select
                      style={{ width: 70 }}
                      options={transformEnumToLabeledValue(DimensionUnitEnum)}
                    />
                  </Form.Item>
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["price", "amount"]}
              label="Price"
              rules={[{ required: true, message: "Price is required" }]}
            >
              <InputNumber
                min={0}
                placeholder="0"
                addonAfter={
                  <Form.Item name={["price", "currency"]} noStyle>
                    <Select
                      loading={isLoadingCurrency}
                      style={{ width: 70 }}
                      options={currencyCollectionData?.getCurrencyLabeledValue()}
                    />
                  </Form.Item>
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
