import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IDisputeApprovedFormData } from "types/disputeDetailsCollection";

class DisputeResource extends BaseResource {
  discountsPath = `/api/discount/admin/discount/v1/discounts`;
  discountGroupPath = `/api/discount/admin/discount-group/v1/discount-groups`;
  discountRuleSchemaPath = `/api/discount/admin/discount-rule/v1/discounts-rule-schema`;
  discountUsageHistoryPath = `/api/discount/admin/discount-usage-history/v1/discount-usage-histories`;
  disputesPath = `/api/refund/admin/dispute/v1/disputes`;

  // Refund
  getDisputes(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.disputesPath}`;
    if (params) {
      path = `${path}?${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getDispute(
    id: number | string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.disputesPath}/${id}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  updateDispute(
    id: number,
    payload: IDisputeApprovedFormData,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.disputesPath}/${id}/approve`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
}

export default DisputeResource;
