import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  notification,
} from "antd";

import {
  useGetCountry,
  useUpdateAgentWarehouse,
} from "lib/core-react/hooks/private";
import { regionCountry } from "lib/core-react/store/store";
import {
  IAgentWarehouseFormData,
} from "types/agentWarehouseCollection";
import {
  AgentWarehouseOperationStatusEnums,
  AgentWarehouseVerificationStatusEnums,
} from "enums/shared";
import { CountryCollectionModel } from "models/countryCollectionModel";
import { AgentWarehouseModel } from "models/agentWarehouseCollectionModel";

import {
  renderOptionsFromEnumReversed,
} from "components/Form/forms";

interface IProps {
  selectedAgentWarehouse: AgentWarehouseModel;
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateAgentWarehouse = ({
  selectedAgentWarehouse,
  setIsShowUpdateModal,
}: IProps) => {
  const { getCountry } = useGetCountry();
  const [{ data: countryCollectionAtom, isLoading: countryLoading }] =
    useAtom(regionCountry);
  const { updateAgentWarehouse, isLoading, isError } =
    useUpdateAgentWarehouse();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = React.createRef();

  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [selectedAgentWarehouse, form]);

  useEffect(() => {
    getCountry("per_page=200");
    
  }, []);

  const CountryData =
    countryCollectionAtom && new CountryCollectionModel(countryCollectionAtom);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: IAgentWarehouseFormData) => {
    const payload = {
      country_id: value.country_id,
      name: value.name,
      shipping_mark: value.shipping_mark,
      operation_status: value.operation_status,
      verification_status: value.verification_status,
    };

    try {
      await updateAgentWarehouse(
        selectedAgentWarehouse.getAgentCompany().getId(),
        selectedAgentWarehouse.getId(),
        payload as IAgentWarehouseFormData,
      );
      notification["success"]({
        message: "Update Agent warehouse successfully",
      });
      setIsShowUpdateModal(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Edit_Agent_Warehouse"
          ref={formRef}
          initialValues={{
            name: selectedAgentWarehouse.getName(),
            shipping_mark: selectedAgentWarehouse.getShippingMark(),
            operation_status: selectedAgentWarehouse.getOperationStatus(),
            verification_status: selectedAgentWarehouse.getVerificationStatus(),
            country_id: selectedAgentWarehouse.getCountry().getId(),
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Agent warehouse name is required",
              },
            ]}
            label="Name"
            name="name"
          >
            <Input placeholder="Agent warehouse name" />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Shipping mark is required",
              },
            ]}
            label="Shipping Mark"
            name="shipping_mark"
          >
            <Input placeholder="Shipping mark" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Country is required" }]}
            name="country_id"
            label="Country"
          >
            <Select
              loading={countryLoading}
              placeholder="Please select Country"
              options={CountryData?.getCountryLabeledValue()}
            />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: "Operation status is required" },
            ]}
            name="operation_status"
            label="Operation Status"
          >
            <Select
              value={selectedAgentWarehouse.getOperationStatus()}
              loading={countryLoading}
              placeholder="Please select a operation status"
            >
              {renderOptionsFromEnumReversed(
                AgentWarehouseOperationStatusEnums,
              )}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: "Verification Status is required" },
            ]}
            name="verification_status"
            label="Verification Status"
          >
            <Select placeholder="Please select a Verification status">
              {renderOptionsFromEnumReversed(
                AgentWarehouseVerificationStatusEnums,
              )}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default UpdateAgentWarehouse;
