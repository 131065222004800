import {
  IShipmentProductPackage,
  IShipmentProductPackageCollection,
  IWarehouseShipment,
} from "types/shipmentProductPackageCollection";
import { ShipmentProductPackageTrackingCollectionModel } from "./shipmentProductPackageTrackingCollectionModel";
import { ShipmentProductPackageVariationCollectionModel } from "./shipmentProductPackageVariationCollectionModel";
import { IPagination } from "types/pagination";
import { CommonProductImageCollectionModel } from "./shared";

export class WarehouseShipmentModel {
  created_at: string;
  estimated_fly_time: string;
  id: string;
  object: string;
  shipment_number: string;
  waybill_number: string;

  constructor(data: IWarehouseShipment) {
    this.created_at = data.created_at;
    this.estimated_fly_time = data.estimated_fly_time;
    this.id = data.id;
    this.object = data.object;
    this.shipment_number = data.shipment_number;
    this.waybill_number = data.waybill_number;
  }

  getEstimatedFlyTime = () => this.estimated_fly_time;
  getId = () => this.id;
  getShipmentNumber = () => this.shipment_number;
  getWaybillNumber = () => this.waybill_number;
}

export class ShipmentProductPackageModel {
  object: string;
  id: number;
  package_number: string;
  carton_number: string | null;
  product_price: number;
  quantity: number;
  weight: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  cbm: number | null;
  contains: string;
  contains_2: null | string;
  hs_code: null | string;
  fulfillment_status: string;
  images: CommonProductImageCollectionModel;
  trackings: ShipmentProductPackageTrackingCollectionModel;
  variations: ShipmentProductPackageVariationCollectionModel;
  warehouse_shipment: WarehouseShipmentModel | null;

  constructor(dataItem: IShipmentProductPackage) {
    this.object = dataItem.object;
    this.package_number = dataItem.package_number;
    this.carton_number = dataItem.carton_number;
    this.id = dataItem.id;
    this.product_price = dataItem.product_price;
    this.quantity = dataItem.quantity;
    this.weight = dataItem.weight;
    this.length = dataItem.length;
    this.width = dataItem.width;
    this.height = dataItem.height;
    this.cbm = dataItem.cbm;
    this.contains = dataItem.contains;
    this.contains_2 = dataItem.contains_2;
    this.hs_code = dataItem.hs_code;
    this.fulfillment_status = dataItem.fulfillment_status;
    this.images = new CommonProductImageCollectionModel(dataItem.images);
    this.trackings = new ShipmentProductPackageTrackingCollectionModel(
      dataItem.trackings,
    );
    this.variations = new ShipmentProductPackageVariationCollectionModel(
      dataItem.variations,
    );
    this.warehouse_shipment =
      dataItem.warehouse_shipment &&
      new WarehouseShipmentModel(dataItem.warehouse_shipment);
  }

  getPackageNumber = () => this.package_number;
  getCartonNumber = () => this.carton_number;
  getWeight = () => this.weight;
  getQuantity = () => this.quantity;
  getLength = () => this.length;
  getWidth = () => this.width;
  getHeight = () => this.height;
  getCbm = () => this.cbm;
  getContains = () => this.contains;
  getTrackings = () => this.trackings;
  getAbroadTracking = ()=>{
   return  this.getTrackings().getData().find(x=>x.getType() === "abroad-courier-tracking")
  }
  getVariations = () => this.variations;
  getProductPrice = () => this.product_price;
  getId = () => this.id;
  getHsCode = () => this.hs_code;
  getFulfillmentStatus = () => this.fulfillment_status;
  getImages = () => this.images;
  getWarehouseShipment = () => this.warehouse_shipment;
}
export class ShipmentProductPackageCollectionModel {
  object: string;
  data: ShipmentProductPackageModel[];
  pagination?: IPagination;

  constructor(dataItem: IShipmentProductPackageCollection) {
    this.object = dataItem.object;
    this.pagination = dataItem.pagination;
    this.data = dataItem.data.map(
      (item) => new ShipmentProductPackageModel(item),
    );
  }

  getData = () => this.data;

  getTotalProductPrice = () =>
    this.data.reduce((pre, curr) => pre + curr.getProductPrice(), 0);
  getTotalLength = () =>
    this.data.reduce((pre, curr) => pre + Number(curr.getLength()), 0);
  getTotalWidth = () =>
    this.data.reduce((pre, curr) => pre + Number(curr.getWidth()), 0);
  getTotalHeight = () =>
    this.data.reduce((pre, curr) => pre + Number(curr.getHeight()), 0);
  getTotalWeight = () =>
    this.data.reduce((pre, curr) => pre + Number(curr.getWeight()), 0);
  getTotalQuantity = () =>
    this.data.reduce((pre, curr) => pre + curr.getQuantity(), 0);
  getTotalCbm = () =>
    this.data.reduce((pre, curr) => pre + Number(curr.getCbm()), 0);
}
