// export class AgentWarehouseModel {
//   public readonly object: string;
//   public readonly name: string;
//   public readonly operation_status: string;
//   public readonly shipping_mark: string;
//   public readonly status: string;

import { IAgentWarehouseAddress } from "types/agentWarehouseAddress";

//   constructor(data: IAgentWarehouse) {
//     this.object = data.object;
//     this.name = data.name;
//     this.operation_status = data.operation_status;
//     this.shipping_mark = data.shipping_mark;
//     this.status = data.status;
//   }
// }

// export class AgentWarehouseAddressSchemaFieldsValueModel {
//   public readonly type: string;
//   public readonly label: string;
//   public readonly place_holder: string;
//   public readonly is_required: boolean;
//   public readonly data_source?: string;
//   public readonly resource?: string;
//   public readonly properties: {
//     is_editable: boolean;
//   };
//   public readonly errors?: {
//     on_empty: string;
//   };

//   constructor(data: IAgentWarehouseAddressSchemaFieldsValue) {
//     this.type = data.type;
//     this.label = data.label;
//     this.place_holder = data.place_holder;
//     this.is_required = data.is_required;
//     this.data_source = data?.data_source;
//     this.resource = data?.resource;
//     this.properties = data.properties;
//     this.errors = data?.errors;
//   }
// }

// export class AgentWarehouseAddressSchemaModel {
//   public readonly fields: {
//     [key: string]: AgentWarehouseAddressSchemaFieldsValueModel;
//   };

//   constructor(data: IAgentWarehouseAddressSchema) {
//     this.fields = {};

//     for (const key in data.fields) {
//       if (data.fields.hasOwnProperty(key)) {
//         this.fields[key] = new AgentWarehouseAddressSchemaFieldsValueModel(
//           data.fields[key],
//         );
//       }
//     }
//   }
// }

export class AgentWarehouseAddressModel {
  public readonly object: string;
  public readonly id: number;
  public readonly address: { [key: string]: string };

  constructor(data: IAgentWarehouseAddress) {
    this.object = data.object;
    this.id = data.id;
    this.address = data.address;
  }
}
