import React, { useState } from "react";
import { InputNumber, Row, Col, Typography } from "antd";

const { Text } = Typography;

// NumberRangeInput component
const NumberRangeInput = ({ name, handleChange }) => {
    // State to hold the current range values
    const [range, setRange] = useState<number[]>([5, 10000]);

    // Fixed min and max values for the input fields
    const min = 0;
    const max = 1000000;

    // Handler for min change, ensuring non-null value
    const onMinChange = (value: number | null) => {
        if (value !== null) {
            const newRange = [value, range[1]];
            setRange(newRange);
            handleChange({ [name]: newRange });
        }
    };

    // Handler for max change, ensuring non-null value
    const onMaxChange = (value: number | null) => {
        if (value !== null) {
            const newRange = [range[0], value];
            setRange(newRange);
            handleChange({ [name]: newRange });
        }
    };

    return (
        <div>
            <Row gutter={16} align="middle" justify="center">
                <Col span={12}>
                    <Text strong>Min:</Text>
                    <InputNumber
                        min={min}
                        max={max}
                        value={range[0]}
                        onChange={onMinChange}
                        placeholder="Min"
                        style={{ marginLeft: 8 }}
                    />
                </Col>
                <Col span={12}>
                    <Text strong>Max:</Text>
                    <InputNumber
                        min={min}
                        max={max}
                        value={range[1]}
                        onChange={onMaxChange}
                        placeholder="Max"
                        style={{ marginLeft: 8 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default NumberRangeInput;
