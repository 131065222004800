import { useState } from "react";
import { atom, useAtom, useSetAtom } from "jotai";

import { getError } from "../utils/errors";
import { useService } from "lib/core-react/contexts";
import {
  campaignCollectionAtom,
  campaignProductCollectionAtom,
} from "lib/core-react/store/store";
import {
  CreateCampaignPostRequestModel,
  UpdateCampaignPostRequestModel,
} from "models/campaignCollectionModel";
import {
  IAddCampaignProductsPostRequest,
  IDeleteCampaignProducts,
} from "types/campaignCollection";
import { IAddCampaignProductRawPayload } from "types/campaignPayload";


export const isLoadingCampaignAtom = atom<boolean>(false)

export const useCampaignList = () => {
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const { campaignService } = useService();

  const getCampaign = async (params:string): Promise<void> => {
    setCampaign({ ...campaign, isLoading: true, error: null });
    try {
      const response = await campaignService.campianResource.getCampaign(params);
      setCampaign({
        ...campaign,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setCampaign({
        ...campaign,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getCampaign } as const;
};

export const useCreateCampaign = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const createCampaign = async (payload: CreateCampaignPostRequestModel) => {
    setIsLoading(true);
    try {
      const response =
        await campaignService.campianResource.createCampaign(payload);
      setIsLoading(false);
      setCampaign({ ...campaign, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createCampaign, isLoading, isError };
};

export const useUpdateCampaign = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const updateCampaign = async (
    id: string,
    payload: UpdateCampaignPostRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response = await campaignService.campianResource.updateCampaign(
        id,
        payload,
      );
      setIsLoading(false);
      setCampaign({ ...campaign, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateCampaign, isLoading, isError };
};

export const useDeleteCampaign = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const deleteCampaign = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await campaignService.campianResource.delete(id);
      setIsLoading(false);
      setCampaign({ ...campaign, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { deleteCampaign, isLoading, isError };
};

export const usePauseCampaign = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const getPauseCampaign = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await campaignService.campianResource.updatePause(id);
      setIsLoading(false);
      setCampaign({ ...campaign, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { getPauseCampaign, isLoading, isError };
};

export const useResumeCampaign = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

  const getResumeCampaign = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await campaignService.campianResource.updateResume(id);
      setIsLoading(false);
      setCampaign({ ...campaign, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { getResumeCampaign, isLoading, isError };
};

export const useGetCampaignProductList = () => {
  const [campaignProducts, setCampaignProducts] = useAtom(
    campaignProductCollectionAtom,
  );
  const setIsLoadingCampaign = useSetAtom(isLoadingCampaignAtom)

  const { campaignService } = useService();

  const getCampaignProducts = async (
    id: string,
    params?: Record<string, any>,
    action ?: "paginate",
  ): Promise<void> => {
   if (action === "paginate") {
     setIsLoadingCampaign(true)
    }else{
      
      setIsLoadingCampaign(campaignProducts.data ? false : true)

   }
   
    setCampaignProducts({ ...campaignProducts, isLoading: true, error: null });
    try {
      const response =
        await campaignService.campianResource.getCampaignProducts(id, params);
      setCampaignProducts({
        ...campaignProducts,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
      setIsLoadingCampaign(false)
    } catch (error: any) {
      setIsLoadingCampaign(false)
      setCampaignProducts({
        ...campaignProducts,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getCampaignProducts } as const;
};

export const useAddCampaignProducts = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const addCampaignProducts = async (
    id: string,
    locale: string,
    region: string,
    payload: IAddCampaignProductsPostRequest,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await campaignService.campianResource.addCampaignProducts(
          id,
          locale,
          region,
          payload,
        );
      setIsLoading(false);
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { addCampaignProducts, isLoading, isError };
};

export const useAddRawCampaignProduct = () => {
  const { campaignService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const addRawCampaignProduct = async (
    campaignId: string,
    locale: string,
    region: string,
    payload: IAddCampaignProductRawPayload,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await campaignService.campianResource.postAddCampaignProductRaw(
          campaignId,
          locale,
          region,
          payload,
        );
      setIsLoading(false);
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { addRawCampaignProduct, isLoading, isError };
};

export const useDeleteCampaignProducts = () => {
  const [campaignProducts, setCampaignProducts] = useAtom(
    campaignProductCollectionAtom,
  );
  const { campaignService } = useService();
  const [isError, setError] = useState<string>("");
  const setIsLoadingCampaign = useSetAtom(isLoadingCampaignAtom)

  const deleteCampaignProducts = async (
    id: string,
    payload: IDeleteCampaignProducts,
  ) => {
    setIsLoadingCampaign(true);
    try {
      const response =
        await campaignService.campianResource.deleteCampaignProducts(
          id,
          payload,
        );
      setIsLoadingCampaign(false);
      setCampaignProducts({ ...campaignProducts,
        data : response.data,refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoadingCampaign(false);
      throw error;
    }
  };
  return { deleteCampaignProducts,isError };
};
