// Enum for admin permissions in the Shipping-Agent module
export enum ADMIN_SHIPPING_AGENT_PERMISSION_ENUM {
    // Agent Company permissions
    AGENT_COMPANY_ADMIN_VIEW = "agent-company-admin-view",
    AGENT_COMPANY_SHIPPING_POINT_ADMIN_VIEW = "agent-company-shipping-point-admin-view",
    AGENT_MEMBER_ADMIN_VIEW = "agent-member-admin-view",
    ADMIN_CHANGE_AGENT_VERIFICATION_STATUS = "admin-change-agent-verification-status",
    AGENT_COMPANY_ADMIN_SYNC = "agent-company-admin-sync",
    AGENT_WAREHOUSE_ADMIN_VIEW = "agent-warehouse-admin-view",
    AGENT_WAREHOUSE_ADMIN_UPDATE = "agent-warehouse-admin-update",
    AGENT_WAREHOUSE_ADMIN_SYNC = "agent-warehouse-admin-sync",
    
    // Agent Category Read Price permissions
    AGENT_CATEGORY_PRICE_READ_ADMIN_VIEW = "agent-category-price-read-admin-view",
    
    // Agent Verification permissions
    AGENT_VERIFICATION_ADMIN_VIEW = "agent-verification-admin-view",
    AGENT_VERIFICATION_ADMIN_UPDATE = "agent-verification-admin-update",
    
    // Pricing Template permissions
    PRICING_TEMPLATE_ADMIN_VIEW = "pricing-template-admin-view",
    PRICING_TEMPLATE_ADMIN_ADD = "pricing-template-admin-add",
    PRICING_TEMPLATE_ADMIN_UPDATE = "pricing-template-admin-update",
    PRICING_TEMPLATE_ADMIN_DELETE = "pricing-template-admin-delete",
    
    // Commission permissions
    ADMIN_COMMISSION_VIEW = "admin-commission-view",
    ADMIN_COMMISSION_ADD = "admin-commission-add",
    ADMIN_COMMISSION_UPDATE = "admin-commission-update",
    ADMIN_COMMISSION_COPY = "admin-commission-copy",

    ADMIN_COMMISSION_ASSOCIATION_COPY = "admin-commission-association-copy",
    ADMIN_CATEGORY_COMMISSION_COPY = "admin-category-commission-copy",
    ADMIN_CATEGORY_COMMISSION_UPDATE = "admin-category-commission-update",
    ADMIN_CATEGORY_COMMISSION_DELETE = "admin-category-commission-delete"
}




// Enum for admin permissions in the Shipping module
export enum ADMIN_SHIPPING_PERMISSION_ENUM {
    // Shipment Product permissions
    ADMIN_CHANGE_SHIPPING_COUNTRY = "admin-change-shipping-country",
    ADMIN_CHANGE_DESTINATION_WAREHOUSE = "admin-change-destination-warehouse",
    ADMIN_CHANGE_SHIPPING_CATEGORY = "admin-change-shipping-category",
    ADMIN_CHANGE_SHIPPING_MODE = "admin-change-shipping-mode",
    ADMIN_CHANGE_SHIPPING_TYPE = "admin-change-shipping-type",


    ADMIN_ASSIGN_DEFAULT = "admin-assign-default",
    ADMIN_ASSIGN_WITH_PRICE = "admin-assign-with-price",


    ADMIN_APPROVE_SHIPMENT_PRODUCT = "admin-approve-shipment-product",
    ADMIN_REJECT_SHIPMENT_PRODUCT = "admin-reject-shipment-product",

    ADMIN_VIEW_SHIPMENT_PRODUCT = "admin-view-shipment-product",
    ADMIN_PLACE_ORDER = "admin-place-order",
    ADMIN_ATTACH_IMAGES = "admin-attach-images",
    
    // Shipment Product Tracking permissions
    ADMIN_ATTACH_TRACKING = "admin-attach-tracking",
    ADMIN_UPDATE_TRACKING = "admin-update-tracking",

    // Shipment Product Package permissions
    ADMIN_PACKAGE_VIEW = "admin-package-view",
    ADMIN_PACKAGE_ADD = "admin-package-add",
    ADMIN_PACKAGE_UPDATE = "admin-package-update",
    ADMIN_PACKAGE_DELETE = "admin-package-delete",

    // Shipment Product Unlisted Package permissions
    ADMIN_UNLISTED_PACKAGE_VIEW = "admin-unlisted-package-view"
}


// Enum for admin permissions in the Shipping-Core module
export enum ADMIN_SHIPPING_CORE_PERMISSION_ENUM {
    // Base-shipping-category permissions
    ADMIN_BASE_SHIPPING_CATEGORY_VIEW = "admin-base-shipping-category-view",
    ADMIN_BASE_SHIPPING_CATEGORY_CREATE = "admin-base-shipping-category-create",
    ADMIN_BASE_SHIPPING_CATEGORY_UPDATE = "admin-base-shipping-category-update",
    ADMIN_BASE_SHIPPING_CATEGORY_SYNC = "admin-base-shipping-category-sync",

    // Shipping-category permissions
    ADMIN_SHIPPING_CATEGORY_VIEW = "admin-shipping-category-view",
    ADMIN_SHIPPING_CATEGORY_CREATE = "admin-shipping-category-create",
    ADMIN_SHIPPING_CATEGORY_UPDATE = "admin-shipping-category-update",
    ADMIN_SHIPPING_CATEGORY_DELETE = "admin-shipping-category-delete",
    ADMIN_SHIPPING_CATEGORY_SYNC = "admin-shipping-category-sync",

    // Shipping-country permissions
    ADMIN_SHIPPING_COUNTRY_VIEW = "admin-shipping-country-view",
    ADMIN_SHIPPING_COUNTRY_CREATE = "admin-shipping-country-create",
    ADMIN_SHIPPING_COUNTRY_UPDATE = "admin-shipping-country-update",
    ADMIN_SHIPPING_COUNTRY_SYNC = "admin-shipping-country-sync",

    // Auction rule permissions
    ADMIN_AUCTION_RULE_VIEW = "admin-auction-rule-view",
    ADMIN_AUCTION_RULE_CREATE = "admin-auction-rule-create",
    ADMIN_AUCTION_RULE_UPDATE = "admin-auction-rule-update",
    ADMIN_AUCTION_RULE_DELETE = "admin-auction-rule-delete",
    ADMIN_AUCTION_RULE_SYNC = "admin-auction-rule-sync"
}



