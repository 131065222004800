import React, { Dispatch, SetStateAction } from "react";
import { Form, Input, Modal } from "antd";

interface IProps {
  url: string;
  open: boolean;
  onCancel: () => void;
  onChangeUrl: Dispatch<SetStateAction<string>>;
  onSaveLink: () => void;
  onRemoveLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function LinkModal(props: IProps) {
  const {
    url,
    open,
    onCancel,
    onChangeUrl,
    onSaveLink,
    onRemoveLink,
    ...rest
  } = props;
  return (
    <Modal
      onOk={onSaveLink}
      title="Edit Link"
      open={open}
      okText="Save"
      onCancel={onCancel}
      {...rest}
    >
      <Form.Item>
        <Input
          value={url}
          onChange={(e) => onChangeUrl(e.target.value)}
          placeholder="Enter link"
        />
      </Form.Item>
    </Modal>
  );
}
