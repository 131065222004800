import React from "react";
import { Form, Button, message } from "antd";
import { showError } from "helpers/showError";
import {
    useDeleteExchangeCustomer,
    useGetExchangeGroupCustomers,
} from "lib/core-react/hooks/private/useExchangeGroup";
import { CustomerUserModel } from "models/customerUserCollectionModel";

interface IProps {
    setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
    filterData: Record<string, any>;
}

const DeleteExchangeGroupCustomer: React.FC<IProps> = ({
    setIsShowDeleteModal,
    selectedCustomerUser,
    filterData,
}) => {
    const { getExchangeGroupCustomers } = useGetExchangeGroupCustomers();
    const { deleteExchangeGroupCustomer, isLoading } =
        useDeleteExchangeCustomer();

    const onConfirmDelete = async () => {
        try {
            const res = await deleteExchangeGroupCustomer(
                selectedCustomerUser.getId(),
            );
            if (res) {
                setIsShowDeleteModal(false);
                message.success(res.message);
                await getExchangeGroupCustomers(filterData);
            }
        } catch (error) {
            showError(error);
        }
    };

    return (
        <Form layout="vertical">
            <Form.Item>
                <h5>
                    Are you sure you want to delete this customer from this
                    exchange group?
                </h5>
            </Form.Item>
            <Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{ minWidth: "100px" }}
                        disabled={isLoading}
                        onClick={() => setIsShowDeleteModal(false)}
                    >
                        No
                    </Button>
                    <Button
                        style={{ marginLeft: "10px", minWidth: "100px" }}
                        type="primary"
                        loading={isLoading}
                        onClick={onConfirmDelete}
                    >
                        Yes
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default DeleteExchangeGroupCustomer;
