import { Col, Row, Skeleton, Space, Tag } from "antd";
import { useAtom } from "jotai/index";
import { useGetBuyProduct } from "lib/core-react/hooks/private/usePurchase";
import { buyProductDetail } from "lib/core-react/store/store";
import { BuyProductView } from "models/buyProductDetailModel";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductDetail from "./viewDetails/ProductDetails";
import { getError } from "lib/core-react/hooks/utils/errors";
import { PageHeader } from "@ant-design/pro-layout";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { formatDateTime } from "utils/helpers";
import { OrderDetail } from "./viewDetails/Order";
import { TimelineTracking } from "./viewDetails/TimelineTracking";
import { CustomerAssociation } from "./viewDetails/CustomerAssociation";
import { AgentAssociation } from "./viewDetails/AgentAssociation";
import { Shipping } from "./viewDetails/Shipping";
import { Invoice } from "./viewDetails/Invoice";
import { Delivery } from "./viewDetails/Delivery";
import { ProductPackage } from "./viewDetails/ProductPackage";
import { SourceDestination } from "./viewDetails/SourceDestination";

const BuyProductViewDetail = () => {
  const [
    {
      data: buyProductDetailData,
      error: isErrosBuyProduct,
      isLoading: isBuyProductLoding,
    },
  ] = useAtom(buyProductDetail);
  const { id } = useParams();

  const { getBuyProduct } = useGetBuyProduct();

  useEffect(() => {
    if (id) {
      getBuyProduct(Number(id));
    }
  }, [id]);

  const data = buyProductDetailData && new BuyProductView(buyProductDetailData);

  return (
    <>
      <PageHeader
        style={{ marginTop: "10px" }}
        ghost={false}
        title={data ? `#${data.getData().getProductNumber()}` : ""}
        subTitle={
          data ? (
            <Space style={{ textTransform: "lowercase" }}>
              <Tag
                icon={
                  data.getData().getStatus() === "pending" ? (
                    <SyncOutlined spin />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                color={
                  data.getData().getStatus() === "pending"
                    ? "processing"
                    : data.getData().getStatus() === "buy-reject"
                      ? "red"
                      : "success"
                }
              >
                {data.getData().getStatus().replaceAll("-", " ")}
              </Tag>
              <Tag
                icon={
                  data.getData().getOrder()?.getPaymentStatus() ===
                    "invoice-unpaid" ||
                  data.getData().getOrder()?.getPaymentStatus() ===
                    "pending" ? (
                    <SyncOutlined spin />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                color={
                  data.getData().getOrder()?.getPaymentStatus() ===
                    "invoice-unpaid" ||
                  data.getData().getOrder()?.getPaymentStatus() === "pending"
                    ? "processing"
                    : "success"
                }
              >
                invoice{" "}
                {data
                  .getData()
                  .getOrder()
                  ?.getPaymentStatus()
                  .replaceAll("-", " ")}
              </Tag>
            </Space>
          ) : (
            ""
          )
        }
        onBack={() => window.history.back()}
        // extra={[
        //   <Dropdown  menu={menuProps}>
        //     <Button icon={<HddFilled />}>
        //       More actions <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
        {!id ? (
          <div>Product number missing!</div>
        ) : isErrosBuyProduct ? (
          <div>Something went wrong: {getError(isErrosBuyProduct)}</div>
        ) : data ? (
          <div>
            {data ? (
              <div >
                <ClockCircleOutlined /> {" "}
                {formatDateTime(data?.getData().getCreatedAt())}
              </div>
            ) : (
              <Skeleton paragraph={{ rows: 2 }} />
            )}
            <Row gutter={24} style={{ marginTop: "10px" }}>
              <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <ProductDetail isloading={isBuyProductLoding} productData={data?.getData()} />
                  </Col>
                  <Col xs={24}>
                    <SourceDestination
                      title="Source Destination"
                      isLoading={isBuyProductLoding}
                      productData={data?.getData()}
                    />
                  </Col>
                  <Col xs={24}>
                    <Shipping
                      title="Shipping"
                      isLoading={isBuyProductLoding}
                      productData={data?.getData()}
                    />
                  </Col>
                  <Col xs={24}>
                    <Invoice
                      title="Invoice"
                      isLoading={isBuyProductLoding}
                      productData={data?.getData()}
                    />
                  </Col>
                  <Col xs={24}>
                    <Delivery
                      title="Delivery"
                      isLoading={isBuyProductLoding}
                      productData={data?.getData()}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <OrderDetail isLoading={isBuyProductLoding} productData={data?.getData()} />
                  </Col>
                  <Col xs={24}>
                    <TimelineTracking
                      productData={{
                        id: data?.getData().getId(),
                        productNumber:data?.getData().getProductNumber()
                      }
                      }
                      isLoading={isBuyProductLoding}
                      title="Timeline"
                    />
                  </Col>
                  <Col xs={24}>
                    <CustomerAssociation
                      productData={data?.getData()}
                      isLoading={isBuyProductLoding}
                      title="Customer Association"
                    />
                  </Col>
                  <Col xs={24}>
                    <AgentAssociation
                      productData={data?.getData()}
                      isLoading={isBuyProductLoding}
                      title="Agent Association"
                    />
                  </Col>
                  <Col xs={24}>
                    <ProductPackage
                      productData={data?.getData()}
                      isLoading={isBuyProductLoding}
                      title="Product Package"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </PageHeader>
    </>
  );
};
export default BuyProductViewDetail;
