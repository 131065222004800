import { PurchaseCartResource } from "lib/core/resources/private";
import CartApiService from "lib/core/apiServices/cartApiService";

class PurchaseCartService extends CartApiService {
  public purchaseCartResource: PurchaseCartResource;

  constructor() {
    super();

    this.purchaseCartResource = new PurchaseCartResource(this.config);
  }
}

export default PurchaseCartService;
