import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";

import {
  RolePostRequestModel,
  RoleUpdateRequestModel,
} from "models/roleCollectionModel";
import { IDestinationWarehouseRoleForm } from "types/roleCollection";

class AclResource extends BaseResource {
  rolePath = `/api/acl/admin/role/v1/roles`;
  permissionPath = `/api/acl/admin/permission/v1/permissions`;

  //Roles
  getRoles(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.rolePath}/?expand=permissions&per_page=999`;
    if (params) {
      path = `${path}&${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  createRole(
    payload: RolePostRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.rolePath}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  updateRole(
    id: number,
    payload: RoleUpdateRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.rolePath}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  getPermissionsByRole(
    role_id: number,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise<{
    message: string;
    selected_permission_ids: number[];
  }> {
    const path = `${this.rolePath}/${role_id}/permission`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  // Permissions
  getPermissions(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.permissionPath}/?per_page=999`;
    if (params) {
      path = `${path}&${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  createDestinationWarehouseRole(
    payload: IDestinationWarehouseRoleForm,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.rolePath}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
}

export default AclResource;
