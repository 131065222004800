export enum CountryCollectionEnums {
  CountryCollection = "CountryCollection",
  Country = "Country",
}
export enum CountryEnum {
  CountryCollection = "CountryCollection",
  Country = "Country",
}
export enum CountryDataStatus {
  active = "active",
  inactive = "inactive",
}
