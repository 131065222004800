import { createDataAtom } from "../jotaiUtility";
import { IBuyOrderDetail } from "types/buyOrderDetail";
import { ITimelineTracking } from "types/timelineTracking";
import { BuyProductViewModel } from "models/buyProductDetailModel";
import { IBuyOrderCollection, IBuyProductCollection } from "types/buyOrderCollection";

export const buyOrderCollectionAtom = createDataAtom<IBuyOrderCollection>();
export const buyProductCollectionAtom =
  createDataAtom<IBuyProductCollection>();
export const buyProductTimelineTrackingAtom =
  createDataAtom<ITimelineTracking>();

export const buyProductDetailAtom = createDataAtom<BuyProductViewModel>();

export const buyOrderDetailAtom = createDataAtom<IBuyOrderDetail>();
