import type { DescriptionsProps } from "antd";
import { Card, Descriptions } from "antd";
import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

export const CustomerAssociation = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  const customerAssociation = ShipmentProductData?.getCustomerAssociation();
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Unit type",
      children: customerAssociation?.getUnitType(),
    },
    {
      key: "2",
      label: "Rate",
      children: customerAssociation?.getRate(),
    },
    {
      key: "3",
      label: "Weight",
      children: customerAssociation?.getWeight() || "none",
    },
    {
      key: "4",
      label: "Piece",
      children: customerAssociation?.getPiece() || "none",
    },
    {
      key: "5",
      label: "Cbm",
      children: customerAssociation?.getCbm() || "none",
    },
  ];
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {customerAssociation ? (
        <Descriptions bordered size="small" layout="vertical" items={items} />
      ) : (
        "Customer association not available yet."
      )}
    </Card>
  );
};
