import QueryString from "qs";
import { Config } from "lib/core/request/types/request";
import { AxiosClient, Client } from "lib/core/request/request";
import KeyManager from "lib/core/request/keyManager";



export default class BaseResource {
  public client: Client;
  public axiosClient: AxiosClient;
  private keyManager: KeyManager;
// some
  constructor(config: Config) {
    this.client = new Client(config);
    this.axiosClient = new AxiosClient(config);
    this.keyManager = new KeyManager();
  }
  /**
   * Set a PublishableApiKey that will be sent with each request
   * to define the scope of available resources.
   *
   * @param key - PublishableApiKey identifier
   */
  setPublishableKey(key: string) {
    this.keyManager.registerPublishableApiKey(key);
  }

  protected addQuery(query: Record<string, any>) {
    return QueryString.stringify(query, { addQueryPrefix: true });
  }
}
