import ChunkedUploady from "@rpldy/chunked-uploady";
import { DivUploadButton } from "./DivUploadButton";
import { FILE_UPLOAD_URL } from "consts/storage";
import setAndGetGuestData from "helpers/setAndGetGuestData";

interface IProps {
    form: any;
    fieldName: string;
    accept?: string;
    uploadUrl?: string;
}
export const SingleChunkUpload = ({
    form,
    fieldName,
    accept = "image/jpeg,image/png,image/jpg,image/gif,image/webp",
}: IProps) => {
    return (
        <ChunkedUploady
            accept={accept}
            multiple={false}
            chunkSize={204800}
            method="POST"
            destination={{
                url: FILE_UPLOAD_URL,
                headers: {
                    Accept: "application/json",
                    ...(process.env.REACT_APP_ORY_TOKEN && {
                        "X-SESSION-TOKEN": process.env.REACT_APP_ORY_TOKEN,
                    }),
                    "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
                    "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
                },
            }}
        >
            <DivUploadButton form={form} fieldName={fieldName} />
        </ChunkedUploady>
    );
};
