import { useAtom, useSetAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import {
    productDetailAtom,
    productSearchResultAtom,
} from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { isLoadingCampaignAtom } from "./useCampaign";
import { useState } from "react";

export const useGetProductDetailsCollection = () => {
    const [productSearchResult, setProductSearchResult] = useAtom(
        productSearchResultAtom,
    );
    const setIsLoadingCampaign = useSetAtom(isLoadingCampaignAtom);
    const { productService } = useService();
    const getProductDetailsCollection = async (
        params: Record<string, any>,
        payload?: Record<string, any>,
    ): Promise<void> => {
        setIsLoadingCampaign(true);
        setProductSearchResult({
            ...productSearchResult,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await productService.productResource.getProductList(
                    params,
                    payload,
                );

            setIsLoadingCampaign(false);

            if (response.data) {
                setProductSearchResult({
                    ...productSearchResult,
                    isLoading: false,
                    data: response.data,
                    refetch: false,
                });
            }
        } catch (error: any) {
            setIsLoadingCampaign(false);
            setProductSearchResult({
                ...productSearchResult,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getProductDetailsCollection } as const;
};

export const useGetProductDetails = () => {
    const [productDetailResult, setProductDetailResult] =
        useAtom(productDetailAtom);
    const { productService } = useService();
    const getProductDetails = async (
        params: Record<string, any>,
        productId: string,
    ): Promise<void> => {
        setProductDetailResult({
            ...productDetailResult,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await productService.productResource.getProductDetails(
                    params,
                    productId,
                );

            setProductDetailResult({
                ...productDetailResult,
                isLoading: false,
                data: response.data.data,
                refetch: false,
            });
        } catch (error: any) {
            setProductDetailResult({
                ...productDetailResult,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getProductDetails } as const;
};

export const useGetProductDetailsByUrl = () => {
    const [productDetailResult, setProductDetailResult] =
        useAtom(productDetailAtom);
    const { productService } = useService();
    const getProductDetails = async (
        params: Record<string, any>,
        productId: string,
    ): Promise<void> => {
        setProductDetailResult({
            ...productDetailResult,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await productService.productResource.getProductDetails(
                    params,
                    productId,
                );

            if (response.data) {
                setProductDetailResult({
                    ...productDetailResult,
                    isLoading: false,
                    data: response.data,
                    refetch: false,
                });
            }
        } catch (error: any) {
            setProductDetailResult({
                ...productDetailResult,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getProductDetails } as const;
};

export const useGetProductDescription = () => {
    const { productService } = useService();

    const [data, setData] = useState<
        | {
              message: string;
              data: string;
          }
        | undefined
    >(undefined);

    const getProductDescription = async (
        params: Record<string, any>,
        productId: string,
    ): Promise<
        | {
              message: string;
              data: string;
          }
        | undefined
    > => {
        try {
            const response =
                await productService.productResource.getDescriptionProduct({
                    params,
                    productId,
                });

            if (response && response.data) {
                setData(response.data)
                return response.data;
            }
        } catch (error: any) {
            throw error;
        }
    };
    return { getProductDescription,data } as const;
};
