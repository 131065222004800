// Enum for admin permissions in the Core module
export enum ADMIN_CORE_PERMISSION_ENUM {
    // Country permissions
    ADMIN_COUNTRY_VIEW = "admin-country-view",
    ADMIN_COUNTRY_ADD = "admin-country-add",
    ADMIN_COUNTRY_UPDATE = "admin-country-update",
    ADMIN_COUNTRY_SYNC = "admin-country-sync",

    // Language permissions
    ADMIN_LANGUAGE_VIEW = "admin-language-view",
    ADMIN_LANGUAGE_ADD = "admin-language-add",
    ADMIN_LANGUAGE_UPDATE = "admin-language-update",
    ADMIN_LANGUAGE_SYNC = "admin-language-sync",

    // Region permissions
    ADMIN_REGION_VIEW = "admin-region-view",
    ADMIN_REGION_ADD = "admin-region-add",
    ADMIN_REGION_UPDATE = "admin-region-update",
    ADMIN_REGION_SYNC = "admin-region-sync",

    // Currency permissions
    ADMIN_CURRENCY_VIEW = "admin-currency-view",
    ADMIN_CURRENCY_ADD = "admin-currency-add",
    ADMIN_CURRENCY_UPDATE = "admin-currency-update",
    ADMIN_CURRENCY_SYNC = "admin-currency-sync"
}




