import { Descriptions, Divider, Tag } from "antd";
import { generateColor } from "utils/helper";

const ViewDetailsModal = ({ product }) => {
  return (
    <>
      <Descriptions title="Harvest Informations">
        <Descriptions.Item label="Batch Number">
          {product.getBatchNumber()}
        </Descriptions.Item>

        <Descriptions.Item label="User Name">
          {product.getUser().getName()}
        </Descriptions.Item>
        <Descriptions.Item label="Tags">
          {product.getTags().data.map((i, index) => (
            <Tag key={index} color={generateColor(i.getTag())}>
              {i.getTag()}
            </Tag>
          ))}
        </Descriptions.Item>
      </Descriptions>

      <Divider></Divider>
    </>
  );
};

export default ViewDetailsModal;
