import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,Skeleton, notification
} from "antd";
import { useAtom } from "jotai";

import {
  useDwAddressCreate,
  useDwAddressUpdate,
  useGetAddressV2,
} from "lib/core-react/hooks/private/useAddress";
import { addressSchemaAtom } from "lib/core-react/store/store";
import { useDwAddressSchema } from "lib/core-react/hooks/private/useAddressSchema";
import { AddressSchemaModel } from "models/addressSchemaModel";
import { IDestinationWarehouseAddressForm } from "types/destinationWarehouseCollection";
import { AddressModel } from "models/addressCollectionModel";
import { DestinationWarehouseModel } from "models/destinationWarehouseCollectionModel";
import {showError} from "../../../../../helpers/showError";

interface IProps {
  destinationWarehouse: DestinationWarehouseModel;
  data?: AddressModel;
  onHide: () => void;
}

export const DwAddressModification = ({
  destinationWarehouse,
  data,
  onHide,
}: IProps) => {
  const [form] = Form.useForm();
  const [dynamicOptions, setDynamicOptions] = useState({});
  const { getAddress } = useGetAddressV2();
  const { createAddress, isLoading } = useDwAddressCreate();
  const {
    updateAddress,
    isLoading: isLoadingUpdate,
  } = useDwAddressUpdate();

  const { getAddressSchema } = useDwAddressSchema();

  const [{ data: addressSchema, isLoading: isLoadingSchema }] =
    useAtom(addressSchemaAtom);

  const addressSchemaData =
    addressSchema && new AddressSchemaModel(addressSchema);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if(destinationWarehouse){
      getAddressSchema(destinationWarehouse.getCountry().getCode(), "en");
      form.setFields([{ name:"country", value:destinationWarehouse.getCountry().getId()}])
    }
  }, [destinationWarehouse]);

  useEffect(() => {
    if (data) {
      const fieldsValue = {};
      Object.keys(data.address).forEach((key) => {
        fieldsValue[key] = data.address[key];
      });
      form.setFieldsValue(fieldsValue);
      form.setFieldValue("is_default_shipping", data.getIsDefaultShipping());
    }
  }, [data]);

  const onFinishInvite = async (values: any) => {
    const { is_default_shipping, country,  ...restOfValues } = values;
    try {
      const payload: IDestinationWarehouseAddressForm = {
        destination_warehouse_id: destinationWarehouse.getId(),
        is_default_shipping,
        address: {...restOfValues, country: destinationWarehouse.getCountry().getName()},
      };
      !data && (await createAddress(payload));
      data && (await updateAddress(data.getId(), payload));
      getAddress(
        `countries[0]=${destinationWarehouse.getCountry().getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
      );
      notification.success(
        {message: data ? "Address updated successfully" : "Address created successfully",}
      );
      onHide();
      form.resetFields();
    } catch (error) {
      showError(error, form)
    }
  };

  const webFull = addressSchemaData?.getData().getWebFull() || {};
  const fields = addressSchemaData?.getData().getFields() || {};
  const handleParentChange = (parentKey: string, value: string) => {
    const parentField = fields[parentKey];

    if (parentField && parentField.relation) {
      const childKey = parentField.relation.relations.child;

      if (childKey && fields[childKey]) {

        const childOptions = parentField.data?.data[value]?.[childKey] || [];

        setDynamicOptions((prev) => ({
          ...prev,
          [childKey]: childOptions.map((childValue) => {
            const childData = fields[childKey]?.data?.data[childValue];

            if (typeof childData === "string") {
              return {
                label: childData,
                value: childValue,
              };
            } else if (typeof childData === "object" && childData !== null) {
              return {
                label: childData.name,
                value: childValue,
              };
            }
            return {
              label: childValue,
              value: childValue,
            };
          }),
        }));
        form.setFieldsValue({ [childKey]: null });
      }
    }
  };


  const renderField = (fieldKey, fieldSchema) => {
    const { type, place_holder, label, is_required, errors, data_source, data, properties, resource } = fieldSchema;

    let fieldComponent;
    const rules = [
      { required: is_required, message: errors?.on_empty || `${label} is required` },
    ];

    if (type === 'string') {
      fieldComponent = (
        <Form.Item label={label} name={fieldKey} rules={rules}>
          <Input placeholder={place_holder} disabled={!properties.is_editable} />
        </Form.Item>
      );
    } else if (type === 'textarea') {
      fieldComponent = (
        <Form.Item label={label} name={fieldKey} rules={rules}>
          <Input.TextArea placeholder={place_holder} disabled={!properties.is_editable} />
        </Form.Item>
      );
    } else if (type === 'select') {
      const options = dynamicOptions[fieldKey] || (data_source === 'on-premise' ? Object.keys(data?.data || {}).map(x => ({
        label: data?.data[x]?.name,
        value: x,
      })) : []);

      let externalOption:{label:string, value:string | number}[] = []
      if(data_source === "external" ){
        switch (resource) {
          case "country":
            externalOption =[{label:destinationWarehouse.getCountry().getName(), value:destinationWarehouse.getCountry().getId()}]
            break;
          default:
            externalOption=[]
        }

        fieldComponent = (
          <Form.Item label={label} name={fieldKey} rules={rules}>
            <Select   filterOption={(input, option) =>
              (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
            }  onChange={(value) => handleParentChange(fieldKey, value)} showSearch={true} placeholder={place_holder} disabled={!properties.is_editable} options={externalOption} />
          </Form.Item>
        )
      }else{

        fieldComponent = (
          <Form.Item label={label} name={fieldKey} rules={rules}>
            <Select   filterOption={(input, option) =>
              (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
            }  onChange={(value) => handleParentChange(fieldKey, value)} showSearch={true} placeholder={place_holder} disabled={!properties.is_editable} options={options} />
          </Form.Item>
        );
      }
    }

    return fieldComponent;
  };

  const renderLines = (lines) => {
    return lines.map((line) => (
      <Row gutter={16} key={line.join('-')}>
        {line.map((fieldKey) => (
          <Col span={12} key={fieldKey}>
            {fields && renderField(fieldKey, fields[fieldKey])}
          </Col>
        ))}
      </Row>
    ));
  };

  if(isLoadingSchema){

    return <Skeleton active  paragraph={{ rows: 6 }}  />
  }
  return !isLoadingSchema && !addressSchemaData ? (
    <div style={{ color: "red" }}>Address schema not found!</div>
  ) : (        <Form form={form}   onFinish={onFinishInvite} layout="vertical">
    {webFull && Object.keys(webFull).map((lineKey) => renderLines([webFull[lineKey]]))}
    <Row>
      <Col>
        <Form.Item name="is_default_shipping" valuePropName="checked" initialValue={false}>
          <Checkbox>Set as default shipping address</Checkbox>
        </Form.Item>
      </Col>
    </Row>
    <Row justify="space-between">

      <Col>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={isLoading || isLoadingUpdate}
          >
            {data ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
  );
};
