import { IPagination } from "types/pagination";
import { IPermission, IPermissionCollection } from "types/permissionCollection";
import { PermissionEnum } from "enums/permissionCollectionEnums";

export class PermissionModel {
  public readonly object: PermissionEnum.Permission;
  public readonly id: number;
  public readonly name: string;
  public readonly label: string;
  public readonly group_name: string;

  constructor(dataItem: IPermission) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.label = dataItem.label;
    this.group_name = dataItem.group_name;
  }
}

export class PermissionCollectionModel {
  public readonly object: PermissionEnum.PermissionCollection;
  public readonly data: PermissionModel[];
  public readonly pagination: IPagination;

  constructor(data: IPermissionCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new PermissionModel(item));
    this.pagination = data.pagination;
  }
}
