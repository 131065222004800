import { useEffect } from "react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { AgentVerificationModel } from "models/agentVerificationCollectionModel";
import { IAgentVerificationFormData } from "types/agentVerificationCollection";
import { AgentVerificationStatus } from "enums/agentCompanyCollectionEnums";

import { renderOptionsFromEnumReversed } from "components/Form/forms";

interface IProps {
  form: FormInstance<any>;
  selectedVerification: AgentVerificationModel;
  onFinish: (value: IAgentVerificationFormData) => Promise<void>;
}

export const UpdateVerificationStatus = ({
  form,
  selectedVerification,
  onFinish,
}: IProps) => {
  useEffect(() => {
    form.resetFields();
    
  }, [selectedVerification]);

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        name="Update Verification Status"
        initialValues={{
          status: selectedVerification.getStatus(),
        }}
      >
        <Form.Item
          rules={[{ required: true, message: "Status is required" }]}
          name="status"
          label="Status"
        >
          <Select placeholder="Please select a status">
            {renderOptionsFromEnumReversed(AgentVerificationStatus)}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};
