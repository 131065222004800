import {
  RegionCode,
  RegionDataStatus,
  RegionEnum,
  RegionStoreType,
} from "enums/regionCollectionEnums";
import { CurrencyDataModel } from "./currency";
import { CountryModel } from "./country";
import { LanguageModel } from "./language";
import { IFilterType } from "types/filters";
import { IRegionCollection, IRegion } from "types/regionCollection";
import { PaginationModel } from "./paginationModel";

export class RegionModel {
  object: RegionEnum.Region;
  id: number;
  store_type: RegionStoreType;
  store: string;
  code: RegionCode;
  name: string;
  status: RegionDataStatus;
  created_at: string;
  currency: CurrencyDataModel | undefined;
  countries: CountryModel | undefined;
  languages: LanguageModel | undefined;

  constructor(dataItem: IRegion) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.store_type = dataItem.store_type;
    this.store = dataItem.store;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
    // @ts-ignore FIXME
    this.currency = dataItem?.currency;
    this.countries = dataItem.countries
      ? new CountryModel(dataItem.countries)
      : undefined;
    this.languages = dataItem.languages
      ? new LanguageModel(dataItem.languages)
      : undefined;
  }

  getId = () => this.id;
  getStoreType = () => this.store_type;
  getStore = () => this.store;
  getCode = () => this.code;
  getName = () => this.name;
  getStatus = () => this.status;
  getCreatedAt = () => this.created_at;
  getCurrency = () => this.currency;
  getCountries = () => this.countries;
  getLanguages = () => this.languages;
}

export class RegionCollectionModel {
  object: RegionEnum.RegionCollection;
  data: RegionModel[];
  pagination: PaginationModel;
  filters: IFilterType;
  constructor(data: IRegionCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new RegionModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }
  getRegionIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
  getRegionCodesAsObject() {
    const obj: { [key: string]: string } = {};
    this.data.forEach((item) => (obj[item.code] = item.code));
    return obj;
  }

  getRegionLabeledValue() {
    return this.data.map((item) => ({
      label: item.getCode(),
      value: item.getCode(),
      country: item.getCountries(),
    }));
  }
  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
}
