import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IAgentWarehouseFormData } from "types/agentWarehouseCollection";

class AgentWarehouseResource extends BaseResource {
  path = `/api/shipping-agent/admin/agent-company/v1`;
  /**
   * @description Gets agent warehouse list,
   * accepts fields and expands as params
   */
  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/warehouses?expand=country,agentCompany${
      params ? `&${params}` : ""
    }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  /**
   * @description Updates warehouse
   */
  update(
    agent_company_id: number,
    warehouse_id: number,
    payload: IAgentWarehouseFormData,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/warehouses/${warehouse_id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default AgentWarehouseResource;
