export const STATUS_PENDING                 = 'buy-pending'
export const STATUS_APPROVED                = 'buy-approved'
export const STATUS_PROCESSING              = 'buy-processing'
export const STATUS_REJECT                  = 'buy-reject'
export const STATUS_PURCHASED               = 'buy-purchased'
export const STATUS_NOT_PURCHASED           = 'buy-not-purchased'
export const STATUS_HANDOVER                = 'handover-to-shipping'
export const STATUS_ARRIVED_AT_WAREHOUSE    = 'arrived-at-warehouse'
export const STATUS_PREPARING_FOR_TRANSPORT = 'preparing-for-transport'
export const STATUS_HANDOVER_TO_AIRLINE     = 'handover-to-airline'
export const STATUS_CUSTOM_RELEASED         = 'customs-released'
export const STATUS_AGENT_PROCESSED         = 'agent-processed'
export const STATUS_RECEIVED_BY_MOVEON      = 'received-by-moveon'
export const STATUS_DELIVERY_PENDING        = 'delivery-pending'
export const STATUS_PRODUCT_CANCELLED       = 'buy-cancelled'
export const STATUS_CANCEL_INITIATED        = 'buy-cancel-initiated'
export const STATUS_REFUND_INITIATED        = 'refund-initiated'
export const STATUS_REFUNDED                = 'refunded'