import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { AuctionRuleRequestModel } from "models/auctionRule";

class AuctionRuleResource extends BaseResource {
  path = `/api/shipping-core/admin/auction-rule/v1/auction-rules`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}?${params ? params : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: AuctionRuleRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: AuctionRuleRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default AuctionRuleResource;
