import { Dispatch, SetStateAction, useEffect } from "react";

import { ProductDetailModel } from "models/productDetailCollectionModel";

import GridItem from "./GridItem";
import AppGrid from "components/App/AppGrid";
import ListEmptyResult from "components/App/AppList/ListEmptyResult";
import { useLocation } from "react-router-dom";
import { CampaignProductModel } from "models/campaignProductCollectionModel";
import CampaignGridItem from "./CampaignGridItem";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";
import { campaignProductCollectionAtom } from "lib/core-react/store/store";
import { useAtom } from "jotai";

type Props = {
  selectedProducts: string[];
  setSelectedProducts: Dispatch<SetStateAction<string[]>>;
  ectomereList?: ProductDetailModel[];
  campaignProductList?: CampaignProductModel[];
  loading: boolean;
};
const ProductGrid = ({
  selectedProducts,
  setSelectedProducts,
  ectomereList,
  campaignProductList,
  loading,
}: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");

  const [{ refetch }] = useAtom(campaignProductCollectionAtom);

  const isChecked = (id: string) => {
    return selectedProducts.includes(id);
  };

  useEffect(() => {
    if (refetch) {
      setSelectedProducts([]);
    }
  }, [refetch]);

  const handleCheck = (id: string) => {
    if (selectedProducts.includes(id)) {
      // If the product is already selected, let's remove it
      setSelectedProducts(
        selectedProducts.filter((productId) => productId !== id),
      );
    } else {
      // If the product is not selected, let's add it
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  if (operation === CampaignOperationEnum.ViewCampaignProduct) {
    return (
      <AppGrid
        itemPadding={8}
        delay={200}
        responsive={{
          xs: 1,
          sm: 4,
          xxl: 6,
        }}
        data={campaignProductList ? campaignProductList : []}
        renderItem={(item) => (
          <CampaignGridItem
            selectable
            isChecked={isChecked(item.getId())}
            handleCheck={handleCheck}
            item={item}
            key={item.getId()}
          />
        )}
        ListEmptyComponent={
          <ListEmptyResult content="No product found" loading={loading} />
        }
      />
    );
  }

  return (
    <AppGrid
      itemPadding={8}
      delay={200}
      responsive={{
        xs: 1,
        sm: 4,
        xxl: 6,
      }}
      data={ectomereList ? ectomereList : []}
      renderItem={(item) => (
        <GridItem
          selectable={operation === CampaignOperationEnum.AddCampaignProduct}
          isChecked={isChecked(item.getId())}
          handleCheck={handleCheck}
          item={item}
          key={item.getId()}
        />
      )}
      ListEmptyComponent={
        <ListEmptyResult content="No product found" loading={loading} />
      }
    />
  );
};
export default ProductGrid;
