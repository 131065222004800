import {
  IShipmentProductPackagePropertyVariation,
  IShipmentProductPackagePropertyVariationCollection,
  IShipmentProductPackageVariation,
  IShipmentProductPackageVariationCollection,
} from "types/shipmentProductPackageVariationCollection";

export class ShipmentProductPackagePropertyVariationModel {
  object: string;
  id: number;
  property_id: string;
  property_name: string | null;
  property_original_name: string;
  property_value_id: string;
  property_value_name: string;
  property_value_title: string | null;
  property_value_color: string | null;
  property_value_image: string | null;
  property_value_thumb: string | null;

  constructor(data: IShipmentProductPackagePropertyVariation) {
    this.object = data.object;
    this.id = data.id;
    this.property_id = data.property_id;
    this.property_name = data.property_name;
    this.property_original_name = data.property_original_name;
    this.property_value_id = data.property_value_id;
    this.property_value_name = data.property_value_name;
    this.property_value_title = data.property_value_title;
    this.property_value_color = data.property_value_color;
    this.property_value_image = data.property_value_image;
    this.property_value_thumb = data.property_value_thumb;
  }

  getId = () => this.id;
  getPropertyId = () => this.property_id;
  getPropertyName = () => this.property_name;
  getPropertyOriginalName = () => this.property_original_name;
  getPropertyValueId = () => this.property_value_id;
  getPropertyValueName = () => this.property_value_name;
  getPropertyValueTitle = () => this.property_value_title;
  getPropertyValueColor = () => this.property_value_color;
  getPropertyValueImage = () => this.property_value_image;
  getPropertyValueThumb = () => this.property_value_thumb;
}

export class ShipmentProductPackagePropertyVariationCollectionModel {
  object: string;
  data: ShipmentProductPackagePropertyVariationModel[];

  constructor(data: IShipmentProductPackagePropertyVariationCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (d) => new ShipmentProductPackagePropertyVariationModel(d),
    );
  }

  getData = () => this.data;
}

export class ShipmentProductPackageVariationModel {
  object: string;
  id: number;
  quantity: number;
  sku_id: string;
  properties: ShipmentProductPackagePropertyVariationCollectionModel;

  constructor(data: IShipmentProductPackageVariation) {
    this.object = data.object;
    this.id = data.id;
    this.quantity = data.quantity;
    this.sku_id = data.sku_id;
    this.properties =
      new ShipmentProductPackagePropertyVariationCollectionModel(
        data.properties,
      );
  }

  getId = () => this.id;
  getQuantity = () => this.quantity;
  getSkuId = () => this.sku_id;
  getProperties = () => this.properties;
}

export class ShipmentProductPackageVariationCollectionModel {
  object: string;
  data: ShipmentProductPackageVariationModel[];

  constructor(data: IShipmentProductPackageVariationCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (item) => new ShipmentProductPackageVariationModel(item),
    );
  }

  getData = () => this.data;
}
