import { useAtom } from "jotai";

import {
  StyledProductSidebar,
  StyledProductSidebarTitle,
} from "./index.styled";

import { ProductSearchResultModel } from "models/productDetailCollectionModel";
import { productSearchResultAtom } from "lib/core-react/store/store";

import AppScrollbar from "components/App/AppScrollbar";
import CountriesFacet from "./components/CountriesFacet";
import SellersFacet from "./components/SellersFacet";
import BrandsFacet from "./components/BrandsFacet";
import CategoriesFacet from "./components/CategoriesFacet";
import ShopsFacet from "./components/ShopsFacet";
import PriceFacet from "./components/PriceMinimumRangeFacet";
import RatingAverageRangeFacet from "./components/RatingAverageRangeFacet";
// import { Skeleton } from "antd";
import PropertiesFacet from "./components/PropertiesFacet";

const ProductsSidebar = () => {
  const [{ data }] = useAtom(productSearchResultAtom);

  const productList = data && new ProductSearchResultModel(data);

  // if (!productList || isLoading) {
  //   return (
  //     <AppScrollbar>
  //       <StyledProductSidebar>
  //         <Skeleton active />
  //       </StyledProductSidebar>
  //     </AppScrollbar>
  //   );
  // }
  return (
    <AppScrollbar>
      <StyledProductSidebar>
        <StyledProductSidebarTitle>Filter By</StyledProductSidebarTitle>

        { productList &&  productList.getFacet().getCountries().length ? (
          <CountriesFacet
            countriesField={productList.getFacet().getCountries()}
          />
        ) : (
          ""
        )}
        {productList &&  productList.getFacet().getCategories().length ? (
          <CategoriesFacet
            categoriesField={productList.getFacet().getCategories()}
          />
        ) : (
          ""
        )}
        {productList &&  productList.getFacet().getPriceMinimumRanges().length ? (
          <PriceFacet
            priceMinimumRangesField={productList
              .getFacet()
              .getPriceMinimumRanges()}
          />
        ) : (
          ""
        )}
        {productList &&  productList.getFacet().getRatingAverageRanges().length ? (
          <RatingAverageRangeFacet
            ratingAverageRangesField={productList
              .getFacet()
              .getRatingAverageRanges()}
          />
        ) : (
          ""
        )}
        {productList && productList.getFacet().getBrands().length ? (
          <BrandsFacet brandsField={productList.getFacet().getBrands()} />
        ) : (
          ""
        )}
        { productList &&  productList.getFacet().getSellers().length ? (
          <SellersFacet sellersField={productList.getFacet().getSellers()} />
        ) : (
          ""
        )}
        {productList && productList.getFacet().getShops().length ? (
          <ShopsFacet shopsField={productList.getFacet().getShops()} />
        ) : (
          ""
        )}
        {productList && productList.getFacet().getProperties().length ? (
          <PropertiesFacet
            propertiesField={productList.getFacet().getProperties()}
          />
        ) : (
          ""
        )}
      </StyledProductSidebar>
    </AppScrollbar>
  );
};

export default ProductsSidebar;
