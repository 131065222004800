import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Result, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { walletOverviewCollectionAtom } from "lib/core-react/store/store";
import { useEffect, useState } from "react";
import { useUserWalletOverviewBalance } from "lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "models/walletOverviewCollectionModel";
import { useNavigate, useParams } from "react-router-dom";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";

const WalletOverviewForUser = () => {
    const { getUserWalletOverviewBalance } = useUserWalletOverviewBalance();
    const { id } = useParams();
    const navigate = useNavigate();
    const [
        { data: walletOverviewData, refetch, error: isWalletErrors, isLoading },
    ] = useAtom(walletOverviewCollectionAtom);

    const [walletId, setWalletId] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            getUserWalletOverviewBalance(Number(id));
        }
    }, [refetch]);

    const walletOverviewCollectionData =
        walletOverviewData && new WalletOverviewModel(walletOverviewData);

    const currencyData = walletOverviewCollectionData?.getByCurrency();

    const currencies = Object.entries(currencyData || {}).map(
        ([currencyCode, data]) => ({
            code: currencyCode,
            totalAvailableBalance: data.totalAvailableBalance,
            totalReservedBalance: data.totalReservedBalance,
            totalPendingBalance: data.totalPendingBalance,
            symbol: data.symbol,
            walletIds: data.walletIds,
        }),
    );

    const handleWalletBalanceClick = (walletIds: any) => {
        setWalletId(walletIds);
    };

    useEffect(() => {
        if (currencies && currencies[0]?.walletIds) {
            setWalletId(currencies[0]?.walletIds);
        }
    }, [walletOverviewData]);

    if (!isLoading && isWalletErrors) {
        return (
            <>
                <Result
                    status="404"
                    title="404"
                    subTitle={isWalletErrors}
                    extra={
                        <Button
                            onClick={() => navigate("/customer/user")}
                            type="primary"
                        >
                            Back Home
                        </Button>
                    }
                />
            </>
        );
    }

    return (
        <PageHeader
            ghost={false}
            title="User Wallet"
            style={{ marginTop: "10px" }}
            onBack={() => {
                setWalletId([]);
                window.history.back();
            }}
        >
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <WalletCard
                        walletId={walletId}
                        handleWalletBalanceClick={handleWalletBalanceClick}
                        item={currencies}
                    />
                </Row>
            )}
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <Col span={24}>
                        <WalletTable isLoading={false} walletId={walletId} />
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
};

export default WalletOverviewForUser;
