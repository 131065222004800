import {
  ICommonProductImage,
  ICommonProductImageCollection,
  ICommonRegion,
  ICommonUser,
} from "types/shared";

export class CommonUserModel {
  id: number;
  name: string;
  email: string;
  phone?: string | null;
  gravatar?: string | null;
  shipping_mark?: string | null;
  type?: string | null;

  constructor(data: ICommonUser) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.gravatar = data.gravatar;
    this.shipping_mark = data.shipping_mark;
    this.type = data.type;
  }

  getId = () => this.id;
  getName = () => this.name;
  getEmail = () => this.email;
  getPhone = () => this.phone;
  getGravatar = () => this.gravatar;
  getShippingMark = () => this.shipping_mark;
  getType = () => this.type;
}

export class CommonRegionModel {
  object: string;
  id: number;
  name: string;
  code: string;

  constructor(data: ICommonRegion) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
  }

  getId = () => this.id;
  getName = () => this.name;
  getCode = () => this.code;
}

// common product image
export class CommonProductImageModel {
  object: string;
  title: string | null;
  id: number;
  alt: string | null;
  src: string;
  display_order: number;

  constructor(data: ICommonProductImage) {
    this.object = data.object;
    this.title = data.title;
    this.id = data.id;
    this.alt = data.alt;
    this.src = data.src;
    this.display_order = data.display_order;
  }

  getTitle = () => this.title;
  getId = () => this.id;
  getAlt = () => this.alt;
  getSrc = () => this.src;
  getDisplayOrder = () => this.display_order;
}

export class CommonProductImageCollectionModel {
  object: string;
  data: CommonProductImageModel[];

  constructor(data: ICommonProductImageCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CommonProductImageModel(item));
  }

  getData = () => this.data;
  getFirstImageSrc = () => {
    if (this.data.length) return this.data[0].getSrc();
    else return "";
  };
}
