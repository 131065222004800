import {
  CurrencyDataStatus,
  CurrencyEnum,
} from "enums/currencyCollectionEnums";
import {
  ICurrency,
  ICurrencyData,
  ICurrencyUpdateRequest,
} from "types/currency";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class CurrencyDataModel {
  public readonly object: CurrencyEnum.Currency;
  public readonly id: number;
  public readonly name: string;
  public readonly code: string;
  public readonly symbol: string;
  public readonly symbol_native: string;
  public readonly status: CurrencyDataStatus;
  public readonly created_at: string;
  constructor(dataItem: ICurrencyData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.code = dataItem.code;
    this.symbol = dataItem.symbol;
    this.symbol_native = dataItem.symbol_native;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }

  getSymbolNative() {
    return this.symbol_native;
  }
}

export class CurrencyModel {
  public readonly object: CurrencyEnum.CurrencyCollection;
  public readonly data: CurrencyDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(dataItem: ICurrency) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((item) => new CurrencyDataModel(item));
    this.pagination = dataItem.pagination;
    this.filters = dataItem.filters;
  }

  getData() {
    return this.data;
  }

  getCurrencyCode() {
    const obj: { [key: string]: string } = {};
    this.data.forEach((item) => (obj[item.code] = item.code));
    return obj;
  }
  getCurrencyId() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
}

//Update Request
export class CurrencyCreateUpdateRequestModel {
  public readonly code: string;
  public readonly name: string;
  public readonly symbol: string;
  public readonly symbol_native: string;
  public readonly status: CurrencyDataStatus;
  constructor(reqData: ICurrencyUpdateRequest) {
    this.name = reqData.name;
    this.code = reqData.code;
    this.symbol = reqData.symbol;
    this.symbol_native = reqData.symbol_native;
    this.status = reqData.status;
  }
}
