import { useState } from "react"
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { shippingCommissionAtom } from "lib/core-react/store/store";
import { CommissionCopyForBaseShippingRequestModel, CommissionCopyForWarehouseRequestModel, CommissionCopyRequestModel, CommissionPostRequestModel } from "models/commissionCollectionModel";
import { ICategoryCommissionAssociationsPutRequest } from "types/commissionCollection";

export const useGetCommission = () => {
  const [commission, setCommission] = useAtom(shippingCommissionAtom);
  const { shippingService } = useService();
  const getCommission = async (params?: string): Promise<void> => {
    setCommission({ ...commission, isLoading: true, error: null });
    try {
      const response = await shippingService.commissionResource.get(params);
      setCommission({
        ...commission,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setCommission({
        ...commission,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getCommission } as const;
};

export const useCreateCommission = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);

  const createCommission = async (payload: CommissionPostRequestModel) => {
    setIsLoading(true);
    try {
      const response = await shippingService.commissionResource.create(payload);
      setIsLoading(false);
      setCommissionData({ ...commissionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createCommission, isLoading, isError };
};

export const useUpdateCommissionCategory = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);

  const updateCommissionCategory = async (id: string, payload: ICategoryCommissionAssociationsPutRequest) => {
    setIsLoading(true);
    try {
      const response = await shippingService.commissionResource.updateCategoryCommission(id, payload);
      setIsLoading(false);
      setCommissionData({ ...commissionData, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { updateCommissionCategory, isLoading, isError };
};

export const useDeleteCategoryCommission = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);

  const deleteCategoryCommission = async (
    id: number
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.commissionResource.deleteCategoryCommission(id);
      setIsLoading(false);
      setCommissionData({ ...commissionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { deleteCategoryCommission, isLoading, isError };
};

export const useCopyCommissionForRegion = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);
  
  const copyCommission = async (id: number, payload: CommissionCopyRequestModel) => {
      setIsLoading(true);
      try {
        const response = await shippingService.commissionResource.copyCommissionForRegion(id, payload);
        setIsLoading(false);
        setCommissionData({ ...commissionData, refetch: true });
        return response.message;
      } catch (error: any) {
        setError(getError(error));
        setIsLoading(false);
        throw error;
      }
    };

  return { copyCommission, isLoading, isError };
}

export const useCopyCommissionForWarehouse = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);
  
  const copyCommission = async (commission_id: number, commission_association_id: number, payload: CommissionCopyForWarehouseRequestModel) => {
      setIsLoading(true);
      try {
        const response = await shippingService.commissionResource.copyCategoryCommissionForWarehouse(commission_id, commission_association_id, payload);
        setIsLoading(false);
        setCommissionData({ ...commissionData, refetch: true });
        return response.message;
      } catch (error: any) {
        setError(getError(error));
        setIsLoading(false);
        throw error;
      }
    };

  return { copyCommission, isLoading, isError };
}

export const useCopyCommissionForBaseShipping = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [commissionData, setCommissionData] = useAtom(shippingCommissionAtom);
  
  const copyCommission = async (commission_id: number, commission_association_id: number, category_commission_id: number, payload: CommissionCopyForBaseShippingRequestModel) => {
      setIsLoading(true);
      try {
        const response = await shippingService.commissionResource.copyCategoryCommissionForBaseShipping(commission_id, commission_association_id, category_commission_id, payload);
        setIsLoading(false);
        setCommissionData({ ...commissionData, refetch: true });
        return response.message;
      } catch (error: any) {
        setError(getError(error));
        setIsLoading(false);
        throw error;
      }
    };

  return { copyCommission, isLoading, isError };
}
