import {
    CommissionRateTypeEnums,
    ExchangeListCollectionEnums,
    RegionalExchangeCollectionEnums,
    StoreExchangeCollectionEnums,
} from "enums/exchangeListCollectionEnums";
import {
    IExchangeList,
    IExchangeListCollection,
    // IExchangeListPostRequest,
    IRegionalExchange,
    IRegionalExchangeCollection,
    // IRegionalExchangePostRequest,
    IRegionalExchangeRegion,
    IRegionalExchangeRegionCountryCollection,
    IRegionalExchangeRegionCurrency,
    IRegionalExchangeRegionLanguageCollection,
    IStoreExchange,
    IStoreExchangeCollection,
    IStoreExchangeStore,
} from "types/exchangeListCollection";
import {
    RegionCode,
    RegionDataStatus,
    RegionStoreType,
} from "enums/regionCollectionEnums";
import { CountryModel } from "./countryCollectionModel";
import { CountryCollectionEnums } from "enums/countryCollectionEnums";
import { LanguageModel } from "./languageCollectionModel";
import { LanguageEnum } from "enums/languageCollectionEnums";
import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";

export class StoreExchangeStoreModel {
    object: string;
    id: number;
    name: string;
    identifier: string;
    availability_status: string;
    created_at: string;

    constructor(dataItem: IStoreExchangeStore) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.identifier = dataItem.identifier;
        this.availability_status = dataItem.availability_status;
        this.created_at = dataItem.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getIdentifier = () => this.identifier;
    getAvailabilityStatus = () => this.availability_status;
    getCreatedAt = () => this.created_at;
}

export class StoreExchangeModel {
    object: StoreExchangeCollectionEnums.StoreExchange;
    id: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: CommissionRateTypeEnums;
    options: {
        minimum_order_amount: number;
    };
    store: StoreExchangeStoreModel;

    constructor(dataItem: IStoreExchange) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.fx_rate = dataItem.fx_rate;
        this.commission_rate = dataItem.commission_rate;
        this.commission_rate_type = dataItem.commission_rate_type;
        this.options = dataItem.options;
        this.store = new StoreExchangeStoreModel(dataItem.store);
    }

    getId = () => this.id;
    getFxRate = () => this.fx_rate;
    getCommissionRate = () => this.commission_rate;
    getCommissionRateType = () => this.commission_rate_type;
    getTotal = (): string => {
        let total: number = 0;

        if (this.commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
            total = this.fx_rate + (this.fx_rate * this.commission_rate) / 100;
        } else if (
            this.commission_rate_type === CommissionRateTypeEnums.FIXED
        ) {
            total = this.fx_rate + this.commission_rate;
        }
        return total.toFixed(2);
    };

    getOptions = () => this.options;
    getMinimumOrderAmount = () => this.getOptions().minimum_order_amount;
    getStore = () => this.store;
}

export class StoreExchangeCollectionModel {
    object: StoreExchangeCollectionEnums.StoreExchangeCollection;
    data: StoreExchangeModel[];
    constructor(dataItem: IStoreExchangeCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((d) => new StoreExchangeModel(d));
    }
    getData = () => this.data;
}

export class RegionalExchangeRegionLanguageCollectionModel {
    object: LanguageEnum.LanguageCollection;
    data: LanguageModel[];

    constructor(dataItem: IRegionalExchangeRegionLanguageCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((d) => new LanguageModel(d));
    }
}

export class RegionalExchangeRegionCountryCollectionModel {
    object: CountryCollectionEnums.CountryCollection;
    data: CountryModel[];

    constructor(dataItem: IRegionalExchangeRegionCountryCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((d) => new CountryModel(d));
    }
}

export class RegionalExchangeRegionCurrencyModel {
    object: string;
    id: number;
    code: string;
    symbol: string;
    symbol_native: string;
    name: string;
    status: string;
    constructor(dataItem: IRegionalExchangeRegionCurrency) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.code = dataItem.code;
        this.symbol = dataItem.symbol;
        this.symbol_native = dataItem.symbol_native;
        this.name = dataItem.name;
        this.status = dataItem.status;
    }
}
export class RegionalExchangeRegionModel {
    object: string;
    id: number;
    store: string;
    store_type: RegionStoreType;
    code: RegionCode;
    name: string;
    status: RegionDataStatus;
    created_at: string;
    currency: RegionalExchangeRegionCurrencyModel;
    countries: RegionalExchangeRegionCountryCollectionModel;
    languages: RegionalExchangeRegionLanguageCollectionModel;

    constructor(dataItem: IRegionalExchangeRegion) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.store = dataItem.store;
        this.store_type = dataItem.store_type;
        this.code = dataItem.code;
        this.name = dataItem.name;
        this.status = dataItem.status;
        this.created_at = dataItem.created_at;
        this.currency = new RegionalExchangeRegionCurrencyModel(
            dataItem.currency,
        );
        this.countries = new RegionalExchangeRegionCountryCollectionModel(
            dataItem.countries,
        );
        this.languages = new RegionalExchangeRegionLanguageCollectionModel(
            dataItem.languages,
        );
    }
    getId(){
        return this.id
    }
}

export class RegionalExchangeModel {
    object: RegionalExchangeCollectionEnums.RegionalExchange;
    id: number;
    region: RegionalExchangeRegionModel;
    store_exchanges: StoreExchangeCollectionModel;

    constructor(dataItem: IRegionalExchange) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.region = new RegionalExchangeRegionModel(dataItem.region);
        this.store_exchanges = new StoreExchangeCollectionModel(
            dataItem.store_exchanges,
        );
    }
    getId = () => this.id;
    getRegion = () => this.region;
    getStoreExchanges = () => this.store_exchanges;
}

export class RegionalExchangeCollectionModel {
    object: RegionalExchangeCollectionEnums.RegionalExchangeCollection;
    data: RegionalExchangeModel[];

    constructor(dataItem: IRegionalExchangeCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((d) => new RegionalExchangeModel(d));
    }
    getData = () => this.data;
}

export class ExchangeListModel {
    object: ExchangeListCollectionEnums.ExchangeList;
    id: number;
    name: string;
    is_default: number;
    regional_exchanges: RegionalExchangeCollectionModel;

    constructor(dataItem: IExchangeList) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.is_default = dataItem.is_default;
        this.regional_exchanges = new RegionalExchangeCollectionModel(
            dataItem.regional_exchanges,
        );
    }
    getId = () => this.id;
    getName = () => this.name;
    getIsDefault = () => this.is_default;
    getRegionalExchanges = () => this.regional_exchanges;
}

export class ExchangeListCollectionModel {
    object: ExchangeListCollectionEnums.ExchangeListCollection;
    data: ExchangeListModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: IExchangeListCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ExchangeListModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }
    getExchangeListById(id: number): ExchangeListModel | undefined {
        return this.data.find((item) => item.id === id);
    }
    getExchangeListIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
    getSelectComponentSelectOptions() {
        return this.data.map((item) => {
          return { value: item.getId(), label: item.getName() };
        });
      }

    getData = () => {
        const defaultIndex = this.data.findIndex((item) => item.is_default);
        if (defaultIndex === -1) {
            return this.data;
        }
        const defaultElement = this.data.splice(defaultIndex, 1)[0];
        this.data.unshift(defaultElement);
        return this.data;
    };
    getDefaultExchange() {
        return this.data.find((item) => item.is_default);
    }
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
