import { Checkbox, Rate } from "antd";

import {
  StyledProductListAction,
  StyledProductListActionItem,
  StyledProductListCard,
  StyledProductListCardContent,
  StyledProductListContentPara,
  StyledProductListPrice,
  StyledProductListPriceItem,
  StyledProductListPriceItemText,
  StyledProductListPriceItemValue,
  StyledProductListPriceItemValueCut,
  StyledProductListSlider,
  StyledProductListSliderContent,
  StyledProductListSliderContentHeader,
  StyledProductListSliderThumb,
} from "./index.styled";
import { CampaignProductModel } from "models/campaignProductCollectionModel";
import { calculateDiscountPercentage } from "./helpers/calculateDiscountPercentage";
import { useNavigate } from "react-router-dom";

type Props = {
  item: CampaignProductModel;
  isChecked: boolean;
  handleCheck: (id: string) => void;
  selectable: boolean;
};

const CampaignListItem = (props: Props) => {
  const { item, isChecked, handleCheck, selectable } = props;
  const navigate = useNavigate();

  return (
    <StyledProductListCard
      className=""
      extra={
        selectable ? (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheck(item.getId())}
          />
        ) : null
      }
    >
      <StyledProductListCardContent>
        <StyledProductListSlider onClick={() => handleCheck(item.getId())}>
          <StyledProductListSliderThumb key={item.getId()}>
            <img src={item.getProduct().getImage()} alt="watch" />
          </StyledProductListSliderThumb>
        </StyledProductListSlider>

        <StyledProductListSliderContent
        //   onClick={() => {
        //     navigate("product_detail/" + item.getId());
        //   }}
        >
          <StyledProductListSliderContentHeader>
            <h3
              onClick={() => {
                navigate(`product_detail/${item.getId()}`);
              }}
              className="text-truncate item-hover"
            >
              {item.getProduct().getTitle()}
            </h3>
          </StyledProductListSliderContentHeader>

          <StyledProductListContentPara>
            {/* {item.getProduct().getDescription()} */}
          </StyledProductListContentPara>

          <StyledProductListPrice>
            <StyledProductListPriceItem>
              <StyledProductListPriceItemText>
                Price:
              </StyledProductListPriceItemText>
              <StyledProductListPriceItemValue>
                ৳
                {item.getProduct().getPrice().getHikeMin()
                  ? Math.ceil(item.getProduct().getPrice().getHikeMin())
                  : Math.ceil(item.getProduct().getPrice().getHikeMax())}
              </StyledProductListPriceItemValue>
            </StyledProductListPriceItem>
            <StyledProductListPriceItem>
              Mrp:
              <StyledProductListPriceItemValueCut>
                ৳{Math.ceil(item.getProduct().getPrice().getHikeMax())}
              </StyledProductListPriceItemValueCut>
            </StyledProductListPriceItem>
            {calculateDiscountPercentage(
              Math.ceil(item.getProduct().getPrice().getHikeMax()),
              Math.ceil(item.getProduct().getPrice().getHikeMin()),
            ) > 0 ? (
              <StyledProductListPriceItem className="green">
                {calculateDiscountPercentage(
                  Math.ceil(item.getProduct().getPrice().getHikeMax()),
                  Math.ceil(item.getProduct().getPrice().getHikeMax()),
                )}
                % off
              </StyledProductListPriceItem>
            ) : (
              ""
            )}
          </StyledProductListPrice>

          <StyledProductListAction>
            <StyledProductListActionItem>
              <Rate value={item.getProduct().getAverageRatings()} />
            </StyledProductListActionItem>
          </StyledProductListAction>
        </StyledProductListSliderContent>
      </StyledProductListCardContent>
    </StyledProductListCard>
  );
};

export default CampaignListItem;
