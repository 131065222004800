import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { renderOptionsFromEnumReversed } from "components/Form/forms";
import { useAddCampaignProducts } from "lib/core-react/hooks/private/useCampaign";
import { IAddCampaignProductsPostRequest } from "types/campaignCollection";
import { CampaignProductConfigurationTypeEnum } from "enums/campaignCollectionEnums";
import { useNavigate } from "react-router-dom";

interface IProps {
  selectedProducts: string[];
  region: string;
  campaignId: string;
  setIsShowAddToCampaignModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddProductToCampaign = ({
  selectedProducts,
  region,
  campaignId,
  setIsShowAddToCampaignModal,
}: IProps) => {
  const navigate = useNavigate();
  const { addCampaignProducts, isLoading, isError } = useAddCampaignProducts();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = React.createRef();

  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [campaignId, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: IAddCampaignProductsPostRequest) => {
    const payload = {
      product_ids: selectedProducts,
      configuration: {
        price_hike: value.configuration.price_hike,
        hike_type: value.configuration.hike_type,
        discount: value.configuration.discount,
        discount_type: value.configuration.discount_type,
      },
    };

    try {
      await addCampaignProducts(
        campaignId,
        "en",
        region,
        payload as IAddCampaignProductsPostRequest
      );

      message.success("Product added successfully");
      setIsShowAddToCampaignModal(false);
      setIsErrorVisible(false);
      navigate("/campaigns");
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Add_Campaign_Products"
          ref={formRef}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Hike type is required" }]}
                name={["configuration", "hike_type"]}
                label="Hike type"
              >
                <Select placeholder="Please select a hike type">
                  {renderOptionsFromEnumReversed(
                    CampaignProductConfigurationTypeEnum
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Price hike is required",
                  },
                ]}
                label="Price Hike"
                name={["configuration", "price_hike"]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter price hike"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                rules={[
                  { required: true, message: "Discount type is required" },
                ]}
                name={["configuration", "discount_type"]}
                label="Discount type"
              >
                <Select placeholder="Please select a discount type">
                  {renderOptionsFromEnumReversed(
                    CampaignProductConfigurationTypeEnum
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Discount is required",
                  },
                ]}
                label="Discount"
                name={["configuration", "discount"]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter discount"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Add Campaign Products
            </Button>
          </Form.Item>
          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default AddProductToCampaign;
