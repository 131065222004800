import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
  discountAtom,
  discountCollectionAtom,
  discountRulesSchemaAtom,
  discountUsageHistoryCollectionAtom,
} from "lib/core-react/store/store";
import { IDiscountFormData } from "types/discountCollection";

// Discount
export const useCreateDiscount = () => {
  const { discountService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [discountCollectionData, setDiscountCollectionData] = useAtom(
    discountCollectionAtom
  );

  const createDiscount = async (payload: IDiscountFormData) => {
    setIsLoading(true);
    try {
      const response = await discountService.discountResource.createDiscount(
        payload
      );
      setIsLoading(false);
      setDiscountCollectionData({ ...discountCollectionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createDiscount, isLoading, isError };
};

export const useUpdateDiscount = () => {
  const { discountService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [discountCollectionData, setDiscountCollectionData] = useAtom(
    discountCollectionAtom
  );

  const updateDiscount = async (id: number, payload: IDiscountFormData) => {
    setIsLoading(true);
    try {
      const response = await discountService.discountResource.updateDiscount(
        id,
        payload
      );
      setIsLoading(false);
      setDiscountCollectionData({ ...discountCollectionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateDiscount, isLoading, isError };
};

export const useGetDiscounts = () => {
  const [discount, setDiscount] = useAtom(discountCollectionAtom);
  const { discountService } = useService();
  const getDiscounts = async (params?: string): Promise<void> => {
    setDiscount({ ...discount, isLoading: true, error: null });
    try {
      const response = await discountService.discountResource.getDiscounts(
        params
      );
      setDiscount({
        ...discount,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setDiscount({
        ...discount,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getDiscounts } as const;
};

export const useGetDiscount = () => {
  const [discount, setDiscount] = useAtom(discountAtom);
  const { discountService } = useService();
  const getDiscount = async (id: number): Promise<void> => {
    setDiscount({ ...discount, isLoading: true, error: null });
    try {
      const response = await discountService.discountResource.getDiscount(id);

      setDiscount({
        ...discount,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setDiscount({
        ...discount,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getDiscount } as const;
};

export const useDeleteDiscount = () => {
  const { discountService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [discountData, setDiscountData] = useAtom(discountCollectionAtom);

  const deleteDiscount = async (id: number) => {
    setIsLoading(true);
    try {
      const response = await discountService.discountResource.deleteDiscount(
        id
      );
      setIsLoading(false);
      setDiscountData({ ...discountData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { deleteDiscount, isLoading, isError };
};

// Discount Group

// Discounts Rule Schema

export const useGetDiscountRulesSchema = () => {
  const [discountRulesSchema, setDiscountRulesSchema] = useAtom(
    discountRulesSchemaAtom
  );
  const { discountService } = useService();
  const getDiscountRulesSchema = async (params?: string): Promise<void> => {
    setDiscountRulesSchema({
      ...discountRulesSchema,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await discountService.discountResource.getDiscountRuleSchema(params);
      setDiscountRulesSchema({
        ...discountRulesSchema,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setDiscountRulesSchema({
        ...discountRulesSchema,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getDiscountRulesSchema } as const;
};

// Discount History
export const useGetDiscountUsageHistories = () => {
  const [discountUsageHistory, setDiscountUsageHistory] = useAtom(
    discountUsageHistoryCollectionAtom
  );
  const { discountService } = useService();
  const getDiscountUsageHistories = async (params?: string): Promise<void> => {
    setDiscountUsageHistory({
      ...discountUsageHistory,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await discountService.discountResource.getDiscountUsageHistories(
          params
        );
      setDiscountUsageHistory({
        ...discountUsageHistory,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setDiscountUsageHistory({
        ...discountUsageHistory,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getDiscountUsageHistories } as const;
};
