import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";

import { roleAtom } from "lib/core-react/store/store";

import {
  RolePostRequestModel,
  RoleUpdateRequestModel,
} from "models/roleCollectionModel";
import { IDestinationWarehouseRoleForm } from "types/roleCollection";
import {notification} from "antd";

export const useRole = () => {
  const { aclService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [roleData, setRoleData] = useAtom(roleAtom);

  const createRole = async (payload: RolePostRequestModel) => {
    setIsLoading(true);
    try {
      const response = await aclService.aclResource.createRole(payload);
      setIsLoading(false);
      setRoleData({ ...roleData, refetch: true });
      notification['success']({
        message: "Update successfully",
      })
      return response.data;
    } catch (error: any) {
      notification['error']({
        message: getError(error),
      })
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createRole, isLoading, isError };
};

export const useUpdateRole = () => {
  const { aclService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [roleData, setRoleData] = useAtom(roleAtom);

  const updateRole = async (id: number, payload: RoleUpdateRequestModel) => {
    setIsLoading(true);
    try {
      const response = await aclService.aclResource.updateRole(id, payload);
      setIsLoading(false);
      setRoleData({ ...roleData, refetch: true });

      notification['success']({
        message: "Update successfully",
      })
      return response.data;
    } catch (error: any) {
      notification['error']({
        message: getError(error),
      })
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateRole, isLoading, isError };
};

export const useGetRole = () => {
  const [role, setRole] = useAtom(roleAtom);
  const { aclService } = useService();
  const getRole = async (params?: string): Promise<void> => {
    setRole({ ...role, isLoading: true, error: null });
    try {
      const response = await aclService.aclResource.getRoles(params);
      setRole({
        ...role,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setRole({
        ...role,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getRole } as const;
};

export const useGetPermissionsByRole = () => {
  const { aclService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const getPermissionsByRole = async (role_id: number) => {
    setIsLoading(true);
    try {
      const response = await aclService.aclResource.getPermissionsByRole(
        role_id
      );
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { getPermissionsByRole, isLoading, isError };
};

export const useDestinationWarehouseRoleCreate = () => {
  const { aclService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [roleData, setRoleData] = useAtom(roleAtom);

  const createDestinationWarehouseRole = async (
    payload: IDestinationWarehouseRoleForm
  ) => {
    setIsLoading(true);
    try {
      const response =
        await aclService.aclResource.createDestinationWarehouseRole(payload);
      setIsLoading(false);
      setRoleData({ ...roleData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createDestinationWarehouseRole, isLoading, isError };
};
