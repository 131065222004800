import {
  RegionCode,
  RegionDataStatus,
  RegionEnum,
  RegionStoreType,
} from "enums/regionCollectionEnums";
import { CurrencyDataModel } from "./currency";
import { CountryModel } from "./country";
import { LanguageModel } from "./language";
import { IRegion, IRegionData } from "types/region";
import { IPagination } from "types/pagination";
import { IFilterType } from "types/filters";

export class RegionDataModel {
  public readonly object: RegionEnum.Region;
  public readonly id: number;
  public readonly store_type: RegionStoreType;
  public readonly store: string;
  public readonly code: RegionCode;
  public readonly name: string;
  public readonly status: RegionDataStatus;
  public readonly created_at: string;
  public readonly currency: CurrencyDataModel | undefined;
  public readonly countries: CountryModel | undefined;
  public readonly languages: LanguageModel | undefined;

  constructor(dataItem: IRegionData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.store_type = dataItem.store_type;
    this.store = dataItem.store;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
    // @ts-ignore FIXME
    this.currency = dataItem?.currency;
    this.countries = dataItem.countries
      ? new CountryModel(dataItem.countries)
      : undefined;
    this.languages = dataItem.languages
      ? new LanguageModel(dataItem.languages)
      : undefined;
  }

  getId() {
    return this.id;
  }
  getName() {
    return this.name;
  }
}

export class RegionModel {
  public readonly object: RegionEnum.RegionCollection;
  public readonly data: RegionDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;
  constructor(data: IRegion) {
    this.object = data.object;
    this.data = data.data.map((item) => new RegionDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  getSelectComponentSelectOptions() {
    return this.data.map((item) => {
      return { value: item.getId(), label: item.getName() };
    });
  }
  getRegionIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
  getRegionCodesAsObject() {
    const obj: { [key: string]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.code));
    return obj;
  }
}

// //Post Request
// export class RegionPostRequestModel {
//   public readonly name: string;
//   public readonly code: RegionCode;
//   public readonly status: RegionDataStatus;
//   public readonly store_type: string;
//   public readonly store: string;
//   public readonly currency_id: number;
//   public readonly country_ids: number[];
//   public readonly language_ids: number[];

//   constructor(reqData: IRegionPostRequest) {
//     this.name = reqData.name;
//     this.code = reqData.code;
//     this.store_type = reqData.store_type;
//     this.store = reqData.store;
//     this.status = reqData.status;
//     this.currency_id = reqData.currency_id;
//     this.language_ids = reqData.language_ids;
//     this.country_ids = reqData.country_ids;
//   }
// }

// //update Request
// export class RegionUpdateRequestModel {
//   public readonly name: string;
//   public readonly store_type: string;
//   public readonly store: string;
//   public readonly status: RegionDataStatus;
//   public readonly currency_id: number;
//   public readonly language_ids: number[];
//   public readonly country_ids: number[];

//   constructor(reqData: IRegionUpdateRequest) {
//     this.name = reqData.name;
//     this.store_type = reqData.store_type;
//     this.store = reqData.store;
//     this.currency_id = reqData.currency_id;
//     this.language_ids = reqData.language_ids;
//     this.country_ids = reqData.country_ids;
//     this.status = reqData.status;
//   }
// }
