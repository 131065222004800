import { Button, Result } from "antd";
import { ResultStatusType } from "antd/es/result";
import { useNavigate } from "react-router-dom";

interface IProps {
  message?: string | null;
  code?: string;
  unAuthorized?: boolean;
}
const CommonError = ({
  message = "Sorry, the page you visited does not exist.",
  code = "404",
  unAuthorized,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <Result
        status={code as ResultStatusType | undefined}
        title={unAuthorized ? "403": code}
        subTitle={ unAuthorized ? "Sorry, you are not authorized to access this page" : message}
        extra={
          <Button onClick={() => navigate(-1)} type="primary">
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default CommonError;
