import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { shippingCategoryAtom } from "lib/core-react/store/store";
import { ShippingCategoryRequestModel } from "models/shippingCategory";
import { useState } from "react";
import { getError } from "../utils/errors";

export const useCreateShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippingCategoryData, setShippingCategoryData] =
    useAtom(shippingCategoryAtom);

  const createShippingCategory = async (
    payload: ShippingCategoryRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingCoreService.shippingCategoryResource.create(payload);
      setIsLoading(false);
      setShippingCategoryData({ ...shippingCategoryData, refetch: true });
      return response;
    } catch (error: any) {
      setIsLoading(false);
      throw error;
    }
  };

  return { createShippingCategory, isLoading };
};

export const useUpdateShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippingCategoryData, setShippingCategoryData] =
    useAtom(shippingCategoryAtom);

  const updateShippingCategory = async (
    id: string,
    payload: ShippingCategoryRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingCoreService.shippingCategoryResource.update(id, payload);
      setIsLoading(false);
      setShippingCategoryData({ ...shippingCategoryData, refetch: true });
      return response;
    } catch (error: any) {
      setIsLoading(false);
      throw error;
    }
  };

  return { updateShippingCategory, isLoading };
};

export const useGetShippingCategory = () => {
  const [shippingCategoryData, setShippingCategoryData] =
    useAtom(shippingCategoryAtom);
  const { shippingCoreService } = useService();

  const getShippingCategory = async (params?: string) => {
    setShippingCategoryData({
      ...shippingCategoryData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingCoreService.shippingCategoryResource.get(params);
      setShippingCategoryData({
        ...shippingCategoryData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShippingCategoryData({
        ...shippingCategoryData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShippingCategory } as const;
};
