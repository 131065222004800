import { EyeFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Space,
  Table,
  Tooltip,
  Modal,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  InvoiceModel,
  TransactionDetailsModel,
} from "../../../../models/invoiceCollectionModel";
import { useService } from "../../../../lib/core-react";
const { Text } = Typography;

interface InvoiceTransactionProps {
  invoice: InvoiceModel;
  transactionFor: "customer" | "agent";
}

const InvoiceTransaction: React.FC<InvoiceTransactionProps> = ({
  invoice,
  transactionFor = "customer",
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { invoiceService } = useService();
  const [transactionDetails, setTransactionDetails] = useState<{
    isLoading: boolean;
    error: any;
    data: TransactionDetailsModel | undefined;
  }>({
    isLoading: true,
    error: null,
    data: undefined,
  });

  const showModal = async (record) => {
    const invoiceId = invoice.getId();
    const transactionId = record.id;
    setIsModalVisible(true);
    setTransactionDetails((pre) => ({
      ...pre,
      isLoading: true,
      error: null,
      data: undefined,
    }));

    try {
      const response =
        await invoiceService.invoiceResource.getTransactionDetails(
          invoiceId,
          transactionId,
          transactionFor,
        );
      if (response) {
        setTransactionDetails((pre) => ({
          ...pre,
          data: new TransactionDetailsModel(response.data),
          isLoading: false,
          error: null,
        }));
      }
    } catch (e) {
      setTransactionDetails((pre) => ({
        ...pre,
        isLoading: false,
        error: null,
        data: undefined,
      }));
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const data =
    invoice.getTransactions()?.map((item, index) => {
      return {
        key: index,
        transactionNumber: item.getTransactionNumber(),
        amount: item.getAmount(),
        type: item.getType(),
        availableBalance: item.getAssociationsWallet()?.available_balance,
        reservedBalance: item.getAssociationsWallet()?.reserved_balance,
        pendingBalance: item.getAssociationsWallet()?.pending_balance,
        createdAt: item.getCreatedAt(),
        id: item.getId(),
      };
    }) || [];

  const columns = [
    {
      title: "Transaction Number",
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Available Balance",
      dataIndex: "availableBalance",
      key: "availableBalance",
    },
    {
      title: "Reserved Balance",
      dataIndex: "reservedBalance",
      key: "reservedBalance",
    },
    {
      title: "Pending Balance",
      dataIndex: "pendingBalance",
      key: "pendingBalance",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => <Tag color="purple">{text}</Tag>,
    },
    {
      title: "Details",
      key: "details",
      align: "center" as const,
      render: (_: any, record) => (
        <Space size={4}>
          <Tooltip title="View Details">
            <Button
              type="primary"
              size="small"
              onClick={() => showModal(record)}
              shape="round"
              icon={<EyeFilled />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const transactionModel = transactionDetails.data;
  const titleStyle = { fontWeight: "bold", fontSize: "1em" }; // Adjust font size as needed

  return (
    <>
      <Card style={{ margin: "10px" }} title="Invoice Transaction">
        <Table
          columns={columns}
          dataSource={data}
          rowKey="transactionNumber"
          pagination={false}
          scroll={{ x: 1237 }}
        />
      </Card>
      <Modal
        title={<h3 style={titleStyle}>Transaction Details </h3>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {transactionDetails.isLoading && (
          <Skeleton active paragraph={{ rows: 10 }} />
        )}
        {transactionModel && (
          <div>
            <p>
              <b>Transaction Number:</b>{" "}
              <Text copyable={true}>
                {" "}
                {transactionModel.getTransactionNumber()}{" "}
              </Text>{" "}
            </p>
            <p>
              <b>Amount:</b> {transactionModel.getAmount()}
            </p>
            <p>
              <b>Type:</b> {transactionModel.getType()}
            </p>
            <p>
              <b>Available Balance:</b>{" "}
              {transactionModel.getAssociationsWallet()?.available_balance}
            </p>
            <p>
              <b>Reserved Balance:</b>{" "}
              {transactionModel.getAssociationsWallet()?.reserved_balance}
            </p>
            <p>
              <b>Pending Balance:</b>{" "}
              {transactionModel.getAssociationsWallet()?.pending_balance}
            </p>
            <p>
              <b>Created At:</b>
              <Tag color="purple">{transactionModel.getCreatedAt()}</Tag>
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default InvoiceTransaction;
