import { Avatar, Button, Image, Progress, type ProgressProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useItemFinishListener,
  useItemProgressListener,
} from "@rpldy/chunked-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

const UploadButton = asUploadButton((props: any) => {
  return (
    <div {...props} style={{ cursor: "pointer" }}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </div>
  );
});

export const DivUploadButton = ({
  form,
  fieldName,
}: {
  form: any;
  fieldName: string;
}) => {
  useItemFinishListener((item) => {
    const { results } = item.uploadResponse;
    const data = results[results.length - 1].data.data;
    form.setFieldValue(fieldName, data.url);
  });

  const progressData = useItemProgressListener();

  return (
    <PasteUploadDropZone>
      <div style={{ cursor: "pointer" }}>
        {progressData && progressData.completed === 100 ? (
          <Avatar
            style={{
              marginBottom: 10,
              objectFit: "contain",
            }}
            size={70}
            shape="circle"
            src={<Image src={form.getFieldValue(fieldName)} />}
          />
        ) : form.getFieldValue(fieldName) ? (
          <Avatar
            style={{
              marginBottom: 10,
              objectFit: "contain",
            }}
            size={70}
            shape="circle"
            src={<Image src={form.getFieldValue(fieldName)} />}
          />
        ) : progressData ? (
          <Progress
            style={{ marginBottom: 10 }}
            size={70}
            type="circle"
            percent={Number(progressData.completed.toFixed(0))}
            strokeColor={twoColors}
          />
        ) : (
          ""
        )}
        <UploadButton />
      </div>
    </PasteUploadDropZone>
  );
};
