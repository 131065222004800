import { CustomerUserEnum } from "enums/customerUserCollectionEnums";
import {
  ICustomerUser,
  ICustomerUserCollection,
  ICustomerUserPostRequest,
  ICustomerUserUpdateRequest,
} from "types/customerUserCollection";
import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";

export class CustomerUserModel {
  public readonly object: CustomerUserEnum.CustomerUser;
  public readonly id: number;
  public readonly name: string;
  public readonly email: string;
  public readonly phone: string | null;
  public readonly type: string;
  public readonly shipping_mark: string;
  public readonly created_at: string;

  constructor(dataItem: ICustomerUser) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.email = dataItem.email;
    this.phone = dataItem.phone;
    this.type = dataItem.type;
    this.shipping_mark = dataItem.shipping_mark;
    this.created_at = dataItem.created_at;
  }
  getId = () => this.id;
  getName = () => this.name;
  getEmail = () => this.email;
  getPhone = () => this.phone;
  getType = () => this.type;
  getCreatedAt = () => this.created_at;
  getShippingMark = () => this.shipping_mark;
}

export class CustomerUserCollectionModel {
  public readonly object: CustomerUserEnum.CustomerUserCollection;
  public readonly data: CustomerUserModel[];
  public readonly pagination: PaginationModel;
  public readonly filters: IFilterType;

  constructor(data: ICustomerUserCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CustomerUserModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }
  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
}

//Post Request
export class CustomerUserPostRequestModel {
  public readonly name: string;
  public readonly email: string;
  public readonly phone: string;
  public readonly password: string;
  public readonly password_confirmation: string;
  public readonly shipping_mark: string;

  constructor(reqData: ICustomerUserPostRequest) {
    this.name = reqData.name;
    this.email = reqData.email;
    this.phone = reqData.phone;
    this.password = reqData.password;
    this.password_confirmation = reqData.password;
    this.shipping_mark = reqData.shipping_mark;
  }
}

//update Request
export class CustomerUserUpdateRequestModel {
  public readonly name: string;
  public readonly shipping_mark: string;

  constructor(reqData: ICustomerUserUpdateRequest) {
    this.name = reqData.name;
    this.shipping_mark = reqData.shipping_mark;
  }
}
