import { Button, Form, } from "antd";
import { FormInstance } from "antd/lib";
import { UserSelect } from "components/CustomFormElement/UserSelect";
// import { showError } from "helpers/showError";
import { useDebounce } from "hooks/useDebounce";
import { useAtom } from "jotai";
import { useGetCustomers } from "lib/core-react/hooks/private/useBulkUser";
// import { useAddExchangeGroupCustomer, useGetExchangeGroupCustomers } from "lib/core-react/hooks/private/useExchangeGroup";
import { bulkUserCustomer } from "lib/core-react/store/store";
import { BulkUserCollectionModel } from "models/bulkUserCollectionModel";
// import { ExchangeGroupModel } from "models/exchangeGroupCollectionModel";
import React, { createRef, useEffect, useState } from "react";
// import { IExchangeGroupUserRequest } from "types/exchangeGroupCollection";
interface IProps {
    addCustomerHandler: (value: {
        user: number;
    },  form: FormInstance<any>) => Promise<void>
    loading: boolean
}
const AddUserToExchangeGroup = ({
    addCustomerHandler,
    loading
}: IProps) => {
    const [form] = Form.useForm();
    const { getCustomers } = useGetCustomers();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [searchTerm, setSearchTerm] = useState("");
    const [bulkUserCustomerData] = useAtom(bulkUserCustomer);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const Customers =
        bulkUserCustomerData &&
        bulkUserCustomerData.data &&
        new BulkUserCollectionModel(bulkUserCustomerData.data);
    const formatCustomersData = Customers?.data.map((x) => {
        return {
            label: `${x.name} (${x.email})`,
            value: x.id,
        };
    });
    useEffect(() => {
        if (debouncedSearchTerm) {
            const queryParam = `per_page=100&keyword=${debouncedSearchTerm}`;
            getCustomers(queryParam);
        } else {
            getCustomers("per_page=200");
        }
    }, [debouncedSearchTerm]);

    const handleSearch = (q: any) => {
        setSearchTerm(q);
    };

    if (bulkUserCustomerData.error) {
        return <div>Error</div>;
    }
    return (
        <div>
            <Form
                form={form}
                onFinish={(value: { user: number })=>addCustomerHandler(value, form)}
                layout="vertical"
                name="User-Form"
                ref={formRef}
            >
                <Form.Item
                    label="Select User"
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: "Please select the user!",
                        },
                    ]}
                    required
                >
                    <UserSelect
                        initialData={
                            (!bulkUserCustomerData.isLoading &&
                                formatCustomersData) ||
                            []
                        }
                        loading={bulkUserCustomerData.isLoading}
                        onClick={async () => {
                            if (
                                (!formatCustomersData && !searchTerm) ||
                                (!formatCustomersData?.length && !searchTerm)
                            ) {
                                await getCustomers("per_page=200");
                            }
                        }}
                        resource={""}
                        onSearch={async (value) => {
                            handleSearch(value);
                        }}
                        allowClear={true}
                        showSearch={true}
                        placeholder={"Search users by email or name"}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                          loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                           Add customer
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddUserToExchangeGroup;
