import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Dropdown,  Modal, Table, Tag } from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { courierGatewayCollectionAtom } from "lib/core-react/store/store";
import { useGetCourierGateway } from "lib/core-react/hooks/private";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import {
  CourierGatewayCollectionModel,
  CourierGatewayModel,
} from "models/courierGatewayCollection";
import { PaginationModel } from "models/pagination";

import {
  CreateCourierGateway,
  DeleteCourierGateway,
  UpdateCourierGateway,
} from "./components";
import { CourierGatewayStatus } from "enums/courierGatewayCollectionEnums";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "../../../utils/helpers";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ROLE_PERMISSION_TEST_ENUM } from "consts/parmissionEnum";

const CourierGateway = () => {
  const { getCourierGateway } = useGetCourierGateway();
  const { isMobile } = useWindowWidth()
  const [{ data: courierGatewayCollectionData, isLoading, refetch }] = useAtom(
    courierGatewayCollectionAtom,
  );
  const { isFirstCall, isFetched, handleFilterChange, filters } =
    useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getCourierGateway);
    }
  }, [isFirstCall, isFetched, refetch]);

  const CourierGatewayCollectionData =
    courierGatewayCollectionData &&
    new CourierGatewayCollectionModel(courierGatewayCollectionData);

  const [selectedCourierGateway, setSelectedCourierGateway] = useState<
    CourierGatewayModel | undefined
  >(undefined);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const handleMenuClick = (key: string, record: CourierGatewayModel) => {
    setSelectedCourierGateway(record);

    if (key === "update") {
      setIsShowUpdateModal(true);
    } else if (key === "delete") {
      setIsShowDeleteModal(true);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Country",
      dataIndex: ["country", "name"],
      key: "country",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, record: CourierGatewayModel) => {
        let statusColor =
          record.getStatus() === CourierGatewayStatus.active ? "green" : "red";

        return <Tag color={statusColor}>{record.getStatus()}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      key: "created_at",
      render: (_: string, record: CourierGatewayModel) => (
        <Tag color={"purple"}>{record.getCreatedAt()}</Tag>),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      width: isMobile ? 100 : 150,
      render: (_: string, record: CourierGatewayModel) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission:ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            key: "1",
            label: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleMenuClick("update", record)}
              >
                <EditOutlined /> <span> Update</span>
              </div>
            ),
          },
          {
            permission:ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            key: "2",
            label: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleMenuClick("delete", record)}
              >
                <EditOutlined /> <span> Delete</span>
              </div>
            ),
          },
        ];

        if (isMobile) {
          items.push({
            permission:ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            key: "3",
            disabled: true,
            label: <Tag color={"purple"}>{formatDateTime(record.created_at)}</Tag>
          })

        }

        return (
          <Dropdown menu={{
            items: items.filter((x) =>
              checkActionPermission(x.permission, x, null),
            ),
          }}>
            <Button icon={<HddFilled />}>
              {isMobile ? <DownOutlined /> : <>  Actions  <DownOutlined /></>}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCourierGateway,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CourierGatewayCollectionData,
    handlePaginationChange,
  );

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Courier Gateway"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => setIsShowCreateModal(true)}
            >
              Add Courier Gateway
            </Button>,
          ]}
        >
          <div>
            <Table
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={CourierGatewayCollectionData?.getData()}
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1000 }}
            />
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`Create Courier Gateway`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateCourierGateway setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>

      {selectedCourierGateway && (
        <Modal
          title={`Update Courier Gateway`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => setIsShowUpdateModal(false)}
        >
          <UpdateCourierGateway
            setIsShowUpdateModal={setIsShowUpdateModal}
            selectedCourierGateway={selectedCourierGateway}
          />
        </Modal>
      )}

      {selectedCourierGateway && (
        <Modal
          title="Delete Courier Gateway"
          open={isShowDeleteModal}
          onCancel={() => setIsShowDeleteModal(false)}
          footer={null}
        >
          <DeleteCourierGateway
            setIsShowDeleteModal={setIsShowDeleteModal}
            selectedCourierGateway={selectedCourierGateway}
          />
        </Modal>
      )}
    </>
  );
};

export default CourierGateway;
