import ChunkedUploady from "@rpldy/chunked-uploady";
import { DivUploadButton } from "./DivUploadButton";
import {
    FILE_UPLOAD_URL,
    FILE_UPLOAD_URL_TOKEKN,
    MAX_FILE_SIZE,
} from "consts/storage";
import setAndGetGuestData from "helpers/setAndGetGuestData";
import { useCallback } from "react";
import { message } from "antd";

interface IProps {
    form: any;
    fieldName: string;
    accept?: string;
    uploadUrl?: string;
}
export const MultiChunkUpload = ({
    form,
    fieldName,
    accept = "image/jpeg,image/png,image/jpg,image/gif,image/webp",
}: IProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const filterBySize = useCallback((file: File | string) => {
        if (file instanceof File) {
           if (file.size > 1024 * 1024 * MAX_FILE_SIZE) {
                //filter out files larger than maxFileSize
                messageApi.open({
                    type: "error",
                    content: ` exceeds maximum size (${MAX_FILE_SIZE}mb) allowed!`,
                });
            }
            return file.size < 1024 * 1024 * MAX_FILE_SIZE;
        }
    }, []);
    return (
        <>
            {contextHolder}
            <ChunkedUploady
                accept={accept}
                fileFilter={filterBySize}
                multiple={true}
                chunkSize={204800}
                method="POST"
                destination={{
                    url: FILE_UPLOAD_URL,
                    headers: {
                        Accept: "application/json",
                        "X-SESSION-TOKEN": FILE_UPLOAD_URL_TOKEKN,
                        "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
                        "X-GUEST-SESSION":
                            setAndGetGuestData()?.guestSession || "",
                    },
                }}
            >
                <DivUploadButton form={form} fieldName={fieldName} />
            </ChunkedUploady>
        </>
    );
};
