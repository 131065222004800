import { ShippingCartResource } from "lib/core/resources/private";
import CartApiService from "lib/core/apiServices/cartApiService";

class ShippingCartService extends CartApiService {
  public shippingCartResource: ShippingCartResource;

  constructor() {
    super();

    this.shippingCartResource = new ShippingCartResource(this.config);
  }
}

export default ShippingCartService;
