import { Button, Flex, Form, Input, notification, Typography } from "antd";
import { useShipmentProductUpdateTrackingId } from "lib/core-react/hooks/private/useShipping";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { getError } from "lib/core-react/hooks/utils/errors";
import { ShipmentProductPackageTrackingModel } from "models/shipmentProductPackageTrackingCollectionModel";
import NotAvailableTracking from "./NotAvailableTracking";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";

interface ITrackingForm {
    tracking?: ShipmentProductPackageTrackingModel;
    trackingId?: number;
    packageId?: number;
    productId?: string | number;
    onCloseModal: () => void;
    action: "view" | "add";
}

export const UpdateTrackingForm = ({
    productId,
    tracking,
    trackingId,
    onCloseModal,
    action,
}: ITrackingForm) => {
    const { isMobile } = useWindowWidth();
    const [form] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState(false);

    const {
        updateTrackingId,
        isLoading: isLoadingUpdateShipmentProductTrackingId,
    } = useShipmentProductUpdateTrackingId();

    const handleUpdateTracking = async () => {
        try {
            const trigger = await form.validateFields();
            if (trigger && productId && trackingId) {
                await updateTrackingId(productId as number, trackingId, {
                    ...trigger,
                });
                notification["success"]({
                    message: "Update tracking successfully.",
                });
                onCloseModal();
            } else {
                notification["error"]({
                    message: getError(
                        "Shipment product number or tracking id not found!",
                    ),
                });
            }
        } catch (err) {
            notification["error"]({
                message: getError(err),
            });
        }
    };

    function getFormattedType(trackingType) {
        if (typeof trackingType === "string") {
            return trackingType
                .replace(/-/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase());
        }
        return trackingType;
    }

    const getIsAbroadCourierTracking = (trackingType): boolean | undefined =>
        trackingType ? trackingType === "abroad-courier-tracking" : undefined;

    return (
        <>
            <Form form={form} name="form" onFinish={handleUpdateTracking}>
                <Flex
                    vertical={isMobile ? true : false}
                    align={isMobile ? "start" : "center"}
                    gap={4}
                >
                    {action === "add" &&
                    getIsAbroadCourierTracking(tracking?.getType()) &&
                    isUpdate ? (
                        <>
                            <Typography.Text
                                strong
                                style={{
                                    textTransform: "capitalize",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {tracking
                                    ? getFormattedType(tracking.getType()) + ":"
                                    : "Tracking"}
                            </Typography.Text>
                            <Flex
                                gap={4}
                                align="center"
                                style={{ width: "100%" }}
                            >
                                <Form.Item
                                    style={{
                                        width: "100%",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Tracking is required",
                                        },
                                    ]}
                                    initialValue={
                                        tracking ? tracking.getTracking() : ""
                                    }
                                    required
                                    name={`tracking`}
                                >
                                    <Input placeholder="Type tracking" />
                                </Form.Item>
                                <Button
                                    disabled={
                                        isLoadingUpdateShipmentProductTrackingId
                                    }
                                    loading={
                                        isLoadingUpdateShipmentProductTrackingId
                                    }
                                    type="default"
                                    htmlType="submit"
                                >
                                    Update
                                </Button>
                            </Flex>
                        </>
                    ) : tracking ? (
                        <Flex gap={4} align="center" justify="start">
                            
                            <Flex gap={4} align="center">
                                <Typography.Text
                                    strong
                                    style={{
                                        textTransform: "capitalize",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {getFormattedType(tracking?.getType())} :
                                </Typography.Text>

                                <Typography.Text
                                    style={{
                                        textTransform: "capitalize",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {tracking?.getTracking
                                        ? tracking?.getTracking()
                                        : "Not Available"}
                                </Typography.Text>
                            </Flex>

                            {action === "add" ? (
                                <Button
                                    type="link"
                                    onClick={() => setIsUpdate(true)}
                                >
                                    <EditOutlined /> Update
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Flex>
                    ) : (
                        <NotAvailableTracking text={"Tracking"} />
                    )}
                </Flex>
            </Form>
        </>
    );
};
