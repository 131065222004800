import {
    FacetFieldModel,
    FacetValuesFieldModel,
} from "models/productDetailCollectionModel";

import {
    StyledProductSidebarItem,
    StyledProductSidebarItemTitle,
    StyledProductSidebarTree,
} from "../index.styled";
import { DownOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { useLocation } from "react-router-dom";

interface IProps {
    propertiesField: FacetValuesFieldModel[];
}

export type Key = string | number;

const PropertiesFacet = ({ propertiesField }: IProps) => {
    const location = useLocation();
    const region = new URLSearchParams(location.search).get("region") || "BD";

    const { getProductDetailsCollection } = useGetProductDetailsCollection();
    const prepareParent = () => {
        return propertiesField.map((p) => {
            return {
                title: `${p.getName()} (${p.getCount()})`,
                key: `*-${p.getName()} (${p.getCount()})`,
                children: prepareChildren(p.getName(), p.getValues()),
                disableCheckBox: true,
            };
        });
    };

    const prepareChildren = (name: string, values: FacetFieldModel[]) => {
        return values.map((v) => {
            return {
                title: `${v.getName()} (${v.getCount()})`,
                key: `${name};${v.getName()}`,
            };
        });
    };

    const preparedData = useMemo(() => prepareParent(), [propertiesField]);

    const onCheck = async (checked) => {
        const values: string[] = checked.map((p) => {
            if (p.includes(";")) {
                const value = p.split(";")[1];
                return value;
            } else {
                return null;
            }
        });

        const properties = values.filter((value) => value);
        if (properties.length > 0) {
            await getProductDetailsCollection(
                {
                    region: region,
                    locale: "en",
                },
                { properties },
            );
        }
    };

    return (
        <StyledProductSidebarItem>
            <StyledProductSidebarItemTitle>
                Properties
            </StyledProductSidebarItemTitle>
            <StyledProductSidebarTree
                checkable
                switcherIcon={<DownOutlined />}
                treeData={preparedData}
                onCheck={(values) => {
                    onCheck(values);
                }}
            />
        </StyledProductSidebarItem>
    );
};
export default PropertiesFacet;
