import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Tooltip,
    Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import { useGetRegion } from "lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { regionRegion } from "lib/core-react/store/store";
import { RegionModel } from "models/region";
import { useCreateService } from "lib/core-react/hooks/private/useAddonService";
import {
    renderOptionsFromEnum,
    renderOptionsFromEnumAddonDependsOnEnum,
    renderOptionsFromEnumAddonForEnum,
} from "components/Form/forms";
import { CreateServicesPostRequestModel } from "models/addonServiceCollectionModel";
import {
    AddonForEnum,
    DependsOnEnum,
} from "enums/addonServicesCollectionEnums";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SingleChunkUpload } from "components/SingleChunkUpload";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateService = ({ setIsShowCreateModal }: IProps) => {
    const { createService } = useCreateService();
    const { getRegion } = useGetRegion();
    const { Title } = Typography;

    const [{ data: regionDataAtom, isLoading: regionLoading }] =
        useAtom(regionRegion);

    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [form] = Form.useForm();

    const regionData = regionDataAtom && new RegionModel(regionDataAtom);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        getRegion("per_page=200");
    }, []);

    //hide error message after 5 sec
    useEffect(() => {
        if (isErrorVisible) {
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 5000);
        }
    }, [isErrorVisible]);

    const onFinish = async (value: CreateServicesPostRequestModel) => {
        try {
            await createService(value);
            setIsShowCreateModal(false);
            setIsErrorVisible(false);
            form.resetFields();
        } catch (error) {
            setIsErrorVisible(true);
        }
    };

    return (
        <Form
            name="serviceForm"
            form={form}
            onFinish={onFinish}
            layout="vertical"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
            >
                <Input placeholder="Enter name" />
            </Form.Item>

            <Form.Item
                label="Identifier"
                name="identifier"
                rules={[{ required: true, message: "Identifier  is required" }]}
            >
                <Input placeholder="Enter identifier" />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
            >
                <TextArea placeholder="Enter Description" />
            </Form.Item>

            <Form.Item
                label="Addon For"
                name="addon_for"
                rules={[{ required: true, message: "Addon for is required" }]}
            >
                <Select placeholder="Please select a Publishing Status">
                    {renderOptionsFromEnumAddonForEnum(AddonForEnum)}
                </Select>
            </Form.Item>

            <Form.Item name="logo" label="Images">
                <SingleChunkUpload
                    form={form}
                    accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                    fieldName="logo"
                />
            </Form.Item>

            <div
                style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    padding: "10px",
                }}
            >
                <Form.List name="prices">
                    {(fields, { add, remove }) => (
                        <div>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Title type="success" level={4}>
                                        Price {index + 1}
                                    </Title>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Region is required",
                                            },
                                        ]}
                                        name={[field.name, "region_id"]}
                                        label="Region"
                                    >
                                        <Select
                                            loading={regionLoading}
                                            placeholder="Please select a region"
                                        >
                                            {regionData?.getRegionIdsAsObject() &&
                                                renderOptionsFromEnum(
                                                    regionData?.getRegionIdsAsObject(),
                                                )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Depends On"
                                        name={[field.name, "depends_on"]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Depends On is required",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select a Publishing Status">
                                            {renderOptionsFromEnumAddonDependsOnEnum(
                                                DependsOnEnum,
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name={[field.name, "is_primary"]}
                                        label="Is primary"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Primary is required",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Price"
                                        name={[field.name, "price"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter price",
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                    <div
                                        style={{
                                            border: "1px solid #d9d9d9",
                                            borderRadius: "4px",
                                            padding: "16px",
                                        }}
                                    >
                                        <Form.List
                                            name={[field.name, "slots"]}
                                            key={index}
                                        >
                                            {(
                                                slotFields,
                                                {
                                                    add: addSlot,
                                                    remove: removeSlot,
                                                },
                                            ) => (
                                                <div>
                                                    {slotFields.map(
                                                        (slotField) => (
                                                            <div
                                                                key={
                                                                    slotField.key
                                                                }
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    marginBottom:
                                                                        "16px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Min"
                                                                        name={[
                                                                            slotField.name,
                                                                            "min_amount",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter min amount",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Max"
                                                                        name={[
                                                                            slotField.name,
                                                                            "max_amount",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter max amount",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Price"
                                                                        name={[
                                                                            slotField.name,
                                                                            "price",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter price",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                <Tooltip title="Remove Slot">
                                                                    <Button
                                                                        type="primary"
                                                                        danger
                                                                        onClick={() =>
                                                                            removeSlot(
                                                                                slotField.name,
                                                                            )
                                                                        }
                                                                        icon={
                                                                            <DeleteOutlined />
                                                                        }
                                                                    ></Button>
                                                                </Tooltip>
                                                            </div>
                                                        ),
                                                    )}
                                                    <Form.Item>
                                                        <Tooltip title="Add Slot">
                                                            <Button
                                                                onClick={() =>
                                                                    addSlot()
                                                                }
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                            ></Button>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Form.List>
                                    </div>

                                    <div>
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={() => remove(field.name)}
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Remove Price
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            <Form.Item style={{ marginTop: "10px" }}>
                                <Button type="primary" onClick={() => add()}>
                                    Add Price
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            </div>
            <Form.Item
                style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateService;
