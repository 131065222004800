import { Dispatch, SetStateAction } from "react";
import { FormInstance } from "antd";

import { TimelineTrackingModel } from "models/timelineTrackingModel";
import { ITrackerAddCommentFormData } from "types/timelineTracking";
import { SelectLabelValue } from "types/buyOrderPlace";

interface IProps {
  specificUserIds: SelectLabelValue[];
  commentContent: string;
  TimelineTrackingData: TimelineTrackingModel | undefined;
  selectedCommentIdToUpdate: number | undefined;
  updateComment: (
    id: number,
    payload: ITrackerAddCommentFormData,
    productId:number,
  ) => Promise<any>;
  form: FormInstance<any>;
  setCommentContent: Dispatch<SetStateAction<string>>;
  setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  setSelectedCommentIdToUpdate: Dispatch<SetStateAction<number | undefined>>;
  message: any;
  setIsErrorVisible: (value: SetStateAction<boolean>) => void;
  setCustomError: Dispatch<SetStateAction<string | null>>;
  visibility: any;
  role_ids: any;
  productId: number
}

export const handleUpdateComment = async ({
  specificUserIds,
  commentContent,
  TimelineTrackingData,
  selectedCommentIdToUpdate,
  updateComment,
  form,
  setCommentContent,
  setSpecificUserIds,
  setSelectedCommentIdToUpdate,
  message,
  setIsErrorVisible,
  setCustomError,
  visibility,
  role_ids,
  productId
}: IProps) => {
  // Check if comment contains only a single empty HTML tag
  const tagRegex = /^<\w+><\/\w+>$/;
  if (!commentContent || tagRegex.test(commentContent)) {
    setIsErrorVisible(true);
    setCustomError("Comment message cannot be empty");
    return;
  }

  const specific_user_ids: number[] = specificUserIds.map((value) =>
    Number(value.value),
  );
  const mentioned_user_ids: number[] = [];

  const regex = /data-id="([^"]+)"/g;
  let match = regex.exec(commentContent);
  while (match !== null) {
    mentioned_user_ids.push(Number(match[1]));
  }

  // handle role_ids in case its object with value and label
  let roleAsNumbers: number[];
  if (
    role_ids.length > 0 &&
    typeof role_ids[0] === "object" &&
    "value" in role_ids[0]
  ) {
    roleAsNumbers = role_ids.map((role: SelectLabelValue) =>
      Number(role.value),
    );
  } else if (role_ids.length > 0 && typeof role_ids[0] === "number") {
    roleAsNumbers = role_ids;
  } else {
    roleAsNumbers = [];
  }

  if (TimelineTrackingData && selectedCommentIdToUpdate) {
    try {
      const payload: ITrackerAddCommentFormData = {
        mentioned_user_ids,
        role_ids: roleAsNumbers,
        specific_user_ids,
        message: commentContent,
        tracker_id: TimelineTrackingData.getData().getId(),
        visibility,
      };

      await updateComment(selectedCommentIdToUpdate, payload, productId);
      form.resetFields();
      setCommentContent("");
      setSpecificUserIds([]);
      setSelectedCommentIdToUpdate(undefined);
      message.success("Comment updated successfully");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  }
};
