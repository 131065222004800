// current permissions
let CURRENT: string | string[] = [];

type CurrentAuthorityType = string | string[] | (() => typeof CURRENT);

/**
 * Permission to use or obtain permission
 *
 * current permissions
 * @param Authorized
 */
const RenderAuthorize = <T>(Authorized: T): ((currentAuthority: CurrentAuthorityType) => T) => (
    currentAuthority: CurrentAuthorityType,
): T => {
    if (currentAuthority) {
        if (typeof currentAuthority === 'function') {
            CURRENT = currentAuthority();
        }
        if (
            Object.prototype.toString.call(currentAuthority) === '[object String]' ||
            Array.isArray(currentAuthority)
        ) {
            CURRENT = currentAuthority as string[];
        }
    } else {
        CURRENT = [];
    }

    // console.log(CURRENT,"CURRENT")
    return Authorized;
};

export {CURRENT};
const authorized = <T>(Authorized: T) => RenderAuthorize<T>(Authorized);
export default authorized
