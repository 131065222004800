import {
  IFacetField,
  IProductSearchResult,
  IFacetRangeField,
  IPriceMinimumRangeFacet,
  IRatingAverageFacet,
  ICategoriesFacet,
  IBrandFacet,
  ISellerFacet,
  IShopFacet,
  IFacet,
  ICountryFacet,
  IPropertiesFacet,
  IFacetValuesField,
  IShippingCategory,
  IProductPriceRange,
  IWholesalePrice,
  IWholesalePriceCollection,
  IProductPrice,
  IProductGallery,
  IProductGalleryCollection,
  IMeasurementUnitBody,
  IProductMeasurement,
  ICountry,
  IResourceMeta,
  IProductDetail,
  ISort,
} from "../types/productDetailCollection";
import { PaginationModel } from "./paginationModel";

// Facet Model
export class FacetFieldModel {
  name: string;
  count: number;

  constructor(payload: IFacetField) {
    this.name = payload.name;
    this.count = payload.count;
  }

  getName() {
    return this.name;
  }

  getCount() {
    return this.count;
  }
}

export class FacetRangeFieldModel {
  name: string;
  from: number | null;
  to: number | null;
  count: number;

  constructor(payload: IFacetRangeField) {
    this.name = payload.name;
    this.from = payload.from;
    this.to = payload.to;
    this.count = payload.count;
  }

  getName() {
    return this.name;
  }

  getFrom() {
    return this.from ? this.from : "";
  }

  getTo() {
    return this.to ? this.to : "";
  }

  getCount() {
    return this.count;
  }
}

export class PriceMinimumRangeFacetModel {
  object: string;
  data: FacetRangeFieldModel[];

  constructor(payload: IPriceMinimumRangeFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetRangeFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class RatingAverageFacetModel {
  object: string;
  data: FacetRangeFieldModel[];

  constructor(payload: IRatingAverageFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetRangeFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class CategoriesFacetModel {
  object: string;
  data: FacetFieldModel[];

  constructor(payload: ICategoriesFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class BrandFacetModel {
  object: string;
  data: FacetFieldModel[];

  constructor(payload: IBrandFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class SellerFacetModel {
  object: string;
  data: FacetFieldModel[];

  constructor(payload: ISellerFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class ShopFacetModel {
  object: string;
  data: FacetFieldModel[];

  constructor(payload: IShopFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class CountryFacetModel {
  object: string;
  data: FacetFieldModel[];

  constructor(payload: ICountryFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class FacetValuesFieldModel {
  name: string;
  count: number;
  values: FacetFieldModel[];

  constructor(payload: IFacetValuesField) {
    this.name = payload.name;
    this.count = payload.count;
    this.values = payload.values.map((value) => new FacetFieldModel(value));
  }

  getName() {
    return this.name;
  }

  getCount() {
    return this.count;
  }

  getValues() {
    return this.values;
  }
}

export class PropertiesFacetModel {
  object: string;
  data: FacetValuesFieldModel[];

  constructor(payload: IPropertiesFacet) {
    this.object = payload.object;
    this.data = payload.data.map((item) => new FacetValuesFieldModel(item));
  }

  getData() {
    return this.data;
  }
}

export class FacetModel {
  price_minimum_ranges: PriceMinimumRangeFacetModel;
  rating_average_ranges: RatingAverageFacetModel;
  categories: CategoriesFacetModel;
  brands: BrandFacetModel;
  sellers: SellerFacetModel;
  shops: ShopFacetModel;
  countries: CountryFacetModel;
  properties: PropertiesFacetModel;

  constructor(payload: IFacet) {
    this.price_minimum_ranges = new PriceMinimumRangeFacetModel(
      payload.price_minimum_ranges,
    );
    this.rating_average_ranges = new RatingAverageFacetModel(
      payload.rating_average_ranges,
    );
    this.categories = new CategoriesFacetModel(payload.categories);
    this.brands = new BrandFacetModel(payload.brands);
    this.sellers = new SellerFacetModel(payload.sellers);
    this.shops = new ShopFacetModel(payload.shops);
    this.countries = new CountryFacetModel(payload.countries);
    this.properties = new PropertiesFacetModel(payload.properties);
  }

  getPriceMinimumRanges() {
    return this.price_minimum_ranges.data;
  }

  getRatingAverageRanges() {
    return this.rating_average_ranges.data;
  }

  getCategories() {
    return this.categories.data;
  }

  getBrands() {
    return this.brands.data;
  }

  getSellers() {
    return this.sellers.data;
  }

  getShops() {
    return this.shops.data;
  }

  getCountries() {
    return this.countries.data;
  }

  getProperties() {
    return this.properties.data;
  }
}

// Product Detail
export class ShippingCategoryModel {
  public readonly object: string;
  public readonly id: string;
  public readonly parent_id: string;
  public readonly name: string;
  public readonly slug: string;

  constructor(dataItem: IShippingCategory) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.parent_id = dataItem.parent_id;
    this.name = dataItem.name;
    this.slug = dataItem.slug;
  }
}

export class ProductPriceRangeModel {
  public readonly object: string;
  public readonly max: number;
  public readonly min: number;

  constructor(dataItem: IProductPriceRange) {
    this.object = dataItem.object;
    this.min = dataItem.min;
    this.max = dataItem.max;
  }
  getMin = () => this.min;
  getMax = () => this.max;
}

export class WholesalePriceModel {
  public readonly object: string;
  public readonly qty_from: number;
  public readonly original: number;
  public readonly discount?: number;

  constructor(dataItem: IWholesalePrice) {
    this.object = dataItem.object;
    this.discount = dataItem.discount;
    this.original = dataItem.original;
    this.qty_from = dataItem.qty_from;
  }
  getDiscount = () => this.discount;
  getOriginal = () => this.original;
  getQtyFrom = () => this.qty_from;
}

export class WholesalePriceCollectionModel {
  public readonly object: string;
  public readonly data: WholesalePriceModel[];

  constructor(dataItem: IWholesalePriceCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((item) => new WholesalePriceModel(item));
  }
  getData = () => this.data;
}

export class ProductPriceModel {
  public readonly object: string;
  public readonly discount: ProductPriceRangeModel;
  public readonly original: ProductPriceRangeModel;
  public readonly wholesales: WholesalePriceCollectionModel;

  constructor(dataItem: IProductPrice) {
    this.object = dataItem.object;
    this.wholesales = new WholesalePriceCollectionModel(dataItem.wholesales);
    this.discount = new ProductPriceRangeModel(dataItem.discount);
    this.original = new ProductPriceRangeModel(dataItem.original);
  }
  getWholeSales = () => this.wholesales;
  getDiscount = () => this.discount;
  getOriginal = () => this.original;
}

export class ProductGalleryModel {
  public readonly object: string;
  public readonly id: string;
  public readonly url: string;
  public readonly thumb: string;
  public readonly title: string;
  public readonly alt: string;

  constructor(dataItem: IProductGallery) {
    this.object = dataItem.object;
    this.alt = dataItem.alt;
    this.url = dataItem.url;
    this.id = dataItem.id;
    this.thumb = dataItem.thumb;
    this.title = dataItem.title;
  }
}

export class ProductGalleryCollectionModel {
  public readonly object: string;
  public readonly data: ProductGalleryModel[];

  constructor(dataItem: IProductGalleryCollection) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((item) => new ProductGalleryModel(item));
  }
}

export class MeasurementUnitBodyModel {
  public readonly object: string;
  public readonly value: number;
  public readonly unit: string;

  constructor(dataItem: IMeasurementUnitBody) {
    this.object = dataItem.object;
    this.unit = dataItem.unit;
    this.value = dataItem.value;
  }
}

export class WidthModel extends MeasurementUnitBodyModel {}

export class HeightModel extends MeasurementUnitBodyModel {}

export class LengthModel extends MeasurementUnitBodyModel {}

export class UnitWeightModel extends MeasurementUnitBodyModel {}

export class ProductMeasurementModel {
  public readonly object: string;
  public readonly unit_weight: UnitWeightModel;
  public readonly length?: LengthModel;
  public readonly width: WidthModel;
  public readonly height: HeightModel;

  constructor(dataItem: IProductMeasurement) {
    this.object = dataItem.object;
    this.unit_weight = new UnitWeightModel(dataItem.unit_weight);
    this.height = new HeightModel(dataItem.height);
    this.width = new WidthModel(dataItem.width);
    this.length = dataItem.length
      ? new LengthModel(dataItem.length)
      : undefined;
  }
}

export class CountryModel {
  public readonly object: string;
  public readonly code: string;
  public readonly name: string;

  constructor(dataItem: ICountry) {
    this.object = dataItem.object;
    this.code = dataItem.code;
    this.name = dataItem.name;
  }
}

export class ResourceMetaModel {
  public readonly object: string;
  public readonly created_at: string;
  public readonly updated_at: string;

  constructor(dataItem: IResourceMeta) {
    this.object = dataItem.object;
    this.updated_at = dataItem.updated_at;
    this.created_at = dataItem.created_at;
  }
}

export class ProductDetailModel {
  object: string;
  id: string;
  etag: string;
  title: string;
  slug: string;
  image: string;
  description: string;
  stock: number;
  sales: number;
  resource_meta: ResourceMetaModel;
  country: CountryModel;
  seller: {
    object: string;
    id: string;
    name: string;
    link: string;
    ratings: string | null;
  };
  // measurement: ProductMeasurementModel;
  // gallery: ProductGalleryCollectionModel;
  ratings: {
    object: string;
    frequency: number[];
    count: number;
    average: number;
  };
  price: ProductPriceModel;
  shipping_category?: ShippingCategoryModel;

  constructor(dataItem: IProductDetail) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.etag = dataItem.etag;
    this.title = dataItem.title;
    this.slug = dataItem.slug;
    this.image = dataItem.image;
    this.description = dataItem.description;
    this.stock = dataItem.stock;
    this.sales = dataItem.sales;
    this.resource_meta = new ResourceMetaModel(dataItem.resource_meta);
    this.country = new CountryModel(dataItem.country);
    this.seller = dataItem.seller;
    // this.measurement = new ProductMeasurementModel(dataItem.measurement);
    // this.gallery = new ProductGalleryCollectionModel(dataItem.gallery);
    this.ratings = dataItem.ratings;
    this.price = new ProductPriceModel(dataItem.price);
    this.shipping_category = dataItem.shipping_category &&  new ShippingCategoryModel(
      dataItem.shipping_category,
    );
  }
  getStock = () => this.stock;
  getSales = () => this.sales;
  getSlug = () => this.slug;
  getCountry = () => this.country;
  getSeller = () => this.seller;
  getAverageRatings = () => this.ratings.average;
  getTitle = () => this.title;
  getId = () => this.id;
  getImage = () => this.image;
  getDescription = () => this.description;
  getPrice = () => this.price;
}

export class ProductSearchResultModel {
  object: "ProductSearchResult";
  data: ProductDetailModel[];
  facet: FacetModel;
  sort: ISort[];
  pagination: PaginationModel;

  constructor(dataItem: IProductSearchResult) {
    this.object = dataItem.object;
    this.data = dataItem.data.map((item) => new ProductDetailModel(item));
    this.facet = new FacetModel(dataItem.facet);
    this.sort = dataItem.sort;
    this.pagination = new PaginationModel(dataItem.pagination);
  }
  getData = () => this.data;
  getFacet = () => this.facet;
  getSort = () => this.sort;
  getPagination = () => this.pagination;
}

