import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  TreeSelect,
  Typography,
  message,
} from "antd";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";

import {
  useCreateCommission,
  useGetAgentWarehouse,
  useGetBaseShippingCategory,
  useGetRegion,
} from "lib/core-react/hooks/private";
import {
  agentWarehouseCollectionAtom,
  baseShippingCategoryAtom,
  regionRegion,
} from "lib/core-react/store/store";
import {
  renderOptionsFromEnum,
  renderOptionsFromEnumReversed,
} from "components/Form/forms";
import { CommissionPostRequestModel } from "models/commissionCollectionModel";
import { AgentWarehouseCollectionModel } from "models/agentWarehouseCollectionModel";
import { BaseShippingCategoryModel } from "models/baseShippingCategory";
import {
  RateTypeEnums,
  ShippingModeEnums,
  ShippingTypeEnums,
  UnitTypeEnums,
} from "enums/shippingCoreEnums";
import { RegionCollectionModel } from "models/regionCollectionModel";
import { transformEnumToLabeledValue } from "utils/helpers";
import {useNavigate} from "react-router-dom";

const CreateCommission = () => {
  const navigate = useNavigate()
  const { getRegion } = useGetRegion();
  const { getAgentWarehouses } = useGetAgentWarehouse();
  const { getBaseShippingCategory } = useGetBaseShippingCategory();
  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);
  const [{ data: warehouseDataAtom, isLoading: warehouseLoading }] = useAtom(
    agentWarehouseCollectionAtom,
  );
  const [
    {
      data: baseShippingCategoryDataAtom,
      isLoading: baseShippingCategoryLoading,
    },
  ] = useAtom(baseShippingCategoryAtom);

  const { createCommission, isLoading, isError } = useCreateCommission();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getRegion("per_page=200");
    getAgentWarehouses();
    getBaseShippingCategory();
    
  }, []);

  const regionData =
    regionDataAtom && new RegionCollectionModel(regionDataAtom);
  const warehouseData =
    warehouseDataAtom && new AgentWarehouseCollectionModel(warehouseDataAtom);
  const baseShippingCategoryTreeIds =
    baseShippingCategoryDataAtom &&
    new BaseShippingCategoryModel(
      baseShippingCategoryDataAtom,
    ).getBaseShippingCategoryTreeIds();

  const onFinish = async (value: CommissionPostRequestModel) => {
    // Check if data is valid before submitting
    const validate = validateCommissionPostRequest(value);
    if (validate) {
      message.error(validate);
      return;
    }

    const payload = {
      region_id: value.region_id,
      name: value.name,
      is_default: value.is_default ?? false,
      commission_associations: value.commission_associations,
    };

    try {
      await createCommission(payload as CommissionPostRequestModel);
      navigate("/shipping/commission")
      message.success("Commission created successfully");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const validateCommissionPostRequest = (
    value: CommissionPostRequestModel,
  ): string => {
    for (const association of value.commission_associations) {
      if (
        !association.category_commission_associations ||
        association.category_commission_associations.length === 0 ||
        association.category_commission_associations.some(
          (categoryAssociation) =>
            !categoryAssociation.commission_rates ||
            categoryAssociation.commission_rates.length === 0 ||
            categoryAssociation.commission_rates.some(
              (rate) =>
                !rate.slots ||
                !Array.isArray(rate.slots) ||
                rate.slots.length === 0,
            ),
        )
      ) {
        if (
          !association.category_commission_associations ||
          association.category_commission_associations.length === 0
        ) {
          return "Please ensure each commission association contains at least one category commission association";
        }
        if (
          association.category_commission_associations.some(
            (categoryAssociation) =>
              !categoryAssociation.commission_rates ||
              categoryAssociation.commission_rates.length === 0,
          )
        ) {
          return "Please ensure each category commission association contains at least one commission rate";
        }
        if (
          association.category_commission_associations.some(
            (categoryAssociation) =>
              categoryAssociation.commission_rates.some(
                (rate) =>
                  !rate.slots ||
                  !Array.isArray(rate.slots) ||
                  rate.slots.length === 0,
              ),
          )
        ) {
          return "Please ensure each commission rate contains at least one slot";
        }
      }
    }

    return "";
  };

  return (
    <PageHeader
      style={{ marginTop: "10px" }}
      ghost={false}
      title="Create a Commission"
      onBack={() => window.history.back()}
    >
      <Card>
        <Form
          layout="vertical"
          form={form}
          name="create-commission-form"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            commission_associations: [
              {
                category_commission_associations: [
                  {
                    commission_rates: [
                      {
                        slots: [
                          {
                            max_cap: undefined,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        >
          {/* Commission Name */}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Commission name is required",
              },
            ]}
            required
            label="Commission Name"
            name="name"
          >
            <Input placeholder="Commission name" />
          </Form.Item>

          {/* Region */}
          <Form.Item
            rules={[{ required: true, message: "Region is required" }]}
            name="region_id"
            label="Region"
            required
          >
            <Select
              loading={regionLoading}
              placeholder="Please select a region"
            >
              {regionData?.getRegionIdsAsObject() &&
                renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
            </Select>
          </Form.Item>

          {/* Commission Association */}
          <Card style={{ marginBottom: 10 }}>
            <Form.List name="commission_associations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      direction="vertical"
                      size="middle"
                      style={{
                        display: "flex",
                        margin: "0 10px",
                      }}
                    >
                      <Row
                        justify="space-between"
                        style={{
                          padding: 5,
                          borderLeft: "2px solid hotpink",
                        }}
                      >
                        <Typography.Text mark>
                          Commission Association #{index + 1}
                        </Typography.Text>
                        {index > 0 && (
                          <DeleteTwoTone
                            twoToneColor="hotpink"
                            onClick={() => remove(name)}
                          />
                        )}
                      </Row>
                      <Form.Item
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Agent warehouse is required",
                          },
                        ]}
                        required
                        name={[name, "agent_warehouse_id"]}
                        label="Warehouse"
                      >
                        <Select
                          loading={warehouseLoading}
                          placeholder="Please select a warehouse"
                          options={warehouseData?.getWarehouseLabeledValue()}
                        />
                      </Form.Item>

                      {/* Category Commission Association */}
                      <Card style={{ marginBottom: 10 }}>
                        <Form.List
                          key={key}
                          name={[name, "category_commission_associations"]}
                        >
                          {(commissionAssociationFields, { add, remove }) => (
                            <>
                              {commissionAssociationFields.map(
                                (
                                  {
                                    key: commissionAssociationKey,
                                    name: commissionAssociationName,
                                    ...commissionAssociationRestField
                                  },
                                  commissionAssociationIndex,
                                ) => (
                                  <Space
                                    key={commissionAssociationKey}
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                      display: "flex",
                                      margin: "0 10px",
                                    }}
                                  >
                                    <Row
                                      justify="space-between"
                                      style={{
                                        padding: 5,
                                        borderLeft: "2px solid hotpink",
                                      }}
                                    >
                                      <Typography.Text mark>
                                        Category Commission Association #
                                        {commissionAssociationIndex + 1}
                                      </Typography.Text>
                                      {commissionAssociationIndex > 0 && (
                                        <DeleteTwoTone
                                          twoToneColor="hotpink"
                                          onClick={() =>
                                            remove(commissionAssociationName)
                                          }
                                        />
                                      )}
                                    </Row>
                                    <Form.Item
                                      {...commissionAssociationRestField}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Base shipping category is required",
                                        },
                                      ]}
                                      required
                                      name={[
                                        commissionAssociationName,
                                        "base_shipping_category_id",
                                      ]}
                                      label="Base Shipping Category"
                                    >
                                      <TreeSelect
                                        showSearch
                                        dropdownStyle={{
                                          maxHeight: 400,
                                          overflow: "auto",
                                        }}
                                        placeholder="Please select a base shipping category"
                                        allowClear
                                        loading={baseShippingCategoryLoading}
                                        treeLine={{ showLeafIcon: true }}
                                        treeData={baseShippingCategoryTreeIds}
                                      />
                                    </Form.Item>

                                    {/* Commission Rate */}
                                    <Card style={{ marginBottom: 10 }}>
                                      <Form.List
                                        {...commissionAssociationRestField}
                                        name={[
                                          commissionAssociationName,
                                          "commission_rates",
                                        ]}
                                      >
                                        {(
                                          commissionRateFields,
                                          { add, remove },
                                        ) => (
                                          <>
                                            {commissionRateFields.map(
                                              (
                                                {
                                                  name: commissionRateName,
                                                  key: commissionRateKey,
                                                  ...commissionRateRestField
                                                },
                                                commissionRateIndex,
                                              ) => (
                                                <Space
                                                  key={commissionRateKey}
                                                  direction="vertical"
                                                  size="middle"
                                                  style={{
                                                    display: "flex",
                                                    margin: "0 10px",
                                                  }}
                                                >
                                                  <Row
                                                    justify="space-between"
                                                    style={{
                                                      padding: 5,
                                                      borderLeft:
                                                        "2px solid hotpink",
                                                    }}
                                                  >
                                                    <Typography.Text mark>
                                                      Commission Rate #
                                                      {commissionRateIndex + 1}
                                                    </Typography.Text>
                                                    {commissionRateIndex >
                                                      0 && (
                                                      <DeleteTwoTone
                                                        twoToneColor="hotpink"
                                                        onClick={() =>
                                                          remove(
                                                            commissionRateName,
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </Row>
                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Shipping type is required",
                                                      },
                                                    ]}
                                                    required
                                                    name={[
                                                      commissionRateName,
                                                      "shipping_type",
                                                    ]}
                                                    label="Shipping Type"
                                                  >
                                                    <Select placeholder="Please select a shipping type">
                                                      {renderOptionsFromEnumReversed(
                                                        ShippingTypeEnums,
                                                      )}
                                                    </Select>
                                                  </Form.Item>

                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Shipping mode is required",
                                                      },
                                                    ]}
                                                    required
                                                    name={[
                                                      commissionRateName,
                                                      "shipping_mode",
                                                    ]}
                                                    label="Shipping Mode"
                                                  >
                                                    <Select placeholder="Please select a shipping mode">
                                                      {renderOptionsFromEnumReversed(
                                                        ShippingModeEnums,
                                                      )}
                                                    </Select>
                                                  </Form.Item>

                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Unit type is required",
                                                      },
                                                    ]}
                                                    name={[
                                                      commissionRateName,
                                                      "unit_type",
                                                    ]}
                                                    label="Unit Type"
                                                  >
                                                    <Select placeholder="Please select a unit type">
                                                      {renderOptionsFromEnumReversed(
                                                        UnitTypeEnums,
                                                      )}
                                                    </Select>
                                                  </Form.Item>

                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Rate type is required",
                                                      },
                                                    ]}
                                                    required
                                                    name={[
                                                      commissionRateName,
                                                      "rate_type",
                                                    ]}
                                                    label="Rate Type"
                                                  >
                                                    <Select
                                                      options={transformEnumToLabeledValue(
                                                        RateTypeEnums,
                                                      )}
                                                      placeholder="Please select a rate type"
                                                    />
                                                  </Form.Item>

                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Rate is required",
                                                      },
                                                    ]}
                                                    required
                                                    name={[
                                                      commissionRateName,
                                                      "rate",
                                                    ]}
                                                    label="Rate"
                                                  >
                                                    <InputNumber
                                                      style={{ width: "100%" }}
                                                      placeholder="Rate"
                                                    />
                                                  </Form.Item>

                                                  <Form.Item
                                                    {...commissionRateRestField}
                                                    name={[
                                                      commissionRateName,
                                                      "max_cap",
                                                    ]}
                                                    label="Max Cap"
                                                  >
                                                    <InputNumber
                                                      style={{ width: "100%" }}
                                                      placeholder="Max Cap"
                                                    />
                                                  </Form.Item>

                                                  {/* Slot */}
                                                  <Card
                                                    style={{ marginBottom: 10 }}
                                                  >
                                                    <Form.List
                                                      key={commissionRateKey}
                                                      name={[
                                                        commissionRateName,
                                                        "slots",
                                                      ]}
                                                    >
                                                      {(
                                                        slotsFields,
                                                        { add, remove },
                                                      ) => (
                                                        <>
                                                          {slotsFields.map(
                                                            (
                                                              {
                                                                name: slotName,
                                                                key: slotKey,
                                                                ...slotRestField
                                                              },
                                                              slotIndex,
                                                            ) => (
                                                              <Space
                                                                key={slotKey}
                                                                direction="vertical"
                                                                size="middle"
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  margin:
                                                                    "0 10px",
                                                                }}
                                                              >
                                                                <Row
                                                                  justify="space-between"
                                                                  style={{
                                                                    padding: 5,
                                                                    borderLeft:
                                                                      "2px solid hotpink",
                                                                  }}
                                                                >
                                                                  <Typography.Text
                                                                    mark
                                                                  >
                                                                    Slot #
                                                                    {slotIndex +
                                                                      1}
                                                                  </Typography.Text>
                                                                  {slotIndex >
                                                                    0 && (
                                                                    <DeleteTwoTone
                                                                      twoToneColor="hotpink"
                                                                      onClick={() =>
                                                                        remove(
                                                                          slotName,
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </Row>
                                                                <Form.Item
                                                                  {...slotRestField}
                                                                  label="Minimum Amount"
                                                                  name={[
                                                                    slotName,
                                                                    "min_amount",
                                                                  ]}
                                                                  rules={[
                                                                    {
                                                                      required:
                                                                        true,
                                                                      message:
                                                                        "Minimum amount is required",
                                                                    },
                                                                  ]}
                                                                  required
                                                                >
                                                                  <InputNumber
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    placeholder="Minimum amount"
                                                                  />
                                                                </Form.Item>
                                                                <Form.Item
                                                                  {...slotRestField}
                                                                  label="Maximum amount"
                                                                  name={[
                                                                    slotName,
                                                                    "max_amount",
                                                                  ]}
                                                                  rules={[
                                                                    {
                                                                      required:
                                                                        true,
                                                                      message:
                                                                        "Maximum amount is required",
                                                                    },
                                                                  ]}
                                                                >
                                                                  <InputNumber
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    placeholder="Maximum amount"
                                                                  />
                                                                </Form.Item>

                                                                <Form.Item
                                                                  {...slotRestField}
                                                                  rules={[
                                                                    {
                                                                      required:
                                                                        true,
                                                                      message:
                                                                        "Rate type is required",
                                                                    },
                                                                  ]}
                                                                  required
                                                                  name={[
                                                                    slotName,
                                                                    "rate_type",
                                                                  ]}
                                                                  label="Rate Type"
                                                                >
                                                                  <Select
                                                                    placeholder="Please select a rate type"
                                                                    options={transformEnumToLabeledValue(
                                                                      RateTypeEnums,
                                                                    )}
                                                                  />
                                                                </Form.Item>

                                                                <Form.Item
                                                                  {...slotRestField}
                                                                  rules={[
                                                                    {
                                                                      required:
                                                                        true,
                                                                      message:
                                                                        "Rate is required",
                                                                    },
                                                                  ]}
                                                                  required
                                                                  name={[
                                                                    slotName,
                                                                    "rate",
                                                                  ]}
                                                                  label="Rate"
                                                                >
                                                                  <InputNumber
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    placeholder="Rate"
                                                                  />
                                                                </Form.Item>

                                                                <Form.Item
                                                                  {...slotRestField}
                                                                  name={[
                                                                    slotName,
                                                                    "max_cap",
                                                                  ]}
                                                                  label="Max Cap"
                                                                >
                                                                  <InputNumber
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    placeholder="Max Cap"
                                                                  />
                                                                </Form.Item>
                                                              </Space>
                                                            ),
                                                          )}

                                                          <Form.Item>
                                                            <Button
                                                              type="dashed"
                                                              onClick={() => {
                                                                form
                                                                  .validateFields()
                                                                  .then(() =>
                                                                    add(),
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      console.error(
                                                                        "Validation Error:",
                                                                        error,
                                                                      );
                                                                    },
                                                                  );
                                                              }}
                                                              block
                                                              icon={
                                                                <PlusOutlined />
                                                              }
                                                            >
                                                              Add slot
                                                            </Button>
                                                          </Form.Item>
                                                        </>
                                                      )}
                                                    </Form.List>
                                                  </Card>
                                                </Space>
                                              ),
                                            )}

                                            <Form.Item>
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  form
                                                    .validateFields()
                                                    .then(() => add())
                                                    .catch((error) => {
                                                      console.error(
                                                        "Validation Error:",
                                                        error,
                                                      );
                                                    });
                                                }}
                                                block
                                                icon={<PlusOutlined />}
                                              >
                                                Add commission rate
                                              </Button>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                    </Card>
                                  </Space>
                                ),
                              )}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    form
                                      .validateFields()
                                      .then(() => add())
                                      .catch((error) => {
                                        console.error(
                                          "Validation Error:",
                                          error,
                                        );
                                      });
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add categry commission association
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        form
                          .validateFields()
                          .then(() => add())
                          .catch((error) => {
                            console.error("Validation Error:", error);
                          });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add commission association
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Card>
          <Form.Item name="is_default" valuePropName="checked">
            <Checkbox>Set as Default</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </PageHeader>
  );
};

export default CreateCommission;
