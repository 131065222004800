
import App from "./App";
import AppProviders from "./context";
import { Provider as JotaiProvider } from "jotai";
import { ServiceProvider } from "lib/core-react";
import rootStore from "lib/core-react/store/store";
import "./index.css";
import { StrictMode } from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);



root.render(
  <StrictMode>
    {/* <Provider store={store}> */}
    <AppProviders>
      <ServiceProvider>
        <JotaiProvider store={rootStore}>
          <App />
        </JotaiProvider>
      </ServiceProvider>
    </AppProviders>
    {/* </Provider> */}
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
