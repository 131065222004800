import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { Avatar, Card, Skeleton, Tabs, TreeSelect, Typography } from "antd";
import { StarTwoTone } from "@ant-design/icons";

import { useGetShippingCategory } from "lib/core-react/hooks/private";
import {
  agentCategoryPriceReadCollectionAtom,
  shippingCategoryAtom,
} from "lib/core-react/store/store";
import { ShippingCategoryModel } from "models/shippingCategory";
import { IAddBuyOrderProductStep2FormData } from "types/buyOrderDetail";
import { useGetCategoryPrices } from "lib/core-react/hooks/private/useShippingAgent";
import { BuyOrderDetailModel } from "models/buyOrderDetailModel";
import { AgentCategoryPriceReadCollectionModel } from "models/agentCategoryPriceReadCollectionModel";

interface IProps {
  orderDetail: BuyOrderDetailModel;
  addBuyOrderProductStep2FormData: IAddBuyOrderProductStep2FormData | undefined;
  setAddBuyOrderProductStep2FormData: Dispatch<
    SetStateAction<IAddBuyOrderProductStep2FormData>
  >;
}

export const AddBuyOrderProductShippingMethodModal = ({
  orderDetail,
  addBuyOrderProductStep2FormData,
  setAddBuyOrderProductStep2FormData,
}: IProps) => {

  const location = useLocation();
  const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");

  const [productCategory, setProductCategory] = useState<string | undefined>();
  const [shippingType, setShippingType] = useState<string>("air");
  const [shippingMode, setShippingMode] = useState<string>("cargo");

  const { getShippingCategory } = useGetShippingCategory();
  const { getCategoryPrices } = useGetCategoryPrices();

  useEffect(() => {
    getShippingCategory();

  }, []);

  useEffect(() => {
    if (productCategory) {
      getCategoryPrices({
        user_id: orderDetail.getCustomer().getId(),
        region: orderDetail.getProducts().getFirstProductRegionCode(),
        destination_warehouse_id: orderDetail
          .getProducts()
          .getFirstDestinationWarehouseId(),
        shipping_category_id: productCategory,
        shipping_country_id: orderDetail
          .getProducts()
          .getFirstShippingCountryId(),
        shipping_mode: shippingMode,
        shipping_type: shippingType,
      });
    }

  }, [shippingType, shippingMode, buyOrderId, productCategory]);

  const {
    data: shippingCategoryCollection,
    isLoading: shippingCategoryLoading,
  } = useAtomValue(shippingCategoryAtom);
  const {
    data: categoryPriceReadCollection,
    isLoading: categoryPriceReadLoading,
  } = useAtomValue(agentCategoryPriceReadCollectionAtom);

  const shippingCategoryCollectionData =
    shippingCategoryCollection &&
    new ShippingCategoryModel(shippingCategoryCollection);

  const categoryPriceReadCollectionData =
    categoryPriceReadCollection &&
    new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection);


  const { Text } = Typography;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: "24px",
        }}
      >
        <Text strong>Product Category</Text>
        <TreeSelect
          onChange={(value) => {
            console.log(value)
            setProductCategory(value)
            setAddBuyOrderProductStep2FormData(pre=>{
              return {
                ...pre,
                shipping_category_id:value
              }
            })
          }}
          showSearch
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select a category"
          allowClear
          loading={shippingCategoryLoading}
          treeLine={{ showLeafIcon: true }}
          treeData={
            shippingCategoryCollectionData
              ? shippingCategoryCollectionData.getShippingCategoryTreeIds()
              : []
          }
        />
      </div>
      <div>
        <Tabs
          onChange={(activeKey) => {
            setShippingType(activeKey);
            if (activeKey === "ship") {
              setShippingMode("cargo");
            }
          }}
          defaultActiveKey={shippingType}
          centered
          size="small"
          type="card"
          items={["By Air", "By Ship"].map((item) => {
            return {
              label: item,
              key: item.split(" ")[1].toLowerCase(),
            };
          })}
        />
      </div>
      <div>
        <Text style={{ fontSize: "18px" }} strong>
          Shipping Modes
        </Text>
        <Tabs
          onChange={(activeKey) => setShippingMode(activeKey)}
          defaultActiveKey={shippingMode}
          size="small"
          items={
            shippingType === "ship"
              ? ["Cargo"].map((item) => {
                  return {
                    label: item,
                    key: item.toLowerCase(),
                  };
                })
              : ["Cargo", "International", "P2P"].map((item) => {
                  return {
                    label: item,
                    key: item.toLowerCase(),
                  };
                })
          }
        />
      </div>
      <div>
        <Card
          title={<Text strong>Select Cargo Company</Text>}
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {categoryPriceReadLoading ? (
              <Skeleton active />
            ) : !categoryPriceReadCollectionData ? (
              <div>Please select a category</div>
            ) : categoryPriceReadCollectionData?.getData().length === 0 ? (
              <div>Shipping partner is not available for this category!</div>
            ) : (
              categoryPriceReadCollectionData?.getData().map((data) => (
                <div
                  onClick={() =>
                    setAddBuyOrderProductStep2FormData((pre)=>{
                      return {
                        ...pre,
                          agent_category_price_read_id: data.getId(),
                          provider: data.getAgentWarehouse().getName(),

                      }
                    })
                  }
                  key={data.getId()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "16px",
                    marginBottom: "16px",
                    cursor: "pointer",
                    border: `${
                      addBuyOrderProductStep2FormData?.agent_category_price_read_id ===
                      data.getId()
                        ? "1px solid green"
                        : ""
                    }`,
                  }}
                >
                  <div style={{ display: "flex", gap: "12px" }}>
                    <Avatar
                      size={50}
                      shape="square"
                      style={{
                        backgroundColor: "#00897B",
                        color: "white",
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    >
                      {data.getAgentWarehouse().getName()[0]}
                    </Avatar>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: "16px" }}>
                        {data.getAgentWarehouse().getName()}
                      </Text>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div
                          style={{
                            borderRight: "2px solid #DDD",
                            paddingRight: "16px",
                            display: "flex",
                          }}
                        >
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" />
                          <StarTwoTone twoToneColor="#FFC107" /> (700)
                        </div>
                        <div>
                          <Text strong>800+ </Text>
                          <Text style={{ color: "#707070", fontSize: "14px" }}>
                            Shipping Delivered
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    <Text strong>৳500/kg</Text>
                    <Text style={{ fontSize: "12px", color: "#6b7386" }}>
                      Tax included
                    </Text>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};
