import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Col, Descriptions, Modal, Row, Button } from 'antd';
import { useEffect, useState } from 'react';
import AddressModal from './AddressModal';
import {useAddressDelete} from "../../../../../lib/core-react/hooks/private/useAddress";

const { confirm } = Modal;

const Address = ({ address, selectedAddressId, setSelectedAddressId }) => {
  const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
  const [visibleCount, setVisibleCount] = useState(5); // Initial count of visible addresses
const {deleteAddress, isLoading:deleteLoading} = useAddressDelete()
  useEffect(() => {
    if (address.length > 0 && !selectedAddressId) {
      setSelectedAddressId(address[0].id);
    }
  }, [address, selectedAddressId, setSelectedAddressId]);

  const handleCardClick = (id) => {
    setSelectedAddressId(id);
  };

  const showModal = () => {
    setIsModalVisibleAddress(true);
  };

  const handleCancel = () => {
    setIsModalVisibleAddress(false);
  };

  const handleShowMore = () => {
    setVisibleCount(address.length); // Show all addresses
  };

  const handleShowLess = () => {
    setVisibleCount(5); // Show only 5 addresses
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this address?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteAddress(id);
      },
      okButtonProps:{loading:deleteLoading, disabled:deleteLoading}
    });
  };

  const onDeleteAddress = async(id) => {
     try {
       await deleteAddress(id)
     }catch (e) {

     }
  };

  // const onEditAddress = (id) => {
  //   console.log('Edit address with id:', id);
  //   // Implement the edit logic here, such as opening the modal with pre-filled data
  //   setIsModalVisibleAddress(true);
  // };

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={[16, 16]}>
          {address.slice(0, visibleCount).map((addr, index) => {
            const items = [
              {
                label: 'Name',
                children: addr.name,
              },
              {
                label: 'Address',
                children: `${addr.address_1}, ${addr.address_2}, ${addr.country ?? addr.country}, ${addr.district ? addr.district : ""}, ${addr.postal_code? addr.postal_code : ""}`,
              },
            ];

            return (
              <Col xs={24} sm={24} md={12} lg={8} key={index}>
                <Card
                  onClick={() => handleCardClick(addr.id)}
                  style={{
                    cursor: 'pointer',
                    border: selectedAddressId === addr.id ? '2px solid #7ABA78' : '1px solid #e0e0e0',
                    marginBottom: '0',
                    minHeight: '160px',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'left',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    position: 'relative',
                  }}
                >
                  <h4 style={{ fontSize: '14px', marginBottom: '8px', marginTop:"-5px" }}>{`Address ${index + 1}`}</h4>
                  <Descriptions column={1} style={{ marginBottom: '0', fontSize: '12px' }}>
                    {items.map((item) => (
                      <Descriptions.Item
                        style={{
                          marginBottom: '0',
                          paddingBottom: '0',
                          fontSize: '12px',
                          lineHeight: '1.4',
                          whiteSpace: "wrap",
                          textOverflow: 'ellipsis', // Add ellipsis for overflow
                        }}
                        label={item.label}
                        key={item.label}
                      >
                        {item.children}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '10px' }}>
                    <EditOutlined
                     disabled={true}
                      style={{
                        fontSize: '16px',
                        color: '#1890ff',
                        cursor: 'pointer',
                        opacity:.3
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        // onEditAddress(addr.id);
                      }}
                    />
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        color: '#ff4d4f',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(addr.id);
                      }}
                    />
                  </div>
                </Card>
              </Col>
            );
          })}
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Card
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                cursor: 'pointer',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                padding: '0',
                textAlign: 'center',
              }}
              onClick={showModal}
            >
              <PlusOutlined style={{ fontSize: '24px', color: '#1890ff', lineHeight: '100px' }} />
            </Card>
          </Col>
        </Row>
        {address.length > 5 && (
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            {visibleCount < address.length ? (
              <Button type="link" onClick={handleShowMore}>
                Show More
              </Button>
            ) : (
              <Button type="link" onClick={handleShowLess}>
                Show Less
              </Button>
            )}
          </div>
        )}
      </div>

      <Modal
        title="Add New Address"
        visible={isModalVisibleAddress}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <AddressModal setIsModalVisible={setIsModalVisibleAddress} />
      </Modal>
    </>
  );
};

export default Address;
