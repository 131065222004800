import { IPagination } from "types/pagination";
import { AgentWarehouseCollectionEnums } from "enums/agentWarehouseCollectionEnums";
import { OperationStatusEnums, StatusEnums } from "enums/shippingCoreEnums";
import {
  IAgentWarehouse,
  IAgentWarehouseCollection,
  IAgentWarehouseUpdateRequest,
} from "types/agentWarehouse";
import { ICountryData } from "types/country";
import { IAgentCompanyData } from "types/agentCompany";

export class AgentWarehouseModel {
  public readonly object: AgentWarehouseCollectionEnums.AgentWarehouse;
  public readonly id: number;
  public readonly name: string;
  public readonly alias_name: string | null | undefined;
  public readonly shipping_mark: string;
  public readonly operation_status: OperationStatusEnums;
  public readonly status: StatusEnums;
  public readonly country: ICountryData;
  public readonly agent_company: IAgentCompanyData;

  constructor(dataItem: IAgentWarehouse) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.alias_name = dataItem.alias_name;
    this.shipping_mark = dataItem.shipping_mark;
    this.operation_status = dataItem.operation_status;
    this.status = dataItem.status;
    this.country = dataItem.country;
    this.agent_company = dataItem.agent_company;
  }
}

export class AgentWarehouseCollectionModel {
  public readonly object: AgentWarehouseCollectionEnums.AgentWarehouseCollection;
  public readonly data: AgentWarehouseModel[];
  public readonly pagination: IPagination;

  constructor(data: IAgentWarehouseCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AgentWarehouseModel(item));
    this.pagination = data.pagination;
  }
  getAgentWarehouseById(id: number): AgentWarehouseModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getWarehouseIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
}

//Update Request
export class AgentWarehouseUpdateModel {
  public readonly country_id: number;
  public readonly name: string;
  public readonly shipping_mark: string;
  public readonly operation_status: OperationStatusEnums;
  public readonly verification_status: StatusEnums;
  public readonly alias_name: string | undefined | null;

  constructor(reqData: IAgentWarehouseUpdateRequest) {
    this.country_id = reqData.country_id;
    this.name = reqData.name;
    this.shipping_mark = reqData.shipping_mark;
    this.operation_status = reqData.operation_status;
    this.verification_status = reqData.status;
    this.alias_name = reqData.alias_name;
  }
}
