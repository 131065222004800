import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { courierGatewayCollectionAtom } from "lib/core-react/store/store";
import { ICourierGatewayForm } from "types/courierGatewayCollection";

export const useCreateCourierGateway = () => {
  const { courierService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [courierGatewayData, setCourierGatewayData] = useAtom(
    courierGatewayCollectionAtom,
  );

  const createCourierGateway = async (payload: ICourierGatewayForm) => {
    setIsLoading(true);
    try {
      const response =
        await courierService.courierGatewayResource.create(payload);
      setIsLoading(false);
      setCourierGatewayData({ ...courierGatewayData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createCourierGateway, isLoading, isError };
};

export const useUpdateCourierGateway = () => {
  const { courierService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [courierGatewayData, setCourierGatewayData] = useAtom(
    courierGatewayCollectionAtom,
  );

  const updateCourierGateway = async (
    id: number,
    payload: ICourierGatewayForm,
  ) => {
    setIsLoading(true);
    try {
      const response = await courierService.courierGatewayResource.update(
        id,
        payload,
      );
      setIsLoading(false);
      setCourierGatewayData({ ...courierGatewayData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateCourierGateway, isLoading, isError };
};

export const useGetCourierGateway = () => {
  const [courierGateway, setCourierGateway] = useAtom(
    courierGatewayCollectionAtom,
  );
  const { courierService } = useService();
  const getCourierGateway = async (params?: string): Promise<void> => {
    setCourierGateway({ ...courierGateway, isLoading: true, error: null });
    try {
      const response = await courierService.courierGatewayResource.get(params);
      setCourierGateway({
        ...courierGateway,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setCourierGateway({
        ...courierGateway,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getCourierGateway } as const;
};

export const useDeleteCourierGateway = () => {
  const { courierService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [courierGatewayData, setCourierGatewayData] = useAtom(
    courierGatewayCollectionAtom,
  );

  const deleteCourierGateway = async (id: number) => {
    setIsLoading(true);
    try {
      const response = await courierService.courierGatewayResource.delete(id);
      setIsLoading(false);
      setCourierGatewayData({ ...courierGatewayData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { deleteCourierGateway, isLoading, isError };
};
