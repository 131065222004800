import React, {
  Dispatch,
  SetStateAction,
  useMemo
} from "react";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";
import { SelectLabelValue } from "./utils";
import {useGetCustomers} from "../../lib/core-react/hooks/private/useBulkUser";
import {useAtom} from "jotai";
import {bulkUserCustomer} from "../../lib/core-react/store/store";
import {BulkUserCollectionModel} from "../../models/bulkUserCollectionModel";

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [bulkUserCustomerData] =
    useAtom(bulkUserCustomer);

  const {getCustomers} = useGetCustomers()
  const Customers = bulkUserCustomerData  && bulkUserCustomerData.data &&  new BulkUserCollectionModel(bulkUserCustomerData.data)
  const formatCustomersData = Customers?.data.map(x=>{
    return {
      label: `${x.name} (${x.email})`,
      value: x.id
    }
  })

  const debounceFetcher = useMemo(() => {
    return debounce((keyword: string) => {
      const query = `per_page=100&keyword=${keyword}`;
      getCustomers(query);
    }, debounceTimeout);
  }, [getCustomers, debounceTimeout]);

  return (
    <Select
      labelInValue
      loading={bulkUserCustomerData.isLoading}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={bulkUserCustomerData.isLoading ? <Spin size="small" /> : null}
      {...props}
      options={formatCustomersData}
    />
  );
}


interface IProps {
  specificUserIds: SelectLabelValue[];
  setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  placeholder?: string;
}

export const UserSelect = ({
  specificUserIds,
  setSpecificUserIds,
  placeholder,
}: IProps) => {
  return (
    <DebounceSelect
      mode="multiple"
      value={specificUserIds}
      placeholder={placeholder || "Select users"}
      onChange={(newValue) => {
        setSpecificUserIds(newValue as SelectLabelValue[]);
      }}
      style={{ width: "100%" }}
    />
  );
};
