export const StatusEnum = {
  CREATED: "created",
  PRE_PROCESSED: "pre-processed",
  PROCESSING: "processing",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  FAILED: "failed",
};

export const UserTypeEnums = {
  AGENT: "agent",
  ADMIN: "admin",
};
