import { Config } from "../request/types/request";
import { ServiceBaseUrl, ServiceNameEnum } from "./servicBaseUrl";


class ProductApiService {
  public config: Config;

  constructor() {
    this.config = {
      baseUrl: ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.PRODUCT_SERVICE),
      apiKey: "",
      maxRetries: 0,
      publishableApiKey: "",
    };
  }
}

export default ProductApiService;
