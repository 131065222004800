import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IRegionPostRequest } from "types/region";

class RegionResource extends BaseResource {
  path = `/api/core/admin/region/v1/regions`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}?expand=currency,countries,languages${params ? `&${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: IRegionPostRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: IRegionPostRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default RegionResource;
