import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IPaymentGatewayFormData } from "types/paymentGatewayCollection";

class GatewayResource extends BaseResource {
  path = `/api/payment/admin/gateway/v1/gateways`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}?expand=logo,currencies${
      params ? `&${params}` : ""
    }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: IPaymentGatewayFormData,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: IPaymentGatewayFormData,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PATCH", path, payload, undefined, customHeaders);
  }

  // delete(id: number, customHeaders: Record<string, any> = {}): ResponsePromise {
  //   const path = `${this.path}/${id}`;
  //   return this.client.request("DELETE", path, undefined, {}, customHeaders);
  // }
}

export default GatewayResource;
