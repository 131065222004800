import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";

import { permissionAtom, roleAtom } from "lib/core-react/store/store";

import { RolePostRequestModel } from "models/roleCollectionModel";

export const usePermission = () => {
  const { aclService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [roleData, setRoleData] = useAtom(roleAtom);

  const createRole = async (payload: RolePostRequestModel) => {
    setIsLoading(true);
    try {
      const response = await aclService.aclResource.createRole(payload);
      setIsLoading(false);
      setRoleData({ ...roleData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createRole, isLoading, isError };
};

// export const useUpdateRole = () => {
//   const { aclService } = useService();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");
//   const [roleData, setRoleData] = useAtom(roleAtom);

//   const updateRole = async (id: number, payload: RoleUpdateRequestModel) => {
//     setIsLoading(true);
//     try {
//       const response = await aclService.aclResource.updateRole(id, payload);
//       setIsLoading(false);
//       setRoleData({ ...roleData, refetch: true });
//       return response.data;
//     } catch (error: any) {
//       setError(getError(error));
//       setIsLoading(false);
//       throw error;
//     }
//   };
//   return { updateRole, isLoading, isError };
// };

export const useGetPermission = () => {
  const [permission, setPermission] = useAtom(permissionAtom);
  const { aclService } = useService();
  const getPermission = async (params?: string): Promise<void> => {
    setPermission({ ...permission, isLoading: true, error: null });
    try {
      const response = await aclService.aclResource.getPermissions(params);
      setPermission({
        ...permission,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setPermission({
        ...permission,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getPermission } as const;
};
