import type { DescriptionsProps } from 'antd'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Flex,
  Modal,
  Row,
  Tag,
  Typography,
} from 'antd'

import { AgentCompanyVerificationStatusEnums } from 'enums/shared'
import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import ShippingUpdateModal from '../ShippingUpdateModal'

export const Shipping = ({ isLoading, productData, title }) => {
  console.log(productData)
  const agentCompany = productData
    ?.getMoveonShipping()
    ?.getAgentWarehouse()
    .getAgentCompany()
  const agentWarehouse = productData?.getMoveonShipping()?.getAgentWarehouse()
  const shippingCategory = productData
    ?.getMoveonShipping()
    .getShippingCatetory()

  const { Text } = Typography

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Shipping Category',
      span: 2,
      children: (
        <Flex>
          <Text>{shippingCategory?.getName()}</Text>
          <Button size="small" style={{ padding: 0 }} type="link" />
        </Flex>
      ),
    },
    {
      key: '1',
      label: 'Shipping Mode',
      span: 2,
      children: (
        <Flex>
          <Text>{productData?.getMoveonShipping()?.getShippingMode()}</Text>
          <Button size="small" style={{ padding: 0 }} type="link" />
        </Flex>
      ),
    },
    {
      key: '2',
      label: 'Shipping type',
      children: (
        <Flex>
          <Text>{productData?.getMoveonShipping()?.getShippingType()}</Text>
          <Button size="small" style={{ padding: 0 }} type="link" />
        </Flex>
      ),
    },
    {
      key: '4',
      label: 'Unit Type',
      children: productData?.getMoveonShipping()?.getUnitType(),
    },
    {
      key: '5',
      label: 'Rate',
      children: productData?.getMoveonShipping()?.getRate(),
    },
  ]
  const companyItems: DescriptionsProps['items'] = [
    {
      key: '6',
      label: 'Name',
      span: 4,
      children: (
        <Typography.Text
          style={{ width: '150px' }}
          ellipsis={{
            tooltip: `${agentCompany?.getPrimaryName()} ${agentCompany?.getSecondaryName()}`,
          }}
        >
          {`${agentCompany?.getPrimaryName()} ${agentCompany?.getSecondaryName()}`}
        </Typography.Text>
      ),
    },
    {
      key: '7',
      span: 4,
      label: 'Verification status',
      children: agentCompany?.getVerificationStatus() ? (
        <Tag
          style={{ textTransform: 'capitalize' }}
          color={
            agentCompany?.getVerificationStatus() ===
            AgentCompanyVerificationStatusEnums.APPROVED
              ? 'green'
              : 'red'
          }
        >
          {agentCompany?.getVerificationStatus()}
        </Tag>
      ) : (
        ''
      ),
    },
    {
      key: '10',
      span: 4,
      label: 'Registered country',
      children: agentCompany?.getRegisteredCountryDetail().name,
    },
    {
      key: '11',
      span: 4,
      label: 'Company type',
      children: agentCompany?.getCompanyType(),
    },
    {
      key: '12',
      span: 4,
      label: 'Director',
      children: `${agentCompany?.getDirectorName()} (${agentCompany?.getDesignation()})`,
    },
    {
      key: '13',
      span: 4,
      label: 'Business phone',
      children: (
        <a href={`tel:${agentCompany?.getBusinessPhone()}`}>
          {agentCompany?.getBusinessPhone()}
        </a>
      ),
    },
    {
      key: '14',
      span: 4,
      label: 'Years of experience',
      children: (
        <Typography.Text
          style={{ width: '150px' }}
          ellipsis={{
            tooltip: agentCompany?.getYearsOfExperience().replaceAll('_', ' '),
          }}
        >
          {agentCompany?.getYearsOfExperience().replaceAll('_', ' ')}
        </Typography.Text>
      ),
    },
    {
      key: '15',
      span: 4,
      label: 'Team Member',
      children: (
        <Typography.Text
          style={{ width: '150px' }}
          ellipsis={{
            tooltip: agentCompany
              ?.getNumberOfPeopleInTeam()
              .replaceAll('_', ' '),
          }}
        >
          {agentCompany?.getNumberOfPeopleInTeam().replaceAll('_', ' ')}
        </Typography.Text>
      ),
    },
  ]

  const warehouseItems: DescriptionsProps['items'] = [
    {
      key: '8',
      label: 'Name',
      span: 4,
      children: (
        <Typography.Text
          style={{ width: '150px' }}
          ellipsis={{
            tooltip: agentWarehouse?.getName(),
          }}
        >
          {agentWarehouse?.getName()}
        </Typography.Text>
      ),
    },
    {
      key: '9',
      span: 4,
      label: 'Verification status',
      children: agentWarehouse?.getStatus() ? (
        <Tag
          style={{ textTransform: 'capitalize' }}
          color={
            agentWarehouse.getStatus() ===
            AgentCompanyVerificationStatusEnums.APPROVED
              ? 'green'
              : 'red'
          }
        >
          {agentWarehouse.getStatus()}
        </Tag>
      ) : (
        ''
      ),
    },
    {
      key: '16',
      span: 4,
      label: 'Shipping mark',
      children: agentWarehouse?.getShippingMark(),
    },
    {
      key: '17',
      span: 4,
      label: 'Operation Status',
      children: agentWarehouse?.getOperationStatus(),
    },
  ]
  return (
    <>
      <Card
        title={title}
        bordered={false}
        loading={isLoading}
        extra={<EditOutlined onClick={showModal} />}
      >
        <Descriptions size="small" items={items} />
        <Row gutter={[16,16]}>
          <Col xs={24} sm={12}>
            <Card
              title={<p style={{ textAlign: 'center' }}>Agent Company</p>}
              bordered
              style={{
                flex: 1,
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {agentCompany ? (
                <Descriptions size="small" items={companyItems} />
              ) : (
                'No company available yet.'
              )}
            </Card>
          </Col>
          <Col xs={24} sm={12}>
            <Card
              title={<p style={{ textAlign: 'center' }}>Warehouse</p>}
              bordered
              style={{
                flex: 1,
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {agentWarehouse ? (
                <Descriptions size="small" items={warehouseItems} />
              ) : (
                'No warehouse available yet.'
              )}
            </Card>
          </Col>
        </Row>
      </Card>

      <Modal
        title="Edit Shipping Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <h1>Hello</h1>
        {/* Modal content goes here */}
        <ShippingUpdateModal
          setIsModalVisible={setIsModalVisible}
          shipping={productData.getMoveonShipping()}
          user={productData.getOrder().getCustomer(). getId()}
          region={productData.getRegion().getCode()}
          buyProductId={productData.getId()}
        />
      </Modal>
    </>
  )
}
