export const generateCouponCode = () => {
  const length = 10;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let coupon = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    coupon += charset[randomIndex];
  }
  return coupon;
};
