import React from "react";
import AppRowContainer from "components/App/AppRowContainer";
import { Col } from "antd";
import {
  StyledProductDetailItemTitle,
  StyledProductDetailSpecification,
} from "./index.styled";
import { ProductSpecificationModel } from "models/productDetailExtendedModel";

type ProductSpecificationProps = {
  productSpec: ProductSpecificationModel[];
};

const ProductSpecification = ({ productSpec }: ProductSpecificationProps) => {
  return (
    <StyledProductDetailSpecification>
      <StyledProductDetailItemTitle>Specification</StyledProductDetailItemTitle>
      <AppRowContainer>
        {productSpec.map((data) => (
          <React.Fragment key={data.getId()}>
            <Col xs={8}>
              <p className="text-secondary"> {data.getLabel()}</p>
            </Col>
            <Col xs={16}>
              <p> {data.getValue()}</p>
            </Col>
          </React.Fragment>
        ))}
      </AppRowContainer>
    </StyledProductDetailSpecification>
  );
};

export default ProductSpecification;
