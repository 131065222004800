import { useState, useEffect } from 'react';

export const  useWindowWidth = ()=> {
  // Initialize state with the current window width
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setWidth(currentWidth);
      setIsMobile(currentWidth < 1500);
    };

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Return the current window width and isMobile flag
  return { width, isMobile };
}

export default useWindowWidth;
