import {Select, Empty, Form, Spin} from "antd";
import type { SelectProps } from "antd";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options"> {
  debounceTimeout?: number;
  resource: string;
}

function DebounceSelect<
  ValueType extends {
    value: string;
  } = any,
>({
  initialData,
  ...props
}: DebounceSelectProps<ValueType> & {
  initialData:{
    value: string | number;
    label: string}[]
}) {

  return (
    <Select
      loading={props.loading}
      showSearch
      filterOption={false}
      notFoundContent={props.loading ? <Spin/>: !initialData.length ? <Empty/> : null}
      {...props}
      options={initialData}
    />
  );
}
export const UserSelect = ({
  value,
  allowClear,
  resource,
  showSearch,
  placeholder,
  onChange,
  initialData,
  style,
  ...props
}: Omit<DebounceSelectProps, "fetchOptions"> & {
  initialData:{ label:string, value:string | number}[]
}) => {
  const form = Form.useFormInstance();
  const handleChangeUser = async (
    newValue:number,
    option: {
      label:string,
      value:number
    },
  ) => {
    if (newValue) {
      form.setFieldsValue({ user: newValue });
    }
    if (onChange) {
      onChange(newValue, option);
    }
  };

  return (
    <DebounceSelect
      initialData={initialData}
      resource={""}
      value={value}
      allowClear={allowClear}
      //@ts-ignore
      onChange={handleChangeUser}
      showSearch={showSearch}
      placeholder={placeholder}
      style={style}
      {...props}
    />
  );
};
