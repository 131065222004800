import {
    IAddress,
    IAgentWarehouse,
    IBuyOrder,
    IBuyOrderCollection,
    IBuyOrderCurrency,
    IBuyProduct,
    IBuyProductCollection,
    IBuyProductCustomerAssociation,
    IBuyProductPackage,
    IBuyProductRegion,
    IBuyProductVariation,
    IBuyProductVariationCollection,
    IBuyProductVariationPropertiesCollection,
    IBuyProductVariationProperty,
    IDestinationWarehouse,
    IInvoice,
    ILabelData,
    ILabels,
    IMoveonShipping,
    IOrder,
    IOrderFilters,
    IOrderFiltersPropertyValue,
    IOrderFiltersPropertyValueOptions,
    IOrderHandler,
    IProductSeller,
    ISequence,
    ISequencesData,
    IShippingSlot,
    IShippingSlotCollection,
    IUser,
    IVariant,
    IShippingCountry,
    ITransactionSummary,
    ICustomerInvoice,
    Address,
    IRegisteredCountryDetail,
    IAgentCompany,
    IBuyProductAgentAssociation,
    SequenceSlot,
    IBuyAction,
    IActionType,
    IBuyActionCollection,
} from "../types/buyOrderCollection";
import { IPagination } from "../types/pagination";
import moment from "moment/moment";
import { IFilterType } from "../types/filters";
import { StoreModel } from "./storeCollectionModel";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";
import { TakeActionsEnums } from "../enums/takeActionsEnums";
import {
    IShippingAddress,
    IShippingCategory,
} from "types/shipmentProduct";
import { ShipmentProductModel } from "./shipmentProductModel";

export class BuyOrderCollectionModel {
    object: string;
    data: BuyOrderModel[];
    filters: IFilterType;
    pagination: PaginationCompressedModel;

    constructor(payload: IBuyOrderCollection) {
        this.object = payload.object;
        this.data = payload.data?.map(
            (buyOrder) => new BuyOrderModel(buyOrder),
        );
        this.filters = payload.filters;
        this.pagination = new PaginationCompressedModel(payload.pagination);
    }

    getData() {
        return this.data;
    }

    getFilters() {
        return this.filters;
    }

    getPagination() {
        return this.pagination;
    }

    getOrders() {
        return this.data.map((order) => {
            return {
                key: order.getId(),
                orderId: order.getOrderNumber(),
                orderHandler: {
                    name: order?.getOrderHandler()?.getName()
                        ? order.getOrderHandler()?.getName() || "Not Assigned"
                        : "Not Assigned",
                    email: order.getOrderHandler()?.getEmail() || "N/A",
                    phone: order.getOrderHandler()?.getPhone() || "N/A",
                },
                customerName: order.getCustomer().getName(),
                customerPhone: order.getCustomer().getPhone(),
                totalItems: order.getProducts().getData().length,
                total: `${order
                    .getProducts()
                    .getData()[0]
                    .getRegion()
                    .getCurrency()
                    ?.getSymbolNative()} ${Math.ceil(
                    order
                        .getProducts()
                        .getData()
                        .reduce(
                            (acc, nextSubTotal) =>
                                acc +
                                nextSubTotal
                                    .getProductCustomerAssociation()
                                    .getSubTotal(),
                            0,
                        ),
                )}`,
                due: 3333, //TODO: Need to update when available in api response
                createdAt: moment
                    .utc(order.getOrderHandler()?.getCreatedAt())
                    .local()
                    .format("DD/MM/YYYY, h:mm a"),
            };
        });
    }

    getProcessedProducts(orderNumber: string) {
        const targetedOrder = this.data.find(
            (singleOrder) => singleOrder.getOrderNumber() === orderNumber,
        );
        if (targetedOrder) {
            return targetedOrder
                .getProducts()
                .getData()
                .map((singleProduct) => {
                    return {
                        image: singleProduct.getProductImage(),
                        productInfo: {
                            productTitle: {
                                title: singleProduct.getProductTitle(),
                                link: singleProduct.getProductLink(),
                            },
                            buyproductid: singleProduct.getId(),
                            productId: singleProduct.getProductNumber(),
                            orderId: singleProduct.getOrder().getId(),
                            productSeller: {
                                name: singleProduct
                                    .getProductSeller()
                                    .getSellerName(),
                                id: singleProduct
                                    .getProductSeller()
                                    .getSellerId(),
                            },
                            customerInvoiceSummary: singleProduct
                                .getCustomerInvoice()
                                ?.getTransactionSummary(),
                            productStatus: singleProduct.getStatus(),
                            invoice: singleProduct.getInvoice()
                                ? (
                                      singleProduct.getInvoice() as InvoiceModel
                                  ).getStatus()
                                : "N/A",
                            region: singleProduct.getRegion(),
                            paidPercentage: "N/A",
                            store: singleProduct.getStore().getName(),
                            fx: singleProduct
                                .getProductCustomerAssociation()
                                .getFxRate(),
                            commissionRate: singleProduct
                                .getProductCustomerAssociation()
                                .getCommissionRate(),
                            commissionType: singleProduct
                                .getProductCustomerAssociation()
                                .getCommissionRateType(),
                            customerName: singleProduct.getOrder()
                                ? (singleProduct.getOrder() as OrderModel)
                                      .getCustomer()
                                      .getName()
                                : "N/A",
                            orderHandler: singleProduct.getOrder()
                                ? (singleProduct.getOrder() as OrderModel)
                                      .getOrderHandler()
                                      ?.getName()
                                : "N/A",
                        },
                        productMeta: singleProduct
                            .getProductVariations()
                            .getData()
                            .map((singleVariation) => {
                                return {
                                    meta: singleVariation
                                        .getProperties()
                                        .getData(),
                                    quantity: singleVariation.getQuantity(),
                                    price: singleVariation.getOriginalUnitPrice(),
                                    totalPrice: singleVariation.getTotalPrice(),
                                };
                            }),
                        shippingDetails: {
                            measurements: singleProduct.getBuyProductPackage(),
                            shipping: singleProduct.getMoveonShipping(),
                            region: singleProduct.getRegion(),
                            orderHandler: singleProduct.getOrder(),
                        },
                        operation: {
                            productID: singleProduct.getId(),
                            shipmentProduct: singleProduct.getShipmentProduct(),
                        },
                        uniqueKey: singleProduct.getId(),
                    };
                });
        }
    }

    getOrderProducts(orderNumber: string) {
        const targetedOrder = this.data.find(
            (singleOrder) => singleOrder.getOrderNumber() === orderNumber,
        );
        if (targetedOrder) {
            return targetedOrder
                .getProducts()
                .getData()
                .map((singleProduct) => singleProduct);
        }
    }

    static getStatusLabel(value: string) {
        const statusInfo = [
            { label: "Pending", value: "shipment-pending", color: "warning" },
            { label: "Approved", value: "shipment-approved", color: "success" },
            { label: "Rejected", value: "shipment-rejected", color: "error" },
            {
                label: "Assigned agent",
                value: "shipment-assigned-agent",
                color: "processing",
            },
            {
                label: "Shipping started",
                value: "shipment-shipping-started",
                color: "processing",
            },
            {
                label: "Shipping not arrived",
                value: "shipment-not-arrived",
                color: "error",
            },
            {
                label: "Shipping cancelled",
                value: "shipment-shipping-cancelled",
                color: "error",
            },
            {
                label: "Arrived at warehouse",
                value: "shipment-arrived-at-warehouse",
                color: "processing",
            },
            {
                label: "Handover to travel agent",
                value: "shipment-handover-to-travel-agent",
                color: "processing",
            },
            {
                label: "Handover to MoveOn",
                value: "shipment-handover-to-moveon",
                color: "processing",
            },
            {
                label: "Preparing for transport",
                value: "shipment-preparing-for-transport",
                color: "processing",
            },
            {
                label: "Handover to ship",
                value: "shipment-handover-to-ship",
                color: "processing",
            },
            {
                label: "Handover to airline",
                value: "shipment-handover-to-airline",
                color: "processing",
            },
            {
                label: "Arrived at destination port",
                value: "shipment-arrived-at-destination-port",
                color: "processing",
            },
            {
                label: "Arrived at destination airport",
                value: "shipment-arrived-at-destination-airport",
                color: "processing",
            },
            {
                label: "Customs released",
                value: "shipment-customs-released",
                color: "processing",
            },
            {
                label: "Received by MoveOn",
                value: "shipment-received-by-moveon",
                color: "success",
            },
            {
                label: "Delivery requested",
                value: "shipment-delivery-requested",
                color: "success",
            },
            {
                label: "Delivery processing",
                value: "shipment-delivery-processing",
                color: "success",
            },
            {
                label: "Prepared for delivery",
                value: "shipment-prepared-for-delivery",
                color: "success",
            },
            {
                label: "Handover to courier",
                value: "shipment-handover-to-courier",
                color: "success",
            },
            {
                label: "Delivered to customer",
                value: "shipment-delivered-to-customer",
                color: "success",
            },
            {
                label: "Delivery failed",
                value: "shipment-delivery-failed",
                color: "success",
            },
            {
                label: "Delivery returned",
                value: "shipment-delivery-returned",
                color: "success",
            },
        ];
        return statusInfo.find((item) => item.value === value);
    }
}

export class BuyOrderModel {
    object: string;
    id: number;
    order_number: string;
    order_tag: string | null;
    order_type: string;
    order_status: string;
    order_payment_status: string;
    order_handler?: OrderHandlerModel;
    customer: UserModel;
    products: BuyProductCollectionModel;

    constructor(payload: IBuyOrder) {
        this.object = payload.object;
        this.id = payload.id;
        this.order_number = payload.order_number;
        this.customer = new UserModel(payload.customer);
        this.products = new BuyProductCollectionModel(payload.products);
        this.order_tag = payload?.order_tag ? payload?.order_tag : null;
        this.order_type = payload.order_type;
        this.order_status = payload.order_status;
        this.order_payment_status = payload?.order_payment_status;
        this.order_handler = payload.order_handler
            ? new OrderHandlerModel(payload.order_handler)
            : undefined;
    }

    getId() {
        return this.id;
    }

    getOrderNumber() {
        return this.order_number;
    }

    getOrderType() {
        return this.order_type;
    }

    getOrderStatus() {
        return this.order_status;
    }

    getOrderPaymentStatus() {
        return this.order_payment_status;
    }

    getCustomer() {
        return this.customer;
    }

    getOrderHandler() {
        return this.order_handler;
    }

    getProducts() {
        return this.products;
    }
}

export class UserModel {
    object: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    shipping_mark: string;

    constructor(payload: IUser) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.name = payload?.name;
        this.phone = payload?.phone;
        this.email = payload?.email;
        this.shipping_mark = payload?.shipping_mark;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getPhone() {
        return this.phone;
    }

    getEmail() {
        return this.email;
    }

    getShippingMark() {
        return this.shipping_mark;
    }
}

export class CustomerInvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: ITransactionSummary;
    created_at: string;

    constructor(payload: ICustomerInvoice) {
        this.object = payload.object;
        this.id = payload.id;
        this.invoice_number = payload.invoice_number;
        this.status = payload.status;
        this.total_amount = payload.total_amount;
        this.initial_amount = payload.initial_amount;
        this.refundable_amount = payload.refundable_amount;
        this.transaction_summary = payload.transaction_summary;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoice_number;
    }

    getStatus() {
        return this.status;
    }

    getTotalAmount() {
        return this.total_amount;
    }

    getInitialAmount() {
        return this.initial_amount;
    }

    getRefundableAmount() {
        return this.refundable_amount;
    }

    getTransactionSummary() {
        return this.transaction_summary;
    }

    getCreatedAt() {
        return this.created_at;
    }

    calculatePaidPercentage(): number {
        return this.total_amount
            ? (this.transaction_summary.paid / this.total_amount) * 100
            : 0;
    }
}

export class AgentAddressModel {
    city: string;
    state: string;
    postal: string;
    country: string;
    address_1: string;
    address_2: string;

    constructor(payload: Address) {
        this.city = payload.city;
        this.state = payload.state;
        this.postal = payload.postal;
        this.country = payload.country;
        this.address_1 = payload.address_1;
        this.address_2 = payload.address_2;
    }
}

export class RegisteredCountryDetailModel {
    id: number;
    code: string;
    name: string;
    status: string;
    created_at: string;

    constructor(payload: IRegisteredCountryDetail) {
        this.id = payload.id;
        this.code = payload.code;
        this.name = payload.name;
        this.status = payload.status;
        this.created_at = payload.created_at;
    }
}

export class AgentCompanyModel {
    object: string;
    id: number;
    primary_name: string;
    secondary_name: string;
    slug: string;
    operation_status: string;
    verification_status: string;
    registered_country: number;
    registered_country_detail: RegisteredCountryDetailModel;
    company_type: string;
    director_name: string;
    designation: string;
    business_phone: string;
    years_of_experience: string;
    number_of_people_in_team: string;
    address: AgentAddressModel;

    constructor(payload: IAgentCompany) {
        this.object = payload.object;
        this.id = payload.id;
        this.primary_name = payload.primary_name;
        this.secondary_name = payload.secondary_name;
        this.slug = payload.slug;
        this.operation_status = payload.operation_status;
        this.verification_status = payload.verification_status;
        this.registered_country = payload.registered_country;
        this.registered_country_detail = new RegisteredCountryDetailModel(
            payload.registered_country_detail,
        );
        this.company_type = payload.company_type;
        this.director_name = payload.director_name;
        this.designation = payload.designation;
        this.business_phone = payload.business_phone;
        this.years_of_experience = payload.years_of_experience;
        this.number_of_people_in_team = payload.number_of_people_in_team;
        this.address = new AgentAddressModel(payload.address);
    }
}

export class BuyProductAgentAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    agent_company_id: number;
    agent_company: AgentCompanyModel;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;
    created_at: string;

    constructor(payload: IBuyProductAgentAssociation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.agent_company_id = payload.agent_company_id;
        this.agent_company = new AgentCompanyModel(payload.agent_company);
        this.include_tax = payload.include_tax;
        this.sub_total = payload.sub_total;
        this.tax_total = payload.tax_total;
        this.total = payload.total;
        this.original_sub_total = payload.original_sub_total;
        this.original_total = payload.original_total;
        this.discount_total = payload.discount_total;
        this.fx_rate = payload.fx_rate;
        this.commission_rate = payload.commission_rate;
        this.commission_rate_type = payload.commission_rate_type;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getAgentCompany() {
        return this.agent_company;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getOriginalSubTotal() {
        return this.original_sub_total;
    }

    getOriginalTotal() {
        return this.original_total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}


export class BuyProductCollectionModel {
    object: string;
    data: BuyProductModel[];
    pagination?: PaginationCompressedModel;
    filters?: IFilterType;

    constructor(payload: IBuyProductCollection) {
        this.object = payload.object
        this.data = payload.data?.map(
            (buyProduct) => new BuyProductModel(buyProduct),
        );
        this.pagination = payload.pagination
            ? new PaginationCompressedModel(payload.pagination)
            : undefined;
        this.filters = payload.filters;
    }

    getData() {
        return this.data;
    }

    getFilters() {
        return this.filters;
    }

    getProductSeller() {
        const uniqueSellers = new Map();

        this.data.forEach((product) => {
            const seller = product.getProductSeller();
            if (!uniqueSellers.has(seller.getSellerName())) {
                uniqueSellers.set(seller.getSellerName(), {
                    sellerName: seller.getSellerName(),
                    sellerId: seller.getId(),
                });
            }
        });

        return Array.from(uniqueSellers.values());
    }

    filterProductsBySeller(sellerName: string) {
        return this.data.filter(
            (product) =>
                product.getProductSeller().getSellerName() === sellerName,
        );
    }

    getProcessedProducts() {
        return this.data.map((singleProduct: BuyProductModel) => {
            return {
                productId : singleProduct.getId(),
                packages : singleProduct.getShipmentProduct()?.getPackages().getData(),
                totalTrackingCount : singleProduct.getShipmentProduct()?.getTrackingsCount() || 0,
                image: singleProduct.getProductImage(),
                productInfo: {
                    productTitle: {
                        title: singleProduct.getProductTitle(),
                        link: singleProduct.getProductLink(),
                    },
                    productSeller: {
                        name: singleProduct.getProductSeller().getSellerName(),
                        id: singleProduct.getProductSeller().getSellerId(),
                    },
                    buyproductid: singleProduct.getId(),
                    productId: singleProduct.getProductNumber(),
                    orderId: singleProduct.getOrder().getId(),
                    productStatus: singleProduct.getStatus(),
                    buyAction: singleProduct.getBuyAction(),
                    invoice: singleProduct.getInvoice()
                        ? (
                              singleProduct.getInvoice() as InvoiceModel
                          ).getStatus()
                        : "N/A",
                    paidPercentage: singleProduct
                        .getCustomerInvoice()
                        ?.calculatePaidPercentage()
                        .toFixed(2),
                    totalAmount: singleProduct
                        .getCustomerInvoice()
                        ?.getTotalAmount(),
                    customerInvoiceSummary: singleProduct
                        .getCustomerInvoice()
                        ?.getTransactionSummary(),
                    store: singleProduct.getStore().getName(),
                    fx: singleProduct
                        .getProductCustomerAssociation()
                        .getFxRate(),
                    commissionRate: singleProduct
                        .getProductCustomerAssociation()
                        .getCommissionRate(),
                    commissionType: singleProduct
                        .getProductCustomerAssociation()
                        .getCommissionRateType(),
                    totalFxAndCommission: singleProduct
                        .getProductCustomerAssociation()
                        .getTotalCommissionAndFx(),
                    customerName: singleProduct.getOrder()
                        ? (singleProduct.getOrder() as OrderModel)
                              .getCustomer()
                              .getName()
                        : "N/A",
                    orderHandler: singleProduct.getOrder()
                        ? (singleProduct.getOrder() as OrderModel)
                              .getOrderHandler()
                              ?.getName()
                        : "N/A",
                },
                productMeta: singleProduct
                    .getProductVariations()
                    .getData()
                    .map((singleVariation) => {
                        return {
                            meta: singleVariation.getProperties().getData(),
                            quantity: singleVariation.getQuantity(),
                            price: singleVariation.getOriginalUnitPrice(),
                            totalPrice: singleVariation.getTotalPrice(),
                        };
                    }),

                shippingDetails: {
                    measurements: singleProduct.getBuyProductPackage(),
                    shipping: singleProduct.getMoveonShipping(),
                    region: singleProduct.getRegion().getCode(),
                    userId: singleProduct.getOrder().getCustomer().getId(),
                    buyProductId: singleProduct.getId(),
                    agentCompany: {
                        director_name:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.director_name,
                        primary_name:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.primary_name,
                        designation:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.designation,
                        verficationStatus:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.verification_status,
                        registered_country_detail_name:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.registered_country_detail.code,
                        registered_country_detail_status:
                            singleProduct.getProductAgentAssociation()
                                ?.agent_company.registered_country_detail
                                .status,
                        commission_rate_type: singleProduct
                            .getProductAgentAssociation()
                            ?.getCommissionRateType(),
                        commission_rate: singleProduct
                            .getProductAgentAssociation()
                            ?.getCommissionRate(),
                        fx_rate: singleProduct
                            .getProductAgentAssociation()
                            ?.getFxRate(),
                        total: singleProduct
                            .getProductAgentAssociation()
                            ?.getTotal(),
                    },
                },
                operation: {
                    productID: singleProduct.getId(),
                    productDetails: singleProduct,
                    productNumber: singleProduct.getProductNumber(),
                    shipmentProduct: singleProduct.getShipmentProduct(),
                },
                uniqueKey: singleProduct.getId(),
            };
        });
    }
}

export class ActionTypeModel {
    label: string;
    value: string;

    constructor(payload: IActionType) {
        this.label = payload.label;
        this.value = payload.value;
    }
}

export class BuyActionModel {
    object: string;
    id: number;
    action_number: string;
    buy_product_id: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    action_type: ActionTypeModel[];
    created_at: string;

    constructor(payload: IBuyAction) {
        this.object = payload.object;
        this.id = payload.id;
        this.action_number = payload.action_number;
        this.buy_product_id = payload.buy_product_id;
        this.status = payload.status;
        this.admin_note = payload.admin_note;
        this.customer_note = payload.customer_note;
        this.agent_note = payload.agent_note;
        this.action_type = payload.action_type.map(
            (item) => new ActionTypeModel(item),
        );
        this.created_at = payload.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getActionNumber() {
        return this.action_number;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getStatus() {
        return this.status;
    }

    getAdminNote() {
        return this.admin_note;
    }

    getCustomerNote() {
        return this.customer_note;
    }

    getAgentNote() {
        return this.agent_note;
    }

    getActionType() {
        return this.action_type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class BuyActionCollectionModel {
    object: string;
    data: BuyActionModel[];

    constructor(payload: IBuyActionCollection) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new BuyActionModel(item));
    }

    getObject() {
        return this.object;
    }

    getData() {
        return this.data;
    }
}

export class BuyProductModel {
    object: string;
    id: number;
    product_number: string;
    product_id: string;
    product_vid: string;
    cart_item_id: number;
    product_title: string;
    product_image: string;
    product_link: string;
    shipping_provider: string;
    total_quantity: number;
    status: string;
    order: OrderModel;
    shipping_address?: ShippingAddressModel;
    store: StoreModel;
    region: BuyProductRegionModel;
    product_seller: ProductSellerModel;
    product_customer_association: BuyProductCustomerAssociationModel;
    product_agent_association?: BuyProductAgentAssociationModel;
    shipment_product?: ShipmentProductModel;
    buy_product_package: BuyProductPackageModel;
    invoice?: InvoiceModel;
    buy_actions?: BuyActionCollectionModel;
    customer_invoice?: CustomerInvoiceModel;
    moveon_shipping?: MoveonShippingModel;
    product_variations: BuyProductVariationCollectionModel;
    created_at: string;

    constructor(payload: IBuyProduct) {
        this.object = payload.object;
        this.id = payload.id;
        this.product_number = payload.product_number;
        this.product_id = payload.product_id;
        this.product_vid = payload?.product_vid;
        this.cart_item_id = payload?.cart_item_id;
        this.product_title = payload?.product_title;
        this.product_image = payload?.product_image;
        this.product_link = payload?.product_link;
        this.shipping_provider = payload?.shipping_provider;
        this.total_quantity = payload?.total_quantity;
        this.customer_invoice = payload.customer_invoice
            ? new CustomerInvoiceModel(payload.customer_invoice)
            : undefined;
        this.status = payload?.status;
        this.order = new OrderModel(payload?.order);
        this.shipping_address = payload.shipping_address
            ? new ShippingAddressModel(payload.shipping_address)
            : undefined;
        this.store = new StoreModel(payload.store);
        this.region = new BuyProductRegionModel(payload?.region);
        this.product_seller = new ProductSellerModel(payload?.product_seller);
        this.product_customer_association =
            new BuyProductCustomerAssociationModel(
                payload?.product_customer_association,
            );
        this.product_agent_association = payload.product_agent_association
            ? new BuyProductAgentAssociationModel(
                  payload.product_agent_association,
              )
            : undefined;
        this.shipment_product = payload.shipment_product
            ? new ShipmentProductModel(payload.shipment_product)
            : undefined;
        this.buy_product_package = new BuyProductPackageModel(
            payload?.buy_product_package,
        );
        this.invoice = payload?.invoice
            ? new InvoiceModel(payload?.invoice)
            : undefined;
        this.moveon_shipping =
            payload.moveon_shipping &&
            new MoveonShippingModel(payload.moveon_shipping);
        this.product_variations = new BuyProductVariationCollectionModel(
            payload?.product_variations,
        );

        this.buy_actions = payload.buy_actions
            ? new BuyActionCollectionModel(payload?.buy_actions)
            : undefined;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getProductNumber() {
        return this.product_number;
    }

    getBuyAction() {
        return this.buy_actions;
    }

    getProductId() {
        return this.product_id;
    }

    getProductVId() {
        return this.product_vid;
    }

    getCartItemId() {
        return this.cart_item_id;
    }

    getProductTitle() {
        return this.product_title;
    }

    getProductImage() {
        return this.product_image;
    }

    getProductLink() {
        return this.product_link;
    }

    getShippingProvider() {
        return this.shipping_provider;
    }

    getTotalQuantity() {
        return this.total_quantity;
    }

    getStatus() {
        return this.status;
    }

    getOrder() {
        return this.order;
    }

    getShippingAddress() {
        return this.shipping_address;
    }

    getStore() {
        return this.store;
    }

    getRegion() {
        return this.region;
    }

    getProductSeller() {
        return this.product_seller;
    }

    getProductCustomerAssociation() {
        return this.product_customer_association;
    }

    getProductAgentAssociation() {
        return this.product_agent_association;
    }

    getShipmentProduct() {
        return this.shipment_product;
    }

    getBuyProductPackage() {
        return this.buy_product_package;
    }

    getInvoice() {
        return this.invoice;
    }

    getMoveonShipping() {
        return this.moveon_shipping;
    }

    getProductVariations() {
        return this.product_variations;
    }

    getCreatedAt() {
        return this.created_at;
    }
    getProductMeta() {
        return this.getProductVariations()
            .getData()
            .map((singleVariation) => {
                return {
                    meta: singleVariation.getProperties().getData(),
                    quantity: singleVariation.getQuantity(),
                    price: singleVariation.getOriginalUnitPrice(),
                    totalPrice: singleVariation.getTotalPrice(),
                };
            });
    }
    getCustomerInvoice() {
        return this.customer_invoice;
    }
}

export class ShippingAddressModel {
    id: number;
    address: AddressModel;
    is_default_shipping: number;
    is_default_billing: number;
    constructor(payload: IShippingAddress) {
        this.id = payload.id;
        this.address = payload.address && new AddressModel(payload.address);
        this.is_default_shipping = payload.is_default_shipping;
        this.is_default_billing = payload.is_default_billing;
    }

    getId() {
        return this.id;
    }

    getAddress() {
        return this.address;
    }

    getIsDefaultShipping() {
        return this.is_default_shipping;
    }

    getIsDefaultBilling() {
        return this.is_default_billing;
    }
}

export class LabelsModel {
    object: string;
    data: LabelDataModel[];

    constructor(payload: ILabels) {
        this.object = payload.object;
        this.data = payload.data.map(
            (labelData) => new LabelDataModel(labelData),
        );
    }

    getObject() {
        return this.object;
    }

    getData() {
        return this.data;
    }
}
export class LabelDataModel {
    object: string;
    id: number;
    label: string;

    constructor(payload: ILabelData) {
        this.object = payload.object;
        this.id = payload.id;
        this.label = payload.label;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getLabel() {
        return this.label;
    }
}
export class AddressModel {
    area: string;
    email: string;
    phone: string;
    country: string;
    district: string;
    address_1: string;
    address_2: string;
    last_name: string;
    first_name: string;
    postal_code: string;

    constructor(payload: IAddress) {
        this.area = payload?.area;
        this.email = payload.email;
        this.phone = payload.phone;
        this.country = payload.country;
        this.district = payload.district;
        this.address_1 = payload.address_1;
        this.address_2 = payload.address_2;
        this.last_name = payload.last_name;
        this.first_name = payload.first_name;
        this.postal_code = payload.postal_code;
    }

    getArea() {
        return this.area;
    }

    getEmail() {
        return this.email;
    }

    getPhone() {
        return this.phone;
    }

    getCountry() {
        return this.country;
    }

    getDistrict() {
        return this.district;
    }

    getAddress1() {
        return this.address_1;
    }

    getAddress2() {
        return this.address_2;
    }

    getLastName() {
        return this.last_name;
    }

    getFirstName() {
        return this.first_name;
    }

    getPostalCode() {
        return this.postal_code;
    }
}

export class ProductAgentAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    agent_company_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;
    purchase_note?: any;
    vendor_order_id?: any;
    created_at: string;

    constructor(payload: IBuyProductAgentAssociation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.agent_company_id = payload.agent_company_id;
        this.include_tax = payload.include_tax;
        this.sub_total = payload.sub_total;
        this.tax_total = payload.tax_total;
        this.total = payload.total;
        this.original_sub_total = payload.original_sub_total;
        this.original_total = payload.original_total;
        this.discount_total = payload.discount_total;
        this.fx_rate = payload.fx_rate;
        this.commission_rate = payload.commission_rate;
        this.commission_rate_type = payload.commission_rate_type;
        this.created_at = payload.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getOriginalSubTotal() {
        return this.original_sub_total;
    }

    getOriginalTotal() {
        return this.original_total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getPurchaseNote() {
        return this.purchase_note;
    }

    getVendorOrderId() {
        return this.vendor_order_id;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class ShipmentProductShippingAddressModel {
    area: string;
    email: string;
    phone: string;
    country: string;
    district: string;
    address_1: string;
    address_2: string;
    last_name: string;
    first_name: string;
    postal_code: string;

    constructor(payload: IShippingAddress) {
        this.area = payload.address.area;
        this.email = payload.address.email;
        this.phone = payload.address.phone;
        this.country = payload.address.country;
        this.district = payload.address.district;
        this.address_1 = payload.address.address_1;
        this.address_2 = payload.address.address_2;
        this.last_name = payload.address.last_name;
        this.first_name = payload.address.first_name;
        this.postal_code = payload.address.postal_code;
    }

    getArea() {
        return this.area;
    }

    getEmail() {
        return this.email;
    }

    getPhone() {
        return this.phone;
    }

    getCountry() {
        return this.country;
    }

    getDistrict() {
        return this.district;
    }

    getAddress1() {
        return this.address_1;
    }

    getAddress2() {
        return this.address_2;
    }

    getLastName() {
        return this.last_name;
    }

    getFirstName() {
        return this.first_name;
    }

    getPostalCode() {
        return this.postal_code;
    }
}

export class InvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    total: number;
    status: string;

    constructor(payload: IInvoice) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.invoice_number = payload?.invoice_number;
        this.total = payload?.total;
        this.status = payload?.status;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoice_number;
    }

    getTotal() {
        return this.total;
    }

    getStatus() {
        return this.status;
    }
}

export class OrderModel {
    id: number;
    order_number: string;
    order_handler?: OrderHandlerModel;
    type: string;
    status: string;
    payment_status: string;
    customer: UserModel;
    created_at: string;
    oauth_client_id: null | string;
    addresses: { [key: string]: string }[];
    meta: any[];

    constructor(data: IOrder) {
        this.id = data.id;
        this.order_number = data.order_number;
        this.order_handler = data.order_handler
            ? new OrderHandlerModel(data.order_handler)
            : undefined;
        this.customer = new UserModel(data.customer);
        this.type = data.type;
        this.status = data.status;
        this.payment_status = data.payment_status;
        this.created_at = data.created_at;
        this.oauth_client_id = data.oauth_client_id;
        this.addresses = data.addresses;
        this.meta = data.meta;
    }
    getId() {
        return this.id;
    }

    getOrderNumber() {
        return this.order_number;
    }

    getCustomer() {
        return this.customer;
    }

    getOrderHandler() {
        return this.order_handler;
    }
}

export class OrderHandlerModel {
    object: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    email_verified_at?: string;
    type: string;
    is_drop_user: boolean;
    order_handler?: string;
    billing_addr_id?: string;
    shipping_addr_id?: string;
    agent_company_id?: string;
    shipping_mark: string;
    is_active: number;
    created_at: string;
    updated_at: string;
    lock_version?: string;
    etag?: string;
    gravatar: string;

    constructor(payload: IOrderHandler) {
        this.object = payload.object;
        this.id = payload.id;
        this.name = payload.name;
        this.phone = payload.phone;
        this.email = payload.email;
        this.email_verified_at = payload.email_verified_at;
        this.type = payload.type;
        this.is_drop_user = payload.is_drop_user;
        this.order_handler = payload.order_handler;
        this.billing_addr_id = payload.billing_addr_id;
        this.shipping_addr_id = payload.shipping_addr_id;
        this.agent_company_id = payload.agent_company_id;
        this.shipping_mark = payload.shipping_mark;
        this.is_active = payload.is_active;
        this.created_at = payload.created_at;
        this.updated_at = payload.updated_at;
        this.lock_version = payload.lock_version;
        this.etag = payload.etag;
        this.gravatar = payload.gravatar;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getPhone() {
        return this.phone;
    }

    getEmail() {
        return this.email;
    }

    getEmailVerifiedAt() {
        return this.email_verified_at;
    }

    getType() {
        return this.type;
    }

    getIsDropUser() {
        return this.is_drop_user;
    }

    getOrderHandler() {
        return this.order_handler;
    }

    getBillingAddressId() {
        return this.billing_addr_id;
    }

    getShippingAddressId() {
        return this.shipping_addr_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getShippingMark() {
        return this.shipping_mark;
    }

    getIsActive() {
        return this.is_active;
    }

    getCreatedAt() {
        return this.created_at;
    }

    getUpdatedAt() {
        return this.updated_at;
    }

    getLockVersion() {
        return this.lock_version;
    }

    getEtag() {
        return this.etag;
    }

    getGravatar() {
        return this.gravatar;
    }
}

export class BuyProductRegionModel {
    public object: string;
    public id: number;
    public name: string;
    public code: string;
    public store_type: string;
    public store: string;
    public currency?: BuyOrderCurrencyModel;

    constructor(region: IBuyProductRegion) {
        this.object = region.object;
        this.id = region.id;
        this.name = region.name;
        this.code = region.code;
        this.store_type = region.store_type;
        this.store = region.store;
        this.currency = region.currency
            ? new BuyOrderCurrencyModel(region.currency)
            : undefined;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getCode() {
        return this.code;
    }

    getStoreType() {
        return this.store_type;
    }

    getStore() {
        return this.store;
    }

    getCurrency() {
        return this.currency;
    }
}

export class BuyOrderCurrencyModel {
    object: string;
    id: number;
    code: string;
    name: string;
    symbol: string;
    symbol_native: string;

    constructor(data: IBuyOrderCurrency) {
        this.object = data?.object;
        this.id = data?.id;
        this.code = data?.code;
        this.symbol = data?.symbol;
        this.symbol_native = data?.symbol_native;
        this.name = data?.name;
    }

    getId() {
        return this.id;
    }

    getCode() {
        return this.code;
    }

    getSymbol() {
        return this.symbol;
    }

    getSymbolNative() {
        return this.symbol_native;
    }

    getName() {
        return this.name;
    }
}

export class ProductSellerModel {
    object: string;
    id: number;
    seller_id: string;
    seller_name: string;

    constructor(payload: IProductSeller) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.seller_id = payload?.seller_id;
        this.seller_name = payload?.seller_name;
    }

    getId() {
        return this.id;
    }

    getSellerId() {
        return this.seller_id;
    }

    getSellerName() {
        return this.seller_name;
    }
}

export class BuyProductCustomerAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;

    constructor(payload: IBuyProductCustomerAssociation) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.include_tax = payload?.include_tax;
        this.sub_total = payload?.sub_total;
        this.tax_total = payload?.tax_total;
        this.total = payload?.total;
        this.original_sub_total = payload?.original_sub_total;
        this.original_total = payload?.original_total;
        this.discount_total = payload?.discount_total;
        this.fx_rate = payload?.fx_rate;
        this.commission_rate = payload?.commission_rate;
        this.commission_rate_type = payload?.commission_rate_type;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getTotalCommissionAndFx = (): string => {
        let total: number = 0;

        if (this.commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
            total = this.fx_rate + (this.fx_rate * this.commission_rate) / 100;
        } else if (
            this.commission_rate_type === CommissionRateTypeEnums.FIXED
        ) {
            total = this.fx_rate + this.commission_rate;
        }
        return total.toFixed(2);
    };
}

export class BuyProductPackageModel {
    object: string;
    id: number;
    buy_product_id: number;
    weight: number;
    weight_unit: string;
    length: number;
    length_unit: string;
    width: number;
    width_unit: string;
    height: number;
    height_unit: string;

    constructor(payload: IBuyProductPackage) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.weight = payload?.weight;
        this.weight_unit = payload?.weight_unit;
        this.length = payload?.length;
        this.length_unit = payload?.length_unit;
        this.width = payload?.width;
        this.width_unit = payload?.width_unit;
        this.height = payload?.height;
        this.height_unit = payload?.height_unit;
    }

    getId() {
        return this.object;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getWeight() {
        return this.weight;
    }

    getWeightUnit() {
        return this.weight_unit;
    }

    getLength() {
        return this.length;
    }

    getLengthUnit() {
        return this.length_unit;
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    getHeightUnit() {
        return this.height_unit;
    }
}

export class ShippingCountryModel {
    public readonly object: string;
    public readonly id: number;
    public readonly status: string;
    public readonly created_at: string;

    constructor(data: IShippingCountry) {
        this.object = data.object;
        this.id = data.id;
        this.status = data.status;
        this.created_at = data.created_at;
    }

    getById() {
        return this.id;
    }
}

export class MoveonShippingModel {
    object: string;
    id: number;
    agent_category_price_read_id: number;
    agent_warehouse: AgentWarehouseModel;
    shipping_category: ShippingCategoryModel;
    shipping_country: ShippingCountryModel;
    destination_warehouse: DestinationWarehouseModel;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    slots: ShippingSlotCollectionModel;

    constructor(payload: IMoveonShipping) {
        this.object = payload.object;
        this.id = payload.id;
        this.agent_category_price_read_id =
            payload.agent_category_price_read_id;
        this.agent_warehouse = new AgentWarehouseModel(payload.agent_warehouse);
        this.shipping_country = new ShippingCountryModel(
            payload.shipping_country,
        );
        this.shipping_category = payload.shipping_category && new ShippingCategoryModel(
            payload.shipping_category,
        ) ;
        this.destination_warehouse = new DestinationWarehouseModel(
            payload.destination_warehouse,
        );
        this.shipping_type = payload.shipping_type;
        this.shipping_mode = payload.shipping_mode;
        this.unit_type = payload.unit_type;
        this.rate = payload.rate;
        this.slots = new ShippingSlotCollectionModel(payload.slots);
    }

    getId() {
        return this.id;
    }

    getShippingCountry() {
        return this.shipping_country;
    }

    getAgentCategoryPriceReadId() {
        return this.agent_category_price_read_id;
    }

    getAgentWarehouse() {
        return this.agent_warehouse;
    }

    getShippingCategory() {
        return this.shipping_category;
    }

    getDestinationWarehouse() {
        return this.destination_warehouse;
    }

    getShippingType() {
        return this.shipping_type;
    }

    getShippingMode() {
        return this.shipping_mode;
    }

    getUnitType() {
        return this.unit_type;
    }

    getRate() {
        return this.rate;
    }

    getSlots() {
        return this.slots.data;
    }
}

export class AgentWarehouseModel {
    object: string;
    id: number;
    name: string;

    constructor(payload: IAgentWarehouse) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.name = payload?.name;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }
}

export class ShippingCategoryModel {
    public object: string;
    public id: string;
    public parent_id: string | null;
    public name: string;
    public slug: string;
    public children?: ShippingCategoryModel[];

    constructor(data: IShippingCategory) {
        this.object = data?.object;
        this.id = data?.id;
        this.parent_id = data?.parent_id;
        this.name = data?.name;
        this.slug = data?.slug;
        // this.children = data?.children
        //     ? data?.children.map(
        //           (shippingCategory: IShippingCategory) =>
        //               new ShippingCategoryModel(shippingCategory),
        //       )
        //     : undefined;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getSlug() {
        return this.slug;
    }
}

export class DestinationWarehouseModel {
    object: string;
    id: number;
    slug: string;
    name: string;

    constructor(payload: IDestinationWarehouse) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.slug = payload?.slug;
        this.name = payload?.name;
    }

    getById() {
        return this.id;
    }

    getSlug() {
        return this.slug;
    }

    getName() {
        return this.name;
    }
}

export class ShippingSlotCollectionModel {
    object: string;
    data: ShippingSlotModel[];

    constructor(payload: IShippingSlotCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (singleData) => new ShippingSlotModel(singleData),
        );
    }
}

export class ShippingSlotModel {
    object: string;
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;

    constructor(payload: IShippingSlot) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.min_amount = payload?.min_amount;
        this.max_amount = payload?.max_amount;
        this.rate = payload?.rate;
    }

    getId() {
        return this.id;
    }

    getMinAmount() {
        return this.min_amount;
    }

    getMaxAmount() {
        return this.max_amount;
    }

    getRate() {
        return this.rate;
    }
}

export class BuyProductVariationCollectionModel {
    object: string;
    data: BuyProductVariationModel[];

    constructor(payload: IBuyProductVariationCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (buyProductVariation) =>
                new BuyProductVariationModel(buyProductVariation),
        );
    }

    getData() {
        return this.data;
    }
}

export class BuyProductVariationModel {
    object: string;
    id: number;
    buy_product_id: number;
    cart_item_variation_id: number;
    sku_id: string;
    image: string;
    quantity: number;
    unit_price: number;
    total_price: number;
    original_unit_price: number;
    original_total_price: number;
    properties: BuyProductVariationPropertiesCollectionModel;

    constructor(payload: IBuyProductVariation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.cart_item_variation_id = payload.cart_item_variation_id;
        this.sku_id = payload.sku_id;
        this.image = payload.image;
        this.quantity = payload.quantity;
        this.unit_price = payload.unit_price;
        this.original_unit_price = payload.original_unit_price;
        this.original_total_price = payload.original_total_price;
        this.total_price = payload.total_price;
        this.properties = new BuyProductVariationPropertiesCollectionModel(
            payload?.properties,
        );
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getCartItemVariationId() {
        return this.cart_item_variation_id;
    }

    getSkuId() {
        return this.sku_id;
    }

    getImage() {
        return this.image;
    }

    getQuantity() {
        return this.quantity;
    }

    getUnitPrice() {
        return this.unit_price;
    }

    getTotalPrice() {
        return this.total_price;
    }

    getOriginalUnitPrice() {
        return this.original_unit_price;
    }

    getOriginalTotalPrice() {
        return this.original_total_price;
    }

    getProperties() {
        return this.properties;
    }
}

export class BuyProductVariationPropertiesCollectionModel {
    object: string;
    data: BuyProductVariationPropertyModel[];

    constructor(payload: IBuyProductVariationPropertiesCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (buyProductVariationProperty) =>
                new BuyProductVariationPropertyModel(
                    buyProductVariationProperty,
                ),
        );
    }

    getData() {
        return this.data;
    }
}

export class BuyProductVariationPropertyModel {
    object: string;
    id: number;
    property_id: string;
    property_name: string;
    property_original_name: string;
    property_value_id: string;
    property_value_name: string;
    property_value_color: string | null;
    property_value_title: string;
    property_value_image: string;
    property_value_thumb: string;

    constructor(payload: IBuyProductVariationProperty) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.property_id = payload?.property_id;
        this.property_name = payload?.property_name;
        this.property_original_name = payload?.property_original_name;
        this.property_value_id = payload?.property_value_id;
        this.property_value_name = payload?.property_value_name;
        this.property_value_color = payload?.property_value_color;
        this.property_value_title = payload?.property_value_title;
        this.property_value_image = payload?.property_value_image;
        this.property_value_thumb = payload?.property_value_thumb;
    }

    getId() {
        return this.id;
    }

    getPropertyId() {
        return this.property_id;
    }

    getPropertyName() {
        return this.property_name;
    }

    getPropertyOriginalName() {
        return this.property_original_name;
    }

    getPropertyValueId() {
        return this.property_value_id;
    }

    getPropertyValueName() {
        return this.property_value_name;
    }

    getPropertyValueColor() {
        return this.property_value_color;
    }

    getPropertyValueTitle() {
        return this.property_value_title;
    }

    getPropertyValueImage() {
        return this.property_value_image;
    }

    getPropertyValueThumb() {
        return this.property_value_thumb;
    }
}

export class OrderFiltersPropertyValueOptionsModel {
    public readonly label: string;
    public readonly value: string;
    public readonly group?: string;

    constructor(data: IOrderFiltersPropertyValueOptions) {
        this.label = data.label;
        this.value = data.value;
        this.group = data?.group;
    }
}

export class OrderFiltersPropertyValueModel {
    public readonly type: string;
    public readonly placeholder: string;
    public readonly label: string;
    public readonly values?: OrderFiltersPropertyValueOptionsModel[];
    public readonly data?: string;
    public readonly resource?: string;

    constructor(data: IOrderFiltersPropertyValue) {
        this.type = data.type;
        this.placeholder = data.placeholder;
        this.label = data.label;
        this.values = data?.values
            ? data.values.map(
                  (item) => new OrderFiltersPropertyValueOptionsModel(item),
              )
            : undefined;
        this.data = data?.data;
        this.resource = data?.resource;
    }
}

export class OrderFiltersModel {
    public readonly keyword: OrderFiltersPropertyValueModel;
    public readonly product_status: OrderFiltersPropertyValueModel;
    public readonly payment_status: OrderFiltersPropertyValueModel;
    public readonly shipping_country: OrderFiltersPropertyValueModel;
    public readonly order_amount: OrderFiltersPropertyValueModel;
    // public readonly group: OrderFiltersPropertyValueModel;
    public readonly seller_id: OrderFiltersPropertyValueModel;

    constructor(data: IOrderFilters) {
        this.keyword = new OrderFiltersPropertyValueModel(data.keyword);
        this.product_status = new OrderFiltersPropertyValueModel(
            data.product_status,
        );
        this.payment_status = new OrderFiltersPropertyValueModel(
            data.payment_status,
        );
        this.shipping_country = new OrderFiltersPropertyValueModel(
            data.shipping_country,
        );
        this.order_amount = new OrderFiltersPropertyValueModel(
            data.order_amount,
        );
        // this.group = new OrderFiltersPropertyValueModel(data.group);
        this.seller_id = new OrderFiltersPropertyValueModel(data.seller_id);
    }
}

export class PaginationCompressedModel {
    public current_page: number;
    public from: number;
    public last_page: number;
    public per_page: number;
    public to: number;
    public total: number;

    constructor(data: IPagination) {
        this.current_page = data.current_page;
        this.from = data.from;
        this.last_page = data.last_page;
        this.per_page = data.per_page;
        this.to = data.to;
        this.total = data.total;
    }

    getCurrentPage() {
        return this.current_page;
    }

    getFrom() {
        return this.from;
    }

    getLastPage() {
        return this.last_page;
    }

    getPerPage() {
        return this.per_page;
    }

    getTo() {
        return this.to;
    }

    getTotalData() {
        return this.total;
    }
}

export class VariantModel {
    variant_id: number;
    new_original_unit_price?: number;
    new_quantity?: number;
    suggested_sku_id?: string;

    constructor(data: IVariant) {
        this.variant_id = data.variant_id;
        this.new_original_unit_price = data.new_original_unit_price;
        this.new_quantity = data.new_quantity;
        this.suggested_sku_id = data.suggested_sku_id;
    }
}

export class SequenceModel {
    action_type: TakeActionsEnums;
    variants?: VariantModel[];
    addon_ids?: any[];
    images?: any[];
    new_shipping_category_id?: string;
    original_amount?: number;
    new_fx?: number;
    minimum_order_quantity?: number;
    new_rate?: number;
    new_agent_category_price_read?: number;
    slots?: SequenceSlot[];
    admin_note: string;

    constructor(data: ISequence) {
        this.action_type = data.action_type;
        this.variants = data.variants?.map(
            (variant) => new VariantModel(variant),
        );
        this.addon_ids = data.addon_ids;
        this.images = data.images;
        this.new_shipping_category_id = data.new_shipping_category_id;
        this.minimum_order_quantity = data?.minimum_order_quantity;
        this.original_amount = data.original_amount;
        this.new_fx = data.new_fx;
        this.new_rate = data.new_rate;
        this.slots = data.slots;
        this.admin_note = data.admin_note;
    }
}

export class SequencesDataModel {
    sequences: SequenceModel[];
    admin_note: string;

    constructor(data: ISequencesData) {
        this.sequences = data.sequences.map(
            (sequence) => new SequenceModel(sequence),
        );
        this.admin_note = data.admin_note;
    }
}
