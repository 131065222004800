import { Button, Col, Dropdown, Form, Menu, message, Row, Space } from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  DownOutlined,
  UpOutlined,
  HddFilled,
  FilterOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
  download_table_as_csv,
  exportToCsv,
  formatDateTime,
} from "utils/helpers";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { IFilterType } from "types/filters";
import CustomElement from "components/CustomFormElement/CustomElement";

interface IFiltersComponentProps {
  handleProductFilter: () => void;
  handleFilterChange: (fields: IFilterType) => void;
  handelFilterClear: () => void;
  isFetched: boolean;
  filters: IFilterType | null | undefined;
  filtersData: IFilterType | null | undefined;
  isFromProductReceived?: boolean;
  extra?: any;
  excelTitle?: [] | undefined | null;
  excelValue?: [] | undefined | null;
  title?: string;
  handleShipmentExport?: (type: string) => void;
  isShipmentProduct?: boolean;
  isDeliveryRequest?: boolean;
  handleDeliveryExport?: (type: string) => void;
  isExcelExportNeeded?: boolean;
  filterSize?: string;
}
const FiltersComponent = (props: IFiltersComponentProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState<boolean>(true);

  const {
    handleProductFilter,
    handleFilterChange,
    handelFilterClear,
    isFetched,
    filters,
    filtersData,
    extra,
    excelTitle,
    excelValue,
    isFromProductReceived,
    title,
    handleShipmentExport,
    isShipmentProduct,
    isDeliveryRequest,
    handleDeliveryExport,
    isExcelExportNeeded,
    filterSize,
  } = props;

  if (!filters || !filtersData) {
    return null;
  }

  let filterData = Object.keys(filtersData);

  if (filterData?.length > 8) {
    filterData = filterData.slice(0, 8);

    if (expand) {
      filterData = Object.keys(filtersData);
    } else if (!expand) {
      filterData = filterData.slice(0, 8);
    }
  }

  const handleExport = (value: string) => {
    let today = new Date();
    if (value === "excel" && excelValue && excelTitle) {
      exportToCsv(
        `${title ? title : "Export"}-${formatDateTime(today)}.csv`,
        excelTitle.concat(excelValue),
      );
    } else {
      message.warning("No data to export, Please contact with IT department");
    }
  };

  return (
    <>
      <Button
        icon={<FilterOutlined />}
        type="primary"
        onClick={() => setFilterVisible(!filterVisible)}
        style={{ marginBottom: 16 }}
      >
        {filterVisible ? "Hide Filter" : "Show Filter"}
      </Button>
      {filterVisible && (
        <Form
          onFinish={handleProductFilter}
          labelCol={{ span: 12 }}
          layout="vertical"
          size={filterSize ? (filterSize as SizeType) : "middle"}
        >
          <Row gutter={24}>
            {isFetched &&
              Object.keys(filters).length > 0 &&
              filterData?.map((el, idx) => {
                const fieldElement = filtersData[el];
                const fieldProps = {
                  type: fieldElement.type,
                  options: fieldElement.values,
                  handleChange: handleFilterChange,
                  name: el,
                  label: fieldElement.label,
                  placeholder: fieldElement?.placeholder
                    ? fieldElement.placeholder
                    : null,
                  fieldValue: filters[el],
                  resource: fieldElement?.resource
                    ? fieldElement.resource
                    : null,
                  data: fieldElement?.data ? fieldElement.data : null,
                };

                return <CustomElement key={idx} {...fieldProps} />;
              })}
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Space>
                  {Object.keys(filtersData).length > 8 && (
                    <>
                      {!expand ? (
                        <Button
                          type="primary"
                          icon={<DownOutlined />}
                          onClick={() => {
                            setExpand(true);
                          }}
                        >
                          Expend
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          icon={<UpOutlined />}
                          onClick={() => {
                            setExpand(false);
                          }}
                        >
                          Collapse
                        </Button>
                      )}
                    </>
                  )}
                  {!isFromProductReceived && (
                    <Dropdown
                      overlay={
                        <Menu>
                          {isExcelExportNeeded && (
                            <Menu.Item
                              key={1}
                              onClick={() => handleExport("excel")}
                            >
                              Customize CSV
                            </Menu.Item>
                          )}
                          {isExcelExportNeeded && (
                            <Menu.Item
                              key={2}
                              onClick={() =>
                                download_table_as_csv("moveOn_table_to_export")
                              }
                            >
                              Full Table CSV
                            </Menu.Item>
                          )}
                          {isShipmentProduct && (
                            <Menu.Item
                              onClick={() =>
                                handleShipmentExport &&
                                handleShipmentExport("xlsx")
                              }
                            >
                              Excel
                            </Menu.Item>
                          )}
                          {isDeliveryRequest && (
                            <Menu.Item
                              onClick={() =>
                                handleDeliveryExport &&
                                handleDeliveryExport("xlsx")
                              }
                            >
                              Excel
                            </Menu.Item>
                          )}
                          {isShipmentProduct && (
                            <Menu.Item
                              onClick={() =>
                                handleShipmentExport &&
                                handleShipmentExport("csv")
                              }
                            >
                              CSV
                            </Menu.Item>
                          )}
                          {isShipmentProduct && (
                            <Menu.Item
                              onClick={() =>
                                handleShipmentExport &&
                                handleShipmentExport("pdf")
                              }
                            >
                              PDF
                            </Menu.Item>
                          )}
                          {isDeliveryRequest && (
                            <Menu.Item
                              onClick={() =>
                                handleDeliveryExport &&
                                handleDeliveryExport("pdf")
                              }
                            >
                              PDF
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      placement="bottomLeft"
                    >
                      <Button
                        type={"primary"}
                        style={{ marginLeft: 5 }}
                        icon={<HddFilled />}
                      >
                        Export <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                  {isShipmentProduct && (
                    <Button
                      onClick={() =>
                        handleShipmentExport &&
                        handleShipmentExport("packing-list")
                      }
                      type="dashed"
                    >
                      Packing List
                    </Button>
                  )}
                  {extra && (
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      icon={<DownloadOutlined />}
                    >
                      EXCEL
                    </Button>
                  )}
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      handelFilterClear();
                    }}
                    type="dashed"
                  >
                    Clear
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType={"submit"}
                    icon={<SearchOutlined />}
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default FiltersComponent;
