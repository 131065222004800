import { PaginationModel } from "./paginationModel";
import {
  DiscountAmountTypeEnum,
  DiscountCollectionEnum,
  DiscountLimitationTypeEnum,
  DiscountStatusEnum,
  DiscountUsageHistoryCollectionEnum,
} from "enums/discountCollectionEnum";
import { IFilterType } from "types/filters";
import {
  IDiscountUsageHistory,
  IDiscountUsageHistoryCollection,
  IDiscountUsageHistoryDiscount,
  IDiscountUsageHistoryDiscountable,
  IDiscountUsageHistoryRegion,
  IDiscountUsageHistoryUser,
} from "types/discountUsageHistoryCollection";

export class DiscountUsageHistoryDiscountableModel {
  id: number;
  invoice_number: string;
  user_id: number;
  region_id: number;
  order_id: number;
  ownable_id: number;
  ownable_type: string;
  total_amount: string;
  initial_amount: string;
  refundable_amount: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;

  constructor(data: IDiscountUsageHistoryDiscountable) {
    this.id = data.id;
    this.invoice_number = data.invoice_number;
    this.user_id = data.user_id;
    this.region_id = data.region_id;
    this.order_id = data.order_id;
    this.ownable_id = data.ownable_id;
    this.ownable_type = data.ownable_type;
    this.total_amount = data.total_amount;
    this.initial_amount = data.initial_amount;
    this.refundable_amount = data.refundable_amount;
    this.status = data.status;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.deleted_at = data.deleted_at;
  }

  getId = () => this.id;
  getUserId = () => this.user_id;
  getRegionId = () => this.region_id;
  getOrderId = () => this.order_id;
  getOwnableId = () => this.ownable_id;
  getOwnableType = () => this.ownable_type;
  getTotalAmount = () => this.total_amount;
  getInitialAmount = () => this.initial_amount;
  getRefundableAmount = () => this.refundable_amount;
  getStatus = () => this.status;
  getCreatedAt = () => this.created_at;
  getUpdatedAt = () => this.updated_at;
  getDeletedAt = () => this.deleted_at;
}

export class DiscountUsageHistoryUserModel {
  object: string;
  id: number;
  name: string;
  phone: string | null;
  email: string;

  constructor(data: IDiscountUsageHistoryUser) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
  }

  getId = () => this.id;
  getName = () => this.name;
  getPhone = () => this.phone;
  getEmail = () => this.email;
}

export class DiscountUsageHistoryDiscountModel {
  object: DiscountCollectionEnum.Discount;
  id: number;
  name: string;
  amount_type: DiscountAmountTypeEnum;
  amount: number;
  maximum_discount_amount: number;
  start_date: string;
  end_date: string;
  limitation_type: DiscountLimitationTypeEnum;
  limitation_times: number;
  coupon_code: string;
  status: DiscountStatusEnum;
  created_at: string;

  constructor(dataItem: IDiscountUsageHistoryDiscount) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.amount_type = dataItem.amount_type;
    this.amount = dataItem.amount;
    this.maximum_discount_amount = dataItem.maximum_discount_amount;
    this.start_date = dataItem.start_date;
    this.end_date = dataItem.end_date;
    this.limitation_type = dataItem.limitation_type;
    this.limitation_times = dataItem.limitation_times;
    this.coupon_code = dataItem.coupon_code;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getName = () => this.name;
  getAmountType = () => this.amount_type;
  getAmount = () => this.amount;
  getMaximumDiscountAmount = () => this.maximum_discount_amount;
  getStartDate = () => this.start_date;
  getEndDate = () => this.end_date;
  getLimitationType = () => this.limitation_type;
  getLimitationTimes = () => this.limitation_times;
  getCouponCode = () => this.coupon_code;
  getStatus = () => this.status;
  getCreatedAt = () => this.created_at;
}

export class DiscountUsageHistoryRegionModel {
  object: string;
  id: number;
  name: string;
  code: string;
  constructor(data: IDiscountUsageHistoryRegion) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
  }

  getId = () => this.id;
  getName = () => this.name;
  getCode = () => this.code;
}
export class DiscountUsageHistoryModel {
  object: DiscountUsageHistoryCollectionEnum.DiscountUsageHistory;
  id: number;
  region: DiscountUsageHistoryRegionModel;
  discount: DiscountUsageHistoryDiscountModel;
  user: DiscountUsageHistoryUserModel;
  discountable: DiscountUsageHistoryDiscountableModel;
  created_at: string;

  constructor(dataItem: IDiscountUsageHistory) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.region = new DiscountUsageHistoryRegionModel(dataItem.region);
    this.discount = new DiscountUsageHistoryDiscountModel(dataItem.discount);
    this.user = new DiscountUsageHistoryUserModel(dataItem.user);
    this.discountable = new DiscountUsageHistoryDiscountableModel(
      dataItem.discountable,
    );
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getRegion = () => this.region;
  getDiscount = () => this.discount;
  getUser = () => this.user;
  getDiscountable = () => this.discountable;
  getCreatedAt = () => this.created_at;
}

export class DiscountUsageHistoryCollectionModel {
  public readonly object: DiscountUsageHistoryCollectionEnum.DiscountUsageHistoryCollection;
  public readonly data: DiscountUsageHistoryModel[];
  public readonly filters: IFilterType;
  public readonly pagination: PaginationModel;

  constructor(data: IDiscountUsageHistoryCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new DiscountUsageHistoryModel(item));
    this.filters = data.filters;
    this.pagination = new PaginationModel(data.pagination);
  }
  getDiscountUsageHistoryById(
    id: number,
  ): DiscountUsageHistoryModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getData() {
    return this.data;
  }
  getPagination() {
    return this.pagination;
  }
  getFilters() {
    return this.filters;
  }
}
