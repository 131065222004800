import {
  IAgentCategoryPriceRead,
  IAgentCategoryPriceReadCollection,
  IAgentCategoryPriceSlot,
  IAgentCategoryPriceSlotCollection,
  IAgentWarehouse,
  IBaseShippingCategory,
  IDestinationWarehouse,
} from "types/agentCategoryPriceReadCollection";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class AgentWarehouseModel {
  public object: string;
  public id: number;
  public name: string;
  public shipping_mark: string;
  public operation_status: string;
  public verification_status: string;
  public contact_person: string | null;
  public contact_number: string | null;
  public instruction: string | null;
  constructor(data: IAgentWarehouse) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.shipping_mark = data.shipping_mark;
    this.operation_status = data.operation_status;
    this.verification_status = data.verification_status;
    this.contact_person = data.contact_person;
    this.contact_number = data.contact_number;
    this.instruction = data.instruction;
  }
  getId = () => this.id;
  getName = () => this.name;
  getShippingMark = () => this.shipping_mark;
  getOperationStatus = () => this.operation_status;
  getVerificationStatus = () => this.verification_status;
  getContactPerson = () => this.contact_person;
  getContactNumber = () => this.contact_number;
  getInstruction = () => this.instruction;
}

export class BaseShippingCategoryModel {
  public object: string;
  public id: string;
  public name: string;
  public slug: string;

  constructor(data: IBaseShippingCategory) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.object = data.object;
  }
  getId = () => this.id;
  getName = () => this.name;
  getSlug = () => this.slug;
}

export class DestinationWarehouseModel {
  public object: string;
  public id: number;
  public slug: string;
  public name: string;
  constructor(data: IDestinationWarehouse) {
    this.object = data.object;
    this.slug = data.slug;
    this.id = data.id;
    this.name = data.name;
  }
  getId = () => this.id;
  getName = () => this.name;
  getSlug = () => this.slug;
}

export class AgentCategoryPriceSlotModel {
  public object: string;
  public id: number;
  public min_amount: number;
  public max_amount: number;
  public rate: number;
  constructor(data: IAgentCategoryPriceSlot) {
    this.object = data.object;
    this.min_amount = data.min_amount;
    this.id = data.id;
    this.max_amount = data.max_amount;
    this.rate = data.rate;
  }
  getId = () => this.id;
  getRate = () => this.rate;
  getMinAmount = () => this.min_amount;
  getMaxAmount = () => this.max_amount;
}

export class AgentCategoryPriceSlotCollectionModel {
  public object: string;
  public data: AgentCategoryPriceSlotModel[];
  constructor(data: IAgentCategoryPriceSlotCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AgentCategoryPriceSlotModel(item));
  }
  getData = () => this.data;
}

export class AgentCategoryPriceReadModel {
  public object: string;
  public id: number;
  public agent_warehouse: AgentWarehouseModel;
  public destination_warehouse: DestinationWarehouseModel;
  public shipping_type: string;
  public shipping_mode: string;
  public unit_type: string;
  public rate: number;
  public commission_rate: number;
  public commission_rate_type: string;
  public total_rate: number;
  public slots: AgentCategoryPriceSlotCollectionModel;
  constructor(data: IAgentCategoryPriceRead) {
    this.object = data.object;
    this.id = data.id;
    this.agent_warehouse = new AgentWarehouseModel(data.agent_warehouse);
    this.destination_warehouse = new DestinationWarehouseModel(
      data.destination_warehouse,
    );
    this.shipping_type = data.shipping_type;
    this.shipping_mode = data.shipping_mode;
    this.unit_type = data.unit_type;
    this.rate = data.rate;
    this.commission_rate = data.commission_rate;
    this.commission_rate_type = data.commission_rate_type;
    this.total_rate = data.total_rate;
    this.slots = new AgentCategoryPriceSlotCollectionModel(data.slots);
  }
  getId = () => this.id;
  getAgentWarehouse = () => this.agent_warehouse;
  getDestinationWarehouse = () => this.destination_warehouse;
  getShippingType = () => this.shipping_type;
  getShippingMode = () => this.shipping_mode;
  getUnitType = () => this.unit_type;
  getRate = () => this.rate;
  getCommissionRate = () => this.commission_rate;
  getCommissionRateType = () => this.commission_rate_type;
  getTotalRate = () => this.total_rate;
  getSlots = () => this.slots;
}

export class AgentCategoryPriceReadCollectionModel {
  public object: string;
  public data: AgentCategoryPriceReadModel[];
  public filters: IFilterType;
  public pagination: IPagination;
  constructor(data: IAgentCategoryPriceReadCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AgentCategoryPriceReadModel(item));
    this.filters = data.filters;
    this.pagination = data.pagination;
  }
  getData = () => this.data;
  getPagination() {
    return this.pagination;
  }

  getFilters() {
    return this.filters;
  }
}
