import {
  ShippingCountryDataStatus,
  ShippingCountryEnum,
} from "enums/shippingCountryCollectionEnums";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";
import {
  IShippingCountry,
  IShippingCountryData,
  IShippingCountryPostRequest,
  IShippingCountryResponse,
  IShippingCountryUpdateRequest,
} from "types/shippingCountry";
import { CountryDataModel } from "./country";
import { RegionDataModel } from "./region";

export class ShippingCountryDataModel {
  public readonly object: ShippingCountryEnum.ShippingCountry;
  public readonly id: number;
  public readonly country: CountryDataModel | undefined;
  public readonly region: RegionDataModel | undefined;
  public readonly status: ShippingCountryDataStatus;

  constructor(dataItem: IShippingCountryData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.country = dataItem.country
      ? new CountryDataModel(dataItem.country)
      : undefined;
    this.region = dataItem.region
      ? new RegionDataModel(dataItem.region)
      : undefined;
    this.status = dataItem.status;
  }
}

export class ShippingCountryModel {
  public readonly object: ShippingCountryEnum.ShippingCountryCollection;
  public readonly data: ShippingCountryDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IShippingCountry) {
    this.object = data.object;
    this.data = data.data.map((item) => new ShippingCountryDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  GetCountries(){
    return this.data
  }
  GetAllCountries(){
    return this.data.map(country => {
      return {
        label: country.country?.name,
        value: country.id
      }
    })
  }
}

//Post Request
export class ShippingCountryPostRequestModel {
  public readonly region_id: number;
  public readonly country_id: number;
  public readonly status: ShippingCountryDataStatus;

  constructor(reqData: IShippingCountryPostRequest) {
    this.region_id = reqData.region_id;
    this.country_id = reqData.country_id;
    this.status = reqData.status;
  }
}

//Update Request
export class ShippingCountryUpdateRequestModel {
  public readonly status: ShippingCountryDataStatus;

  constructor(reqData: IShippingCountryUpdateRequest) {
    this.status = reqData.status;
  }
}

// Response
export class ShippingCountryResponseModel {
  public readonly message: string;
  public readonly data: ShippingCountryDataModel;

  constructor(resData: IShippingCountryResponse) {
    this.message = resData.message;
    this.data = new ShippingCountryDataModel(resData.data);
  }
}
