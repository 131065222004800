import { ResponsePromise } from "lib/core/request/types/typings";
import { BaseResource } from "../public";
import { IHeaders } from "lib/core/request/types/request";

export declare interface IRfqRequestsStatus {
  source_agent_company_ids: number[];
  shipping_agent_company_ids: number[];
  valid_until: string;
}

export class RfqResource extends BaseResource {
  private rfqPath = "/api/rfq/admin/request/v1/requests";
  public getRequestsList(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = this.rfqPath;

    if (params) {
      path = `${path}?${params}`;
    }

    return this.client.request(
      "GET",
      path,
      undefined,
      undefined,
      customHeaders,
    );
  }

  public postRequestsApprove({
    requestId,
    payload,
    params,
    customHeaders,
  }: {
    params?: string;
    requestId: string;
    payload: IRfqRequestsStatus;
    customHeaders: IHeaders;
  }) {
    const path = `${this.rfqPath}/${requestId}/approve?${params}`;
    return this.client.request("POST", path, payload, undefined, customHeaders);
  }

  public postRequestsReject({
    requestId,
    payload,
    customHeaders,
    params,
  }: {
    params?: string;
    requestId: string;
    payload: {
      reject_reason: string;
    };
    customHeaders: IHeaders;
  }) {
    const path = `${this.rfqPath}/${requestId}/reject?${params}`;
    return this.client.request("POST", path, payload, undefined, customHeaders);
  }
}
