import {
  ShippingCategoryDataStatus,
  ShippingCategoryEnum,
} from "enums/shippingCategoryCollectionEnums";
import { TreeSelect } from "types";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";
import {
  IShippingCategory,
  IShippingCategoryData,
  IShippingCategoryRequest,
} from "types/shippingCategory";

import { RegionModel } from "./regionCollectionModel";

export class ShippingCategoryDataModel {
  public readonly object: ShippingCategoryEnum.ShippingCategory;
  public readonly id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly parent_id: string;
  public readonly base_shipping_category_id: string;
  public readonly region?: RegionModel;
  public readonly status: ShippingCategoryDataStatus;
  public readonly lock_version: number;
  public readonly etag: string;
  public readonly created_at: string;
  public readonly children?: ShippingCategoryDataModel[];

  constructor(dataItem: IShippingCategoryData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.parent_id = dataItem.parent_id;
    this.base_shipping_category_id = dataItem.base_shipping_category_id;
    this.region = dataItem.region
      ? new RegionModel(dataItem.region)
      : undefined;
    this.lock_version = dataItem.lock_version;
    this.etag = dataItem.etag;
    this.name = dataItem.name;
    this.slug = dataItem.slug;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
    this.children = dataItem.children
      ? dataItem.children.map((item) => new ShippingCategoryDataModel(item))
      : undefined;
  }
}

export class ShippingCategoryModel {
  public readonly object: ShippingCategoryEnum.ShippingCategoryCollection;
  public readonly data: ShippingCategoryDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: IShippingCategory) {
    this.object = data.object;
    this.data = data.data.map((item) => new ShippingCategoryDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }

  getShippingCategoryTreeIds() {
    const treeData: TreeSelect[] = [];
    // Helper function to build the tree recursively
    function buildTree(dataItem: ShippingCategoryDataModel): TreeSelect {
      const node: TreeSelect = {
        value: dataItem.id,
        title: dataItem.name,
        children: [],
      };

      if (dataItem.children && dataItem.children.length > 0) {
        dataItem.children.forEach((childItem: ShippingCategoryDataModel) => {
          const childNode = buildTree(childItem);
          node.children.push(childNode);
        });
      }
      return node;
    }

    // Build the tree for each top-level data item
    this.data.forEach((dataItem) => {
      const treeItem = buildTree(dataItem);
      treeData.push(treeItem);
    });

    return treeData;
  }
}

//Post Request
export class ShippingCategoryRequestModel {
  public readonly region_id: number;
  public readonly base_shipping_category_id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly status: ShippingCategoryDataStatus;

  constructor(reqData: IShippingCategoryRequest) {
    this.region_id = reqData.region_id;
    this.base_shipping_category_id = reqData.base_shipping_category_id;
    this.name = reqData.name;
    this.slug = reqData.slug;
    this.status = reqData.status;
  }
}
