// Enum for admin permissions in the Store module
export enum ADMIN_STORE_PERMISSION_ENUM {
    // Store permissions
    ADMIN_STORE_ADD = "admin-store-add",
    ADMIN_STORE_UPDATE = "admin-store-update",
    ADMIN_STORE_VIEW = "admin-store-view",
    ADMIN_STORE_SYNC = "admin-store-sync",

    // Exchange permissions
    ADMIN_EXCHANGE_ADD = "admin-exchange-add",
    ADMIN_EXCHANGE_VIEW = "admin-exchange-view",
    ADMIN_EXCHANGE_COPY = "admin-exchange-copy",
    ADMIN_EXCHANGE_UPDATE = "admin-exchange-update",
    ADMIN_EXCHANGE_DELETE = "admin-exchange-delete",
    ADMIN_EXCHANGE_SYNC = "admin-exchange-sync",

    // Regional Store Exchange permissions
    ADMIN_REGIONAL_STORE_EXCHANGE_UPDATE = "admin-regional-store-exchange-update",

    // Exchange Group permissions
    ADMIN_EXCHANGE_GROUP_VIEW = "admin-exchange-group-view",
    ADMIN_EXCHANGE_GROUP_ADD = "admin-exchange-group-add",
    ADMIN_EXCHANGE_GROUP_UPDATE = "admin-exchange-group-update",
    ADMIN_EXCHANGE_GROUP_DELETE = "admin-exchange-group-delete",
    ADMIN_EXCHANGE_GROUP_SYNC = "admin-exchange-group-sync",

    // Exchange Group User permissions
    ADMIN_EXCHANGE_GROUP_USER_ADD = "admin-exchange-group-user-add",
    ADMIN_EXCHANGE_GROUP_USER_DELETE = "admin-exchange-group-user-delete",
    ADMIN_EXCHANGE_GROUP_USER_SYNC = "admin-exchange-group-user-sync",

    // Store Exchange Read permissions
    ADMIN_STORE_EXCHANGE_READ_VIEW = "admin-store-exchange-read-view",
    ADMIN_STORE_EXCHANGE_READ_SYNC = "admin-store-exchange-read-sync"
}

