import {
  IBulkUser,
  IBulkUserCollection,
  IBulkUserMetaLinks,
  IBulkUserRole,
} from "types/bulkUserCollection.d";
import {PaginationModel} from "./paginationModel";

export class BulkUserRoleModel {
  id: number;
  name: string;
  label: null | string;
  guard_name: string;
  access: string;
  agent_company_id: null | string;

  constructor(dataItem: IBulkUserRole) {
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.label = dataItem.label;
    this.guard_name = dataItem.guard_name;
    this.access = dataItem.access;
    this.agent_company_id = dataItem.agent_company_id;
  }
}
export class BulkUserModel {
  id: number;
  name: string;
  email: string;
  phone: string;
  shipping_mark: string;
  type: string;
  agent_company: null | object;
  roles?: BulkUserRoleModel[];

  constructor(dataItem: IBulkUser) {
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.email = dataItem.email;
    this.phone = dataItem.phone;
    this.shipping_mark = dataItem.shipping_mark;
    this.type = dataItem.type;
    this.agent_company = dataItem.agent_company;
    this.roles = dataItem.roles &&  dataItem.roles.data.map((role) => new BulkUserRoleModel(role));
  }
}
export class BulkUserMetaLinksModel {
  url: null | string;
  label: string;
  active: boolean;

  constructor(dataItem: IBulkUserMetaLinks) {
    this.url = dataItem.url;
    this.label = dataItem.label;
    this.active = dataItem.active;
  }
}

export class BulkUserCollectionModel {
  data: BulkUserModel[];
  links: {
    first: string;
    last: string;
    prev: null | string;
    next: null | string;
  };
  pagination: PaginationModel;

  constructor(dataItem: IBulkUserCollection) {
    this.data = dataItem.data.map((item) => new BulkUserModel(item));
    this.links = dataItem.links;
    this.pagination = new PaginationModel(dataItem.pagination);
  }
  getDataForSelect() {
    return this.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }
}
