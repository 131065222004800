import {
    DeleteTwoTone,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
} from "antd";
import { Store } from "antd/es/form/interface";
import { renderSelectOptionsFromEnum } from "components/Form/forms";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";
import { showError } from "helpers/showError";
import { useAtom } from "jotai";
import { useGetRegion, useGetStore } from "lib/core-react/hooks/private";
import {
    useCreateExchange,
    useGetExchange,
    useUpdateExchange,
} from "lib/core-react/hooks/private/useExchange";
import { regionRegion, storeStoreAtom } from "lib/core-react/store/store";
import { RegionModel } from "models/region";
import { StoreCollectionModel } from "models/storeCollectionModel";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    IExchangeCreateUpdateFormValue,
    IExchangeListPostRequest,
    IExchangeStoreUpdatePayload,
} from "types/exchangeListCollection";
import { v4 as uuidv4 } from "uuid";
interface IProps {
    initialValues?: Store | undefined;
    title: string;
    action: "update" | "create";
    exchangeId?: number;
}
const CreateUpdateExchange = ({
    initialValues,
    title,
    action,
    exchangeId,
}: IProps) => {
    const { getRegion } = useGetRegion();
    const {getExchange} = useGetExchange()
    const navigate = useNavigate();
    const [{ data: regionDataAtom, isLoading: regionLoading }] =
        useAtom(regionRegion);
    const { getStore } = useGetStore();
    const [{ data: storeDataAtom, isLoading: storeLoading }] =
        useAtom(storeStoreAtom);

    const { createExchange, isLoading } = useCreateExchange();
    const { isLoading: updateExchangeLoading, updateExchange } =
        useUpdateExchange();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        getRegion("per_page=200");
        getStore();
    }, []);

    const regionData = regionDataAtom && new RegionModel(regionDataAtom);
    const storeData = storeDataAtom && new StoreCollectionModel(storeDataAtom);

    const onFinish = async (value: IExchangeCreateUpdateFormValue) => {
        if (action === "create") {
            const payload: IExchangeListPostRequest = {
                name: value.name,
                is_default: value.is_default ?? false,
                regional_exchanges: value.regional_exchanges.map((rx) => {
                    return {
                        region_id: rx.region_id,
                        store_exchanges: rx.store_exchanges.map((sx) => {
                            return {
                                fx_rate: sx.fx_rate,
                                commission_rate: sx.commission_rate,
                                commission_rate_type: sx.commission_rate_type,
                                options: sx.options,
                                store_id: sx.store_id,
                            };
                        }),
                    };
                }),
            };

            try {
                const response = await createExchange(payload);
                if (response) {
                    message.success(response.message);
                    await getExchange({});
                    navigate("/store-manage/store-exchanges");
                }
            } catch (e: any) {
                showError(e, form);
            }
        } else if (action === "update" && exchangeId) {
            try {
                const updatePayload: IExchangeStoreUpdatePayload = {
                    name: value.name,
                    regional_exchanges: value?.regional_exchanges.map(
                        (item) => {
                            return {
                                regional_exchange_id:
                                    item.regional_exchange_id as number,
                                store_exchanges: item?.store_exchanges.map(
                                    (ex) => {
                                        return {
                                            commission_rate: ex.commission_rate,
                                            commission_rate_type:
                                                ex.commission_rate_type,
                                            fx_rate: ex.fx_rate,
                                            options: ex.options,
                                            store_exchange_id:
                                                ex.store_exchange_id as number,
                                        };
                                    },
                                ),
                            };
                        },
                    ),
                };
                const res = await updateExchange(exchangeId, updatePayload);
                if (res) {
                    message.success(res.message);
                    await getExchange({});
                    navigate("/store-manage/store-exchanges");
                }
            } catch (error: any) {
                showError(error, form);
            }
        }
    };
    return (
        <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title={title}
            onBack={() => window.history.back()}
        >
            <Card title={" Create Form"} style={{ margin: "10px 0px" }}>
                <Form
                    layout="vertical"
                    form={form}
                    name="create-exchange-form"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={initialValues}
                >
                    {/* Exchange Name */}
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Exchange name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Exchange name" />
                    </Form.Item>

                    {/* Regional Exchanges */}
                    <Form.List name="regional_exchanges">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Card
                                        key={field.key}
                                        size="small"
                                        title={`Region #${index + 1}`}
                                        extra={
                                            index > 0 && (
                                                <MinusCircleOutlined
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    style={{ color: "red" }}
                                                />
                                            )
                                        }
                                        style={{
                                            marginBottom: 16,
                                            border: "4px solid #DFFFED",
                                        }}
                                    >
                                        <Space
                                            key={uuidv4()}
                                            direction="vertical"
                                            size="middle"
                                            style={{
                                                display: "flex",
                                                margin: "0 10px",
                                            }}
                                        >
                                            <Row
                                                justify="space-between"
                                                style={{
                                                    padding: 5,
                                                }}
                                            >
                                                {/*<Typography.Text mark>Region #{index + 1}</Typography.Text>*/}
                                                {index > 0 && (
                                                    <DeleteTwoTone
                                                        twoToneColor="hotpink"
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                    />
                                                )}
                                            </Row>
                                            {/* Region */}
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Region is required",
                                                    },
                                                ]}
                                                name={[field.name, "region_id"]}
                                                label="Region"
                                            >
                                                <Select
                                                    disabled={
                                                        action === "update"
                                                    }
                                                    loading={regionLoading}
                                                    placeholder="Please select a region"
                                                    options={regionData?.getSelectComponentSelectOptions()}
                                                />
                                            </Form.Item>

                                            {/* Store Exchanges */}
                                            <Form.List
                                                name={[
                                                    field.name,
                                                    "store_exchanges",
                                                ]}
                                            >
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(
                                                            (field, index) => (
                                                                <Card
                                                                    key={
                                                                        field.key
                                                                    }
                                                                    size="small"
                                                                    title={`Store #${index + 1}`}
                                                                    extra={
                                                                        index >
                                                                            0 && (
                                                                            <MinusCircleOutlined
                                                                                onClick={() =>
                                                                                    remove(
                                                                                        field.name,
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    style={{
                                                                        marginBottom: 16,
                                                                        border: "2px solid #DFFFED",
                                                                    }}
                                                                >
                                                                    <Space
                                                                        key={uuidv4()}
                                                                        direction="vertical"
                                                                        size="middle"
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            margin: "0 10px",
                                                                        }}
                                                                    >
                                                                        <Row
                                                                            justify="space-between"
                                                                            style={{
                                                                                padding: 5,
                                                                            }}
                                                                        >
                                                                            {index >
                                                                                0 && (
                                                                                <DeleteTwoTone
                                                                                    twoToneColor="hotpink"
                                                                                    onClick={() =>
                                                                                        remove(
                                                                                            field.name,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Row>
                                                                        {/* Store */}

                                                                        <Row
                                                                            gutter={
                                                                                16
                                                                            }
                                                                        >
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Store is required",
                                                                                        },
                                                                                    ]}
                                                                                    name={[
                                                                                        field.name,
                                                                                        "store_id",
                                                                                    ]}
                                                                                    label="Store"
                                                                                >
                                                                                    <Select
                                                                                        loading={
                                                                                            storeLoading
                                                                                        }
                                                                                        placeholder="Please select a store"
                                                                                        options={storeData?.getSelectComponentSelectOptions()}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "FX rate is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="FX Rate"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "fx_rate",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="FX Rate"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Commission rate is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Commission Rate"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "commission_rate",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="Commission Rate"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Commission rate type is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Commission Rate Type"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "commission_rate_type",
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        placeholder="Please select a commission rate type"
                                                                                        options={renderSelectOptionsFromEnum(
                                                                                            CommissionRateTypeEnums,
                                                                                        )}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>

                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Minimum order amount is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Minimum Order Amount"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "options",
                                                                                        "minimum_order_amount",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="Minimum Order Amount"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </Space>
                                                                </Card>
                                                            ),
                                                        )}
                                                        {action !==
                                                            "update" && (
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() =>
                                                                        add()
                                                                    }
                                                                    block
                                                                    icon={
                                                                        <PlusOutlined />
                                                                    }
                                                                >
                                                                    Add exchange
                                                                    for another
                                                                    store
                                                                </Button>
                                                            </Form.Item>
                                                        )}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Space>
                                    </Card>
                                ))}
                                {action !== "update" && (
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add exchange for another region
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>
                    <Form.Item name="is_default" valuePropName="checked">
                        <Checkbox>Set as Default</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading || updateExchangeLoading}
                            >
                                {action === "update" ? "Update" : "Submit"}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </PageHeader>
    );
};

export default CreateUpdateExchange;
