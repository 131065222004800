import {
    CurrencyDataStatus,
    CurrencyEnum,
} from "enums/currencyCollectionEnums";
import {
    PaymentGatewayFeeTypeEnums,
    PaymentGatewayTypeEnums,
} from "enums/paymentGatewayCollectionEnums";
import { TransactionsStatusEnums } from "enums/transactionCollectionsEnums";
import {
    IAssociation,
    ICurrencyData,
    IGatewayCurrencyCollection,
    IImage,
    IPaymentGateway,
    IPaymentGatewayFormSchema,
    IPaymentSession,
    IPaymentSessionInvoice,
    IPaymentSessionInvoices,
    IPaymentTransactionAttachment,
    IPaymentTransactionAttachmentCollection,
    ITransaction,
} from "types/transactionDetail";
import { RegionDataModel } from "./region";

export class PaymentGatewayModel {
    public readonly object: string;
    public readonly id: number;
    public readonly identifier: string;
    public readonly name: string;
    public readonly type: PaymentGatewayTypeEnums;
    public readonly fee: number;
    public readonly fee_type: PaymentGatewayFeeTypeEnums;
    public readonly merchant_data: { [key: string]: string } | null;
    public readonly form_schema: {
        [key: string]: IPaymentGatewayFormSchema;
    } | null;
    public readonly currencies: IGatewayCurrencyCollection;

    constructor(dataItem: IPaymentGateway) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.identifier = dataItem.identifier;
        this.name = dataItem.name;
        this.type = dataItem.type;
        this.fee = dataItem.fee;
        this.fee_type = dataItem.fee_type;
        this.merchant_data = dataItem.merchant_data;
        this.form_schema = dataItem.form_schema;
        this.currencies = dataItem.currencies;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getIdentifier() {
        return this.identifier;
    }
    getFee() {
        return this.fee;
    }
    getType() {
        return this.type;
    }
    getFeeType() {
        return this.fee_type;
    }
    getMarchentData() {
        return this.merchant_data;
    }
}

export class CurrencyDataModel {
    public readonly object: CurrencyEnum.Currency;
    public readonly id: number;
    public readonly name: string;
    public readonly code: string;
    public readonly symbol: string;
    public readonly symbol_native: string;
    public readonly status: CurrencyDataStatus;
    constructor(dataItem: ICurrencyData) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.code = dataItem.code;
        this.symbol = dataItem.symbol;
        this.symbol_native = dataItem.symbol_native;
        this.status = dataItem.status;
    }

    getName() {
        return this.name;
    }

    getSymbolNative() {
        return this.symbol_native;
    }
}

export class AssociationModel {
    public readonly id: number;
    public readonly amount: number;
    public readonly paymentSession: PaymentSessionModel;
    public readonly createdAt: string;

    constructor(dataItem: IAssociation) {
        this.id = dataItem.id;
        this.amount = dataItem.amount;
        this.paymentSession = new PaymentSessionModel(dataItem.payment_session);
        this.createdAt = dataItem.created_at;
    }

    getId() {
        return this.id;
    }

    getAmount() {
        return this.amount;
    }

    getPaymentSession() {
        return this.paymentSession;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class PaymentSessionModel {
    public readonly object: string;
    public readonly sessionInvoices: PaymentSessionInvoicesModel;
    public readonly createdAt: string;

    constructor(dataItem: IPaymentSession) {
        this.object = dataItem.object;
        this.sessionInvoices = new PaymentSessionInvoicesModel(
            dataItem.session_invoices,
        );
        this.createdAt = dataItem.created_at;
    }

    getObject() {
        return this.object;
    }

    getSessionInvoices() {
        return this.sessionInvoices;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class PaymentSessionInvoicesModel {
    public readonly object: string;
    public readonly data: PaymentSessionInvoiceModel[];

    constructor(dataItem: IPaymentSessionInvoices) {
        this.object = dataItem.object;
        this.data = dataItem.data.map(
            (invoice) => new PaymentSessionInvoiceModel(invoice),
        );
    }

    getObject() {
        return this.object;
    }

    getData() {
        return this.data;
    }
}

export class PaymentSessionInvoiceModel {
    public readonly object: string;
    public readonly id: number;
    public readonly invoiceNumber: string;
    public readonly status: string;
    public readonly totalAmount: number;
    public readonly initialAmount: number;
    public readonly refundableAmount: number;
    public readonly transactionSummary: {
        paid: number;
        due: number;
        overpaid: number;
    };
    public readonly createdAt: string;

    constructor(dataItem: IPaymentSessionInvoice) {
        this.object = dataItem.object;
        this.id = dataItem.invoice.id;
        this.invoiceNumber = dataItem.invoice.invoice_number;
        this.status = dataItem.invoice.status;
        this.totalAmount = dataItem.invoice.total_amount;
        this.initialAmount = dataItem.invoice.initial_amount;
        this.refundableAmount = dataItem.invoice.refundable_amount;
        this.transactionSummary = {
            paid: dataItem.invoice.transaction_summary.paid,
            due: dataItem.invoice.transaction_summary.due,
            overpaid: dataItem.invoice.transaction_summary.overpaid,
        };
        this.createdAt = dataItem.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoiceNumber;
    }

    getStatus() {
        return this.status;
    }

    getTotalAmount() {
        return this.totalAmount;
    }

    getInitialAmount() {
        return this.initialAmount;
    }

    getRefundableAmount() {
        return this.refundableAmount;
    }

    getTransactionSummary() {
        return this.transactionSummary;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class ImageModel {
    public readonly object: string;
    public readonly id: number;
    public readonly title: string | null;
    public readonly alt: string | null;
    public readonly src: string;
    public readonly height: number | null;
    public readonly width: number | null;
    public readonly created_at: string;

    constructor(data: IImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.height = data.height;
        this.width = data.width;
        this.created_at = data.created_at;
    }
}

export class PaymentTransactionAttachmentModel {
    public readonly object: string;
    public readonly type: string;
    public readonly image: ImageModel;

    constructor(data: IPaymentTransactionAttachment) {
        this.object = data.object;
        this.type = data.type;
        this.image = new ImageModel(data.image);
    }
}

export class PaymentTransactionAttachmentCollectionModel {
    public readonly object: string;
    public readonly data: PaymentTransactionAttachmentModel[];

    constructor(data: IPaymentTransactionAttachmentCollection) {
        this.object = data.object;
        this.data = data.data
            ? data.data.map(
                  (item) => new PaymentTransactionAttachmentModel(item),
              )
            : [];
    }
}

export class TransactionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly lock_version: number;
    public readonly etag: string;
    public readonly transaction_source: string;
    public readonly consent: string;
    public readonly gateway_id: number;
    public readonly gateway_transaction_id: number | null;
    public readonly currency_id: number;
    public readonly region_id: null;
    public readonly requested_amount: number;
    public readonly amount: number;
    public readonly reference: string | null;
    public readonly idempotency_key: string;
    public readonly status: TransactionsStatusEnums;
    public readonly gateway?: PaymentGatewayModel;
    public readonly association: AssociationModel;
    public readonly attachments?: PaymentTransactionAttachmentCollectionModel;
    public readonly currency?: CurrencyDataModel;
    public readonly region?: RegionDataModel;
    public readonly created_at: string;
    public readonly updated_at: string;

    constructor(dataItem: ITransaction) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.lock_version = dataItem.lock_version;
        this.etag = dataItem.etag;
        this.transaction_source = dataItem.transaction_source;
        this.consent = dataItem.consent;
        this.gateway_id = dataItem.gateway_id;
        this.gateway_transaction_id = dataItem.gateway_transaction_id;
        this.currency_id = dataItem.currency_id;
        this.region_id = dataItem.region_id;
        this.requested_amount = dataItem.requested_amount;
        this.amount = dataItem.amount;
        this.reference = dataItem.reference;
        this.idempotency_key = dataItem.idempotency_key;
        this.status = dataItem.status;
        this.gateway = dataItem.gateway
            ? new PaymentGatewayModel(dataItem.gateway)
            : undefined;
        this.association = new AssociationModel(dataItem.association);
        this.attachments = dataItem.attachments
            ? new PaymentTransactionAttachmentCollectionModel(
                  dataItem.attachments,
              )
            : undefined;
        this.currency = dataItem.currency
            ? new CurrencyDataModel(dataItem.currency)
            : undefined;
        this.created_at = dataItem.created_at;
        this.updated_at = dataItem.updated_at;
        this.region = dataItem.region
            ? new RegionDataModel(dataItem.region)
            : undefined;
    }

    getId() {
        return this.id;
    }
    getStatus() {
        return this.status;
    }
    getAmount() {
        return this.amount;
    }
    getGateway() {
        return this.gateway;
    }

    getRequestAmount() {
        return this.requested_amount;
    }

    getTransactionSource() {
        return this.transaction_source;
    }

    getAttachment() {
        return this.attachments;
    }

    getRegion() {
        return this.region;
    }

    getCurrency() {
        return this.currency;
    }

    getConsent() {
        return this.consent;
    }

    getCreated() {
        return this.created_at;
    }
}
