import type { DescriptionsProps } from "antd";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Flex,
  Grid,
  Row,
  Tag,
  Typography,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { AgentCompanyVerificationStatusEnums } from "enums/shared";
import { IShippingProps } from "types/shipmentProductCollection";
import {
  ChangeShippingCategoryModal,
  ChangeShippingModeModal,
  ChangeShippingTypeModal,
} from ".";

const { useBreakpoint } = Grid;

export const Shipping = ({
  isLoading,
  ShipmentProductData,
  title,
  changeShippingCategoryDetails,
  changeShippingCategoryHandler,
  changeShippingModeDetails,
  changeShippingModeHandler,
  changeShippingTypeDetails,
  changeShippingTypeHandler,
  setChangeShippingCategoryDetails,
  setChangeShippingModeDetails,
  setChangeShippingTypeDetails,
}: IShippingProps) => {
  const agentCompany =
    ShipmentProductData?.getAgentAssociation()?.getAgentCompany();
  const agentWarehouse =
    ShipmentProductData?.getAgentAssociation()?.getWarehouse();
  const status  = ShipmentProductData?.getStatus()
  const isEditable = status === "shipment-pending"
  const screens = useBreakpoint();

  const { Text } = Typography;

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Shipping category",
      span: 2,
      children: (
        <Flex>
          {
            ShipmentProductData &&
          <Text>{ShipmentProductData.getShippingCategory()?.getName()}</Text>
          }
          { isEditable && <Button
            size="small"
            style={{ padding: 0 }}
            type="link"
            icon={<EditOutlined />}
            onClick={() =>
              changeShippingCategoryHandler(
                [ShipmentProductData!.getId()],
                ShipmentProductData ?  ShipmentProductData.getShippingCategoryId() : undefined ,
              )
            }
          />
          }
        </Flex>
      ),
    },
    {
      key: "2",
      label: "Shipping type",
      children: (
        <Flex>
          <Text>{ShipmentProductData?.getShippingType()}</Text>

          { isEditable &&  <Button
            size="small"
            style={{ padding: 0 }}
            type="link"
            icon={<EditOutlined />}
            onClick={() =>
              changeShippingTypeHandler(
                [ShipmentProductData!.getId()],
                ShipmentProductData!.getShippingType(),
              )
            }
          />
          }
        </Flex>
      ),
    },
    {
      key: "3",
      label: "Shipping mode",
      children: (
        <Flex>
          <Text>{ShipmentProductData?.getShippingMode()}</Text>

          { isEditable &&  <Button
            size="small"
            style={{ padding: 0 }}
            type="link"
            icon={<EditOutlined />}
            onClick={() =>
              changeShippingModeHandler(
                [ShipmentProductData!.getId()],
                ShipmentProductData!.getShippingMode(),
              )
            }
          />
          }
        </Flex>
      ),
    },
    {
      key: "4",
      label: "Total quantity",
      children: ShipmentProductData?.getTotalQuantity(),
    },
    {
      key: "5",
      label: "Total weight",
      children: ShipmentProductData?.getTotalWeight(),
    },
  ];
  const companyItems: DescriptionsProps["items"] = [
    {
      key: "6",
      label: "Name",
      span: 4,
      children: (
        <Typography.Text
          style={{ width: "150px" }}
          ellipsis={{
            tooltip: `${agentCompany?.getPrimaryName()} ${agentCompany?.getSecondaryName()}`,
          }}
        >
          {`${agentCompany?.getPrimaryName()} ${agentCompany?.getSecondaryName()}`}
        </Typography.Text>
      ),
    },
    {
      key: "7",
      span: 4,
      label: "Verification status",
      children: ShipmentProductData?.getAgentAssociation()
        ?.getAgentCompany()
        .getVerificationStatus() ? (
        <Tag
          style={{ textTransform: "capitalize" }}
          color={
            ShipmentProductData.getAgentAssociation()
              ?.getAgentCompany()
              .getVerificationStatus() ===
            AgentCompanyVerificationStatusEnums.APPROVED
              ? "green"
              : "red"
          }
        >
          {ShipmentProductData.getAgentAssociation()
            ?.getAgentCompany()
            .getVerificationStatus()}
        </Tag>
      ) : (
        ""
      ),
    },
    {
      key: "10",
      span: 4,
      label: "Registered country",
      children: agentCompany?.getRegisteredCountryDetail().getName(),
    },
    {
      key: "11",
      span: 4,
      label: "Company type",
      children: agentCompany?.getCompanyType(),
    },
    {
      key: "12",
      span: 4,
      label: "Director",
      children: `${agentCompany?.getDirectorName()} (${agentCompany?.getDesignation()})`,
    },
    {
      key: "13",
      span: 4,
      label: "Business phone",
      children: (
        <a href={`tel:${agentCompany?.getBusinessPhone()}`}>
          {agentCompany?.getBusinessPhone()}
        </a>
      ),
    },
    {
      key: "14",
      span: 4,
      label: "Years of experience",
      children: (
        <Typography.Text
          style={{ width: "150px" }}
          ellipsis={{
            tooltip: agentCompany?.getYearsOfExperience().replaceAll("_", " "),
          }}
        >
          {agentCompany?.getYearsOfExperience().replaceAll("_", " ")}
        </Typography.Text>
      ),
    },
    {
      key: "15",
      span: 4,
      label: "Team Member",
      children: (
        <Typography.Text
          style={{ width: "150px" }}
          ellipsis={{
            tooltip: agentCompany
              ?.getNumberOfPeopleInTeam()
              .replaceAll("_", " "),
          }}
        >
          {agentCompany?.getNumberOfPeopleInTeam().replaceAll("_", " ")}
        </Typography.Text>
      ),
    },
  ];
  const warehouseItems: DescriptionsProps["items"] = [
    {
      key: "8",
      label: "Name",
      span: 4,
      children: (
        <Typography.Text
          style={{ width: "150px" }}
          ellipsis={{
            tooltip: agentWarehouse?.getName(),
          }}
        >
          {agentWarehouse?.getName()}
        </Typography.Text>
      ),
    },
    {
      key: "9",
      span: 4,
      label: "Verification status",
      children: ShipmentProductData?.getAgentAssociation()
        ?.getWarehouse()
        .getVerificationStatus() ? (
        <Tag
          style={{ textTransform: "capitalize" }}
          color={
            ShipmentProductData.getAgentAssociation()
              ?.getWarehouse()
              .getVerificationStatus() ===
            AgentCompanyVerificationStatusEnums.APPROVED
              ? "green"
              : "red"
          }
        >
          {ShipmentProductData.getAgentAssociation()
            ?.getWarehouse()
            .getVerificationStatus()}
        </Tag>
      ) : (
        ""
      ),
    },
    {
      key: "16",
      span: 4,
      label: "Shipping mark",
      children: agentWarehouse?.getShippingMark(),
    },
    {
      key: "17",
      span: 4,
      label: "Contact person",
      children: agentWarehouse?.getContactPerson(),
    },
    {
      key: "18",
      span: 4,
      label: "Contact number",
      children: agentWarehouse?.getContactNumber(),
    },
    {
      key: "19",
      span: 4,
      label: "Instruction",
      children: agentWarehouse?.getInstruction(),
    },
  ];
  return (
    <>
     <Card title={title} bordered={false} loading={isLoading}>
      <Descriptions size="small" items={items} />
      <Row gutter={16}>
        <Col span={screens.xs ? 24 : 12}>
          <Card
            title={<p style={{ textAlign: "center" }}>Agent Company</p>}
            bordered
            style={{
              flex: 1,
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {agentCompany ? (
              <Descriptions size="small" items={companyItems} />
            ) : (
              "No company available yet."
            )}
          </Card>
        </Col>
        <Col span={screens.xs ? 24 : 12}>
          <Card
            title={<p style={{ textAlign: "center" }}>Warehouse</p>}
            bordered
            style={{
              flex: 1,
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {agentWarehouse ? (
              <Descriptions size="small" items={warehouseItems} />
            ) : (
              "No warehouse available yet."
            )}
          </Card>
        </Col>
      </Row>
    </Card>

      {changeShippingCategoryDetails.productIds &&
        changeShippingCategoryDetails.productIds.length && (
          <ChangeShippingCategoryModal
            existingId={changeShippingCategoryDetails.existingId}
            isOpen={changeShippingCategoryDetails.isOpen}
            productIds={changeShippingCategoryDetails.productIds}
            onCancel={() =>
              setChangeShippingCategoryDetails({
                isOpen: false,
                existingId: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      {changeShippingModeDetails.productIds &&
        changeShippingModeDetails.productIds.length && (
          <ChangeShippingModeModal
            existingMode={changeShippingModeDetails.existingData}
            isOpen={changeShippingModeDetails.isOpen}
            productIds={changeShippingModeDetails.productIds}
            onCancel={() =>
              setChangeShippingModeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      {changeShippingTypeDetails.productIds &&
        changeShippingTypeDetails.productIds.length && (
          <ChangeShippingTypeModal
            existingType={changeShippingTypeDetails.existingData}
            isOpen={changeShippingTypeDetails.isOpen}
            productIds={changeShippingTypeDetails.productIds}
            onCancel={() =>
              setChangeShippingTypeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}
    </>
  );
};
