import { EditOutlined } from "@ant-design/icons";
import {
    Avatar,
    Button,
    Col,
    Flex,
    Form,
    Image,
    InputNumber,
    Modal,
    Row,
    Select,
    Table,
} from "antd";
import { useAtom, useSetAtom } from "jotai";

import {
    ProductVariationModel,
    SkuModelExtended,
    StoreModel,
} from "models/productDetailExtendedModel";
import {
    isOpenUpdatePriceModalAtom,
    selectedSkusAtom,
} from "pages/ProductManage/campagin/AddRawCampaignProductForm/AddRawCampaignProductFormProvider";
import { useLocation } from "react-router-dom";

const priceAdjustments = [
    { value: "setToFixed", label: "Set To Fixed" },
    { value: "incrementByFixed", label: "Increment By Fixed" },
    { value: "decrementByFixed", label: "Decrement By Fixed" },
    { value: "incrementByPercent", label: "Increment By Percent" },
    { value: "decrementByPercent", label: "Decrement By Percent" },
    { value: "incrementByMultiply", label: "Increment By Multiply" },
];

interface IProps {
    variations: ProductVariationModel;
    store: StoreModel;
}

export const ProductDetailsVariations = ({ variations, store }: IProps) => {
    const skus = variations ? variations.getSkus().getData() : [];
    const location = useLocation();
    const region = new URLSearchParams(location.search).get("region") || "BD";
    const campaignId = new URLSearchParams(location.search).get("campaignId");
    const [isOpenModal, setIsOpenDiscountModal] = useAtom(
        isOpenUpdatePriceModalAtom,
    );
    const setSelectedVariations = useSetAtom(selectedSkusAtom);

    const columns = [
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (_: string, record: SkuModelExtended) => {
                const propertyAssociations = record
                    .getPropertyAssociations()
                    .getData();
                const imageUrl =
                    propertyAssociations.length > 0
                        ? propertyAssociations[0].property_value_image
                        : "";

                return (
                    <>
                        <Avatar
                            src={<Image src={imageUrl} />}
                            size={50}
                            shape="square"
                        />
                    </>
                );
            },
        },
        {
            title: `${"properties"}`,
            dataIndex: "id",
            key: "id",
            render: (_: string, record: SkuModelExtended) => {
                const propertyAssociations = record
                    .getPropertyAssociations()
                    .getData();

                if (propertyAssociations.length > 0) {
                    return (
                        <div>
                            <p>
                                <span>
                                    {propertyAssociations[0].property_name}:
                                </span>
                                <span>
                                    {
                                        propertyAssociations[0]
                                            .property_value_name
                                    }
                                </span>
                            </p>
                        </div>
                    );
                } else {
                    return <></>;
                }
            },
        },

        {
            title: (
                <Button
                disabled
                    type="text"
                    htmlType="button"
                    onClick={() => {
                        setIsOpenDiscountModal({
                            formName: "updateRawProductOriginalPriceForm",
                            isOpen: true,
                        });
                    }}
                >
                    Original Price ({store.getCurrencySymbol()}){" "}
                    <EditOutlined />
                </Button>
            ),

            dataIndex: "color",
            key: "color",
            align: "center" as const,
            render: (_: string, record: SkuModelExtended) => {
                const price = record.getPrice().getOriginal() || 0;
                return (
                    <Row justify={"center"}>
                        <Col>
                            <Form.Item
                                style={{
                                    width: "100%",
                                }}
                                name={[
                                    "variation_original_price",
                                    record.getId(),
                                ]}
                                initialValue={price}
                                rules={[
                                    { required: true, message: "required" },
                                ]}
                            >
                                <InputNumber disabled={true} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: (
                <Button
                disabled
                    type="text"
                    htmlType="button"
                    onClick={() => {
                        setIsOpenDiscountModal({
                            formName: "updateRawProductOfferPriceForm",
                            isOpen: true,
                        });
                    }}
                >
                    Offer Price ({store.getCurrencySymbol()}) <EditOutlined />
                </Button>
            ),

            dataIndex: "color",
            key: "color",
            align: "center" as const,
            render: (_: string, record: SkuModelExtended) => {
                const price = record.getPrice().getDiscount() || 0;

                return (
                    <Row justify={"center"}>
                        <Col>
                            <Form.Item
                                style={{
                                    width: "100%",
                                }}
                                name={["variation_offer_price", record.getId()]}
                                initialValue={price}
                                rules={[
                                    { required: true, message: "required" },
                                ]}
                            >
                                <InputNumber disabled={true} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            },
        },

        {
            title: (
                <Button
                disabled
                    type="text"
                    htmlType="button"
                    onClick={() => {
                        setIsOpenDiscountModal({
                            formName: "updateRawProductStockForm",
                            isOpen: true,
                        });
                    }}
                >
                    Stock <EditOutlined />
                </Button>
            ),

            dataIndex: "title",
            key: "title",
            align: "center" as const,
            render: (_: string, record: SkuModelExtended) => {
                return (
                    <Row justify={"center"} align={"middle"}>
                        <Col>
                            <Form.Item
                        
                                style={{
                                    width: "100%",
                                }}
                                name={["variation_stock", record.getId()]}
                                initialValue={record.getStock()}
                                rules={[
                                    { required: true, message: "required" },
                                ]}
                                
                            >
                                <InputNumber disabled={true}  style={{ width: "100px" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    return (
        <>
            <Table
                style={{
                    overflow: "scroll",
                    height: skus.length > 7 ? 700 : "",
                }}
                size="small"
                bordered
                columns={columns}
                dataSource={skus}
                rowKey="id"
                pagination={false}
                scroll={{ x: 600 }}
                rowSelection={{
                    onChange(selectedRowKeys, selectedRows) {
                        setSelectedVariations(() => selectedRows);
                    },
                }}
            />

            {campaignId && region && (
                <Modal
                    title="Edit Offer Price"
                    open={isOpenModal.isOpen}
                    onCancel={() =>
                        setIsOpenDiscountModal({
                            formName: undefined,
                            isOpen: false,
                        })
                    }
                    getContainer={() =>
                        document.getElementById(`${isOpenModal.formName}`) ||
                        document.body
                    }
                    footer={null}
                >
                    <div>
                        {isOpenModal.formName ===
                            "updateRawProductOfferPriceForm" ||
                        isOpenModal.formName ===
                            "updateRawProductOriginalPriceForm" ? (
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "action type is required",
                                        },
                                    ]}
                                    name={["updatedPrice", "action"]}
                                    label="Action Type"
                                >
                                    <Select placeholder="Please select a action type">
                                        {priceAdjustments.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.value}
                                                    key={item.value}
                                                >
                                                    {item.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "amount is required",
                                        },
                                    ]}
                                    label="Amount"
                                    name={["updatedPrice", "amount"]}
                                >
                                    <InputNumber
                                        min={0}
                                        style={{ width: "100%" }}
                                        placeholder="Enter amount"
                                    />
                                </Form.Item>
                            </div>
                        ) : (
                            ""
                        )}

                        {isOpenModal.formName ===
                        "updateRawProductStockForm" ? (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "stock quantity is required",
                                    },
                                ]}
                                label="Quantity"
                                name={"stock"}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Enter amount"
                                />
                            </Form.Item>
                        ) : (
                            ""
                        )}
                        <Flex justify="end">
                            <Form.Item>
                                <Button
                                    key="submit"
                                    id="update-price-btn"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                                ,
                            </Form.Item>
                        </Flex>
                    </div>
                </Modal>
            )}
        </>
    );
};
