import React from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { BuyProductVariationPropertyModel } from '../../../models/buyOrderCollectionModel';
import { v4 } from 'uuid';

interface ProductMetaDataType {
  id: React.Key;
  meta: BuyProductVariationPropertyModel[];
  quantity: number;
  price: number;
  totalPrice: number;
}

const ProductMeta = ({
                       productMeta,
                     }: {
  productMeta: ProductMetaDataType[];
}) => {
  const items: ProductMetaDataType[] = productMeta.map((product, index) => {
    return {
      id: index,
      meta: product.meta,
      quantity: product?.quantity,
      price: product?.price,
      totalPrice: product?.totalPrice,
    };
  });

  const columns: TableColumnsType<ProductMetaDataType> = [
    {
      title: 'Meta',
      dataIndex: 'meta',
      key: 'meta',
      width: 200,
      render: (meta: BuyProductVariationPropertyModel[]) => (
        <div>
          {meta.map((singleProperty) => {
            return (
              <div
                key={v4()}
                style={{
                  textTransform: 'capitalize',
                }}
              >
                <span>{singleProperty.getPropertyName()}:</span>
                <span style={{ fontWeight: 'bold' }}>
                  {singleProperty.getPropertyValueName()}
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 60,
    },
    {
      title: 'Price (BDT/RMB)',
      dataIndex: 'price',
      key: 'price',
      width: 120,
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 90,
    },
  ];

  const totalQuantity = items.reduce(
    (acc, item) => acc + (item.quantity || 0),
    0
  );
  const totalPrice = items.reduce(
    (acc, item) => acc + (item.totalPrice || 0),
    0
  );

  return (
    <Table
      style={{ width: '100%' }}
      columns={columns}
      dataSource={items}
      rowKey={'id'}
      pagination={false}
      className={'meta-table'}
      scroll={{ y: 300 }}
      footer={() => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '200px 60px 120px 90px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ textAlign: 'left' }}>
            <strong>Total:</strong>
          </div>
          <div style={{ textAlign: 'right', fontWeight: 'bold', }}>
            {totalQuantity}
          </div>
          <div style={{ textAlign: 'center', gridColumn: 'span 2', fontWeight: 'bold' }}>
            {totalPrice.toFixed(2)}
          </div>
        </div>
      )}
    />
  );
};

export default ProductMeta;
