
import {  TransactionsResource } from "../../resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";
class PaymentService extends CommerceApiService {
  // public gatewayResource: GatewayResource;
  public transactionResource: TransactionsResource; //FIXME: should fix the api path
  constructor() {
    super();

    // this.gatewayResource = new GatewayResource(this.config);
    this.transactionResource = new TransactionsResource(this.config);
  }
}

export default PaymentService;
