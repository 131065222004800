import CommerceApiService from "lib/core/apiServices/commerceApiService";

import { InvoiceResource } from "../../resources/private";

class InvoiceService extends CommerceApiService {
  public invoiceResource: InvoiceResource;

  constructor(){
    super();

    this.invoiceResource = new InvoiceResource(this.config)
  }
}

export default InvoiceService;
