import qs, { ParsedQs } from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import MultiCheckedCell from "../MultiCheckedCell";

interface IProps {
  countriesField: FacetFieldModel[];
}

const CountriesFacet = ({ countriesField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { countries, ...rest } = parsedQuery;
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectCountries = async (name: string & ParsedQs) => {
    let updatedCountries;

    if (Array.isArray(countries)) {
      if (countries.includes(name)) {
        // Remove the country if it already exists
        // @ts-ignore FIXME
        // updatedCountries = countries.filter((country) => country !== name);
      } else {
        // Add the country if it doesn't exist
        updatedCountries = [...countries, name];
      }
    } else {
      // If country array does not exist, create it with the current countryName
      updatedCountries = [name];
    }

    const queryString = qs.stringify({
      ...rest,
      countries: updatedCountries,
    });
    await getProductDetailsCollection(
      {
        region: "BD",
        locale: "en",
      },
      { countries: updatedCountries, ...rest }
    );
    navigate(`/product-manage/products?${queryString}`);
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Countries</StyledProductSidebarItemTitle>
      <AppList
        data={countriesField}
        renderItem={(data) => (
          <MultiCheckedCell
            key={data.getName()}
            data={data}
            onChange={onSelectCountries}
            selected={Array.isArray(countries) ? countries : []}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default CountriesFacet;
