import {
  shippingCountry,
  shippingCountryCollectionAtom,
} from "lib/core-react/store/store";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { useState } from "react";
import {
  ShippingCountryPostRequestModel,
  ShippingCountryUpdateRequestModel,
} from "models/shippingCountry";
import { getError } from "../utils/errors";
import { notification } from "antd";

export const useCreateShippingCountry = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shippingCountryData, setShippingCountryData] =
    useAtom(shippingCountry);

  const createShippingCountry = async (
    payload: ShippingCountryPostRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingCoreService.shippingCountryResource.create(payload);
      setIsLoading(false);
      setShippingCountryData({ ...shippingCountryData, refetch: true });
      notification["success"]({
        message: "Create successfully",
      });
      return response;
    } catch (error: any) {
      if (error.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { createShippingCountry, isLoading, isError };
};

export const useUpdateShippingCountry = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shippingCountryData, setShippingCountryData] =
    useAtom(shippingCountry);

  const updateShippingCountry = async (
    id: number,
    payload: ShippingCountryUpdateRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingCoreService.shippingCountryResource.update(
        id,
        payload,
      );
      setIsLoading(false);
      setShippingCountryData({ ...shippingCountryData, refetch: true });
      notification["success"]({
        message: "Update successfully",
      });
      return response.data;
    } catch (error: any) {
      if (error.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { updateShippingCountry, isLoading, isError };
};

export const useGetShippingCountry = () => {
  const [shippingCountryData, setShippingCountryData] =
    useAtom(shippingCountry);
  const { shippingCoreService } = useService();
  const getShippingCountry = async (params?: string): Promise<void> => {
    setShippingCountryData({
      ...shippingCountryData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingCoreService.shippingCountryResource.get(params);
      setShippingCountryData({
        ...shippingCountryData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShippingCountryData({
        ...shippingCountryData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShippingCountry } as const;
};

export const useGetShippingCountries = () => {
  const [shippingCountryCollectionData, setShippingCountryCollectionData] =
    useAtom(shippingCountryCollectionAtom);
  const { shippingCoreService } = useService();

  const getShippingCountries = async (params?: string): Promise<void> => {
    setShippingCountryCollectionData({
      ...shippingCountryCollectionData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingCoreService.shippingCountryResource.get(params);
      setShippingCountryCollectionData({
        ...shippingCountryCollectionData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShippingCountryCollectionData({
        ...shippingCountryCollectionData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShippingCountries } as const;
};
