import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
  Tag,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { CountryDataModel, CountryModel } from "models/country";
import { CountryDataStatus } from "enums/countryCollectionEnums";
import useDataFilters from "hooks/useDataFilters";
import FiltersComponent from "components/FiltersComponent";
import { useGetCountry, useUpdateCountry } from "lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { regionCountry } from "lib/core-react/store/store";
import { IFilterType } from "types/filters";
import UpdateCountry from "./components/CountryModifications";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "../../../utils/helpers";
import { MenuInfo } from "rc-menu/lib/interface";
import { useCreateCountry } from "lib/core-react/hooks/private/useCountry";
import { ICountryUpdateRequest } from "types/country";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-core-enum";

const { Column } = Table;
const Country = () => {
  const { getCountry } = useGetCountry();
  const { updateCountry, isLoading: updateLoading } = useUpdateCountry();
  const { createCountry, isLoading: createLoading } = useCreateCountry();

  const { isMobile } = useWindowWidth();
  const [form] = Form.useForm();
  const [{ data: countryStoreData, isLoading, refetch }] =
    useAtom(regionCountry);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  useEffect(() => {
    if (
      (filters && !isFetched && isFirstCall) ||
      refetch ||
      refetchFromFilter
    ) {
      filters && ApiHelperModel.makeGetRequest(filters, getCountry);
    }
    
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  // Country
  // @ts-ignore FIXME
  const CountryData = countryStoreData && new CountryModel(countryStoreData);

  // Filter
  let filterData = CountryData?.filters;
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && CountryData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, CountryData?.filters]);

  // Update modal state
  const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
    useState<boolean>(false);
  const [seletedCountry, setSeletedCountry] = useState<
    CountryDataModel | undefined
  >(undefined);

  // Handle Modal
  const handleMenuClick = (e: MenuInfo, record: CountryDataModel) => {
    setSeletedCountry(record);

    if (e.key === "update") {
      setIsShowCreateUpdateModal(true);
    }
  };

  const onFinish = async (value) => {
    try {
      const payload: ICountryUpdateRequest = {
        name: value.name,
        status: value.status,
        code: value.code,
      };

      if (seletedCountry) {
        await updateCountry(seletedCountry?.id, payload);
        setSeletedCountry(undefined);
        notification["success"]({
          message: "Country Update successfully",
        });
      } else {
        await createCountry(payload);
        notification["success"]({
          message: "Country Create successfully",
        });
      }
      setIsShowCreateUpdateModal(false);
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCountry,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CountryData,
    handlePaginationChange,
  );

  // Filter Handling
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCountry);
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Region Country"
          style={{ marginTop: "10px" }}
          extra={[
            checkActionPermission(
              ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_ADD,
              <Button
                key="1"
                type="primary"
                onClick={() => setIsShowCreateUpdateModal(true)}
              >
                Add Country
              </Button>,
              null,
            ),
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={CountryData?.data}
              scroll={{ x: 1000 }}
            >
              <Column width={70} title="ID" dataIndex="id" key="id" />
              <Column title="Country Code" dataIndex="code" key="code" />
              <Column title="Country Name" dataIndex="name" key="name" />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: CountryDataModel) => (
                  <Tag
                    color={
                      record.status === CountryDataStatus.active // active
                        ? "green"
                        : "red"
                    }
                    key={record.status}
                  >
                    {record.status}
                  </Tag>
                )}
              />
              <Column
                hidden={isMobile}
                title="Created At"
                dataIndex="created_at"
                key="created_at"
                render={(created_at) => {
                  return (
                    <Tag color={"purple"}>{formatDateTime(created_at)}</Tag>
                  );
                }}
              />
              <Column
                title="Action"
                fixed={isMobile ? "right" : undefined}
                width={isMobile ? 100 : 150}
                key="action"
                render={(_, record: CountryDataModel) => {
                  const items: ExtendedMenuItemType[] = [
                    {
                      permission: ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_UPDATE,
                      label: "Update",
                      key: "update",
                      icon: <EditOutlined />,
                      onClick: (e) => handleMenuClick(e, record),
                    },
                  ];

                  if (isMobile) {
                    items.push({
                      permission: ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_VIEW,
                      key: "2",
                      disabled: true,
                      label: (
                        <Tag color={"purple"}>
                          {formatDateTime(record.created_at)}
                        </Tag>
                      ),
                    });
                  }
                  return (
                    <Dropdown
                      menu={{
                        items: items.filter((x) =>
                          checkActionPermission(x.permission, x, null),
                        ),
                      }}
                    >
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            {" "}
                            Actions <DownOutlined />
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`${seletedCountry ? "Update" : "Create"} Country`}
        open={isShowCreateUpdateModal}
        okText={seletedCountry ? "Update" : "Create"}
        okButtonProps={{ loading: createLoading || updateLoading }}
        onCancel={() => {
          setIsShowCreateUpdateModal(false);
          setSeletedCountry(undefined);
        }}
        onOk={form.submit}
        centered
      >
        <UpdateCountry data={seletedCountry} form={form} onFinish={onFinish} />
      </Modal>
    </>
  );
};

export default Country;
