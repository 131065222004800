import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { buyActionCollectionAtom } from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useState } from "react";
import { IActionPerformPayload } from "types/buyActionCollection";
import { notification } from "antd";

export const useTakeActionList = () => {
    const [takeAction, setTakeAction] = useAtom(buyActionCollectionAtom);
    const [loading, setLoading] = useState(true);

    const { buyTakeActionsService } = useService();

    const getAllTakeActions = async (params?: string): Promise<void> => {
        setLoading(true);
        setTakeAction({ ...takeAction, isLoading: true, error: null });
        try {
            const response =
                await buyTakeActionsService.buyActionResource.getBuyActions(
                    params,
                );
            setTakeAction({
                ...takeAction,
                isLoading: false,
                data: response,
                refetch: false,
            });
            setLoading(false);
        } catch (error: any) {
            setTakeAction({
                ...takeAction,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            setLoading(false);
            throw error;
        }
    };

    return { getAllTakeActions, loading, setLoading };
};

export const useTakeActionPerform = () => {
    const { buyTakeActionsService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [takeAction, setTakeAction] = useAtom(buyActionCollectionAtom);

    const takeActionPerform = async (
        buyTakeAction_id: number,
        payload: IActionPerformPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await buyTakeActionsService.buyActionResource.performBuyActions(
                    buyTakeAction_id,
                    payload,
                );

            setIsLoading(false);
            setTakeAction({ ...takeAction, refetch: true });
            notification["success"]({
                message: "Perform successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { takeActionPerform, isLoading, isError };
};

export const useTakeActionRefund = () => {
    const { buyTakeActionsService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [takeAction, setTakeAction] = useAtom(buyActionCollectionAtom);

    const takeActionRefund = async (
        buyTakeAction_id: number,
        payload: IActionPerformPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await buyTakeActionsService.buyActionResource.refundBuyActions(
                    buyTakeAction_id,
                    payload,
                );

            setIsLoading(false);
            setTakeAction({ ...takeAction, refetch: true });
            notification["success"]({
                message: "Rufund successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return { takeActionRefund, isLoading, isError };
};

export const useTakeActionCancel = () => {
    const { buyTakeActionsService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [takeAction, setTakeAction] = useAtom(buyActionCollectionAtom);

    const takeActionCancel = async (buyTakeAction_id: number) => {
        setIsLoading(true);
        try {
            const response =
                await buyTakeActionsService.buyActionResource.cancelBuyActions(
                    buyTakeAction_id,
                );

            setIsLoading(false);
            setTakeAction({ ...takeAction, refetch: true });
            notification["success"]({
                message: "Cancel successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return { takeActionCancel, isLoading, isError };
};
