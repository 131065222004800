import qs, { ParsedQs } from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import MultiCheckedCell from "../MultiCheckedCell";

interface IProps {
  brandsField: FacetFieldModel[];
}

const BrandsFacet = ({ brandsField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { brands, ...rest } = parsedQuery;
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectBrands = async (name: string & ParsedQs) => {
    let updatedBrands;

    if (Array.isArray(brands)) {
      if (brands.includes(name)) {
        // Remove the brand if it already exists
        // @ts-ignore FIXME
        // updatedBrands = brands.filter((brand) => brand !== name);
      } else {
        // Add the brand if it doesn't exist
        updatedBrands = [...brands, name];
      }
    } else {
      // If brand array does not exist, create it with the current brandName
      updatedBrands = [name];
    }

    const queryString = qs.stringify({
      ...rest,
      brands: updatedBrands,
    });
    await getProductDetailsCollection(
      {
        region: "BD",
        locale: "en",
      },
      { brands: updatedBrands, ...rest }
    );
    navigate(`/product-manage/products?${queryString}`);
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Brands</StyledProductSidebarItemTitle>
      <AppList
        data={brandsField}
        renderItem={(data) => (
          <MultiCheckedCell
            key={data.getName()}
            data={data}
            onChange={onSelectBrands}
            selected={Array.isArray(brands) ? brands : []}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default BrandsFacet;
