import { Table } from "antd";
import { OfferModel } from "models/shipmentProductBidCollectionModel";
import { formatDate } from "utils/helpers";

const { Column } = Table;
interface IProps {
  data: OfferModel[];
}

const BidOffersTable = ({ data }: IProps) => {
  return (
    <Table dataSource={data} pagination={{ pageSize: 10 }} rowKey="id">
      <Column
        title="Type"
        dataIndex="unit_type"
        key="product_details"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getUnitType()}</div>
        )}
      />
      <Column
        title="Rate"
        dataIndex="rate"
        key="rate"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getRate()}</div>
        )}
      />
      <Column
        title="Applicable Before"
        dataIndex="applicable_until"
        key="applicable_until"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{formatDate(record.getApplicableUntil())}</div>
        )}
      />

      <Column
        title="Rate (Only Before)"
        dataIndex="rate_if_lower"
        key="rate_if_lower"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getRateIfLower()}</div>
        )}
      />
      <Column
        title="Rate (Only After)"
        dataIndex="rate_if_above"
        key="rate_if_above"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getRateIfAbove()}</div>
        )}
      />
      <Column
        title="CBM Max Value"
        dataIndex="cbm_max_value"
        key="cbm_max_value"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getCbmMaxValue()}</div>
        )}
      />

      <Column
        title="Agent Note"
        dataIndex="agent_note"
        key="agent_note"
        className="vertical-align-top"
        render={(_: string, record: OfferModel) => (
          <div>{record.getAgentNote()}</div>
        )}
      />
    </Table>
  );
};

export default BidOffersTable;
