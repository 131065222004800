import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Select,
  TreeSelect,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import { renderOptionsFromEnum } from "components/Form/forms";
import { ShippingCategoryDataStatus } from "enums/shippingCategoryCollectionEnums";
import { prepareError } from "helpers/prepareError";
import { useAtom } from "jotai";
import {
  useCreateShippingCategory,
  useGetBaseShippingCategory,
  useGetRegion,
} from "lib/core-react/hooks/private";
import {
  baseShippingCategoryAtom,
  regionRegion,
} from "lib/core-react/store/store";
import { BaseShippingCategoryModel } from "models/baseShippingCategory";
import { Forms } from "models/form";
import { RegionModel } from "models/region";
import {
  ShippingCategoryDataModel,
  ShippingCategoryRequestModel,
} from "models/shippingCategory";
import React, { useEffect, useState, createRef } from "react";

interface IProps {
  data: ShippingCategoryDataModel;
  setIsShowAddChildModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddChildren = ({ data, setIsShowAddChildModal }: IProps) => {
  const { createShippingCategory, isLoading } = useCreateShippingCategory();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getRegion("per_page=200");
    getBaseShippingCategory("per_page=200");
  }, []);

  // region, base Shipping category
  const { getRegion } = useGetRegion();
  const { getBaseShippingCategory } = useGetBaseShippingCategory();

  const [
    {
      data: baseShippingCategoryDataAtom,
      isLoading: baseShippingCategoryLoading,
    },
  ] = useAtom(baseShippingCategoryAtom);
  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);

  const baseShippingCategoryTreeId =
    baseShippingCategoryDataAtom &&
    new BaseShippingCategoryModel(
      baseShippingCategoryDataAtom,
    ).getBaseShippingCategoryTreeIds();
  const regionDataTreeId =
    regionDataAtom && new RegionModel(regionDataAtom).getRegionIdsAsObject();

  const onFinish = async (value: ShippingCategoryRequestModel) => {
    const payload = {
      name: value.name,
      status: value.status,
      slug: value.slug,
      region_id: value.region_id,
      base_shipping_category_id: value.base_shipping_category_id
        ? value.base_shipping_category_id
        : null,
      parent_id: data.id,
    };

    try {
      const res = await createShippingCategory(
        payload as ShippingCategoryRequestModel,
      );

      if (res) {
        notification["success"]({
          message: res.message,
        });
        setIsShowAddChildModal(false);
        setIsErrorVisible(false);
      }
    } catch (error) {
      setIsErrorVisible(true);
      setErrorMessage(prepareError(error).message);
    }
  };
  const onValuesChange = (changedValues: any) => {
    Forms.formValueChange(form, changedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Create_Shipping_Category"
      ref={formRef}
      onFieldsChange={onValuesChange}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Shipping category name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Shipping category name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Slug is required" }]}
        label="Slug"
        name="slug"
      >
        <Input style={{ width: "100%" }} readOnly placeholder="Slug name" />
      </Form.Item>
      <Form.Item
        label="Select Base Shipping Category"
        name="base_shipping_category_id"
        rules={[
          { required: true, message: "Base shipping category is required" },
        ]}
      >
        <TreeSelect
          showSearch
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select a base shipping category"
          allowClear
          loading={baseShippingCategoryLoading}
          treeLine={{ showLeafIcon: true }}
          treeData={baseShippingCategoryTreeId}
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Region is required" }]}
        name="region_id"
        label="Region"
      >
        <Select loading={regionLoading} placeholder="Please select a region">
          {regionDataTreeId && renderOptionsFromEnum(regionDataTreeId)}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Status is required" }]}
        name="status"
        label="Status"
      >
        <Select placeholder="Please select a status">
          {renderOptionsFromEnum(ShippingCategoryDataStatus)}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={errorMessage}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default AddChildren;
