import { CommissionResource, ShippingResource } from "lib/core/resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class ShippingService extends CommerceApiService {
  public commissionResource: CommissionResource;
  public shippingResource: ShippingResource;

  constructor() {
    super();

    this.commissionResource = new CommissionResource(this.config);
    this.shippingResource = new ShippingResource(this.config);
  }
}

export default ShippingService;
