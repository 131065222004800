import React, {useMemo, useRef, useState,useEffect } from "react";
import { Spin, Form ,TreeSelect} from "antd";
import type { TreeSelectProps } from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import {TreeSelect as TreeSelectType} from "../../types";

const filterTreeData = (
  data: TreeSelectType[],
  searchValue: string
): TreeSelectType[] => {
  const filteredData: TreeSelectType[] = [];

  data.forEach((item) => {
    const filteredChildren = item.children ? filterTreeData(item.children, searchValue) : [];
    if (
      item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      filteredChildren.length > 0
    ) {
      filteredData.push({
        ...item,
        children: filteredChildren,
      });
    }
  });

  return filteredData;
};

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceTreeSelectProps<ValueType = any>
  extends Omit<TreeSelectProps<ValueType>, "options" | "children"> {
  debounceTimeout?: number;
  initialOptions: TreeSelectType[];
}

function DebounceTreeSelect<
  ValueType extends { value: string | number } = any,
>({
    debounceTimeout = 800,
    initialOptions,
    ...props
  }: DebounceTreeSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<TreeSelectType[]>(initialOptions);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      const filteredOptions = filterTreeData(initialOptions, value);

      if (fetchId === fetchRef.current) {
        setOptions(filteredOptions);
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [initialOptions, debounceTimeout]);

  useEffect(()=>{

    setOptions(initialOptions)

  },[initialOptions])

  return (
    <TreeSelect<ValueType>
      showSearch
      labelInValue
      allowClear
      filterTreeNode={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      treeData={options}
      treeLine={{ showLeafIcon: true }}
      treeIcon={<CarryOutOutlined />}
      onClear={() => setOptions(initialOptions)}
    />
  );
}
export const ShippingCategoryTreeSelect = ({
                                             value,
                                             allowClear,
                                             showSearch,
                                             placeholder,
                                             onChange,
                                             style,
                                             initialOptions,
                                             ...props
                                           }: Omit<DebounceTreeSelectProps, "initialOptions"> & {initialOptions : TreeSelectType[]}) => {
  const form = Form.useFormInstance();

  const handleChange = async (
    newValue:
      | { label: React.ReactNode; value: string | number }
      | { label: React.ReactNode; value: string | number }[],
    option: any,
    extra: any,
  ) => {
    if (newValue && "label" in newValue) {
      form.setFieldsValue({ shipping_category_id: newValue.value });
    }
    if (onChange) {
      onChange(newValue, option, extra);
    }
  };

  return (
    <DebounceTreeSelect
      value={value}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      initialOptions={initialOptions ?? []}
      onChange={handleChange}
      style={style}
      {...props}
    />
  );
};

export default ShippingCategoryTreeSelect;
