export class UpdatedPriceCalculate {
    static incrementByMultiply = (
        baseValue: number,
        amount: number,
    ): number => {
        return baseValue * amount;
    };
    static setToFixed = (baseValue: number): number => {
        return baseValue;
    };
    static incrementByFixed = (baseValue: number, amount: number): number => {
        return baseValue + amount;
    };

    static decrementByFixed = (baseValue: number, amount: number): number => {
        if (baseValue > 0) {
            return baseValue - amount;
        } else {
            return 0;
        }
    };

    static incrementByPercent = (
        baseValue: number,
        percent: number,
    ): number => {
        return baseValue + (baseValue * percent) / 100;
    };
    static decrementByPercent = (
        baseValue: number,
        percent: number,
    ): number => {
        return baseValue - (baseValue * percent) / 100;
    };
}
