import {Button, Form, FormInstance, Modal, Select, notification} from "antd";
import { useAtom } from "jotai";
import { useUpdateShippingCountry } from "lib/core-react/hooks/private/useShipping";
import { useGetShippingCountries } from "lib/core-react/hooks/private/useShippingCountry";
import { getError } from "lib/core-react/hooks/utils/errors";
import { shippingCountryCollectionAtom } from "lib/core-react/store/store";
import { ShippingCountryCollectionModel } from "models/shippingCountryCollectionModel";
import React, { useEffect } from "react";

interface IProps {
  productIds: number[];
  isOpen: boolean;
  onCancel: () => void;
  existingShippingCountryId: number | undefined;
}

export const ChangeShippingCountryModal = ({
  isOpen,
  onCancel,
  productIds,
  existingShippingCountryId,
}: IProps) => {
  const { getShippingCountries } = useGetShippingCountries();
  const { updateShippingCountry, isLoading: updateShippingCountryLoading } =
    useUpdateShippingCountry();
  const [{ data: shippingCountryCollectionData, isLoading }] = useAtom(
    shippingCountryCollectionAtom,
  );
  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [shippingCountryCollectionData, form]);

  useEffect(() => {
    getShippingCountries(`region_id=3`);
  }, []);

  const ShippingCountryData =
    shippingCountryCollectionData &&
    new ShippingCountryCollectionModel(shippingCountryCollectionData);

  const onOkHandler = async (data: { shippingCountry: number }) => {
    const payload: {
      shipment_product_ids: number[];
      shipping_country_id: number;
    } = {
      shipment_product_ids: productIds,
      shipping_country_id: data.shippingCountry,
    };

    try {
      const data = await updateShippingCountry(payload);
      notification["success"]({
        message: data.message,
      });
      onCancel();
    } catch (error) {
      notification["error"]({
        message: getError(error),
      });
    }
  };

  return (
    <Modal
      title="Change Shipping Country"
      open={isOpen}
      destroyOnClose={true}
      confirmLoading={updateShippingCountryLoading}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        onFinish={onOkHandler}
        form={form}
        layout="vertical"
        name="update_shipping_country"
        ref={formRef}
        initialValues={{
          shippingCountry: existingShippingCountryId,
        }}
      >
        <Form.Item name="shippingCountry" label="Shipping Country">
          <Select
            loading={isLoading}
            placeholder="Please select a shipping country"
            options={ShippingCountryData?.getLabeledShippingCountries()}
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={updateShippingCountryLoading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
