import { Card } from "antd";
import { BuyProductViewModel } from "models/buyProductDetailModel";
import { useState } from "react";
import StoreDetail from "./Store";
import ProductSellerDetail from "./ProductSeller";
import ProductMetas from "./ProductMetas";

interface IProps {
  productData: BuyProductViewModel | undefined;
  isloading: boolean;
}

const ProductDetail: React.FC<IProps> = ({ productData, isloading }) => {
  const [activeTabKey1, setActiveTabKey1] = useState<string>("product_details");

  const onTab1Change = (key: string) => {
    setActiveTabKey1(key);
  };

  const tabList = [
    {
      key: "product_details",
      tab: "Product Details",
    },
    {
      key: "store",
      tab: "Store",
    },
    {
      key: "seller_Information",
      tab: "Seller Information",
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    product_details: <ProductMetas productData={productData} />,
    store: <StoreDetail productData={productData} />,
    seller_Information: <ProductSellerDetail productData={productData} />,
  };

  return (
    <>
      <Card
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
        loading={isloading}
      >
        {contentList[activeTabKey1]}
      </Card>
    </>
  );
};

export default ProductDetail;
