import React  from "react";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Row
} from "antd";
import {
  BuyProductModel
} from "models/buyOrderCollectionModel";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {IShippingRateType, IShippingRateUpdatePayload} from "types/buyOrderCollection";
import {useBuyProductsShippingPriceUpdate} from "../../../../lib/core-react/hooks/private/usePurchase";

interface IProps {
  setIsOpenUpdateShippingModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProduct: BuyProductModel;
}

const UpdateShippingPrice: React.FC<IProps> = ({
  selectedProduct,
  setIsOpenUpdateShippingModal
}) => {
  const [form] = Form.useForm();
  const { buyProductShippingPriceUpdate, isLoading} = useBuyProductsShippingPriceUpdate()

  const onFinish = async (values: IShippingRateType) => {
    const payload : IShippingRateUpdatePayload = {
      rate: values.new_rate,
      slots: values.slots?.map(slot => ({
        min_amount: slot.min_amount,
        max_amount: slot.max_amount,
        rate: slot.rate,
      })) || []
    };

  try {
    await buyProductShippingPriceUpdate(selectedProduct.getId(),payload)
    setIsOpenUpdateShippingModal(false)
  }catch (_e) {
  }
  };

  return (

    <Row>
      <Col span={24}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            new_rate: selectedProduct.getMoveonShipping()?.getRate() || 0,
            slots: selectedProduct.getMoveonShipping()?.getSlots().map(rate => ({
              min_amount: rate.getMinAmount(),
              max_amount: rate.getMaxAmount(),
              rate: rate.getRate(),
            }))
          }}
        >
            <Form.Item
              label="New Rate"
              name="new_rate"
              rules={[
                {
                  required: true,
                  message: "Please enter a new rate",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.List name="slots">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 5,
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "16px" }}>
                        <Form.Item
                          {...restField}
                          label="Min"
                          name={[name, "min_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter min amount",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ flex: 1, marginRight: "16px" }}>
                        <Form.Item
                          {...restField}
                          label="Max"
                          name={[name, "max_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter max amount",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      <div style={{ flex: 1, marginRight: "16px" }}>
                        <Form.Item
                          {...restField}
                          label="Rate"
                          name={[name, "rate"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter rate",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </div>
                      {fields.length > 1 && (
                        <Button
                          type="link"
                          onClick={() => remove(name)}
                          icon={<MinusCircleOutlined />}
                          style={{ marginLeft: "16px", marginTop: 8 }}
                        />
                      )}
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ marginTop: "20px" }}
                  >
                    <PlusOutlined /> Add Rate Change
                  </Button>
                </>
              )}
            </Form.List>
            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button  loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Form>
      </Col>
    </Row>

  );
};

export default UpdateShippingPrice;
