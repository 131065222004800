export enum DestinationWarehouseEnum {
  DestinationWarehouseCollection = "DestinationWarehouseCollection",
  DestinationWarehouse = "DestinationWarehouse",
}

export enum DestinationWarehouseStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum DestinationWarehouseMemberStatus {
  Active = "active",
  Inactive = "inactive",
}
