import React from 'react';
import { Card, Col, Row, Descriptions, Tag } from 'antd';
import { InvoiceAgentCompanyModel } from "../../../../models/invoiceCollectionModel";
import { StatusTag } from "../../../../components";

interface AgentCompanyCardProps {
  agentCompany: InvoiceAgentCompanyModel;
}

const AgentCompanyCard: React.FC<AgentCompanyCardProps> = ({ agentCompany }) => {
  const titleStyle = { fontWeight: 'bold', fontSize: '1em' };
  const labelStyle = { fontWeight: 'bold' };
  const status = agentCompany.getRegisteredCountryDetail().status;
  const operationStatus = agentCompany.getOperationStatus();
  const verificationStatus = agentCompany.getVerificationStatus();
  const createdAt = agentCompany.getRegisteredCountryDetail().created_at;

  return (
    <Card style={{ margin: '10px' }} title={<span style={titleStyle}>Agent Details</span>} bordered={false}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Descriptions title={<span style={titleStyle}>Agent Company</span>} bordered column={1}>
            <Descriptions.Item label={<b style={labelStyle}>Primary Name</b>}>{agentCompany.getPrimaryName()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Secondary Name</b>}>{agentCompany.getSecondaryName()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Slug</b>}>{agentCompany.getSlug()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Operation Status</b>}>
              <StatusTag text={operationStatus} slug={operationStatus} />
            </Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Verification Status</b>}>
              <StatusTag text={verificationStatus} slug={verificationStatus} />
            </Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Company Type</b>}>{agentCompany.getCompanyType()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Director Name</b>}>{agentCompany.getDirectorName()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Designation</b>}>{agentCompany.getDesignation()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Business Phone</b>}>{agentCompany.getBusinessPhone()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Years of Experience</b>}>{agentCompany.getYearsOfExperience()}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Number of People in Team</b>}>{agentCompany.getNumberOfPeopleInTeam()}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Descriptions title={<span style={titleStyle}>Address</span>} bordered column={1}>
            <Descriptions.Item label={<b style={labelStyle}>City</b>}>{agentCompany.getAddress().city}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>State</b>}>{agentCompany.getAddress().state}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Postal</b>}>{agentCompany.getAddress().postal}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Country</b>}>{agentCompany.getAddress().country}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Address 1</b>}>{agentCompany.getAddress().address_1}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Address 2</b>}>{agentCompany.getAddress().address_2}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Descriptions title={<span style={titleStyle}>Registered Country Detail</span>} bordered column={1}>
            <Descriptions.Item label={<b style={labelStyle}>Country</b>}>{agentCompany.getRegisteredCountryDetail().name}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Country Code</b>}>{agentCompany.getRegisteredCountryDetail().code}</Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Status</b>}>
              <StatusTag slug={status} text={status} />
            </Descriptions.Item>
            <Descriptions.Item label={<b style={labelStyle}>Created At</b>}>
              <Tag color="purple">{createdAt}</Tag>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};

export default AgentCompanyCard;
