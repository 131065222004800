
export enum PriceAdjustmentsActionEnum {
    SET_TO_FIXED = "setToFixed",
    INCREMENT_BY_FIXED = "incrementByFixed",
    INCREMENT_BY_PERCENT = "incrementByPercent",
    DECREMENT_BY_FIXED = "decrementByFixed",
    DECREMENT_BY_PERCENT = "decrementByPercent",
    INCREMENT_BY_MULTIPLY = "incrementByMultiply",
}


export enum FormNameEnum {
    UPDATE_RAW_PRODUCT_OFFER_PRICE_FORM = "updateRawProductOfferPriceForm",
    UPDATE_RAW_PRODUCT_ORIGINAL_PRICE_FORM = "updateRawProductOriginalPriceForm",
    UPDATE_RAW_PRODUCT_STOCK_FORM = "updateRawProductStockForm",
    ADD_RAW_PRODUCT_TO_CAMPAIGN_FORM = "addRawProductToCampaignForm",
}


export interface IAddRawProductToCampaignForm {
    productId: string;
    title: string;
    variation_original_price: Record<string, number>;
    variation_offer_price: Record<string, number>;
    variation_stock: Record<string, number>;
    configuration: {
        hike_type: "percentage" | "fixed";
        price_hike: number;
        discount_type: "fixed" | "percentage";
        discount: number;
    };
}
