import {
  IBuyPlaceOrderCartFormData,
  IDeleteCartProduct,
} from "types/buyOrderPlace";
import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";

class PurchaseCartResource extends BaseResource {
  get(
    user_id: number): ResponsePromise {
    const path = `/api/purchase-cart/admin/v1/carts?user_id=${user_id}`;
     return this.client.request("GET", path)
  }

  cartProduct(
    region: string,
    locale: string,
    payload: IBuyPlaceOrderCartFormData,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `/api/purchase-cart/admin/v1/cart-items?locale=${locale}&region=${region}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
    // return  this.axiosClient.post(path, payload, customHeaders)
  }

  cartDeleteProduct(
    region: string,
    locale: string,
    payload: IDeleteCartProduct,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `/api/purchase-cart/admin/v1/cart-items/destroy?locale=${locale}&region=${region}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
}

export default PurchaseCartResource;
