import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { customerUserCollectionAtom } from "lib/core-react/store/store";
import {
  CustomerUserPostRequestModel,
  CustomerUserUpdateRequestModel,
} from "models/customerUserCollectionModel";

export const useCreateCustomerUser = () => {
  const { customerUserService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [customerUserData, setCustomerUserData] = useAtom(
    customerUserCollectionAtom
  );

  const createCustomerUser = async (payload: CustomerUserPostRequestModel) => {
    setIsLoading(true);
    try {
      const response = await customerUserService.customerUserResource.create(
        payload
      );
      setIsLoading(false);
      setCustomerUserData({ ...customerUserData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createCustomerUser, isLoading, isError };
};

export const useUpdateCustomerUser = () => {
  const { customerUserService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [customerUserData, setCustomerUserData] = useAtom(
    customerUserCollectionAtom
  );

  const updateCustomerUser = async (
    id: number,
    payload: CustomerUserUpdateRequestModel
  ) => {
    setIsLoading(true);
    try {
      const response = await customerUserService.customerUserResource.update(
        id,
        payload
      );
      setIsLoading(false);
      setCustomerUserData({ ...customerUserData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateCustomerUser, isLoading, isError };
};

export const useGetCustomerUser = () => {
  const [customerUser, setCustomerUser] = useAtom(customerUserCollectionAtom);

  const { customerUserService } = useService();

  const getCustomerUser = async (params?: string): Promise<void> => {
    setCustomerUser({ ...customerUser, isLoading: true, error: null });
    try {
      const response = await customerUserService.customerUserResource.get(
        params
      );
      setCustomerUser({
        ...customerUser,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setCustomerUser({
        ...customerUser,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getCustomerUser } as const;
};

// export const useDeleteCourierGateway = () => {
//   const { courierService } = useService();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");
//   const [courierGatewayData, setCourierGatewayData] = useAtom(
//     courierCourierGateway
//   );

//   const deleteCourierGateway = async (id: number) => {
//     setIsLoading(true);
//     try {
//       const response = await courierService.courierGatewayResource.delete(id);
//       setIsLoading(false);
//       setCourierGatewayData({ ...courierGatewayData, refetch: true });
//       return response.data;
//     } catch (error: any) {
//       setError(getError(error));
//       setIsLoading(false);
//       throw error;
//     }
//   };
//   return { deleteCourierGateway, isLoading, isError };
// };
