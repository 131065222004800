import { V1Resource } from "lib/core/resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class V1Service extends CommerceApiService {
  public v1Resource: V1Resource;

  constructor() {
    super();
    this.v1Resource = new V1Resource(this.config);
  }
}

export default V1Service;
