import { useAtom } from "jotai";
import { useState } from "react";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import { buyProductTimelineTrackingAtom } from "lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import {
  ITrackerAddCommentFormData,
  ITrackerReplyCommentFormData,
} from "types/timelineTracking";
import { shipmentProductTimelineTrackingAtom } from "lib/core-react/store/shipmentOrder/shipmentOrderAtom";

export const useProductCreateComment = () => {
  const { trackService } = useService();
  const { getTimelineTracking } = useBuyProductGetTimelineTracking();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [shipmentProductTimelineTracking, setShipmentProductTimelineTracking] =
    useAtom(shipmentProductTimelineTrackingAtom);
  const [buyProductTimelineTracking, setBuyProductTimelineTracking] = useAtom(
    buyProductTimelineTrackingAtom,
  );

  const createComment = async (payload: ITrackerAddCommentFormData, productId:number) => {
    setIsLoading(true);
    try {
      const response = await trackService.trackResource.createComment(payload);

      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        refetch: true,
      });
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        refetch: true,
      });
      setIsLoading(false);
      await getTimelineTracking(productId)
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createComment, isLoading, error };
};

export const useProductUpdateComment = () => {
  const { trackService } = useService();
  const { getTimelineTracking } = useBuyProductGetTimelineTracking();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [shipmentProductTimelineTracking, setShipmentProductTimelineTracking] =
    useAtom(shipmentProductTimelineTrackingAtom);
  const [buyProductTimelineTracking, setBuyProductTimelineTracking] = useAtom(
    buyProductTimelineTrackingAtom,
  );

  const updateComment = async (
    id: number,
    payload: ITrackerAddCommentFormData,
    productId:number
  ) => {
    setIsLoading(true);
    try {
      const response = await trackService.trackResource.updateComment(
        id,
        payload,
      );
      await getTimelineTracking(productId)
      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        refetch: true,
      });
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        refetch: true,
      });
      setIsLoading(false);
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { updateComment, isLoading, error };
};

export const useProductDeleteComment = () => {
  const { trackService } = useService();
  const { getTimelineTracking } = useBuyProductGetTimelineTracking();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [shipmentProductTimelineTracking, setShipmentProductTimelineTracking] =
    useAtom(shipmentProductTimelineTrackingAtom);
  const [buyProductTimelineTracking, setBuyProductTimelineTracking] = useAtom(
    buyProductTimelineTrackingAtom,
  );

  const deleteComment = async (id: number, productId:number) => {
    setIsLoading(true);
    try {
      const response = await trackService.trackResource.deleteComment(id);
                       await getTimelineTracking(productId)

      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        refetch: true,
      });
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        refetch: true,
      });
      setIsLoading(false);
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { deleteComment, isLoading, error };
};

export const useProductReplyComment = () => {
  const { trackService } = useService();
  const { getTimelineTracking } = useBuyProductGetTimelineTracking();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [shipmentProductTimelineTracking, setShipmentProductTimelineTracking] =
    useAtom(shipmentProductTimelineTrackingAtom);
  const [buyProductTimelineTracking, setBuyProductTimelineTracking] = useAtom(
    buyProductTimelineTrackingAtom,
  );

  const replyComment = async (
    id: number,
    payload: ITrackerReplyCommentFormData,
    productId:number
  ) => {
    setIsLoading(true);
    try {
      const response = await trackService.trackResource.replyComment(
        id,
        payload,
      );

      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        refetch: true,
      });
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        refetch: true,
      });
      setIsLoading(false);
      await getTimelineTracking(productId)
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { replyComment, isLoading, error };
};

export const useBuyProductGetTimelineTracking = () => {
  const [buyProductTimelineTracking, setBuyProductTimelineTracking] = useAtom(
    buyProductTimelineTrackingAtom,
  );
  const { trackService } = useService();
  const getTimelineTracking = async (
    productId: number,
    params?: string,
  ): Promise<void> => {
    setBuyProductTimelineTracking({
      ...buyProductTimelineTracking,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await trackService.trackResource.getTimelineTrackingBuyProduct(
          productId,
          params,
        );
      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBuyProductTimelineTracking({
        ...buyProductTimelineTracking,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getTimelineTracking } as const;
};

export const useShipmentProductGetTimelineTracking = () => {
  const [shipmentProductTimelineTracking, setShipmentProductTimelineTracking] =
    useAtom(shipmentProductTimelineTrackingAtom);
  const { trackService } = useService();
  const getTimelineTracking = async (
    productId: number,
    params?: string,
  ): Promise<void> => {
    setShipmentProductTimelineTracking({
      ...shipmentProductTimelineTracking,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await trackService.trackResource.getTimelineTrackingShipmentProduct(
          productId,
          params,
        );
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShipmentProductTimelineTracking({
        ...shipmentProductTimelineTracking,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getTimelineTracking } as const;
};
