import { useEffect, useState } from "react";
import { Button, Modal, Tag, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
    ArrowRightOutlined,
    EditOutlined,
    ShopOutlined,
    UserOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import StoreIcon from "assets/storeIcon";
import { StatusTag } from "../../../components";
import { UpdateFax } from "../BuyProducts/BuyProducts";
import UpdateFxModal from "../BuyProducts/components/UpdateFxModal";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
import { ITransactionSummary } from "types/buyOrderCollection";

export interface IProductInfo {
    productTitle: { title: string; link: string };
    productSeller: { name: string; id: string };
    productId: string;
    buyproductid: number;
    orderId: string;
    productStatus: string;
    invoice: string;
    paidPercentage: string;
    store: string;
    fx: number;
    totalAmount: number;
    customerInvoiceSummary: ITransactionSummary;
    customerName: string;
    orderHandler: string;
    commissionRate: number;
    commissionType: string;
}
const ProductInfo = ({ productInfo }: { productInfo: IProductInfo }) => {
    const { Text } = Typography;

    const navigate = useNavigate();
    const [selectedBuyProductDetail, setSelectedBuyProductDetail] = useState<
        number | undefined
    >(undefined);

    const [totalValue, setTotalValue] = useState<number | string | null>();

    const [isSelectedFaxUpdate, setIsSelectedFaxUpdate] = useState<UpdateFax>();

    const [isUpdateFxModalOpen, setIsUpdateFxModalOpen] =
        useState<boolean>(false);

    const handleShowDetail = (record: number) => {
        setSelectedBuyProductDetail(record);
    };

    useEffect(() => {
        if (selectedBuyProductDetail !== undefined) {
            navigate(`/buy-and-ship-for-me/detail/${selectedBuyProductDetail}`);
        }
    }, [selectedBuyProductDetail]);

    const showUpdateFx = (operation) => {
        const payloadData = {
            product_id: operation.buyproductid,
            fxRate: operation.fx,
            commission_rate: operation.commissionRate,
            commission_type: operation.commissionType,
            totalFxAndCommission: operation.totalFxAndCommission,
        };
        setIsSelectedFaxUpdate(payloadData);
        setIsUpdateFxModalOpen(true);
    };

    useEffect(() => {
        if (productInfo.fx && productInfo.commissionRate) {
            let total = 0;
            if (
                productInfo.commissionType ===
                CommissionRateTypeEnums.PERCENTAGE
            ) {
                total =
                    productInfo.fx +
                    (productInfo.fx * productInfo.commissionRate) / 100;
            }
            setTotalValue(total.toFixed(2));
        }
    }, [productInfo]);

    return (
        <div>
            <p style={{ margin: "0 auto", fontWeight: "bold" }}>
                <span>
                    <ShopOutlined
                        style={{ color: "red", paddingRight: 4, width: 20 }}
                    />
                </span>
                {productInfo.productSeller.name}
            </p>
            <a
                href={productInfo.productTitle.link}
                target={"_blank"}
                rel="noreferrer"
            >
                {productInfo.productTitle.title.length >= 50
                    ? productInfo.productTitle.title.slice(0, 50) + "..."
                    : productInfo.productTitle.title}
            </a>

            <p style={{ margin: "1px 0" }}>
                PB:{" "}
                <Text type="success" copyable>
                    {productInfo.productId}
                </Text>
            </p>
            <p style={{ margin: "1px 0", marginTop: 5, marginBottom: 5 }}>
                Paid Percentage:{" "}
                <span
                    style={{
                        textTransform: "capitalize",
                    }}
                >
                    <Tooltip
                        title={`Total Amount - ${productInfo.totalAmount} , Total Due - ${productInfo.customerInvoiceSummary.due}`}
                    >
                        <Text strong>{productInfo.totalAmount}</Text>
                    </Tooltip>{" "}
                    <Tag color="#CA8A04">{productInfo.paidPercentage}%</Tag>
                </span>
            </p>
            <p style={{ margin: "1px 0" }}>
                Product Status:{" "}
                <StatusTag
                    text={productInfo.productStatus}
                    slug={productInfo.productStatus}
                />
            </p>
            <p style={{ margin: "1px 0" }}>
                FX:{" "}
                {productInfo.fx ? (
                    <Tooltip
                        placement="top"
                        title={`Commission ${productInfo.commissionRate}${
                            productInfo.commissionType ===
                            CommissionRateTypeEnums.PERCENTAGE
                                ? "%"
                                : ""
                        }, Total Profit - ${(
                            Number(totalValue) - productInfo.fx
                        ).toFixed(2)}`}
                    >
                        <Tag
                            style={{ textTransform: "capitalize" }}
                            color={"#EF4444"}
                        >
                            {productInfo.fx}
                        </Tag>
                    </Tooltip>
                ) : (
                    <span>
                        {checkActionPermission(
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
                            <Button onClick={() => showUpdateFx(productInfo)}>
                                <PlusOutlined style={{ color: "green" }} /> Add
                                FX
                            </Button>,
                            null,
                        )}
                    </span>
                )}
                {productInfo.fx &&
                    checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                        <EditOutlined
                            onClick={() => showUpdateFx(productInfo)}
                        />,
                        null,
                    )}
                {productInfo.fx && (
                    <Tooltip placement="top" title={"Total Fx"}>
                        <Tag color="red">{totalValue}</Tag>
                    </Tooltip>
                )}
            </p>
            {/* <p style={{ margin: "1px 0" }}>
        Add/View Note: <PlusOutlined />
      </p> */}

            <p style={{ margin: "1px 0" }}>
                <UserOutlined />{" "}
                <span
                    style={{
                        fontWeight: "600",
                    }}
                >
                    {productInfo.customerName}
                </span>
            </p>

            <p style={{ margin: "1px 0" }}>
                Order Handler:{" "}
                <span
                    style={{
                        fontWeight: "600",
                    }}
                >
                    {productInfo.orderHandler}
                </span>
            </p>

            {/* <p style={{ margin: "1px 0" }}>
        Customer Note: <PlusOutlined />
      </p> */}

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <StoreIcon
                    style={{
                        border: "1px solid #f7f7f7",
                        width: "25px",
                        height: "25px",
                        padding: "4px",
                        borderRadius: "100%",
                    }}
                />
                <Text style={{ marginLeft: "10px" }} type="success" strong>
                    {productInfo.store}
                </Text>
            </div>
            {checkActionPermission(
                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
                <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => handleShowDetail(productInfo.buyproductid)}
                >
                    View Details <ArrowRightOutlined />
                </Button>,
                null,
            )}

            {isSelectedFaxUpdate && (
                <Modal
                    title="Update Fx"
                    open={isUpdateFxModalOpen}
                    destroyOnClose={true}
                    onCancel={() => {
                        setIsUpdateFxModalOpen(false);
                    }}
                    footer={false}
                >
                    {checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,

                        <UpdateFxModal
                            fxData={isSelectedFaxUpdate}
                            setIsUpdateFxModalOpen={setIsUpdateFxModalOpen}
                        />,
                        null,
                    )}
                </Modal>
            )}
        </div>
    );
};

export default ProductInfo;
