
import CartApiService from "lib/core/apiServices/cartApiService";
import { HsCodeResource } from "lib/core/resources/private";

class HsCodeService extends CartApiService {
  public hsCodeResource: HsCodeResource;

  constructor() {
    super();

    this.hsCodeResource = new HsCodeResource(this.config);
  }
}
export default HsCodeService;
