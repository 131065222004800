import {Avatar, Button, Image, notification, Progress, type ProgressProps} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useItemFinishListener,
  useItemProgressListener,
  useItemErrorListener
} from "@rpldy/chunked-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";
import { useEffect, useState } from "react";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

const UploadButton = asUploadButton((props: any) => {
  return (
    <div {...props} style={{ cursor: "pointer" }}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </div>
  );
});

export const DivUploadButton = ({
  form,
  fieldName,
}: {
  form: any;
  fieldName: string;
}) => {
  const [images, setImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("")

  useItemFinishListener((item) => {
    const { results } = item.uploadResponse;
    const data = results[results.length - 1].data.data;
    const newUrl = data.url;
    setImages((prevImages) => {
      const updatedImages = new Set(prevImages);
      updatedImages.add(newUrl);
      return Array.from(updatedImages);
    });
  });
  useItemErrorListener((data) => {
    console.log(data.uploadResponse.chunkUploadResponse,data.uploadResponse )
    if(data.uploadResponse.chunkUploadResponse.status === 422){
      const error = data.uploadResponse.chunkUploadResponse.response.data.message
      notification.error({
        message:error
      })
      setErrorMessage(error)
    }
  });
  useEffect(() => {
    console.log(images, "Images state after update");
    // Optionally, you could delay this operation slightly to avoid any race conditions
    const timer = setTimeout(() => {
      form.setFieldValue(fieldName, images);
    }, 100);

    return () => clearTimeout(timer);
  }, [images, fieldName, form]);

  const progressData = useItemProgressListener();
  const handleRemoveImage = (url: string) => {
    setImages((prevImages) => prevImages.filter((imageUrl) => imageUrl !== url));
  };
  const handleCancelUpload = () => {
    setIsUploading(false)
    setErrorMessage("")
  };


  useEffect(() => {
    if (progressData && progressData.completed < 100) {
      setIsUploading(true);
    }
  }, [progressData]);

  return (
    <PasteUploadDropZone>
      <div style={{ cursor: "pointer" }}>
        {/* Render already uploaded images */}
        {images &&
          images.length > 0 &&
          images.map((url: string, index: number) => (
            <div key={index} style={{ position: "relative", display: "inline-block", marginRight: 10 }}>
              <Avatar
                style={{
                  marginBottom: 10,
                  objectFit: "contain",
                }}
                size={70}
                shape="square"
                src={<Image src={url} />}
              />
              <Button
                style={{ position: "absolute", top: 0, right: 0 }}
                type="text"
                danger
                onClick={() => handleRemoveImage(url)}
              >
                X
              </Button>
            </div>
          ))}

        {isUploading && progressData && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Progress
              style={{ marginBottom: 10 }}
              size={70}
              type="circle"
              format={() => {
                return (
                  <div style={{ textAlign: "center" }}>
                    {errorMessage && (
                      <div style={{ color: "red", fontSize: "8px", whiteSpace: "nowrap" }}>
                        {errorMessage}
                      </div>
                    )}
                    <div>
                      {Number(progressData.completed.toFixed(0))}%
                    </div>
                  </div>
                );
              }}
              percent={Number(progressData.completed.toFixed(0))}
              strokeColor={twoColors}
            />
            <Button
              style={{ marginLeft: 10 }}
              type="text"
              danger
              onClick={handleCancelUpload}
            >
              Cancel
            </Button>
          </div>
        )}

        <UploadButton />
      </div>
    </PasteUploadDropZone>    );
};
