import { Tag } from "antd";
import { ActionSequenceModel } from "models/buyActionCollectionModel";
interface IProps {
  data: ActionSequenceModel;
}
const ActionNeededIssuesType = ({ data }: IProps) => {


  return (
    <div
      style={{
        fontWeight: "bold",
        marginLeft: "10px",
      }}
    >
      Issue Type:{" "}
      <Tag
        color="processing"
        style={{
          marginTop: "3px",
        }}
      >
        {data.getType()}
      </Tag>
    </div>
  );
};

export default ActionNeededIssuesType;
