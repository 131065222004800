// export const LOCAL_STORAGE_KEY = "__moveon_admin_token__";
// export const MOVEON_ADMIN_AUTHORITY = "__moveon_admin_authority__";
export const SUPER_ADMIN = "super-admin";
export const LOCAL_STORAGE_AGENT_KEY = "__moveon_agent_token__";
export const LOCAL_STORAGE_AGENT_USER = "__moveon_agent_user__";

export const LOCAL_STORAGE_CUSTOMER_KEY = "__CUSTOMER_TOKEN__";
export const LOCAL_STORAGE_CUSTOMER_USER = "__CUSTOMER_USER__";

export const MOVEON_ADMIN_AUTHORITY_KEY = "__moveon_admin_authority__";
export const MOVEON_ADMIN_USER_KEY = "__moveon_admin_user__";
export const MOVEON_ADMIN_TOKEN_KEY = "__moveon_admin_token__";

export const FILE_UPLOAD_URL = `${process.env.REACT_APP_COMMERCE_SERVICE_HOST}/api/storage/admin/asset/v1/assets`;
export const FILE_UPLOAD_URL_TOKEKN = `${process.env.REACT_APP_ORY_TOKEN}`;
export const MAX_FILE_SIZE = 20;
export const NOVU_SUBSCRIBER_ID =
    process.env.REACT_APP_NOVU_SUBSCRIBER_ID || "";
export const NOVU_APPLICATION_IDENTIFIER =
    process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER || "";
