import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import { useState, useEffect } from "react";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { auctionRuleAtom } from "lib/core-react/store/store";
import { IFilterType } from "types/filters";
import FiltersComponent from "components/FiltersComponent";
import { PaginationModel } from "models/pagination";
import { AuctionRuleDataModel, AuctionRuleModel } from "models/auctionRule";
import { useGetAuctionRule } from "lib/core-react/hooks/private";
import UpdateAuctionRule from "./components/UpdateAuctionRule";
import CreateAuctionRule from "./components/CreateAuctionRule";
import { ApiHelperModel } from "models/apiHelper";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "components";
import { formatDate } from "utils/helpers";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";

const AuctionRule = () => {
  const { getAuctionRule } = useGetAuctionRule();
  const [{ data: auctionRules, isLoading, refetch }] = useAtom(auctionRuleAtom);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();
  const { isMobile } = useWindowWidth();

  // Api Call
  useEffect(() => {
    if (
      (filters && !isFetched && isFirstCall) ||
      refetch ||
      refetchFromFilter
    ) {
      filters && ApiHelperModel.makeGetRequest(filters, getAuctionRule);
    }
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  // Auction Rule
  const AuctionRulesData = auctionRules && new AuctionRuleModel(auctionRules);

  // Filter
  let filterData = AuctionRulesData?.filters;
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && AuctionRulesData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, AuctionRulesData?.filters]);
  // Create modal state
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  // Update modal state
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [updatePayload, setUpdatePayload] = useState<
    AuctionRuleDataModel | undefined
  >(undefined);
  // Show update modal with data
  const showModal = (record: any) => {
    setUpdatePayload(record);
    setIsShowUpdateModal(true);
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAuctionRule,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AuctionRulesData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAuctionRule);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Auction Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render() {
        return <Tag color="Green">{"Hello"}</Tag>;
      },
    },
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Rule Type",
      dataIndex: "rule_type",
      key: "rule_type",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, record: AuctionRuleDataModel) => {
        return <StatusTag slug={record.status} text={record.status} />;
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: string) => {
        return <Tag color="purple">{formatDate(created_at)}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: AuctionRuleDataModel) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission: ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_UPDATE,
            label: "Update",
            key: "update",
            icon: <EditOutlined />,
            onClick: async () => {
              showModal(record);
            },
          },
        ];

        return (
          <Dropdown
            menu={{
              items: items.filter((x) =>
                checkActionPermission(x.permission, x, null),
              ),
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Auction Rules"
          style={{ marginTop: "10px" }}
          extra={[
            checkActionPermission(
              ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_CREATE,
              <Button
                key="1"
                type="primary"
                onClick={() => setIsShowCreateModal(true)}
              >
                Create Auction Rule
              </Button>,
              null,
            ),
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={AuctionRulesData?.data}
              scroll={{ x: 1000 }}
              //@ts-ignore
              columns={columns}
            />
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`Update Auction Rule`}
        open={isShowUpdateModal}
        footer={false}
        onCancel={() => setIsShowUpdateModal(false)}
      >
        <UpdateAuctionRule
          setIsShowUpdateModal={setIsShowUpdateModal}
          data={updatePayload as AuctionRuleDataModel}
        />
      </Modal>

      <Modal
        title={`Create Auction Rule`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateAuctionRule setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>
    </>
  );
};

export default AuctionRule;
