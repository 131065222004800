import {
    Button,
    Form,
    InputNumber,
    Row,
    Col,
    Select,
    Typography,
    notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
    ExchangeListModel,
    StoreExchangeModel,
} from "../../../../models/exchangeListCollectionModel";
import { CommissionRateTypeEnums } from "../../../../enums/exchangeListCollectionEnums";
import {
    useGetExchange,
    useUpdateExchange,
} from "../../../../lib/core-react/hooks/private/useExchange";
import { IExchangeStoreUpdatePayload } from "../../../../types/exchangeListCollection";
import { handleFormErrors } from "utils/helper";

interface IProps {
    selectedExchange: ExchangeListModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedStore: StoreExchangeModel;
}

const { Text } = Typography;

const UpdateExchange = ({
    selectedExchange,
    setIsShowUpdateModal,
    selectedStore,
}: IProps) => {
    const [form] = Form.useForm();
    const { getExchange } = useGetExchange();
    const { updateExchange, isLoading } = useUpdateExchange();
    const [totalValue, setTotalValue] = useState<number | string | null>();

    useEffect(() => {
        form.resetFields();
    }, [form, selectedExchange, selectedStore]);

    useEffect(() => {
        setTotalValue(selectedStore.getTotal());
    }, [selectedStore]);

    const calculateTotalValue = (_changedValues: any, allValues: any) => {
        const { fx_rate, commission_rate, commission_rate_type } = allValues;
        if (fx_rate && commission_rate && commission_rate_type) {
            let total = 0;
            if (commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
                total = fx_rate + (fx_rate * commission_rate) / 100;
            } else {
                total = fx_rate + commission_rate;
            }
            setTotalValue(total.toFixed(2));
        }
    };

    const onFinish = async (values: any) => {
        const {
            fx_rate,
            commission_rate,
            commission_rate_type,
            minimum_order_amount,
        } = values;
        try {
            const data: IExchangeStoreUpdatePayload = {
                name: selectedExchange.getName(),
                regional_exchanges: [
                    {
                        regional_exchange_id: selectedExchange
                            .getRegionalExchanges()
                            .getData()[0]
                            .getId(),
                        store_exchanges: [
                            {
                                store_exchange_id: selectedStore.getId(),
                                fx_rate,
                                commission_rate,
                                commission_rate_type,
                                options: {
                                    minimum_order_amount,
                                },
                            },
                        ],
                    },
                ],
            };
            const res = await updateExchange(selectedExchange.getId(), data);
            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowUpdateModal(false);
                await getExchange({});
            }
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message
                ? e?.response?.data?.message
                : e.message;
            if (e.response.data.errors) {
                handleFormErrors(form, e.response);
            }
            notification["error"]({
                message: errorMessage,
            });
        }
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    fx_rate: selectedStore.getFxRate(),
                    commission_rate: selectedStore.getCommissionRate(),
                    commission_rate_type: selectedStore.getCommissionRateType(),
                    minimum_order_amount: selectedStore.getMinimumOrderAmount(),
                }}
                onValuesChange={calculateTotalValue}
            >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="fx_rate"
                            label="FX Rate"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the FX rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="commission_rate"
                            label="Commission Rate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input the commission rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="commission_rate_type"
                            label="Commission Rate Type"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please select the commission rate type!",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option
                                    value={CommissionRateTypeEnums.PERCENTAGE}
                                >
                                    Percentage
                                </Select.Option>
                                <Select.Option
                                    value={CommissionRateTypeEnums.FIXED}
                                >
                                    Fixed
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="minimum_order_amount"
                            label="Minimum Order Amount"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input the minimum order amount!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                {totalValue !== null && (
                    <Row gutter={16} style={{ marginBottom: "16px" }}>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Text strong>Total Value: {totalValue}</Text>
                        </Col>
                    </Row>
                )}

                <Form.Item>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            style={{ minWidth: "100px" }}
                            onClick={() => setIsShowUpdateModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={isLoading}
                            style={{ marginLeft: "10px", minWidth: "100px" }}
                            type="primary"
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default UpdateExchange;
