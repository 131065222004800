// Enum for admin permissions in the Destination module
export enum ADMIN_DESTINATION_PERMISSION_ENUM {
    // Destination Warehouse permissions
    ADMIN_DESTINATION_WAREHOUSE_VIEW = "admin-destination-warehouse-view",
    ADMIN_DESTINATION_WAREHOUSE_ADD = "admin-destination-warehouse-add",
    ADMIN_DESTINATION_WAREHOUSE_UPDATE = "admin-destination-warehouse-update",
    ADMIN_DESTINATION_WAREHOUSE_SYNC = "admin-destination-warehouse-sync",

    // Membership Invitation permissions
    ADMIN_DW_MEMBER_INVITATION_VIEW = "admin-dw-member-invitation-view",
    ADMIN_DW_MEMBER_INVITATION_ADD = "admin-dw-member-invitation-add",
    ADMIN_DW_MEMBER_INVITATION_RESEND = "admin-dw-member-invitation-resend",
    ADMIN_DW_MEMBER_INVITATION_DELETE = "admin-dw-member-invitation-delete",

    // Membership permissions
    ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW = "admin-destination-warehouse-member-view",
    ADMIN_DESTINATION_WAREHOUSE_MEMBER_UPDATE = "admin-destination-warehouse-member-update",
    ADMIN_DESTINATION_WAREHOUSE_MEMBER_DELETE = "admin-destination-warehouse-member-delete"
}

