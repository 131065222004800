import { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Form, Row, Skeleton, Space } from "antd";
import "./style.css";

import {
    useGetPermissionsByRole,
    useUpdateRole,
} from "lib/core-react/hooks/private/useRole";

import {
    PermissionCollectionModel,
    PermissionModel,
} from "models/permissionCollectionModel";
import { PermissionGroup } from "./PermissionGroup";
import { RoleModel, RoleUpdateRequestModel } from "models/roleCollectionModel";
import { v4 } from "uuid";

interface IProps {
    show: boolean;
    onHide: () => void;
    allPermissions: PermissionCollectionModel;
    role: RoleModel;
}
const PermissionsDrawer = ({ show, onHide, allPermissions, role }: IProps) => {
    const [form] = Form.useForm();
    const { getPermissionsByRole, isLoading } = useGetPermissionsByRole();
    const { updateRole, isLoading: updateLoading } = useUpdateRole();
    const [groupedPermissions, setGroupedPermissions] = useState<{
        [key: string]: PermissionModel[];
    }>({});

    const [_groupedPermissionIdsWithStatus, setGroupedPermissionIdsWithStatus] =
        useState<{
            [key: string]: { id: number; isChecked: boolean }[];
        }>({});

    const [selectedPermissionIds, setSelectedPermissionIds] =
        useState<number[]>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const permissionsResponse = await getPermissionsByRole(role.id);
                const groupedPermissions: { [key: string]: PermissionModel[] } =
                    {};
                const groupedPermissionIds: {
                    [key: string]: { id: number; isChecked: boolean }[];
                } = {};
                if (
                    allPermissions &&
                    permissionsResponse.selected_permission_ids
                ) {
                    allPermissions.data.forEach((permission) => {
                        const groupName = permission.group_name;

                        // grouper permissions
                        if (!groupedPermissions[groupName]) {
                            groupedPermissions[groupName] = [];
                        }
                        if (!groupedPermissionIds[groupName]) {
                            groupedPermissionIds[groupName] = [];
                        }
                        groupedPermissions[groupName].push(permission);
                        groupedPermissionIds[groupName].push({
                            id: permission.id,
                            isChecked:
                                permissionsResponse.selected_permission_ids.includes(
                                    permission.id,
                                ),
                        });
                    });

                    setGroupedPermissions(groupedPermissions);
                    setGroupedPermissionIdsWithStatus(groupedPermissionIds);
                    setSelectedPermissionIds(
                        permissionsResponse.selected_permission_ids,
                    );
                }
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchData();
    }, []);

    const updateFinalPermissions = (group: string, data: number[]) => {
        setGroupedPermissionIdsWithStatus((prevGroupedPermissionIds) => {
            const updatedGroup = [...(prevGroupedPermissionIds[group] || [])];

            updatedGroup.forEach((item, index) => {
                if (data.includes(item.id))
                    updatedGroup[index].isChecked = true;
                else updatedGroup[index].isChecked = false;
            });

            const updatedGroupedPermissionIds = {
                ...prevGroupedPermissionIds,
                [group]: updatedGroup,
            };

            const checkedIds: number[] = [];

            Object.values(updatedGroupedPermissionIds).forEach((group) => {
                group.forEach((item) => {
                    if (item.isChecked) {
                        checkedIds.push(item.id);
                    }
                });
            });

            setSelectedPermissionIds(checkedIds);

            return updatedGroupedPermissionIds;
        });
    };

    const handleSubmit = async () => {
        const payload: RoleUpdateRequestModel = {
            name: role.name,
            label: role.label,
            permission_ids: selectedPermissionIds ? selectedPermissionIds : [],
        };

        try {
            await updateRole(role.id, payload);
            onHide();
        } catch (error) {}
    };

    return (
        <Drawer
            title={`Update Permission`}
            width={500}
            onClose={onHide}
            open={show}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    {/* @ts-ignore FIXME */}
                    <Button onClick={onHide} rootstyle={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={updateLoading}
                    >
                        Update
                    </Button>
                </Space>
            }
        >
            <Row>
                <Col span={24}>
                    <Form layout="vertical" form={form} onFinish={handleSubmit}>
                        <Skeleton loading={isLoading} active>
                            {groupedPermissions &&
                                selectedPermissionIds &&
                                Object.entries(groupedPermissions).map(
                                    (item) => {
                                        return (
                                            <Row key={v4()}>
                                                <Col>
                                                    <div key={item[0]}>
                                                        <PermissionGroup
                                                            permission={item}
                                                            checkedPermission={
                                                                selectedPermissionIds
                                                            }
                                                            updateFinalPermissions={
                                                                updateFinalPermissions
                                                            }
                                                        />
                                                        <Divider />
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    },
                                )}
                        </Skeleton>
                    </Form>
                </Col>
            </Row>
        </Drawer>
    );
};

export default PermissionsDrawer;
