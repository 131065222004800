import { CourierGatewayStatus } from "enums/courierGatewayCollectionEnums";
import { PaginationModel } from "./paginationModel";
import {
  ICourierGateway,
  ICourierGatewayCollection,
} from "types/courierGatewayCollection";
import { CountryModel } from "./countryCollectionModel";
import {formatDateTime} from "../utils/helpers";

export class CourierGatewayModel {
  object: string;
  id: number;
  name: string;
  slug: string;
  country: CountryModel;
  status: CourierGatewayStatus;
  created_at: string;

  constructor(dataItem: ICourierGateway) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.slug = dataItem.slug;
    this.country = new CountryModel(dataItem.country);
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getName = () => this.name;
  getSlug = () => this.slug;
  getCountry = () => this.country;
  getStatus = () => this.status;
  getCreatedAt = () => formatDateTime(this.created_at);
}

export class CourierGatewayCollectionModel {
  object: string;
  data: CourierGatewayModel[];
  pagination: PaginationModel;

  constructor(data: ICourierGatewayCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CourierGatewayModel(item));
    this.pagination = new PaginationModel(data.pagination);
  }

  getData = () => this.data;
  getPagination = () => this.pagination;
  getCourierGatewayIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
}
