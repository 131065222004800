import { Typography } from "antd";
import React from "react";

const NotAvailableTracking = ({ text }) => {
    return (
        <div
            style={{
                padding: 12,
                backgroundColor: "white",
                width: "100%",
                borderRadius : 6
            }}
        >
            <Typography.Text
                strong
                style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                }}
            >
                {text} Not Available
            </Typography.Text>
        </div>
    );
};

export default NotAvailableTracking;
