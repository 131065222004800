import { Button, Form, message, Input } from "antd";
import { useCopyExchange } from "lib/core-react/hooks/private/useExchange";
import React, { useEffect } from "react";
import { IExchangeList } from "types/exchangeListCollection";
import { handleFormErrors } from "utils/helper";

interface IProps {
    selectedExchange: IExchangeList;
    setIsShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyExchange = ({ selectedExchange, setIsShowCopyModal }: IProps) => {
    const [form] = Form.useForm();
    const { copyExchange, isLoading } = useCopyExchange();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: { name: string }) => {
        const data = {
            name: value.name,
        };
        try {
            const response = await copyExchange(selectedExchange.id, data);
            setIsShowCopyModal(false);

            message.success(response.message);
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message
                ? e?.response?.data?.message
                : e.message;
            if (e.response.data.errors) {
                handleFormErrors(form, e.response);
            }
            message.error(errorMessage);
        }
    };

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item>
                    <h5>
                        Are you sure you want to make a copy of{" "}
                        {selectedExchange.name} ?
                    </h5>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: "Please enter name" }]}
                >
                    <Input placeholder="Enter the name" />
                </Form.Item>

                <Form.Item>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            style={{ minWidth: "100px" }}
                            onClick={() => setIsShowCopyModal(false)}
                        >
                            No
                        </Button>
                        <Button
                            htmlType="submit"
                            style={{ marginLeft: "10px", minWidth: "100px" }}
                            type="primary"
                            loading={isLoading}
                        >
                            Yes
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default CopyExchange;
