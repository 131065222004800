import CommerceApiService from "lib/core/apiServices/commerceApiService";
import { RfqResource } from "lib/core/resources/private/rfqResource";

class RfqService extends CommerceApiService {
  public rfqResource: RfqResource;

  constructor() {
    super();

    this.rfqResource = new RfqResource(this.config);
  }
}

export default RfqService;
