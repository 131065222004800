import { WalletResource } from "../../resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class WalletService extends CommerceApiService {
  public walletResource: WalletResource;

  constructor(){
    super();

    this.walletResource = new WalletResource(this.config)
  }
}

export default WalletService;
