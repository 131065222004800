import {
  IAddress,
  IAgentCompany,
  IAgentWarehouse,
  IBuyProduct,
  IBuyProductAgentAssociation,
  IBuyProductPackage,
  IBuyProductVariation,
  IBuyProductVariationCollection,
  IBuyProductVariationPropertiesCollection,
  IBuyProductVariationProperty,
  IBuyProductView,
  ICurrency,
  ICustomer,
  ICustomerInvoice,
  IDestinationWarehouse,
  IMoveOnShipping,
  IOrder,
  IOrderHandler,
  IProductCustomerAssociation,
  IProductSeller,
  IRegion,
  IShipmentProduct,
  IShippingAddress,
  IShippingAddressRefarance,
  IShippingCategory,
  IShippingCountry,
  IShippingSlot,
  IShippingSlotCollection,
  IStore,
  IUser,
} from "types/buyProductDetail";

export class BuyProductVariationPropertyModel {
  public readonly object: string;
  public readonly id: number;
  public readonly property_id: string;
  public readonly property_name: string;
  public readonly property_original_name: string;
  public readonly property_value_id: string;
  public readonly property_value_name: string;
  public readonly property_value_color: string | null;
  public readonly property_value_title: string;
  public readonly property_value_image: string | null;
  public readonly property_value_thumb: string | null;

  constructor(data: IBuyProductVariationProperty) {
    this.object = data.object;
    this.id = data.id;
    this.property_id = data.property_id;
    this.property_name = data.property_name;
    this.property_original_name = data.property_original_name;
    this.property_value_id = data.property_value_id;
    this.property_value_name = data.property_value_name;
    this.property_value_color = data.property_value_color;
    this.property_value_title = data.property_value_title;
    this.property_value_image = data.property_value_image;
    this.property_value_thumb = data.property_value_thumb;
  }

  getId() {
    return this.id;
  }

  getPropertyId() {
    return this.property_id;
  }

  getPropertyName() {
    return this.property_name;
  }

  getPropertyOriginalName() {
    return this.property_original_name;
  }

  getPropertyValueId() {
    return this.property_value_id;
  }

  getPropertyValueName() {
    return this.property_value_name;
  }

  getPropertyValueColor() {
    return this.property_value_color;
  }

  getPropertyValueTitle() {
    return this.property_value_title;
  }

  getPropertyValueImage() {
    return this.property_value_image;
  }

  getPropertyValueThumb() {
    return this.property_value_thumb;
  }
}

export class BuyProductVariationPropertiesCollectionModel {
  public readonly object: string;
  public readonly data: BuyProductVariationPropertyModel[];

  constructor(data: IBuyProductVariationPropertiesCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (item) => new BuyProductVariationPropertyModel(item),
    );
  }

  getPropertyById(id: number): BuyProductVariationPropertyModel | undefined {
    return this.data.find((property) => property.getId() === id);
  }

  getData() {
    return this.data;
  }
}

export class BuyProductVariationModel {
  public readonly object: string;
  public readonly id: number;
  public readonly buy_product_id: number;
  public readonly cart_item_variation_id: number;
  public readonly sku_id: string;
  public readonly image: string | null;
  public readonly quantity: number;
  public readonly unit_price: number;
  public readonly total_price: number;
  public readonly original_unit_price: number;
  public readonly original_total_price: number;
  public readonly properties: BuyProductVariationPropertiesCollectionModel;

  constructor(data: IBuyProductVariation) {
    this.object = data.object;
    this.id = data.id;
    this.buy_product_id = data.buy_product_id;
    this.cart_item_variation_id = data.cart_item_variation_id;
    this.sku_id = data.sku_id;
    this.image = data.image;
    this.quantity = data.quantity;
    this.unit_price = data.unit_price;
    this.total_price = data.total_price;
    this.original_unit_price = data.original_unit_price;
    this.original_total_price = data.original_total_price;
    this.properties = new BuyProductVariationPropertiesCollectionModel(
      data.properties,
    );
  }

  getId() {
    return this.id;
  }

  getBuyProductId() {
    return this.buy_product_id;
  }

  getCartItemVariationId() {
    return this.cart_item_variation_id;
  }

  getSkuId() {
    return this.sku_id;
  }

  getImage() {
    return this.image;
  }

  getQuantity() {
    return this.quantity;
  }

  getUnitPrice() {
    return this.unit_price;
  }

  getTotalPrice() {
    return this.total_price;
  }

  getOriginalUnitPrice() {
    return this.original_unit_price;
  }

  getOriginalTotalPrice() {
    return this.original_total_price;
  }

  getProperties() {
    return this.properties;
  }
}

export class BuyProductVariationCollectionModel {
  public readonly object: string;
  public readonly data: BuyProductVariationModel[];

  constructor(data: IBuyProductVariationCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new BuyProductVariationModel(item));
  }

  getData() {
    return this.data;
  }

  getBuyProductVariationById(id: number): BuyProductVariationModel | undefined {
    return this.data.find((variation) => variation.getId() === id);
  }
}

export class CustomerInvoiceModel {
  public readonly object: string;
  public readonly id: number;
  public readonly invoice_number: string;
  public readonly type: string | null;
  public readonly product_number: string | null;
  public readonly status: string;
  public readonly total_amount: number;
  public readonly refundable_amount: number;
  public readonly transaction_summary: {
    paid: number;
    due: number;
    overpaid: number;
  };
  public readonly created_at: string;

  constructor(data: ICustomerInvoice) {
    this.object = data.object;
    this.id = data.id;
    this.invoice_number = data.invoice_number;
    this.type = data.type;
    this.product_number = data.product_number;
    this.status = data.status;
    this.total_amount = data.total_amount;
    this.refundable_amount = data.refundable_amount;
    this.transaction_summary = data.transaction_summary;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getInvoiceNumber() {
    return this.invoice_number;
  }

  getType() {
    return this.type;
  }

  getProductNumber() {
    return this.product_number;
  }

  getStatus() {
    return this.status;
  }

  getTotalAmount() {
    return this.total_amount;
  }

  getRefundableAmount() {
    return this.refundable_amount;
  }

  getTransctionSummary() {
    return this.transaction_summary;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class BuyProductPackageModel {
  public readonly object: string;
  public readonly id: number;
  public readonly buy_product_id: number;
  public readonly weight: number;
  public readonly weight_unit: string;
  public readonly length: number;
  public readonly length_unit: string | null;
  public readonly width: number;
  public readonly width_unit: string;
  public readonly height: number;
  public readonly height_unit: string;

  constructor(data: IBuyProductPackage) {
    this.object = data.object;
    this.id = data.id;
    this.buy_product_id = data.buy_product_id;
    this.weight = data.weight;
    this.weight_unit = data.weight_unit;
    this.length = data.length;
    this.length_unit = data.length_unit;
    this.width = data.width;
    this.width_unit = data.width_unit;
    this.height = data.height;
    this.height_unit = data.height_unit;
  }

  getId() {
    return this.id;
  }

  getBuyProductId() {
    return this.buy_product_id;
  }

  getWeight() {
    return this.weight;
  }

  getWeightUnit() {
    return this.weight_unit;
  }

  getLength() {
    return this.length;
  }

  getLengthUnit() {
    return this.length_unit;
  }

  getWidth() {
    return this.width;
  }

  getWidthUnit() {
    return this.width_unit;
  }

  getHeight() {
    return this.height;
  }

  getHeightUnit() {
    return this.height_unit;
  }
}

export class BuyProductAgentAssociationModel {
  public readonly object: string;
  public readonly id: number;
  public readonly buy_product_id: number;
  public readonly agent_company_id: number;
  public readonly include_tax: number;
  public readonly sub_total: number;
  public readonly tax_total: number;
  public readonly total: number;
  public readonly original_sub_total: number;
  public readonly original_total: number;
  public readonly discount_total: number;
  public readonly fx_rate: number;
  public readonly commission_rate: number;
  public readonly commission_rate_type: string;
  public readonly created_at: string;
  public readonly purchase_note: string;
  public readonly vendor_order_id: string;

  constructor(data: IBuyProductAgentAssociation) {
    this.object = data.object;
    this.id = data.id;
    this.buy_product_id = data.buy_product_id;
    this.agent_company_id = data.agent_company_id;
    this.include_tax = data.include_tax;
    this.sub_total = data.sub_total;
    this.tax_total = data.tax_total;
    this.total = data.total;
    this.original_sub_total = data.original_sub_total;
    this.original_total = data.original_total;
    this.discount_total = data.discount_total;
    this.fx_rate = data.fx_rate;
    this.commission_rate = data.commission_rate;
    this.commission_rate_type = data.commission_rate_type;
    this.created_at = data.created_at;
    this.purchase_note = data.purchase_note;
    this.vendor_order_id = data.vendor_order_id;
  }

  getId() {
    return this.id;
  }

  getPurchaseNote() {
    return this.purchase_note;
  }

  getVendorOrderId() {
    return this.vendor_order_id;
  }

  getBuyProductId() {
    return this.buy_product_id;
  }

  getAgentCompanyId() {
    return this.agent_company_id;
  }

  getIncludeTax() {
    return this.include_tax;
  }

  getSubTotal() {
    return this.sub_total;
  }

  getTaxTotal() {
    return this.tax_total;
  }

  getTotal() {
    return this.total;
  }

  getOriginalSubTotal() {
    return this.original_sub_total;
  }

  getOriginalTotal() {
    return this.original_total;
  }

  getDiscountTotal() {
    return this.discount_total;
  }

  getFxRate() {
    return this.fx_rate;
  }

  getCommissionRate() {
    return this.commission_rate;
  }

  getCommissionRateType() {
    return this.commission_rate_type;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class ProductCustomerAssociationModel {
  public readonly object: string;
  public readonly id: number;
  public readonly buy_product_id: number;
  public readonly include_tax: number;
  public readonly sub_total: number;
  public readonly tax_total: number;
  public readonly total: number;
  public readonly original_sub_total: number;
  public readonly original_total: number;
  public readonly discount_total: number;
  public readonly fx_rate: number;
  public readonly commission_rate: number;
  public readonly commission_rate_type: string;

  constructor(data: IProductCustomerAssociation) {
    this.object = data.object;
    this.id = data.id;
    this.buy_product_id = data.buy_product_id;
    this.include_tax = data.include_tax;
    this.sub_total = data.sub_total;
    this.tax_total = data.tax_total;
    this.total = data.total;
    this.original_sub_total = data.original_sub_total;
    this.original_total = data.original_total;
    this.discount_total = data.discount_total;
    this.fx_rate = data.fx_rate;
    this.commission_rate = data.commission_rate;
    this.commission_rate_type = data.commission_rate_type;
  }

  getId(): number {
    return this.id;
  }

  getBuyProductId(): number {
    return this.buy_product_id;
  }

  getIncludeTax(): number {
    return this.include_tax;
  }

  getSubTotal(): number {
    return this.sub_total;
  }

  getTaxTotal(): number {
    return this.tax_total;
  }

  getTotal(): number {
    return this.total;
  }

  getOriginalSubTotal(): number {
    return this.original_sub_total;
  }

  getOriginalTotal(): number {
    return this.original_total;
  }

  getDiscountTotal(): number {
    return this.discount_total;
  }

  getFxRate(): number {
    return this.fx_rate;
  }

  getCommissionRate(): number {
    return this.commission_rate;
  }

  getCommissionRateType(): string {
    return this.commission_rate_type;
  }
}

export class ProductSellerModel {
  public readonly object: string;
  public readonly id: number;
  public readonly seller_id: string;
  public readonly seller_name: string;

  constructor(data: IProductSeller) {
    this.object = data.object;
    this.id = data.id;
    this.seller_id = data.seller_id;
    this.seller_name = data.seller_name;
  }

  getId() {
    return this.id;
  }

  getSellerId() {
    return this.seller_id;
  }

  getSellerName() {
    return this.seller_name;
  }
}

export class StoreModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly identifier: string;
  public readonly availability_status: string;

  constructor(data: IStore) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.identifier = data.identifier;
    this.availability_status = data.availability_status;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getIdentifier() {
    return this.identifier;
  }

  getAvailabilityStatus() {
    return this.availability_status;
  }
}

export class OrderHandlerModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly phone: string | null;
  public readonly email: string;
  public readonly shipping_mark: string;
  public readonly type: string;
  public readonly is_drop_user: boolean;
  public readonly is_active: number;
  public readonly gravatar: string;
  public readonly created_at: string;

  constructor(data: IOrderHandler) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.shipping_mark = data.shipping_mark;
    this.type = data.type;
    this.is_drop_user = data.is_drop_user;
    this.is_active = data.is_active;
    this.gravatar = data.gravatar;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getPhone() {
    return this.phone;
  }

  getEmail() {
    return this.email;
  }

  getShippingMark() {
    return this.shipping_mark;
  }

  getType() {
    return this.type;
  }

  getIsDropUser() {
    return this.is_drop_user;
  }

  getIsActive() {
    return this.is_active;
  }

  getGravatar() {
    return this.gravatar;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class ShippingAddressModel {
  public readonly object: string;
  public readonly id: number;
  public readonly address: {
    area?: string;
    email?: string;
    phone?: string;
    country?: string;
    district?: string;
    address_1?: string;
    address_2?: string;
    last_name?: string;
    first_name?: string;
    postal_code?: string;
  };
  public readonly is_default_shipping: number;
  public readonly is_default_billing: number;
  public readonly labels: {
    object?: string;
    data?: any[];
  };

  constructor(data: IShippingAddressRefarance) {
    this.object = data.object;
    this.id = data.id;
    this.address = {
      area: data.address?.area,
      email: data.address?.email,
      phone: data.address?.phone,
      country: data.address?.country,
      district: data.address?.district,
      address_1: data.address?.address_1,
      address_2: data.address?.address_2,
      last_name: data.address?.last_name,
      first_name: data.address?.first_name,
      postal_code: data.address?.postal_code,
    };
    this.is_default_shipping = data.is_default_shipping;
    this.is_default_billing = data.is_default_billing;
    this.labels = {
      object: data?.labels?.object,
      data: data?.labels?.data,
    };
  }

  getAddress(): {
    area?: string;
    email?: string;
    phone?: string;
    country?: string;
    district?: string;
    address_1?: string;
    address_2?: string;
    last_name?: string;
    first_name?: string;
    postal_code?: string;
  } {
    return this.address;
  }

  getIsDefaultShipping(): number {
    return this.is_default_shipping;
  }

  getIsDefaultBilling(): number {
    return this.is_default_billing;
  }

  getLabels() {
    return this.labels;
  }
}

export class CustomerModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly phone: string;
  public readonly email: string;
  public readonly shipping_mark: string;
  public readonly created_at: string;

  constructor(data: ICustomer) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.shipping_mark = data.shipping_mark;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getPhone() {
    return this.phone;
  }

  getEmail() {
    return this.email;
  }

  getShippingMark() {
    return this.shipping_mark;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class OrderModel {
  public readonly object: string;
  public readonly id: number;
  public readonly order_number: string;
  public readonly order_tag: string | null;
  public readonly type: string;
  public readonly status: string;
  public readonly payment_status: string;
  public readonly order_handler: OrderHandlerModel | null;
  public readonly customer: CustomerModel;
  public readonly meta: any | null;
  public readonly oauth_client_id: string | null;
  public readonly created_at: string;

  constructor(data: IOrder) {
    this.object = data.object;
    this.id = data.id;
    this.order_number = data.order_number;
    this.order_tag = data.order_tag;
    this.type = data.type;
    this.status = data.status;
    this.payment_status = data.payment_status;
    this.order_handler = data.order_handler
      ? new OrderHandlerModel(data.order_handler)
      : null;
    this.customer = new CustomerModel(data.customer);
    this.meta = data.meta;
    this.oauth_client_id = data.oauth_client_id;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getOrderNumber() {
    return this.order_number;
  }

  getOrderTag() {
    return this.order_tag;
  }

  getType() {
    return this.type;
  }

  getStatus() {
    return this.status;
  }

  getPaymentStatus() {
    return this.payment_status;
  }

  getOrderHandler() {
    return this.order_handler;
  }

  getCustomer() {
    return this.customer;
  }

  getMeta() {
    return this.meta;
  }

  getOauthClientId() {
    return this.oauth_client_id;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class CurrencyModel {
  public readonly object: string;
  public readonly id: number;
  public readonly code: string;
  public readonly name: string;
  public readonly symbol: string;
  public readonly symbol_native: string;

  constructor(data: ICurrency) {
    this.object = data.object;
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.symbol = data.symbol;
    this.symbol_native = data.symbol_native;
  }

  getId() {
    return this.id;
  }

  getCode() {
    return this.code;
  }

  getName() {
    return this.name;
  }

  getSymbol() {
    return this.symbol;
  }

  getSymbolNative() {
    return this.symbol_native;
  }
}

export class RegionModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly code: string;
  public readonly store_type: string;
  public readonly store: string;
  public readonly currency: CurrencyModel;

  constructor(data: IRegion) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.store_type = data.store_type;
    this.store = data.store;
    this.currency = new CurrencyModel(data.currency);
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getCode() {
    return this.code;
  }

  getStoreType() {
    return this.store_type;
  }

  getStore() {
    return this.store;
  }

  getCurrency() {
    return this.currency;
  }
}

export class AgentCompanyModel {
  public readonly object: string;
  public readonly id: number;
  public readonly primary_name: string;
  public readonly secondary_name: string;
  public readonly slug: string;
  public readonly operation_status: string;
  public readonly verification_status: string;
  public readonly registered_country: number;
  public readonly registered_country_detail: any;
  public readonly company_type: string;
  public readonly director_name: string;
  public readonly designation: string;
  public readonly business_phone: string;
  public readonly years_of_experience: string;
  public readonly number_of_people_in_team: string;
  public readonly address: AddressModel;

  constructor(data: IAgentCompany) {
    this.object = data.object;
    this.id = data.id;
    this.primary_name = data.primary_name;
    this.secondary_name = data.secondary_name;
    this.slug = data.slug;
    this.operation_status = data.operation_status;
    this.verification_status = data.verification_status;
    this.registered_country = data.registered_country;
    this.registered_country_detail = data.registered_country_detail;
    this.company_type = data.company_type;
    this.director_name = data.director_name;
    this.designation = data.designation;
    this.business_phone = data.business_phone;
    this.years_of_experience = data.years_of_experience;
    this.number_of_people_in_team = data.number_of_people_in_team;
    this.address = new AddressModel(data.address);
  }

  getId() {
    return this.id;
  }

  getPrimaryName() {
    return this.primary_name;
  }

  getSecondaryName() {
    return this.secondary_name;
  }

  getSlug() {
    return this.slug;
  }

  getOperationStatus() {
    return this.operation_status;
  }

  getVerificationStatus() {
    return this.verification_status;
  }

  getRegisteredCountry() {
    return this.registered_country;
  }

  getRegisteredCountryDetail() {
    return this.registered_country_detail;
  }

  getCompanyType() {
    return this.company_type;
  }

  getDirectorName() {
    return this.director_name;
  }

  getDesignation() {
    return this.designation;
  }

  getBusinessPhone() {
    return this.business_phone;
  }

  getYearsOfExperience() {
    return this.years_of_experience;
  }

  getNumberOfPeopleInTeam() {
    return this.number_of_people_in_team;
  }

  getAddress() {
    return this.address;
  }
}

export class AddressModel {
  public readonly city: string;
  public readonly state: string;
  public readonly postal: string;
  public readonly country: string;
  public readonly address_1: string;
  public readonly address_2: string;

  constructor(data: IAddress) {
    this.city = data.city;
    this.state = data.state;
    this.postal = data.postal;
    this.country = data.country;
    this.address_1 = data.address_1;
    this.address_2 = data.address_2;
  }

  getCity() {
    return this.city;
  }

  getState() {
    return this.state;
  }

  getPostal() {
    return this.postal;
  }

  getCountry() {
    return this.country;
  }

  getAddress1() {
    return this.address_1;
  }

  getAddress2() {
    return this.address_2;
  }
}

export class AgentWarehouseModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly shipping_mark: string;
  public readonly operation_status: string;
  public readonly status: string;
  public readonly agent_company: AgentCompanyModel;

  constructor(data: IAgentWarehouse) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.shipping_mark = data.shipping_mark;
    this.operation_status = data.operation_status;
    this.status = data.status;
    this.agent_company = new AgentCompanyModel(data.agent_company);
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getShippingMark() {
    return this.shipping_mark;
  }

  getOperationStatus() {
    return this.operation_status;
  }

  getStatus() {
    return this.status;
  }

  getAgentCompany() {
    return this.agent_company;
  }
}

export class ShippingCountryModel {
  public readonly object: string;
  public readonly id: number;

  constructor(data: IShippingCountry) {
    this.object = data.object;
    this.id = data.id;
  }

  getById() {
    return this.id;
  }

  getObject() {
    return this.object;
  }
}

export class UserModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly phone: string;
  public readonly email: string;
  public readonly shipping_mark: string;
  public readonly created_at: string;

  constructor(data: IUser) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.shipping_mark = data.shipping_mark;
    this.created_at = data.created_at;
  }

  getName(): string {
    return this.name;
  }

  getId(): number {
    return this.id;
  }

  getPhone(): string {
    return this.phone;
  }

  getEmail(): string {
    return this.email;
  }

  getShippingMark(): string {
    return this.shipping_mark;
  }

  getCreatedAt(): string {
    return this.created_at;
  }
}

export class DestinationWarehouseModel {
  public readonly object: string;
  public readonly id: number;
  public readonly slug: string;
  public readonly name: string;

  constructor(data: IDestinationWarehouse) {
    this.object = data.object;
    this.id = data.id;
    this.slug = data.slug;
    this.name = data.name;
  }

  getById() {
    return this.id;
  }

  getSlug() {
    return this.slug;
  }

  getName() {
    return this.name;
  }
}

export class ShippingCategoryModel {
  public readonly object: string;
  public readonly id: string;
  public readonly parent_id: string | null;
  public readonly name: string;
  public readonly slug: string;
  public readonly children: any[];

  constructor(data: IShippingCategory) {
    this.object = data.object;
    this.id = data.id;
    this.parent_id = data.parent_id;
    this.name = data.name;
    this.slug = data.slug;
    this.children = data.children;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getSlug() {
    return this.slug;
  }
}

export class ShipmentProductModel {
  public readonly object: string;
  public readonly id: number;
  public readonly product_number: string;
  public readonly shipping_category?: ShippingCategoryModel;
  public readonly shipping_address: IShippingAddress;
  public readonly shipping_address_reference: ShippingAddressModel;
  public readonly shipment_instruction: string | null;
  public readonly shipping_mode: string;
  public readonly shipping_type: string;
  public readonly status: string;

  constructor(data: IShipmentProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product_number = data.product_number;
    this.shipping_category = data.shipping_category
      ? new ShippingCategoryModel(data.shipping_category)
      : undefined;
    this.shipping_address_reference =
      data.shipping_address_reference &&
      new ShippingAddressModel(data.shipping_address_reference);
    this.shipping_address = data.shipping_address;
    this.shipment_instruction = data.shipment_instruction;
    this.shipping_mode = data.shipping_mode;
    this.shipping_type = data.shipping_type;
    this.status = data.status;
  }

  getId() {
    return this.id;
  }

  getProductNumber() {
    return this.product_number;
  }

  getStatus() {
    return this.status;
  }

  getCategory() {
    return this.shipping_category;
  }

  getShippingCategory() {
    return this.shipping_category;
  }

  getShippingDetails() {
    return {
      mode: this.shipping_mode,
      type: this.shipping_type,
      address: this.shipping_address_reference.getAddress(),
      category: this.shipping_category?.getName(),
    };
  }
}

export class ShippingSlotModel {
  public readonly object: string;
  public readonly id: number;
  public readonly min_amount: number;
  public readonly max_amount: number;
  public readonly rate: number;

  constructor(data: IShippingSlot) {
    this.object = data.object;
    this.id = data.id;
    this.min_amount = data.min_amount;
    this.max_amount = data.max_amount;
    this.rate = data.rate;
  }

  getId() {
    return this.id;
  }

  getMaxAmount() {
    return this.max_amount;
  }

  getMiniAmount() {
    return this.min_amount;
  }

  getRate() {
    return this.rate;
  }
}

export class ShippingSlotCollectionModel {
  public readonly object: string;
  public readonly data: ShippingSlotModel[];

  constructor(data: IShippingSlotCollection) {
    this.object = data.object;
    this.data = data.data.map((slot) => new ShippingSlotModel(slot));
  }
}

export class MoveOnShippingModel {
  public readonly object: string;
  public readonly id: number;
  public readonly shipping_type: string;
  public readonly shipping_mode: string;
  public readonly unit_type: string;
  public readonly rate: number;
  public readonly agent_category_price_read_id: number;
  public readonly shipping_country: ShippingCountryModel;
  public readonly agent_warehouse: AgentWarehouseModel;
  public readonly shipping_category: ShippingCategoryModel;
  public readonly destination_warehouse?: DestinationWarehouseModel;
  public readonly slots?: ShippingSlotCollectionModel;

  constructor(data: IMoveOnShipping) {
    this.object = data.object;
    this.id = data.id;
    this.shipping_type = data.shipping_type;
    this.shipping_mode = data.shipping_mode;
    this.unit_type = data.unit_type;
    this.rate = data.rate;
    this.agent_category_price_read_id = data.agent_category_price_read_id;
    this.shipping_category = new ShippingCategoryModel(data.shipping_category);
    this.shipping_country = new ShippingCountryModel(data.shipping_country);
    this.agent_warehouse = new AgentWarehouseModel(data.agent_warehouse);
    this.destination_warehouse = data.destination_warehouse
      ? new DestinationWarehouseModel(data.destination_warehouse)
      : undefined;
    this.slots = data.slots
      ? new ShippingSlotCollectionModel(data.slots)
      : undefined;
  }

  getId() {
    return this.id;
  }

  getShippingCatetory() {
    return this.shipping_category;
  }

  getAgentWarehouse() {
    return this.agent_warehouse;
  }

  getObject() {
    return this.object;
  }

  getShippingType() {
    return this.shipping_type;
  }

  getShippingMode() {
    return this.shipping_mode;
  }

  getUnitType() {
    return this.unit_type;
  }

  getRate() {
    return this.rate;
  }

  getAgentCategoryPriceReadId() {
    return this.agent_category_price_read_id;
  }

  getShippingCountry() {
    return this.shipping_country;
  }
  getDestinationWarehouse() {
    return this.destination_warehouse;
  }
}

export class BuyProductViewModel {
  public readonly object: string;
  public readonly id: number;
  public readonly product_number: string;
  public readonly product_id: string;
  public readonly product_vid: string;
  public readonly cart_item_id: number;
  public readonly product_title: string;
  public readonly product_image: string;
  public readonly product_link: string;
  public readonly shipping_provider: string;
  public readonly total_quantity: number;
  public readonly status: string;
  public readonly order: OrderModel;
  public readonly shipping_address: IShippingAddress;
  public readonly shipping_address_reference: ShippingAddressModel;

  public readonly store: StoreModel;
  public readonly user: UserModel;
  public readonly region: RegionModel;
  public readonly product_seller?: ProductSellerModel;
  public readonly product_customer_association?: ProductCustomerAssociationModel;
  public readonly product_agent_association?: BuyProductAgentAssociationModel | null;
  public readonly buy_product_package?: BuyProductPackageModel;
  public readonly moveon_shipping: MoveOnShippingModel;
  public readonly customer_invoice?: CustomerInvoiceModel;
  public readonly agent_invoice: null;
  public readonly shipment_product?: ShipmentProductModel | null;
  public readonly product_variations: BuyProductVariationCollectionModel;
  public readonly created_at: string;

  constructor(data: IBuyProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product_number = data.product_number;
    this.product_id = data.product_id;
    this.product_vid = data.product_vid;
    this.cart_item_id = data.cart_item_id;
    this.product_title = data.product_title;
    this.product_image = data.product_image;
    this.product_link = data.product_link;
    this.shipping_provider = data.shipping_provider;
    this.total_quantity = data.total_quantity;
    this.status = data.status;
    this.region = new RegionModel(data.region);
    this.order = new OrderModel(data.order);
    this.product_seller = data.product_seller
      ? new ProductSellerModel(data.product_seller)
      : undefined;
    this.moveon_shipping = new MoveOnShippingModel(data.moveon_shipping);

    this.user = new UserModel(data.user);
    this.shipping_address = data.shipping_address;
    this.shipping_address_reference = new ShippingAddressModel(
      data.shipping_address_reference,
    );
    this.product_customer_association = data.product_customer_association
      ? new ProductCustomerAssociationModel(data.product_customer_association)
      : undefined;
    this.customer_invoice = data.customer_invoice
      ? new CustomerInvoiceModel(data.customer_invoice)
      : undefined;
    this.agent_invoice = null;
    this.shipment_product = data.shipment_product
      ? new ShipmentProductModel(data.shipment_product)
      : undefined;
    this.product_agent_association = data.product_agent_association
      ? new BuyProductAgentAssociationModel(data.product_agent_association)
      : null;
    this.buy_product_package = data.buy_product_package
      ? new BuyProductPackageModel(data.buy_product_package)
      : undefined;
    this.product_variations = new BuyProductVariationCollectionModel(
      data.product_variations,
    );

    this.created_at = data.created_at;
    this.store = new StoreModel(data.store);
  }
  getId() {
    return this.id;
  }

  getProductNumber() {
    return this.product_number;
  }

  getAgentInvoice() {
    return this.agent_invoice;
  }

  getCustomerInvoice() {
    return this.customer_invoice;
  }

  getUser() {
    return this.user;
  }

  getProductCustomerAssociation() {
    return this.product_customer_association;
  }

  getShippingAddress() {
    return this.shipping_address;
  }

  getMoveonShipping() {
    return this.moveon_shipping;
  }

  getShippementProduct() {
    return this.shipment_product;
  }

  getProductId() {
    return this.product_id;
  }

  getProductVid() {
    return this.product_vid;
  }

  getCartItemId() {
    return this.cart_item_id;
  }

  getProductTitle() {
    return this.product_title;
  }

  getProductImage() {
    return this.product_image;
  }

  getProductLink() {
    return this.product_link;
  }

  getShippingProvider() {
    return this.shipping_provider;
  }

  getTotalQuantity() {
    return this.total_quantity;
  }

  getStatus() {
    return this.status;
  }

  getRegion() {
    return this.region;
  }

  getOrder() {
    return this.order;
  }

  getProductSeller() {
    return this.product_seller;
  }

  getProductAgentAssociation() {
    return this.product_agent_association;
  }

  getBuyProductPackage() {
    return this.buy_product_package;
  }

  getProductVariations() {
    return this.product_variations;
  }

  getCreatedAt() {
    return this.created_at;
  }

  getStore() {
    return this.store;
  }
}

export class BuyProductView {
  public readonly message: string;
  public readonly data: BuyProductViewModel;

  constructor(data: IBuyProductView) {
    this.message = data.message;
    this.data = new BuyProductViewModel(data.data);
  }

  getData() {
    return this.data;
  }
}
