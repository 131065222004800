import React from "react";

import TextLine from "./TextLine";
import { DisputeBuyProductVriationModel } from "models/disputeDetailsCollectionModel";
import { Col, Image, Row, Skeleton, Space } from "antd";

const DisputeVariation: React.FC<DisputeBuyProductVriationModel> = (props) => {
  const {
    getOriginalTotalPrice,
    getOriginalUnitPrice,
    getQuantity,
    getTotalPrice,
    getUnitPrice,
    getImage,
  } = props;

  const src = getImage() && getImage() !== "None" ? getImage() : "";
  return (
    <Space direction="vertical">
      <div>
        {src ? (
          <Image
            height={60}
            width={60}
            alt=" "
            src={src}
            preview={false}
            style={{ borderRadius: 4 }}
          />
        ) : (
          <Skeleton.Image
            active={true}
            style={{ width: 60, height: 60, borderRadius: 4 }}
          />
        )}
      </div>
      <Row gutter={12}>
        <Col>
          <Space direction="vertical" size={0}>
            {getQuantity() && (
              <TextLine label="Quantity :" value={getQuantity()} />
            )}
            <br/>
            {getUnitPrice() && (
              <TextLine label="Unit Price : " value={getUnitPrice()} />
            )}
            {getTotalPrice() && (
              <TextLine label="Total Price : " value={getTotalPrice()} />
            )}
            <br/>

            {getOriginalUnitPrice() && (
              <TextLine
                label=" Original Unit Price : "
                value={getOriginalUnitPrice()}
              />
            )}
            {getOriginalTotalPrice() && (
              <TextLine
                label=" Original Total Price : "
                value={getOriginalTotalPrice()}
              />
            )}

          </Space>
        </Col>
      </Row>
    </Space>
  );
};

export default DisputeVariation;
