/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Table, Tag, Typography } from "antd";

import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";

import StatusTag from "components/StatusTag";
import {
  BuyActionCollectionModel,
  BuyActionModel,
} from "models/buyOrderCollectionModel";
import { formatDateTime } from "utils/helpers";

interface IProps {
  selectedBuyActionProduct: BuyActionCollectionModel;
  isBuyProductLoading: boolean;
}

const ActionsNeededsBuyProduct: React.FC<IProps> = (
  selectedBuyActionProduct,
  isBuyProductLoading,
) => {
  // const { takeActionPefrom, isLoading: performLoading } =
  //   useTakeActionPerform();
  // const { takeActionRefund, isLoading: refundLoading } = useTakeActionRefund();
  // const { takeActionCancel, isLoading: cancelLoading } = useTakeActionCancel();
  // const [selectedBuyProduct, setSelectedBuyProduct] = useState<
  //   BuyActionModel | undefined
  // >(undefined);

  // const [selectedAction, setSelectedAction] = useState(null);
  // const [textAreaValue, setTextAreaValue] = useState("");

  // const [openReject, setOpenReject] = useState<boolean>(false);

  const { Text } = Typography;
  // const { isMobile } = useWindowWidth();

  // const handleRejectCancel = () => {
  //   setOpenReject(false);
  // };

  // useEffect(() => {
  //   if (openReject) {
  //     setTextAreaValue("");
  //   }
  // }, [openReject]);

  // //Take Action handlers =>
  // const handleTakeActionNeeded = async () => {
  //   if (!selectedBuyProduct) {
  //     return;
  //   }
  //   const sequences: IActionPerformPayloadSequence[] = selectedBuyProduct
  //     .getSequences()
  //     .map((sequence) => {
  //       const sequenceData: IActionPerformPayloadSequence = {
  //         action_type: sequence.getType(),
  //         customer_note: textAreaValue,
  //         sequence_id: sequence.id,
  //       };
  //       if (
  //         ["product-stock-out", "sku-mismatched", "quantity-update"].includes(
  //           sequence.getType(),
  //         )
  //       ) {
  //         sequenceData.variant_ids = sequence.variant_mismatches?.data.map(
  //           (variant) => variant.id,
  //         );
  //         sequenceData.variants = sequence.variant_mismatches?.data.map(
  //           (variant) => ({
  //             variant_id: variant.buy_product_variation?.id,
  //             original_unit_price:
  //               variant.buy_product_variation?.original_unit_price,
  //             sku_id: variant.buy_product_variation?.sku_id,
  //             new_quantity: variant.new_quantity,
  //           }),
  //         );
  //       }

  //       return sequenceData;
  //     });

  //   const payload: IActionPerformPayload = {
  //     customer_note: textAreaValue,
  //     action: selectedAction ? selectedAction : "",
  //     sequences: sequences,
  //   };

  //   try {
  //     let data: any;
  //     if (selectedAction === "perform") {
  //       data = await takeActionPefrom(
  //         Number(selectedBuyProduct?.getId()),
  //         payload,
  //       );
  //     } else if (selectedAction === "refund") {
  //       data = await takeActionRefund(
  //         Number(selectedBuyProduct?.getId()),
  //         payload,
  //       );
  //     } else if (selectedAction === "cancel") {
  //       data = await takeActionCancel(Number(selectedBuyProduct?.getId()));
  //     }

  //     if (data) {
  //       notification["success"]({
  //         message: data.message,
  //       });
  //       setOpenReject(false);
  //     }
  //     setOpenReject(false);
  //   } catch (error: any) {
  //     console.log(error, "errors");
  //     if (error?.response?.data?.message) {
  //       notification["error"]({
  //         message: error.response.data.message,
  //       });
  //     }
  //     setOpenReject(false);
  //   }
  // };

  // const handleMenuClick = (key, record: BuyActionModel) => {
  //   setSelectedBuyProduct(record);
  //   setSelectedAction(key);
  //   setOpenReject(true);
  // };

  const columns = [
    {
      title: "Action Number",
      dataIndex: "action_number",
      key: "action_number",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <Text copyable={true}>{record.getActionNumber()}</Text>
          </div>
        );
      },
    },
    {
      title: "Action Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <StatusTag text={record.getStatus()} slug={record.getStatus()} />
          </div>
        );
      },
    },
    {
      title: "Create At",
      dataIndex: "create_at",
      key: "create_at",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <Tag color={"purple"}> {formatDateTime(record.getCreatedAt())}</Tag>
          </div>
        );
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   fixed: isMobile ? "right" : undefined,
    //   width: 100,
    //   render: (_, record: BuyActionModel) => {
    //     const items = [
    //       {
    //         label: "Perform",
    //         key: "perform",
    //         icon: <EditOutlined />,
    //         onClick: () => handleMenuClick("perform", record),
    //         style: { margin: "5px", padding: "8px 16px" },
    //       },
    //       {
    //         label: "Refund",
    //         key: "refund",
    //         icon: <RollbackOutlined />,
    //         onClick: () => handleMenuClick("refund", record),
    //         style: { margin: "5px", padding: "8px 16px" },
    //       },
    //       {
    //         label: "Cancel",
    //         key: "cancel",
    //         icon: <CloseOutlined />,
    //         onClick: () => handleMenuClick("cancel", record),
    //         style: { margin: "5px", padding: "8px 16px" },
    //       },
    //     ];
    //     return (
    //       <Space size="middle">
    //         <Dropdown menu={{ items: items }}>
    //           <Button icon={<HddFilled />}>
    //             {isMobile ? (
    //               <DownOutlined />
    //             ) : (
    //               <>
    //                 Actions <DownOutlined />{" "}
    //               </>
    //             )}
    //           </Button>
    //         </Dropdown>
    //       </Space>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div>
        <PageHeader ghost={false} style={{ marginTop: "10px" }}>
          <div>
            <Table
              //loading={isBuyProductLoading}
              rowKey="id"
              bordered={true}
              dataSource={selectedBuyActionProduct?.selectedBuyActionProduct.getData()}
              //@ts-ignore
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </div>
        </PageHeader>

        {/* {openReject && (
          <Modal
            open={openReject}
            title={`Are you sure you want to ${selectedAction ? selectedAction : ""}?`}
            onCancel={handleRejectCancel}
            footer={[
              <Button key="back" onClick={handleRejectCancel}>
                No
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleTakeActionNeeded}
                loading={performLoading || refundLoading || cancelLoading}
              >
                Yes
              </Button>,
            ]}
          >
            {selectedAction !== "cancel" && (
              <TextArea
                value={textAreaValue ? textAreaValue : undefined}
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder="Please add the Note..."
                rows={4}
              />
            )}
          </Modal>
        )} */}
      </div>
    </>
  );
};

export default ActionsNeededsBuyProduct;
