import { Col, DatePicker, Form, Input, Select } from "antd";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    useGetAgentCompany,
    useGetAgentWarehouse,
    useGetCurrency,
    useGetDestinationWarehouse,
    useGetShippingCategory,
    useGetShippingCountry,
    useGetStore,
} from "lib/core-react/hooks/private";
import {
    useGetAdmins,
    useGetCustomers,
} from "lib/core-react/hooks/private/useBulkUser";
import { useGetCountry } from "lib/core-react/hooks/private/useCountry";
import { useGetRegion } from "lib/core-react/hooks/private/useRegion";
import {
    agentCompanyCollectionAtom,
    agentWarehouseCollectionAtom,
    bulkUserAdmin,
    bulkUserCustomer,
    destinationWarehouseCollectionAtom,
    exchangeGroupCollectionAtom,
    payoutGateWayCollectionAtom,
    payoutRequestCollectionAtom,
    regionCountry,
    regionCurrency,
    regionRegion,
    shippingCategoryAtom,
    shippingCountry,
    storeExchangeAtom,
    storeStoreAtom,
} from "lib/core-react/store/store";
import { IFilterType } from "types/filters";
import { UserSelect } from "./UserSelect";
import { useAtom } from "jotai";
import { useGetExchangeGroup } from "../../lib/core-react/hooks/private/useExchangeGroup";
import { filterResourceEnum } from "enums/filterResourceEnum";
import { ShippingCategoryModel } from "../../models/shippingCategory";
import ShippingCategoryTreeSelect from "./ShippingCategoryTreeSelect";
import { BulkUserCollectionModel } from "../../models/bulkUserCollectionModel";
import { useDebounce } from "hooks/useDebounce";
import moment, { Moment } from "moment";
import {
    useGetPayoutGateways,
    useGetPayoutRequests,
} from "lib/core-react/hooks/private/useWallet";
import NumberRangeInput from "./NumberRangeSlider";
import { useGetExchange } from "lib/core-react/hooks/private/useExchange";
import { ExchangeListCollectionModel } from "models/exchangeListCollectionModel";
const { Option } = Select;
const { RangePicker } = DatePicker;

interface ICustomElementProps {
    type: string;
    label: string;
    name: string;
    placeholder: string | null;
    handleChange: (fields: IFilterType) => void;
    fieldValue: any;
    options: any[] | IFilterType | undefined;
    resource: string | null;
    data: string | null;
}

const CustomElement = ({
    type,
    label,
    name,
    placeholder,
    handleChange,
    fieldValue,
    options,
    resource,
    data,
}: ICustomElementProps) => {
    const { getCountry } = useGetCountry();
    const { getRegion } = useGetRegion();
    const { getCurrency } = useGetCurrency();
    const { getAgentCompanies } = useGetAgentCompany();
    // const { getLanguage } = useGetLanguage();
    const { getDestinationWarehouse } = useGetDestinationWarehouse();
    const { getAdmins } = useGetAdmins();
    const { getCustomers } = useGetCustomers();
    const { getPayoutGateWay } = useGetPayoutGateways();
    const { getPayoutRequests } = useGetPayoutRequests();
    const { getExchangeGroup } = useGetExchangeGroup();
    const { getExchange } = useGetExchange();
    const { getShippingCategory } = useGetShippingCategory();
    const { getShippingCountry } = useGetShippingCountry();
    const navigate = useNavigate();
    const location = useLocation();
    const { getStore } = useGetStore();
    const { getAgentWarehouses } = useGetAgentWarehouse();
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const { data: countryDataAtom, isLoading: countryLoading } =
        useAtomValue(regionCountry);
    const [shippingCategoryData] = useAtom(shippingCategoryAtom);

    const { data: agentCompanyDataAtom } = useAtomValue(
        agentCompanyCollectionAtom,
    );
    const { data: regionDataAtom, isLoading: regionLoading } =
        useAtomValue(regionRegion);

    const { data: currencyDataAtom, isLoading: currencyLoading } =
        useAtomValue(regionCurrency);

    // const { data: languageDataAtom, isLoading: languageLoading } =
    //   useAtomValue(regionLanguage);
    const {
        data: destinationWarehouseData,
        isLoading: destinationWarehouseDataLoading,
    } = useAtomValue(destinationWarehouseCollectionAtom);

    const { data: payoutRequestCollections, isLoading: payoutRequestLoading } =
        useAtomValue(payoutRequestCollectionAtom);

    const { data: payoutGatewayCollections, isLoading: payoutGateWayLoading } =
        useAtomValue(payoutGateWayCollectionAtom);

    const { data: stores, isLoading: storeLoading } =
        useAtomValue(storeStoreAtom);

    const [{ data: exchangeGroupStoreData, isLoading: exchangeGroupLoading }] =
        useAtom(exchangeGroupCollectionAtom);

    const [{ data: exchangeListData, isLoading: exchangeListLoading }] =
        useAtom(storeExchangeAtom);

    const [{ data: agentWarehouseStoreData, isLoading: warehouseLoading }] =
        useAtom(agentWarehouseCollectionAtom);

    const { data: shippingCountryData, isLoading: shippingCountryDataLoading } =
        useAtomValue(shippingCountry);
    const [bulkUserCustomerData] = useAtom(bulkUserCustomer);
    const [bulkUserAdminData] = useAtom(bulkUserAdmin);

    const ShippingCategoryData =
        shippingCategoryData.data &&
        new ShippingCategoryModel(
            shippingCategoryData.data,
        ).getShippingCategoryTreeIds();
    const Customers =
        bulkUserCustomerData &&
        bulkUserCustomerData.data &&
        new BulkUserCollectionModel(bulkUserCustomerData.data);
    const formatCustomersData = Customers?.data.map((x) => {
        return {
            label: `${x.name} (${x.email})`,
            value: x.id,
        };
    });
    const Admins =
        bulkUserAdminData &&
        bulkUserAdminData.data &&
        new BulkUserCollectionModel(bulkUserAdminData.data);
    const formatAdminData = Admins?.data.map((x) => {
        return {
            label: `${x.name} (${x.email})`,
            value: x.id,
        };
    });
    useEffect(() => {
        const fetchData = async () => {
            if (data === "external") {
                switch (resource) {
                    case filterResourceEnum.COUNTRY:
                        await getCountry("per_page=200");
                        break;

                    case filterResourceEnum.AGENT_WAREHOUSE:
                        await getAgentWarehouses("per_page=200");
                        break;

                    case filterResourceEnum.REGION:
                        await getRegion("per_page=200");
                        break;

                    case filterResourceEnum.DESTINATION_WAREHOUSE:
                        await getDestinationWarehouse("per_page=200");
                        break;

                    case filterResourceEnum.ADMIN:
                        await getAdmins("per_page=200");
                        break;

                    case filterResourceEnum.CUSTOMER:
                    case filterResourceEnum.USER:
                        await getCustomers("per_page=200");
                        break;
                    case filterResourceEnum.PAYOUT_GATEWAY:
                        await getPayoutGateWay("per_page=200");
                        break;

                    case filterResourceEnum.PAYOUT_ACCOUNT:
                        await getPayoutRequests("per_page=200");
                        break;
                    case filterResourceEnum.SHIPPING_COUNTRY:
                        await getShippingCountry("per_page=200");
                        break;

                    case filterResourceEnum.CURRENCY:
                        await getCurrency("per_page=200");
                        break;

                    case filterResourceEnum.AGENT_COMPANY:
                        await getAgentCompanies("per_page=200");
                        break;

                    case filterResourceEnum.STORE:
                        await getStore("per_page=200");
                        break;

                    case filterResourceEnum.EXCHANGE_GROUP:
                        await getExchangeGroup({ per_page: 200 });
                        break;

                    case filterResourceEnum.EXCHANGE_LIST:
                        await getExchange({ per_page: 200 });
                        break;
                    default:
                        break;
                }
            }
        };

        fetchData();
    }, [data, resource]);

    useEffect(() => {
        let queryParam = "per_page=100";
        if (debouncedSearchTerm) {
            queryParam = `per_page=100&keyword=${debouncedSearchTerm}`;
        }

        if (
            resource === filterResourceEnum.CUSTOMER ||
            resource === filterResourceEnum.USER
        ) {
            getCustomers(queryParam);
        } else if (resource === filterResourceEnum.ADMIN) {
            getAdmins(queryParam);
        }
    }, [debouncedSearchTerm]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };

    let fieldInput: ReactNode;

    if (type === "array") {
        fieldInput = (
            <Select
                showSearch
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
                filterOption={(input, option) =>
                    option && typeof option.children === "string"
                        ? (option.children as String)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        : false
                }
            >
                {Object.keys(options as IFilterType).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt}>
                        {(options as IFilterType)[opt]}
                    </Option>
                ))}
            </Select>
        );
    } else if (type === "daterange") {
        let defaultDates;
        if (fieldValue) {
            defaultDates = fieldValue.map((dVal: Moment) => moment(dVal));
        }

        fieldInput = (
            <RangePicker
                showTime={{ format: "YYYY-MM-DD HH:mm:ss" }}
                style={{ width: "100%" }}
                value={defaultDates || null}
                onChange={(_, dateString) => {
                    const isEmptyArray = dateString.every(
                        (item) => item === "",
                    );
                    if (isEmptyArray) {
                        navigate(location.pathname);
                    } else {
                        return handleChange({ [name]: dateString });
                    }
                }}
            />
        );
    } else if (type === "number_range") {
        // let defaultDates: any;
        // if (fieldValue) {

        // }

        fieldInput = (
            <NumberRangeInput name={name} handleChange={handleChange} />
        );
    } else if (type === "external_array") {
        fieldInput = (
            <Select
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
            >
                {(options as any[]).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt.id}>
                        {opt.name}
                    </Option>
                ))}
            </Select>
        );
    } else if (type === "select") {
        if (Array.isArray(options)) {
            if (options && !resource) {
                fieldInput = (
                    <Select
                        value={fieldValue}
                        allowClear
                        onChange={(value) => handleChange({ [name]: value })}
                        placeholder={placeholder}
                    >
                        {options?.map((data) => (
                            <Option key={`${data.value}`} value={data.value}>
                                {data.label}
                            </Option>
                        ))}
                    </Select>
                );
            }
        } else {
            if (options && !resource) {
                fieldInput = (
                    <Select
                        value={fieldValue}
                        allowClear
                        onChange={(value) => handleChange({ [name]: value })}
                        placeholder={placeholder}
                    >
                        {Object.keys(options as IFilterType).map((opt, idx) => (
                            <Option key={`${name}-${idx}`} value={opt}>
                                {(options as IFilterType)[opt]}
                            </Option>
                        ))}
                    </Select>
                );
            } else {
                if (resource === filterResourceEnum.COUNTRY) {
                    fieldInput = (
                        <Select
                            loading={countryLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {countryDataAtom?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.REGION) {
                    fieldInput = (
                        <Select
                            loading={regionLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {regionDataAtom?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.AGENT_COMPANY) {
                    fieldInput = (
                        <Select
                            loading={regionLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {agentCompanyDataAtom?.data.map((data) => (
                                <Option
                                    key={`${data.primary_name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.primary_name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.PAYOUT_GATEWAY) {
                    fieldInput = (
                        <Select
                            loading={payoutGateWayLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {payoutGatewayCollections?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.PAYOUT_ACCOUNT) {
                    fieldInput = (
                        <Select
                            loading={payoutRequestLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {payoutRequestCollections?.data.map((data) => (
                                <Option
                                    key={`${data.payout_account.account_data.branch}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.payout_account.account_data.branch}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.STORE) {
                    fieldInput = (
                        <Select
                            loading={storeLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {stores?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.CURRENCY) {
                    fieldInput = (
                        <Select
                            loading={currencyLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                            onFocus={async () => {
                                if (!currencyDataAtom) {
                                    await getCurrency(`per_page=200`);
                                }
                            }}
                        >
                            {currencyDataAtom?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.AGENT_WAREHOUSE) {
                    fieldInput = (
                        <Select
                            loading={warehouseLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {agentWarehouseStoreData?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (
                    resource === filterResourceEnum.DESTINATION_WAREHOUSE
                ) {
                    fieldInput = (
                        <Select
                            loading={destinationWarehouseDataLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {destinationWarehouseData?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                }
                //  else if (resource === "language") {
                //   fieldInput = (
                //     <Select
                //       loading={languageLoading}
                //       value={fieldValue}
                //       allowClear
                //       onChange={(value) => handleChange({ [name]: value })}
                //       placeholder={placeholder}
                //     >
                //       {languageDataAtom?.data.map((data) => (
                //         <Option key={`${data.name}-${data.id}`} value={data.id}>
                //           {data.name}
                //         </Option>
                //       ))}
                //     </Select>
                //   );
                // }
                else if (resource === filterResourceEnum.EXCHANGE_GROUP) {
                    fieldInput = (
                        <Select
                            loading={exchangeGroupLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {exchangeGroupStoreData?.data.map((data) => (
                                <Option
                                    key={`${data.name}-${data.id}`}
                                    value={data.id}
                                >
                                    {data.name}
                                </Option>
                            ))}
                        </Select>
                    );
                } else if (resource === filterResourceEnum.EXCHANGE_LIST) {
                    const exchangeListCollectionData =
                        exchangeListData &&
                        new ExchangeListCollectionModel(exchangeListData);
                    fieldInput = (
                        <Select
                            loading={exchangeListLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                            options={exchangeListCollectionData?.getSelectComponentSelectOptions()}
                        />
                    );
                } else if (resource === filterResourceEnum.ADMIN) {
                    fieldInput = (
                        <UserSelect
                            initialData={
                                (!bulkUserAdminData.isLoading &&
                                    formatAdminData) ||
                                []
                            }
                            loading={bulkUserAdminData.isLoading}
                            onSearch={(value) => {
                                handleSearch(value);
                            }}
                            resource={resource}
                            value={fieldValue}
                            allowClear={true}
                            showSearch={true}
                            placeholder={placeholder}
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            style={{ width: "100%" }}
                        />
                    );
                } else if (
                    resource === filterResourceEnum.CUSTOMER ||
                    resource === filterResourceEnum.USER
                ) {
                    fieldInput = (
                        <UserSelect
                            initialData={
                                (!bulkUserCustomerData.isLoading &&
                                    formatCustomersData) ||
                                []
                            }
                            loading={bulkUserCustomerData.isLoading}
                            resource={resource}
                            onSearch={(value) => {
                                handleSearch(value);
                            }}
                            value={fieldValue}
                            allowClear={true}
                            showSearch={true}
                            placeholder={placeholder}
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            style={{ width: "100%" }}
                        />
                    );
                } else if (resource === filterResourceEnum.SHIPPING_CATEGORY) {
                    fieldInput = (
                        <ShippingCategoryTreeSelect
                            initialOptions={ShippingCategoryData || []}
                            value={fieldValue}
                            allowClear={true}
                            showSearch={true}
                            loading={shippingCategoryData.isLoading}
                            onFocus={async () => {
                                if (
                                    !ShippingCategoryData ||
                                    !ShippingCategoryData.length
                                ) {
                                    await getShippingCategory("per_page=200");
                                }
                            }}
                            placeholder={placeholder}
                            onChange={(value) =>
                                handleChange({ [name]: value?.value })
                            }
                            style={{ width: "100%" }}
                        />
                    );
                } else if (resource === filterResourceEnum.SHIPPING_COUNTRY) {
                    fieldInput = (
                        <Select
                            loading={shippingCountryDataLoading}
                            value={fieldValue}
                            allowClear
                            onChange={(value) =>
                                handleChange({ [name]: value })
                            }
                            placeholder={placeholder}
                        >
                            {shippingCountryData?.data.map((data) => (
                                <Option key={`${data.id}`} value={data.id}>
                                    {data.country?.name}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            }
        }
    } else if (type === "multiselect") {
        if (options && !resource) {
            fieldInput = (
                <Select
                    mode="multiple"
                    value={fieldValue}
                    style={{ width: "100%" }}
                    placeholder={placeholder}
                    onChange={(value) => handleChange({ [name]: value })}
                    optionLabelProp="label"
                >
                    {Array.isArray(options) &&
                        options.map((option: { [key: string]: string }) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                </Select>
            );
        }
    } else if (type === "pricerange") {
        let makePriceOptions = {
            1: "1",
            10: "10",
            50: "50",
            100: "100",
            300: "300",
            500: "500",
            1000: "1000",
            5000: "5000",
            10000: "10000",
        };
        fieldInput = (
            <Select
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
            >
                {Object.keys(makePriceOptions).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt}>
                        {opt}
                    </Option>
                ))}
            </Select>
        );
    } else {
        fieldInput = (
            <Input
                placeholder={placeholder || undefined}
                value={fieldValue}
                onChange={(e) => handleChange({ [name]: e.target.value })}
            />
        );
    }

    return (
        <Col xs={24} md={12} xl={8} xxl={6}>
            <Form.Item label={label}>{fieldInput}</Form.Item>
        </Col>
    );
};

export default CustomElement;
