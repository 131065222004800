import CommerceApiService from "lib/core/apiServices/commerceApiService";
import DisputeResource from "lib/core/resources/private/disputeResource";

class DisputeService extends CommerceApiService {
  public disputeResource: DisputeResource;

  constructor() {
    super();

    this.disputeResource = new DisputeResource(this.config);
  }
}

export default DisputeService;
