import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { MenuInfo } from "rc-menu/lib/interface";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  DeleteTwoTone,
  DownOutlined,
  EditTwoTone,
  ExclamationCircleOutlined,
  HddFilled,
} from "@ant-design/icons";

import {
  useDeleteDiscount,
  useGetDiscounts,
} from "lib/core-react/hooks/private/useDiscount";
import {
  DiscountCollectionModel,
  DiscountModel,
} from "models/discountCollectionModel";
import { discountCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { IFilterType } from "types/filters";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import { formatDateTime } from "utils/helpers";
import { DiscountStatusEnum } from "enums/discountCollectionEnum";
import { getError } from "lib/core-react/hooks/utils/errors";

import FiltersComponent from "components/FiltersComponent";
import DiscountRules from "./components/DiscountRules";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_DISCOUNT_PERMISSION_ENUM } from "consts/permission-enum/admin-discount-enum";

const Discount = () => {
  const navigate = useNavigate();
  const { getDiscounts } = useGetDiscounts();
  const { deleteDiscount, isLoading: deleteLoading } = useDeleteDiscount();
  const [{ data: discountCollectionData, isLoading, refetch }] = useAtom(
    discountCollectionAtom,
  );

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getDiscounts);
    }
    
  }, [isFirstCall, isFetched, refetch , refetchFromFilter]);
  const { isMobile } = useWindowWidth();

  const DiscountCollectionData =
    discountCollectionData &&
    new DiscountCollectionModel(discountCollectionData);

  // Filter
  const filterData = DiscountCollectionData?.getFilters();
  // Pagination
  const paginationData = DiscountCollectionData?.getPagination();

  const [selectedDiscount, setSelectedDiscount] = useState<
    DiscountModel | undefined
  >(undefined);
  const [isShowDiscountRulesModal, setIsShowDiscountRulesModal] =
    useState(false);

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === "update") {
      navigate(
        `/discount-manage/discounts/update/${selectedDiscount?.getId()}`,
      );
    }
  };

  const handleDeleteDiscount = async (id: number) => {
    try {
      await deleteDiscount(id);
      message.success("Discount deleted.");
    } catch (error) {
      message.error(getError(error));
    }
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && DiscountCollectionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    DiscountCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getDiscounts,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    DiscountCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getDiscounts);
  };

  const { Text } = Typography;

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: DiscountModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Discount info",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      render: (_: string, record: DiscountModel) => {
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Text strong>Region:</Text>
              <Text>{record.getRegion().getName()}</Text>
            </Space>
            <Space>
              <Text strong>Name:</Text>
              <Text>{record.getName()}</Text>
            </Space>
            <Space>
              <Text strong>Amount:</Text>
              <Text>{record.getAmount()}</Text>
              <Tag color="success">{record.getAmountType()}</Tag>
            </Space>
            <Space>
              <Text strong>Max discount amount:</Text>
              <Text>{record.getMaximumDiscountAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Limitation type:</Text>
              <Tag color="success">{record.getLimitationType()}</Tag>
            </Space>
            <Space>
              <Text strong>Limitation times:</Text>
              <Text>{record.getLimitationTimes()}</Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Discount info",
      dataIndex: "name",
      hidden: !isMobile,
      width: 280,
      key: "name",
      render: (_: string, record: DiscountModel) => {
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Text copyable>{record.getCouponCode()}</Text>
            </Space>
            <Space>
              Status:
              <Tag
                icon={
                  record.getStatus() === DiscountStatusEnum.ACTIVE ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ExclamationCircleOutlined />
                  )
                }
                color={
                  record.getStatus() === DiscountStatusEnum.INACTIVE
                    ? "warning"
                    : "success"
                }
              >
                {record.getStatus()}
              </Tag>
            </Space>
            <Space>
              <Text strong>Region:</Text>
              <Text>{record.getRegion().getName()}</Text>
            </Space>
            <Space>
              <Text strong>Name:</Text>
              <Text>{record.getName()}</Text>
            </Space>
            <Space>
              <Text strong>Amount:</Text>
              <Text>{record.getAmount()}</Text>
              <Tag color="success">{record.getAmountType()}</Tag>
            </Space>
            <Space>
              <Text strong>Max discount amount:</Text>
              <Text>{record.getMaximumDiscountAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Limitation type:</Text>
              <Tag color="success">{record.getLimitationType()}</Tag>
            </Space>
            <Space>
              <Text strong>Limitation times:</Text>
              <Text>{record.getLimitationTimes()}</Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Discount rules",
      dataIndex: "discount_rules",
      key: "discount_rules",
      width: 250,
      render: (_: string, record: DiscountModel) => {
        return (
          <Space direction="vertical">
            {record
              .getDiscountRules()
              .getData()
              .map((item) => item.getRuleIdentifier().replaceAll("-", " "))
              .join(", ")}
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                setSelectedDiscount(record);
                setIsShowDiscountRulesModal(true);
              }}
            >
              View Details <ArrowRightOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Coupon code",
      dataIndex: "coupon_code",
      key: "coupon_code",
      hidden: isMobile,
      render: (_: string, record: DiscountModel) => {
        return <Text copyable>{record.getCouponCode()}</Text>;
      },
    },
    {
      title: "Start - End",
      dataIndex: "start_date",
      width: 200,
      key: "start_date",
      render: (_: string, record: DiscountModel) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Tag color="purple" style={{ marginBottom: 10 }}>
              {formatDateTime(record.getStartDate())}
            </Tag>
            <Tag color="purple">{formatDateTime(record.getEndDate())}</Tag>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: DiscountModel) => {
        return (
          <Tag
            icon={
              record.getStatus() === DiscountStatusEnum.ACTIVE ? (
                <CheckCircleOutlined />
              ) : (
                <ExclamationCircleOutlined />
              )
            }
            color={
              record.getStatus() === DiscountStatusEnum.INACTIVE
                ? "warning"
                : "success"
            }
          >
            {record.getStatus()}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "center" as const,
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: DiscountModel) => {
        const items: ExtendedMenuItemType[] = [
          {
            permission:ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_UPDATE,
            icon: <EditTwoTone />,
            label: "Update",
            key: "update",
          },
          {
            permission:ADMIN_DISCOUNT_PERMISSION_ENUM.ADMIN_DISCOUNT_DELETE,
            icon: <DeleteTwoTone />,
            label: (
              <Popconfirm
                title="Delete this discount?"
                onConfirm={() => handleDeleteDiscount(record.getId())}
                okButtonProps={{ loading: deleteLoading }}
                okText="Delete"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>Delete</span>
              </Popconfirm>
            ),
            key: "delete",
          },
        ];

        const menuProps = {
          items: items.filter((x) =>
            checkActionPermission(x.permission, x, null),
          ),
          onClick: handleMenuClick,
        };

        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedDiscount(record)}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Discounts"
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => navigate("/discount-manage/discounts/create")}
              >
                Create Discount
              </Button>,
            ]}
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <div style={{marginTop: 10}}>
              <Table
                //@ts-ignore
                columns={columns}
                dataSource={DiscountCollectionData?.getData()}
                bordered
                loading={isLoading}
                rowKey="id"
                pagination={paginationConfig}
                scroll={{ x: 900 }}
              />
            </div>
          </PageHeader>
        </Col>
      </Row>
      {selectedDiscount && (
        <Modal
          title={`Discount Rules (${selectedDiscount.getName()})`}
          open={isShowDiscountRulesModal}
          onCancel={() => setIsShowDiscountRulesModal(false)}
          footer={null}
        >
          <DiscountRules selectedDiscount={selectedDiscount} />
        </Modal>
      )}
    </>
  );
};

export default Discount;
