import { Form, Button, message, Alert } from "antd";
import { useDeleteCourierGateway } from "lib/core-react/hooks/private";
import { CourierGatewayModel } from "models/courierGatewayCollection";
import { useState, useEffect } from "react";

interface IProps {
  setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCourierGateway: CourierGatewayModel;
}

export const DeleteCourierGateway = ({
  setIsShowDeleteModal,
  selectedCourierGateway,
}: IProps) => {
  const { deleteCourierGateway, isLoading, isError } =
    useDeleteCourierGateway();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onConfirmDelete = async () => {
    try {
      await deleteCourierGateway(selectedCourierGateway.id);
      setIsShowDeleteModal(false);
      setIsErrorVisible(false);
      message.success({
        content: "Courier Gateway deleted successfully.",
        key: "deleteCourierGateway",
        duration: 2,
      });
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <h3>Are you sure you want to delete this Courier Gateway?</h3>
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={isLoading} onClick={onConfirmDelete}>
            Yes
          </Button>{" "}
          <Button
            disabled={isLoading}
            onClick={() => setIsShowDeleteModal(false)}
          >
            No
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};
