export function calculateDiscountPercentage(
  originalPrice: number,
  discountedPrice?: number
): number {
  if (discountedPrice === undefined) {
    return 0; // No discount if discountedPrice is not provided
  }

  return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);
}
