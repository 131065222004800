import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Flex,
    Modal,
    Skeleton,
    Space,
    Typography,
} from "antd";

import { StyledProductDetails } from "./index.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";
import { useGetProductDetails } from "lib/core-react/hooks/private/useProduct";
import { productDetailAtom } from "lib/core-react/store/store";

import ProductImageSlide from "./ProductImageSlide";
import ProductView from "./ProductView";
import AppRowContainer from "components/App/AppRowContainer";
import AppAnimate from "components/App/AppAnimate";
import AppCard from "components/App/AppCard";
import AppInfoView from "components/App/AppInfoView";
import {
    StyledProductViewTitle,
    StyledStrokeSubtitle,
    StyledProductView,
} from "./ProductView/index.styled";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "types";
import { ROLE_PERMISSION_TEST_ENUM } from "consts/parmissionEnum";
import { DownOutlined, HddFilled, PlusOutlined } from "@ant-design/icons";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";
import { AddProductToCampaign } from "../Products/ProductHeader/components";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { MenuInfo } from "rc-menu/lib/interface";
import { ProductDetailCampaign } from "../campagin";
import ProductHeader from "./productHeader/ProductHeader";
import CommonError from "components/Error/CommonError";

const ProductDetail = () => {
    const location = useLocation();
    const { id } = useParams();
    const { getProductDetails } = useGetProductDetails();
    const { width } = useWindowWidth();
    const divStyle = width >= 1280 ? { margin: "30px 30px 0px 30px" } : {};
    const operation = new URLSearchParams(location.search).get("operation");
    const productUrl = new URLSearchParams(location.search).get("productUrl");
    const productId = new URLSearchParams(location.search).get("productId");
    const user_id = new URLSearchParams(location.search).get("userId");
    const region = new URLSearchParams(location.search).get("region") || "BD";
    const campaignId = new URLSearchParams(location.search).get("campaignId");

    const [isShowAddToCampaignModal, setIsShowAddToCampaignModal] =
        useState(false);
    useEffect(() => {
        if (id && id === "find") {
            getProductDetails(
                {
                    region: region,
                    apply_exchange: 1,
                    locale: "bn",
                    user_id,
                    url: productUrl,
                },
                `${productId || ""}`,
            );
        } else {
            if (productId) {
                getProductDetails(
                    {
                        region: region,
                        apply_exchange: 1,
                        locale: "bn",
                    },
                    productId,
                );
            }
        }
    }, [id,productId]);

    const [{ data, isLoading,error,unAuthorized }] = useAtom(productDetailAtom);

    const productDetail = data && new ProductDetailExtendedModel(data);

    const items: ExtendedMenuItemType[] = [
        {
            permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
            icon: <PlusOutlined />,
            label: "Add to campaign",
            key: CampaignOperationEnum.AddCampaignProduct,
            disabled: operation !== CampaignOperationEnum.AddCampaignProduct,
        },
    ];

    const handleMenuClick = (e: MenuInfo) => {
        if (e.key === CampaignOperationEnum.AddCampaignProduct) {
            setIsShowAddToCampaignModal(true);
        }
    };
      // ------------------------------ui------------------------
  if (unAuthorized || error) {
    return <CommonError unAuthorized={unAuthorized} message={error}  />;
  }

    return (
        <StyledProductDetails style={divStyle}>
            {isLoading ? (
                <Skeleton active />
            ) : productDetail ? (
                <AppAnimate animation="transition.slideUpIn" delay={200}>
                    <AppCard key="product_detail">
                        {operation ===
                        CampaignOperationEnum.AddCampaignProduct ? (
                            <div style={{ marginTop: 20 }}>
                                <Flex
                                    style={{ width: "100%" }}
                                    gap="middle"
                                    justify="end"
                                >
                                    <Space>
                                        <div style={{ marginRight: "16px" }}>
                                            <Dropdown
                                                menu={{
                                                    items: items.filter((x) =>
                                                        checkActionPermission(
                                                            x.permission,
                                                            x,
                                                            null,
                                                        ),
                                                    ),
                                                    onClick: handleMenuClick,
                                                }}
                                            >
                                                <Button
                                                    style={{ height: "36px" }}
                                                    icon={<HddFilled />}
                                                >
                                                    Actions <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </Space>
                                </Flex>
                                <Divider plain></Divider>

                                {campaignId && region && (
                                    <Modal
                                        title="Add Configuration"
                                        open={isShowAddToCampaignModal}
                                        onCancel={() =>
                                            setIsShowAddToCampaignModal(false)
                                        }
                                        footer={null}
                                    >
                                        <AddProductToCampaign
                                            setIsShowAddToCampaignModal={
                                                setIsShowAddToCampaignModal
                                            }
                                            region={region}
                                            campaignId={campaignId}
                                            selectedProducts={[
                                                productDetail.getId(),
                                            ]}
                                        />
                                    </Modal>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        <AppRowContainer>
                            <Col sm={24} lg={20}>
                                <ProductHeader product={productDetail} />
                                <StyledProductView>
                                    <StyledProductViewTitle>
                                        ৳
                                        {productDetail
                                            .getPrice()
                                            .getDiscountMin()
                                            ? productDetail
                                                  .getPrice()
                                                  .getDiscountMin()
                                            : productDetail
                                                  .getPrice()
                                                  .getOriginalMin()}
                                        <span className="line-through">
                                            ৳
                                            {productDetail
                                                .getPrice()
                                                .getOriginalMin()}
                                        </span>
                                    </StyledProductViewTitle>
                                </StyledProductView>
                                <StyledStrokeSubtitle>
                                    {productDetail.getStock()
                                        ? "In stock"
                                        : "Out of stock"}
                                </StyledStrokeSubtitle>
                            </Col>
                            <Col sm={24} lg={4}>
                                <ProductImageSlide product={productDetail} />
                            </Col>
                        </AppRowContainer>
                        <ProductView product={productDetail} />
                    </AppCard>
                </AppAnimate>
            ) : (
                <Typography.Text type="danger">
                    Product not found!
                </Typography.Text>
            )}
            <AppInfoView />
        </StyledProductDetails>
    );
};

const ProductDetailPage = () => {
    const location = useLocation();
    const operation = new URLSearchParams(location.search).get("operation");

    return (
        <>
            {CampaignOperationEnum.AddCampaignProduct === operation ||
            CampaignOperationEnum.ViewCampaignProduct === operation ? (
                <ProductDetailCampaign />
            ) : (
                <ProductDetail />
            )}
        </>
    );
};

export default ProductDetailPage;
