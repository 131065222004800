import { Form, Input, Select } from "antd";
import { CountryDataStatus } from "enums/countryCollectionEnums";
import { CountryDataModel } from "models/country";
import { useEffect } from "react";
import { renderOptionsFromEnum } from "components/Form/forms";

interface IProps {
  data?: CountryDataModel;
  form: any;
  onFinish: (values: any) => Promise<void>;
}

const CountryModifications = ({ data, form, onFinish }: IProps) => {
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Add country"
          initialValues={{
            name: data?.name,
            code: data?.code,
            status: data?.status,
          }}
        >
          <Form.Item label="Code" name="code">
            <Input
              placeholder="Code"
              style={{ width: "100%" }}
              disabled={!!data}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Country name is required" }]}
            label="Country name"
            name="name"
          >
            <Input placeholder="Country name" />
          </Form.Item>

          <Form.Item required name="status" label="Status">
            <Select placeholder="Please select a status">
              {renderOptionsFromEnum(CountryDataStatus)}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CountryModifications;
