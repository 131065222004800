import { Collapse, Flex, Form, Checkbox, Typography } from "antd";
import { useState } from "react";
import { PermissionModel } from "models/permissionCollectionModel";

const { Text } = Typography;
const { Panel } = Collapse;

interface IProps {
  permission: [string, PermissionModel[]];
  checkedPermission: number[];
  updateFinalPermissions: (group: string, data: number[]) => void;
}

export const PermissionGroup = ({
  permission,
  checkedPermission,
  updateFinalPermissions,
}: IProps) => {
  const getCheckedData = () => {
    let checked: number[] = [];
    permission[1].forEach((item) => {
      if (checkedPermission?.includes(item.id)) checked.push(item.id);
    });

    return checked;
  };
  const getAllData = () => {
    let checked: number[] = [];
    permission[1].forEach((item) => {
      checked.push(item.id);
    });
    return checked;
  };

  const data = getAllData();
  const checkedData = getCheckedData();

  const [selectedValues, setSelectedValues] = useState(checkedData);
  const [indeterminate, setIndeterminate] = useState(
    checkedData.length > 0 && checkedData.length < permission[1].length
  );
  const [checkAll, setCheckAll] = useState(
    checkedData.length === permission[1].length
  );
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const handleCheckAll = (e) => {
    const checked = e.target.checked;
    const newSelectedValues = checked
      ? permission[1].map((item) => item.id)
      : [];
    setCheckAll(checked);
    setIndeterminate(false);
    setSelectedValues(newSelectedValues);
    updateFinalPermissions(permission[0], e.target.checked ? data : []);
  };

  const handleGroupChange = (checkedValues) => {
    setSelectedValues(checkedValues);
    setCheckAll(checkedValues.length === permission[1].length);
    setIndeterminate(
      checkedValues.length > 0 && checkedValues.length < permission[1].length
    );
  };

  const onCollapseChange = (key) => {
    setActiveKey(key.length ? [key] : []);
  };

  return (
    <Form.Item>
      <Collapse activeKey={activeKey} onChange={onCollapseChange}>
        <Panel
          header={
            <div style={{ display: "flex", alignItems: "center", minWidth: "305px" }}>
              <Checkbox
                key={permission[0] + "_all"}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
                checked={checkAll}
              >
                <span style={{ fontWeight: 700, textTransform: "capitalize" }}>
                  {permission[0].split(/[-_]/).join(" ")}
                </span>
              </Checkbox>
            </div>
          }
          key={permission[0]}
          style={{ width: "100%" }}
        >
          <Checkbox.Group value={selectedValues} onChange={handleGroupChange}>
            <Flex vertical>
              {permission[1].map((item) => {
                return (
                  <div key={item.id}>
                    <Checkbox value={item.id} style={{ lineHeight: "32px" }}>
                      <Text style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>
                        {item.label.split(/[-_]/).join(" ")}
                      </Text>
                    </Checkbox>
                  </div>
                );
              })}
            </Flex>
          </Checkbox.Group>
        </Panel>
      </Collapse>
    </Form.Item>
  );
};
