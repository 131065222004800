import {  Form, Input, Rate } from "antd";

import {
    StyledProductDetailHeader,
    StyledProductDetailHeaderContent,
    StyledProductDetailHeaderInfo,
    StyledProfileMbText,
    StyledProfileReviewText,
    StyledProductDetailSocial,
} from "../styles/productDetailCampaign.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";
import { Link } from "react-router-dom";


interface IProps {
    product: ProductDetailExtendedModel;
}



const ProductInfo = ({ product }: IProps) => {
    return (
        <div>
            <StyledProductDetailHeader>
                <StyledProductDetailHeaderInfo>
                    <Form.Item
                    
                        name="productId"
                        label="productId"
                        style={{
                            width: "100%",
                        }}
                        hidden
                        initialValue={product.getId()}
                        rules={[
                            { required: true, message: "title is required" },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                    
                        name="title"
                        label="Title"
                        style={{
                            width: "100%",
                        }}
                        initialValue={product.getTitle()}
                        rules={[
                            { required: true, message: "title is required" },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <StyledProductDetailHeaderContent>
                        <Rate
                            defaultValue={product.getRatingsAverage()}
                            disabled
                        />
                        <StyledProfileReviewText>
                            {product.getRatingsCount() || 0 + " reviews"}
                        </StyledProfileReviewText>

                        <StyledProfileReviewText>
                            Sales :{" "}
                            <StyledProfileMbText>
                                {product.getTotalSales()}
                            </StyledProfileMbText>
                        </StyledProfileReviewText>
                        <StyledProfileReviewText>
                            Stock :
                            {product.getTotalStock() ? (
                                <StyledProfileMbText>
                                    {product.getTotalStock()}
                                </StyledProfileMbText>
                            ) : (
                                <span style={{color : "black", paddingLeft : 3}}>Out of stock</span>
                            )}
                        </StyledProfileReviewText>
                        {product.getVendorLink() ? (
                            <StyledProfileReviewText>
                                <StyledProfileMbText>
                                    <Link to={product.getVendorLink()}>
                                        Original link
                                    </Link>
                                </StyledProfileMbText>
                            </StyledProfileReviewText>
                        ) : (
                            ""
                        )}
                    </StyledProductDetailHeaderContent>
                </StyledProductDetailHeaderInfo>

                <StyledProductDetailSocial></StyledProductDetailSocial>
            </StyledProductDetailHeader>
        </div>
    );
};

export default ProductInfo;


