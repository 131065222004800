
// Enum for admin permissions in the Invoice module
export enum ADMIN_INVOICE_PERMISSION_ENUM {
    // Invoice permissions
    ADMIN_INVOICE_VIEW = "admin-invoice-view",
    ADMIN_INVOICE_SETTLE = "admin-invoice-settle",
    ADMIN_INVOICE_RECALCULATE = "admin-invoice-recalculate",
    ADMIN_INVOICE_TRANSACTION_VIEW = "admin-invoice-transaction-view",

    // Agent Invoice permissions
    ADMIN_VIEW_AGENT_INVOICE = "admin-view-agent-invoice",
    ADMIN_SETTLE_AGENT_INVOICE = "admin-settle-agent-invoice",
    ADMIN_RECALCULATE_AGENT_INVOICE = "admin-recalculate-agent-invoice",
    ADMIN_VIEW_AGENT_INVOICE_TRANSACTION = "admin-view-agent-invoice-transaction",

    // Invoice payment rule group permissions
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW = "admin-invoice-payment-rule-group-view",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_ADD = "admin-invoice-payment-rule-group-add",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_UPDATE = "admin-invoice-payment-rule-group-update",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_DELETE = "admin-invoice-payment-rule-group-delete",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_ADD = "admin-invoice-payment-rule-group-user-add",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_DELETE = "admin-invoice-payment-rule-group-user-delete"
}



