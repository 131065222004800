import {
  Button,
  Form,
  Input,
  Select,
  Space,
  notification,
} from "antd";
import { useAtom } from "jotai";
import { useGetRole } from "lib/core-react/hooks/private";
import { useDestinationWarehouseInvitationSend } from "lib/core-react/hooks/private/useDestinationWarehouse";
import { roleAtom } from "lib/core-react/store/store";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { useEffect, useState } from "react";
import { IDestinationWarehouseMemberInvitationForm } from "types/destinationWarehouseCollection";
import { AddRole } from "./AddRole";
import { showError } from "../../../../../helpers/showError";
import {LeftOutlined} from "@ant-design/icons";

interface IProps {
  destinationWarehouseId: number;
  onHide: () => void;
}

export const SendInvitation = ({ destinationWarehouseId, onHide }: IProps) => {
  const [isRoleFormVisible, setIsRoleFormVisible] = useState(false); // Controls view switching inside the main modal
  const { sendInvitation, isLoading: isLoadingSend } = useDestinationWarehouseInvitationSend();
  const { getRole } = useGetRole();

  const [form] = Form.useForm<IDestinationWarehouseMemberInvitationForm>();

  const [{ data: roleCollectionData, isLoading: isLoadingRole }] = useAtom(roleAtom);
  const RoleCollectionData = roleCollectionData && new RoleCollectionModel(roleCollectionData);

  useEffect(() => {
    form.resetFields();
  }, [destinationWarehouseId]);

  useEffect(() => {
    getRole(`destination_warehouse_id=${destinationWarehouseId}`);
  }, [destinationWarehouseId]);

  const onFinishInvite = async (value: IDestinationWarehouseMemberInvitationForm) => {
    try {
      destinationWarehouseId && (await sendInvitation(destinationWarehouseId, value));
      notification.success({ message: "Invitation successfully sent" });
      onHide();
    } catch (error) {
      showError(error, form);
    }
  };

  const handleAddRoleSuccess = () => {
    // Hide the AddRole form and show the original form
    setIsRoleFormVisible(false);
    // Refresh roles after adding a new one
    getRole(`destination_warehouse_id=${destinationWarehouseId}`);
  };
  return (
    <div className="form-container-animation">
      {/* Send Invitation Form */}

      { !isRoleFormVisible ? <div>
        <Form
          onFinish={onFinishInvite}
          form={form}
          layout="vertical"
          name="Send Invite"
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "User email is required",
              },
            ]}
            label="User email"
            name="user_email"
          >
            <Input placeholder="Enter user email" />
          </Form.Item>

          <Form.Item label="Roles">
            <Space>
              <Form.Item
                rules={[{ required: true, message: "Role is required" }]}
                name="role_ids"
                noStyle
              >
                <Select
                  style={{ width: 260 }}
                  loading={isLoadingRole}
                  mode="multiple"
                  placeholder="Please select roles"
                  options={RoleCollectionData?.getRolesLabeledValue()}
                />
              </Form.Item>

              <Button
                onClick={() => setIsRoleFormVisible(true)}
              >
                Add role?
              </Button>
            </Space>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoadingSend}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>:    <div>
        <AddRole
          destinationWarehouseId={destinationWarehouseId}
          onHide={handleAddRoleSuccess}
        />

        <Button
          icon={<LeftOutlined/>}
          onClick={() => setIsRoleFormVisible(false)} style={{ marginTop: 16 }}
        >
          Back
        </Button>
      </div>

      }
    </div>
  );
};
