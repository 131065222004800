import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { FacetRangeFieldModel } from "models/productDetailCollectionModel";

import AppList from "components/App/AppList";
import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
} from "../index.styled";
import RangeCheckedCell from "../RangeCheckedCell";

interface IProps {
  priceMinimumRangesField: FacetRangeFieldModel[];
}

const PriceMinimumRangeFacet = ({ priceMinimumRangesField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const { price_minimum_ranges, ...rest } = parsedQuery;
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const onSelectPriceMinimumRanges = async (
    name: FacetRangeFieldModel,
    isChecked: boolean
  ) => {
    let updatedPriceMinimumRanges = {
      min: name.getFrom(),
      max: name.getTo(),
    };

    if (isChecked) {
      // isChecked=true means user trying deselect
      const queryString = qs.stringify({ ...rest });
      await getProductDetailsCollection(
        {
          region: "BD",
          locale: "en",
        },
        { ...rest }
      );
      navigate(`/product-manage/products?${queryString}`);
    } else {
      const queryString = qs.stringify({
        ...rest,
        price_minimum_ranges: updatedPriceMinimumRanges,
      });
      await getProductDetailsCollection(
        {
          region: "BD",
          locale: "en",
        },
        { price_minimum_ranges: updatedPriceMinimumRanges, ...rest }
      );
      navigate(`/product-manage/products?${queryString}`);
    }
  };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Price</StyledProductSidebarItemTitle>
      <AppList
        data={priceMinimumRangesField}
        renderItem={(data) => (
          <RangeCheckedCell
            key={data.getName()}
            data={data}
            onChange={onSelectPriceMinimumRanges}
            selected={price_minimum_ranges ? price_minimum_ranges : undefined}
          />
        )}
      />
    </StyledProductSidebarItem>
  );
};
export default PriceMinimumRangeFacet;
