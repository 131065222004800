import { TablePaginationConfig } from "antd";

export class PaginationModel {
  static getPaginationConfig(
    data: any,
    handlePaginationChange: (pageCount: number, pageSize: number) => void,
  ): false | TablePaginationConfig {
    return data && data.pagination
      ? {
          current: data.pagination.current_page,
          showSizeChanger: true,
          total: data.pagination.total,
          pageSize: data.pagination.per_page,
          onChange: handlePaginationChange,
          position: ["bottomRight"],
          onShowSizeChange: handlePaginationChange,
        }
      : false;
  }
}
