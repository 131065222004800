import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Forms } from "models/form";
import { useCreateAdmin } from "lib/core-react/hooks/private/useAdminUser";
import { IUserCredentials } from "types/userCollection";
import { useAtom } from "jotai";
import { roleAtom } from "lib/core-react/store/store";
import { useGetRole } from "lib/core-react/hooks/private";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { renderOptionsFromEnum } from "components/Form/forms";

interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAdminUser = ({ setIsShowCreateModal }: IProps) => {
  const { createAdmin, isLoading, isError } = useCreateAdmin();
  const { getRole } = useGetRole();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [{ data: roleData, refetch }] = useAtom(roleAtom);

  const RoleData = roleData && new RoleCollectionModel(roleData);

  // Api Call
  useEffect(() => {
    getRole();
    
  }, [refetch]);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: IUserCredentials) => {
    try {
      await createAdmin(value);
      setIsShowCreateModal(false);
      setIsErrorVisible(false);
      notification["success"]({
        message: "Create Admin User successfully",
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const onValuesChange = (changedValues: any) => {
    Forms.formValueChange(form, changedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Update Admin User"
      onFieldsChange={onValuesChange}
      ref={formRef}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "User name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="User name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Role is required" }]}
        name="role_ids"
        label="Role"
        
      >
        <Select mode="multiple" loading={isLoading} placeholder="Please select the Roles">
          {RoleData?.getRoleIdsAsObject() &&
            renderOptionsFromEnum(RoleData?.getRoleIdsAsObject())}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[
          { required: true, message: "Email is required" },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
        label="Email"
        name="email"
      >
        <Input placeholder="Email" type="email" autoComplete="email" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Password is required" }]}
        label="Password"
        name="password"
      >
        <Input.Password placeholder="Password" type="password" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default CreateAdminUser;
