import React, { useEffect, useState, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import {
  DeleteTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import moment, { Moment } from "moment";

import {
  agentCompanyCollectionAtom,
  discountAtom,
  discountRulesSchemaAtom,
  regionRegion,
  shippingCountryCollectionAtom,
  storeStoreAtom,
} from "lib/core-react/store/store";
import { renderOptionsFromEnum } from "components/Form/forms";
import { useGetAgentCompany, useGetRegion } from "lib/core-react/hooks/private";
import { RegionCollectionModel } from "models/regionCollectionModel";
import { IDiscountFormData } from "types/discountCollection";
import {
  DiscountAmountTypeEnum,
  DiscountLimitationTypeEnum,
  DiscountStatusEnum,
} from "enums/discountCollectionEnum";
import {
  useGetDiscount,
  useGetDiscountRulesSchema,
  useUpdateDiscount,
} from "lib/core-react/hooks/private/useDiscount";
import { DiscountRulesSchemaModel } from "models/discountRulesSchemaModel";
import { useGetStore } from "lib/core-react/hooks/private/useStore";
import { StoreCollectionModel } from "models/storeCollectionModel";
import { ShippingCountryCollectionModel } from "models/shippingCountryCollectionModel";
import { AgentCompanyCollectionModel } from "models/agentCompanyCollectionModel";
import { useGetShippingCountries } from "lib/core-react/hooks/private/useShippingCountry";
import { DiscountModel } from "models/discountCollectionModel";
import { getError } from "lib/core-react/hooks/utils/errors";
import { isValidISODate, transformEnumToLabeledValue } from "utils/helpers";
import { generateCouponCode } from "../utilts";
import dayjs from "dayjs";

const UpdateDiscount = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { getRegion } = useGetRegion();
  const { updateDiscount, isLoading, isError } = useUpdateDiscount();
  const { getDiscountRulesSchema } = useGetDiscountRulesSchema();
  const { getDiscount } = useGetDiscount();

  // for rules
  const { getStore } = useGetStore();
  const { getShippingCountries } = useGetShippingCountries();
  const { getAgentCompanies } = useGetAgentCompany();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();
  const rules = Form.useWatch("rules", form);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getRegion("per_page=200");
    getDiscountRulesSchema();
    if (id) {
      getDiscount(Number(id));
    }
    
  }, []);

  // fetch data required for rules
  useEffect(() => {
    if (
      Array.isArray(rules) &&
      rules.some(
        (item) =>
          item &&
          item.rule_identifier &&
          item.rule_identifier.includes("store"),
      )
    ) {
      getStore("per_page=200");
    }
    if (
      Array.isArray(rules) &&
      rules.some(
        (item) =>
          item &&
          item.rule_identifier &&
          item.rule_identifier.includes("agent-company"),
      )
    ) {
      getAgentCompanies("per_page=200");
    }
    if (
      Array.isArray(rules) &&
      rules.some(
        (item) =>
          item &&
          item.rule_identifier &&
          item.rule_identifier.includes("shipping-country"),
      )
    ) {
      getShippingCountries("per_page=200");
    }
    
  }, [rules]);

  const [{ data: regionCollectionData, isLoading: regionLoading }] =
    useAtom(regionRegion);
  const [
    { data: discountRulesSchemaData, isLoading: discountRulesSchemaLoading },
  ] = useAtom(discountRulesSchemaAtom);

  const RegionCollectionData =
    regionCollectionData && new RegionCollectionModel(regionCollectionData);
  const DiscountRulesSchemaData =
    discountRulesSchemaData &&
    new DiscountRulesSchemaModel(discountRulesSchemaData);

  const [{ data: discountData, isLoading: discountLoading, error }] =
    useAtom(discountAtom);
  
  const DiscountData = discountData && new DiscountModel(discountData);

  // for rules
  const [{ data: storeCollectionData, isLoading: storeLoading }] =
    useAtom(storeStoreAtom);
  const [
    { data: shippingCountryCollectionData, isLoading: shippingCountryLoading },
  ] = useAtom(shippingCountryCollectionAtom);
  const [{ data: agentCompanyCollectionData, isLoading: agentCompanyLoading }] =
    useAtom(agentCompanyCollectionAtom);

  const StoreCollectionData =
    storeCollectionData && new StoreCollectionModel(storeCollectionData);
  const ShippingCountryCollectionData =
    shippingCountryCollectionData &&
    new ShippingCountryCollectionModel(shippingCountryCollectionData);
  const AgentCompanyCollectionData =
    agentCompanyCollectionData &&
    new AgentCompanyCollectionModel(agentCompanyCollectionData);

  const onFinish = async (value: IDiscountFormData) => {
    try {
      const payload: IDiscountFormData = {
        ...value,
      };
      await updateDiscount(Number(id), payload);
      // await getDiscount(Number(id));
      navigate("/discount-manage/discounts");
      message.success("Discount updated successfully");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleGenerateCoupon = () => {
    const couponCode = generateCouponCode();
    form.setFieldsValue({ coupon_code: couponCode });
  };

  return (
    <PageHeader
      style={{ marginTop: "10px" }}
      ghost={false}
      title="Update Discount"
      onBack={() => window.history.back()}
    >
      {!id ? (
        <div>ID missing!</div>
      ) : discountLoading ? (
        <Skeleton active />
      ) : error ? (
        <div>{getError(error)}</div>
      ) : DiscountData ? (
        <Card title={"Update Discounts"} style={{ margin: "10px 0px" }}>
          <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add Discount"
            ref={formRef}
            initialValues={{
              name: DiscountData.getName(),
              region_id: DiscountData.getRegion().getId(),
              amount_type: DiscountData.getAmountType(),
              amount: DiscountData.getAmount(),
              maximum_discount_amount: DiscountData.getMaximumDiscountAmount(),
              limitation_type: DiscountData.getLimitationType(),
              limitation_times: DiscountData.getLimitationTimes(),
              coupon_code: DiscountData.getCouponCode(),
              status: DiscountData.getStatus(),
              rules: DiscountData.getDiscountRules()
                .getData()
                .map((item) => {
                  const metadata = Object.entries(item.getMetaData()).reduce<{
                    [key: string]: string | number | Moment;
                  }>((acc, [key, value]) => {
                    if (isValidISODate(value)) {
                      acc[key] = moment(value);
                    } else {
                      acc[key] = Number(value);
                    }
                    return acc;
                  }, {});
                  return {
                    rule_identifier: item.getRuleIdentifier(),
                    metadata: metadata,
                  };
                }),
            }}
          >
            <Form.Item
              rules={[{ required: true, message: "Region is required" }]}
              name="region_id"
              label="Region"
            >
              <Select
                loading={regionLoading}
                placeholder="Please select a region"
              >
                {RegionCollectionData?.getRegionIdsAsObject() &&
                  renderOptionsFromEnum(
                    RegionCollectionData?.getRegionIdsAsObject(),
                  )}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Discount name is required",
                },
              ]}
              label="Name"
              name="name"
            >
              <Input placeholder="Enter discount name" />
            </Form.Item>

            <Space
              direction="vertical"
              size="middle"
              style={{
                display: "flex",
              }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Amount is required",
                      },
                    ]}
                    label="Amount"
                    name="amount"
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      placeholder="Enter amount"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Amount type is required" },
                    ]}
                    name="amount_type"
                    label={
                      <span>
                        Amount Type
                        <Tooltip
                          title="Flat Amount: A fixed discount applied to the original price.
Percentage: A discount calculated as a percentage of the original price."
                        >
                          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </span>
                    }
                  >
                    <Select
                      placeholder="Please select a amount type"
                      options={transformEnumToLabeledValue(
                        DiscountAmountTypeEnum,
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Max discount is required",
                      },
                    ]}
                    label={
                      <span>
                        Max Discount
                        <Tooltip title="Select the type of discount amount: Flat Amount for a fixed discount, or Percentage for a percentage-based discount. This helps set the maximum discount effectively.">
                          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </span>
                    }
                    name="maximum_discount_amount"
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      placeholder="Enter max discount"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: "flex",
              }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Coupon code is required",
                      },
                    ]}
                    label={
                      <span>
                        Coupon Code
                        <Tooltip title="If you update the coupon code Please generate the coupon">
                          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </span>
                    }
                    name="coupon_code"
                  >
                    <Input
                      placeholder="Enter coupon code"
                      addonAfter={
                        <Button
                          size="small"
                          type="link"
                          icon={<SyncOutlined />}
                          onClick={handleGenerateCoupon}
                        >
                          Generate
                        </Button>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Limitation times is required",
                      },
                    ]}
                    label={
                      <span>
                        Limitation Times
                        <Tooltip title="Set the maximum number of times this discount can be used. This helps control the usage and availability of the discount for your promotions.">
                          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </span>
                    }
                    name="limitation_times"
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      placeholder="Enter limitation times"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Limitation type is required",
                      },
                    ]}
                    name="limitation_type"
                    label={
                      <span>
                        Limitation Type
                        <Tooltip title="Select the limitation type for this discount: restrict usage per customer or overall. This helps manage how often the discount can be applied.">
                          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip>
                      </span>
                    }
                  >
                    <Select
                      placeholder="Please select a limitation type"
                      options={transformEnumToLabeledValue(
                        DiscountLimitationTypeEnum,
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
            <Space size="large">
              <Form.Item
                label={
                  <span>
                    Start Date
                    <Tooltip title="Set the date when this discount will become active. This helps in scheduling your promotions effectively.">
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </span>
                }
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please select a start date!",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                  defaultValue={dayjs(
                    DiscountData.getStartDate(),
                    "YYYY-MM-DD HH:mm:ss",
                  )}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    End Date
                    <Tooltip title="Set the date when this discount will expire. This helps in managing the duration of your promotional offers.">
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </span>
                }
                name="end_date"
                rules={[
                  { required: true, message: "Please select an end date!" },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                  defaultValue={dayjs(
                    DiscountData.getStartDate(),
                    "YYYY-MM-DD HH:mm:ss",
                  )}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Space>

            {/* Rules */}
            <Card style={{ marginBottom: 10 }}>
              <Form.List name="rules">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Card
                        key={key}
                        title={`Rule #${index + 1}`}
                        style={{ marginBottom: 10 }}
                        extra={
                          index > 0 && (
                            <DeleteTwoTone
                              twoToneColor="hotpink"
                              onClick={() => remove(name)}
                            />
                          )
                        }
                      >
                        <Space
                          key={key}
                          direction="vertical"
                          size="middle"
                          style={{
                            display: "flex",
                            margin: "0 10px",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            rules={[
                              {
                                required: true,
                                message: "Rule identifier is required",
                              },
                            ]}
                            required
                            name={[name, "rule_identifier"]}
                            label={
                              <span>
                                Rule Identifier
                                <Tooltip title="Enter a unique identifier for this rule. This helps in identifying and managing specific discount rules within your system.">
                                  <QuestionCircleOutlined
                                    style={{ marginLeft: 8 }}
                                  />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Select
                              loading={discountRulesSchemaLoading}
                              placeholder="Please select a rule identifier"
                            >
                              {DiscountRulesSchemaData &&
                                renderOptionsFromEnum(
                                  DiscountRulesSchemaData.getDataKeysAsObject(),
                                )}
                            </Select>
                          </Form.Item>

                          {DiscountRulesSchemaData &&
                            Array.isArray(rules) &&
                            rules[index] &&
                            rules[index].rule_identifier &&
                            Object.entries(
                              DiscountRulesSchemaData.getDataByKey(
                                rules[index].rule_identifier,
                              ),
                            ).map((item) => {
                              const type = item[1].getType();
                              const placeholder = item[1].getPlaceholder();
                              const required = item[1].getIsRequired();
                              const label = item[1].getLabel();
                              const isLoading = label.includes(
                                "Shipping Country",
                              )
                                ? shippingCountryLoading
                                : label.includes("Agent Company")
                                  ? agentCompanyLoading
                                  : label.includes("Store")
                                    ? storeLoading
                                    : false;
                              const selectValues = label.includes(
                                "Shipping Country",
                              )
                                ? ShippingCountryCollectionData?.getShippingCountryIdsAsObject()
                                : label.includes("Agent Company")
                                  ? AgentCompanyCollectionData?.getAgentCompanyIdsAsObject()
                                  : label.includes("Store")
                                    ? StoreCollectionData?.getStoreIdsAsObject()
                                    : [];

                              return (
                                <Form.Item
                                  key={item[0]}
                                  {...restField}
                                  rules={[
                                    {
                                      required,
                                      message: `${label} is required`,
                                    },
                                  ]}
                                  required={required}
                                  name={[name, "metadata", item[0]]}
                                  label={label}
                                >
                                  {type === "number" ? (
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      placeholder={placeholder}
                                    />
                                  ) : type === "datetime" ? (
                                    <DatePicker
                                      showTime
                                      format="YYYY-MM-DD HH:mm:ss"
                                    />
                                  ) : type === "select" ? (
                                    <Select
                                      loading={isLoading}
                                      placeholder={placeholder}
                                      options={transformEnumToLabeledValue(
                                        selectValues,
                                      )}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Form.Item>
                              );
                            })}
                        </Space>
                      </Card>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add rule
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>

            <Form.Item
              rules={[{ required: true, message: "Status is required" }]}
              name="status"
              label="Status"
            >
              <Select
                placeholder="Please select a status"
                options={transformEnumToLabeledValue(DiscountStatusEnum)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Form.Item>

            {isErrorVisible && (
              <Alert
                style={{ marginTop: "7px" }}
                message={isError}
                closable
                afterClose={() => setIsErrorVisible(false)}
                type="error"
                showIcon
              />
            )}
          </Form>
        </Card>
      ) : (
        ""
      )}
    </PageHeader>
  );
};

export default UpdateDiscount;
