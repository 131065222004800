import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { baseShippingCategoryAtom } from "lib/core-react/store/store";
import { BaseShippingCategoryRequestModel } from "models/baseShippingCategory";
import { useState } from "react";
import { getError } from "../utils/errors";

export const useCreateBaseShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom,
  );

  const createBaseShippingCategory = async (
    payload: BaseShippingCategoryRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingCoreService.baseShippingCategoryResource.create(payload);
      setIsLoading(false);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        refetch: true,
      });

      return response;
    } catch (error: any) {
      setIsLoading(false);
      throw error;
    }
  };

  return { createBaseShippingCategory, isLoading };
};

export const useUpdateBaseShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom,
  );
  const updateBaseShippingCategory = async (
    id: string,
    payload: BaseShippingCategoryRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingCoreService.baseShippingCategoryResource.update(
          id,
          payload,
        );
      setIsLoading(false);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setIsLoading(false);
      throw error;
    }
  };

  return { updateBaseShippingCategory, isLoading };
};

export const useGetBaseShippingCategory = () => {
  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom,
  );
  const { shippingCoreService } = useService();
  const getBaseShippingCategory = async (params?: string): Promise<void> => {
    setBaseShippingCategoryData({
      ...baseShippingCategoryData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingCoreService.baseShippingCategoryResource.get(params);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getBaseShippingCategory } as const;
};
