/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    Card,
    Col,
    Modal,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
    Image,
    Dropdown,
    notification,
    Space,
} from "antd";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { buyActionCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import { useEffect, useState } from "react";
import {
    useTakeActionCancel,
    useTakeActionList,
    useTakeActionPerform,
    useTakeActionRefund,
} from "lib/core-react/hooks/private/useBuyAction";
import {
    BuyActionCollectionModel,
    BuyActionModel,
} from "models/buyActionCollectionModel";
import {
    CloseOutlined,
    DownOutlined,
    EditOutlined,
    EyeFilled,
    HddFilled,
    RollbackOutlined,
} from "@ant-design/icons";
import ActionViewDetailsModal from "./ActionViewDetailsModal";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "../../../components";
import { IFilterType } from "../../../types/filters";
import { PaginationModel } from "../../../models/pagination";
import FiltersComponent from "../../../components/FiltersComponent";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM } from "consts/permission-enum/buy-and-take-enum";
import TextArea from "antd/es/input/TextArea";
import {
    IActionPerformPayload,
    IActionPerformPayloadSequence,
} from "types/buyActionCollection";

const ActionsNeeded = () => {
    const { getAllTakeActions } = useTakeActionList();
    const { takeActionPerform, isLoading: performLoading } =
        useTakeActionPerform();
    const { takeActionRefund, isLoading: refundLoading } =
        useTakeActionRefund();
    const { takeActionCancel, isLoading: cancelLoading } =
        useTakeActionCancel();
    const [{ data: buyActionCollectionData, isLoading, refetch }] = useAtom(
        buyActionCollectionAtom,
    );
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [selectedBuyProduct, setSelectedBuyProduct] = useState<
        BuyActionModel | undefined
    >(undefined);

    const [selectedAction, setSelectedAction] = useState(null);
    const [textAreaValue, setTextAreaValue] = useState("");

    const [openReject, setOpenReject] = useState<boolean>(false);

    const { Text } = Typography;
    const { width, isMobile } = useWindowWidth();

    const {
        filters,
        isFirstCall,
        isFetched,
        handleFilterChange,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest(filters || {}, getAllTakeActions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const AllTackActonsCollectionData =
        buyActionCollectionData &&
        new BuyActionCollectionModel(buyActionCollectionData);

    const paginationData = AllTackActonsCollectionData?.pagination;

    const filterData = AllTackActonsCollectionData?.getFilters();

    const handleShowDetail = (record: any) => {
        setSelectedBuyProduct(record);
        setIsShowCreateModal(record);
    };

    // // Getting all available filters
    useEffect(() => {
        if (!isFetched && AllTackActonsCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFetched,
        initializeAvailableFilter,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        AllTackActonsCollectionData?.getFilters(),
    ]);

    const handleRejectCancel = () => {
        setOpenReject(false);
    };

    useEffect(() => {
        if (openReject) {
            setTextAreaValue("");
        }
    }, [openReject]);

    //Take Action handlers =>
    const handleTakeActionNeeded = async () => {
        if (!selectedBuyProduct) {
            return;
        }
        console.log(selectedBuyProduct, "selectedBuyProduct");
        const sequences: IActionPerformPayloadSequence[] = selectedBuyProduct
            .getSequences()
            .map((sequence) => {
                const sequenceData: IActionPerformPayloadSequence = {
                    action_type: sequence.getType(),
                    customer_note: textAreaValue,
                    sequence_id: sequence.id,
                };

                if (
                    [
                        "product-stock-out",
                        "sku-mismatched",
                        "quantity-update",
                    ].includes(sequence.getType())
                ) {
                    sequenceData.variants = selectedBuyProduct
                        .getBuyProduct()
                        .getProductVariations()
                        .data.map((variant) => ({
                            variant_id: variant.id,
                            sku_id: variant.sku_id,
                            new_quantity: variant.quantity, // Use the new quantity value here
                            original_unit_price: variant.getUnitPrice(),
                        }));
                }

                return sequenceData;
            });

        const payload: IActionPerformPayload = {
            customer_note: textAreaValue,
            action: selectedAction ? selectedAction : "",
            sequences: sequences,
        };

        try {
            let data: any;
            if (selectedAction === "purchase") {
                data = await takeActionPerform(
                    Number(selectedBuyProduct?.getId()),
                    payload,
                );
            } else if (selectedAction === "refund") {
                data = await takeActionRefund(
                    Number(selectedBuyProduct?.getId()),
                    payload,
                );
            } else if (selectedAction === "cancel") {
                data = await takeActionCancel(
                    Number(selectedBuyProduct?.getId()),
                );
            }

            if (data) {
                setOpenReject(false);
            }
            setOpenReject(false);
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
            setOpenReject(false);
        }
    };

    const handleMenuClick = (key: any, record: BuyActionModel) => {
        setSelectedBuyProduct(record);
        setSelectedAction(key);
        setOpenReject(true);
    };

    // // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAllTakeActions,
        );
    };

    // // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AllTackActonsCollectionData,
        handlePaginationChange,
    );

    // Filter Handler

    const handleProductFilter = () => {
        filters && ApiHelperModel.makeGetRequest(filters, getAllTakeActions);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            width: 70,
            key: "sl",
            align: "center" as const,
            render: (_: string, __: BuyActionModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Images",
            dataIndex: "image",
            width: 70,
            key: "image",
            align: "center" as const,
            render: (_: string, record: BuyActionModel, index: number) => {
                return (
                    <div>
                        <Image.PreviewGroup
                            preview={{
                                onChange: (current, prev) =>
                                    console.log(
                                        `current index: ${current}, prev index: ${prev}`,
                                    ),
                            }}
                        >
                            <Image
                                width={60}
                                src={record.getBuyProduct().getProductImage()}
                            />
                        </Image.PreviewGroup>
                    </div>
                );
            },
        },
        {
            title: "Action Number",
            dataIndex: "action_number",
            key: "action_number",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <Text copyable={true}>{record.getActionNumber()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Action Status",
            dataIndex: "status",
            key: "status",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <StatusTag
                            text={record.getStatus()}
                            slug={record.getStatus()}
                        />
                    </div>
                );
            },
        },
        {
            title: "Product Status",
            dataIndex: "status",
            key: "status",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <StatusTag
                            text={record.getBuyProduct().getStatus()}
                            slug={record.getBuyProduct().getStatus()}
                        />
                    </div>
                );
            },
        },
        {
            title: "Issue Type",
            dataIndex: "issue_type",
            key: "issue_type",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        {record.getSequences().map((item) => (
                            <Tag color="volcano">{item.getType()}</Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Create At",
            dataIndex: "create_at",
            key: "create_at",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <Tag color={"purple"}> {record.getCreatedAt()}</Tag>
                    </div>
                );
            },
        },
        {
            title: "Details",
            dataIndex: "ud",
            key: "details",
            fixed: isMobile ? "right" : undefined,
            width: isMobile ? 100 : 150,
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return checkActionPermission(
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ACTION_VIEW,
                    <Tooltip title="View Details">
                        <Button
                            onClick={() => handleShowDetail(record)}
                            shape="round"
                            icon={<EyeFilled />}
                        />
                    </Tooltip>,
                    null,
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            render: (_, record: BuyActionModel) => {
                const items = [
                    {
                        label: "Perform",
                        key: "purchase",
                        icon: <EditOutlined />,
                        onClick: () => handleMenuClick("purchase", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        label: "Refund",
                        key: "refund",
                        icon: <RollbackOutlined />,
                        onClick: () => handleMenuClick("refund", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        label: "Cancel",
                        key: "cancel",
                        icon: <CloseOutlined />,
                        onClick: () => handleMenuClick("cancel", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];
                return (
                    <Space size="middle">
                        <Dropdown menu={{ items: items }}>
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    </Space>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Actions Needed"
                    style={{ marginTop: "10px" }}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <Card
                                    style={{ marginBottom: "10px" }}
                                    title="Filter"
                                >
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <div>
                        <Table
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={AllTackActonsCollectionData?.getData()}
                            //@ts-ignore
                            columns={columns}
                            scroll={{ x: 1000 }}
                            pagination={paginationConfig}
                        />
                    </div>
                </PageHeader>
                {selectedBuyProduct && (
                    <Modal
                        title={`Take action Details for #${selectedBuyProduct
                            ?.getBuyProduct()
                            .getProductNumber()}`}
                        open={isShowCreateModal}
                        onCancel={() => {
                            {
                                setIsShowCreateModal(false);
                            }
                        }}
                        onOk={() => {
                            {
                                setIsShowCreateModal(false);
                            }
                        }}
                        width={width < 600 ? "90vw" : "50vw"}
                    >
                        <ActionViewDetailsModal
                            selectedProduct={selectedBuyProduct}
                        />
                    </Modal>
                )}

                {openReject && (
                    <Modal
                        open={openReject}
                        title={`Are you sure you want to ${selectedAction ? selectedAction : ""}?`}
                        onCancel={handleRejectCancel}
                        footer={[
                            <Button key="back" onClick={handleRejectCancel}>
                                No
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleTakeActionNeeded}
                                loading={
                                    performLoading ||
                                    refundLoading ||
                                    cancelLoading
                                }
                            >
                                Yes
                            </Button>,
                        ]}
                    >
                        {selectedAction !== "cancel" && (
                            <TextArea
                                value={
                                    textAreaValue ? textAreaValue : undefined
                                }
                                onChange={(e) =>
                                    setTextAreaValue(e.target.value)
                                }
                                placeholder="Please add the Note..."
                                rows={4}
                            />
                        )}
                    </Modal>
                )}
            </div>
        </>
    );
};

export default ActionsNeeded;
