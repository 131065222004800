import { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Form, Select, message } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";
import { useAtom } from "jotai";
import { useGetRegion } from "lib/core-react/hooks/private";
import { useCopyCommissionForRegion } from "lib/core-react/hooks/private/useCommission";
import { regionRegion } from "lib/core-react/store/store";
import {
  CommissionCopyRequestModel,
  CommissionModel,
} from "models/commissionCollectionModel";
import { RegionModel } from "models/region";

interface IProps {
  selectedCommission: CommissionModel;
  setIsShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyCommissionForRegion = ({
  selectedCommission,
  setIsShowCopyModal,
}: IProps) => {
  const [form] = Form.useForm();
  const { getRegion } = useGetRegion();
  const { copyCommission, isError } = useCopyCommissionForRegion();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [{ data: regionDataAtom, isLoading }] = useAtom(regionRegion);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getRegion("per_page=200");
    
  }, []);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  const onFinish = async (value: CommissionCopyRequestModel) => {
    try {
      const payload: CommissionCopyRequestModel = {
        region_id: value.region_id,
        is_default: value.is_default ?? false,
      };
      const response = await copyCommission(selectedCommission.id, payload);
      setIsShowCopyModal(false);
      setIsErrorVisible(false);
      message.success(response);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="create-commission-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          rules={[{ required: true, message: "Region is required" }]}
          name="region_id"
          label="Region"
        >
          <Select loading={isLoading} placeholder="Please select a region">
            {regionData?.getRegionIdsAsObject() &&
              renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
          </Select>
        </Form.Item>
        <Form.Item name="is_default" valuePropName="checked">
          <Checkbox>Set as Default</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isLoading}>
            Copy
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default CopyCommissionForRegion;
