import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
} from "antd";

import { disputeCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { IFilterType } from "types/filters";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import FiltersComponent from "components/FiltersComponent";
import { useGetDisputes } from "lib/core-react/hooks/private/useDispute";
import { StatusTag } from "components";
import {
  DisputeCollectionModel,
  DisputeModel,
} from "models/disputeCollectionModel";
import { EyeFilled } from "@ant-design/icons";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { truncateTitle } from "../../../utils/helper";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ADMIN_REFUND_PERMISSION_ENUM } from "consts/permission-enum/refund-enum";
import CommonError from "../../../components/Error/CommonError";
const { Text } = Typography;

const RefundDispute = () => {
  const navigate = useNavigate();
  const { getDisputes } = useGetDisputes();
  const { isMobile } = useWindowWidth();
  const [{ data: disputeCollectionData, isLoading: disputeLoading, refetch, unAuthorized }] =
    useAtom(disputeCollectionAtom);
  const disputeCollectionModelData =
    disputeCollectionData && new DisputeCollectionModel(disputeCollectionData);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getDisputes);
    }
  }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

  const filterData = disputeCollectionModelData?.getFilters();
  // Pagination
  const paginationData = disputeCollectionModelData?.getPagination();

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && disputeCollectionModelData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    disputeCollectionModelData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getDisputes,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    disputeCollectionModelData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getDisputes);
  };

  const disputeLists = disputeCollectionModelData?.getData();

  if (disputeLoading) {
    return (
      <Row justify={"center"} style={{ marginTop: 100 }} align={"middle"}>
        <Spin />
      </Row>
    );
  }else if(unAuthorized){
      return <CommonError unAuthorized={unAuthorized} />;
  }

  const columns: TableColumnsType<DisputeModel> = [
    {
      title: "#SL",
      dataIndex: "id",
      width: 60,
      key: "id",
      align: "center" as const,
      render: (_: string, record, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData?.getCurrentPage() - 1) *
              paginationData?.getPerPage() +
              index +
              1
              : ""}
          </div>
        );
      },
    },

    {
      title: "Dispute info",
      dataIndex: "dispute_id",
      width: 360,
      key: "id",
      render: (tags: any, record, ) => {
        return (
          <Row>
            <Col>
              <Space direction="vertical" size={2}>
                <Descriptions size="small" column={1}>
                  <Descriptions.Item
                    label={<span style={{ fontWeight: "bold" }}>Number:</span>}
                  >
                    <Text copyable>{record?.getDisputeNumber()}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={<span style={{ fontWeight: "bold" }}>Intent:</span>}
                  >
                    <StatusTag
                      slug={record?.getDisputeIntent()}
                      text={record?.getDisputeIntent()}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span style={{ fontWeight: "bold" }}>Region Code:</span>
                    }
                  >
                    {record?.getRegionCode()}
                  </Descriptions.Item>
                  {record?.getRegion()?.currency?.getCurrencyCode() && (
                    <Descriptions.Item
                      label={
                        <span style={{ fontWeight: "bold" }}>Currency:</span>
                      }
                    >
                      {record?.getRegion()?.currency?.getCurrencyCode()}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        Dispute Status:
                      </span>
                    }
                  >
                    <StatusTag
                      slug={record.getStatus()}
                      text={record.getStatus()}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Space>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Buy Product info",
      dataIndex: "buy_product",
      width: 360,
      key: "id",
      render: (tags: any, record, ) => {
        const {
          getProductTitle,
          getStatus,
          getProductImage,
          getProductNumber,
        } = record?.getBuyProduct();

        return (
          <Row>
            <Col>
              <Space direction="vertical" size={2}>
                <Descriptions size="small" column={1}>
                  <Descriptions.Item>
                    {getProductImage() !== "None" && getProductImage() ? (
                      <Image
                        height={60}
                        width={60}
                        wrapperStyle={{ marginBottom: 4 }}
                        alt="product image"
                        src={getProductImage()}
                        preview={true}
                        style={{ borderRadius: 4 }}
                      />
                    ) : (
                      <Skeleton.Image
                        active={true}
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 4,
                          marginBottom: 4,
                        }}
                      />
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        Product Number:
                      </span>
                    }
                  >
                    <Text copyable>{getProductNumber()}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<span style={{ fontWeight: "bold" }}>Title:</span>}
                  >
                    {truncateTitle(getProductTitle(), 30)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        Dispute Status:
                      </span>
                    }
                  >
                    <StatusTag slug={getStatus()} text={getStatus()} />
                  </Descriptions.Item>
                </Descriptions>
              </Space>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "User",
      dataIndex: "user",
      width: 250,
      key: "id",
      render: (tags: any, record,) => {
        return (
          <Row>
            <Col>
              <Space direction="vertical" size={1}>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span
                    className={"ant-descriptions-item-label"}
                    style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.45)" }}
                  >
                    Name :{" "}
                  </span>
                  <span>{record?.getUserName()}</span>
                </p>
                <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                  <span
                    className={"ant-descriptions-item-label"}
                    style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.45)" }}
                  >
                    Email :{" "}
                  </span>
                  <span>{record?.getUserEmail()}</span>
                </p>
              </Space>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "details",
      fixed: "right",
      width: isMobile ? 80 : 100,
      key: "id",
      align: "center",
      render: (tags: any, record: DisputeModel, ) => {
        return (
          <Space direction="vertical" wrap>
            <Row justify={"center"}>
              <Col>
                <Space
                  direction="horizontal"
                  size={1}
                  style={{ textAlign: "center" }}
                >
                  <Tooltip title="Invoice Details">
                    {checkActionPermission(
                      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          navigate(
                            `/refund-manage/refund/details/${record.getId()}`,
                          );
                        }}
                        shape="round"
                        icon={<EyeFilled />}
                      />,
                      null
                    )}
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Discounts"
            extra={[
              checkActionPermission(ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
                <Button
                  key="1"
                  type="primary"
                  onClick={() => navigate("/refund-manage/refund")}
                >
                  Create Refund
                </Button>,
                null

              )
            ]}
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <div style={{ marginTop: 20 }}>
              <Table
                style={{ overflow: "auto" }}
                columns={columns}
                dataSource={disputeLists}
                bordered
                loading={disputeLoading}
                rowKey="id"
                scroll={{ x: 1000 }}
                pagination={paginationConfig}
              />
            </div>
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default RefundDispute;
