import DiscountResource from "lib/core/resources/private/discountResource";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class DiscountService extends CommerceApiService {
  public discountResource: DiscountResource;

  constructor() {
    super();

    this.discountResource = new DiscountResource(this.config);
  }
}

export default DiscountService;
