import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
  disputeCollectionAtom,
  disputeDetailsAtom,
} from "lib/core-react/store/store";
import { IDisputeApprovedFormData } from "types/disputeDetailsCollection";

// Dispute

export const useGetDisputes = () => {
  const [disputes, setDisputes] = useAtom(disputeCollectionAtom);
  const { disputeService } = useService();

  const getDisputes = async (params?: string): Promise<void> => {
    setDisputes({ ...disputes, isLoading: true, error: null });

    try {
      const response = await disputeService.disputeResource.getDisputes(params);
      setDisputes({
        ...disputes,
        data: response,
        isLoading: false,
        error: null,
      });
    } catch (error:any) {
      setDisputes({
        ...disputes,
        isLoading: false,
        refetch: false,
        error: getError(error),
        unAuthorized: error?.response?.status === 403,
      });

      throw error;
    }
  };

  return { getDisputes } as const;
};

export const useGetDispute = () => {
  const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
  const { disputeService } = useService();
  const getDispute = async (params: number) => {
    setDisputeDetails({ ...disputeDetails, isLoading: true, error: null });

    try {
      const response = await disputeService.disputeResource.getDispute(params);
      setDisputeDetails({
        ...disputeDetails,
        data: response,
        isLoading: false,
        error: null,
      });
      return
    } catch (error) {
      setDisputeDetails({
        ...disputeDetails,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
    return
  };

  return { getDispute } as const;
};

export const useUpdateDispute = () => {
  const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
  const { disputeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const updateDispute = async (
    id: number,
    payload: IDisputeApprovedFormData
  ) => {
    setIsLoading(true);
    try {
      const response = await disputeService.disputeResource.updateDispute(
        id,
        payload
      );
      setIsLoading(false);
      setDisputeDetails({
        ...disputeDetails,
        data: response,
        isLoading: false,
        error: null,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { updateDispute, isLoading, isError };
};
