import { IPagination } from "types/pagination";
import {
  IAgentVerification,
  IAgentVerificationCollection,
  IAgentVerificationItem,
  IAgentVerificationItemCollection,
} from "types/agentVerificationCollection";
import { AgentVerificationStatus } from "enums/agentCompanyCollectionEnums";

export class AgentVerificationItemModel {
  object: string;
  id: number;
  name: string;
  type: string;
  value: string;
  value_type: string;

  constructor(dataItem: IAgentVerificationItem) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.type = dataItem.type;
    this.value = dataItem.value;
    this.value_type = dataItem.value_type;
  }

  getId = () => this.id;
  getName = () => this.name;
  getType = () => this.type;
  getValue = () => this.value;
  getValueType = () => this.value_type;
}

export class AgentVerificationItemCollectionModel {
  object: string;
  data: AgentVerificationItemModel[];

  constructor(data: IAgentVerificationItemCollection) {
    this.object = data.object;
    this.data = data.data.map((d) => new AgentVerificationItemModel(d));
  }

  getData = () => this.data;
}

export class AgentVerificationModel {
  object: string;
  id: number;
  name: string;
  medium_type: string;
  status: AgentVerificationStatus;
  verification_items: AgentVerificationItemCollectionModel;
  created_at: string;

  constructor(dataItem: IAgentVerification) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.medium_type = dataItem.medium_type;
    this.status = dataItem.status;
    this.verification_items = new AgentVerificationItemCollectionModel(
      dataItem.verification_items
    );
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getName = () => this.name;
  getMediumType = () => this.medium_type;
  getStatus = () => this.status;
  getVerificationItems = () => this.verification_items;
  getCreatedAt = () => this.created_at;
}

export class AgentVerificationCollectionModel {
  public readonly object: string;
  public readonly data: AgentVerificationModel[];
  public readonly pagination: IPagination;

  constructor(data: IAgentVerificationCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AgentVerificationModel(item));
    this.pagination = data.pagination;
  }

  getData = () => this.data;
  getPagination = () => this.pagination;
  getAgentVerificationById(id: number): AgentVerificationModel | undefined {
    return this.data.find((item) => item.id === id);
  }
}
