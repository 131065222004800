import {
  LanguageDataStatus,
  LanguageEnum,
} from "enums/languageCollectionEnums";
import { IFilterType } from "types/filters";
import { ILanguage, ILanguageCollection } from "types/languageCollection";
import { PaginationModel } from "./paginationModel";

export class LanguageModel {
  public readonly object: LanguageEnum.Language;
  public readonly id: number;
  public readonly code: string;
  public readonly name: string;
  public readonly status: LanguageDataStatus;
  public readonly is_rtl: boolean;
  public readonly created_at: string;

  constructor(dataItem: ILanguage) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.status = dataItem.status;
    this.is_rtl = dataItem.is_rtl;
    this.created_at = dataItem.created_at;
  }
  getId = () => this.id;
  getCode = () => this.code;
  getName = () => this.name;
  getStatus = () => this.status;
  getIsRtl = () => this.is_rtl;
  getCreatedAt = () => this.created_at;
}

export class LanguageCollectionModel {
  object: LanguageEnum.LanguageCollection;
  data: LanguageModel[];
  pagination: PaginationModel;
  filters: IFilterType;

  constructor(data: ILanguageCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new LanguageModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }
  getLanguageId() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
}
