// Enum for admin permissions in the Purchase module
export enum ADMIN_PURCHASE_PERMISSION_ENUM {
    // Buy-product permissions
    ADMIN_BUY_PRODUCT_ADD = "admin-buy-product-add",
    ADMIN_BUY_PRODUCT_VIEW = "admin-buy-product-view",
    ADMIN_BUY_PRODUCT_APPROVE = "admin-buy-product-approve",
    ADMIN_BUY_PRODUCT_REJECT = "admin-buy-product-reject",
    ADMIN_BUY_PRODUCT_VARIATION_ADD = "admin-buy-product-variation-add",
    ADMIN_BUY_PRODUCT_VARIATION_UPDATE = "admin-buy-product-variation-update",
    ADMIN_BUY_PRODUCT_VARIATION_DELETE = "admin-buy-product-variation-delete",
    ADMIN_BUY_PRODUCT_FX_SET = "admin-buy-product-fx-set",
    ADMIN_BUY_PRODUCT_FX_UPDATE = "admin-buy-product-fx-update",
    ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE = "admin-buy-product-shipment-update",
    ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE = "admin-change-shipping-agent-category-price",
    
    // Buy Order permissions
    ADMIN_BUY_ORDER_VIEW = "admin-buy-order-view",
    ADMIN_BUY_PRODUCT_ADD_TO_ORDER = "admin-buy-product-add",
    
    // Buy Product Charge permissions
    ADMIN_BUY_PRODUCT_CHARGE_ADD = "admin-buy-product-charge-add",
    ADMIN_BUY_PRODUCT_CHARGE_UPDATE = "admin-buy-product-charge-update",
    ADMIN_BUY_PRODUCT_CHARGE_DELETE = "admin-buy-product-charge-delete"
}


