import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";

class ShippingCartResource extends BaseResource {
  path = `/api/shipping-cart/admin/v1/shipping-carts`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}${params ? `?${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
}

export default ShippingCartResource;
