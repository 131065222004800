import React from "react";
import { Card, Table, Tag, Row, Col } from "antd";
import { InvoiceModel } from "../../../../models/invoiceCollectionModel";
enum InvoiceItemStatus {
  AWAITING_FULFILMENT = "awaiting-fulfilment",
  FULFILLED = "fulfilled",
  PARTIALLY_REFUNDED = "partially-refunded",
  REFUNDED = "refunded",
}

interface IItemDetailsProps {
  invoice: InvoiceModel;
}

const ItemDetails: React.FC<IItemDetailsProps> = ({ invoice }) => {
  const productType = invoice.getInvoiceProductType();
  const isVariationAvailable = invoice.isBuyProductVariationPresentInItems();

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      render: (image: string) => (
        <img
          src={image}
          alt="Product"
          style={{ width: "50px", height: "50px" }}
        />
      ),
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "SKU ID",
      dataIndex: "skuId",
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "Original Unit Price",
      dataIndex: "originalUnitPrice",
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "Original Total Price",
      dataIndex: "originalTotalPrice",
      hidden: productType !== "BuyProduct" || !isVariationAvailable,
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
    },
    {
      title: "Initial Amount",
      dataIndex: "initialAmount",
    },
    {
      title: "Refundable Amount",
      dataIndex: "refundableAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <Tag color={getStatusColor(status as InvoiceItemStatus)}>{status}</Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (createdAt: string) => <Tag color="purple">{createdAt}</Tag>,
    },
  ].filter((col) => !col.hidden);

  // Map data to the required structure
  const data =
    invoice.getItems()?.map((item, index) => ({
      key: index,
      image: item.getProductVariation()?.image,
      skuId: item.getProductVariation()?.sku_id,
      quantity: item.getProductVariation()?.quantity,
      unitPrice: item.getProductVariation()?.unit_price,
      originalUnitPrice: item.getProductVariation()?.original_unit_price,
      originalTotalPrice: item.getProductVariation()?.original_total_price,
      totalPrice: item.getTotalAmount(),
      initialAmount: item.getInitialAmount(),
      refundableAmount: item.getRefundableAmount(),
      status: item.getStatus(),
      createdAt: item.getCreatedAt(),
    })) || [];

  function getStatusColor(status: InvoiceItemStatus): string {
    switch (status) {
      case InvoiceItemStatus.AWAITING_FULFILMENT:
        return "magenta";
      case InvoiceItemStatus.FULFILLED:
        return "green";
      case InvoiceItemStatus.PARTIALLY_REFUNDED:
        return "orange";
      case InvoiceItemStatus.REFUNDED:
        return "red";
      default:
        return "black"; // default color
    }
  }

  return (
    <Card
      style={{ margin: "10px" }}
      title={
        productType === "BuyProduct"
          ? "Buy Product Details"
          : " Shipment Product Details "
      }
    >
      {productType === "BuyProduct" && invoice.buy_product ? (
        <div
          style={{
            padding: "20px",
            background: "#f7f7f7",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8} md={6}>
              <img
                src={invoice.buy_product?.getProductImage()}
                alt="Product"
                style={{ width: "60%", height: "auto", maxWidth: "200px" }}
              />
            </Col>
            <Col xs={24} sm={16} md={18}>
              <div className="product-detail-item">
                <b>Product Title:</b> {invoice.buy_product?.getProductTitle()}
              </div>
              <div className="product-detail-item">
                <b>Product ID:</b> {invoice.buy_product?.getProductID()}
              </div>
              <div className="product-detail-item">
                <b>Product Link:</b>{" "}
                <a
                  href={invoice.buy_product?.getProductLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Product
                </a>
              </div>
              <div className="product-detail-item">
                <b>Total Quantity:</b> {invoice.buy_product?.getTotalQuantity()}
              </div>
              <div className="product-detail-item">
                <b>Status:</b> {invoice.buy_product?.getStatus()}
              </div>
              <div className="product-detail-item">
                <b>Created At:</b>
                <Tag color="purple">{invoice.buy_product?.getCreatedAt()}</Tag>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          style={{
            padding: "20px",
            background: "#f7f7f7",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <div className="product-detail-item">
                <b>Product Number:</b>{" "}
                {invoice.getShipmentProduct()?.getProductNumber()}
              </div>
              <div className="product-detail-item">
                <b>Shipping Mode:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingMode()}
              </div>
              <div className="product-detail-item">
                <b>Shipping type:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingType()}
              </div>
              <div className="product-detail-item">
                <b>Status:</b> {invoice.getShipmentProduct()?.getStatus()}
              </div>
              <div className="product-detail-item">
                <b>Shipment Instruction:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingInstrucation() || ""}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="product-detail-item">
                <b>Full Name:</b> {invoice.getShipmentProduct()?.getFullName()}
              </div>
              <div className="product-detail-item">
                <b>Email:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().email}
              </div>
              <div className="product-detail-item">
                <b>Phone:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().phone}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="product-detail-item">
                <b>Country:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().country}
              </div>
              <div className="product-detail-item">
                <b>District:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().district}
              </div>
              <div className="product-detail-item">
                <b>Address1:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().address_1}
              </div>
              <div className="product-detail-item">
                <b>Address2:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().address_2}
              </div>
              <div className="product-detail-item">
                <b>Area:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().area}
              </div>
              <div className="product-detail-item">
                <b>Postal Code:</b>{" "}
                {invoice.getShipmentProduct()?.getShippingAddress().postal_code}
              </div>
            </Col>
          </Row>
        </div>
      )}
      <br />
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 1237 }}
      />
    </Card>
  );
};
export default ItemDetails;
