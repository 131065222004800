import { Space, Typography } from "antd";
import { ShipmentProductPackageModel } from "models/shipmentProductPackageCollectionModel";

interface IProps {
  productPackage: ShipmentProductPackageModel;
}

export const CartonVariationsModal = ({ productPackage }: IProps) => {
  return (
    <>
      {productPackage.getVariations().getData().length ? (
        <Space
          size="large"
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography.Text strong style={{ textTransform: "capitalize" }}>
            {productPackage
              .getVariations()
              .getData()[0]
              .getProperties()
              .getData()
              .map((d) => d.getPropertyName())
              .join(", ")}
          </Typography.Text>
          <Typography.Text strong>Quantity</Typography.Text>
        </Space>
      ) : (
        ""
      )}

      <Space
        size="large"
        direction="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {productPackage.getVariations().getData().length
          ? productPackage
              .getVariations()
              .getData()
              .map((variation, variationIndex) => {
                return (
                  <div
                    key={variation.getId()}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "10px",
                    }}
                  >
                    <Typography.Text>
                      {variationIndex + 1}
                      {". "}
                      {variation
                        .getProperties()
                        .getData()
                        .map((prop) => prop.getPropertyValueName())
                        .join(", ")}
                    </Typography.Text>
                    <Typography.Text>{variation.getQuantity()}</Typography.Text>
                  </div>
                );
              })
          : "Variation not found"}
      </Space>
    </>
  );
};
