export enum DiscountCollectionEnum {
  DiscountCollection = "DiscountCollection",
  Discount = "Discount",
}

export enum DiscountRuleCollectionEnum {
  DiscountRuleCollection = "DiscountRuleCollection",
  DiscountRule = "DiscountRule",
}

export enum DiscountRulesSchemaEnum {
  DiscountRulesSchema = "DiscountRulesSchema",
}

export enum DiscountUsageHistoryCollectionEnum {
  DiscountUsageHistoryCollection = "DiscountUsageHistoryCollection",
  DiscountUsageHistory = "DiscountUsageHistory",
}

export enum DiscountAmountTypeEnum {
  Flat_Amount = "flat-amount",
  Percentage = "percentage",
}

export enum DiscountLimitationTypeEnum {
  NO_LIMITS = "no-limits",
  N_TIMES_PER_DAY  = "n-times-per-day",
  N_TIMES_PER_USER = "n-times-per-user",
  N_TIMES_PER_DAY_PER_USER = "n-times-per-day-per-user",
}

export enum DiscountStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
