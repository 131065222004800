import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IAgentVerificationFormData } from "types/agentVerificationCollection";

class AgentCompanyResource extends BaseResource {
  path = `/api/shipping-agent/admin/agent-company/v1/agent-companies`;
  verificationsPath = `/api/shipping-agent//admin/verifications/v1/verifications`;

  /**
   * @description Gets agent company list,
   * accepts fields and expands as params
   */
  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.path}`;
    if (params) {
      path = `${path}?${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  /**
   * @description Gets member list,
   * accepts fields and expands as params
   */
  getMembers(
    agent_company_id: number,
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.path}/${agent_company_id}/members`;
    if (path) {
      path = `${path}/?${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  /**
   * @description Gets verification list,
   * accepts fields and expands as params
   */
  getVerifications(
    agent_company_id: number,
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.verificationsPath}?expand=agentVerificationItems&agent_company_id=${agent_company_id}`;
    if (params) {
      path = `${path}&${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  /**
   * @description Updates agent company verification
   */
  updateVerification(
    id: number,
    payload: IAgentVerificationFormData,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.verificationsPath}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  /**
   * @description Gets shipping point list,
   * accepts fields and expands as params
   */
  getShippingPoints(
    agent_company_id: number,
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${agent_company_id}/shipping-points${
      params ? `?${params}` : ""
    }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
}

export default AgentCompanyResource;
