import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { regionRegion } from "lib/core-react/store/store";
import {} from "models/region";
import { getError } from "../utils/errors";
import { IRegionPostRequest } from "types/region";

export const useCreateRegion = () => {
  const { coreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [regionData, setRegionData] = useAtom(regionRegion);

  const createRegion = async (payload: IRegionPostRequest) => {
    setIsLoading(true);
    try {
      const response = await coreService.RegionResource.create(payload);
      setIsLoading(false);
      setRegionData({ ...regionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createRegion, isLoading, isError };
};

export const useUpdateRegion = () => {
  const { coreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [regionData, setRegionData] = useAtom(regionRegion);

  const updateRegion = async (id: number, payload: IRegionPostRequest) => {
    setIsLoading(true);
    try {
      const response = await coreService.RegionResource.update(id, payload);
      setIsLoading(false);
      setRegionData({ ...regionData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateRegion, isLoading, isError };
};

export const useGetRegion = () => {
  const [region, setRegion] = useAtom(regionRegion);
  const { coreService } = useService();
  const getRegion = async (params?: string): Promise<void> => {
    setRegion({ ...region, isLoading: true, error: null });
    try {
      const response = await coreService.RegionResource.get(params);
      setRegion({
        ...region,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setRegion({
        ...region,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getRegion } as const;
};
