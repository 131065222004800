import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { auctionRuleAtom } from "lib/core-react/store/store";
import { AuctionRuleRequestModel } from "models/auctionRule";
import { useState } from "react";
import { getError } from "../utils/errors";

export const useCreateAuctionRule = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [auctionRuleData, setAuctionRuleData] = useAtom(auctionRuleAtom);
  const createAuctionRule = async (payload: AuctionRuleRequestModel) => {
    setIsLoading(true);
    try {
      const response = await shippingCoreService.auctionRuleResource.create(payload);
      setAuctionRuleData({ ...auctionRuleData, refetch: true });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createAuctionRule, isLoading, isError };
};

export const useUpdateAuctionRule = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [auctionRuleData, setAuctionRuleData] = useAtom(auctionRuleAtom);
  const updateAuctionRule = async (
    id: number,
    payload: AuctionRuleRequestModel
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingCoreService.auctionRuleResource.update(id, payload);
      setIsLoading(false);
      setAuctionRuleData({ ...auctionRuleData, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { updateAuctionRule, isLoading, isError };
};

export const useGetAuctionRule = () => {
  const [auctionRuleData, setAuctionRuleData] = useAtom(auctionRuleAtom);
  const { shippingCoreService } = useService();
  const getAuctionRule = async (params?: string): Promise<void> => {
    setAuctionRuleData({
      ...auctionRuleData,
      isLoading: true,
      error: null,
    });
    try {
      const response = await shippingCoreService.auctionRuleResource.get(params);
      setAuctionRuleData({
        ...auctionRuleData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAuctionRuleData({
        ...auctionRuleData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getAuctionRule } as const;
};
