import { Avatar, Card, List } from "antd";

import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

export const AddonService = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      {ShipmentProductData?.getAddonServices().getData().length ? (
        <List
          grid={{ gutter: 16, column: 2 }}
          size="small"
          pagination={{
            pageSize: 3,
            hideOnSinglePage: true,
          }}
          dataSource={
            ShipmentProductData.getAddonServices()
              .getData()
              .map((data) => {
                return {
                  name: data.getName(),
                  image: data.getImage(),
                  description: data.getDescription(),
                };
              }) || []
          }
          renderItem={(item) => {
            return (
              <List.Item key={item.name}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={40}
                      shape="square"
                      src={item.image.getSrc()}
                    />
                  }
                  title={item.name}
                />
                {item.description}
              </List.Item>
            );
          }}
        />
      ) : (
        "No addon service requested."
      )}
    </Card>
  );
};
