import { permissionData } from "./permissionData";


 export const userRoleData = {
    id: 18717,
    name: "Nesar Ahammed",
    email: "nesar@moveon.com.bd",
    phone: null,
    roles: [
      {
        id: 1,
        name: "super-admin",
        label: "Super Admin",
        guard_name: "api",
        access: "admin",
        agent_company_id: null,
        permissions: [
          {
            id: 1,
            name: "ship-for-me-order-list-view",
            label: "ShipForMe Order list View",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 2,
            name: "ship-for-me-order-products-view",
            label: "ShipForMe Products View",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 3,
            name: "ship-for-me-order-products-reject",
            label: "ShipForMe Products Reject",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 4,
            name: "ship-for-me-order-products-approve",
            label: "ShipForMe Products Approve",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 5,
            name: "ship-for-me-order-product-category-update",
            label: "ShipForMe Product Category Update",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 6,
            name: "ship-for-me-order-product-status-logs-view",
            label: "ShipForMe Product Status Logs",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 7,
            name: "all-agent-view",
            label: "All agent view",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 8,
            name: "create-new-agent",
            label: "Agent create",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 9,
            name: "category-commission-view",
            label: "View all category commission",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 10,
            name: "global-category-commission-update",
            label: "Global category commission Update",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 11,
            name: "category-commission-update",
            label: "Category commission Update",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 12,
            name: "warehouse-approve",
            label: "Warehouse Approve",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 13,
            name: "all-category-view",
            label: "All category view",
            group_name: "admin-category",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 14,
            name: "create-new-category",
            label: "Category create",
            group_name: "admin-category",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 15,
            name: "update-a-category",
            label: "Category update",
            group_name: "admin-category",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 16,
            name: "all-customer-view",
            label: "All customer view",
            group_name: "admin-customer",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 17,
            name: "all-master-gateway-view",
            label: "All master gateway view",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 18,
            name: "create-master-gateway",
            label: "Create master gateway",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 19,
            name: "update-master-gateway",
            label: "Update master gateway",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 20,
            name: "delete-master-gateway",
            label: "Delete master gateway",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 21,
            name: "view-all-bank",
            label: "View all bank",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 22,
            name: "add-bank",
            label: "Add bank",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 23,
            name: "update-bank",
            label: "Update bank",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 24,
            name: "delete-bank",
            label: "Delete bank",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 25,
            name: "view-all-permission",
            label: "All Permission view",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 26,
            name: "update-permission",
            label: "Update Permission",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 27,
            name: "admin_role_index",
            label: "All Roles list",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 28,
            name: "admin_role_store",
            label: "Create new role",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 29,
            name: "admin_role_show",
            label: "Role Detail",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 30,
            name: "admin_role_update",
            label: "Role Update",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 31,
            name: "admin_role_delete",
            label: "Role Delete",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 32,
            name: "admin_role_permissions_index",
            label: "View permission for a role",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 33,
            name: "admin_role_permissions_update",
            label: "Update permission for a role",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 34,
            name: "admin_update_user_role",
            label: "Update user role",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 35,
            name: "admin_employee_role_update",
            label: "Update admin employee role",
            group_name: "Role-Permission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 36,
            name: "view-wallet-balance",
            label: "View wallet balance",
            group_name: "admin-wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 37,
            name: "view-wallet-transaction",
            label: "View wallet transaction",
            group_name: "admin-wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 38,
            name: "view-cashout-request",
            label: "View cashout request",
            group_name: "admin-cashout",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 39,
            name: "approve-cashout-request",
            label: "Approve Cashout Request",
            group_name: "admin-cashout",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 40,
            name: "view-receivable-shipment-products",
            label: "View receivable products",
            group_name: "Product-Receive",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 41,
            name: "receive-shipment-products",
            label: "Receive shipment products",
            group_name: "Product-Receive",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 42,
            name: "attach-image-to-product",
            label: "Attach image to products",
            group_name: "Product-Receive",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 43,
            name: "invoice-unsettled-view",
            label: "Unsettled invoice view",
            group_name: "invoice",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 44,
            name: "invoice-all-view",
            label: "All invoice view",
            group_name: "invoice",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 45,
            name: "invoice-settlement",
            label: "Settle Invoice",
            group_name: "invoice",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 46,
            name: "invoice-detail-view",
            label: "Invoice detail view",
            group_name: "invoice",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 47,
            name: "all-bank-deposit-request",
            label: "All bank deposit request view",
            group_name: "invoice payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 48,
            name: "all-mobile-banking-request",
            label: "All mobile banking request view",
            group_name: "invoice payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 49,
            name: "approve-invoice-payment-request",
            label: "Approve invoice payment request",
            group_name: "invoice payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 50,
            name: "settle-overpaid-invoice",
            label: "Settle Overpaid invoice",
            group_name: "invoice payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 51,
            name: "view-ssl-commerz-transactions",
            label: "View SSL Commerz Transactions",
            group_name: "invoice payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 52,
            name: "all-delivery-request-view",
            label: "Delivery Request view",
            group_name: "delivery-request",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 53,
            name: "view-request-products",
            label: "Delivery Request Products View",
            group_name: "delivery-request",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 54,
            name: "update-delivery-request-status",
            label: "Delivery Request Status Update",
            group_name: "delivery-request",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 55,
            name: "admin_index",
            label: "admin_index",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 56,
            name: "admin_store",
            label: "admin_store",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 57,
            name: "admin_show",
            label: "admin_show",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 58,
            name: "admin_update",
            label: "admin_update",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 59,
            name: "admin_delete",
            label: "admin_delete",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 60,
            name: "admin_reset_password",
            label: "admin_reset_password",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 111,
            name: "view-email-templates",
            label: "View Email Templates",
            group_name: "email-template",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 112,
            name: "update-email-templates",
            label: "Update Email Templates",
            group_name: "email-template",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 113,
            name: "update-customer-site-information",
            label: "Update Customer Site Information",
            group_name: "email-template",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 114,
            name: "view_stores",
            label: "View all stores",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 115,
            name: "view_store",
            label: "View store",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 116,
            name: "create_store",
            label: "Create store",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 117,
            name: "update_store",
            label: "Update store",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 118,
            name: "delete_store",
            label: "Delete store",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 119,
            name: "buy_orders",
            label: "Show buy orders",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 120,
            name: "buy_order_create",
            label: "Create buy order",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 121,
            name: "agent_buy_order_update",
            label: "Update buy order after agent assign",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 122,
            name: "buy_order_delete",
            label: "Delete buy order",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 123,
            name: "buy_products",
            label: "Show buy products",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 124,
            name: "buy_product_status_update",
            label: "Update buy product status",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 125,
            name: "buy_product_issue",
            label: "Update buy product issue",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 126,
            name: "create_buy_product",
            label: "Create buy product",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 127,
            name: "view_stores_limit",
            label: "View limited stores",
            group_name: "stores",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 128,
            name: "change_customer_password",
            label: "Change customer password",
            group_name: "customer",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 129,
            name: "customer_login_token",
            label: "Customer login token",
            group_name: "customer",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 130,
            name: "customer_update",
            label: "Customer update",
            group_name: "customer",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 131,
            name: "change_order_handler",
            label: "Change order handler",
            group_name: "customer",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 132,
            name: "view_buy_invoices",
            label: "View buy invoices",
            group_name: "buy_invoice",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 133,
            name: "view_wallet_report",
            label: "View wallet report",
            group_name: "report",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 134,
            name: "refund-list-view",
            label: "refund-list-view",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 135,
            name: "refund-request-create",
            label: "refund-request-create",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 136,
            name: "refund-request-cancel",
            label: "refund-request-cancel",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 137,
            name: "refund-manage",
            label: "refund-manage",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 138,
            name: "manage-thread",
            label: "manage-thread",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 139,
            name: "process-subdispute",
            label: "process-subdispute",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 140,
            name: "release-dispute",
            label: "release-dispute",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 141,
            name: "buy_product_change_shipping",
            label: "Change buy product shipping",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 142,
            name: "send-bulk-email",
            label: "send-bulk-email",
            group_name: "Email",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 143,
            name: "view-email-log-list",
            label: "view-email-log-list",
            group_name: "Email",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 144,
            name: "view-email-log-detail",
            label: "view-email-log-detail",
            group_name: "Email",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 145,
            name: "view_offer_products",
            label: "View offer products",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 146,
            name: "view_without_offer_products",
            label: "View without offer products",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 147,
            name: "delete_inventory_product",
            label: "Delete inventory product",
            group_name: "inventory_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 148,
            name: "view_any_campaign",
            label: "View any campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 149,
            name: "edit_any_campaign",
            label: "Edit any campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 150,
            name: "create_campaign",
            label: "Create campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 151,
            name: "assign_products_campaign",
            label: "Assign products to campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 152,
            name: "remove_products_campaign",
            label: "Remove products from campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 153,
            name: "view_buy_ship_requests",
            label: "View buy ship requests",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 154,
            name: "delete_campaign",
            label: "Delete campaign",
            group_name: "inventory_campaign",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 155,
            name: "buy-order-item-profit-report",
            label: "buy-order-item-profit-report",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 156,
            name: "moveon-drop-customer-create",
            label: "moveon-drop-customer-create",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 157,
            name: "moveon-drop-customer-update",
            label: "moveon-drop-customer-update",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 158,
            name: "recharge-request-list-view",
            label: "recharge-request-list-view",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 159,
            name: "recharge-request-detail-view",
            label: "recharge-request-detail-view",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 160,
            name: "recharge-request-create",
            label: "recharge-request-create",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 161,
            name: "recharge-request-approve",
            label: "recharge-request-approve",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 162,
            name: "recharge-request-reject",
            label: "recharge-request-reject",
            group_name: "admin",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 163,
            name: "view-service-quotas",
            label: "view-service-quotas",
            group_name: "service_quota",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 164,
            name: "view-service-quota-detail",
            label: "view-service-quota-detail",
            group_name: "service_quota",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 165,
            name: "create-service-quota",
            label: "create-service-quota",
            group_name: "service_quota",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 166,
            name: "update-service-quota",
            label: "update-service-quota",
            group_name: "service_quota",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 167,
            name: "delete-service-quota",
            label: "delete-service-quota",
            group_name: "service_quota",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 168,
            name: "view-all-transactions",
            label: "view-all-transactions",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 169,
            name: "view-transaction-detail",
            label: "view-transaction-detail",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 170,
            name: "create-transaction",
            label: "create-transaction",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 171,
            name: "update-transaction",
            label: "update-transaction",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 172,
            name: "approve-transaction",
            label: "approve-transaction",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 173,
            name: "reject-transaction",
            label: "reject-transaction",
            group_name: "transaction",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 174,
            name: "set_product_current_fx",
            label: "Set product to current fx",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 175,
            name: "copy_buy_order",
            label: "Copy buy order",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 176,
            name: "buy_order_update",
            label: "Update buy order",
            group_name: "buy_order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 177,
            name: "buy_products_issue_views",
            label: "Show buy products issues",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 178,
            name: "buy_product_issue_update",
            label: "Update buy product issue",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 179,
            name: "buy_product_issue_delete",
            label: "Delete buy product issue",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 180,
            name: "buy_product_issue_details",
            label: "Details of buy product issue",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 181,
            name: "buy_product_issue_status",
            label: "Update buy product status",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 182,
            name: "buy_product_process_issue",
            label: "Process issue buy product",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 183,
            name: "ship-for-me-product-force-price-update",
            label: "ShipForMe Force Shipment Price Update",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 184,
            name: "ship-for-me-product-price-update",
            label: "ShipForMe Shipment Price Update",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 185,
            name: "issue_price_increased",
            label: "Issue Price Increased",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 186,
            name: "issue_qty_mismatch",
            label: "Issue Quantity Increased",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 187,
            name: "issue_price_increased_qty_mismatch",
            label: "Issue Price Increased & Quantity Update",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 188,
            name: "issue_sku_mismatch",
            label: "Issue SKU Mismatch",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 189,
            name: "issue_high_tax_possibility",
            label: "Issue High Tax Possibility",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 190,
            name: "issue_illegal_item",
            label: "Issue Illegal Item",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 191,
            name: "issue_stock_out",
            label: "Issue Stock Out",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 192,
            name: "issue_general_terms_notification",
            label: "Issue General Terms Notification",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 193,
            name: "issue_no_risk_products",
            label: "Issue No Risk Products",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 194,
            name: "issue_local_delivery_charges",
            label: "Issue Local Delivery Charges",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 195,
            name: "issue_remove_local_delivery_charges",
            label: "Issue Remove Local Delivery Charges",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 196,
            name: "buy_product_update",
            label: "Update buy product",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 197,
            name: "buy_product_update_of_all_status",
            label: "Update buy product of All status by Special user",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 198,
            name: "assign-handler-for-orders",
            label: "Order handler assign",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 199,
            name: "ship-for-me-createIfNotExist-shipment-request-for-customers",
            label: "createIfNotExist hipment request for customers",
            group_name: "ship-for-me-order",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 200,
            name: "createIfNotExist-new-agent",
            label: "Agent createIfNotExist",
            group_name: "admin-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 201,
            name: "createIfNotExist-new-category",
            label: "Category createIfNotExist",
            group_name: "admin-category",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 202,
            name: "createIfNotExist-master-gateway",
            label: "createIfNotExist master gateway",
            group_name: "admin-payment",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 208,
            name: "assign_handler_for_orders",
            label: "Order handler assign",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 209,
            name: "buy_ship_customer_note_edit",
            label: "Force customer note edit",
            group_name: "buy_product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 210,
            name: "admin-commission-view",
            label: "Admin commission view",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 211,
            name: "admin-commission-add",
            label: "Admin commission add",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 214,
            name: "admin-exchange-group-view",
            label: "Admin exchange group view",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 215,
            name: "admin-exchange-group-add",
            label: "Admin exchange group add",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 216,
            name: "admin-exchange-group-update",
            label: "Admin exchange group update",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 217,
            name: "admin-exchange-group-delete",
            label: "Admin exchange group delete",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 222,
            name: "admin-country-view",
            label: "Admin country view",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 223,
            name: "admin-country-add",
            label: "Admin country add",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 224,
            name: "admin-country-update",
            label: "Admin country update",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 225,
            name: "admin-language-view",
            label: "Admin language view",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 226,
            name: "admin-language-add",
            label: "Admin language add",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 227,
            name: "admin-language-update",
            label: "Admin language update",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 228,
            name: "admin-currency-view",
            label: "Admin currency view",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 229,
            name: "admin-currency-add",
            label: "Admin currency add",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 230,
            name: "admin-currency-update",
            label: "Admin currency update",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 231,
            name: "admin-region-view",
            label: "Admin region view",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 232,
            name: "admin-region-add",
            label: "Admin region add",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 233,
            name: "admin-region-update",
            label: "Admin region update",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 239,
            name: "admin-address-view",
            label: "Admin address view",
            group_name: "address",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 240,
            name: "admin-address-add",
            label: "Admin address add",
            group_name: "address",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 241,
            name: "admin-address-update",
            label: "Admin address update",
            group_name: "address",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 242,
            name: "admin-address-delete",
            label: "Admin address delete",
            group_name: "address",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 243,
            name: "admin-courier-gateway-view",
            label: "Admin courier gateway view",
            group_name: "courier",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 244,
            name: "admin-courier-gateway-create",
            label: "Admin courier gateway create",
            group_name: "courier",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 245,
            name: "admin-courier-gateway-update",
            label: "Admin courier gateway update",
            group_name: "courier",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 246,
            name: "admin-courier-gateway-delete",
            label: "Admin courier gateway delete",
            group_name: "courier",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 247,
            name: "admin-destination-warehouse-view",
            label: "Admin destination warehouse view",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 248,
            name: "admin-destination-warehouse-add",
            label: "Admin destination warehouse add",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 249,
            name: "admin-destination-warehouse-update",
            label: "Admin destination warehouse update",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 250,
            name: "admin-place-order",
            label: "Admin place order",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 251,
            name: "admin-approve-shipment-product",
            label: "Admin approve shipment product",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 252,
            name: "admin-reject-shipment-product",
            label: "Admin reject shipment product",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 253,
            name: "admin-attach-tracking",
            label: "Admin attach tracking",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 254,
            name: "admin-package-add",
            label: "Admin package add",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 255,
            name: "admin-package-update",
            label: "Admin package update",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 256,
            name: "admin-package-delete",
            label: "Admin package delete",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 257,
            name: "admin-commission-copy",
            label: "Admin commission copy",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 258,
            name: "admin-commission-association-copy",
            label: "Admin commission association copy",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 259,
            name: "admin-category-commission-copy",
            label: "Admin category commission copy",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 260,
            name: "admin-category-commission-update",
            label: "Admin category commission update",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 261,
            name: "admin-category-commission-delete",
            label: "Admin category commission delete",
            group_name: "commission",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 265,
            name: "agent-company-admin-view",
            label: "Agent company admin view",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 266,
            name: "agent-warehouse-admin-view",
            label: "Agent warehouse admin view",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 267,
            name: "agent-warehouse-admin-update",
            label: "Agent warehouse admin update",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 268,
            name: "agent-category-price-read-admin-view",
            label: "Agent category price read admin view",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 269,
            name: "admin-change-agent-verification-status",
            label: "Admin change agent verification status",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 270,
            name: "admin-store-view",
            label: "Admin store view",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 271,
            name: "admin-store-add",
            label: "Admin store add",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 272,
            name: "admin-store-update",
            label: "Admin store update",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 273,
            name: "admin-store-delete",
            label: "Admin store delete",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 274,
            name: "admin-exchange-view",
            label: "Admin exchange view",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 275,
            name: "admin-exchange-add",
            label: "Admin exchange add",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 276,
            name: "admin-exchange-update",
            label: "Admin exchange update",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 277,
            name: "admin-exchange-delete",
            label: "Admin exchange delete",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 278,
            name: "admin-payout-gateway-view",
            label: "Admin payout gateway view",
            group_name: "wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 279,
            name: "admin-payout-gateway-add",
            label: "Admin payout gateway add",
            group_name: "wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 280,
            name: "admin-payout-gateway-update",
            label: "Admin payout gateway update",
            group_name: "wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 281,
            name: "admin-payout-gateway-delete",
            label: "Admin payout gateway delete",
            group_name: "wallet",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 282,
            name: "admin-shipping-category-view",
            label: "Admin shipping category view",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 283,
            name: "admin-shipping-category-update",
            label: "Admin shipping category update",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 284,
            name: "admin-shipping-category-create",
            label: "Admin shipping category create",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 285,
            name: "admin-base-shipping-category-view",
            label: "Admin base shipping category view",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 286,
            name: "admin-base-shipping-category-create",
            label: "Admin base shipping category create",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 287,
            name: "admin-base-shipping-category-update",
            label: "Admin base shipping category update",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 288,
            name: "admin-auction-rule-view",
            label: "Admin auction rule view",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 289,
            name: "admin-auction-rule-create",
            label: "Admin auction rule create",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 290,
            name: "admin-auction-rule-update",
            label: "Admin auction rule update",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 291,
            name: "admin-shipping-country-view",
            label: "Admin shipping country view",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 292,
            name: "admin-shipping-country-create",
            label: "Admin shipping country create",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 293,
            name: "admin-shipping-country-update",
            label: "Admin shipping country update",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 296,
            name: "admin-destination-warehouse-member-view",
            label: "Admin destination warehouse member view",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 297,
            name: "admin-destination-warehouse-member-update",
            label: "Admin destination warehouse member update",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 298,
            name: "admin-destination-warehouse-member-delete",
            label: "Admin destination warehouse member delete",
            group_name: "destination",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 299,
            name: "payment-gateway-view",
            label: "Admin payment gateway view",
            group_name: "payment-gateway",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 300,
            name: "payment-gateway-add",
            label: "Admin payment gateway add",
            group_name: "payment-gateway",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 301,
            name: "payment-gateway-update",
            label: "Admin payment gateway update",
            group_name: "payment-gateway",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 303,
            name: "admin-view-shipment-product",
            label: "Admin view shipment products",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 306,
            name: "admin-view",
            label: "Admin shipment product bid view",
            group_name: "shipment-product-bid",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 307,
            name: "admin-create",
            label: "Admin shipment product bid create",
            group_name: "shipment-product-bid",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 308,
            name: "admin-attach-participants",
            label: "Admin shipment product bid attach participants",
            group_name: "shipment-product-bid",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 309,
            name: "admin-abandon-bid",
            label: "Admin shipment product abandon bid",
            group_name: "shipment-product-bid",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 310,
            name: "admin-choose-winner",
            label: "Admin choose shipment product bid winner",
            group_name: "shipment-product-bid",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 315,
            name: "admin-assign-default",
            label: "Admin shipment product assign agent",
            group_name: "shipment-product-assign-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 316,
            name: "admin-assign-with-price",
            label: "Admin shipment product assign agent with price",
            group_name: "shipment-product-assign-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 319,
            name: "admin-country-sync",
            label: "Admin country sync",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 320,
            name: "admin-change-shipping-country",
            label: "Admin change shipping country",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 321,
            name: "admin-change-destination-warehouse",
            label: "Admin change destination warehouse",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 322,
            name: "admin-change-shipping-category",
            label: "Admin change shipping category",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 323,
            name: "admin-change-shipping-mode",
            label: "Admin change shipping mode",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 324,
            name: "admin-change-shipping-type",
            label: "Admin change shipping type",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 325,
            name: "admin-attach-images",
            label: "Admin attach images",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 326,
            name: "admin-view-shipment-product-by-destination-warehouse",
            label: "Admin view shipment products by destination warehouse",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 327,
            name: "admin-attach-images-to-product-and-package",
            label: "Admin attach image to shipment product and packages",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 328,
            name: "admin-package-update-measurement",
            label: "Admin update measurement",
            group_name: "shipment-product-package",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 329,
            name: "admin-package-update-carton",
            label: "Admin update carton",
            group_name: "shipment-product-package",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 333,
            name: "temp-destination-admin",
            label: "Temporary destination admin",
            group_name: "destination",
            guard_name: "api",
            access: "destination-admin"
          },
          {
            id: 335,
            name: "admin-language-sync",
            label: "Admin language sync",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 336,
            name: "admin-currency-sync",
            label: "Admin currency sync",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 337,
            name: "admin-region-sync",
            label: "Admin region sync",
            group_name: "core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 338,
            name: "admin-permission-view",
            label: "Admin permission view",
            group_name: "acl",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 339,
            name: "admin-role-view",
            label: "Admin role view",
            group_name: "acl",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 340,
            name: "admin-role-create",
            label: "Admin role create",
            group_name: "acl",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 341,
            name: "admin-role-update",
            label: "Admin role update",
            group_name: "acl",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 342,
            name: "admin-address-sync",
            label: "Admin address sync",
            group_name: "address",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 343,
            name: "destination-admin-courier-option-view",
            label: "Admin courier option view",
            group_name: "courier-option",
            guard_name: "api",
            access: "destination-admin"
          },
          {
            id: 344,
            name: "destination-admin-courier-option-create",
            label: "Admin courier option create",
            group_name: "courier-option",
            guard_name: "api",
            access: "destination-admin"
          },
          {
            id: 345,
            name: "destination-admin-courier-option-update",
            label: "Admin courier option update",
            group_name: "courier-option",
            guard_name: "api",
            access: "destination-admin"
          },
          {
            id: 346,
            name: "admin-shipping-category-sync",
            label: "Admin shipping category sync",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 347,
            name: "admin-base-shipping-category-sync",
            label: "Admin base shipping category sync",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 348,
            name: "admin-auction-rule-sync",
            label: "Admin auction rule sync",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 349,
            name: "admin-shipping-country-sync",
            label: "Admin shipping country sync",
            group_name: "shipping-core",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 350,
            name: "admin-receive-by-moveon",
            label: "Admin receive shipment product",
            group_name: "shipment-product",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 354,
            name: "admin-unlisted-package-view",
            label: "Admin unlisted package view",
            group_name: "unlisted-package",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 355,
            name: "admin-unlisted-package-sync",
            label: "Admin unlisted package sync",
            group_name: "unlisted-package",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 356,
            name: "agent-company-admin-sync",
            label: "Agent company admin sync",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 357,
            name: "agent-warehouse-admin-sync",
            label: "Agent warehouse admin sync",
            group_name: "shipping-agent",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 358,
            name: "admin-store-sync",
            label: "Admin store sync",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 359,
            name: "admin-exchange-sync",
            label: "Admin exchange sync",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 360,
            name: "admin-exchange-group-sync",
            label: "Admin exchange group sync",
            group_name: "store",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 361,
            name: "admin-customer-user-view",
            label: "Admin customer user view",
            group_name: "customer-user",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 362,
            name: "admin-customer-user-add",
            label: "Admin customer user add",
            group_name: "customer-user",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 363,
            name: "admin-customer-user-update",
            label: "Admin customer user update",
            group_name: "customer-user",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 364,
            name: "admin-user-view",
            label: "Admin user view",
            group_name: "admin-user",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 365,
            name: "admin-user-add",
            label: "Admin user add",
            group_name: "admin-user",
            guard_name: "api",
            access: "admin"
          },
          {
            id: 366,
            name: "admin-user-update",
            label: "Admin user update",
            group_name: "admin-user",
            guard_name: "api",
            access: "admin"
          }
        ]
      }
    ]
  }

  const userRolePermissionsData = userRoleData.roles[0].permissions.map((p) => p.name);

  export const getPermissionList = (): string[] => {
    // Combine the arrays
    const combinedPermissions: string[] = [...permissionData, ...userRolePermissionsData];
  
    // Use a Set to remove duplicates and convert it back to an array
    const uniquePermissions: string[] = Array.from(new Set(combinedPermissions));
  
    return uniquePermissions;
  };
  