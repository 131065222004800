import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  Row,
  Skeleton,
  Space,
  Tag,
  notification,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  BorderOuterOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
  DownOutlined,
  HddFilled,
  HomeTwoTone,
  RadiusBottomleftOutlined,
  RadiusUpleftOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";

import {
  useGetShipmentProduct,
  useRejectedShippingProduct,
} from "lib/core-react/hooks/private/useShipping";
import { getError } from "lib/core-react/hooks/utils/errors";
import { shipmentProductAtom } from "lib/core-react/store/store";
import {
  ShipmentProductCountryModel,
  ShipmentProductDestinationWarehouseModel,
  ShipmentProductModel,
} from "models/shipmentProductCollectionModel";
import { formatDateTime } from "utils/helpers";

import useDataFilters from "hooks/useDataFilters";
import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";

import {
  AddonService,
  AgentAssociation,
  BidList,
  Carton,
  CustomerAssociation,
  CustomerOrderHandler,
  Delivery,
  Invoice,
  MoveonAssociation,
  Product,
  SelectWarehouseModal,
  ShipmentInstruction,
  Shipping,
  SourceDestination,
  TimelineTracking,
} from "./components";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "types";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "consts/permission-enum/purchase-enum";
import { ADMIN_DESTINATION_PERMISSION_ENUM } from "consts/permission-enum/destination-enum";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "consts/permission-enum/admin-shipping-enum";

export const EditShipmentProduct = () => {
  const params = useParams();
  const { productId } = params;
  const { getShipmentProduct } = useGetShipmentProduct();
  const [{ data: dataShipmentProduct, error: errorShipmentProduct, refetch, isLoading: isProductDataLoading }] =
    useAtom(shipmentProductAtom);

  const ShipmentProductData =
    dataShipmentProduct && new ShipmentProductModel(dataShipmentProduct);

  const { rejectedShippingProduct, isLoading: isRejectedLoading } =
    useRejectedShippingProduct();

  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [rejectProductId, setRejectProductId] = useState<number | undefined>(
    undefined,
  );
  const [showAgentAssignModal, setShowAgentAssignModal] =
    useState<boolean>(false);
  const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
    ICategoryPriceDetails | undefined
  >(undefined);

  // Bid list
  const [, setOpenBidModal] = useState<boolean>(false);
  const [selectedBidProduct, setSelectedBidProduct] = useState<
    ShipmentProductModel | undefined
  >(undefined);
  const showBidModalHandler = (product: ShipmentProductModel) => {
    setSelectedBidProduct(product);
    setOpenBidModal(true);
  };

  // Change Shipping Country
  const [changeShippingCountryDetails, setChangeShippingCountryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingShippingCountry: ShipmentProductCountryModel | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingShippingCountry: undefined,
    });

  const changeShippingCountryHandler = (
    ids: number[],
    shippingCountry: ShipmentProductCountryModel | undefined,
  ) => {
    setChangeShippingCountryDetails({
      existingShippingCountry: shippingCountry,
      isOpen: true,
      productIds: ids,
    });
  };

  // Change Destination warehouse
  const [
    changeDestinationWarehouseDetails,
    setChangeDestinationWarehouseDetails,
  ] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingWarehouse: ShipmentProductDestinationWarehouseModel | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingWarehouse: undefined,
  });

  const changeDestinationWarehouseHandler = (
    ids: number[],
    warehouse: ShipmentProductDestinationWarehouseModel | undefined,
  ) => {
    setChangeDestinationWarehouseDetails({
      existingWarehouse: warehouse,
      isOpen: true,
      productIds: ids,
    });
  };

  // Change Shipping category
  const [changeShippingCategoryDetails, setChangeShippingCategoryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingId: string | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingId: undefined,
    });

  const changeShippingCategoryHandler = (
    ids: number[],
    categoryId: string | undefined,
  ) => {
    setChangeShippingCategoryDetails({
      existingId: categoryId,
      isOpen: true,
      productIds: ids,
    });
  };

  // Change Shipping mode
  const [changeShippingModeDetails, setChangeShippingModeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingModesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingModeHandler = (
    ids: number[],
    shippingMode: ShippingModesEnums | undefined,
  ) => {
    setChangeShippingModeDetails({
      existingData: shippingMode,
      isOpen: true,
      productIds: ids,
    });
  };

  // Change Shipping type
  const [changeShippingTypeDetails, setChangeShippingTypeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingTypesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingTypeHandler = (
    ids: number[],
    shippingType: ShippingTypesEnums | undefined,
  ) => {
    setChangeShippingTypeDetails({
      existingData: shippingType,
      isOpen: true,
      productIds: ids,
    });
  };

  // Reject Handler
  const showRejectedModalHandler = (productId: number) => {
    setRejectProductId(productId);
    setOpenRejectModal(true);
  };

  const rejectedModalOkHandler = async () => {
    if (rejectProductId) {
      try {
        const data = await rejectedShippingProduct(rejectProductId);
        if (data) {
          notification["success"]({
            message: data.message,
          });
          setOpenRejectModal(false);
        }
      } catch (error: any) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const agentAssignHandler = () => {
    if (ShipmentProductData) {
      const categoryDetails: ICategoryPriceDetails = {
        user_id: ShipmentProductData.getUserId(),
        region: ShipmentProductData.getRegionCode(),
        shipping_category_id: ShipmentProductData.getShippingCategoryId(),
        destination_warehouse_id:
          ShipmentProductData.getDestinationWarehouseId(),
        shipping_type: ShipmentProductData.getShippingType(),
        shipping_mode: ShipmentProductData.getShippingMode(),
        shipping_country_id: ShipmentProductData.getShippingCountryId(),
      };
      setCategoryPriceDetailsInfo(categoryDetails);
      setShowAgentAssignModal(true);
    }
  };

  const handleMenuClick = () => {};
  const items: ExtendedMenuItemType[] = [
    {
      permission:ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_UPDATE,
      icon: <CheckCircleTwoTone />,
      label: "Approve and Assign Agent",
      key: "approve-and-assign-agent",
      onClick: () => agentAssignHandler(),
    },
    {
      permission:ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
      icon: <CloseCircleTwoTone />,
      label: "Reject product",
      key: "reject-product",
      onClick: () =>
        showRejectedModalHandler(ShipmentProductData!.getId()),
    },
    {
      permission:ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
      icon: <UnorderedListOutlined />,
      label: "Bid list",
      key: "bid-list",
      onClick: () => showBidModalHandler(ShipmentProductData!),
    },
    {
      permission: ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
      icon: <VerticalLeftOutlined />,
      label: "Change source country",
      key: "change-source-country",
      onClick: () =>
        changeShippingCountryHandler(
          [ShipmentProductData!.getId()],
          ShipmentProductData!.getShippingCountry(),
        ),
    },
    {
      permission:ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_ADD,
      icon: <HomeTwoTone />,
      label: "Change destination warehouse",
      key: "change-destination-warehouse",
      onClick: () =>
        changeDestinationWarehouseHandler(
          [ShipmentProductData!.getId()],
          ShipmentProductData ? ShipmentProductData.getDestinationWarehouse() : undefined,
        ),
    },
    {
      permission:ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
      icon: <BorderOuterOutlined />,
      label: "Change shipping category",
      key: "change-shipping-category",
      onClick: () =>
        changeShippingCategoryHandler(
          [ShipmentProductData!.getId()],
          ShipmentProductData ? ShipmentProductData.getShippingCategoryId() : undefined,
        ),
    },
    {
      permission:ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
      icon: <RadiusBottomleftOutlined />,
      label: "Change shipping type",
      key: "change-shipping-type",
      onClick: () =>
        changeShippingTypeHandler(
          [ShipmentProductData!.getId()],
          ShipmentProductData!.getShippingType(),
        ),
    },
    {
      permission:ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
      icon: <RadiusUpleftOutlined />,
      label: "Change shipping mode",
      key: "change-shipping-mode",
      onClick: () =>
        changeShippingModeHandler(
          [ShipmentProductData!.getId()],
          ShipmentProductData!.getShippingMode(),
        ),
    },
  ];

  const menuProps = {
    items: items.filter((x) =>
      checkActionPermission(x.permission, x, null),
    ),
    onClick: handleMenuClick,
  };

  const { isFirstCall, isFetched } = useDataFilters();

  useEffect(() => {
    if (!isFetched && isFirstCall && productId) {
      getShipmentProduct(parseInt(productId));
    }
  }, [isFirstCall, productId, isFetched]);

  useEffect(() => {
    if (refetch && productId) {
      getShipmentProduct(parseInt(productId));
    }

  }, [refetch]);

  const isLoading =  isProductDataLoading

  return (
    <PageHeader
      style={{ marginTop: "10px" }}
      ghost={false}
      title={
        ShipmentProductData ? `#${ShipmentProductData?.getOrder().getId()}` : ""
      }
      subTitle={
        ShipmentProductData ? (
          <Space style={{ textTransform: "lowercase" }}>
            <Tag
              icon={
                ShipmentProductData?.getOrder().getPaymentStatus() ===
                  "invoice-unpaid" ||
                ShipmentProductData?.getOrder().getPaymentStatus() ===
                  "pending" ? (
                  <SyncOutlined spin />
                ) : (
                  <CheckCircleOutlined />
                )
              }
              color={
                ShipmentProductData?.getOrder().getPaymentStatus() ===
                  "invoice-unpaid" ||
                ShipmentProductData?.getOrder().getPaymentStatus() === "pending"
                  ? "processing"
                  : "success"
              }
            >
              invoice{" "}
              {ShipmentProductData?.getOrder()
                .getPaymentStatus()
                .replaceAll("-", " ")}
            </Tag>
            <Tag
              icon={
                ShipmentProductData?.getOrder().getStatus() === "pending" ? (
                  <SyncOutlined spin />
                ) : (
                  <CheckCircleOutlined />
                )
              }
              color={
                ShipmentProductData?.getOrder().getStatus() === "pending"
                  ? "processing"
                  : "success"
              }
            >
              order {ShipmentProductData?.getOrder().getStatus()}
            </Tag>
            <Tag
              icon={
                ShipmentProductData?.getStatus() === "shipment-pending" ? (
                  <SyncOutlined spin />
                ) : (
                  <CheckCircleOutlined />
                )
              }
              color={
                ShipmentProductData?.getStatus() === "shipment-pending"
                  ? "processing"
                  : "success"
              }
            >
              shipment {ShipmentProductData?.getStatusLabel()}
            </Tag>
          </Space>
        ) : (
          ""
        )
      }
      onBack={() => window.history.back()}
      extra={[
        <Dropdown disabled={ShipmentProductData?.getStatus() !== "shipment-pending"} menu={menuProps} placement="bottom" key="extra">
          <Button icon={<HddFilled />}>
            More actions <DownOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      {!productId ? (
        <div>Product number missing!</div>
      ) : errorShipmentProduct ? (
        <div>Something went wrong: {getError(errorShipmentProduct)}</div>
      ) : (
        <Space direction="vertical" size="large">
          {ShipmentProductData ? (
            <Space size="small">
              <ClockCircleOutlined />
              {formatDateTime(ShipmentProductData?.getOrder().getCreatedAt())}
            </Space>
          ) : (
            <Skeleton paragraph={{ rows: 2 }} />
          )}
          <Row gutter={[24, 24]} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={12} lg={16}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Product
                    title="Product"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <SourceDestination
                    title="Source and Destinations"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                    changeDestinationWarehouseDetails={
                      changeDestinationWarehouseDetails
                    }
                    changeDestinationWarehouseHandler={
                      changeDestinationWarehouseHandler
                    }
                    changeShippingCountryDetails={changeShippingCountryDetails}
                    changeShippingCountryHandler={changeShippingCountryHandler}
                    setChangeDestinationWarehouseDetails={
                      setChangeDestinationWarehouseDetails
                    }
                    setChangeShippingCountryDetails={
                      setChangeShippingCountryDetails
                    }
                  />
                </Col>
                <Col span={24}>
                  <Carton
                    title="Carton"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <AddonService
                    title="Subscribed Value Added Services"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <Shipping
                    title="Shipping"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                    changeShippingCategoryDetails={
                      changeShippingCategoryDetails
                    }
                    changeShippingCategoryHandler={
                      changeShippingCategoryHandler
                    }
                    changeShippingModeDetails={changeShippingModeDetails}
                    changeShippingModeHandler={changeShippingModeHandler}
                    changeShippingTypeDetails={changeShippingTypeDetails}
                    changeShippingTypeHandler={changeShippingTypeHandler}
                    setChangeShippingCategoryDetails={
                      setChangeShippingCategoryDetails
                    }
                    setChangeShippingModeDetails={setChangeShippingModeDetails}
                    setChangeShippingTypeDetails={setChangeShippingTypeDetails}
                  />
                </Col>
                <Col span={24}>
                  <Invoice
                    title="Invoice"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <Delivery
                    title="Delivery"
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <TimelineTracking
                    ShipmentProductData={ShipmentProductData}
                    isLoading={isLoading}
                    title="Timeline"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <CustomerOrderHandler
                    title=""
                    isLoading={isLoading}
                    ShipmentProductData={ShipmentProductData}
                  />
                </Col>
                <Col span={24}>
                  <ShipmentInstruction
                    ShipmentProductData={ShipmentProductData}
                    isLoading={isLoading}
                    title="Shipment Instruction"
                  />
                </Col>
                <Col span={24}>
                  <CustomerAssociation
                    ShipmentProductData={ShipmentProductData}
                    isLoading={isLoading}
                    title="Customer Association"
                  />
                </Col>
                <Col span={24}>
                  <AgentAssociation
                    ShipmentProductData={ShipmentProductData}
                    isLoading={isLoading}
                    title="Agent Association"
                  />
                </Col>
                <Col span={24}>
                  <MoveonAssociation
                    ShipmentProductData={ShipmentProductData}
                    isLoading={isLoading}
                    title="Moveon Association"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      )}

      <Modal
        title="Are you sure?"
        open={openRejectModal}
        onOk={rejectedModalOkHandler}
        confirmLoading={isRejectedLoading}
        onCancel={() => setOpenRejectModal(false)}
      >
        Are you sure want to reject this product?
      </Modal>

      {categoryPriceDetailsInfo && ShipmentProductData && (
        <SelectWarehouseModal
          categoryPriceDetails={categoryPriceDetailsInfo}
          selectedProductIds={[ShipmentProductData.getId()]}
          selectedProducts={[ShipmentProductData]}
          onCancel={() => {
            setCategoryPriceDetailsInfo(undefined);
            setShowAgentAssignModal(false);
          }}
          open={showAgentAssignModal}
        />
      )}

      <Modal
        width="900px"
        open={Boolean(selectedBidProduct)}
        onCancel={() => setSelectedBidProduct(undefined)}
        destroyOnClose={true}
        footer={null}
        title="Bid List"
      >
        {selectedBidProduct && <BidList product={selectedBidProduct} />}
      </Modal>
    </PageHeader>
  );
};
