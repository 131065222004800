import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { atom, useAtom } from "jotai";
import {
    Button,
    Col,
    Divider,
    Flex,
    Modal,
    Row,
    Skeleton,
    Typography,
} from "antd";

import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";
import { useGetProductDetails } from "lib/core-react/hooks/private/useProduct";
import { productDetailAtom } from "lib/core-react/store/store";
import AppAnimate from "components/App/AppAnimate";
import AppCard from "components/App/AppCard";
import AppInfoView from "components/App/AppInfoView";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import checkActionPermission from "components/Authorized/CheckPermissions";
import { ROLE_PERMISSION_TEST_ENUM } from "consts/parmissionEnum";
import AddRawCampaignProductFormProvider, {
    isShowAddToCampaignModalAtom,
} from "pages/ProductManage/campagin/AddRawCampaignProductForm/AddRawCampaignProductFormProvider";

import ProductView from "../ProductView";
import ProductInfo from "./ProductInfo";
import { StyledProductDetails } from "../styles/productDetailCampaign.styled";
import { ProductDescription } from "pages/ProductManage/ProductDetail/ProductDescription/ProductDescription";
import AddRawProductToCampaignInput from "../AddRawCampaignProductForm/AddRawProductToCampaignInput";
import ProductImageSlide from "pages/ProductManage/ProductDetail/ProductImageSlide";
import ProductSpecification from "pages/ProductManage/ProductDetail/ProductView/ProductSpecification";
import CommonError from "components/Error/CommonError";

export const isRawProductEditAtom = atom<boolean>(false);

const ProductDetailCampaign = () => {
    const { id } = useParams();
    const { getProductDetails } = useGetProductDetails();
    const { width } = useWindowWidth();
    const divStyle = width >= 1280 ? { margin: "30px 30px 0px 30px" } : {};
    const location = useLocation();
    const operation = new URLSearchParams(location.search).get("operation");
    const productUrl = new URLSearchParams(location.search).get("productUrl");
    const productId = new URLSearchParams(location.search).get("productId");
    const user_id = new URLSearchParams(location.search).get("userId");
    const region = new URLSearchParams(location.search).get("region") || "BD";
    const campaignId = new URLSearchParams(location.search).get("campaignId") as string

    const [isShowAddToCampaignModal, setIsShowAddToCampaignModal] = useAtom(
        isShowAddToCampaignModalAtom,
    );

    useEffect(() => {
        if (id && id === "find") {
            getProductDetails(
                {
                    region: region,
                    apply_exchange: 1,
                    locale: "bn",
                    user_id,
                    url: productUrl,
                },
                `${productId || ""}`,
            );
        } else {
            if (productId) {
                getProductDetails(
                    {
                        region: "BD",
                        apply_exchange: 1,
                        locale: "bn",
                    },
                    productId,
                );
            }
        }
    }, [id, productId]);

    const [{ data, isLoading,error,unAuthorized }] = useAtom(productDetailAtom);

    const productDetail = data && new ProductDetailExtendedModel(data);

    const handleMenuClick = (action: "add-campaign-product") => {
        if (action === CampaignOperationEnum.AddCampaignProduct) {
            setIsShowAddToCampaignModal(true);
        }
    };


      // ------------------------------ui------------------------
    if (unAuthorized || error) {
      return <CommonError unAuthorized={unAuthorized} message={error}  />;
    }
    return (
        <AddRawCampaignProductFormProvider>
            <StyledProductDetails style={divStyle}>
                {isLoading ? (
                    <Skeleton active />
                ) : productDetail ? (
                    <AppAnimate animation="transition.slideUpIn" delay={200}>
                        <AppCard key="product_detail">
                            {/*------------------ Topbar part----------------- */}

        

                            {checkActionPermission(
                                ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
                                <>
                                    {operation ===
                                    CampaignOperationEnum.AddCampaignProduct ? (
                                        <div style={{ marginTop: 20 }}>
                                            <Flex
                                                style={{ width: "100%" }}
                                                gap="middle"
                                                justify="end"
                                            >
                                                <div
                                                    style={{
                                                        marginRight: "16px",
                                                    }}
                                                >
                                                    <Button
                                                        type="default"
                                                        htmlType="button"
                                                        disabled
                                                        style={{
                                                            marginRight: 12,
                                                        }}
                                                        onClick={() =>
                                                            window.location.reload()
                                                        }
                                                    >
                                                        Reset
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        htmlType="button"
                                                        onClick={() =>
                                                            handleMenuClick(
                                                                "add-campaign-product",
                                                            )
                                                        }
                                                    >
                                                        Add To Campaign
                                                    </Button>
                                                </div>
                                            </Flex>
                                            <Divider />
                                            {campaignId && region && (
                                                <Modal
                                                    title="Add Configuration"
                                                    open={
                                                        isShowAddToCampaignModal
                                                    }
                                                    onCancel={() =>
                                                        setIsShowAddToCampaignModal(
                                                            false,
                                                        )
                                                    }
                                                    getContainer={() =>
                                                        document.getElementById(
                                                            "addRawProductToCampaignForm",
                                                        ) || document.body
                                                    }
                                                    footer={null}
                                                >
                                                    <AddRawProductToCampaignInput />
                                                </Modal>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>,
                                null,
                            )}

                            <div>
                                <Row gutter={16}>
                                    <Col
                                        // style={{ border: "1px solid red" }}
                                        sm={24}
                                        lg={18}
                                    >
                                        {/*------------------ Basic Info----------------- */}
                                        <ProductInfo product={productDetail} />

                                        {/*------------------ Variations----------------- */}
                                        <ProductView product={productDetail} />
                                    </Col>
                                    <Col sm={24} lg={6}>
                                        <ProductImageSlide
                                            product={productDetail}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            {/*------------------ Specifications----------------- */}
                            <Divider
                                style={{ marginTop: 15, marginBottom: 15 }}
                            />
                            {operation ===
                            BuyOrderOperationEnum.PlaceBuyOrder ? (
                                ""
                            ) : (
                                <ProductSpecification
                                    productSpec={
                                        productDetail.getSpecifications() || []
                                    }
                                />
                            )}

                            {/*------------------ descriptions----------------- */}
                            <ProductDescription />
                        </AppCard>
                    </AppAnimate>
                ) : (
                    <Typography.Text type="danger">
                        Product not found!
                    </Typography.Text>
                )}

                <AppInfoView />
            </StyledProductDetails>
        </AddRawCampaignProductFormProvider>
    );
};

export default ProductDetailCampaign;
