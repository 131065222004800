import {
  IProductDetailExtended,
  IProductDetailResponseExtended,
  IProductMeasurementExtended,
  IProductGalleryExtended,
  IProductGalleryCollectionExtended,
  IProductMetaExtended,
  IProductMetaItemExtended,
  IProductPriceExtended,
  IProductPriceRangeExtended,
  IProductSpecificationExtended,
  IProductSpecificationCollectionExtended,
  IProductVariationExtended,
  IProductVideoExtended,
  IProductVideoCollectionExtended,
  IPropertyExtended,
  IPropertyAssociationCollectionExtended,
  IPropertyAssociationExtended,
  IPropertyCollectionExtended,
  IPropertyValueCollectionExtended,
  IPropertyValueExtended,
  ISkuCollectionExtended,
  ISkuExtended,
  ISkuMetaCollectionExtended,
  ISkuPriceExtended,
  IWholesalePriceCollectionExtended,
  IWholesalePriceExtended,
  IUnitWeight,
  IWidth,
  IHeight,
  ILength,
  IStore,
} from 'types/productDetailExtended'
import { ProductDetailExtendedCountryModel } from './countryCollectionModel'
import { IProductDetailExtendedCountry } from 'types/countryCollection'

export class ProductSpecificationModel {
  object: string
  id: string
  label: string
  value: string

  constructor(payload: IProductSpecificationExtended) {
    this.object = payload.object
    this.id = payload.id
    this.label = payload.label
    this.value = payload.value
  }

  getId() {
    return this.id
  }

  getLabel() {
    return this.label
  }

  getValue() {
    return this.value
  }
}

export class ProductSpecificationCollectionModel {
  object: string
  data: ProductSpecificationModel[]

  constructor(payload: IProductSpecificationCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.length
      ? payload.data.map((spec) => new ProductSpecificationModel(spec))
      : []
  }
}

export class ProductMetaItemModel {
  object: string
  key: string
  value: string

  constructor(data: IProductMetaItemExtended) {
    this.object = data.object
    this.key = data.key
    this.value = data.value
  }
}

export class ProductMetaModel {
  object: string
  data: ProductMetaItemModel[]

  constructor(data: IProductMetaExtended) {
    this.object = data.object
    this.data = data.data.length
      ? data.data.map((data) => new ProductMetaItemModel(data))
      : []
  }
}

export class ProductMeasurementModel {
  object: string
  unit_weight: IUnitWeight
  length?: ILength
  width: IWidth
  height: IHeight

  constructor(payload: IProductMeasurementExtended) {
    this.object = payload.object
    this.unit_weight = payload.unit_weight
    this.length = payload.length
    this.width = payload.width
    this.height = payload.height
  }

  getWeightValue() {
    return this.unit_weight.value
  }

  getWeightUnit() {
    return this.unit_weight.unit
  }

  getLengthValue() {
    return this.length?.value
  }

  getLengthUnit() {
    return this.length?.unit
  }

  getWidthValue() {
    return this.width.value
  }

  getWidthUnit() {
    return this.width.unit
  }

  getHeightValue() {
    return this.height.value
  }

  getHeightUnit() {
    return this.height.unit
  }
}

export class ProductGalleryModel {
  object: string
  id: string
  url: string
  thumb: string
  title: string

  constructor(payload: IProductGalleryExtended) {
    this.object = payload.object
    this.id = payload.id
    this.url = payload.url
    this.thumb = payload.thumb
    this.title = payload.title
  }

  getId() {
    return this.id
  }

  getUrl() {
    return this.url
  }

  getThumb() {
    return this.thumb
  }

  getTitle() {
    return this.title
  }
}

export class ProductGalleryCollectionModel {
  object: string
  data: ProductGalleryModel[]

  constructor(payload: IProductGalleryCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.map((gallery) => new ProductGalleryModel(gallery))
  }
}

export class ProductVideoModel {
  object: string
  id: string
  name?: string
  src: string

  constructor(payload: IProductVideoExtended) {
    this.object = payload.object
    this.id = payload.id
    this.name = payload.name
    this.src = payload.src
  }
}

export class ProductVideoCollectionModel {
  object: string
  data: ProductVideoModel[]

  constructor(payload: IProductVideoCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.map((video) => new ProductVideoModel(video))
  }
}

export class PropertyValueModelExtended {
  object: string
  id: string
  match_id?: string
  name: string
  color?: string
  title: string
  image: string
  thumb: string

  constructor(payload: IPropertyValueExtended) {
    this.object = payload.object
    this.id = payload.id
    this.match_id = payload.match_id ? payload.match_id : undefined
    this.name = payload.name
    this.color = payload.color
    this.title = payload.title
    this.image = payload.image
    this.thumb = payload.thumb
  }

  getId() {
    return this.id
  }

  getMatchId() {
    return this.match_id
  }

  getName() {
    return this.name
  }

  getColor() {
    return this.color
  }

  getTitle() {
    return this.title
  }

  getImage() {
    return this.image
  }

  getThumb() {
    return this.thumb
  }
}

export class PropertyValueCollectionModel {
  object: string
  data: PropertyValueModelExtended[]

  constructor(payload: IPropertyValueCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.map(
      (value) => new PropertyValueModelExtended(value),
    )
  }
  getData = () => this.data
}

export class PropertyModel {
  object: string
  id: string
  name: string
  actual_name: string
  values: PropertyValueCollectionModel

  constructor(payload: IPropertyExtended) {
    this.object = payload.object
    this.id = payload.id
    this.name = payload.name
    this.actual_name = payload.actual_name
    this.values = new PropertyValueCollectionModel(payload.values)
  }

  getId() {
    return this.id
  }

  getName() {
    return this.name
  }

  getValues() {
    return this.values
  }
}

export class PropertyCollectionModel {
  object: string
  data: PropertyModel[]

  constructor(payload: IPropertyCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.length
      ? payload.data.map((property) => new PropertyModel(property))
      : []
  }
  getData = () => this.data
  getPropertyNames = () => this.data.map((d) => d.getName())
}

export class SkuMetaCollectionModel {
  object: string
  data: ProductMetaItemModel[]

  constructor(payload: ISkuMetaCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.map((item) => new ProductMetaItemModel(item))
  }
}

export class PropertyAssociationExtendedModel {
  object: string
  id: string
  match_id?: string
  property_id: string
  property_name?: string
  property_original_name?: string
  property_value_id: string
  property_value_name?: string
  property_value_title?: string
  property_value_color?: string
  property_value_image?: string
  property_value_thumb?: string

  constructor(payload: IPropertyAssociationExtended) {
    this.object = payload.object
    this.id = payload.id
    this.match_id = payload?.match_id ? payload.match_id : undefined
    this.property_id = payload.property_id
    this.property_name = payload?.property_name
      ? payload.property_name
      : undefined
    this.property_original_name = payload?.property_original_name
      ? payload.property_original_name
      : undefined
    this.property_value_id = payload.property_value_id
    this.property_value_name = payload?.property_value_name
      ? payload.property_value_name
      : undefined
    this.property_value_title = payload?.property_value_title
      ? payload.property_value_title
      : undefined
    this.property_value_color = payload?.property_value_color
      ? payload.property_value_color
      : undefined
    this.property_value_image = payload?.property_value_image
      ? payload.property_value_image
      : undefined
    this.property_value_thumb = payload?.property_value_thumb
      ? payload.property_value_thumb
      : undefined
  }

  getId() {
    return this.id
  }

  getMatchId() {
    return this.match_id
  }

  getPropertyId() {
    return this.property_id
  }

  getPropertyName() {
    return this.property_name
  }

  getPropertyOriginalName() {
    return this.property_original_name
  }

  getPropertyValueId() {
    return this.property_value_id
  }

  getPropertyValueName() {
    return this.property_value_name
  }

  getPropertyValueTitle() {
    return this.property_value_title
  }

  getPropertyValueColor() {
    return this.property_value_color
  }

  getPropertyValueImage() {
    return this.property_value_image
  }

  getPropertyValueThumb() {
    return this.property_value_thumb
  }
}

export class PropertyAssociationCollectionModel {
  object: string
  data: PropertyAssociationExtendedModel[]

  constructor(payload: IPropertyAssociationCollectionExtended) {
    this.object = payload.object
    this.data = payload?.data?.length
      ? payload.data.map(
          (property) => new PropertyAssociationExtendedModel(property),
        )
      : []
  }

  getData() {
    return this.data
  }

  getCustomizedDataToAddShipmentProductCarton = () => {
    return this.data.map((d) => {
      return {
        property_id: d.getPropertyId(),
        property_name: d.getPropertyName() || '',
        property_original_name: d.getPropertyOriginalName(),
        property_value_id: d.getPropertyValueId(),
        property_value_name: d.getPropertyValueName() || '',
        property_value_color: d.getPropertyValueColor(),
        property_value_title: d.getPropertyValueTitle(),
        property_value_image: d.getPropertyValueImage(),
        property_value_thumb: d.getPropertyValueThumb(),
      }
    })
  }
}

export class SkuPriceModel {
  object: string
  currency: string
  discount?: string
  original: number
  preorder?: number
  wholesales: WholesalePriceCollectionModel
  original_raw: number
  discount_raw: number
  offer?: number
  
  constructor(payload: ISkuPriceExtended) {
    this.object = payload.object
    this.currency = payload.currency
    this.discount = payload.discount
    this.original = payload.original
    this.preorder = payload.preorder
    this.wholesales = new WholesalePriceCollectionModel(payload.wholesales)
    this.original_raw = payload.original_raw
    this.discount_raw = payload.discount_raw
    this.offer = payload.offer
  }

  getDiscount() {
    return this.discount
  }
  getVariationPrice() {
    return this.discount || this.original
  }
  getOriginal() {
    return this.original
  }
  getWholesales() {
    return this.wholesales
  }
  getOriginalRaw = () => this.original_raw
  getDiscountRaw = () => this.discount_raw
  getCurrency = () => this.currency
}

export class SkuModelExtended {
  object: string
  id: string
  sku_match_id?: string
  etag: string
  meta: SkuMetaCollectionModel
  property_associations: PropertyAssociationCollectionModel
  image?: string
  vendor_id?: string;
  stock: {
    object: string
    availability: string
    min?: number
    limit?: number
    available: number
  }
  price: SkuPriceModel

  constructor(payload: ISkuExtended) {
    this.object = payload.object
    this.id = payload.id
    this.sku_match_id = payload?.sku_match_id ? payload.sku_match_id : undefined
    this.etag = payload.etag
    this.meta = new SkuMetaCollectionModel(payload.meta)
    this.property_associations = new PropertyAssociationCollectionModel(
      payload.property_associations,
    )
    this.image = payload.image
    this.stock = payload.stock
    this.price = new SkuPriceModel(payload.price)
    this.vendor_id = payload.vendor_id
  }

  getSkuWholesalesData() {
    return this.price.wholesales.data
  }

  getPropertyAssociations() {
    return this.property_associations
  }
  getId() {
    return this.id
  }

  getStock() {
    return this.stock.available
  }

  getPrice() {
    return this.price
  }
}

export class SkuCollectionModel {
  object: string
  data: SkuModelExtended[]

  constructor(payload: ISkuCollectionExtended) {
    this.object = payload.object
    this.data = payload.data.map((sku) => new SkuModelExtended(sku))
  }
  getData = () => this.data
}

export class ProductVariationModel {
  object: string 
  properties: PropertyCollectionModel
  skus: SkuCollectionModel

  constructor(payload: IProductVariationExtended) {
    this.object = payload.object
    this.properties = new PropertyCollectionModel(payload.properties)
    this.skus = new SkuCollectionModel(payload.skus)
  }
  getSkus() {
    return this.skus
  }
  getProperties = () => this.properties
}

export class WholesalePriceModelExtended {
  object: string
  qty_from: number
  qty_to?: number
  original: number
  discount?: number
  hike?: any
  offer?: number
  original_raw?:number
  discount_raw?:number
  constructor(payload: IWholesalePriceExtended) {
    this.object = payload.object
    this.qty_from = payload.qty_from
    this.qty_to = payload?.qty_to ? payload.qty_to : undefined
    this.original = payload.original
    this.discount = payload.discount
    this.hike = payload.hike
    this.offer = payload.offer
    this.original_raw = payload.original_raw
    this.discount_raw = payload.discount_raw
  
  }

  getId() {
    return this.qty_from.toString()
  }

  getQtyFrom() {
    return this.qty_from
  }

  getQtyTo() {
    return this.qty_to
  }

  getOriginalPrice() {
    return this.original
  }
}

export class WholesalePriceCollectionModel {
  object: string
  data: WholesalePriceModelExtended[]

  constructor(payload: IWholesalePriceCollectionExtended) {
    this.object = payload.object
    this.data = payload?.data?.length
      ? payload.data.map((price) => new WholesalePriceModelExtended(price))
      : []
  }
  getData() {
    return this.data
  }
}

export class ProductPriceModel {
  object: string
  discount: IProductPriceRangeExtended
  original: IProductPriceRangeExtended
  wholesales: WholesalePriceCollectionModel

  constructor(payload: IProductPriceExtended) {
    this.object = payload.object
    this.discount = payload.discount
    this.original = payload.original
    this.wholesales = new WholesalePriceCollectionModel(payload.wholesales)
  }

  getDiscountMin() {
    return this.discount.min
  }
  getDiscountMax() {
    return this.discount.max
  }
  getOriginalMin() {
    return this.original.min
  }
  getOriginalMax() {
    return this.original.max
  }
  getWholesales() {
    return this.wholesales
  }
}


export class StoreModel {
  object: "Store"
  id: number
  name: string
  identifier: string
  currency_name: string
  currency_code: string
  currency_symbol: string
  constructor(payload : IStore) {
    this.object = payload.object
    this.id = payload.id
    this.name = payload.name
    this.identifier = payload.identifier
    this.currency_name = payload.currency_name
    this.currency_code = payload.currency_code
    this.currency_symbol = payload.currency_symbol
  }

  
  getCurrencySymbol() {
    return this.currency_symbol
  }




}

export class ProductDetailExtendedModel {
  object: string
  id: string
  etag: string
  resource_version: string
  title: string
  slug: string
  image: string
  description: string | null
  stock: number
  sales: number
  shipping_category?: {
    object: string
    id: string
    parent_id: string
    name: string
    slug: string
  }
  shop: {
    object: string
    id: string
    identifier: string
  }
  vendor: {
    object: string
    vid: string
    link: string
  }
  seller: {
    object: string
    id: string
    name: string
    link: string
  }


  store : StoreModel
  country: IProductDetailExtendedCountry
  measurement?: ProductMeasurementModel
  meta: ProductMetaModel
  gallery: ProductGalleryCollectionModel
  ratings: {
    object: string
    frequency: number[]
    count: number
    average: number
  }
  specifications: ProductSpecificationCollectionModel
  price: ProductPriceModel
  videos: ProductVideoCollectionModel
  variation: ProductVariationModel

  constructor(data: IProductDetailExtended) {
    this.object = data.object
    this.id = data.id
    this.etag = data.etag
    this.resource_version = data.resource_version
    this.title = data.title
    this.slug = data.slug
    this.image = data.image
    this.description = data.description
    this.stock = data.stock
    this.sales = data.sales
    this.shipping_category = data.shipping_category
    this.shop = data.shop
    this.vendor = data.vendor
    this.seller = data.seller
    this.country = new ProductDetailExtendedCountryModel(data.country)
    this.measurement = data.measurement
      ? new ProductMeasurementModel(data.measurement)
      : undefined
    this.meta = new ProductMetaModel(data.meta)
    this.gallery = new ProductGalleryCollectionModel(data.gallery)
    this.ratings = data.ratings
    this.specifications = new ProductSpecificationCollectionModel(
      data.specifications,
    )
    this.price = new ProductPriceModel(data.price)
    this.videos = new ProductVideoCollectionModel(data.videos)
    this.variation = new ProductVariationModel(data.variation)
    this.store = new StoreModel(data.store)
  }

  
  
  getStore() {
    return this.store
  }
  getStock() {
    return this.stock
  }

  getTitle() {
    return this.title
  }

  getPriceForShow() {
    
     const price =  this.getPrice().getDiscountMin()
                        ? this.getPrice().getDiscountMin()
                        : this.getPrice().getOriginalMin()
    
    return price
  }

  getSlug() {
    return this.slug
  }

  getImage() {
    return this.image
  }

  getId() {
    return this.id
  }

  getSales() {
    return this.sales
  }
  getTotalSales() {
    return this.sales
  }
  getTotalStock = () => this.stock

  getSellerId() {
    return this.seller.id
  }

  getSellerName() {
    return this.seller.name
  }

  getSellerLink() {
    return this.seller.link
  }

  getVendorLink = () => this.vendor.link

  getShippingCategoryId() {
    return this.shipping_category ? this.shipping_category.id : undefined
  }

  getCountry() {
    return this.country
  }

  getDimension() {
    return this.measurement
  }

  getGallery() {
    return this.gallery.data
  }

  getRatingsCount() {
    return this.ratings.count
  }

  getRatingsAverage() {
    return this.ratings.average
  }

  getDescription() {
    return this.description
  }

  getSpecifications() {
    return this.specifications.data
  }

  getPrice() {
    return this.price
  }

  getWholesalePriceRange() {
    return this.price.wholesales.data
  }

  getVariation() {
    return this.variation
  }

  getVariationPropertiesData() {
    return this.variation.properties.data
  }

  getVariationSkusData() {
    return this.variation.skus.data
  }
}

export class ProductDetailResponseModel {
  public message: string
  public data: ProductDetailExtendedModel

  constructor(data: IProductDetailResponseExtended) {
    this.message = data.message
    this.data = new ProductDetailExtendedModel(data.data)
  }

  getData() {
    return this.data
  }
}
