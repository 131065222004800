import {
  UserOutlined,
  SwitcherOutlined,
  BuildOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  CarOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  BorderHorizontalOutlined,
  MoneyCollectOutlined,
  ReadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { ROLE_PERMISSION_TEST_ENUM } from "./parmissionEnum";
import { ADMIN_ACL_PERMISSION_ENUM } from "./permission-enum/ACL-enum";
import { ADMIN_USER_PERMISSION_ENUM } from "./permission-enum/admin-user-enum";
import { ADMIN_REFUND_PERMISSION_ENUM } from "./permission-enum/refund-enum";
import { ADMIN_RFQ_PERMISSION_ENUM } from "./permission-enum/RFQ-enum";
import { ADMIN_STORE_PERMISSION_ENUM } from "./permission-enum/admin-store-enum";
import { ADMIN_ADDON_SERVICE_PERMISSION_ENUM } from "./permission-enum/addon-service-enum";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM, ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "./permission-enum/admin-shipping-enum";
import { ADMIN_CORE_PERMISSION_ENUM } from "./permission-enum/admin-core-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "./permission-enum/purchase-enum";
import { ADMIN_DESTINATION_PERMISSION_ENUM } from "./permission-enum/destination-enum";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "./permission-enum/payment-enum";
import { ADMIN_WALLET_PERMISSION_ENUM } from "./permission-enum/wallet-enum";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "./permission-enum/invoice-enum";

const menuItems = [
  {
    title: "Admin Manage",
    icon: <SwitcherOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: [
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_PERMISSION_VIEW,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_CREATE,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
    ],
    subMenus: [
      {
        path: "/admin-manage/users",
        title: "Admin User",
        permission: [
          ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_ADD,
          ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_UPDATE,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Role Permission",
    icon: <SwitcherOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: [
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_PERMISSION_VIEW,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_CREATE,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
      ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
    ],
    subMenus: [
      // {
      //   path: "/role-manage/overview",
      //   title: "Overview",
      //   permission: "admin_role_index",
      //   requirePermission: true,
      //   icon: null,
      // },
      {
        path: "/role-manage/role",
        title: "Roles",
        permission: [
          ADMIN_ACL_PERMISSION_ENUM.ADMIN_PERMISSION_VIEW,
          ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_CREATE,
          ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
          ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_UPDATE,
        ],
        requirePermission: true,
        icon: null,
      },

      // {
      //   path: "/role-manage/agent-permissions",
      //   title: "Agent permissions",
      //   permission: "view-all-permission",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  {
    title: "Customer Manage",
    icon: <UserOutlined />,
    hasSubMenu: true,
    permission: [],
    requirePermission: true,
    subMenus: [
      {
        path: "/customer/user",
        title: "User List",
        permission: [ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/customer/wishlists",
        title: "Wishlists",
        permission: [ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/customer/shipping-carts",
        title: "Shipping Carts",
        permission: [ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW],
        requirePermission: true,
        icon: null,
      },
      // {
      //   path: "/customer/regular",
      //   title: "Regular Customers (Old)",
      //   permission: "all-customer-view",
      //   requirePermission: true,
      //   icon: null,
      // },
      // {
      //   path: "/customer/drop-user",
      //   title: "Drop Customers (Old)",
      //   permission: "all-customer-view",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  // {
  //   title: "Discount Manage",
  //   icon: <UserOutlined />,
  //   hasSubMenu: true,
  //   permission:[],
  //   requirePermission: true,
  //   subMenus: [
  //     {
  //       path: "/discount-manage/discounts",
  //       title: "Discounts",
  //       permission: [],
  //       requirePermission: true,
  //       icon: null,
  //     },
  //     {
  //       path: "/discount-manage/discount-usage-histories",
  //       title: "Discount Usage History",
  //       permission: [],
  //       requirePermission: true,
  //       icon: null,
  //     },
  //   ],
  // },
  {
    title: "Refund Manage",
    icon: <RetweetOutlined />,
    hasSubMenu: true,
    permission: [
      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_APPROVE,
      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_DISPUTE_ASSIGN,
      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
    ],
    requirePermission: true,
    subMenus: [
      {
        path: "/refund-manage/refund",
        title: "Refund",
        permission: [
          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_APPROVE,
          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_DISPUTE_ASSIGN,
          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "RFQ Manage",
    icon: <RetweetOutlined />,
    hasSubMenu: true,
    permission: [
      ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_ADD,
      ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_APPROVE,
      ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_REJECT,
      ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_VIEW,
    ],
    requirePermission: true,
    subMenus: [
      {
        path: "/rfq-manage/requests/list",
        title: "RFQ List",
        permission: [
          ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_ADD,
          ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_APPROVE,
          ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_REJECT,
          ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },

  {
    title: "Store Manage",
    icon: <AppstoreOutlined />,
    hasSubMenu: true,
    permission: [
      ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_VIEW,
      ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_VIEW,
      ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
      ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_EXCHANGE_READ_VIEW,
    ],
    requirePermission: true,
    subMenus: [
      {
        path: "/store-manage/store-lists",
        title: "Store Lists",
        permission: [
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_ADD,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_SYNC,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_UPDATE,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/store-manage/store-exchanges",
        title: "Exchange Lists",
        permission: [
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_COPY,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_ADD,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_DELETE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_UPDATE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/store-manage/exchange-groups",
        title: "Exchange Groups",
        permission: [
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_ADD,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_DELETE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_UPDATE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_SYNC,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_DELETE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_DELETE,
          ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_USER_SYNC,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Addon Manage",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    permission: [ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_VIEW],
    requirePermission: true,
    subMenus: [
      {
        path: "/addon-services",
        title: "Addon Services",
        permission: [
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_VIEW,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_ADD,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_SYNC,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_UPDATE,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_UPDATE,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_ADD,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_DELETE,
          ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_PRICE_UPDATE,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Campaigns Manage",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    permission: ["view_any_campaign"],
    requirePermission: true,
    subMenus: [
      {
        path: "/campaigns",
        title: "Campaigns",
        permission: ["view_any_campaign"],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Agent Manage",
    icon: <BuildOutlined />,
    hasSubMenu: true,
    permission: [
      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_SHIPPING_POINT_ADMIN_VIEW,
      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_MEMBER_ADMIN_VIEW,
      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_VIEW,

      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_CATEGORY_PRICE_READ_ADMIN_VIEW,

      ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_VERIFICATION_ADMIN_VIEW,






    ],
    requirePermission: true,
    subMenus: [
      {
        path: "/agent-manage/agent-companies",
        title: "Agent Companies",
        permission: [
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/agent-manage/agent-warehouses",
        title: "Agent Warehouses",
        permission: [
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    icon: <GlobalOutlined />,
    title: "Region",
    requirePermission: true,
    permission: [ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_VIEW],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/region/currency",
        title: "Currency",
        requirePermission: true,
        permission: [
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_ADD,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_SYNC,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_UPDATE,
        ],
        icon: null,
      },
      {
        path: "/region/country",
        title: "Country",
        requirePermission: true,
        permission: [
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_VIEW,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_SYNC,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_ADD,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_UPDATE,
        ],
        icon: null,
      },
      {
        path: "/region/language",
        title: "Language",
        requirePermission: true,
        permission: [
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_ADD,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_SYNC,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_UPDATE,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_VIEW,
        ],
        icon: null,
      },
      {
        path: "/region/region",
        title: "Region",
        requirePermission: true,
        icon: null,
        permission: [
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_ADD,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_SYNC,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_UPDATE,
          ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_VIEW,
        ],
      },
    ],
  },
  // {
  //   icon: <EnvironmentOutlined />,
  //   title: "Courier",
  //   requirePermission: true,
  //   permission: [],
  //   hasSubMenu: true,
  //   subMenus: [
  //     {
  //       path: "/courier/courier-gateway",
  //       title: "Courier Gateway",
  //       requirePermission: true,
  //       permission: [],
  //       icon: null,
  //     },
  //   ],
  // },
  {
    icon: <EnvironmentOutlined />,
    title: "Product Manage",
    requirePermission: true,
    permission: [
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
    ],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/product-manage/products",
        title: "Inventory Products",
        requirePermission: true,
        permission: [
          ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
      // {
      //   path: "/product-manage/products-old",
      //   title: "Products Old",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  {
    icon: <CarOutlined />,
    title: "Shipping",
    requirePermission: true,
    permission: [
      ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_VIEW,
      ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_VIEW,
      ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_VIEW,
      ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_VIEW,
    ],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/shipping/shipping-country",
        title: "Shipping Country",
        requirePermission: true,
        permission: [
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_CREATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_SYNC,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_UPDATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_VIEW,
        ],
        icon: null,
      },
      {
        path: "/shipping/shipping-category",
        title: "Shipping Category",
        permission: [
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_CREATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_DELETE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_SYNC,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_UPDATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/base-shipping-category",
        title: "Base Shipping Category",
        permission: [
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_CREATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_SYNC,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_UPDATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/auction-rules",
        title: "Auction Rules",
        permission: [
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_CREATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_DELETE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_SYNC,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_UPDATE,
          ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_AUCTION_RULE_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/commission",
        permission: [
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ASSOCIATION_COPY,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_COPY,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_VIEW,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_COPY,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_DELETE,
          ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_CATEGORY_COMMISSION_UPDATE,
        ],
        title: "Commission",
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/shipment-orders",
        title: "Shipment Orders",
        requirePermission: true,
        permission: [
          ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
      {
        path: "/shipping/shipment-products",
        title: "Shipment Products",
        requirePermission: true,
        permission: [
          ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_PERMISSION_ENUM,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          // ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
    ],
  },
  {
    icon: <HomeOutlined />,
    title: "Destination",
    requirePermission: true,
    permission: [
      ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_VIEW,
      ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
    ],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/destination/destination-warehouse",
        title: "Destination Warehouse",
        permission: [
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_DELETE,
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_UPDATE,
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_ADD,
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_SYNC,
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_UPDATE,
          ADMIN_DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    icon: <BorderHorizontalOutlined />,
    title: "Buy & Ship",
    requirePermission: true,
    permission: [
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
      ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
    ],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/buy-and-ship-for-me/orders",
        title: "Buy Orders",
        requirePermission: true,
        permission: [
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
      {
        path: "/buy-and-ship-for-me/products",
        title: "Buy Products",
        requirePermission: true,
        permission: [
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
      {
        path: "/buy-and-ship-for-me/actions-needed",
        title: "Actions Needed",
        requirePermission: true,
        permission: [
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_ORDER_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_SET,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_AGENT_CATEGORY_PRICE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD_TO_ORDER,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_DELETE,
          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CHARGE_UPDATE,
        ],
        icon: null,
      },
    ],
  },
  // {
  //   icon: <BorderHorizontalOutlined />,
  //   title: "Buy & Ship For Me",
  //   requirePermission: true,
  //   hasSubMenu: true,
  //   subMenus: [
  //     {
  //       path: "/buy-and-ship/orders",
  //       title: "Orders",
  //       requirePermission: true,
  //       icon: null,
  //     },
  //     {
  //       path: "/buy-and-ship/products",
  //       title: "Products",
  //       requirePermission: true,
  //       icon: null,
  //     },
  //   ],
  // },
  {
    icon: <MoneyCollectOutlined />,
    title: "Payment Manage",
    requirePermission: true,
    hasSubMenu: true,
    permission: [
      PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
      PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
    ],
    subMenus: [
      {
        path: "/payment/gateway",
        title: "Gateway",
        requirePermission: true,
        permission: [
          PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_ADD,
          PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_UPDATE,
          PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
        ],
        icon: null,
      },
      {
        path: "/payment/payout-gateways",
        title: "Payout Gateway",
        permission: [
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_UPDATE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_ADD,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_APPROVE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_REJECT,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_PROCESS,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_TRANSACTION,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },

      {
        path: "/payment/transaction",
        permission: [
          PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_UPDATE,
          PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
        ],
        title: "All Transactions",
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Wallet Manage",
    icon: <MoneyCollectOutlined />,
    hasSubMenu: true,
    permission: [
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_UPDATE,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_ADD,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_APPROVE,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_REJECT,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_PROCESS,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_TRANSACTION,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
      ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
    ],
    requirePermission: true,
    subMenus: [
      {
        path: "/wallet-manage/overview",
        title: "Overview",
        permission: [
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_UPDATE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_ADD,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_APPROVE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_REJECT,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_PROCESS,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_TRANSACTION,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      // {
      //   path: "/wallet-manage/all-transactions",
      //   title: "All Transactions",
      //   permission: "view-wallet-balance",
      //   requirePermission: true,
      //   icon: null,
      // },
      // {
      //   path: "/wallet-manage/anomalies-transactions",
      //   title: "Anomalies Transactions",
      //   permission: "view-wallet-balance",
      //   requirePermission: true,
      //   icon: null,
      // },
      {
        path: "/wallet-manage/withdrawal-history",
        title: "Withdrawal History",
        permission: [
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_UPDATE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_ADD,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_APPROVE,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_REJECT,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_PROCESS,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_TRANSACTION,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
          ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Invoice",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: [
      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_TRANSACTION_VIEW,
      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE_TRANSACTION,
    ],
    subMenus: [
      {
        path: "/invoice/customer-invoices",
        title: "Customer",
        permission: [
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_SETTLE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_TRANSACTION_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/invoice/agent-invoices",
        title: "Agent",
        permission: [
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_RECALCULATE_AGENT_INVOICE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE_TRANSACTION,
        ],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/invoice/invoice-payment-rule-groups",
        title: "Rule Groups",
        permission: [
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_ADD,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_DELETE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_UPDATE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_ADD,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_USER_DELETE,
          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
        ],
        requirePermission: true,
        icon: null,
      },
    ],
  },
];

export default menuItems;
